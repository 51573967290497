import React from 'react'

import _ from 'lodash'

import {CarrierSelector, CurrencyInput, TimePicker, Option, Input, Select, Toggle, TextArea, Row, Column} from '../UI/index.js'
import {P, H5} from '../UI/index.js'

export default class ParcelSettings extends React.Component {
    onChangeCarrier(event) {
        const customer = {...this.props.customer}

        const index = customer.settings.parcels.disabledCarriers.indexOf(event.target.value)

        if (index > -1) {
            customer.settings.parcels.disabledCarriers.splice(index, 1)
        } else {
            customer.settings.parcels.disabledCarriers.push(event.target.value)
        }

        this.props.onChange(customer)
    }

    onChangeValue(path, event) {
        const customer = {...this.props.customer}

        _.set(customer, path, event.target.value)

        this.props.onChange(customer)
    }

    onChangeValues(path, event) {
        const customer = {...this.props.customer}

        _.set(customer, path, event.target.values)

        this.props.onChange(customer)
    }

    onChangeChecked(path, event) {
        const customer = {...this.props.customer}
        _.set(customer, path, event.target.checked)
        this.props.onChange(customer)
    }

    render() {
        const {reseller, customer, carriers} = this.props

        const enabledCarriers = _.filter(carriers, (carrier) => {
            return reseller.settings.parcels.carriers[carrier.name] && reseller.settings.parcels.carriers[carrier.name].enabled
        })

        const customerCarriers = _.map(_.filter(enabledCarriers, (carrier) => {
            return (customer.settings.parcels.disabledCarriers || []).indexOf(carrier.name) === -1
        }), 'name')

        const isSameDayActive = (customerCarriers.map((carrier) => reseller.settings.parcels.products['Same Day']?.[carrier])).includes(true)

        const priceTables = _.uniq(['Standaard', ..._.keys(reseller.settings.parcels.prices).sort()])

        let enabledOptions = (_.keys(reseller.settings.parcels.products) || []).filter((product) => {
            return _.some(_.keys(reseller.settings.parcels.products[product]), (carrier) => {
                return reseller.settings.parcels.products[product][carrier]
            })
        }).sort()

        enabledOptions = enabledOptions.map((option) => {
            return {value: option, title: option}
        })

        const dayToLabel = {
            monday: 'M',
            tuesday: 'D',
            wednesday: 'W',
            thursday: 'D',
            friday: 'V',
            saturday: 'Z',
            sunday: 'Z'
        }

        return (
            <div>
                {reseller.isShop &&
                    <Toggle
                        label='Stuur emailadressen niet naar externe vervoerders'
                        info='Email adressen worden niet naar externe vervoerders gestuurd. De ontvanger zal dus geen emails van de vervoerder ontvangen.'
                        checked={customer.settings.parcels.dontSendEmailToCarriers}
                        onChange={this.onChangeChecked.bind(this, 'settings.parcels.dontSendEmailToCarriers')}
                    />
                }

                {!reseller.isShop &&
                    <>
                        <H5 style={{marginBottom: 24}}>Vervoerders</H5>
                        <Row style={{marginBottom: 12}}>
                            <Column style={{width: '100%', overflowX: 'auto'}}>
                                <CarrierSelector
                                    label='Geactiveerde vervoerders'
                                    size={40}
                                    carriers={enabledCarriers}
                                    values={customerCarriers}
                                    onChange={this.onChangeCarrier.bind(this)}
                                />
                            </Column>
                        </Row>

                        <Row>
                            <Column>
                                <Toggle
                                    label='Vrije vervoerders keuze'
                                    checked={customer.settings.parcels.selectCarrier}
                                    onChange={this.onChangeChecked.bind(this, 'settings.parcels.selectCarrier')}
                                />

                            </Column>
                        </Row>

                    </>
                }

                {enabledCarriers.map(({name}) => name).indexOf('Cycloon') > -1 && (customer.settings.parcels.disabledCarriers || []).indexOf('Cycloon') === -1 &&
                    <Row>
                        <Column>
                            <Select
                                label='Lagere voorkeur voor Cycloon op vrijdag'
                                info='Cycloon krijgt een lagere voorkeur dan andere vervoerders voor pakketten die worden aangemeld op vrijdag, dit voorkomt vertraging van een pakket omdat Cycloon op zaterdag niet bezorgd.'
                                value={customer.settings.parcels.cycloonNoParcelsOnFriday}
                                onChange={this.onChangeValue.bind(this, 'settings.parcels.cycloonNoParcelsOnFriday')}
                            >
                                <option value=''>{`Gebruik vervoerder instelling (${reseller.settings.parcels.carriers.Cycloon.noParcelsOnFriday ? 'Ja' : 'Nee'})`}</option>
                                <option value='yes'>Ja</option>
                                <option value='no'>Nee</option>
                            </Select>
                        </Column>

                        <Column>
                            <Select
                                label='Alleen Cycloon bezorgingen per fiets'
                                info='Cycloon is alleen beschikbaar voor zendingen die op de fiets worden bezorgd.'
                                value={customer.settings.parcels.cycloonDeliveredByBikeOnly}
                                onChange={this.onChangeValue.bind(this, 'settings.parcels.cycloonDeliveredByBikeOnly')}
                            >
                                <option value=''>{`Gebruik vervoerder instelling (${reseller.settings.parcels.carriers.Cycloon.deliveredByBikeOnly ? 'Ja' : 'Nee'})`}</option>
                                <option value='yes'>Ja</option>
                                <option value='no'>Nee</option>
                            </Select>
                        </Column>
                    </Row>
                }

                {(enabledCarriers.map(({name}) => name).indexOf('PostNL-MSG') > -1 || _.map(enabledCarriers, 'name').indexOf('DHL-MSG') > -1) && ((customer.settings.parcels.disabledCarriers || []).indexOf('PostNL-MSG') === -1 || (customer.settings.parcels.disabledCarriers || []).indexOf('DHL-MSG') === -1) &&
                    <Row>
                        <Column>
                            <Input
                                label='Klantnaam bij Monta'
                                value={customer.settings.parcels.montaOriginName}
                                onChange={this.onChangeValue.bind(this, 'settings.parcels.montaOriginName')}
                            />
                        </Column>
                    </Row>
                }

                {_.map(enabledCarriers, 'name').indexOf('DPD-onlineparcel') > -1 && (customer.settings.parcels.disabledCarriers || []).indexOf('DPD-onlineparcel') === -1 &&
                    <Row>
                        <Column>
                            <Select
                                label='DPD - Onlineparcel zaterdagbezorging'
                                info='Zendingen die op vrijdag worden aangemeld worden op zaterdag bezorgd.'
                                value={customer.settings.parcels.dpdOnlineparcelSaturdayDelivery}
                                onChange={this.onChangeValue.bind(this, 'settings.parcels.dpdOnlineparcelSaturdayDelivery')}
                            >
                                <option value=''>{`Gebruik vervoerder instelling (${reseller.settings.parcels.carriers['DPD-onlineparcel'].saturdayDelivery ? 'Ja' : 'Nee'})`}</option>
                                <option value='yes'>Ja</option>
                                <option value='no'>Nee</option>
                            </Select>
                        </Column>
                    </Row>
                }

                {_.map(enabledCarriers, 'name').indexOf('GLS') > -1 && (customer.settings.parcels.disabledCarriers || []).indexOf('GLS') === -1 &&
                    <Row>
                        <Column>
                            <Select
                                label='GLS zaterdagbezorging'
                                info='Zendingen die op vrijdag worden aangemeld worden op zaterdag bezorgd.'
                                value={customer.settings.parcels.glsSaturdayDelivery}
                                onChange={this.onChangeValue.bind(this, 'settings.parcels.glsSaturdayDelivery')}
                            >
                                <option value=''>{`Gebruik vervoerder instelling (${reseller.settings.parcels.carriers.GLS.glsSaturdayDelivery ? 'Ja' : 'Nee'})`}</option>
                                <option value='yes'>Ja</option>
                                <option value='no'>Nee</option>
                            </Select>
                        </Column>
                    </Row>
                }

                {_.map(enabledCarriers, 'name').indexOf('DHL') > -1 && (customer.settings.parcels.disabledCarriers || []).indexOf('DHL') === -1 &&
                    <Row>
                        <Column>
                            <Select
                                label='Bedrijfsadressen via DHL Europlus'
                                info='Zendingen naar bedrijfsadressen worden verstuurd via het DHL Europlus netwerk.'
                                value={customer.settings.parcels.dhlEuroplusForBusinesAddress}
                                onChange={this.onChangeValue.bind(this, 'settings.parcels.dhlEuroplusForBusinesAddress')}
                            >
                                <option value=''>{`Gebruik vervoerder instelling (${reseller.settings.parcels.carriers.DHL.europlusForBusinesAddress ? 'Ja' : 'Nee'})`}</option>
                                <option value='yes'>Ja</option>
                                <option value='no'>Nee</option>
                            </Select>
                        </Column>
                    </Row>
                }

                {_.map(enabledCarriers, 'name').indexOf('DHL-QLS') > -1 && (customer.settings.parcels.disabledCarriers || []).indexOf('DHL-QLS') === -1 &&
                    <Row>
                        <Column>
                            <Select
                                label='Bedrijfsadressen via DHL Europlus'
                                info='Zendingen naar bedrijfsadressen worden verstuurd via het DHL Europlus netwerk.'
                                value={customer.settings.parcels.dhlQLSEuroplusForBusinesAddress}
                                onChange={this.onChangeValue.bind(this, 'settings.parcels.dhlQLSEuroplusForBusinesAddress')}
                            >
                                <option value=''>{`Gebruik vervoerder instelling (${reseller.settings.parcels.carriers['DHL-QLS'].europlusForBusinesAddress ? 'Ja' : 'Nee'})`}</option>
                                <option value='yes'>Ja</option>
                                <option value='no'>Nee</option>
                            </Select>
                        </Column>
                    </Row>
                }

                {['DHL', 'DHL-MSG', 'Cycloon'].map((carrier) => {
                    if (enabledCarriers.findIndex((c) => c.name === carrier) === -1) {
                        return
                    }

                    return (
                        <Row key={carrier}>
                            <Column>
                                <Select
                                    label={`Lagere voorkeur voor ${carrier} voor bedrijfsadressen`}
                                    value={customer.settings.parcels[`${carrier}noParcelsForBusinessAddress`]}
                                    onChange={this.onChangeValue.bind(this, `settings.parcels.${carrier}noParcelsForBusinessAddress`)}
                                >
                                    <option value=''>{`Gebruik vervoerder instelling (${reseller.settings.parcels.carriers[carrier].noParcelsForBusinessAddress ? 'Ja' : 'Nee'})`}</option>
                                    <option value='yes'>Ja</option>
                                    <option value='no'>Nee</option>
                                </Select>
                            </Column>
                        </Row>
                    )
                })}

                <H5 style={{marginTop: 36, marginBottom: 24}}>Cutoff tijd en ophaalrit</H5>

                <Row>
                    {isSameDayActive &&
                        <Column>
                            <TimePicker
                                label='Same Day cutoff'
                                info='Deze tijd bepaald tot hoelaat deze klant Same Day zendingen kan aanmelden. Zet deze op 00:00 om Same Day voor deze klant uit te schakelen.'
                                time={customer.settings.parcels.sameDayCuttoff}
                                onChange={this.onChangeValue.bind(this, 'settings.parcels.sameDayCuttoff')}
                            />
                        </Column>
                    }
                    <Column>
                        <TimePicker
                            label='Next Day cutoff'
                            info='Deze tijd bepaald tot hoelaat deze klant Next Day zendingen kan aanmelden.'
                            time={customer.settings.parcels.nextDayCuttoff}
                            onChange={this.onChangeValue.bind(this, 'settings.parcels.nextDayCuttoff')}
                        />
                    </Column>

                    {!isSameDayActive &&
                        <Column />
                    }
                </Row>

                <Row>
                    <Column>
                        <Toggle
                            label='Automatisch Same Day / Next Day'
                            info='Een zending wordt automatisch Same Day verzonden als deze voor de Same Day cutoff of na de Next Day cutoff wordt aangemeld'
                            checked={customer.settings.parcels.autoSameDayNextDay}
                            onChange={this.onChangeChecked.bind(this, 'settings.parcels.autoSameDayNextDay')}
                        />
                    </Column>
                </Row>

                {!reseller.isShop &&
                    <Row>
                        <Column>
                            <Toggle
                                label='Automatische ophaalrit'
                                info='Er wordt automatisch een ophaalrit ingepland om de zendingen voor deze klant binnen het ingestelde tijdvak op te halen.'
                                checked={customer.settings.parcels.autoPickupOrder}
                                onChange={this.onChangeChecked.bind(this, 'settings.parcels.autoPickupOrder')}
                            />
                        </Column>
                    </Row>
                }

                {customer.settings.parcels.autoPickupOrder &&
                    <>
                        <Row>
                            <Column>
                                <Toggle
                                    label='Zendingen scannen bij ophalen'
                                    info='Barcodes worden toegevoegd aan de ophaalrit en kunnen gescand worden.'
                                    checked={customer.settings.parcels.scanParcelsAtPickup}
                                    onChange={this.onChangeChecked.bind(this, 'settings.parcels.scanParcelsAtPickup')}
                                />
                            </Column>
                        </Row>

                        <Row>
                            <Column>
                                <TextArea
                                    label='Instructies ophaalrit'
                                    value={customer.settings.parcels.autoPickupInstructions}
                                    onChange={this.onChangeValue.bind(this, 'settings.parcels.autoPickupInstructions')}
                                />
                            </Column>

                            <Column>
                                <CurrencyInput
                                    style={{width: 'calc(50% - 12px)'}}
                                    label='Prijs ophaalrit'
                                    value={customer.settings.parcels.autoPickupPrice}
                                    onChange={this.onChangeValue.bind(this, 'settings.parcels.autoPickupPrice')}
                                />
                            </Column>
                        </Row>

                        <Row>
                            <Column>
                                <Toggle
                                    label='Retourrit plannen op ophaaldag'
                                    info='Voor een retourrit wordt gekeken naar de ophaaldagen in plaats van de openingsdagen.'
                                    checked={customer.settings.usePickupDaysForRetourOrder}
                                    onChange={this.onChangeChecked.bind(this, 'settings.usePickupDaysForRetourOrder')}
                                />
                            </Column>
                        </Row>

                        <Row style={{alignItems: 'center'}}>
                            <Column>
                                <P style={{marginLeft: 6}}>Ophaaldagen</P>
                            </Column>

                            <Column style={{marginBottom: 12}}>
                                {_.keys(customer.settings.parcels.pickupDays).map((day) => {
                                    return (
                                        <Option
                                            key={day}
                                            label={dayToLabel[day]}
                                            checked={customer.settings.parcels.pickupDays[day]}
                                            disabled={!reseller.settings.account.openingHours[day].open || !customer.settings.openingHours[day].open}
                                            onChange={this.onChangeChecked.bind(this, `settings.parcels.pickupDays.${day}`)}
                                        />
                                    )
                                })}
                            </Column>
                        </Row>

                        {isSameDayActive && customer.settings.parcels.sameDayCuttoff !== '00:00' &&
                            <Row style={{alignItems: 'center'}}>
                                <Column>
                                    <P style={{marginLeft: 6}}>Ophaaltijdvak Same Day</P>
                                </Column>

                                <Column>
                                    <TimePicker
                                        label='Starttijd'
                                        time={customer.settings.parcels.sameDayPickupStart}
                                        onChange={this.onChangeValue.bind(this, 'settings.parcels.sameDayPickupStart')}
                                    />

                                    <TimePicker
                                        label='Eindtijd'
                                        time={customer.settings.parcels.sameDayPickupEnd}
                                        onChange={this.onChangeValue.bind(this, 'settings.parcels.sameDayPickupEnd')}
                                    />
                                </Column>
                            </Row>
                        }

                        <Row style={{alignItems: 'center'}}>
                            <Column>
                                <P style={{marginLeft: 6}}>Ophaaltijdvak Next Day</P>
                            </Column>

                            <Column>
                                <TimePicker
                                    label='Starttijd'
                                    time={customer.settings.parcels.nextDayPickupStart}
                                    onChange={this.onChangeValue.bind(this, 'settings.parcels.nextDayPickupStart')}
                                />

                                <TimePicker
                                    label='Eindtijd'
                                    time={customer.settings.parcels.nextDayPickupEnd}
                                    onChange={this.onChangeValue.bind(this, 'settings.parcels.nextDayPickupEnd')}
                                />
                            </Column>

                        </Row>

                    </>
                }

                <H5 style={{marginTop: 36, marginBottom: 24}}>Administratie</H5>

                {!reseller.isShop &&
                    <Row>
                        <Column>
                            <Select
                                label='Tarieftabel Nederland'
                                value={customer.settings.parcels.priceTable}
                                options={priceTables.map((priceTable) => {
                                    return {value: priceTable, title: priceTable}
                                })}
                                onChange={this.onChangeValue.bind(this, 'settings.parcels.priceTable')}
                            />
                        </Column>

                        <Column>
                            <Select
                                label='Tarieftabel buitenland'
                                placeholder='Gebruik tarieftabel Nederland'
                                options={priceTables.map((priceTable) => {
                                    return {value: priceTable, title: priceTable}
                                })}
                                value={customer.settings.parcels.priceTableAbroad}
                                onChange={this.onChangeValue.bind(this, 'settings.parcels.priceTableAbroad')}
                            />
                        </Column>
                    </Row>
                }

                <Row>
                    <Column>
                        <Toggle
                            label='Referentie verplicht'
                            info='Bij het aanmaken van een zending is het verplicht een referentie in te vullen.'
                            checked={customer.settings.parcels.referenceRequiredReseller}
                            onChange={this.onChangeChecked.bind(this, 'settings.parcels.referenceRequiredReseller')}
                        />
                    </Column>
                </Row>

                <H5 style={{marginTop: 36, marginBottom: 24}}>Overig</H5>

                <Row>
                    <Column>
                        <Select
                            noSort
                            label='Standaard verzendopties'
                            info='Selecteer de opties die standaard gebruikt worden bij het aanmelden van een pakket. De geselecteerde opties zijn niet voor elke zending beschikbaar, als een combinatie van opties niet mogelijk is wordt de voorkeur gegeven aan de eerst geselecteerde optie.'
                            values={customer.settings.parcels.defaultOptions || []}
                            onChange={this.onChangeValues.bind(this, 'settings.parcels.defaultOptions')}
                            options={enabledOptions}
                        />
                    </Column>
                </Row>

                <Row>
                    <Column>
                        {(reseller.name === 'Tour de Ville Eindhoven' || reseller.name === 'Veloyd') &&
                            <Toggle
                                label='Verzendoptie Px beschikbaar'
                                checked={customer.settings.pxEnabled}
                                onChange={this.onChangeChecked.bind(this, 'settings.pxEnabled')}
                            />
                        }
                    </Column>
                </Row>

                {customer.settings.parcels.defaultOptions?.includes('Verzekerd') && (
                    <>
                        <CurrencyInput
                            label='Bedrag verzekerd'
                            value={customer.settings.parcels?.coverAmount || '0,00'}
                            onChange={this.onChangeValue.bind(this, 'settings.parcels.coverAmount')}
                        />
                    </>
                )}

                {!reseller.isShop &&
                    <>
                        <Row>
                            <Column>
                                <Select
                                    style={{marginRight: 5}}
                                    label='Retouren via'
                                    options={[
                                        {value: '', title: 'Gebruik vervoerder instelling'},
                                        {value: 'reseller', title: reseller.settings?.accountName || reseller.name},
                                        {value: 'customer', title: 'Klant'}
                                    ]}
                                    value={customer.settings.parcels.returnsVia}
                                    onChange={this.onChangeValue.bind(this, 'settings.parcels.returnsVia')}
                                />
                            </Column>

                            <Column>
                                <Input
                                    label='Emailadres zending notificaties'
                                    value={customer.settings.parcels.emailTT}
                                    onChange={this.onChangeValue.bind(this, 'settings.parcels.emailTT')}
                                />
                            </Column>
                        </Row>
                    </>
                }
            </div>

        )
    }
}
