import React, {forwardRef, useEffect, useImperativeHandle, useReducer, useRef} from 'react'

import moment from 'moment'

import dbCodes from '../../../server/dbCodes.js'

import {Alert, Button, Colors, IconButton, Modal, H4, S1, S2, P, ScrollView, Card, Row, Column, H6, ListItem, Badge, Popup} from '../UI/index.js'
import {Input, Spinner} from '../UI/index.js'
import usePackageStore from '../../stores/PackageStore.js'

/**
 * @typedef {object} PackagingScanModalProps
 *
 * @param {Order[]} filteredOrders
 */

const PackagingScanModal = forwardRef(
    /**
 * @component
 * @param {PackagingScanModalProps} props
 *
 * @returns {JSX.Element}
 */
    (props, ref) => {
        const initialState = {
            modalIsOpen: false,
            barcode: '',
            packaging: null,
            loading: false,
            message: '',
            warning: '',
            error: ''
        }
        const [state, setState] = useReducer((state, newState) => ({...state, ...newState}), structuredClone(initialState))

        const PackageStore = usePackageStore()

        const popupRef = useRef(null)
        const inputRef = useRef(null)

        const successAudio = new Audio('../scan.wav')
        const errorAudio = new Audio('../error.wav')

        useEffect(() => {
            if (state.modalIsOpen) {
                inputRef.current.focus()
            }
        }, [state.modalIsOpen])

        const open = () => {
            setState({modalIsOpen: true})
        }

        const close = () => {
            setState(initialState)
        }

        // To make passed functions accessible to parents
        useImperativeHandle(ref, () => ({open, close}))

        const onSubmit = async (event) => {
            event.preventDefault()
            const {barcode} = state

            setState({loading: true, packaging: null, error: ''})

            try {
                const packages = await PackageStore.findByBarcode(barcode)

                let id = packages[0]?._id

                if (packages.length > 1) {
                    const option = await popupRef.current.openOptions('Er zijn meerdere verpakkingen gevonden voor deze barcode', 'Selecteer de verpakking die je wilt scannen', packages.map((packaging) => {
                        return (
                            <ListItem key={packaging._id} onClick={() => popupRef.current.onClickOption({value: packaging._id})}>
                                <S1 style={{width: 100}}>{moment(packaging.events[packaging.events.length - 1].timestamp).format('YYYY-MM-DD')}</S1>
                                <P style={{flex: 2, marginLeft: 12}}>
                                    {packaging.barcode}
                                </P>

                                <Badge style={{flex: 1, marginLeft: 12}}>{dbCodes.packagingStatus[packaging.status]}</Badge>
                            </ListItem>
                        )
                    }))

                    id = option.value
                }

                if (id) {
                    const packaging = await PackageStore.updateStatus(id, 'return_delivered')
                    successAudio.play()
                    setState({barcode: '', loading: false, packaging})
                } else {
                    errorAudio.play()
                    setState({barcode: '', loading: false, error: `Geen resultaat voor barcode ${barcode}`})
                }
            } catch (err) {
                console.error(err)
                errorAudio.play()
                setState({barcode: '', loading: false, error: err.message})
            }
        }

        const {modalIsOpen, barcode, packaging, loading, message, warning, error} = state

        return (
            <Modal show={modalIsOpen} onClose={() => close()}>
                <div style={{display: 'flex', alignItems: 'center', marginBottom: 12}}>
                    <H4>Emballage uitleveren</H4>

                    <div style={{flex: 1}} />

                    <div style={{flex: 1}} />

                    <IconButton onClick={() => close()}>
                        <i style={{color: Colors.buttonSolid}} className='mdi mdi-close' />
                    </IconButton>
                </div>

                <P style={{marginBottom: 36}}>
                    Scan emballage items die worden uitgeleverd aan de klant.
                </P>

                <form onSubmit={(event) => onSubmit(event)} style={{display: 'flex'}}>
                    <Input
                        label='Barcode'
                        value={barcode}
                        onChange={(event) => setState({barcode: event.target.value.toUpperCase().trim()})}
                        ref={inputRef}
                    />

                    <Button style={{visibility: 'hidden', position: 'absolute'}} type='submit' />
                </form>

                {loading &&
                    <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', height: 330}}>
                        <Spinner name='ball-clip-rotate' fadeIn='none' />
                    </div>
                }

                {!loading && !packaging &&
                    <div style={{display: 'flex', justifyContent: 'center'}}>
                        <i style={{fontSize: 250, color: Colors.grey20}} className='mdi mdi-barcode-scan' />
                    </div>
                }

                {message && <Alert style={{marginBottom: 12}} variant='success'>{message}</Alert>}
                {warning && <Alert style={{marginBottom: 12}} variant='warning'>{warning}</Alert>}
                {error && <Alert style={{marginBottom: 12}} variant='danger'>{error}</Alert>}

                {packaging &&
                    <>
                        <Card style={{marginBottom: 24}}>
                            <Row style={{alignItems: 'center'}}>
                                <Column>
                                    <i style={{fontSize: 16, marginRight: 12}} className='mdi mdi-package-variant-closed' />
                                    <P>{packaging.barcode}</P>
                                </Column>

                                <Column>
                                    <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', width: '100%', height: 42, marginLeft: 6, marginRight: 6, marginBottom: 6}}>
                                        <S1>{dbCodes.packagingStatus[packaging.status]}</S1>
                                    </div>
                                </Column>
                            </Row>
                        </Card>

                        <H6>Historie</H6>

                        <ScrollView style={{maxHeight: 250}}>
                            {[...packaging.events].reverse().map((event, index) => {
                                return (
                                    <Row key={`history${index}`} style={{marginBottom: 3}}>

                                        <i className='mdi mdi-circle-small' style={{marginRight: 6, fontSize: 16, color: Colors.grey60}} />

                                        <Column style={{flex: 1, flexDirection: 'column'}}>

                                            <Row style={{alignItems: 'center'}}>
                                                <P>{event.description || dbCodes.packagingStatus[event.status]}</P>
                                            </Row>
                                        </Column>

                                        <Column style={{width: 200}}>
                                            <S2>{`${event.who || ''} | ${moment(event.timestamp).format('DD-MM-YYYY HH:mm:ss')}`}</S2>
                                        </Column>
                                    </Row>
                                )
                            })}
                        </ScrollView>
                    </>
                }

                <Popup ref={popupRef} />
            </Modal>
        )
    })

PackagingScanModal.displayName = 'PackagingScanModal'

export default PackagingScanModal
