import React, {forwardRef} from 'react'
import {create} from 'zustand'
import socket from '../socketio/socketio.js'
import {Vehicle} from '../../server/functions/vehicles/vehicles.types.js'

interface VehicleState {
    vehicles: Vehicle[]
    getVehicles: (options?: {ids: string[], types: string[]}) => void
    createVehicle: (vehicle: Vehicle, callback?: (err: string) => void) => void
    updateVehicles: (vehicles: Vehicle[], callback?: (err: string[]) => void) => void
}

const useVehicleStore = create<VehicleState>((set) => ({
    vehicles: [],
    getVehicles: (options) => {
        socket.emit('vehicles.get', sessionStorage.token || localStorage.mtoken, options,
        /**
         * @param {string} err
         * @param {Vehicle[]} vehicles
         */
            (err, vehicles) => {
                set({vehicles})
            })
    },
    createVehicle: (vehicle, callback) => {
        socket.emit('vehicles.create', sessionStorage.token, vehicle,
        /**
         * @param {string} err
         * @param {Vehicle} vehicle
         */
            (err, vehicle) => {
                if (callback && typeof callback === 'function') {
                    callback(err)
                }

                if (!err) {
                    set((state) => ({vehicles: [...state.vehicles, vehicle]}))
                }
            })
    },
    updateVehicles: async (vehicles, callback) => {
        const errors: string[] = []
        for (const vehicle of vehicles) {
            await new Promise<void>((resolve) => {
                socket.emit('vehicles.update', sessionStorage.token, vehicle, (err?: string, vehicle?: Vehicle) => {
                    if (err) {
                        errors.push(err)
                    }

                    if (!err) {
                        set((state) => {
                            const clonedVehicles = structuredClone(state.vehicles)
                            const index = clonedVehicles.findIndex((v) => v._id === vehicle._id)
                            clonedVehicles[index] = vehicle
                            return {vehicles: clonedVehicles}
                        })
                    }

                    resolve()
                })
            })
        }
        if (callback && typeof callback === 'function') {
            callback(errors)
        }
    }
}))

export function withVehiclesHook(ChildComponent) {
    const forwardedRef = forwardRef((props, ref) => {
        const vehicles = useVehicleStore((state) => state.vehicles)
        return <ChildComponent {...props} ref={ref} vehicles={vehicles} />
    })
    forwardedRef.displayName = ChildComponent.displayName || ChildComponent.name
    return forwardedRef
}

export default useVehicleStore
