import moment from 'moment'
import _ from 'underscore'

function now() {
    return moment().format('DD-MM-YYYY HH:mm')
}

function time() {
    return moment().format('HH:mm')
}

function timestamp() {
    return moment().toISOString(true)
}

function yesterday() {
    return moment().subtract(1, 'd').format('YYYY-MM-DD')
}

function today() {
    return moment().format('YYYY-MM-DD')
}

function tomorrow() {
    return moment().add(1, 'd').format('YYYY-MM-DD')
}

function weekDay(reseller?, date?, customer?, usePickupDays?, availableDays?) {
    date = date || today()
    date = moment(date).format('YYYY-MM-DD')

    if (!reseller) {
        date = moment(date).day() === 6 ?
            moment(date).add(2, 'd').format('YYYY-MM-DD') :
            moment(date).day() === 0 ?
                moment(date).add(1, 'd').format('YYYY-MM-DD') :
                date

        return date
    } else if (checkOpen(reseller, date, availableDays) && checkOpenCustomer(customer, date, usePickupDays)) {
        return date
    } else {
        return nextWeekDay(reseller, date, customer, usePickupDays, availableDays)
    }
}

function nextWeekDay(reseller?, date?, customer?, usePickupDays?, availableDays?) {
    date = date || today()
    date = moment(date).add(1, 'd').format('YYYY-MM-DD')

    if (!reseller) {
        date = moment(date).day() === 6 ?
            moment(date).add(2, 'd').format('YYYY-MM-DD') :
            moment(date).day() === 0 ?
                moment(date).add(1, 'd').format('YYYY-MM-DD') :
                date

        return date
    } else if (checkOpen(reseller, date, availableDays) && checkOpenCustomer(customer, date, usePickupDays)) {
        return date
    } else {
        return nextWeekDay(reseller, date, customer, usePickupDays, availableDays)
    }
}

function nextSunday() {
    return moment().add(7 - moment().day(), 'd').format('YYYY-MM-DD')
}

function checkOpen(reseller, date, availableDays?) {
    if (availableDays) {
        if (reseller.settings.account.openingHours[moment(date).format('dddd').toLowerCase()].open && availableDays[moment(date).format('dddd').toLowerCase()]) {
            return checkHoliday(reseller, date)
        } else {
            return false
        }
    } else {
        if (reseller.settings.account.openingHours[moment(date).format('dddd').toLowerCase()].open) {
            return checkHoliday(reseller, date)
        } else {
            return false
        }
    }
}

function checkOpenCustomer(customer, date, usePickupDays) {
    if (!customer) {
        return true
    } else {
        // Check if customer has any open days
        if (!_.some(Object.values(customer.settings.openingHours), (day) => day.open)) {
            throw new global.UserError('Customer has no open days', 'Er zijn geen openingsdagen ingesteld.')
        }

        if (usePickupDays && customer.settings.parcels.pickupDays) {
            // Check if customer has any pickup days
            if (!_.some(Object.keys(customer.settings.openingHours), (day) => customer.settings.openingHours[day].open && customer.settings.parcels.pickupDays[day])) {
                throw new global.UserError('Customer has no pickup days', 'Er zijn geen ophaaldagen ingesteld.')
            }

            return customer.settings.openingHours[moment(date).format('dddd').toLocaleLowerCase()].open && customer.settings.parcels.pickupDays[moment(date).format('dddd').toLowerCase()]
        } else {
            return customer.settings.openingHours[moment(date).format('dddd').toLocaleLowerCase()].open
        }
    }
}

function checkHoliday(reseller, date) {
    return _.every(_.keys(reseller.settings.account.openingHours.closed), (day) => {
        if (!reseller.settings.account.openingHours.closed[day]) {
            return true
        } else {
            return !(holidays[day].includes('0000' + date.substr(4)) || holidays[day].includes(date))
        }
    })
}

export default {
    now,
    time,
    timestamp,
    yesterday,
    today,
    tomorrow,
    weekDay,
    nextWeekDay,
    nextSunday,
    checkOpen,
    checkHoliday
}

const holidays = {
    nieuwjaarsdag: [
        '0000-01-01'
    ],
    koningsdag: [
        '0000-04-27'
    ],
    goedeVrijdag: [
        '2018-03-30',
        '2019-04-19',
        '2020-04-19',
        '2021-04-02',
        '2022-04-15',
        '2023-04-07',
        '2024-03-29',
        '2025-04-18',
        '2026-04-03',
        '2027-03-26',
        '2028-04-14',
        '2029-03-30',
        '2030-04-19',
        '2031-04-11',
        '2032-03-26',
        '2033-04-15',
        '2034-04-07',
        '2035-03-25',
        '2036-04-11',
        '2037-04-03',
        '2038-04-23',
        '2039-04-08',
        '2040-03-30',
        '2041-04-19',
        '2042-04-04',
        '2043-03-29',
        '2044-04-15',
        '2045-04-07',
        '2046-03-25',
        '2047-04-12',
        '2048-04-03',
        '2049-04-16',
        '2050-04-08',
        '2051-03-31',
        '2052-04-19',
        '2053-04-04',
        '2054-03-29',
        '2055-04-16',
        '2056-03-31',
        '2057-04-20',
        '2058-04-12'
    ],
    eerstePaasdag: [
        '2018-04-01',
        '2019-04-21',
        '2020-04-12',
        '2021-04-04',
        '2022-04-17',
        '2023-04-09',
        '2024-03-31',
        '2025-04-20',
        '2026-04-05',
        '2027-03-28',
        '2028-04-16',
        '2029-04-01',
        '2030-04-21',
        '2031-04-13',
        '2032-03-28',
        '2033-04-17',
        '2034-04-09',
        '2035-03-25',
        '2036-04-13',
        '2037-04-05',
        '2038-04-25',
        '2039-04-10',
        '2040-04-01',
        '2041-04-21',
        '2042-04-06',
        '2043-03-29',
        '2044-04-17',
        '2045-04-09',
        '2046-03-25',
        '2047-04-14',
        '2048-04-05',
        '2049-04-18',
        '2050-04-10',
        '2051-04-02',
        '2052-04-21',
        '2053-04-06',
        '2054-03-29',
        '2055-04-18',
        '2056-04-02',
        '2057-04-22',
        '2058-04-14'
    ],
    tweedePaasdag: [
        '2018-04-02',
        '2019-04-22',
        '2020-04-13',
        '2021-04-05',
        '2022-04-18',
        '2023-04-10',
        '2024-04-01',
        '2025-04-21',
        '2026-04-06',
        '2027-03-29',
        '2028-04-17',
        '2029-04-02',
        '2030-04-22',
        '2031-04-14',
        '2032-03-29',
        '2033-04-18',
        '2034-04-10',
        '2035-03-26',
        '2036-04-14',
        '2037-04-06',
        '2038-04-26',
        '2039-04-11',
        '2040-04-02',
        '2041-04-22',
        '2042-04-07',
        '2043-03-30',
        '2044-04-18',
        '2045-04-10',
        '2046-03-26',
        '2047-04-15',
        '2048-04-06',
        '2049-04-19',
        '2050-04-11',
        '2051-04-03',
        '2052-04-22',
        '2053-04-07',
        '2054-03-30',
        '2055-04-19',
        '2056-04-03',
        '2057-04-23',
        '2058-04-15'
    ],
    dagvdarbeid: [
        '0000-05-01'
    ],
    bevrijdingsdag: [
        '0000-05-05'
    ],
    hemelvaartsdag: [
        '2018-05-10',
        '2019-05-30',
        '2020-05-21',
        '2021-05-13',
        '2022-05-26',
        '2023-05-18',
        '2024-05-09',
        '2025-05-29',
        '2026-05-14',
        '2027-05-06',
        '2028-05-25',
        '2029-05-10',
        '2030-05-30',
        '2031-05-22',
        '2032-05-06',
        '2033-05-26',
        '2034-05-18',
        '2035-05-03',
        '2036-05-22',
        '2037-05-14',
        '2038-06-03',
        '2039-05-19',
        '2040-05-10',
        '2041-05-30',
        '2042-05-15',
        '2043-05-07',
        '2044-05-26',
        '2045-05-18',
        '2046-05-03',
        '2047-05-23',
        '2048-05-14',
        '2049-05-27',
        '2050-05-19',
        '2051-05-11',
        '2052-05-30',
        '2053-05-15',
        '2054-05-07',
        '2055-05-27',
        '2056-05-11',
        '2057-05-31',
        '2058-05-23'
    ],
    pinksteren: [
        '2018-05-20',
        '2019-06-09',
        '2020-05-31',
        '2021-05-23',
        '2022-06-05',
        '2023-05-28',
        '2024-05-19',
        '2025-06-08',
        '2026-05-24',
        '2027-05-16',
        '2028-06-04',
        '2029-05-20',
        '2030-06-09',
        '2031-06-01',
        '2032-05-16',
        '2033-06-05',
        '2034-05-28',
        '2035-05-13',
        '2036-06-01',
        '2037-05-24',
        '2038-06-13',
        '2039-05-29',
        '2040-05-20',
        '2041-06-09',
        '2042-05-25',
        '2043-05-17',
        '2044-06-05',
        '2045-05-28',
        '2046-05-13',
        '2047-06-02',
        '2048-05-24',
        '2049-06-06',
        '2050-05-29',
        '2051-05-21',
        '2052-06-09',
        '2053-05-25',
        '2054-05-17',
        '2055-06-06',
        '2056-05-21',
        '2057-06-10',
        '2058-06-02'
    ],
    pinkstermaandag: [
        '2018-05-21',
        '2019-06-10',
        '2020-06-01',
        '2021-05-24',
        '2022-06-06',
        '2023-05-29',
        '2024-05-20',
        '2025-06-09',
        '2026-05-25',
        '2027-05-17',
        '2028-06-05',
        '2029-05-21',
        '2030-06-10',
        '2031-06-02',
        '2032-05-17',
        '2033-06-06',
        '2034-05-29',
        '2035-05-14',
        '2036-06-02',
        '2037-05-25',
        '2038-06-14',
        '2039-05-30',
        '2040-05-21',
        '2041-06-10',
        '2042-05-26',
        '2043-05-18',
        '2044-06-06',
        '2045-05-29',
        '2046-05-14',
        '2047-06-03',
        '2048-05-25',
        '2049-06-07',
        '2050-05-30',
        '2051-05-22',
        '2052-06-10',
        '2053-05-26',
        '2054-05-18',
        '2055-06-07',
        '2056-05-22',
        '2057-06-11',
        '2058-06-03'
    ],
    eersteKerstdag: [
        '0000-12-25'
    ],
    tweedeKerstdag: [
        '0000-12-26'
    ]
}
