import React, {CSSProperties} from 'react'

export default function Spinner({style = {}, color}: {style?: CSSProperties, color?: string}) {
    const defaultStyle = {
        zIndex: 1001,
        backgroundColor: color || '#333',
        borderRadius: '100%',
        border: `2px solid ${color || '#333'}`,
        opacity: 0.8,
        borderBottomColor: 'transparent',
        height: 20,
        width: 20,
        background: 'transparent',
        display: 'inline-block',
        animation: 'rotate 0.75s 0s linear infinite',
        ...style
    }

    return (
        <div id='ball-clip-rotate' style={defaultStyle} />
    )
}
