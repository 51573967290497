import {z} from 'zod'

const NotAvailableDateTimeRange = z.object({
    start: z.string(),
    end: z.string(),
    reason: z.string().optional()
})

const NewVehicleModel = z.object({
    reseller: z.string(),
    typeId: z.string(),
    name: z.string().max(30, 'Naam mag maximaal 30 karakters bevatten'),
    comment: z.string(),
    notes: z.string(),
    notAvailableDateTimeRanges: z.array(NotAvailableDateTimeRange),
    isArchived: z.boolean().optional(),
    notesEntries: z.array(z.object({
        date: z.string(),
        note: z.string()
    })).optional()
})

const VehicleModel = NewVehicleModel.extend({
    _id: z.string()
})

export {NewVehicleModel, VehicleModel}
