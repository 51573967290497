import React from 'react'

import {Colors, P} from '../UI/index.js'

export default class Link extends React.PureComponent {
    constructor(props) {
        super(props)

        this.state = {
            hover: false
        }
    }

    render() {
        const {children, style = {}, disabled, onClick, href} = this.props

        const defaultStyle = {
            color: disabled ? Colors.grey60 : Colors.buttonSolid,
            cursor: 'pointer',
            textDecoration: this.state.hover ? 'underline' : 'none',
            fontWeight: 'bold',
            ...style
        }

        return (
            <P
                style={defaultStyle}
                onClick={disabled ? null : href ? () => window.open(href) : onClick}
                onMouseEnter={() => this.setState({hover: true})}
                onMouseLeave={() => this.setState({hover: false})}
            >
                {children}
            </P>
        )
    }
}
