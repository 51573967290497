import React from 'react'
import Leaflet from 'leaflet'

import {Button, Card, FlexColumn, Icon, Link, ListItem, Popover} from '../UI/index.js'

export default class Map extends React.PureComponent {
    componentDidMount() {
        this.initMap()
    }

    componentDidUpdate() {
        this.updateMap()
    }

    initMap() {
        this.map = Leaflet.map('map', {
            preferCanvas: true,
            boxZoom: false,
            updateWhenZooming: false,
            updateWhenIdle: true,
            attributionControl: false,
            zoomControl: false
        }).setView({lat: 52.2076823, lng: 5.1584931}, 13)

        Leaflet.tileLayer(`${window.location.origin}/api/mapbox/styles/v1/{account}/{id}/tiles/{z}/{x}/{y}`, {
            maxZoom: 18,
            updateWhenZooming: false,
            updateWhenIdle: true,
            account: 'veloyd',
            id: 'cka2lgtlz0huj1iln27ujuqim',
            tileSize: 512,
            zoomOffset: -1
        }).addTo(this.map)

        Leaflet.control.scale().addTo(this.map)

        this.drawnItems = new Leaflet.FeatureGroup()
        this.map.addLayer(this.drawnItems)

        this.updateMap()
    }

    updateMap() {
        const {layers} = this.props

        this.drawnItems.clearLayers()

        if (layers?.length > 0) {
            layers.map((layer) => {
                if (layer.geoJSON) {
                    Leaflet.geoJson(layer.geoJSON, {
                        style: () => {
                            return {
                                color: layer.color,
                                weight: 1,
                                fillOpacity: 0.2,
                                opacity: 1
                            }
                        },
                        onEachFeature: (feature, layer) => {
                            this.drawnItems.addLayer(layer)
                        }
                    })
                }
            })

            const bounds = this.drawnItems.getBounds()
            this.map.fitBounds(bounds)
        }
    }

    render() {
        return (
            <div id='map' style={{position: 'relative', cursor: 'grab', zIndex: 0, width: '100%', height: '100%'}}>
                <FlexColumn style={{position: 'absolute', left: 6, top: 6, zIndex: 99999999, overflow: 'default'}}>
                    <Card shadow='true' style={{padding: 0, width: 'fit-content', marginBottom: 24}}>
                        <Button
                            style={{marginLeft: 0, marginRight: 0}}
                            variant='text'
                            icon='mdi mdi-plus'
                            onClick={() => this.map.zoomIn()}
                        />

                        <Button
                            style={{marginLeft: 0, marginRight: 0}}
                            variant='text'
                            icon='mdi mdi-minus'
                            onClick={() => this.map.zoomOut()}
                        />
                    </Card>
                </FlexColumn>

                <img src='/images/mapboxLogo.png' style={{position: 'absolute', left: 100, bottom: 6, height: 20, zIndex: 99999, overflow: 'hidden'}} />

                <div style={{position: 'absolute', right: 6, bottom: 0, zIndex: 99999}}>
                    <Popover
                        placement='top-left'
                        content={
                            <div>
                                <ListItem size='sm'><Link href='https://www.mapbox.com/about/maps/'>Leaflet</Link></ListItem>
                                <ListItem size='sm'><Link href='https://www.mapbox.com/about/maps/'>© Mapbox</Link></ListItem>
                                <ListItem size='sm'><Link href='http://www.openstreetmap.org/copyright'>© OpenStreetMap</Link></ListItem>
                                <ListItem size='sm'><Link href='https://www.mapbox.com/map-feedback/'>Improve this map</Link></ListItem>
                            </div>
                        }
                    >
                        <Icon icon='mdi mdi-information-outline' style={{color: 'white', fontSize: 20}} />
                    </Popover>
                </div>
            </div>
        )
    }
}
