import React from 'react'

import {Input} from '../UI/index.js'
import {Button, IconButton} from '../UI/index.js'

class Contacts extends React.Component {
    constructor(props) {
        super(props)
        this.state = {validation: {}}
    }

    onClickAddContact(event) {
        event.preventDefault()
        const {contacts, onChange} = this.props
        contacts.push({name: '', role: '', notes: '', phone: '', email: ''})
        onChange(contacts)
    }

    onClickRemoveContact(index, event) {
        event.preventDefault()
        const {contacts, onChange} = this.props
        contacts.splice(index, 1)
        onChange(contacts)
    }

    onChangeValue(index, key, event) {
        let {validation} = this.state
        const {contacts, onChange} = this.props
        if (key === 'email') {
            const emailRegex = /\S+@\S+\.\S+/
            event.target.value = event.target.value.replace(' ', '')
            if (!emailRegex.test(event.target.value)) {
                if (validation[index]) {
                    validation[index].email = true
                } else {
                    validation = {
                        [index]: {
                            email: true
                        },
                        ...validation
                    }
                }
            } else {
                validation[index].email = false
            }
            this.setState({
                validation
            })
        }
        contacts[index][key] = key === 'name' && event.target.value.length === 1 ? event.target.value.toUpperCase() : event.target.value
        onChange(contacts)
    }

    render() {
        const {validation} = this.state
        const {contacts} = this.props

        return (
            <div>
                {contacts.map((contact, index) => {
                    return (
                        <div key={`contactname${index}`} style={{display: 'flex'}}>
                            <Input
                                style={{flex: 1, marginRight: 6}}
                                label='Naam'
                                value={contact.name}
                                isInvalid={validation[index] && validation[index].name}
                                onChange={this.onChangeValue.bind(this, index, 'name')}
                            />

                            <Input
                                style={{flex: 1, marginRight: 6}}
                                label='Functie'
                                value={contact.role}
                                onChange={this.onChangeValue.bind(this, index, 'role')}
                            />

                            <Input
                                style={{flex: 1, marginRight: 6}}
                                label='Opmerkingen'
                                value={contact.notes}
                                onChange={this.onChangeValue.bind(this, index, 'notes')}
                            />

                            <Input
                                style={{flex: 1, marginRight: 6}}
                                label='Telefoon'
                                value={contact.phone}
                                onChange={this.onChangeValue.bind(this, index, 'phone')}
                            />

                            <Input
                                style={{flex: 1}}
                                label='Emailadres'
                                value={contact.email}
                                isInvalid={validation[index] && validation[index].email}
                                onChange={this.onChangeValue.bind(this, index, 'email')}
                            />

                            <IconButton onClick={this.onClickRemoveContact.bind(this, index)}>
                                <i className='mdi mdi-delete' />
                            </IconButton>
                        </div>
                    )
                })}

                <Button
                    variant='outline-white'
                    icon='mdi mdi-plus'
                    label='Nieuwe contactpersoon'
                    onClick={this.onClickAddContact.bind(this)}
                />
            </div>
        )
    }
}

export default (Contacts)
