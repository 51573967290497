import React, {useReducer} from 'react'

import _ from 'underscore'

import LoginActions from '../../../../actions/LoginActions.js'
import reducer from '../../../../helpers/reducer.js'

import {Alert, Divider, IconButton, H3, H6, P, Info, Button} from '../../../../components/UI/index.js'
import {Toggle, Input, Select} from '../../../../components/UI/index.js'

const initialState = {
    shippingmethodsLoading: false,
    error: '',
    loading: false,
    verified: false
}

export default function LightspeedSettings({user, onChange, reseller, location}) {
    const [{shippingmethodsLoading, loading, verified, error}, dispatch] = useReducer(reducer, initialState)

    function onChangeChecked(key, event) {
        user.settings.webshops.lightspeed[key] = event.target.checked

        onChange(user)
    }

    function onChangeValue(key, event) {
        user.settings.webshops.lightspeed[key] = event.target.value

        onChange(user)
    }

    function onClickGetShippingMethods() {
        dispatch({shippingmethodsLoading: true, error: ''})

        LoginActions.getShippingMethods(user, (err, shippingMethods) => {
            if (!err) {
                const shippingMethodsNames = shippingMethods.lightspeed || []

                const oldShippingMethods = JSON.parse(JSON.stringify(user.settings.webshops.lightspeed.shippingMethods || []))
                user.settings.webshops.lightspeed.shippingMethods = []

                shippingMethodsNames.map((name) => {
                    const shippingMethod = _.findWhere(oldShippingMethods, {name}) || {disabled: false, name, options: []}

                    shippingMethod.disabled = shippingMethod ? shippingMethod.disabled : false
                    shippingMethod.options = shippingMethod ? shippingMethod.options : []

                    user.settings.webshops.lightspeed.shippingMethods.push(shippingMethod)
                })

                onChange(user)
            }

            dispatch({shippingmethodsLoading: false, error: err})
        })
    }

    function onChangeShippingMethodDisabled(index) {
        user.settings.webshops.lightspeed.shippingMethods[index].disabled = !user.settings.webshops.lightspeed.shippingMethods[index].disabled

        onChange(user)
    }

    function onChangeShippingMethod(index, event) {
        user.settings.webshops.lightspeed.shippingMethods[index].options = event.target.values

        onChange(user)
    }

    function onVerifyConnection() {
        dispatch({loading: true, verified: false, error: ''})

        onChange(user, true)

        setTimeout(() => { // wait for saving
            LoginActions.verifyWebshop('lightspeed', (err) => {
                if (err) {
                    dispatch({loading: false, error: `Verificatie niet succesvol, controleer de ingevulde gegevens. Foutmelding: ${err}`})
                } else {
                    dispatch({loading: false, verified: true, error: ''})
                }
            })
        }, 1000)
    }

    let enabledOptions = (_.keys(reseller.settings.parcels.products) || []).filter((product) => {
        return _.some(_.keys(reseller.settings.parcels.products[product]), (carrier) => {
            return reseller.settings.parcels.products[product][carrier]
        })
    }).sort()

    enabledOptions = enabledOptions.map((option) => {
        return {value: option, title: option}
    })

    return (
        <div>
            {location.state && location.state.message &&
                <Alert variant='success'>{location.state.message}</Alert>
            }
            {location.state && location.state.err &&
                <Alert variant='error'>{`Lightspeed foutmelding: ${location.state.err}`}</Alert>
            }

            <H3>Lightspeed</H3>

            <H6>Instellingen</H6>
            <Divider />

            <Toggle
                label='Actief'
                checked={user.settings.webshops.lightspeed.active}
                onChange={(event) => onChangeChecked('active', event)}
            />

            {user.settings.webshops.lightspeed.active &&
                <>
                    <Select
                        label='Webshop taal'
                        value={user.settings.webshops.lightspeed.language}
                        options={[{title: 'Nederlands', value: 'nl'}, {title: 'Engels', value: 'en'}]}
                        onChange={(event) => onChangeValue('language', event)}
                    />

                    <Select
                        label='Cluster id'
                        info='Op te vragen bij de Lightspeed klantenservice.'
                        options={[{title: 'EU1', value: 'webshopapp'}, {title: 'US1', value: 'shoplightspeed'}]}
                        value={user.settings.webshops.lightspeed.clusterId}
                        onChange={(event) => onChangeValue('clusterId', event)}
                    />

                    <Input
                        label='API key'
                        info='Op te vragen bij de Lightspeed klantenservice.'
                        value={user.settings.webshops.lightspeed.apiKey}
                        onChange={(event) => onChangeValue('apiKey', event)}
                    />

                    <Input
                        label='API secret'
                        info='Op te vragen bij de Lightspeed klantenservice.'
                        value={user.settings.webshops.lightspeed.apiSecret}
                        onChange={(event) => onChangeValue('apiSecret', event)}
                    />

                    <Input
                        type='number'
                        min={0}
                        max={180}
                        label='Aantal dagen terugkijken voor ophalen bestellingen'
                        placeholder={7}
                        value={user.settings.webshops.lightspeed.nrOfDays}
                        onChange={(event) => onChangeValue('nrOfDays', event)}
                    />

                    <Select
                        label='Update order status in Lightspeed'
                        info=''
                        value={user.settings.webshops.lightspeed.updateStatus}
                        onChange={(event) => onChangeValue('updateStatus', event)}
                    >
                        <option value=''>Nooit</option>
                        <option value='confirm'>Na bevestigen</option>
                        <option value='afterSent'>Na verzenden</option>
                    </Select>

                    <Toggle
                        label='Haal alleen betaalde zendingen op'
                        checked={user.settings.webshops.lightspeed.ignoreNotPayedOrders}
                        onChange={(event) => onChangeChecked('ignoreNotPayedOrders', event)}
                    />

                    <br />
                    <br />
                    <H6>Verzendmethodes</H6>
                    <Divider />

                    <div style={{display: 'flex', alignItems: 'center', marginBottom: 12}}>
                        <P style={{display: 'flex', flex: 1}}>Verzendmethodes ophalen uit Lightspeed
                            <Info text='Klik op de knop om verzendmethodes uit Lightspeed op te halen. Selecteer vervolgens voor elke verzendmethode de nodige verzendopties.' />
                        </P>

                        <IconButton loading={shippingmethodsLoading} onClick={onClickGetShippingMethods}>
                            <i className='mdi mdi-refresh' />
                        </IconButton>
                    </div>

                    {(user.settings.webshops.lightspeed.shippingMethods || []).map((shippingmethod, index) => {
                        return (
                            <div key={`shippingmethod${index}`} style={{display: 'flex', alignItems: 'center'}}>
                                <Toggle
                                    checked={!shippingmethod.disabled}
                                    onChange={(event) => onChangeShippingMethodDisabled(index, event)}
                                />

                                <P style={{marginLeft: 20, marginBottom: 12, flex: 1}}>{shippingmethod.name}</P>

                                <Select
                                    noSort
                                    label='Verzendopties'
                                    values={shippingmethod.options || []}
                                    options={enabledOptions}
                                    onChange={(event) => onChangeShippingMethod(index, event)}
                                />
                            </div>
                        )
                    })}

                    <Button
                        variant='text'
                        style={{marginBottom: 12, marginLeft: 0}}
                        loading={loading}
                        onClick={onVerifyConnection}
                    >
                        Verifieer
                    </Button>

                    {error && (
                        <Alert onClose={() => dispatch({error: ''})} variant='error'>{error}</Alert>
                    )}

                    {!error && verified && (
                        <Alert onClose={() => dispatch({verified: false})}>Verificatie succesvol.</Alert>
                    )}
                </>
            }
        </div>
    )
}
