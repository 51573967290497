import React from 'react'
import _ from 'underscore'

import {Button, Colors, Popover, ListItem, P, ToolTip} from '../UI/index.js'

class SearchBox extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            filter: '',
            operator: '=',
            text: '',
            focus: false
        }
    }

    onRemoveFilter() {
        this.setState({filter: ''})
    }

    setFilter(filter) {
        this.setState({filter})
        this.filterPopover.close()

        setTimeout(() => this.input.focus(), 100)
    }

    setOperator(operator) {
        this.setState({operator})
        this.operatorPopover.close()

        setTimeout(() => this.input.focus(), 100)
    }

    search(event) {
        event.preventDefault()
        const {onChange, columns} = this.props
        const {filter, operator, text} = this.state

        if (filter || text) {
            const column = columns[filter] || {key: '', title: ''}
            const query = {key: column.key, title: column.title, operator, text}
            this.setState({filter: '', operator: '=', text: ''})
            onChange(query)
        }
    }

    focus() {
        this.setState({focus: true})
    }

    blur() {
        this.setState({focus: false})
    }

    render() {
        const {filter, operator, text, focus} = this.state
        const {columns} = this.props

        const operators = {
            '=': {
                label: 'Bevat',
                icon: 'mdi-equal'
            },
            '==': {
                label: 'Is gelijk aan'
            },
            '!=': {
                label: 'Niet gelijk aan',
                icon: 'mdi-not-equal-variant'
            },
            '>': {
                label: 'Groter dan',
                icon: 'mdi-greater-than'
            },
            '<': {
                label: 'Kleiner dan',
                icon: 'mdi-less-than'
            }
        }

        const inputStyle = {
            background: Colors.backgroundWhite,
            width: '100%',
            borderRadius: '4px 4px 0px 0px',
            borderBottom: `1px solid ${Colors.grey20}`,
            display: 'flex',
            alignItems: 'center',
            height: 42,
            paddingRight: 10,
            paddingLeft: 10,
            marginRight: 0,
            marginBottom: 10,
            cursor: 'text',
            position: 'relative'
        }

        const inputElStyle = {
            all: 'unset',
            width: '100%',
            height: '100%'
        }

        const iconStyle = {
            width: 20,
            height: 20,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-around',
            fontSize: 20,
            transform: 'translateY(-1px)',
            cursor: 'pointer'
        }

        const textStyle = {
            fontSize: 15,
            color: Colors.textDark,
            marginLeft: 12,
            marginRight: 12,
            flex: 3,
            transform: focus || text ? 'translateY(5px)' : 'translateY(0px)',
            alignSelf: 'stretch'
        }

        const filterStyle = {
            fontSize: 15,
            color: Colors.textDark,
            marginLeft: filter ? 10 : 5,
            marginRight: filter ? 10 : 5,
            flexShrink: filter ? 'auto' : 0,
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            overflow: 'hidden'
        }

        const borderStyle = {
            position: 'absolute',
            width: '100%',
            height: 2,
            bottom: -1,
            background: Colors.buttonSolid,
            left: 0,
            transform: focus ? 'scale(1, 1)' : 'scale(0, 1)',
            transformOrigin: 'center left'
            // transition: 'transform .2s cubic-bezier(0.770, 0.000, 0.175, 1.000)'
        }

        const labelStyle = {
            display: 'flex',
            alignItems: 'center',
            position: 'absolute',
            top: 12,
            transform: focus || text ? 'translateY(-15px)' : 'translateY(0px)',
            fontSize: focus || text ? 12 : 15,
            color: Colors.textMedium,
            cursor: 'text',
            transition: 'transform .2s ease',
            pointerEvents: 'none'
        }

        return (
            <form style={{display: 'flex', maxWidth: 400, marginLeft: 0, flexWrap: 'wrap'}} onSubmit={this.search.bind(this)}>

                <div style={inputStyle}>

                    {!filter &&
                        <Popover
                            popoverStyle={{marginTop: 12}}
                            ref={(ref) => this.filterPopover = ref}
                            content={
                                <div style={{maxHeight: 400, overflowY: 'auto'}}>
                                    {_.keys(columns).map((key) => {
                                        return (
                                            <ListItem
                                                key={key}
                                                size='sm'
                                                focused={key === filter}
                                                onMouseDown={this.setFilter.bind(this, key)}
                                            >
                                                <P>{columns[key].title}</P>
                                            </ListItem>
                                        )
                                    })}
                                </div>
                            }
                        >
                            <i
                                className='mdi mdi-filter-variant-plus'
                                style={iconStyle}
                            >
                            </i>
                        </Popover>
                    }
                    {filter &&
                        <i
                            className='mdi mdi-filter-variant-remove'
                            style={iconStyle}
                            onClick={this.onRemoveFilter.bind(this)}
                        >
                        </i>
                    }

                    <div style={filterStyle}>
                        <span>{filter ? columns[filter].title : ''}</span>
                    </div>

                    <Popover
                        popoverStyle={{marginTop: 12}}
                        ref={(ref) => this.operatorPopover = ref}
                        content={
                            <div style={{maxHeight: 400}}>
                                {_.keys(operators).map((key) => {
                                    return (
                                        <ToolTip text={operators[key]?.label || ''} key={key}>
                                            <ListItem
                                                size='sm'
                                                focused={key === operator}
                                                onMouseDown={this.setOperator.bind(this, key)}
                                            >
                                                {operators[key].icon ? <i className={`mdi ${operators[key].icon}`}></i> : <span>{key}</span>}
                                            </ListItem>
                                        </ToolTip>
                                    )
                                })}
                            </div>
                        }
                    >
                        {operators[operator].icon ?
                                <i
                                    className={`mdi ${operators[operator].icon}`}
                                    style={iconStyle}
                                >
                                </i> :
                                <span>{operator}</span>
                        }
                    </Popover>

                    <div style={textStyle}>
                        <div style={labelStyle}>Zoeken</div>
                        <input
                            ref={(ref) => this.input = ref}
                            style={inputElStyle}
                            value={text}
                            onChange={(event) => this.setState({text: event.target.value})}
                            onFocus={this.focus.bind(this)}
                            onBlur={this.blur.bind(this)}
                            onMouseEnter={() => this.setState({hover: true})}
                            onMouseLeave={() => this.setState({hover: false})}
                        />
                    </div>

                    <i
                        onClick={this.search.bind(this)}
                        className='mdi mdi-magnify'
                        style={iconStyle}
                    >
                    </i>
                    <div style={borderStyle}></div>
                </div>

                <Button style={{visibility: 'hidden', position: 'absolute'}} type='submit' />
            </form>
        )
    }
}

export default SearchBox
