import React from 'react'
import {Alert, Button, H4, Modal, ProgressBar, CurrencyInput, RadioButton, Select, Check} from '../UI/index.js'

class EditPricePopup extends React.Component {
    constructor(props) {
        super(props)

        this.initialState = {
            isOpen: false,
            newPrice: '',
            pricingOption: '',
            invoiceOption: false,
            invoice: false,
            priceTable: '',
            loading: false,
            progressNow: 0,
            progressMax: null,
            onConfirm: null,
            onClose: null,
            message: '',
            error: '',
            errors: []
        }

        this.state = JSON.parse(JSON.stringify(this.initialState))
    }

    open(onConfirm) {
        this.setState({isOpen: true, onConfirm})
    }

    close() {
        this.setState(this.initialState)
    }

    onClickConfirm() {
        this.setState({loading: true, message: '', error: ''})
        const {pricingOption, newPrice, invoiceOption, invoice, priceTable} = this.state

        typeof this.state.onConfirm === 'function' && this.state.onConfirm(pricingOption, newPrice, invoiceOption ? invoice : '', priceTable)
    }

    setMessage(message) {
        this.setState({message, loading: false})
    }

    setError(error) {
        this.setState({error, loading: false})
    }

    setErrors(errors) {
        this.setState({errors, loading: false})
    }

    setProgress(progressNow, progressMax) {
        this.setState({progressNow, progressMax})
    }

    render() {
        const {isOpen, newPrice, pricingOption, invoiceOption, invoice, priceTable, progressNow, progressMax, onConfirm, loading, message, error, errors} = this.state
        const {reseller} = this.props

        return (
            <Modal show={isOpen} style={{width: errors.length > 0 ? 800 : 600, maxWidth: '90%'}} onClose={this.close.bind(this)}>
                <div style={{display: 'flex', marginBottom: 24}}>
                    <H4>Wijzig rit prijs</H4>

                    <div style={{flex: 1}} />
                </div>

                <br />

                <div style={{display: 'flex'}}>
                    <RadioButton
                        style={{width: '100%'}}
                        label='Herbereken prijs volgens tarieftabel'
                        checked={pricingOption === 'recalculatePrice'}
                        value='recalculatePrice'
                        onChange={(event) => this.setState({pricingOption: event.target.value})}
                        disabled={error || message}
                    />

                    <Select
                        style={{width: '45%'}}
                        label='Tarieftabel'
                        value={priceTable}
                        onChange={(event) => this.setState({priceTable: event.target.value})}
                        allowEmptyValue
                        placeholder='Behoud huidige tarieftabel'
                        disabled={(pricingOption !== 'recalculatePrice') || (error || message)}
                    >
                        {reseller.settings.orders.prices.map((orderPriceTable) => (
                            <option key={orderPriceTable.id} value={orderPriceTable.id}>{orderPriceTable.name}</option>
                        ))}
                    </Select>
                </div>

                <div style={{display: 'flex'}}>
                    <RadioButton
                        style={{width: '100%'}}
                        label='Wijzig prijs'
                        checked={pricingOption === 'newPrice'}
                        onChange={(event) => this.setState({pricingOption: event.target.value})}
                        value='newPrice'
                        disabled={error || message}
                    />
                    <CurrencyInput
                        style={{width: '45%'}}
                        label='Prijs'
                        value={newPrice}
                        onChange={(event) => this.setState({newPrice: event.target.value})}
                        disabled={(pricingOption !== 'newPrice') || (error || message)}
                    />
                </div>

                <div style={{display: 'flex'}}>
                    <Check
                        style={{width: '100%'}}
                        labelStyle={{marginLeft: 6}}
                        label='Wijzig facturatie van ritten'
                        checked={invoiceOption}
                        onChange={(event) => this.setState({invoiceOption: event.target.checked})}
                        disabled={error || message}
                    />

                    <Select
                        style={{width: '45%'}}
                        label='Factureren'
                        value={invoice}
                        onChange={(event) => this.setState({invoice: event.target.value})}
                        disabled={!invoiceOption || (error || message)}
                    >
                        <option value='on'>Aan</option>
                        <option value='off'>Uit</option>
                    </Select>
                </div>

                {progressMax &&
                    <ProgressBar now={progressNow} max={progressMax} />
                }

                {message &&
                    <Alert variant='success'>{message}</Alert>
                }

                {error &&
                    <Alert variant='danger'>{error}</Alert>
                }

                {errors.length > 0 && errors.map((error, index) => {
                    return <Alert key={`error${index}`} variant='danger'>{error}</Alert>
                })}

                <div style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-end'}}>
                    {loading && progressNow !== progressMax &&
                        <Button
                            variant='outline-white'
                            onClick={() => this.setState({stopOperation: true, progressNow: progressMax, loading: false}, this.close)}
                        >
                            Afbreken
                        </Button>
                    }

                    {!message && !error && !loading &&
                        <Button
                            variant='text'
                            onClick={this.close.bind(this)}
                        >
                            Annuleren
                        </Button>
                    }

                    {onConfirm && !message && !error &&
                        <Button
                            variant='text'
                            loading={loading}
                            onClick={() => this.setState({stopOperation: false}, this.onClickConfirm)}
                        >
                            Bevestigen
                        </Button>
                    }

                    {(message || error) &&
                        <Button
                            style={{marginBottom: 0}}
                            variant='text'
                            onClick={this.close.bind(this)}
                        >
                            Sluiten
                        </Button>
                    }
                </div>
            </Modal>
        )
    }
}

export default EditPricePopup
