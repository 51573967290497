import React from 'react'

import Alert from './Alert.js'
import Panel from './Panel.js'
import ListItem from './ListItem.js'
import TextArea from './TextArea.js'
import S1 from './fonts/S1.js'
import P from './fonts/P.js'
import Input from './Input.js'

class Editor extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            value: props.value,
            id: `mention-${Math.random().toString()}`,
            lookupId: `lookup-${Math.random().toString()}`,
            startAt: -1,
            mentionSize: 0,
            mentionList: [],
            top: 0,
            left: 0
        }
    }

    componentDidMount() {
        document.addEventListener('keydown', this.onKeyDown.bind(this))
    }

    componentWillUnmount() {
        document.removeEventListener('onKeyDown', this.onKeyDown)
        document.removeEventListener('onKeyPress', this.onKeyPress)
    }

    componentDidUpdate(prevProps) {
        if (this.props.value !== prevProps.value) {
            this.setState({value: this.props.value})
        }
    }

    getCaretCoordinates(element, position, options) {
        if (!isBrowser) {
            throw new Error('textarea-caret-position#getCaretCoordinates should only be called in a browser')
        }

        const debug = options && options.debug || false
        if (debug) {
            const el = document.querySelector('#input-textarea-caret-position-mirror-div')
            if (el) el.parentNode.removeChild(el)
        }

        // The mirror div will replicate the textarea's style
        const div = document.createElement('div')
        div.id = 'input-textarea-caret-position-mirror-div'
        document.body.appendChild(div)

        const style = div.style
        const computed = window.getComputedStyle ? window.getComputedStyle(element) : element.currentStyle // currentStyle for IE < 9
        const isInput = element.nodeName === 'INPUT'

        // Default textarea styles
        style.whiteSpace = 'pre-wrap'
        if (!isInput) {
            style.wordWrap = 'break-word'
        } // only for textarea-s

        // Position off-screen
        style.position = 'absolute' // required to return coordinates properly
        if (!debug) {
            style.visibility = 'hidden'
        } // not 'display: none' because we want rendering

        // Transfer the element's properties to the div
        properties.forEach((prop) => {
            if (isInput && prop === 'lineHeight') {
            // Special case for <input>s because text is rendered centered and line height may be != height
                if (computed.boxSizing === 'border-box') {
                    const height = parseInt(computed.height)
                    const outerHeight =
                    parseInt(computed.paddingTop) +
                    parseInt(computed.paddingBottom) +
                    parseInt(computed.borderTopWidth) +
                    parseInt(computed.borderBottomWidth)
                    const targetHeight = outerHeight + parseInt(computed.lineHeight)
                    if (height > targetHeight) {
                        style.lineHeight = height - outerHeight + 'px'
                    } else if (height === targetHeight) {
                        style.lineHeight = computed.lineHeight
                    } else {
                        style.lineHeight = 0
                    }
                } else {
                    style.lineHeight = computed.height
                }
            } else {
                style[prop] = computed[prop]
            }
        })

        if (isFirefox) {
            // Firefox lies about the overflow property for textareas: https://bugzilla.mozilla.org/show_bug.cgi?id=984275
            if (element.scrollHeight > parseInt(computed.height)) {
                style.overflowY = 'scroll'
            }
        } else {
            style.overflow = 'hidden' // for Chrome to not render a scrollbar; IE keeps overflowY = 'scroll'
        }

        div.textContent = element.value.substring(0, position)
        // The second special handling for input type="text" vs textarea:
        // spaces need to be replaced with non-breaking spaces - http://stackoverflow.com/a/13402035/1269037
        if (isInput) {
            div.textContent = div.textContent.replace(/\s/g, '\u00a0')
        }

        const span = document.createElement('span')
        // Wrapping must be replicated *exactly*, including when a long word gets
        // onto the next line, with whitespace at the end of the line before (#7).
        // The  *only* reliable way to do that is to copy the *entire* rest of the
        // textarea's content into the <span> created at the caret position.
        // For inputs, just '.' would be enough, but no need to bother.
        span.textContent = element.value.substring(position) || '.' // || because a completely empty faux span doesn't render at all
        div.appendChild(span)

        const coordinates = {
            top: span.offsetTop + parseInt(computed['borderTopWidth']),
            left: span.offsetLeft + parseInt(computed['borderLeftWidth']),
            height: parseInt(computed['lineHeight'])
        }

        if (debug) {
            span.style.backgroundColor = '#aaa'
        } else {
            document.body.removeChild(div)
        }

        if (element.scrollLeft) {
            coordinates.left = coordinates.left - element.scrollLeft
        }
        if (element.scrollHeight) {
            coordinates.top = coordinates.top - element.scrollTop
        }

        return coordinates
    }

    extractMention(value, startAt) {
        let mention = value.substring(startAt, value.length)
        const whiteSpaceIndex = mention.search(/\s/)
        const endAt = whiteSpaceIndex > -1 ? whiteSpaceIndex : value.length
        mention = mention.substring(0, endAt)
        return mention.toLowerCase()
    }

    hideLookup(event) {
        if (!event?.target?.selectionStart || event.target.selectionStart !== this.state.startAt) {
            this.setState({mentionList: [], startAt: -1})
        }
    }

    insertNameIntoInput(event, tag) {
        const textArea = this.textArea.getRef()
        const {value, startAt, mentionSize} = this.state

        const first = value.substr(0, startAt)
        const last = value.substr(startAt + mentionSize, value.length)

        this.setState({value: `${first}${tag}}${last}`, mentionSize: 0, startAt: -1})

        textArea.focus()
        textArea.selectionEnd = startAt + tag.length + 1
        this.hideLookup()
    }

    updateMentionList() {
        const {value, startAt} = this.state
        const mention = this.extractMention(value, startAt)

        const filteredData = (this.props.tags || []).filter((tag) => typeof tag === 'string' && tag.toLowerCase().includes(mention))
            .slice(0, this.props.limit || 10)

        this.setState({mentionList: filteredData, selectedMention: 0})
    }

    onChange(event) {
        const {value, selectionStart} = event.target

        const character = value.substring(selectionStart - 1, selectionStart)

        if (character === (this.props.symbol || '{')) {
            const textArea = this.textArea.getRef()
            const {top, left} = this.getCaretCoordinates(textArea, textArea.selectionEnd)

            this.setState({
                value,
                startAt: selectionStart,
                mentionList: [...this.props.tags],
                selectedMention: 0,
                top,
                left
            })

            return
        }

        if (character === ' ' || value.trim() === '') {
            this.setState({value})
            this.hideLookup()
            return
        }

        if (this.state.startAt > -1) {
            this.setState({value, mentionSize: this.state.mentionSize + 1}, () => {
                this.updateMentionList()
            })
            return
        }

        this.setState({value})
    }

    onKeyDown(event) {
        const {mentionList, selectedMention} = this.state

        if (mentionList.length && (event.keyCode === 40 || event.which === 40 || event.keyCode === 38 || event.which === 38 || event.keyCode === 13 || event.which === 13)) {
            event.preventDefault()

            if ((event.keyCode === 40 || event.which === 40) && selectedMention < mentionList.length - 1) { // Down
                this.setState({selectedMention: selectedMention + 1})
            }

            if ((event.keyCode === 38 || event.which === 38) && selectedMention > 0) { // Up
                this.setState({selectedMention: selectedMention - 1})
            }

            if ((event.keyCode === 13 || event.which === 13) && mentionList[selectedMention]) { // Enter
                this.insertNameIntoInput(event, mentionList[selectedMention])
            }
        }
    }

    onBlur() {
        setTimeout(() => {
            if (document.activeElement !== this.textArea?.getRef()) {
                this.props.onChange({target: {value: this.state.value}})
            }
        }, 100)
    }

    render() {
        const {comment} = this.props

        return (
            <div style={{position: 'relative'}}>
                {!!this.state.mentionList.length &&
                    <Panel
                        style={{
                            position: 'absolute',
                            zIndex: 10,
                            width: 200,
                            left: this.state.left,
                            top: this.state.top + 18
                        }}
                    >
                        {this.state.mentionList.map((tag, index) => {
                            if (tag) {
                                return (
                                    <ListItem
                                        size='sm'
                                        focused={index === this.state.selectedMention}
                                        key={`tag${index}`}
                                        onClick={(e) => this.insertNameIntoInput(e, tag)}
                                    >
                                        {tag}
                                    </ListItem>
                                )
                            }
                        })}
                    </Panel>
                }

                {this.props.as === 'input' ?
                        <Input
                            label={this.props.label}
                            value={this.state.value}
                            ref={(ref) => this.textArea = ref}
                            onChange={this.onChange.bind(this)}
                            onBlur={this.onBlur.bind(this)}
                            onClick={this.hideLookup.bind(this)}
                        /> :
                        <TextArea
                            label={this.props.label}
                            wrap='off'
                            value={this.state.value}
                            rows={10}
                            ref={(ref) => this.textArea = ref}
                            onChange={this.onChange.bind(this)}
                            onBlur={this.onBlur.bind(this)}
                            onClick={this.hideLookup.bind(this)}
                        />
                }

                {comment &&
                    <Alert variant='info' style={{marginTop: -10, marginBottom: 30}}>
                        <P>{comment}</P>
                    </Alert>
                }

                {!!this.props.tags?.length && !this.props.as &&
                    <Alert variant='info' style={{marginTop: -10, marginBottom: 30}}>
                        <S1>Gebruik <code>{'{tags}'}</code> in je bericht</S1>
                        <P>Type een accolade <code>{'{'}</code> en selecteer een tag uit het keuzemenu</P>
                    </Alert>
                }
            </div>
        )
    }
}

export default Editor

const properties = [
    'direction', // RTL support
    'boxSizing',
    'width', // on Chrome and IE, exclude the scrollbar, so the mirror div wraps exactly as the textarea does
    'height',
    'overflowX',
    'overflowY', // copy the scrollbar for IE

    'borderTopWidth',
    'borderRightWidth',
    'borderBottomWidth',
    'borderLeftWidth',
    'borderStyle',

    'paddingTop',
    'paddingRight',
    'paddingBottom',
    'paddingLeft',

    // https://developer.mozilla.org/en-US/docs/Web/CSS/font
    'fontStyle',
    'fontVariant',
    'fontWeight',
    'fontStretch',
    'fontSize',
    'fontSizeAdjust',
    'lineHeight',
    'fontFamily',

    'textAlign',
    'textTransform',
    'textIndent',
    'textDecoration', // might not make a difference, but better be safe

    'letterSpacing',
    'wordSpacing',

    'tabSize',
    'MozTabSize'
]

const isBrowser = (typeof window !== 'undefined')
const isFirefox = (isBrowser && window.mozInnerScreenX != null)
