import React, {useState, useEffect, useRef} from 'react'
import {Alert, Button, DraggableList, IconButton, Input, Modal, Popup, Select} from '../UI/index.js'
import guid from '../../../utils/guid.js'
import {getColumns} from '../../utils/csvExport.js'

const newExportTemplate = {
    name: '',
    columns: ['order._id', 'order.date', 'order.customer'],
    method: 'order'
}

const defaultColumns = [
    'order.date',
    'order._id',
    'order.customer',
    'order.trackTrace',
    'order.status',
    'order.subStatus',
    'order.route',
    'order.messenger',
    'order.amountOfAddresses',
    'order.nrOfColli',
    'address.type',
    'address.name',
    'address.street',
    'address.nr',
    'address.addition',
    'address.postalCode',
    'address.city',
    'collo.barcode',
    'collo.type',
    'collo.status',
    'collo.reason'
]

export default function ExportOrders(props) {
    const {reseller, onChange, open, onClickExport, onClose} = props

    const [exportTemplate, setExportTemplate] = useState('default')
    const [activeTemplate, setActiveTemplate] = useState({
        name: '',
        getColumns,
        method: 'order'
    })
    const [error, setError] = useState('')
    const [message, setMessage] = useState('')
    const [isCopying, setIsCopying] = useState(false)
    const [nameIsInvalid, setNameIsInvalid] = useState(false)
    const popupRef = useRef()
    const modalRef = useRef()
    const lastSelectRef = useRef()

    useEffect(() => {
        setNameIsInvalid(false)
        setError('')
        setMessage('')

        if (exportTemplate === 'new' && !isCopying) {
            setActiveTemplate(newExportTemplate)
        } else if (exportTemplate === 'default') {
            setActiveTemplate((prev) => ({
                ...prev,
                columns: defaultColumns,
                name: 'Standaard'
            }))
        } else {
            setActiveTemplate((prev) => ({
                ...prev,
                ...reseller.settings.orders.exportCsvTemplates.find((template) => template.id === exportTemplate)
            }))
        }
    }, [exportTemplate])

    useEffect(() => {
        if (!open) {
            setError('')
            setMessage('')
        }
    }, [open])

    const saveTemplate = () => {
        if (popupRef.current) {
            const popupText = activeTemplate.id ?
                `Je gaat een bestaande template overschrijven.

Weet je zeker dat je door wil gaan?` :
                `Je gaat een nieuw template toevoegen.

Weet je zeker dat je door wil gaan?`
            popupRef.current.open('Template opslaan', popupText, () => {
                popupRef.current.close()

                if (!activeTemplate.id) {
                    activeTemplate.id = guid.generate()
                }

                const existingIndex = reseller.settings.orders.exportCsvTemplates.findIndex((template) => template.id === activeTemplate.id) ?? -1
                if (existingIndex > -1) {
                    reseller.settings.orders.exportCsvTemplates[existingIndex] = activeTemplate
                } else {
                    reseller.settings.orders.exportCsvTemplates.push(activeTemplate)
                }

                setExportTemplate(activeTemplate.id)

                onChange(reseller, 'orders.exportCsvTemplates')

                setTimeout(() => {
                    setMessage(`Template ${activeTemplate.name} is opgeslagen.`)
                }, 500)
            })
        }
    }

    const onRemoveTemplate = () => {
        if (popupRef.current) {
            const popupText = `Je gaat het export template ${activeTemplate?.name} verwijderen.
            
Weet je zeker dat je door wil gaan?`
            popupRef.current.open('Template verwijderen', popupText, () => {
                popupRef.current.close()
                if (!activeTemplate.id) {
                    setError('Kan template niet verwijderen.')
                }

                const existingIndex = reseller.settings.orders.exportCsvTemplates.findIndex((template) => template.id === activeTemplate.id) ?? -1
                if (existingIndex > -1) {
                    const name = reseller.settings.orders.exportCsvTemplates[existingIndex].name
                    reseller.settings.orders.exportCsvTemplates.splice(existingIndex, 1)

                    setExportTemplate('new')

                    onChange(reseller, 'orders.exportCsvTemplates')

                    setMessage(`Template ${name} is verwijderd`)
                } else {
                    setError('Kan template niet verwijderen.')
                }
            })
        }
    }

    const onCopy = () => {
        setIsCopying(true)

        setExportTemplate('new')

        setActiveTemplate((prev) => ({
            ...prev,
            id: undefined,
            name: ''
        }))

        setNameIsInvalid(true)
        modalRef?.current?.innerRef?.scrollTo(0, 0)

        setTimeout(() => {
            setIsCopying(false)
        }, 1000)
    }

    const onExport = () => {
        onClickExport(activeTemplate)
    }

    return (
        <Modal
            show={open}
            closeButton
            onClose={onClose}
            title='Ritten exporteren'
            ref={modalRef}
        >
            <Select
                style={{marginBottom: 24}}
                value={exportTemplate}
                onChange={(event) => setExportTemplate(event.target.value)}
                options={[
                    {
                        value: 'new',
                        title: 'Nieuw'
                    },
                    {
                        value: 'default',
                        title: 'Standaard'
                    },
                    ...(reseller.settings.orders.exportCsvTemplates.map((template) => ({
                        value: template.id,
                        title: template.name
                    })))
                ]}
                label='Export template'
            />

            <Select
                value={activeTemplate.method}
                onChange={(event) => setActiveTemplate((prev) => ({...prev, method: event.target.value, columns: exportTemplate === 'all' ? getColumns(event.target.value).map((column) => column.value) : prev.columns}))}
                options={[
                    {
                        value: 'order',
                        title: 'Per rit'
                    },
                    {
                        value: 'address',
                        title: 'Per adres'
                    },
                    {
                        value: 'collo',
                        title: 'Per collo'
                    }
                ]}
                label='Export methode'
            />

            {activeTemplate && exportTemplate !== 'all' && (
                <div style={{marginTop: 24, marginBottom: 24}}>
                    <Input
                        value={activeTemplate.name}
                        onChange={(event) => {
                            const value = event.target.value // https://stackoverflow.com/questions/44708518/event-target-is-null-inside-functional-setstate
                            setActiveTemplate((prev) => ({...prev, name: value}))
                        }}
                        label='Template naam'
                        style={{marginBottom: 24}}
                        isInvalid={nameIsInvalid}
                    />

                    <DraggableList
                        items={activeTemplate.columns}
                        renderItem={(column, DragHandle, index) => {
                            return (
                                <div style={{display: 'flex', alignItems: 'baseline'}}>
                                    <p style={{width: 20}}>{index + 1}</p>
                                    <DragHandle />

                                    <Select
                                        value={column || ''}
                                        options={getColumns(activeTemplate.method).map((column) => ({
                                            value: column.value || '',
                                            title: column.label,
                                            badge: column.typeLabel,
                                            badgeColor: column.typeColor,
                                            badgeTextColor: column.typeTextColor || '',
                                            info: column.info || ''
                                        }))}
                                        onChange={(event) => {
                                            const newColumns = structuredClone(activeTemplate.columns)
                                            newColumns[index] = event.target.value
                                            setActiveTemplate((prev) => ({
                                                ...prev,
                                                columns: newColumns
                                            }))
                                        }}
                                        smartPlacement
                                        ref={index === activeTemplate.columns.length - 1 ? lastSelectRef : undefined}
                                    />
                                    <IconButton
                                        icon='mdi mdi-delete'
                                        onClick={() => {
                                            const newColumns = structuredClone(activeTemplate.columns)
                                            newColumns.splice(index, 1)
                                            setActiveTemplate((prev) => ({
                                                ...prev,
                                                columns: newColumns
                                            }))
                                        }}
                                    />
                                </div>
                            )
                        }}
                        onChange={(columns) => {
                            setActiveTemplate((prev) => ({
                                ...prev,
                                columns
                            }))
                        }}
                    />
                    <div style={{display: 'flex', gap: 10, marginTop: 12}}>
                        <Button
                            icon='mdi mdi-plus'
                            variant='outline-white'
                            label='Veld toevoegen'
                            onClick={() => {
                                setActiveTemplate((prev) => ({
                                    ...prev,
                                    columns: [...activeTemplate.columns, '']
                                }))
                                setTimeout(() => {
                                    lastSelectRef?.current?.focus()
                                }, 100)
                            }}
                        />

                        <Button
                            variant='outline-white'
                            label='Opslaan'
                            onClick={saveTemplate}
                            disabled={!activeTemplate.name}
                        />

                        <Button
                            variant='outline-white'
                            label='Kopieer'
                            onClick={onCopy}
                        />

                        {exportTemplate !== 'new' &&
                            <Button
                                variant='outline-white'
                                label='Verwijderen'
                                onClick={onRemoveTemplate}
                            />
                        }
                    </div>
                </div>
            )}

            {error && <Alert variant='error' onClose={() => setError('')}>{error}</Alert>}

            {message && <Alert variant='success' onClose={() => setMessage('')}>{message}</Alert>}

            <div
                style={{width: '100%', display: 'flex', justifyContent: 'end'}}
            >
                <div style={{display: 'flex'}}>
                    <Button onClick={onClose} variant='text'>Annuleren</Button>
                    <Button onClick={onExport}>Exporteren</Button>
                </div>
            </div>

            <Popup
                ref={popupRef}
            />
        </Modal>
    )
}
