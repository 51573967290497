import React from 'react'

import {DatePicker} from '../UI/index.js'

class Datepicker extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            startDate: this.props.startDate,
            endDate: this.props.endDate
        }
    }

    onChangeDate(range, noBubble) {
        const {onChange} = this.props
        const startDate = range.startDate
        const endDate = range.endDate

        this.setState({startDate, endDate})
        if (!noBubble) {
            onChange(startDate, endDate)
            this.datePicker?.close()
        }
    }

    render() {
        const {startDate, endDate} = this.state

        return (
            <div style={{display: 'flex', marginRight: 12}}>
                {startDate && endDate &&
                    <DatePicker ref={(ref) => this.datePicker = ref} label='Datum' style={{width: 200, marginRight: 6}} onChange={this.onChangeDate.bind(this)} value={startDate} range={{startDate, endDate}} />
                }
            </div>
        )
    }
}

export default (Datepicker)
