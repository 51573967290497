import React from 'react'

import OrderActions from '../../actions/OrderActions.js'

import dbCodes from '../../../server/dbCodes.js'
import {Alert, Button, H4, P, Modal, ProgressBar, Input, Select, Colors, Card, S1, Row, DropdownActionButton, DropdownButton, FlexRow, Toggle} from '../UI/index.js'
import _ from 'lodash'

export default class PickupDeliverPopup extends React.Component {
    constructor(props) {
        super(props)

        this.initialState = {
            isOpen: false,
            order: null,
            comment: '',
            callback: null,
            loading: false,
            progressNow: 0,
            progressMax: null,
            message: '',
            error: '',
            errors: [],
            validation: {}
        }

        this.addressCommentInputs = []

        this.state = structuredClone(this.initialState)
    }

    open(originalOrder, reseller, customer, callback) {
        let order = structuredClone(originalOrder)

        order.addresses.map((address) => {
            const addressColli = order.colli.filter((collo) => {
                if (address.type === 'pickup') {
                    return collo.pickupAddressIndex === address.index
                }

                if (address.type === 'delivery') {
                    return collo.deliveryAddressIndex === address.index
                }
            })

            if (!addressColli.length) {
                const collo = {
                    index: order.colli.length,
                    type: '',
                    description: '',
                    weight: 0,
                    length: 0,
                    width: 0,
                    height: 0,
                    barcode: ''
                }

                if (address.type === 'pickup') {
                    collo.pickupAddressIndex = address.index
                    collo.status = 'pickup'
                }

                if (address.type === 'delivery') {
                    collo.deliveryAddressIndex = address.index
                    collo.status = 'athub'
                }

                order.colli.push(collo)
            }

            order.colli.map((collo) => {
                collo.originalStatus = collo.status
                collo.originalReason = collo.reason
                collo.originalSpecification = collo.reasonSpecification

                if (collo.deliveredAtNeighbors) {
                    collo.originalDeliveredAtNeighbors = collo.deliveredAtNeighbors
                    collo.originalNrNeighbor = collo.nrNeighbor
                }

                if (collo.deliveredInMailbox) {
                    collo.originalDeliveredInMailbox = collo.deliveredInMailbox
                }
            })

            address.expanded = order.addresses.length === 1 || !(address.hasArrived || address.completed)
            address.correction = address.hasArrived
        })

        order = this.checkOrderCompleted(order)

        this.setState({isOpen: true, order, originalOrder, callback, reseller, customer})
    }

    close() {
        this.setState(this.initialState)
    }

    updatePickupAddresses(event) {
        const order = {...this.state.order}

        order.colli.map((collo) => {
            if (typeof collo.pickupAddressIndex === 'number') {
                this.updateColloStatus(collo, event)
            }
        })

        this.commentInput?.focus()
    }

    updateDeliveryAddresses(event) {
        const order = {...this.state.order}

        order.colli.map((collo) => {
            if (typeof collo.deliveryAddressIndex === 'number') {
                this.updateColloStatus(collo, event)
            }
        })

        this.commentInput?.focus()
    }

    updateAddressesComment(event) {
        event?.preventDefault()

        const {order, comment} = this.state

        order.addresses.map((address) => {
            if (!address.completed) {
                this.updateAddressComment(address.index, {target: {value: comment}})
            }
        })

        this.setState({comment: ''})
    }

    updatePickupAddress(addressIndex, event) {
        const order = {...this.state.order}

        order.colli.map((collo) => {
            if (collo.pickupAddressIndex === addressIndex && collo.status !== 'cancelled' && (['notcollected'].includes(event.target.value) || ['pickup', 'outforpickup', 'notcollected'].includes(collo.status))) {
                this.updateColloStatus(collo, event)
            }
        })
    }

    updateDeliveryAddress(addressIndex, event) {
        const order = {...this.state.order}

        order.colli.map((collo) => {
            if (collo.deliveryAddressIndex === addressIndex && ['collected', 'athub', 'onhold', 'outfordelivery', 'delivered', 'notdelivered', 'missing', 'manco', 'refused'].includes(collo.status)) {
                this.updateColloStatus(collo, event)
            }
        })
    }

    updateColloStatus(collo, event) {
        let order = {...this.state.order}

        order.colli[collo.index].status = event.target.value

        if (event.target.subvalue === 'deliveredAtNeighbors') {
            order.colli[collo.index].reason = ''
            order.colli[collo.index].deliveredAtNeighbors = true
            order.colli[collo.index].deliveredInMailbox = false
        } else if (event.target.subvalue === 'deliveredInMailbox') {
            order.colli[collo.index].reason = ''
            order.colli[collo.index].deliveredInMailbox = true
            order.colli[collo.index].deliveredAtNeighbors = false
        } else {
            const subvalue = event.target.subvalue?.split(';')[0] || ''
            const specification = event.target.subvalue?.split(';')[1] || ''
            order.colli[collo.index].reason = subvalue || ''
            order.colli[collo.index].reasonSpecification = specification
            order.colli[collo.index].deliveredInMailbox = false
            order.colli[collo.index].deliveredAtNeighbors = false
        }

        order = this.checkOrderCompleted(order)

        this.setState({order})
    }

    updateAddressComment(addressIndex, event) {
        const order = {...this.state.order}

        order.addresses[addressIndex].comment = event.target.value

        this.setState({order})
    }

    checkOrderCompleted(order) {
        order.addresses.map((address) => {
            const addressColli = order.colli.filter((collo) => {
                if (address.type === 'pickup') {
                    return collo.pickupAddressIndex === address.index
                }

                if (address.type === 'delivery') {
                    return collo.deliveryAddressIndex === address.index && ['collected', 'athub', 'onhold', 'outfordelivery', 'delivered', 'notdelivered', 'missing', 'manco', 'refused'].includes(collo.status)
                }
            })

            if (address.type === 'pickup') {
                address.completed = false
                address.hasHistoryEntry = _.find(order.history, {type: 'pickup', addressIndex: address.index})
            }

            if (address.type === 'delivery') {
                address.completed = !addressColli.length
                address.hasHistoryEntry = _.find(order.history, {type: 'delivery', addressIndex: address.index})
            }
        })

        return order
    }

    isValid() {
        const {order} = this.state
        const validation = {}
        let valid = true

        order.colli.map((collo) => {
            if (collo.deliveredAtNeighbors && !collo.nrNeighbor) {
                validation.nrNeighbor = validation.nrNeighbor || {}
                validation.nrNeighbor[collo.index] = true
                valid = false
            }
        })

        if (!valid) {
            this.setState({validation})
        }

        return valid
    }

    async submit() {
        const order = {...this.state.order}

        if (!this.isValid()) {
            return
        }

        const errors = []

        this.setState({loading: true, errors, progressNow: 0, progressMax: order.addresses.length})

        let updatedOrder

        for await (const address of order.addresses) {
            if (!address.completed) {
                try {
                    if (address.type === 'pickup') {
                        const updatedPickupOrder = await this.pickup(order, address)

                        if (updatedPickupOrder) {
                            updatedOrder = updatedPickupOrder
                        }
                    }

                    if (address.type === 'delivery') {
                        const updatedDeliveryOrder = await this.deliver(order, address)

                        if (updatedDeliveryOrder) {
                            updatedOrder = updatedDeliveryOrder
                        }
                    }
                } catch (err) {
                    errors.push(err)
                }

                this.setState({progressNow: this.state.progressNow + 1})
            }
        }

        if (errors.length) {
            this.setState({loading: false, errors})
        } else {
            typeof this.state.callback === 'function' && updatedOrder && this.state.callback(updatedOrder)
            this.close()
        }
    }

    async pickup(order, address) {
        const comment = address.comment
        const scanned = []
        const image = ''
        const location = null
        const correction = address.correction

        const colli = []

        order.colli.map((collo) => {
            if (collo.pickupAddressIndex !== address.index) {
                return
            }

            collo.pickupStatus = ['cancelled', 'pickup', 'outforpickup', 'notcollected'].includes(collo.status) ? collo.status : 'collected'
            collo.originalPickupStatus = ['cancelled', 'pickup', 'outforpickup', 'notcollected'].includes(collo.originalStatus) ? collo.originalStatus : 'collected'

            const colloMustBePickedUp = !['cancelled', 'pickup', 'outforpickup'].includes(collo.status) &&
                (
                    collo.pickupStatus !== collo.originalPickupStatus ||
                    (collo.pickupStatus === 'notcollected' && (collo.reason !== collo.originalReason || collo.reasonSpecification !== collo.originalSpecification))
                )

            if (colloMustBePickedUp) {
                colli.push({...collo, status: collo.status === 'notcollected' ? collo.status : 'collected'})
            }
        })

        if (!colli.length) {
            return
        }

        return new Promise((resolve, reject) => {
            OrderActions.pickup(order._id, address.index, colli, [], comment, scanned, image, location, correction, (err, order) => {
                if (err) {
                    reject(err)
                } else {
                    resolve(order)
                }
            })
        })
    }

    async deliver(order, address) {
        const signee = ''
        const documentId = ''
        const comment = address.comment
        const signature = ''
        const scanned = []
        const image = ''
        const location = null
        const correction = address.correction

        const colli = []

        order.colli.map((collo) => {
            if (collo.deliveryAddressIndex !== address.index) {
                return
            }

            const colloMustBeDelivered = ['delivered', 'notdelivered', 'missing', 'manco', 'refused'].includes(collo.status) &&
                (
                    collo.status !== collo.originalStatus ||
                    (collo.status === 'notdelivered' && (collo.reason !== collo.originalReason || collo.reasonSpecification !== collo.originalSpecification)) ||
                    !!collo.deliveredInMailbox !== !!collo.originalDeliveredInMailbox ||
                    (!!collo.deliveredAtNeighbors !== !!collo.originalDeliveredAtNeighbors || (collo.deliveredAtNeighbors && collo.nrNeighbor !== collo.originalNrNeighbor))
                )

            if (colloMustBeDelivered) {
                colli.push({...collo})
            }
        })

        if (!colli.length) {
            return
        }

        return new Promise((resolve, reject) => {
            OrderActions.deliver(order._id, address.index, colli, [], signee, documentId, comment, signature, scanned, image, location, correction, (err, order) => {
                if (err) {
                    reject(err)
                } else {
                    resolve(order)
                }
            })
        })
    }

    render() {
        const {isOpen, order, reseller, customer, comment, progressNow, progressMax, loading, message, validation, error, errors} = this.state

        const nrOfPickupAddresses = order?.addresses.filter((address) => address.type === 'pickup').length
        const nrOfDeliveryAddresses = order?.addresses.filter((address) => address.type === 'delivery').length

        const pickupNotPossibleReasons = customer?.settings.orders.pickupNotPossibleReasons?.includes('default') ? reseller?.settings?.pickupNotPossibleReasons : customer?.settings.orders.pickupNotPossibleReasons
        const pickupNotPossibleReasonsSpecifications = customer?.settings.orders.pickupNotPossibleReasons?.includes('default') ? reseller?.settings?.pickupNotPossibleReasonsSpecifications : customer?.settings.orders.pickupNotPossibleReasonsSpecifications

        const deliveryNotPossibleReasons = customer?.settings.orders.deliveryNotPossibleReasons?.includes('default') ? reseller?.settings?.deliveryNotPossibleReasons : customer?.settings.orders.deliveryNotPossibleReasons
        const deliveryNotPossibleReasonsSpecifications = customer?.settings.orders.deliveryNotPossibleReasons?.includes('default') ? reseller?.settings?.deliveryNotPossibleReasonsSpecifications : customer?.settings.orders.deliveryNotPossibleReasonsSpecifications

        const pickupNotPossibleOptions = []
        const pickupNotPossibleOptionsAddress = []
        const deliveryNotPossibleOptions = []
        const deliveryNotPossibleOptionsAddress = []

        if (pickupNotPossibleReasons) {
            for (const reason of pickupNotPossibleReasons) {
                const specifications = pickupNotPossibleReasonsSpecifications.filter((specification) => specification.reason === reason)

                if (specifications.length > 0) {
                    for (const specification of specifications) {
                        pickupNotPossibleOptions.push({label: `${dbCodes.colloReasons[reason]} - ${specification.specification}`, onClick: () => this.updatePickupAddresses({target: {value: 'notcollected', subvalue: `${reason};${specification.specification}`}}), value: 'notcollected', subvalue: `${reason};${specification.specification}`, title: `${dbCodes.colloReasons[reason]} - ${specification.specification}`})
                        pickupNotPossibleOptionsAddress.push({label: `${dbCodes.colloReasons[reason]} - ${specification.specification}`, onClick: (event) => this.updatePickupAddress(event.index, {target: {value: 'notcollected', subvalue: `${reason};${specification.specification}`}}), value: 'notcollected', subvalue: `${reason};${specification.specification}`, title: `${dbCodes.colloReasons[reason]} - ${specification.specification}`})
                    }
                } else {
                    pickupNotPossibleOptions.push({label: dbCodes.colloReasons[reason], onClick: () => this.updatePickupAddresses({target: {value: 'notcollected', subvalue: reason}}), value: 'notcollected', subvalue: reason, title: dbCodes.colloReasons[reason]})
                    pickupNotPossibleOptionsAddress.push({label: dbCodes.colloReasons[reason], onClick: (event) => this.updatePickupAddress(event.index, {target: {value: 'notcollected', subvalue: reason}}), value: 'notcollected', subvalue: reason, title: dbCodes.colloReasons[reason]})
                }
            }
        }

        if (deliveryNotPossibleReasons) {
            for (const reason of deliveryNotPossibleReasons) {
                const value = reason.split(';')[0]
                const subValue = reason.split(';')[1] || ''

                const specifications = deliveryNotPossibleReasonsSpecifications.filter((specification) => specification.reason === reason)

                if (specifications.length > 0) {
                    for (const specification of specifications) {
                        const specStatus = specification.reason.split(';')[0]
                        const specReason = specification.reason.split(';')[1] || ''

                        let label
                        if (specReason && specReason === subValue) {
                            label = `${dbCodes.colloReasons[specReason]} - ${specification.specification}`
                        } else if (specStatus === value) {
                            label = `${dbCodes.colloReasons[specStatus] || dbCodes.colloStatus[specStatus]} - ${specification.specification}`
                        }
                        deliveryNotPossibleOptions.push({label, onClick: () => this.updateDeliveryAddresses({target: {value: specStatus, subvalue: `${specReason};${specification.specification}`}}), value: specStatus, subvalue: `${specReason};${specification.specification}`, title: label})
                        deliveryNotPossibleOptionsAddress.push({label, onClick: (event) => this.updateDeliveryAddress(event.index, {target: {value: specStatus, subvalue: `${specReason};${specification.specification}`}}), value: specStatus, subvalue: `${specReason};${specification.specification}`, title: label})
                    }
                } else {
                    const label = dbCodes.colloReasons[subValue || value] || dbCodes.colloStatus[value]

                    deliveryNotPossibleOptions.push({label, onClick: () => this.updateDeliveryAddresses({target: {value, subvalue: subValue}}), value, subvalue: subValue, title: label})
                    deliveryNotPossibleOptionsAddress.push({label, onClick: (event) => this.updateDeliveryAddress(event.index, {target: {value, subvalue: subValue}}), value, subvalue: subValue, title: label})
                }
            }
        }

        return (
            <Modal show={isOpen} style={{width: 600}} onClose={this.close.bind(this)}>
                <div style={{display: 'flex', marginBottom: 24}}>
                    {nrOfPickupAddresses > 0 && !nrOfDeliveryAddresses &&
                        <H4>Ophalen</H4>
                    }

                    {!nrOfPickupAddresses && nrOfDeliveryAddresses > 0 &&
                        <H4>Bezorgen</H4>
                    }

                    {!!nrOfPickupAddresses && !!nrOfDeliveryAddresses &&
                        <H4>Ophalen / Bezorgen</H4>
                    }

                    <div style={{flex: 1}} />
                </div>

                {order &&
                    <>
                        {order.addresses.length > 2 &&
                            <>
                                <S1 style={{marginBottom: 6}}>Toepassen op alle adressen</S1>

                                <div style={{marginBottom: 24}}>
                                    {nrOfPickupAddresses > 0 &&
                                        <Row style={{marginBottom: 12}}>
                                            <Button
                                                style={{flex: 1}}
                                                label='Ophalen'
                                                icon='mdi mdi-check'
                                                variant='outline-white'
                                                onClick={() => this.updatePickupAddresses({target: {value: 'collected', subvalue: ''}})}
                                            />

                                            <DropdownActionButton
                                                style={{flex: 1}}
                                                label='Ophalen niet mogelijk'
                                                icon='mdi mdi-close'
                                                variant='outline-white'
                                                options={pickupNotPossibleOptions}
                                            />
                                        </Row>
                                    }

                                    {nrOfDeliveryAddresses > 0 &&
                                        <Row>
                                            <Button
                                                style={{flex: 1}}
                                                label='Bezorgen'
                                                icon='mdi mdi-check'
                                                variant='outline-white'
                                                onClick={() => this.updateDeliveryAddresses({target: {value: 'delivered', subvalue: ''}})}
                                            />

                                            <DropdownActionButton
                                                style={{flex: 1}}
                                                label='Bezorgen niet mogelijk'
                                                icon='mdi mdi-close'
                                                variant='outline-white'
                                                options={deliveryNotPossibleOptions}
                                            />
                                        </Row>
                                    }

                                    <form style={{display: 'flex', marginTop: 24}} onSubmit={this.updateAddressesComment.bind(this)}>
                                        <Input
                                            label='Opmerking'
                                            value={comment}
                                            onChange={(event) => this.setState({comment: event.target.value})}
                                            ref={(ref) => this.commentInput = ref}
                                        />

                                        {comment &&
                                            <Button
                                                type='submit'
                                                label='Toepassen'
                                                variant='text'
                                            />
                                        }
                                    </form>
                                </div>
                            </>
                        }

                        {order.addresses.length > 2 &&
                            <S1 style={{marginBottom: 6}}>Adressen</S1>
                        }

                        {order.addresses.map((address, addressIndex) => {
                            const addressColli = order.colli.filter((collo) => {
                                return (address.type === 'pickup' && collo.pickupAddressIndex === address.index) || (address.type === 'delivery' && collo.deliveryAddressIndex === address.index)
                            })

                            return (
                                <div key={`addressStatus${addressIndex}`} style={{display: 'flex', width: '100%', marginBottom: 12}}>
                                    <Card shadow='true' selected={address.expanded} style={{position: 'relative', flex: 1, width: 'auto', borderRadius: '0px 4px 4px 4px'}}>
                                        <div style={{position: 'absolute', height: 36, width: 6, left: -6, top: 0, borderRadius: '4px 0px 0px 4px', background: Colors[address.type]}} />

                                        <FlexRow
                                            style={{alignItems: 'center', marginTop: 5, cursor: 'pointer'}}
                                            onClick={() => {
                                                const order = {...this.state.order}

                                                order.addresses[addressIndex].expanded = !address.expanded

                                                this.setState({order})
                                            }}
                                        >

                                            <P disabled={address.completed} ellipsis style={{flex: 1}}>{`${address.isBusiness && address.name ? `${address.name}, ` : ''} ${address.street} ${address.nr}${address.addition} ${address.postalCode}`}</P>

                                            <div style={{flex: 1}} />

                                            <P>{Colors.addressColliStatus(order, address).status}</P>

                                            <i style={{fontSize: 12, marginLeft: 12, color: Colors.addressColliStatus(order, address).statusColor}} className='mdi mdi-circle' />

                                        </FlexRow>

                                        {address.expanded &&
                                            <>
                                                {address.type === 'pickup' &&
                                                    <FlexRow style={{justifyContent: 'space-between', marginTop: 12, marginBottom: 12}}>
                                                        <Button
                                                            style={{flex: 1, marginLeft: 0}}
                                                            icon='mdi mdi-check'
                                                            label='Ophalen'
                                                            variant='outline-white'
                                                            onClick={() => this.updatePickupAddress(address.index, {target: {value: 'collected', subvalue: ''}})}
                                                        />

                                                        <DropdownActionButton
                                                            style={{flex: 1, marginRight: 0}}
                                                            icon='mdi mdi-close'
                                                            label='Ophalen niet mogelijk'
                                                            variant='outline-white'
                                                            index={address.index}
                                                            options={pickupNotPossibleOptionsAddress}
                                                        />
                                                    </FlexRow>
                                                }

                                                {address.type === 'delivery' &&
                                                    <FlexRow style={{justifyContent: 'space-between', marginTop: 12, marginBottom: 12}}>
                                                        <DropdownButton
                                                            disabled={address.completed}
                                                            style={{flex: 1, marginLeft: 0}}
                                                            icon='mdi mdi-check'
                                                            label='Bezorgen'
                                                            variant='outline-white'
                                                            onClick={() => this.updateDeliveryAddress(address.index, {target: {value: 'delivered', subvalue: ''}})}
                                                            options={[
                                                                {title: 'Bezorgd bij buren', action: () => this.updateDeliveryAddress(address.index, {target: {value: 'delivered', subvalue: 'deliveredAtNeighbors'}})},
                                                                {title: 'Bezorgd in brievenbus', action: () => this.updateDeliveryAddress(address.index, {target: {value: 'delivered', subvalue: 'deliveredInMailbox'}})}
                                                            ]}
                                                        />

                                                        <DropdownActionButton
                                                            disabled={address.completed}
                                                            style={{flex: 1, marginRight: 0}}
                                                            icon='mdi mdi-close'
                                                            label='Bezorgen niet mogelijk'
                                                            variant='outline-white'
                                                            index={address.index}
                                                            options={deliveryNotPossibleOptionsAddress}
                                                        />
                                                    </FlexRow>
                                                }

                                                <Card style={{padding: 0}}>
                                                    {addressColli.map((collo, colloIndex) => {
                                                        const disabled = (address.type === 'delivery' && !['collected', 'athub', 'onhold', 'outfordelivery', 'delivered', 'notdelivered', 'missing', 'manco', 'refused'].includes(collo.status)) || (address.type === 'pickup' && collo.status === 'cancelled')

                                                        return (
                                                            <div
                                                                key={`collo${collo.index}`}
                                                                style={{display: 'flex', alignItems: 'center', height: 48, background: disabled ? Colors.grey20 : Colors.backgroundWhite, borderRadius: addressColli.length === 1 ? 4 : colloIndex === 0 ? '4px 4px 0px 0px' : colloIndex === addressColli.length - 1 ? '0px 0px 4px 4px' : 0}}
                                                            >
                                                                <S1
                                                                    disabled={disabled}
                                                                    style={{marginLeft: 12, marginRight: 12}}
                                                                >
                                                                    {collo.index + 1}
                                                                </S1>

                                                                <div style={{flex: 1, marginRight: 12}}>
                                                                    <P disabled={disabled}>
                                                                        {`${collo.barcode || 'Geen barcode'}`}
                                                                    </P>
                                                                    <P>{dbCodes.colloTypes[collo.type]}</P>
                                                                </div>

                                                                {address.type === 'pickup' &&
                                                                    <Select
                                                                        style={{marginBottom: 0, flex: 1}}
                                                                        label=''
                                                                        value={['cancelled', 'pickup', 'outforpickup', 'notcollected'].includes(collo.status) ? collo.status : 'collected'}
                                                                        subvalue={['notcollected'].includes(collo.status) ? `${collo.reason}${collo.reasonSpecification ? `;${collo.reasonSpecification}` : ''}` : ''}
                                                                        options={[
                                                                            collo.originalStatus === 'cancelled' && {value: 'cancelled', title: dbCodes.colloStatus['cancelled']},
                                                                            collo.originalStatus === 'pickup' && {value: 'pickup', title: dbCodes.colloStatus['pickup']},
                                                                            collo.originalStatus === 'outforpickup' && {value: 'outforpickup', title: dbCodes.colloStatus['outforpickup']},
                                                                            {value: 'collected', title: dbCodes.colloStatus['collected']},
                                                                            ...pickupNotPossibleOptions
                                                                        ]}
                                                                        disabled={disabled}
                                                                        onChange={this.updateColloStatus.bind(this, collo)}
                                                                    />
                                                                }

                                                                {address.type === 'delivery' &&
                                                                    <Select
                                                                        style={{marginBottom: 0, flex: 1}}
                                                                        label=''
                                                                        value={collo.status}
                                                                        subvalue={collo.deliveredAtNeighbors ? 'deliveredAtNeighbors' : collo.deliveredInMailbox ? 'deliveredInMailbox' : (collo.status === 'notcollected' ? '' : collo.reasonSpecification ? `${collo.reason};${collo.reasonSpecification}` : collo.reason)}
                                                                        options={[
                                                                            collo.status === 'cancelled' && {value: 'cancelled', title: dbCodes.colloStatus['cancelled']},
                                                                            collo.status === 'notcollected' && {value: 'notcollected', title: dbCodes.colloStatus['notcollected']},
                                                                            collo.status === 'pickup' && {value: 'pickup', title: dbCodes.colloStatus['pickup']},
                                                                            collo.status === 'outforpickup' && {value: 'outforpickup', title: dbCodes.colloStatus['outforpickup']},
                                                                            collo.status === 'collected' && {value: 'collected', title: dbCodes.colloStatus['collected']},
                                                                            collo.originalStatus === 'athub' && {value: 'athub', title: dbCodes.colloStatus['athub']},
                                                                            collo.originalStatus === 'onhold' && {value: 'onhold', title: dbCodes.colloStatus['onhold']},
                                                                            collo.originalStatus === 'outfordelivery' && {value: 'outfordelivery', title: dbCodes.colloStatus['outfordelivery']},
                                                                            {value: 'delivered', title: dbCodes.colloStatus['delivered']},
                                                                            {value: 'delivered', subvalue: 'deliveredAtNeighbors', title: 'Bezorgd bij buren'},
                                                                            {value: 'delivered', subvalue: 'deliveredInMailbox', title: 'Bezorgd in brievenbus'},
                                                                            collo.originalStatus === 'manco' && {value: 'manco', title: dbCodes.colloStatus['manco']},
                                                                            ...deliveryNotPossibleOptions
                                                                        ]}
                                                                        disabled={disabled}
                                                                        onChange={this.updateColloStatus.bind(this, collo)}
                                                                    />
                                                                }

                                                                {address.type === 'delivery' && collo.deliveredAtNeighbors &&
                                                                    <Input
                                                                        style={{marginBottom: 0, flex: 1}}
                                                                        label='Huisnummer'
                                                                        value={collo.nrNeighbor}
                                                                        isInvalid={validation.nrNeighbor?.[collo.index]}
                                                                        onChange={(event) => {
                                                                            const order = {...this.state.order}

                                                                            order.colli[collo.index].nrNeighbor = event.target.value

                                                                            this.setState({order})
                                                                        }}
                                                                    />
                                                                }
                                                            </div>
                                                        )
                                                    })}
                                                </Card>

                                                {address.hasHistoryEntry && !address.hasArrived &&
                                                    <Toggle
                                                        style={{marginTop: 24}}
                                                        label={address.type === 'pickup' ? 'Correctie laatste ophaalpoging' : 'Correctie laatste bezorgpoging'}
                                                        info='Zet deze optie aan als je de laatste poging wilt corrigeren.'
                                                        checked={address.correction}
                                                        disabled={address.hasArrived}
                                                        onChange={() => {
                                                            const order = {...this.state.order}

                                                            order.addresses[addressIndex].correction = !address.correction

                                                            this.setState({order})
                                                        }}
                                                    />
                                                }

                                                <Input
                                                    disabled={address.completed}
                                                    style={{marginTop: 24}}
                                                    label='Opmerking'
                                                    value={address.comment}
                                                    onChange={this.updateAddressComment.bind(this, addressIndex)}
                                                    ref={(ref) => this.addressCommentInputs[addressIndex] = ref}
                                                />

                                            </>
                                        }
                                    </Card>
                                </div>
                            )
                        })}
                    </>
                }

                {progressMax &&
                    <ProgressBar now={progressNow} max={progressMax} />
                }

                {message &&
                    <Alert variant='success'>{message}</Alert>
                }

                {error &&
                    <Alert variant='danger'>{error}</Alert>
                }

                {errors.length > 0 && errors.map((error, index) => {
                    return <Alert key={`error${index}`} variant='danger'>{error}</Alert>
                })}

                <div style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-end'}}>
                    {!message && !error &&
                        <Button
                            variant='text'
                            onClick={this.close.bind(this)}
                        >
                            Annuleren
                        </Button>
                    }

                    {!message && !error &&
                        <Button
                            variant='text'
                            loading={loading}
                            onClick={this.submit.bind(this)}
                        >
                            Bevestigen
                        </Button>
                    }

                    {(message || error) &&
                        <Button
                            style={{marginBottom: 0}}
                            variant='text'
                            onClick={this.close.bind(this)}
                        >
                            Sluiten
                        </Button>
                    }
                </div>
            </Modal>
        )
    }
}
