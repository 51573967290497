import React from 'react'
import Reflux from 'reflux-react-16'

import {Route} from 'react-router-dom'

import LoginStore from '../../stores/LoginStore.js'
import LoginActions from '../../actions/LoginActions.js'

import {AlertPopup, Colors, FlexColumn, MfaPopup, NavBar, NavBarLink, NavBarMenu, Popup} from '../../components/UI/index.js'
import LoginPopup from '../../components/login/LoginPopup.js'
import Parcels from './Parcels.js'
import Settings from './settings/Settings.js'
import LightSpeedInstall from './lightspeed/Install.js'
import LightSpeedUninstall from './lightspeed/Uninstall.js'
import ShopifyInstall from './shopify/Install.js'
import NotificationsPopup from '../../components/communication/NotificationsPopup.js'
import NotificationsPage from '../../components/communication/NotificationsPage.js'

export default class Layout extends Reflux.Component {
    constructor(props) {
        super(props)

        this.store = LoginStore
    }

    componentDidMount() {
        const {history} = this.props

        document.title = 'Klantportaal'

        LoginActions.loggedIn((loggedIn) => {
            if (!loggedIn) {
                const redirect = encodeURIComponent(`${history.location.pathname}${history.location.search}`)

                if (redirect && redirect !== '%2F') {
                    history.push(`/login?redirect=${redirect}`)
                } else {
                    history.push('/login')
                }
            } else {
                if (history.location.pathname === '/klant' || history.location.pathname === '/klant/') {
                    history.push('/klant/zendingen')
                }

                LoginActions.checkNotifications(() => {
                    this.notificationsPopup.checkIfNewNotification()
                })
            }
        })
    }

    onClickHelp() {
        window.open('https://veloyd.nl/klant-handleiding/', '_blank')
    }

    onClickSwitchAccount() {
        const {accounts, user} = this.state

        this.loginPopup.open(accounts, user, (index) => {
            const account = accounts[index]

            if (account.mfaRequired && !user.is2FASignedIn) {
                if (account.mfaEnabled) {
                    if (account.mfaMethod === 'email') {
                        LoginActions.mailMFACode(null, index, null, (err) => {
                            if (err) {
                                this.mfaPopup.setError(err)
                            }
                        })
                    }

                    this.mfaPopup.open((code) => {
                        LoginActions.switchAccount(index, code, (err) => {
                            if (err) {
                                this.mfaPopup.setError(err)
                            }
                        })
                    }, account.mfaMethod)
                } else {
                    this.alertPopup.open('Tweestapsverificatie nog niet ingesteld.', 'Er is nog geen twee-stapsverificatie ingesteld op dit account, maar het is wel verplicht. Log uit en vervolgens weer in op het betreffende account om het proces voor het instellen van tweestapsverificatie te starten.')
                    this.alertPopup.setAction('Uitloggen', () => {
                        LoginActions.logout()
                    })
                }
            } else {
                LoginActions.switchAccount(index, null, (err, reseller) => {
                    if (err) {
                        this.setState({loading: false, error: err})

                        if ((!account.isMessenger && reseller.settings.mfaWebEnabled) || (account.isMessenger && reseller.settings.mfaAppEnabled)) {
                            this.alertPopup.open('Tweestapsverificatie nog niet ingesteld.', 'Er is nog geen twee-stapsverificatie ingesteld op dit account, maar het is wel verplicht. Log uit en vervolgens weer in op het betreffende account om het proces voor het instellen van tweestapsverificatie te starten.')
                            this.alertPopup.setAction('Uitloggen', () => {
                                LoginActions.logout()
                            })
                        }
                    }
                })
            }
        })
    }

    onClickLogout() {
        this.popup.open('Uitloggen', 'Weet je zeker dat je wilt uitloggen?', () => LoginActions.logout())
    }

    render() {
        const {reseller, user, accounts, notifications, branding} = this.state
        const {history} = this.props
        const {pathname} = history.location

        return (
            <FlexColumn style={{height: '100vh', minWidth: 1366, paddingTop: 60}}>
                <NavBar branding={branding}>
                    <NavBarLink
                        history={history}
                        active={pathname === '/klant/zendingen'}
                        link={{icon: 'mdi mdi-package-variant-closed', title: 'Zendingen', target: '/klant/zendingen'}}
                        branding={branding}
                    />

                    <div style={{flex: 1}} />

                    <NavBarLink
                        history={history}
                        link={{icon: 'mdi mdi-help-circle', onClick: this.onClickHelp.bind(this)}}
                        branding={branding}
                    />

                    <NavBarLink
                        history={history}
                        active={pathname.indexOf('/klant/instellingen') > -1}
                        link={{icon: 'mdi mdi-cog', title: '', target: '/klant/instellingen'}}
                        branding={branding}
                    />

                    <NavBarMenu
                        history={history}
                        links={[
                            {title: 'Meldingen', icon: 'mdi mdi-message-outline', target: '/klant/meldingen'},
                            accounts.length > 1 && {title: 'Wissel account', icon: 'mdi mdi-account-multiple', onClick: this.onClickSwitchAccount.bind(this)},
                            {title: 'Uitloggen', icon: 'mdi mdi-logout', onClick: this.onClickLogout.bind(this)}
                        ]}
                        link={{icon: 'mdi mdi-account-circle', title: user.name}}
                    />
                </NavBar>

                <FlexColumn style={{flex: 1, background: Colors.backgroundNeutral}}>
                    {user.isCustomer &&
                        <Route path='/klant/zendingen' render={(props) => <Parcels {...props} user={user} reseller={reseller} />} />
                    }
                    {user.isCustomer &&
                        <Route path='/klant/instellingen' render={(props) => <Settings {...props} user={user} reseller={reseller} />} />
                    }
                    {user.isCustomer &&
                        <Route path='/klant/lightspeed/install' render={(props) => <LightSpeedInstall {...props} user={user} reseller={reseller} />} />
                    }
                    {user.isCustomer &&
                        <Route path='/klant/lightspeed/uninstall' render={(props) => <LightSpeedUninstall {...props} user={user} reseller={reseller} />} />
                    }
                    {user.isCustomer &&
                        <Route path='/klant/shopify/install' render={(props) => <ShopifyInstall {...props} user={user} reseller={reseller} />} />
                    }
                    {user.isCustomer &&
                        <Route path='/klant/meldingen' render={(props) => <NotificationsPage {...props} user={user} notifications={notifications} reseller={reseller} />} />
                    }
                </FlexColumn>

                <LoginPopup ref={(ref) => this.loginPopup = ref} />
                <Popup ref={(ref) => this.popup = ref} />
                <MfaPopup ref={(ref) => this.mfaPopup = ref} />
                <AlertPopup ref={(ref) => this.alertPopup = ref} />

                <NotificationsPopup
                    user={user}
                    onClickReadNotifications={LoginActions.updateReadNotifications}
                    notifications={notifications}
                    ref={(ref) => this.notificationsPopup = ref}
                />
            </FlexColumn>
        )
    }
}
