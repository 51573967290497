import React, {CSSProperties, ReactNode} from 'react'

interface ScrollViewProps {
    children: ReactNode
    style?: CSSProperties
}

class ScrollView extends React.Component<ScrollViewProps> {
    render() {
        const {children, style} = this.props

        const defaultStyle: CSSProperties = {
            display: 'flex',
            flexDirection: 'column',
            flex: 1,
            overflowY: 'auto',
            width: '100%'
        }

        if (style) {
            Object.keys(style).map((key) => {
                defaultStyle[key] = style[key]
            })
        }

        return (
            <div style={defaultStyle}>
                {children}
            </div>

        )
    }
}

export default (ScrollView)
