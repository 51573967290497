import React from 'react'

import {Colors, Input, MetricInput} from '../UI/index.js'
import {Button, IconButton, Modal, H4} from '../UI/index.js'
import _ from 'lodash'

class CategoryModal extends React.Component {
    constructor(props) {
        super(props)
        this.initialState = {
            open: false,
            category: {
                name: '',
                price: '0,00',
                maxWeight: '0',
                length: 0,
                width: 0,
                height: 0,
                volume: 0,
                productCode: ''
            }
        }
        this.state = this.initialState
    }

    open(carrier, product, i, j, category) {
        this.setState({open: true, carrier, product, i, j, category})
    }

    close() {
        this.setState(this.initialState)
    }

    onChangeMaxWeight(event) {
        if (/^$|^[-]{0,1}[0-9]{0,3}$|^[-]{0,1}[0-9]{0,3}[,][0-9]{0,3}$/.test(event.target.value)) {
            const {category} = this.state
            category.maxWeight = event.target.value
            this.setState({category})
        }
    }

    onChangeValue(event, path, parser) {
        const category = {...this.state.category}

        let value = event.target.value

        if (typeof parser === 'function') {
            value = parser(event.target.value)
        }

        _.set(category, path, value)

        this.setState({category})
    }

    onRemove() {
        const {carrier, product, i, j} = this.state
        const {remove} = this.props
        remove(carrier, product, i, j)
        this.close()
    }

    onSubmit() {
        const {carrier, product, i, j, category} = this.state
        const {onChange} = this.props

        // Sort dimensions: length > width > height
        const [length, width, height] = [category.length || 0, category.width || 0, category.height || 0].sort((a, b) => b - a)

        category.length = length
        category.width = width
        category.height = height

        onChange(carrier, product, i, j, category)
        this.close()
    }

    render() {
        const {open, category} = this.state

        return (
            <Modal style={{width: 400}} show={open} onClose={this.close.bind(this)}>
                <div style={{display: 'flex', justifyContent: 'space-between'}}>
                    <H4>Wijzig categorie</H4>

                    <IconButton onClick={this.close.bind(this)}>
                        <i style={{color: Colors.buttonSolid}} className='mdi mdi-close' />
                    </IconButton>
                </div>

                <Input
                    label='Naam'
                    value={category.name || ''}
                    onChange={(event) => this.onChangeValue(event, 'name')}
                />

                <Input
                    label='Gewicht'
                    value={category.maxWeight}
                    onChange={this.onChangeMaxWeight.bind(this)}
                    append='kg'
                />

                <MetricInput
                    label='Lengte'
                    value={category.length}
                    divisionFactor={10}
                    onChange={(event) => this.onChangeValue(event, 'length', parseInt)}
                    append='cm'
                />

                <MetricInput
                    label='Breedte'
                    value={category.width}
                    divisionFactor={10}
                    onChange={(event) => this.onChangeValue(event, 'width', parseInt)}
                    append='cm'
                />

                <MetricInput
                    label='Hoogte'
                    value={category.height}
                    divisionFactor={10}
                    onChange={(event) => this.onChangeValue(event, 'height', parseInt)}
                    append='cm'
                />

                <MetricInput
                    label='Volume'
                    value={category.volume}
                    onChange={(event) => this.onChangeValue(event, 'volume', parseInt)}
                    append='L'
                />

                <br />
                <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                    <Button variant='text' onClick={this.onRemove.bind(this)}>Verwijder</Button>
                    <Button variant='text' onClick={this.onSubmit.bind(this)}>Opslaan</Button>
                </div>
            </Modal>
        )
    }
}

export default CategoryModal
