import React from 'react'
import moment from 'moment'
import {RRule} from 'rrule'

import SubscriptionActions from '../../actions/SubscriptionActions.js'

import {Input, TextArea, CurrencyInput, DatePicker, Link, Recurrence, SelectCustomer, Check, Row, Column, Spinner} from '../UI/index.js'
import {Alert, Button, Colors, Modal, S2, P, ScrollView} from '../UI/index.js'
import NewSubscription from './NewSubscription.js'

class SubscriptionModal extends React.Component {
    constructor(props) {
        super(props)

        this.initialState = {
            modalIsOpen: false,
            subscriptionLoading: false,
            subscriptionError: null,
            subscription: NewSubscription(),
            firstInvoiceDate: moment().format('YYYY-MM-DD'),
            error: '',
            loading: false
        }

        this.state = structuredClone(this.initialState)
    }

    open(id) {
        if (id) {
            id = id.split(' ')[0]

            this.setState({modalIsOpen: true, subscriptionLoading: true})

            SubscriptionActions.getOne(id, (err, subscription) => {
                if (err) {
                    this.setState({subscriptionLoading: false, subscriptionError: err})
                } else {
                    console.log(subscription)

                    this.setState({
                        subscriptionLoading: false,
                        subscriptionError: null,
                        subscription,
                        firstInvoiceDate: moment(RRule.fromString(subscription.rrule).options.dtstart).format('YYYY-MM-DD')
                    })
                }
            })
        } else {
            this.setState({modalIsOpen: true}, () => {
                const subscription = NewSubscription()
                subscription.rrule = this.recurrence.init()

                this.setState({subscription})
            })
        }
    }

    close() {
        this.setState(this.initialState)
    }

    onChangeCustomer({customer}) {
        if (customer) {
            const subscription = {...this.state.subscription}

            subscription.customer = customer._id

            this.setState({subscription})
        }
    }

    onChangeValue(key, event) {
        const {subscription} = this.state
        subscription[key] = event.target.value
        this.setState({subscription})
    }

    onChangeNoEndDate(event) {
        const {subscription} = this.state

        if (event.target.checked) {
            subscription.endDate = '2999-12-31'
        } else {
            subscription.endDate = moment().format('YYYY-MM-DD')
        }

        this.setState({subscription})
    }

    onSubmit(event) {
        event.preventDefault()
        const {subscription} = this.state
        const customerValid = !this.selectCustomer || this.selectCustomer.validate()

        if (customerValid) {
            this.setState({loading: true, error: ''})

            if (subscription._id) {
                SubscriptionActions.edit(subscription, this.response.bind(this))
            } else {
                SubscriptionActions.create(subscription, this.response.bind(this))
            }
        }
    }

    response(err) {
        if (err) {
            this.setState({loading: false, error: err})
        } else {
            this.close()
        }
    }

    render() {
        const {modalIsOpen, subscriptionLoading, subscriptionError, subscription, firstInvoiceDate, error, loading} = this.state
        const {history, customers} = this.props

        return (
            <Modal
                show={modalIsOpen}
                title={subscription?._id ? 'Abonnement info' : 'Nieuw Abonnement'}
                onClose={this.close.bind(this)}
                closeButton
            >

                {subscriptionLoading ?
                        <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: 250}}>
                            <Spinner name='ball-clip-rotate' fadeIn='none' color={Colors.textDark} />
                        </div> :
                        <>
                            <form onSubmit={this.onSubmit.bind(this)}>
                                <Row>
                                    <Column>
                                        <SelectCustomer
                                            value={subscription.customer}
                                            customers={customers}
                                            onChange={this.onChangeCustomer.bind(this)}
                                            ref={(ref) => this.selectCustomer = ref}
                                        />
                                    </Column>

                                    <Column>
                                        <Input
                                            label='Referentie'
                                            value={subscription.reference}
                                            onChange={this.onChangeValue.bind(this, 'reference')}
                                        />
                                    </Column>
                                </Row>

                                <Row>
                                    <Column>
                                        <Input
                                            label='Omschrijving'
                                            value={subscription.description}
                                            onChange={this.onChangeValue.bind(this, 'description')}
                                        />
                                    </Column>

                                    <Column>
                                        <CurrencyInput
                                            label='prijs'
                                            value={subscription.price}
                                            onChange={this.onChangeValue.bind(this, 'price')}
                                        />
                                    </Column>
                                </Row>

                                <Row style={{marginTop: 12}}>
                                    <Column>
                                        <DatePicker
                                            disabled={subscription._id}
                                            label='Eerste factuur datum'
                                            value={firstInvoiceDate}
                                            onChange={() => this.setState({firstInvoiceDate: event.target.value})}
                                        />
                                    </Column>

                                    <Column>
                                        <Recurrence
                                            type='subscription'
                                            disabled={subscription._id}
                                            rrule={subscription.rrule}
                                            date={firstInvoiceDate}
                                            onChange={this.onChangeValue.bind(this, 'rrule')}
                                            ref={(ref) => this.recurrence = ref}
                                        />
                                    </Column>
                                </Row>

                                <Row>
                                    <Column>
                                        <DatePicker
                                            label='Begindatum'
                                            value={subscription.startDate}
                                            disabled={subscription._id}
                                            onChange={this.onChangeValue.bind(this, 'startDate')}
                                        />
                                    </Column>

                                    <Column>
                                        <DatePicker
                                            label='Einddatum'
                                            value={subscription.endDate}
                                            disabled={subscription.endDate === '2999-12-31'}
                                            onChange={this.onChangeValue.bind(this, 'endDate')}
                                        />
                                    </Column>
                                </Row>

                                <Row style={{marginTop: -6}}>
                                    <Column />
                                    <Column>
                                        <Check
                                            label='Geen einddatum'
                                            checked={subscription.endDate === '2999-12-31'}
                                            onChange={this.onChangeNoEndDate.bind(this)}
                                        />
                                    </Column>
                                </Row>

                                <Row style={{marginTop: 12}}>
                                    <Column>
                                        <TextArea
                                            rows={3}
                                            label='Opmerkingen'
                                            value={subscription.notes}
                                            onChange={this.onChangeValue.bind(this, 'notes')}
                                        />
                                    </Column>
                                </Row>

                                {subscription._id && subscription.history &&
                                    <ScrollView style={{marginTop: 24, maxHeight: 250}}>
                                        {[...subscription.history].reverse().map((entry, index) => {
                                            return (
                                                <div key={`history${index}`} style={{display: 'flex', alignItems: 'center', padding: 5}}>
                                                    <div style={{height: 15, width: 15, borderRadius: '50%', marginRight: 15, background: Colors.grey20}} />
                                                    <div style={{flex: 1}}>
                                                        {entry.type === 'invoice' ?
                                                                <Link onClick={() => history.push('/facturatie', {invoiceId: entry.invoiceId})}>{entry.description}</Link> :
                                                                <P>{entry.description}</P>
                                                        }
                                                    </div>
                                                    <S2 style={{marginRight: 15}}>{`${entry.who} | ${entry.timestamp ? moment(entry.timestamp).format('DD-MM-YYYY HH:mm:ss') : entry.time}`}</S2>
                                                </div>
                                            )
                                        })}
                                    </ScrollView>
                                }

                                {error && <Alert variant='danger'>{error}</Alert>}

                                <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                                    <Button loading={loading} type='submit'>Opslaan</Button>
                                </div>
                            </form>
                        </>
                }

                {subscriptionError &&
                    <Alert variant='danger'>{subscriptionError}</Alert>
                }
            </Modal>
        )
    }
}

export default (SubscriptionModal)
