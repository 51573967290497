import React, {CSSProperties, PureComponent, ReactNode} from 'react'

import {Colors} from '../index.js'

interface H3Props {
    id?: string
    children: ReactNode
    style?: CSSProperties
    ellipsis?: boolean
    onClick?: () => void
    onMouseDown?: () => void
    onMouseUp?: () => void
    onMouseEnter?: () => void
    onMouseLeave?: () => void
}

class H3 extends PureComponent<H3Props> {
    render() {
        const {id, children, style, ellipsis} = this.props

        const defaultStyle: CSSProperties = {
            fontSize: 24,
            fontWeight: 300, // normal
            letterSpacing: 0,
            color: Colors.textDark,
            marginBottom: 48
        }

        if (ellipsis) {
            defaultStyle.overflow = 'hidden'
            defaultStyle.whiteSpace = 'nowrap'
            defaultStyle.textOverflow = 'ellipsis'
        }

        if (style) {
            Object.keys(style).map((key) => {
                defaultStyle[key] = style[key]
            })
        }

        return (
            <div
                id={id}
                style={defaultStyle}
                onClick={this.props.onClick || null}
                onMouseDown={this.props.onMouseDown || null}
                onMouseUp={this.props.onMouseUp || null}
                onMouseEnter={this.props.onMouseEnter || null}
                onMouseLeave={this.props.onMouseLeave || null}
            >
                {children}
            </div>
        )
    }
}

export default (H3)
