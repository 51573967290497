import React, {useEffect, useReducer} from 'react'

import LoginActions from '../../../../actions/LoginActions.js'
import reducer from '../../../../helpers/reducer.js'

import {H3, H6, Divider, Toggle, Input, Button, Select, Alert} from '../../../../components/UI/index.js'

const initialState = {
    loading: false,
    verified: false,
    error: ''
}

export default function ExactOnlineSettings({user, onChange}) {
    const [{loading, verified, error}, dispatch] = useReducer(reducer, initialState)

    function onChangeChecked(key, event) {
        user.settings.webshops.exactOnline[key] = event.target.checked

        onChange(user)
    }

    function onChangeValue(key, event) {
        user.settings.webshops.exactOnline[key] = event.target.value

        onChange(user)
    }

    function onVerifyConnection() {
        dispatch({loading: true, verified: false, error: ''})

        onChange(user, true)

        setTimeout(() => { // wait for saving
            LoginActions.verifyWebshop('exactonline', (err) => {
                if (err) {
                    dispatch({loading: false, error: `Verificatie niet succesvol, controleer de ingevulde gegevens. Foutmelding: ${err}`})
                } else {
                    dispatch({loading: false, verified: true})
                }
            })
        }, 1000)
    }

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search)
        const code = searchParams.get('code')
        if (code) {
            LoginActions.installExactOnline(code, (err) => {
                if (err) {
                    dispatch({error: err})
                }
            })
        }
    }, [])

    return (
        <div>
            <H3>Exact Online</H3>

            <H6>Instellingen</H6>
            <Divider />

            {!user.settings.webshops.exactOnline.accessToken &&
                <Button onClick={() => location.replace('https://start.exactonline.nl/api/oauth2/auth?client_id=be63d162-d78d-4b52-a608-c4cbe0b7eb5e&redirect_uri=https://app.veloyd.nl/klant/instellingen/koppelingen/webshops/exact&response_type=code')}>Installeer Exact</Button>
            }

            {user.settings.webshops.exactOnline.accessToken &&
                <>
                    <Toggle
                        label='Actief'
                        checked={user.settings.webshops.exactOnline.active}
                        onChange={(event) => onChangeChecked('active', event)}
                    />
                    <Input
                        label='Access Token'
                        value={user.settings.webshops.exactOnline.accessToken}
                        onChange={(event) => onChangeValue('accessToken', event)}
                        disabled
                    />

                    <Input
                        label='Refresh Token'
                        value={user.settings.webshops.exactOnline.refreshToken}
                        onChange={(event) => onChangeValue('refreshToken', event)}
                        disabled
                    />

                    <Select
                        label='Update order status in Exact'
                        info=''
                        value={user.settings.webshops.exactOnline.updateStatus}
                        onChange={(event) => onChangeValue('updateStatus', event)}
                    >
                        <option value=''>Nooit</option>
                        <option value='confirm'>Na bevestigen</option>
                        <option value='afterSent'>Na verzenden</option>
                    </Select>

                    <Button
                        variant='text'
                        style={{marginBottom: 12, marginLeft: 0}}
                        loading={loading}
                        onClick={onVerifyConnection}
                    >
                        Verifieer
                    </Button>

                    {error && (
                        <Alert onClose={() => dispatch({error: ''})} variant='error'>{error}</Alert>
                    )}

                    {!error && verified && (
                        <Alert onClose={() => dispatch({verified: false})}>Verificatie succesvol.</Alert>
                    )}
                </>
            }
        </div>
    )
}
