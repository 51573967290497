import React from 'react'

import {Colors, Input} from '../UI/index.js'
import {Button, IconButton, Modal, H4} from '../UI/index.js'

class FAQModal extends React.Component {
    constructor(props) {
        super(props)
        this.initialState = {
            modalIsOpen: false,
            newFAQ: true,
            faq: {question: '', answer: ''},
            validation: {}
        }

        this.state = JSON.parse(JSON.stringify(this.initialState))
    }

    open(faq, index) {
        faq = faq ? JSON.parse(JSON.stringify(faq)) : {question: '', answer: ''}

        this.setState({
            modalIsOpen: true,
            newFAQ: typeof index === 'number',
            index,
            faq
        })
    }

    close() {
        this.setState(this.initialState)
    }

    onChangeValue(key, event) {
        const faq = {...this.state.faq}
        faq[key] = event.target.value
        this.setState({faq})
    }

    onChangeChecked(key, event) {
        const faq = {...this.state.faq}
        faq[key] = event.target.checked
        this.setState({faq})
    }

    onValidate() {
        const faq = {...this.state.faq}
        const validation = {}
        let id = ''
        let valid = true

        if (!faq.question) {
            id = 'question'
            validation.question = true
            valid = false
        }

        if (!faq.answer) {
            id = 'answer'
            validation.answer = true
            valid = false
        }

        if (!valid) {
            const element = document.getElementById(id)
            if (element) {
                element.focus()
            }
        }

        this.setState({validation})
        return valid
    }

    onRemove(event) {
        event.preventDefault()

        this.props.onRemove(this.state.index)
        this.close()
    }

    onSubmit(event) {
        event.preventDefault()

        if (this.onValidate()) {
            this.props.onSubmit(this.state.faq, this.state.index)
            this.close()
        }
    }

    render() {
        const {modalIsOpen, faq, validation} = this.state

        return (
            <Modal show={modalIsOpen} onClose={this.close.bind(this)}>
                <div style={{display: 'flex', justifyContent: 'space-between'}}>
                    <H4>Bewerk FAQ</H4>

                    <IconButton onClick={this.close.bind(this)}>
                        <i style={{color: Colors.buttonSolid}} className='mdi mdi-close' />
                    </IconButton>
                </div>

                <form onSubmit={this.onSubmit.bind(this)}>

                    <Input
                        id='question'
                        label='Vraag'
                        value={faq.question}
                        isInvalid={validation.question}
                        onChange={this.onChangeValue.bind(this, 'question')}
                    />

                    <Input
                        id='answer'
                        label='Antwoord'
                        value={faq.answer}
                        isInvalid={validation.answer}
                        onChange={this.onChangeValue.bind(this, 'answer')}
                    />

                    <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                        <Button variant='error' onClick={this.onRemove.bind(this)}>
                            Verwijder
                        </Button>

                        <Button type='submit'>
                            Opslaan
                        </Button>
                    </div>
                </form>
            </Modal>
        )
    }
}

export default (FAQModal)
