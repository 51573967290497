import React from 'react'

import {Input} from '../UI/index.js'

class FloatInput extends React.Component {
    onChange(event) {
        if (/^$|^[-]{0,1}[0-9]{0,5}$|^[-]{0,1}[0-9]{0,5}[,][0-9]{0,2}$/.test(event.target.value)) {
            const {onChange} = this.props
            onChange(event)
        }
    }

    render() {
        const {label, value, prepend, append} = this.props

        return (
            <Input
                {...this.props}
                label={label}
                value={value}
                onChange={this.onChange.bind(this)}
                className={prepend}
                append={append}
            />
        )
    }
}

export default (FloatInput)
