import React from 'react'
import Spinner from './Spinner.js'

export default class LoadingPage extends React.Component {
    render() {
        const {style} = this.props

        const defaultStyle = {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            width: '100%'
        }

        style && Object.keys(style).map((key) => {
            defaultStyle[key] = style[key]
        })

        const loaderWrapperStyle = {
            position: 'fixed',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            top: 0,
            width: '100%',
            height: '100%',
            background: '#fff',
            zIndex: 1000
        }

        return (
            <div style={defaultStyle}>
                <div style={loaderWrapperStyle}>
                    <Spinner name='ball-clip-rotate' fadeIn='none' />
                </div>
            </div>

        )
    }
}
