import React from 'react'
import Reflux from 'reflux-react-16'
import _ from 'lodash'
import {Route} from 'react-router-dom'

import LoginStore from '../../stores/LoginStore.js'
import LoginActions from '../../actions/LoginActions.js'

import {AlertPopup, Colors, FlexColumn, MfaPopup, NavBar, NavBarLink, NavBarMenu, Popup} from '../../components/UI/index.js'
import LoginPopup from '../../components/login/LoginPopup.js'
import Dashboard from './Dashboard.js'
import Packages from './Packages.js'
import Parcels from './Parcels.js'
import Shifts from './Shifts.js'
import Customers from './Customers.js'
import Users from './Users.js'
import Invoices from './Invoices.js'
import Subscriptions from './Subscriptions.js'
import Settings from './settings/Settings.js'
import NotificationsPopup from '../../components/communication/NotificationsPopup.js'
import NotificationsPage from '../../components/communication/NotificationsPage.js'
import AddressBook from './AddressBook.js'
import Vehicles from './Vehicles.js'

export default class Layout extends Reflux.Component {
    constructor(props) {
        super(props)

        this.store = LoginStore
    }

    componentDidMount() {
        const {history} = this.props

        LoginActions.loggedIn((loggedIn) => {
            if (!loggedIn) {
                const redirect = encodeURIComponent(`${history.location.pathname}${history.location.search}`)

                if (redirect && redirect !== '%2F') {
                    history.push(`/login?redirect=${redirect}`)
                } else {
                    history.push('/login')
                }
            } else {
                if (history.location.pathname === '/') {
                    history.push('/planning')
                }

                LoginActions.checkNotifications(() => {
                    this.notificationsPopup.checkIfNewNotification()
                })
            }
        })
    }

    onClickHelp() {
        window.open('https://veloyd.nl/handleiding/', '_blank')
    }

    onClickSwitchAccount() {
        const {accounts, user} = this.state

        this.loginPopup.open(accounts, user, (index) => {
            const account = accounts[index]

            if ((account.mfaRequired && !user.is2FASignedIn)) {
                if (account.mfaEnabled) {
                    if (account.mfaMethod === 'email') {
                        LoginActions.mailMFACode(null, index, null, (err) => {
                            if (err) {
                                this.mfaPopup.setError(err)
                            }
                        })
                    }

                    this.mfaPopup.open((code) => {
                        LoginActions.switchAccount(index, code, (err) => {
                            if (err) {
                                this.mfaPopup.setError(err)
                            }
                        })
                    }, account.mfaMethod)
                } else {
                    this.alertPopup.open('Tweestapsverificatie nog niet ingesteld.', 'Er is nog geen twee-stapsverificatie ingesteld op dit account, maar het is wel verplicht. Log uit en vervolgens weer in op het betreffende account om het proces voor het instellen van tweestapsverificatie te starten.')
                    this.alertPopup.setAction('Uitloggen', () => {
                        LoginActions.logout()
                    })
                }
            } else {
                LoginActions.switchAccount(index, null, (err, reseller) => {
                    if (err) {
                        this.setState({loading: false, error: err})

                        if ((!account.isMessenger && reseller.settings.mfaWebEnabled) || (account.isMessenger && reseller.settings.mfaAppEnabled)) {
                            this.alertPopup.open('Tweestapsverificatie nog niet ingesteld.', 'Er is nog geen twee-stapsverificatie ingesteld op dit account, maar het is wel verplicht. Log uit en vervolgens weer in op het betreffende account om het proces voor het instellen van tweestapsverificatie te starten.')
                            this.alertPopup.setAction('Uitloggen', () => {
                                LoginActions.logout()
                            })
                        }
                    }
                })
            }
        })
    }

    onClickLogout() {
        this.popup.open('Uitloggen', 'Weet je zeker dat je wilt uitloggen?', () => LoginActions.logout())
    }

    updateReseller(reseller, path, unsetPath) {
        this.setState({reseller: {...reseller}, saved: false}, () => {
            if (path) {
                const value = _.get(reseller.settings, path)

                LoginActions.updateResellerSettings(path, value, unsetPath, (err) => {
                    if (!err) {
                        clearTimeout(this.state.timeout)
                        const timeout = setTimeout(() => this.setState({saved: false}), 3000)
                        this.setState({saved: true, timeout})
                    } else {
                        this.setState({error: err})
                    }
                })
            }
        })
    }

    render() {
        const {reseller, user, accounts, notifications, branding} = this.state
        const {history} = this.props
        const {pathname} = history.location

        // important
        user.permissions = user.permissions || {}

        return (
            <FlexColumn style={{minHeight: '100vh', minWidth: 1366, paddingTop: 60, ...(pathname === '/meldingen' && {height: 'unset'})}}>
                <NavBar branding={branding}>
                    {reseller.permissions.orders && user.permissions.orders &&
                        <>
                            <NavBarLink
                                history={history}
                                active={pathname === '/planning'}
                                link={{icon: 'mdi mdi-map-clock', title: 'Planning', target: '/planning'}}
                                branding={branding}
                            />
                        </>
                    }

                    {reseller.permissions.parcels && user.permissions.parcels &&
                        <NavBarLink
                            history={history}
                            active={pathname === '/zendingen'}
                            link={{icon: 'mdi mdi-package-variant-closed', title: 'Zendingen', target: '/zendingen'}}
                            branding={branding}
                        />
                    }

                    {((reseller.permissions.users && user.permissions.users) || (reseller.permissions.vehicles && user.permissions.vehicles) || (reseller.permissions.orders && user.permissions.orders) || (reseller.permissions.shifts && user.permissions.shifts) || (reseller.permissions.paychecks && user.permissions.paychecks)) &&
                        <NavBarLink
                            history={history}
                            active={pathname === '/gebruikers' || pathname === '/voertuigen' || pathname === '/emballage' || pathname === '/diensten'}
                            link={{icon: 'mdi mdi-account-group', title: 'Beheer'}}
                            links={[
                                reseller.permissions.users && user.permissions.users && {icon: 'mdi mdi-account-group', title: 'Gebruikers', target: '/gebruikers', active: pathname === '/gebruikers'},
                                reseller.permissions.vehicles && user.permissions.vehicles && {icon: 'mdi mdi-bicycle-cargo', title: 'Voertuigen', target: '/voertuigen', active: pathname === '/voertuigen'},
                                (reseller.name === 'Tour de Ville Eindhoven' || reseller.name === 'Veloyd') && reseller.permissions.orders && user.permissions.orders && {icon: 'mdi mdi-package-variant', title: 'Emballage', target: '/emballage', active: pathname === '/emballage'},
                                reseller.permissions.shifts && user.permissions.shifts && {icon: 'mdi mdi-calendar-clock', title: 'Diensten', target: '/diensten', active: pathname === '/diensten'}
                            ]}
                            branding={branding}
                        />
                    }

                    {(Object.keys(reseller.permissions).some((key) => reseller.permissions[key]) && Object.keys(user.permissions).some((key) => user.permissions[key])) && (
                        <NavBarLink
                            history={history}
                            active={pathname === '/abonnementen' || pathname === '/facturatie' || pathname === '/klanten'}
                            link={{icon: 'mdi mdi-finance', title: 'Administratie'}}
                            links={[
                                reseller.permissions.subscriptions && user.permissions.subscriptions && {icon: 'mdi mdi-calendar-sync', title: 'Abonnementen', target: '/abonnementen', active: pathname === '/abonnementen'},
                                reseller.permissions.invoices && user.permissions.invoices && {icon: 'mdi mdi-receipt', title: 'Facturatie', target: '/facturatie', active: pathname === '/facturatie'},
                                reseller.permissions.customers && user.permissions.customers && {icon: 'mdi mdi-store', title: 'Klanten', target: '/klanten', active: pathname === '/klanten'},
                                (Object.keys(reseller.permissions).some((key) => reseller.permissions[key]) && Object.keys(user.permissions).some((key) => user.permissions[key])) && {icon: 'mdi mdi-book-account-outline', title: 'Adresboek', target: '/adresboek', active: pathname === '/adresboek'}
                            ]}
                            branding={branding}
                        />
                    )}

                    <div style={{flex: 1}} />

                    <NavBarLink
                        history={history}
                        link={{icon: 'mdi mdi-help-circle', onClick: this.onClickHelp.bind(this)}}
                        branding={branding}
                    />

                    {user.permissions.settings &&
                        <NavBarLink
                            history={history}
                            active={pathname.indexOf('/instellingen') > -1}
                            link={{icon: 'mdi mdi-cog', target: '/instellingen'}}
                            branding={branding}
                        />
                    }

                    <NavBarMenu
                        history={history}
                        links={[
                            {title: 'Meldingen', icon: 'mdi mdi-message-outline', target: '/meldingen'},
                            accounts.length > 1 && {title: 'Wissel account', icon: 'mdi mdi-account-multiple', onClick: this.onClickSwitchAccount.bind(this)},
                            {title: 'Uitloggen', icon: 'mdi mdi-logout', onClick: this.onClickLogout.bind(this)}
                        ]}
                        link={{icon: 'mdi mdi-account-circle', title: user.name, subTitle: reseller.settings?.accountName || reseller.name}}
                        branding={branding}
                    />
                </NavBar>

                <FlexColumn style={{flex: 1, background: Colors.backgroundNeutral}}>
                    {reseller.permissions.orders && user.permissions.orders &&
                        <>
                            <Route path='/planning/:orderId?' render={(props) => <Dashboard {...props} user={user} reseller={reseller} onUpdateReseller={this.updateReseller.bind(this)} />} />
                            <Route path='/emballage/:id?' render={(props) => <Packages {...props} user={user} reseller={reseller} />} />
                        </>
                    }
                    {reseller.permissions.parcels && user.permissions.parcels &&
                        <Route path='/zendingen/:parcelId?' render={(props) => <Parcels {...props} user={user} reseller={reseller} />} />
                    }
                    {reseller.permissions.shifts && user.permissions.shifts &&
                        <Route path='/diensten' render={(props) => <Shifts {...props} user={user} reseller={reseller} />} />
                    }
                    {reseller.permissions.customers && user.permissions.customers &&
                        <Route path='/klanten' render={(props) => <Customers {...props} user={user} reseller={reseller} />} />
                    }
                    {reseller.permissions.users && user.permissions.users &&
                        <Route path='/gebruikers' render={(props) => <Users {...props} user={user} reseller={reseller} />} />
                    }
                    {reseller.permissions.vehicles && user.permissions.vehicles &&
                        <Route path='/voertuigen' render={(props) => <Vehicles {...props} user={user} reseller={reseller} />} />
                    }
                    {reseller.permissions.invoices && user.permissions.invoices &&
                        <Route path='/facturatie' render={(props) => <Invoices {...props} user={user} reseller={reseller} />} />
                    }
                    {(Object.keys(reseller.permissions).some((key) => reseller.permissions[key]) && Object.keys(user.permissions).some((key) => user.permissions[key])) &&
                        <Route path='/adresboek' render={(props) => <AddressBook {...props} user={user} reseller={reseller} />} />
                    }

                    {reseller.permissions.subscriptions && user.permissions.subscriptions &&
                        <Route path='/abonnementen' render={(props) => <Subscriptions {...props} user={user} reseller={reseller} />} />
                    }
                    <Route path='/meldingen' render={(props) => <NotificationsPage {...props} user={user} notifications={notifications} reseller={reseller} />} />

                    {user.permissions.settings &&
                        <Route path='/instellingen' render={(props) => <Settings {...props} user={user} reseller={reseller} />} />
                    }
                </FlexColumn>

                <LoginPopup ref={(ref) => this.loginPopup = ref} />
                <Popup ref={(ref) => this.popup = ref} />
                <MfaPopup ref={(ref) => this.mfaPopup = ref} />
                <AlertPopup ref={(ref) => this.alertPopup = ref} />

                <NotificationsPopup
                    user={user}
                    onClickReadNotifications={LoginActions.updateReadNotifications}
                    notifications={notifications}
                    ref={(ref) => this.notificationsPopup = ref}
                />
            </FlexColumn>
        )
    }
}
