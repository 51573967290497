import React, {Component} from 'react'
import _ from 'lodash'

import {H3, H5, Modal, Button, Row, P, IconButton, Alert, Toggle, RadioButton} from '../../UI/index.js'

export default class ImportPriceTableModal extends Component {
    constructor(props) {
        super(props)

        this.initialState = {
            open: false,
            fileName: '',
            fromReseller: '',
            exchangePriceTables: '',
            overwrite: false,
            error: ''
        }

        this.state = structuredClone(this.initialState)
    }

    open(onImport) {
        this.setState({open: true, onImport})
    }

    close() {
        document.getElementById('table-import').value = null
        this.setState(this.initialState)
    }

    onClickImportTable() {
        document.getElementById('table-import').value = null
        document.getElementById('table-import').click()
    }

    onLoadFile() {
        this.setState({fromReseller: '', error: ''})
        const file = document.getElementById('table-import').files[0]

        if (file) {
            this.setState({fileName: file.name})
            const reader = new FileReader()
            reader.readAsText(file)
            reader.onerror = (event) => {
                if (event.target.error.name == 'NotReadableError') {
                    alert('Can\'t read file !')
                }
            }
            reader.onload = (event) => {
                try {
                    const res = JSON.parse(event.target.result)
                    if (!res.resellerName || !res.priceTables) {
                        throw new Error('Dit bestand heeft geen geldige opmaak. Er kan alleen een JSON bestand geïmporteerd worden dat ook vanuit Veloyd geëxporteerd is en dat niet is bewerkt.')
                    } else {
                        this.setState({fromReseller: res.resellerName, priceTables: res.priceTables, fees: res.fees || []})
                    }
                } catch {
                    this.setState({error: 'Dit bestand heeft geen geldige opmaak. Er kan alleen een JSON bestand geïmporteerd worden dat ook vanuit Veloyd geëxporteerd is en dat niet is bewerkt.'})
                }
            }
        }
    }

    onClickImport() {
        const {fromReseller, overwrite, priceTables, fees, exchangePriceTables, onImport} = this.state
        const {reseller} = this.props

        if (overwrite) {
            reseller.settings.parcels.prices = priceTables || reseller.settings.parcels.prices

            reseller.settings.parcels.fees = reseller.settings.parcels.fees || []
            reseller.settings.parcels.fees = fees
        } else {
            Object.keys(priceTables).map((priceTableName) => {
                const checkNameAlreadyExists = (name) => {
                    const alreadyExists = !!reseller.settings.parcels.prices[name]
                    if (alreadyExists) {
                        let newName = `${name} (1)`
                        const regex = /\((\d+)\)$/ // Regular expression to match "(x)" at the end of the name
                        const hasNumber = name.match(regex)

                        if (hasNumber) {
                            const number = parseInt(hasNumber[1], 10)
                            const incrementedName = name.replace(regex, `(${number + 1})`)
                            newName = incrementedName
                        }

                        checkNameAlreadyExists(newName)
                    } else {
                        priceTables[name] = priceTables[priceTableName]
                        if (name !== priceTableName) {
                            delete priceTables[priceTableName]
                        }
                    }
                }
                checkNameAlreadyExists(priceTableName)
            })
            _.merge(reseller.settings.parcels.prices, priceTables || {})

            reseller.settings.parcels.fees = reseller.settings.parcels.fees || []
            reseller.settings.parcels.fees.push(...fees)
        }

        if (exchangePriceTables) {
            Object.keys(reseller.settings.parcels.prices).map((priceTable) => {
                reseller.settings.parcels.prices[priceTable][reseller.name] = priceTables?.[priceTable]?.[fromReseller] || reseller.settings.parcels.prices[priceTable]?.[reseller.name] || {}
                reseller.settings.parcels.prices[priceTable][fromReseller] = priceTables?.[priceTable]?.[reseller.name] || reseller.settings.parcels.prices[priceTable]?.[fromReseller] || {}
            })
        }

        typeof onImport === 'function' && onImport(reseller)
        this.close()
    }

    render() {
        const {fileName, fromReseller, open, exchangePriceTables, overwrite, error} = this.state
        const {reseller} = this.props

        return (
            <Modal style={{width: 'fit-content', minWidth: 600, maxWidth: 600}} show={open} onClose={this.close.bind(this)}>
                <Row style={{justifyContent: 'space-between'}}>
                    <H3 style={{marginBottom: 24}}>Importeer tarieftabel</H3>

                    <IconButton onClick={this.close.bind(this)}>
                        <i className='mdi mdi-close' />
                    </IconButton>
                </Row>

                <Button
                    variant='outline-white'
                    icon='mdi mdi-folder-open'
                    label={fileName || 'Selecteer json bestand'}
                    onClick={this.onClickImportTable.bind(this)}
                />

                {fromReseller && (
                    <>
                        <Toggle
                            style={{marginTop: 24}}
                            label='Overschrijf tarieftabellen'
                            info='Wanneer deze optie aan staat dan zullen geïmporteerde tarieftabellen die dezelfde naam hebben als bestaande tarieftabellen deze overschrijven.'
                            checked={overwrite}
                            onChange={(event) => this.setState({overwrite: event.target.checked})}
                        />

                        {fromReseller !== reseller.name && (
                            <>
                                <H5 style={{marginTop: 24}}>Wissel tarieftabellen</H5>

                                <P>{'Wanneer je tarieven van een andere vestiging importeert kan het handig zijn om de \'eigen vestiging\' tarieven te wisselen met de \'andere vestiging tarieven\'.'}</P>

                                <P style={{marginTop: 12, marginBottom: 12}}>Kies hieronder welke optie van toepassing is:</P>

                                <RadioButton
                                    label={`Je eigen tarieven voor ${reseller.settings?.accountName || reseller.name} worden de geëxporteerde tarieven van ${fromReseller}`}
                                    checked={exchangePriceTables}
                                    unsetWhiteSpace
                                    value={true}
                                    onChange={(e) => {
                                        if (e.target.value) {
                                            this.setState({exchangePriceTables: true})
                                        }
                                    }}
                                />

                                <RadioButton
                                    label={`Je eigen tarieven voor ${reseller.settings?.accountName || reseller.name} worden de geëxporteerde tarieven van ${reseller.settings?.accountName || reseller.name}`}
                                    checked={exchangePriceTables === false}
                                    unsetWhiteSpace
                                    value={false}
                                    onChange={(e) => {
                                        if (!e.target.value) {
                                            this.setState({exchangePriceTables: false})
                                        }
                                    }}
                                />
                            </>
                        )}
                    </>
                )}

                {error && <Alert style={{marginTop: 24}} variant='error'>{error}</Alert>}

                <Row style={{justifyContent: 'flex-end', marginTop: 24}}>
                    <Button variant='text' onClick={this.close.bind(this)}>Annuleren</Button>
                    <Button disabled={(!fromReseller) || (fromReseller !== reseller.name && exchangePriceTables === '')} onClick={this.onClickImport.bind(this)}>Importeer</Button>
                </Row>

                <input
                    type='file'
                    id='table-import'
                    accept='.json'
                    style={{visibility: 'hidden', height: 0}}
                    onChange={this.onLoadFile.bind(this)}
                />
            </Modal>
        )
    }
}
