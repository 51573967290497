import React from 'react'

import dbCodes from '../../../server/dbCodes.js'
import {Colors, S1, P, Link} from '../UI/index.js'

const Packaging = (props) => {
    const {history, order, packaging, index} = props

    const historyPackaging = packaging.events.findLast((event) => event.orderId === order._id)
    console.log(historyPackaging)

    return (
        <>
            <div style={{display: 'flex', minHeight: 37, borderRadius: index === 0 ? '4px 4px 0px 0px' : index === order.packages.length ? '0px 0px 4px 4px' : 0, borderTop: index === 0 ? '' : `1px solid ${Colors.border}`}}>

                <div style={{display: 'flex', flex: 1, borderRight: `1px dotted ${Colors.border}`}}>
                    <div style={{flex: 1, padding: 6}}>
                        <div style={{display: 'flex', alignItems: 'center'}}>
                            <S1 style={{width: 20, marginRight: 12}}>{index + 1}</S1>

                            <Link onClick={() => history.push(`/emballage?id=${packaging._id}`)}>{packaging.barcode || 'Geen barcode'}</Link>
                        </div>

                        {packaging.description &&
                            <div style={{display: 'flex'}}>
                                <i style={{width: 20, marginRight: 12, fontSize: 16}} className='mdi mdi-message-outline' />
                                <P>{packaging.description}</P>
                            </div>
                        }
                    </div>

                    <div style={{display: 'flex', flexDirection: 'column', width: 150, padding: 6, alignItems: 'flex-end'}}>
                        {packaging.type &&
                            <P style={{minHeight: 30}}>{packaging.type}</P>
                        }
                    </div>
                </div>

                <div style={{display: 'flex', flex: 1, alignItems: 'center', justifyContent: 'flex-end'}}>

                    <div style={{display: 'flex', alignItems: 'center', padding: 6}}>
                        <div style={{marginRight: 12}}>
                            <P style={{textAlign: 'right'}}>{dbCodes.packagingStatus[historyPackaging.status]}</P>
                        </div>

                        <i style={{fontSize: 12, color: Colors.grey40}} className='mdi mdi-circle' />
                    </div>

                </div>
            </div>
        </>
    )
}

export default Packaging
