import moment from 'moment'
import _ from 'underscore'
import dbCodes from '../../server/dbCodes.js'
import Countries from './Countries.js'
import {RRule} from 'rrule'

import CustomerStore from '../stores/CustomerStore.js'

function filter(object, queries) {
    return _.every(queries, (query) => {
        const values = query.text.split('||')

        if (query.key) {
            if (query.operator === '=') {
                return _.some(values, (value) => {
                    value = value.trim().toLowerCase()
                    if (value === '') {
                        return traverse(object, query.key).toString().toLowerCase() == value
                    } else {
                        return traverse(object, query.key).toString().toLowerCase().indexOf(value) > -1
                    }
                })
            } else if (query.operator === '==') {
                return _.some(values, (value) => {
                    value = value.trim().toLowerCase()
                    return traverse(object, query.key).toString().toLowerCase() == value
                })
            } else if (query.operator === '!=') {
                return _.every(values, (value) => {
                    value = value.trim().toLowerCase()
                    if (value === '') {
                        return traverse(object, query.key).toString().toLowerCase() != value
                    } else {
                        return traverse(object, query.key).toString().toLowerCase().indexOf(value) == -1
                    }
                })
            } else if (query.operator === '<') {
                return _.every(values, (value) => {
                    value = value.trim()
                    return parseFloat(traverse(object, query.key)?.toString().replace(/,/g, '.')) < parseFloat(value.replace(/,/g, '.'))
                })
            } else if (query.operator === '>') {
                return _.every(values, (value) => {
                    value = value.trim()
                    return parseFloat(traverse(object, query.key)?.toString().replace(/,/g, '.')) > parseFloat(value.replace(/,/g, '.'))
                })
            }
        } else {
            if (query.operator === '=') {
                return _.some(values, (value) => {
                    value = value.trim().toLowerCase()
                    return JSON.stringify(object).toString().toLowerCase().indexOf(value) > -1
                })
            } else if (query.operator === '!=') {
                return _.every(values, (value) => {
                    value = value.trim().toLowerCase()
                    return JSON.stringify(object).toString().toLowerCase().indexOf(value) === -1
                })
            }
        }
    })
}

export default {
    orders: (orders, routes, queries, startTime, endTime, startInclude, endInclude) => {
        const filteredOrders = {}

        Object.keys(orders).map((id) => {
            const order = structuredClone(orders[id])
            order.createdTime = moment(new Date(parseInt(order._id.substring(0, 8), 16) * 1000)).format('HH:mm')

            order.customer = `${order.senderAddress?.name || ''}${order.senderAddress?.name ? ` (${order.customerAddress?.name})` : order.customerAddress?.name}`

            order.status = `${dbCodes.status[order.status]}${order.retourId ? ' (retour)' : ''}`
            order.checked = order.checked ? 'Ja' : 'Nee'
            order.invoiced = order.dontInvoice ? 'n.v.t.' : order.invoiced ? 'Ja' : 'Nee'
            order.date = moment(order.date).format('DD-MM-YYYY')

            order.startTime = ''
            order.endTime = ''

            order.address = ''

            order.addresses.map((address) => {
                order.startTime += `${address.startTime} `
                order.endTime += `${address.endTime} `

                order.address += `${address.name} ${address.street} ${address.nr}${address.addition} ${address.postalCode} ${address.city}`

                if (!order.pickupAddress && address.type === 'pickup') {
                    order.pickupAddress = `${address.name} ${address.street} ${address.nr}${address.addition} ${address.postalCode} ${address.city}`
                }

                if (address.type === 'delivery') {
                    order.deliveryAddress = `${address.name} ${address.street} ${address.nr}${address.addition} ${address.postalCode} ${address.city}`
                }
            })

            order.routeName = routes[order.routeId]?.name || ''
            order.messengerName = (order.routeId ? routes[order.routeId]?.messengerName : order.messenger) || '' // order.messenger is DEPRECATED

            order.barcodes = order.colli?.map((collo) => collo.barcode || '').join(', ') || ''

            order.colli = `${order.colli.length} ${order.colli?.length === 1 ? 'collo' : 'colli'}`

            delete order._id
            delete order.reseller

            const isInTime = _.some(order.addresses, (address) => {
                if (startInclude && endInclude) {
                    return address.startTime < endTime && (startTime === '00:00' ? address.endTime >= startTime : address.endTime > startTime)
                }

                if (!startInclude && !endInclude) {
                    return address.startTime >= startTime && address.endTime <= endTime
                }

                if (!startInclude) {
                    return address.startTime >= startTime
                }

                if (!endInclude) {
                    return address.endTime <= endTime
                }
            })

            if (isInTime) {
                if (filter(order, queries)) {
                    filteredOrders[id] = orders[id]
                }
            }
        })

        return filteredOrders
    },

    packages: (packages, queries) => {
        return packages.filter((packaging) => {
            packaging = structuredClone(packaging)

            delete packaging._id
            delete packaging.reseller

            return filter(packaging, queries)
        })
    },

    shifts: (shifts, queries) => {
        return shifts.filter((shift) => {
            shift = structuredClone(shift)

            shift.date = moment(shift.date).format('DD-MM-YYYY')
            shift.salaryMethod = dbCodes.salaryMethod[shift.salaryMethod]

            delete shift._id
            delete shift.reseller

            return filter(shift, queries)
        })
    },

    parcels: (parcels, queries, carriers) => {
        const carrierObject = {}
        carriers.map((carrier) => carrierObject[carrier.name] = carrier)

        return parcels.filter((parcel) => {
            parcel = structuredClone(parcel)

            const customers = (CustomerStore.singleton && CustomerStore.state.customers) || {}
            parcel.customer = customers[parcel.customer] ? customers[parcel.customer].name : ''
            parcel.status = dbCodes.parcelStatus[parcel.status]
            parcel.invoiced = parcel.invoiced ? 'Ja' : 'Nee'
            parcel.date = moment(parcel.date).format('DD-MM-YYYY')
            parcel.address.postalCode = `${parcel.address.name} ${parcel.address.street} ${parcel.address.nr}${parcel.address.addition} ${parcel.address.postalCode} ${parcel.address.city}`

            parcel.length = (parcel.length || 0) / 10
            parcel.width = (parcel.width || 0) / 10
            parcel.height = (parcel.length || 0) / 10

            parcel.carrier = carrierObject[parcel.carrier]?.displayName || parcel.carrier

            const country = _.findWhere(Countries, {code2: parcel.address.country})
            if (country) {
                parcel.address.country = `${country.code2} ${country.name}`
            }

            parcel.address.isBusiness = parcel.address.isBusiness ? 'Ja' : 'Nee'

            parcel.fees.map((fee) => {
                if (fee.price) {
                    parcel.price = (parseFloat(parcel.price.replace(',', '.')) + (parseFloat(fee.price.replace(',', '.')) * fee.quantity)).toFixed(2).replace('.', ',')
                }
            })

            const productCodes = parcel.productCodes.map((item) => {
                if (item.quantity === 1) {
                    return item.productCode
                } else {
                    return `${item.quantity}x ${item.productCode}`
                }
            })

            parcel.fees.map((fee) => {
                if (fee.productCode) {
                    if (fee.quantity === 1) {
                        productCodes.push(fee.productCode)
                    } else {
                        productCodes.push(`${fee.quantity}x ${fee.productCode}`)
                    }
                }
            })

            parcel.productCodes = productCodes.join(', ')

            parcel.fees = parcel.fees.map((fee) => {
                if (fee.quantity === 1) {
                    return fee.description
                } else {
                    return `${fee.quantity}x ${fee.description}`
                }
            }).join(', ')

            delete parcel._id
            delete parcel.reseller

            return filter(parcel, queries)
        })
    },

    customers: (customers, queries) => {
        return customers.filter((customer) => {
            customer = structuredClone(customer)

            customer.street = `${customer.address.street} ${customer.address.nr}${customer.address.addition}`
            customer.synced = customer.synced ? 'ja' : 'nee'
            customer.login = customer.login ? 'ja' : 'nee'

            if (customer.settings && customer.settings.parcels) {
                customer.settings.parcels.priceTable = customer.settings.parcels.priceTable || 'Standaard'
                customer.settings.parcels.priceTableAbroad = customer.settings.parcels.priceTableAbroad || customer.settings.parcels.priceTable
            }

            delete customer._id
            delete customer.reseller

            return filter(customer, queries)
        })
    },

    addresses: (addresses, queries) => {
        return addresses.filter((address) => {
            address = structuredClone(address)

            address.address = `${address.street} ${address.nr}${address.addition}`
            address.isBusiness = address.isBusiness ? 'ja' : 'nee'

            delete address._id
            delete address.reseller

            return filter(address, queries)
        })
    },

    users: (users, queries) => {
        return users.filter((user) => {
            user = structuredClone(user)

            user.planner = user.planner ? 'ja' : 'nee'

            delete user._id
            delete user.reseller

            return filter(user, queries)
        })
    },

    paychecks: (paychecks, queries) => {
        return paychecks.filter((paycheck) => {
            paycheck = structuredClone(paycheck)

            delete paycheck._id
            delete paycheck.reseller

            return filter(paycheck, queries)
        })
    },

    invoices: (invoices, queries) => {
        return invoices.filter((invoice) => {
            invoice = structuredClone(invoice)

            const customers = (CustomerStore.singleton && CustomerStore.state.customers) || {}
            invoice.customer = customers[invoice.customer] ? customers[invoice.customer].name : ''
            invoice.date = moment(invoice.date).format('DD-MM-YYYY')
            invoice.invoiced = invoice.invoiced ? 'Gefactureerd Verstuurd' : 'Concept'

            delete invoice._id
            delete invoice.reseller

            return filter(invoice, queries)
        })
    },

    subscriptions: (subscriptions, queries) => {
        return subscriptions.filter((subscription) => {
            subscription = structuredClone(subscription)

            const customers = (CustomerStore.singleton && CustomerStore.state.customers) || {}
            subscription.customer = customers[subscription.customer] ? customers[subscription.customer].name : ''
            subscription.date = moment(subscription.date).format('DD-MM-YYYY')
            subscription.rrule = moment(RRule.fromString(subscription.rrule).options.dtstart).format('DD-MM-YYYY')
            subscription.startDate = moment(subscription.startDate).format('DD-MM-YYYY')
            subscription.endDate = subscription.endDate === '2999-12-31' ? '' : moment(subscription.endDate).format('DD-MM-YYYY')

            delete subscription._id
            delete subscription.reseller

            return filter(subscription, queries)
        })
    },

    objects: (objects, queries) => {
        return objects.filter((object) => {
            object = structuredClone(object)

            return filter(object, queries)
        })
    }
}

/**
* Traverse an object the find the value for the given multilevel key
* @param {object} obj
* @param {string} key multilevel key seperated by dots
* @return {string}
*/
function traverse(obj, key) {
    if (key) {
        return key.split('.').reduce((cur, key) => {
            return cur?.[key] ?? ''
        }, obj)
    } else {
        return ''
    }
}
