import React from 'react'

import Reflux from 'reflux-react-16'

import LoginStore from '../../../stores/LoginStore.js'
import LoginActions from '../../../actions/LoginActions.js'

class ShopifyInstall extends Reflux.Component {
    constructor(props) {
        super(props)

        this.store = LoginStore
    }

    componentDidMount() {
        const {history} = this.props

        const query = new URLSearchParams(history.location.search)
        const code = query.get('code')
        const hmac = query.get('hmac')
        const nonce = query.get('state')
        const shop = query.get('shop')

        LoginActions.installShopify(code, hmac, nonce, shop, (err) => {
            history.push('/klant/instellingen/koppelingen/webshops/shopify', {err, message: !err && 'Shopify koppeling is geactiveerd.'})
        })
    }

    render() {
        return (
            <div />
        )
    }
}

export default (ShopifyInstall)
