import React from 'react'

export default class Column extends React.Component {
    render() {
        const {style = {}, children} = this.props

        const defaultStyle = {
            display: 'flex',
            flex: !style.width ? 1 : undefined,
            paddingLeft: 6,
            paddingRight: 6,
            ...style
        }

        return (
            <div {...this.props} style={defaultStyle}>
                {children}
            </div>

        )
    }
}
