import React, {Component} from 'react'

import {Colors} from './index.js'
import Row from './Row.js'
import IconButton from './IconButton.js'

class Steps extends Component {
    constructor(props) {
        super(props)

        this.state = {
            focused: false,
            isInvalid: false
        }
    }

    focus() {
        this.input.focus()
    }

    blur() {
        this.input.blur()
    }

    getRef() {
        return this.input
    }

    setInvalid() {
        this.setState({isInvalid: true})
        this.focus()
    }

    onChange(event) {
        const {onChange, min, max} = this.props
        this.setState({isInvalid: false})

        event.target.value = parseInt(event.target.value || '0')
        if (parseInt(event.target.value) < min) {
            event.target.value = min
        }

        if (parseInt(event.target.value) > max) {
            event.target.value = max
        }

        typeof onChange === 'function' && onChange(event)
    }

    render() {
        const {style = {}, inputStyle, value, noPlaceholder, placeholder, disabled, isInvalid, readOnly, id, required, onBlur, onFocus, onKeyDown, onKeyPress, inputMode, label} = this.props
        const {focused} = this.state

        const focusedState = focused || value || noPlaceholder || placeholder || value === 0

        const defaultStyle = {
            flex: 1,
            flexShrink: 0,
            position: 'relative',
            marginLeft: 6,
            marginRight: 6,
            marginBottom: 12,
            height: 42,
            borderTop: 'none',
            borderLeft: 'none',
            borderRight: 'none',
            borderBottom: '1px solid',
            borderColor: disabled ? Colors.grey20 : isInvalid ? Colors.errorBright : (focusedState && focused) ? Colors.buttonSolid : Colors.grey20,
            borderRadius: '4px 4px 0px 0px',
            background: disabled ? Colors.grey20 : readOnly ? 'transparent' : Colors.backgroundWhite,
            overflow: 'hidden'
        }

        if (style && style.width) {
            delete defaultStyle.flex
        }

        const labelStyle = {
            display: 'flex',
            alignItems: 'center',
            position: 'absolute',
            paddingLeft: 36,
            top: focusedState ? 2 : 10,
            fontSize: focusedState ? 12 : 14,
            color: Colors.textMedium,
            cursor: disabled || readOnly ? 'default' : 'text',
            transition: 'top .2s ease'
        }

        const defaultInputStyle = {
            fontSize: 14,
            height: 42,
            paddingTop: 12,
            width: '100%',
            outline: 'none',
            border: 'none',
            color: disabled ? Colors.textLight : Colors.textDark,
            cursor: disabled || readOnly ? 'default' : 'text'
        }

        style && Object.keys(style).map((key) => {
            defaultStyle[key] = style[key]
        })

        inputStyle && Object.keys(inputStyle).map((key) => {
            defaultInputStyle[key] = inputStyle[key]
        })

        return (
            <div style={defaultStyle}>
                <div style={labelStyle} onClick={() => this.input.focus()}>
                    <div style={{whiteSpace: 'nowrap'}}>{label}</div>
                </div>
                <Row style={{alignItems: 'center'}}>
                    <IconButton onClick={() => this.onChange({target: {value: value - 1}})} icon='mdi mdi-minus' />
                    <input
                        style={defaultInputStyle}
                        id={id}
                        type='number'
                        placeholder={placeholder}
                        value={value === 0 ? 0 : (value || '')}
                        disabled={disabled || readOnly}
                        required={required}
                        onFocus={() => {
                            if (!disabled && !readOnly) {
                                this.setState({focused: true})
                                onFocus && onFocus()
                            }
                        }}
                        onBlur={() => {
                            this.setState({focused: false})
                            onBlur && onBlur()
                        }}
                        onClick={this.props.onClick && this.props.onClick.bind(this)}
                        onKeyDown={onKeyDown}
                        onKeyPress={onKeyPress}
                        onChange={this.onChange.bind(this)}
                        ref={(ref) => this.input = ref}
                        inputMode={inputMode}
                    />
                    <IconButton onClick={() => this.onChange({target: {value: value + 1}})} icon='mdi mdi-plus' />
                </Row>
            </div>
        )
    }
}

export default Steps
