import React from 'react'
import {Route} from 'react-router-dom'

import CompanySettings from './CompanySettings.js'
import OpeningHours from './OpeningHours.js'
import DeliveryZones from './DeliveryZones.js'
import EmailSettings from './EmailSettings.js'

class AccountSettings extends React.Component {
    componentDidMount() {
        const {history} = this.props

        if (history.location.pathname === '/instellingen/algemeen' || history.location.pathname === '/instellingen/algemeen/') {
            history.push('/instellingen/algemeen/bedrijfsgegevens')
        }
    }

    render() {
        const {reseller, user, onChange} = this.props

        return (
            <>
                <Route path='/instellingen/algemeen/bedrijfsgegevens' render={() => <CompanySettings reseller={reseller} user={user} onChange={onChange} />} />
                <Route path='/instellingen/algemeen/openingstijden' render={() => <OpeningHours reseller={reseller} onChange={onChange} />} />
                <Route path='/instellingen/algemeen/bezorggebieden' render={() => <DeliveryZones reseller={reseller} onChange={onChange} />} />
                <Route path='/instellingen/algemeen/emailinstellingen' render={() => <EmailSettings reseller={reseller} onChange={onChange} />} />
            </>
        )
    }
}

export default AccountSettings
