import React from 'react'
import Leaflet from './Leaflet.js'
import Reflux from 'reflux-react-16'

import UtilActions from '../../actions/UtilActions.js'

import {Button} from '../UI/index.js'

class ReturnLocationPicker extends Reflux.Component {
    constructor(props) {
        super(props)

        this.state = {
            map: null,
            droppingMarker: false,
            loadingPickupPoints: false
        }
    }

    componentDidMount() {
        this.initMap()
    }

    initMap() {
        const {returnParcelRequest} = this.props
        let {map} = this.state

        if (map) {
            map.remove()
        }

        map = Leaflet.map('map').setView(returnParcelRequest.address.position || {lat: 52.2076823, lng: 5.1584931}, 13)

        Leaflet.tileLayer(`${window.location.origin}/api/mapbox/styles/v1/{account}/{id}/tiles/{z}/{x}/{y}`, {
            maxZoom: 18,
            account: 'veloyd',
            id: 'cka2lgtlz0huj1iln27ujuqim',
            tileSize: 512,
            zoomOffset: -1,
            attribution: '© <a href="https://www.mapbox.com/feedback/">Mapbox</a> © <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>'
        }).addTo(map)

        this.setState({map})

        this.addPickupPoints(map)
    }

    onClickMyLocation() {
        const {map} = this.state
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    map.setView({lat: position.coords.latitude, lng: position.coords.longitude}, 13)
                    this.addPickupPoints(map, {lat: position.coords.latitude, lng: position.coords.longitude})
                },
                (err) => {
                    if ('message' in err) {
                        alert(err.message)
                    } else if (typeof err === 'string') {
                        alert(err)
                    } else {
                        console.error(err)
                        alert('Er is een fout opgetreden')
                    }
                },
                {
                    enableHighAccuracy: true,
                    timeout: 5000,
                    maximumAge: 0
                }
            )
        } else {
            alert('Dit apparaat ondersteund geen locatie')
        }
    }

    onCenterMarker() {
        const {locationMarker, map} = this.state
        locationMarker.setLatLng(map.getCenter())
    }

    onClickMapMarker() {
        const {returnParcelRequest} = this.props
        const {map, droppingMarker} = this.state
        let {locationMarker} = this.state

        if (droppingMarker) {
            locationMarker.remove()
            this.setState({locationMarker: null, droppingMarker: false})
            map.off('move')
            return
        }

        const icon = Leaflet.icon({
            iconUrl: '/images/location-picker-icon.png',
            iconSize: [38, 38]
        })
        if (!locationMarker) {
            locationMarker = Leaflet.marker(returnParcelRequest.address.position, {icon, zIndexOffset: 1000}).addTo(map)
        }
        locationMarker.setLatLng(map.getCenter())
        this.setState({locationMarker, droppingMarker: true})

        map.on('move', this.onCenterMarker.bind(this))
    }

    onConfirmMarker() {
        const {locationMarker, map} = this.state

        const position = locationMarker?.getLatLng()

        this.addPickupPoints(map, position)
        locationMarker.remove()
        this.setState({locationMarker: null})
        map.off('move')
    }

    addPickupPoints(map, location) {
        const {returnParcelRequest, customerURL} = this.props
        let {markers} = this.state

        if (markers && markers.length > 0) {
            markers.forEach((marker) => {
                map.removeLayer(marker)
            })
            markers = []
        }

        if (location) {
            returnParcelRequest.location = location
        }

        const PostNLIcon = Leaflet.icon({
            iconUrl: '/images/postnlLogo.png',
            iconSize: [38, 37]
        })

        const GLSIcon = Leaflet.icon({
            iconUrl: '/images/glsLogo.png',
            iconSize: [38, 38]
        })

        const DHLIcon = Leaflet.icon({
            iconUrl: '/images/dhlLogo.png',
            iconSize: [38, 15]
        })

        const icons = {
            PostNL: PostNLIcon,
            GLS: GLSIcon,
            DHL: DHLIcon
        }

        this.setState({loadingPickupPoints: true})

        UtilActions.getOptionsReturnPortal(customerURL, returnParcelRequest, (err, options) => {
            if (err) {
                console.log({err})
            } else {
                const markers = options.filter((option) => returnParcelRequest.carrier ? option.carrier === returnParcelRequest.carrier : true).map((option) => {
                    const icon = icons[option.carrier] ? icons[option.carrier] : option.carrier.includes('PostNL') ? PostNLIcon : option.carrier.includes('GLS') ? GLSIcon : option.carrier.includes('DHL') ? DHLIcon : null

                    const marker = Leaflet.marker([option.location.lat, option.location.lng], {icon})

                    let carrierName = option.carrier
                    if (carrierName.includes('Onlineparcel')) {
                        carrierName = carrierName.replace(' - Onlineparcel', '')
                    }

                    if (carrierName.includes('QLS')) {
                        carrierName = carrierName.replace('-QLS', '')
                    }

                    if (carrierName.includes('MSG')) {
                        carrierName = carrierName.replace('-MSG', '')
                    }

                    const tooltipContent = `<div style='max-width: 300px; max-width: fit-content;'>
                        <span>${carrierName || option.carrier}</span>
                        <br>
                        <span>${option.address.name}</span>
                        <br>
                        <span>${option.address.street} ${option.address.nr}${option.address.addition ? ` ${option.address.addition}` : ''}</span>
                        <br>
                        <span>${option.address.postalCode} ${option.address.city}</span>
                        <hr>
                        <span>Maandag: ${option.openingHours.monday}</span>
                        <br>
                        <span>Dinsdag: ${option.openingHours.tuesday}</span>
                        <br>
                        <span>Woensdag: ${option.openingHours.wednesday}</span>
                        <br>
                        <span>Donderdag: ${option.openingHours.thursday}</span>
                        <br>
                        <span>Vrijdag: ${option.openingHours.friday}</span>
                        <br>
                        <span>Zaterdag: ${option.openingHours.saturday}</span>
                        <br>
                        <span>Zondag: ${option.openingHours.sunday}</span>
                        </div>
                        `
                    marker.bindTooltip(tooltipContent)
                    marker.addTo(map)
                    return marker
                })
                this.setState({markers})
            }
            this.setState({loadingPickupPoints: false, droppingMarker: false})
        })
    }

    render() {
        const {droppingMarker, loadingPickupPoints} = this.state

        const buttonStyle = {
            position: 'absolute',
            bottom: 50,
            left: '50%',
            transform: 'translateX(-50%)',
            zIndex: 999
        }

        return (
            <div style={{width: '100%', maxWidth: 400}}>
                <div id='map' style={{position: 'relative', height: 0, paddingBottom: '100%', width: '100%'}}>
                    <MyLocationButton onClick={this.onClickMyLocation.bind(this)} />
                    <MapMarker onClick={this.onClickMapMarker.bind(this)} />
                    {droppingMarker && !loadingPickupPoints && (
                        <Button onClick={this.onConfirmMarker.bind(this)} style={buttonStyle}>Bevestig positie</Button>
                    )}
                    {loadingPickupPoints &&
                        <Button loading style={buttonStyle} />
                    }
                </div>
            </div>
        )
    }
}

export default ReturnLocationPicker

class MyLocationButton extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            hover: false
        }
    }

    render() {
        const {hover} = this.state
        const {onClick} = this.props

        const containerStyle = {
            position: 'absolute',
            zIndex: 999,
            top: '20%',
            left: 10,
            borderRadius: 4,
            border: '2px solid rgba(0,0,0,0.2)',
            width: 34,
            height: 30
        }
        const buttonStyle = {
            backgroundColor: 'white',
            border: 'none',
            outline: 'none',
            width: '100%',
            height: '100%',
            cursor: 'pointer'
        }

        if (hover) {
            buttonStyle.backgroundColor = '#f4f4f4'
        }

        return (
            <div onClick={onClick} style={containerStyle} onMouseEnter={() => this.setState({hover: true})} onMouseLeave={() => this.setState({hover: false})}>
                <button style={buttonStyle}>
                    <i className='mdi mdi-crosshairs-gps' />
                </button>
            </div>
        )
    }
}

class MapMarker extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            hover: false
        }
    }

    render() {
        const {hover} = this.state
        const {onClick} = this.props

        const containerStyle = {
            position: 'absolute',
            zIndex: 999,
            top: '27%',
            left: 10,
            borderRadius: 4,
            border: '2px solid rgba(0,0,0,0.2)',
            width: 34,
            height: 30
        }
        const buttonStyle = {
            backgroundColor: 'white',
            border: 'none',
            outline: 'none',
            width: '100%',
            height: '100%',
            cursor: 'pointer'
        }

        if (hover) {
            buttonStyle.backgroundColor = '#f4f4f4'
        }

        return (
            <div onClick={onClick} style={containerStyle} onMouseEnter={() => this.setState({hover: true})} onMouseLeave={() => this.setState({hover: false})}>
                <button style={buttonStyle}>
                    <i className='mdi mdi-map-marker' />
                </button>
            </div>
        )
    }
}
