import React from 'react'

import {Input, Select, TimePicker} from '../UI/index.js'
import {Alert, Button, Modal, H4} from '../UI/index.js'
import time from '../../../server/utils/time.js'

export default class AutoSetMessengerPopup extends React.Component {
    constructor(props) {
        super(props)

        const vehicleType = props.reseller.settings.vehicleTypes.find((v) => v._id === props.reseller.settings.defaultVehicle)

        this.initialState = {
            isOpen: false,
            confirm: null,
            options: {
                plannedStartTime: time.time(),
                nrOfRoutes: 2,
                maxRouteDuration: 0,
                maxStops: 0,
                vehicleTypeId: props.reseller.settings.defaultVehicle || '',
                vehicleType: vehicleType?.routeProfile || ''
            },
            selectedOrders: [],
            loading: false,
            error: ''
        }

        this.state = JSON.parse(JSON.stringify(this.initialState))
    }

    open(selectedOrders, confirm) {
        const options = {...this.state.options}
        options.plannedStartTime = time.time()
        this.setState({isOpen: true, confirm, options, selectedOrders})
    }

    confirm() {
        const {selectedOrders, options} = this.state

        const min = 1
        const max = Math.min(Math.ceil(selectedOrders.length / 2), 100)

        if (options.nrOfRoutes >= min && options.nrOfRoutes <= max) {
            this.setState({loading: true, error: ''})
            this.state.confirm(options)
        } else {
            this.setError(`Aantal routes moet tussen de ${min} en ${max} liggen.`)
        }
    }

    close() {
        this.setState(this.initialState)
    }

    setError(error) {
        this.setState({error, loading: false})
    }

    render() {
        const {isOpen, options, loading, error} = this.state
        const {reseller} = this.props

        return (
            <Modal show={isOpen} style={{width: 450}} onClose={this.close.bind(this)}>

                <div style={{display: 'flex', marginBottom: 24}}>
                    <H4>Automatisch routes berekenen</H4>

                    <div style={{flex: 1}} />
                </div>

                <TimePicker
                    label='Starttijd'
                    value={options.plannedStartTime}
                    onChange={(event) => this.setState({options: {...options, plannedStartTime: event.target.value || ''}})}
                />

                <Input
                    type='number'
                    label='Aantal routes'
                    value={options.nrOfRoutes}
                    onChange={(event) => this.setState({options: {...options, nrOfRoutes: parseInt(event.target.value) || ''}})}
                />

                {/* {false &&
                    <DurationPicker
                        label='Maximale route lengte'
                        value={options.maxRouteDuration}
                        onChange={(event) => this.setState({options: {...options, maxRouteDuration: parseInt(event.target.value)}})}
                    />
                }

                {false &&
                    <Input
                        type='number'
                        label='Maximaal aantal stops per route'
                        value={options.maxStops}
                        onChange={(event) => this.setState({options: {...options, maxStops: parseInt(event.target.value) || ''}})}
                    />
                } */}

                <Select
                    style={{width: '100%'}}
                    label='Voertuig'
                    options={reseller.settings.vehicleTypes.filter((v) => !v.isArchived).map((v) => ({value: v._id, title: v.name, icon: `mdi mdi-${v.icon}`}))}
                    value={options.vehicleTypeId || reseller.settings.defaultVehicle}
                    onChange={(event) => this.setState({options: {...options, vehicleTypeId: event.target.value, vehicleType: reseller.settings.vehicleTypes.find((v) => v._id === event.target.value)?.routeProfile || ''}})}
                />

                <br />
                {error &&
                    <Alert variant='danger'>{error}</Alert>
                }

                <div style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-end'}}>
                    <Button
                        variant='text'
                        disabled={loading}
                        onClick={this.close.bind(this)}
                    >
                        Annuleren
                    </Button>

                    <Button
                        variant='text'
                        disabled={!options.nrOfRoutes}
                        loading={loading}
                        onClick={this.confirm.bind(this)}
                    >
                        Bevestigen
                    </Button>
                </div>
            </Modal>
        )
    }
}
