import React from 'react'

import aSync from 'async'
import _ from 'underscore'
import {parse} from 'csv-parse/browser/esm'
import jschardet from 'jschardet'
import FileSaver from 'file-saver'

import LoginActions from '../../actions/LoginActions.js'
import ParcelActions from '../../actions/ParcelActions.js'

import {Alert, Button, Colors, Modal, S2, ProgressBar, Row, Column, H5, P} from '../UI/index.js'
import {Input, Select, SelectCustomer, Toggle} from '../UI/index.js'
import NewParcel from '../../../server/functions/parcels/NewParcel.js'
import splitAddress from '../../utils/splitAddress.js'
import time from '../../../server/utils/time.js'

class ImportParcels extends React.Component {
    constructor(props) {
        super(props)
        this.initialState = {
            modalIsOpen: false,
            customer: {name: ''},
            contact: {name: ''},
            emailTT: '',
            ignoreFirstLine: false,
            chosenOptions: [],
            optionsFromCSV: false,
            businessAddress: false,
            columns: [],
            possibleColumns: { // TODO specify type of column (text, number, date, etc) and use it to validate and parse input
                'address.name': 'Naam',
                'firstName': 'Voornaam',
                'lastName': 'Achternaam',
                'address.attention': 'T.a.v.',
                'address.street': 'Straat',
                'address.nr': 'Nr',
                'address.addition': 'Toevoeging',
                'streetNr': 'Straat + nr + toevoeging',
                'nrAddition': 'Nr + toevoeging',
                'address.street2': '2e adres regel',
                'address.postalCode': 'Postcode',
                'address.city': 'Plaats',
                'address.country': 'Landcode',
                'address.isBusiness': 'Bedrijfsadres',
                'address.email': 'Emailadres',
                'address.phone': 'Telefoon',
                'carrier': 'Vervoerder',
                'options': 'Opties',
                'pickupPoint.id': 'Afhaalpunt Id',
                'fees': 'Toeslagen',
                'reference': 'Referentie',
                'comment': 'Opmerkingen',
                'description': 'Inhoud',
                'value': 'Waarde',
                'weight': 'Gewicht',
                'sizeOption': 'Standaardformaat'
            },
            sizeOption: props.user?.settings?.parcels?.sizes?.findIndex((size) => size.isDefault) ?? undefined,
            lines: [],
            loading: false,
            progressNow: 0,
            progressMax: null,
            message: '',
            errors: [],
            errorLines: []
        }

        this.state = JSON.parse(JSON.stringify(this.initialState))
    }

    open() {
        const {user} = this.props

        let {customer, columns, chosenOptions, optionsFromCSV, businessAddress, ignoreFirstLine} = this.state

        if (user.isCustomer) {
            customer = user

            if (user.settings.parcels?.csvColumns) {
                columns = [...user.settings.parcels.csvColumns]
            }

            if (user.settings.parcels?.csvOptions) {
                optionsFromCSV = user.settings.parcels?.csvOptions?.optionsFromCSV || optionsFromCSV
                businessAddress = user.settings.parcels?.csvOptions?.businessAddress || businessAddress
                ignoreFirstLine = user.settings.parcels?.csvOptions?.ignoreFirstLine || ignoreFirstLine
            }

            if (customer.settings.parcels.defaultOptions) {
                chosenOptions = [...customer.settings.parcels.defaultOptions]
            }

            if (customer.settings.parcels.autoSameDayNextDay) {
                const index = chosenOptions.indexOf('Same Day')

                if (index === -1 && time.time() <= customer.settings.parcels.sameDayCuttoff || time.time() > customer.settings.parcels.nextDayCuttoff) {
                    chosenOptions = [...chosenOptions, 'Same Day']
                }
            }
        }

        this.setState({modalIsOpen: true, customer, columns, chosenOptions, optionsFromCSV, businessAddress, ignoreFirstLine})
    }

    close() {
        this.setState(this.initialState)
    }

    onChangeCustomer({customer, contact}) {
        const {reseller} = this.props
        let {columns, chosenOptions} = this.state

        if (customer) {
            const emailTT = customer.settings.parcels.emailTT || ''
            const contact = {name: '', email: '', phone: ''}

            if (reseller.settings.parcels.csvColumns[customer._id]) {
                const customerColumns = [...reseller.settings.parcels.csvColumns[customer._id]]

                customerColumns.map((value, index) => {
                    return columns[index] = value
                })
            }

            let optionsFromCSV = false
            let businessAddress = false
            let ignoreFirstLine = false

            if (reseller.settings.parcels.csvOptions?.[customer._id]) {
                optionsFromCSV = reseller.settings.parcels?.csvOptions[customer._id]?.optionsFromCSV || optionsFromCSV
                businessAddress = reseller.settings.parcels?.csvOptions[customer._id]?.businessAddress || businessAddress
                ignoreFirstLine = reseller.settings.parcels?.csvOptions[customer._id]?.ignoreFirstLine || ignoreFirstLine
            }

            if (customer.settings.parcels.defaultOptions) {
                chosenOptions = [...customer.settings.parcels.defaultOptions]
            }

            if (customer.settings.parcels.autoSameDayNextDay) {
                const index = chosenOptions.indexOf('Same Day')

                if (index === -1 && time.time() <= customer.settings.parcels.sameDayCuttoff || time.time() > customer.settings.parcels.nextDayCuttoff) {
                    chosenOptions = [...chosenOptions, 'Same Day']
                }
            }

            this.setState({
                customer, contact, emailTT, columns, chosenOptions, optionsFromCSV, businessAddress, ignoreFirstLine
            })
        } else if (contact) {
            let {emailTT} = this.state
            emailTT = contact.email || emailTT

            this.setState({contact, emailTT})
        }
    }

    onChangeOptions(event) {
        this.setState({
            chosenOptions: event.target.values
        })
    }

    onChangeValue(key, {target: {value}}) {
        this.setState({[key]: key === 'sizeOption' ? parseInt(value) : value})
    }

    onClickOpenFile(event) {
        event.preventDefault()
        document.getElementById('csv').value = null
        document.getElementById('csv').click()
    }

    onLoadFile() {
        const file = document.getElementById('csv').files[0]

        if (file) {
            const reader = new FileReader()
            reader.readAsBinaryString(file)

            reader.onerror = (event) => {
                if (event.target.error.name == 'NotReadableError') {
                    alert('Can\'t read file !')
                }
            }
            reader.onload = (event) => {
                const encoding = jschardet.detect(event.target.result, {minimumThreshold: 0}).encoding
                const reader = new FileReader()
                reader.readAsText(file, encoding)

                reader.onerror = (event) => {
                    if (event.target.error.name == 'NotReadableError') {
                        alert('Can\'t read file !')
                    }
                }
                reader.onload = (event) => {
                    let delimiter = ';'
                    let commaCount = 0
                    let semicolonCount = 0

                    event.target.result.split('').slice(0, 500).map((char) => {
                        commaCount += char === ',' ? 1 : 0
                        semicolonCount += char === ';' ? 1 : 0
                    })

                    if (commaCount > semicolonCount) {
                        delimiter = ','
                    }

                    parse(event.target.result, {delimiter, relax_column_count: true, skip_empty_lines: true, trim: true}, (err, lines) => {
                        if (err) {
                            alert('Geen geldig .csv bestand')
                        } else {
                            const {columns} = this.state

                            let nrOfColumns = 0

                            const nonEmptyLines = []

                            lines.map((line) => {
                                if (line.length > nrOfColumns) {
                                    nrOfColumns = line.length
                                }

                                if (line.join('') !== '') {
                                    nonEmptyLines.push(line)
                                }
                            })

                            const columnDiff = nrOfColumns - columns.length
                            if (columnDiff > 0) {
                                for (let i = 0; i < columnDiff; i++) {
                                    columns.push('')
                                }
                            } else {
                                columns.splice(nrOfColumns)
                            }

                            this.setState({lines: nonEmptyLines, columns, error: ''})
                        }
                    })
                }
            }
        }
    }

    onChangeColumn(index, event) {
        const {columns} = this.state
        columns[index] = event.target.value
        this.setState({columns})
    }

    onClickCreateParcels() {
        const {lines, columns, customer, contact, emailTT, ignoreFirstLine, chosenOptions, optionsFromCSV, businessAddress, sizeOption} = this.state
        const {reseller, user} = this.props
        const customerValid = !this.parcelCustomer || this.parcelCustomer.validate()

        if (customerValid) {
            let success = 0
            const errors = []
            const errorLines = []

            this.setState({loading: true, progressMax: ignoreFirstLine ? lines.length - 1 : lines.length, progressNow: 1})

            if (ignoreFirstLine) {
                lines.shift()
            }

            aSync.eachOfSeries(lines, (line, index, next) => {
                if (this.state.stopOperation) return
                this.setState({progressNow: index + 1})
                const parcel = NewParcel()

                parcel.plugin = 'CSV'

                parcel.customer = customer._id
                parcel.contact = contact.name
                parcel.customerAddress = customer.address
                parcel.emailTT = emailTT

                parcel.address.isBusiness = undefined

                if (businessAddress) {
                    parcel.address.isBusiness = true
                }

                if (!isNaN(sizeOption)) {
                    const size = user.settings.parcels.sizes[sizeOption]

                    if (size) {
                        parcel.length = size.length
                        parcel.width = size.width
                        parcel.height = size.height
                    }
                }

                line.map((value, index) => {
                    if (columns[index]) {
                        const key = columns[index].split('.')

                        value = value.replace(/"/g, '')

                        if (key[0] === 'firstName') {
                            parcel.address.name = `${value} ${parcel.address.name}`.replace(/  +/g, ' ').trim()
                        } else if (key[0] === 'lastName') {
                            parcel.address.name = `${parcel.address.name} ${value}`.replace(/  +/g, ' ').trim()
                        } else if (key[0] === 'streetNr') {
                            const address = splitAddress(value)
                            parcel.address.street = address.street
                            parcel.address.nr = address.nr
                            parcel.address.addition = address.addition
                        } else if (key[0] === 'nrAddition') {
                            const address = splitAddress(`straat ${value}`)
                            parcel.address.nr = address.nr
                            parcel.address.addition = address.addition
                        } else if (key[1] === 'postalCode') {
                            parcel.address.postalCode = value.toUpperCase().replace(/  +/g, ' ').trim()

                            if (/^[0-9]{4}[A-Z]{2}$/.test(parcel.address.postalCode)) {
                                parcel.address.postalCode = `${parcel.address.postalCode.substr(0, 4)} ${parcel.address.postalCode.substr(4, 2)}`
                            }
                        } else if (key[1] === 'isBusiness') {
                            if (['ja', 'yes', '1', 'true'].indexOf(value.toLowerCase()) > -1) {
                                parcel.address.isBusiness = true
                            }
                            if (['nee', 'no', '0', 'false'].indexOf(value.toLowerCase()) > -1) {
                                parcel.address.isBusiness = false
                            }
                        } else if (key[0] === 'options') {
                            value.split(',').map((option) => {
                                parcel.options.push(option.trim())
                            })
                        } else if (key[0] === 'fees') {
                            value.split(',').map((value) => {
                                const fee = _.findWhere(reseller.settings.parcels.fees, {description: value.trim()})

                                if (fee && fee.description) {
                                    fee.quantity = 1
                                    parcel.fees.push(fee)
                                }
                            })
                        } else if (key[0] === 'weight') {
                            parcel.weight = parseInt(value) || 0
                        } else if (key[0] === 'sizeOption') {
                            const index = user.settings.parcels.sizes.findIndex((size) => size.name === value.trim())
                            const size = user.settings.parcels.sizes[index]

                            if (size) {
                                parcel.length = size.length
                                parcel.width = size.width
                                parcel.height = size.height
                            }
                        } else if (key[0] === 'pickupPoint' && key[1] === 'id') {
                            parcel.pickupPoint = {id: value.trim()}
                        } else if (key.length === 1) {
                            parcel[key[0]] = value.trim()
                        } else {
                            parcel[key[0]][key[1]] = value.trim() || parcel[key[0]][key[1]] || ''
                        }
                    }
                })

                if (parcel.address.isBusiness === undefined) {
                    parcel.address.isBusiness = !!parcel.address.attention
                }

                parcel.address.country = parcel.address.country.toUpperCase()

                if (parcel.address.country === 'NLD') {
                    parcel.address.country = 'NL'
                }

                if (!optionsFromCSV) {
                    parcel.options = chosenOptions || []
                }

                if (parcel.pickupPoint?.id) {
                    parcel.options.push('Afhaalpunt')
                }

                // Add customs section to non EU parcels
                if (!EU.includes(parcel.address.country)) {
                    parcel.customs = {
                        typeItem: 'Documents',
                        typeNumber: '',
                        number: '',
                        content: [{
                            description: 'Documents',
                            quantity: 1,
                            weight: 0,
                            value: '0,00',
                            hsCode: '',
                            countryOrigin: 'NL'
                        }]
                    }
                }

                ParcelActions.create(parcel, (err) => {
                    if (err) {
                        errors.push(`Regel ${index + 1}: ${err}`)
                        errorLines.push(`${line.join(';')};${err.replace(/"/g, '')}`)
                    } else {
                        success += 1
                    }

                    next()
                })
            }, () => {
                const message = `${success} nieuwe ${success === 1 ? 'zending' : 'zendingen'} aangemaakt.`
                this.setState({loading: false, message, errors, errorLines})
            })

            if (user.isCustomer) {
                user.settings.parcels.csvColumns = columns
                user.settings.parcels.csvOptions = {
                    optionsFromCSV,
                    businessAddress,
                    ignoreFirstLine
                }
                LoginActions.updateUserSettings(user)
            } else {
                reseller.settings.parcels.csvColumns[customer._id] = columns
                reseller.settings.parcels.csvOptions[customer._id] = {
                    optionsFromCSV,
                    businessAddress,
                    ignoreFirstLine
                }

                LoginActions.updateResellerSettings('parcels.csvColumns', reseller.settings.parcels.csvColumns)
                LoginActions.updateResellerSettings('parcels.csvOptions', reseller.settings.parcels.csvOptions)
            }
        }
    }

    onClickDownloadErrors() {
        const {errorLines} = this.state
        const blob = new Blob([errorLines.join('\n')], {type: 'data:text/csv,charset=utf-8'})
        FileSaver.saveAs(blob, 'foutieve_zendingen.csv')
    }

    render() {
        const {modalIsOpen, customer, contact, emailTT, ignoreFirstLine, chosenOptions, optionsFromCSV, businessAddress, columns, possibleColumns, sizeOption, lines, loading, progressNow, progressMax, message, errors, errorLines} = this.state
        const {user, customers, reseller} = this.props

        let enabledOptions = (_.keys(reseller.settings.parcels.products) || []).filter((product) => {
            return _.some(_.keys(reseller.settings.parcels.products[product]), (carrier) => {
                return reseller.settings.parcels.products[product][carrier]
            })
        }).sort()

        enabledOptions = enabledOptions.map((option) => {
            return {value: option, title: option}
        })

        if (optionsFromCSV) {
            possibleColumns.options = 'Opties'
        } else {
            possibleColumns.options = undefined
        }

        return (
            <Modal
                style={{width: 'auto', minWidth: 800, maxWidth: '90vw'}}
                closeButton
                title='Zendingen importeren'
                show={modalIsOpen}
                onClose={this.close.bind(this)}
            >

                {!user.isCustomer && !progressMax &&
                    <>
                        <H5>Klantgegevens</H5>

                        <Row style={{marginBottom: 24, width: 788}}>
                            <Column>
                                <SelectCustomer
                                    value={customer._id}
                                    contact={contact.name}
                                    customers={customers}
                                    onChange={this.onChangeCustomer.bind(this)}
                                    ref={(ref) => this.parcelCustomer = ref}
                                />
                            </Column>

                            <Column>
                                <Input
                                    label='Track & Trace email'
                                    value={emailTT}
                                    onChange={(event) => this.setState({emailTT: event.target.value})}
                                />
                            </Column>
                        </Row>
                    </>
                }

                {!progressMax &&
                    <>
                        {!user.isCustomer &&
                            <H5>Zending import</H5>
                        }

                        <Row style={{width: 788}}>
                            <Column>
                                <Toggle
                                    label='Haal verzendopties uit CSV bestand'
                                    checked={optionsFromCSV}
                                    onChange={(event) => this.setState({optionsFromCSV: event.target.checked})}
                                />
                            </Column>

                            <Column>
                                <Select
                                    noSort
                                    label='Verzendopties'
                                    info='Als een combinatie van opties niet mogelijk is wordt de voorkeur gegeven aan de eerst geselecteerde optie.'
                                    values={chosenOptions || []}
                                    disabled={optionsFromCSV}
                                    onChange={this.onChangeOptions.bind(this)}
                                    options={enabledOptions}
                                />
                            </Column>
                        </Row>

                        <Row style={{width: 788}}>
                            <Column>
                                <Toggle
                                    label='Bedrijfsadressen'
                                    info='Geïmporteerde adressen worden gemarkeerd als bedrijfsadres.'
                                    checked={businessAddress}
                                    onChange={(event) => this.setState({businessAddress: event.target.checked})}
                                />
                            </Column>
                            <Column />
                        </Row>

                        <Row style={{width: 788}}>
                            <Column>
                                <Toggle
                                    label='Negeer 1e regel van CSV bestand'
                                    checked={ignoreFirstLine}
                                    onChange={(event) => this.setState({ignoreFirstLine: event.target.checked})}
                                />
                            </Column>
                            <Column />
                        </Row>

                        {user.isCustomer && user.settings.parcels?.sizes?.length > 0 && (
                            <Row style={{width: 788 / 2}}>
                                <Column>
                                    <Select
                                        label='Standaardformaat'
                                        value={sizeOption}
                                        onChange={this.onChangeValue.bind(this, 'sizeOption')}
                                        allowEmptyValue
                                    >
                                        {user.settings.parcels.sizes.map((size, index) => (
                                            <option key={index} value={index}>{size.name}</option>
                                        ))}
                                    </Select>
                                </Column>
                            </Row>
                        )}

                    </>
                }

                {!lines.length && !progressMax &&
                    <Button
                        variant='outline-white'
                        label='Selecteer csv bestand'
                        icon='mdi mdi-folder-open'
                        onClick={this.onClickOpenFile.bind(this)}
                    />
                }

                {!!lines.length && !progressMax &&
                    <>

                        <div style={{maxHeight: 500, overflow: 'auto', marginBottom: 24, marginTop: 24}}>
                            <Row style={{flexShrink: 0}}>
                                {columns.map((value, index) => {
                                    return (
                                        <Select
                                            key={index}
                                            style={{width: 200}}
                                            allowEmptyValue
                                            placeholder='Negeer kolom'
                                            value={value}
                                            onChange={this.onChangeColumn.bind(this, index)}
                                        >
                                            {_.keys(possibleColumns).map((key) => {
                                                if (possibleColumns[key]) {
                                                    return <option key={key} value={key}>{possibleColumns[key]}</option>
                                                }
                                            })}
                                        </Select>
                                    )
                                })}
                            </Row>

                            {lines.map((line, index) => {
                                if ((index > 0 || !ignoreFirstLine) && index < 20) {
                                    return (
                                        <Row
                                            key={index}
                                            style={{
                                                flexShrink: 0,
                                                paddingTop: 6,
                                                paddingBottom: 6,
                                                background: 'white',
                                                borderBottom: '1px solid',
                                                borderColor: Colors.grey40,
                                                width: 'fit-content'
                                            }}
                                        >
                                            {line.map((value, index) => {
                                                return (
                                                    <Column
                                                        key={index}
                                                        style={{
                                                            width: 212,
                                                            flexShrink: 0
                                                        }}
                                                    >
                                                        <P ellipsis>{value}</P>
                                                    </Column>
                                                )
                                            })}
                                        </Row>
                                    )
                                }
                            })}
                        </div>
                    </>
                }

                {!!lines.length && !progressMax &&
                    <S2>{`${ignoreFirstLine ? lines.length - 1 : lines.length} regels`}</S2>
                }

                <br />
                <br />
                {progressMax &&
                    <ProgressBar now={progressNow} max={progressMax} />
                }

                {message &&
                    <Alert variant='success'>{message}</Alert>
                }

                {errors.length > 0 &&
                    <div style={{maxHeight: 300, overflow: 'auto'}}>
                        {errors.map((error, index) => {
                            return <Alert key={`error${index}`} variant='danger'>{error}</Alert>
                        })}
                    </div>

                }

                <br />

                <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                    {!!errorLines.length &&
                        <Button
                            variant='outline-white'
                            label='Download foutieve zendingen .csv'
                            onClick={this.onClickDownloadErrors.bind(this)}
                        />
                    }

                    {loading &&
                        <Button
                            variant='outline-white'
                            onClick={() => this.setState({stopOperation: true, loading: false, progressNow: progressMax})}
                        >
                            Afbreken
                        </Button>
                    }

                    {progressMax && progressNow === progressMax ?
                            <Button
                                onClick={this.close.bind(this)}
                            >
                                Sluiten
                            </Button> :
                            <Button
                                loading={loading}
                                disabled={!lines.length}
                                onClick={() => {
                                    this.setState({stopOperation: false}, this.onClickCreateParcels)
                                }}
                            >
                                Importeer
                            </Button>
                    }
                </div>

                <input
                    type='file'
                    id='csv'
                    accept='.csv'
                    style={{visibility: 'hidden', height: 0}}
                    onChange={this.onLoadFile.bind(this)}
                />
            </Modal>

        )
    }
}

const EU = [
    'AT', 'BE', 'BG', 'HR', 'CY', 'DK', 'EE', 'FI', 'FR', 'DE', 'GR', 'HU', 'IE',
    'IT', 'LV', 'LT', 'LU', 'MC', 'MT', 'NL', 'CZ', 'PL', 'PT', 'RO', 'SK', 'SI', 'ES', 'SE'
]

export default (ImportParcels)
