import React from 'react'
import moment from 'moment'

import {Input, Modal, TextArea, Button, IconButton, Check, Card, Colors, DatePicker, TimePicker, H4} from '../UI/index.js'

import AdminActions from '../../actions/AdminActions.js'
import NotificationsPopup from './NotificationsPopup.js'

const newNotification = () => ({
    title: '',
    moreInfoUrl: '',
    message: '',
    imageSrc: '',
    recipients: [],
    scheduleDate: '',
    scheduleTime: '',
    scheduleNotification: false
})

class CreateNotification extends React.Component {
    constructor(props) {
        super(props)

        this.initialState = {
            modalIsOpen: false,
            notification: newNotification()
        }

        this.state = JSON.parse(JSON.stringify(this.initialState))
    }

    open(notification) {
        if (notification) {
            this.setState({notification: structuredClone(notification), modalIsOpen: true})
        } else {
            this.setState({modalIsOpen: true})
        }

        this.previewPopupRef?.open()
    }

    close() {
        this.setState(this.initialState)
    }

    onChangeValue(key, event) {
        const {notification} = this.state

        notification[key] = event.target.value

        this.setState({notification})
    }

    onChangeChecked(key, event) {
        const {notification} = this.state

        notification[key] = event.target.checked

        if (key === 'scheduleNotification') {
            if (event.target.checked) {
                notification.scheduleDate = moment().format('YYYY-MM-DD')
                notification.scheduleTime = moment().format('HH:mm')
            } else {
                notification.scheduleDate = ''
                notification.scheduleTime = ''
            }
        }

        this.setState({notification})
    }

    onChangeRecipients(recipient) {
        const {notification, notification: {recipients}} = this.state
        const index = recipients.findIndex((r) => r === recipient)
        const includes = index > -1

        if (includes) {
            recipients.splice(index, 1)
        } else {
            recipients.push(recipient)
        }

        notification.recipients = recipients

        this.setState({notification})
    }

    onInsertImage(event) {
        const {notification} = this.state
        const reader = new FileReader()

        reader.onloadend = (event) => {
            notification.imageSrc = event.target.result
            this.setState({notification})
        }

        reader.readAsDataURL(event.target.files[0])
    }

    onCreateNotification() {
        const {notification} = this.state
        AdminActions.createNotification(notification, (err) => {
            if (err) {
                console.log(err)
                alert('Error')
            } else {
                this.close()
                this.setState({notification: newNotification()})
            }
        })
    }

    onUpdateNotification() {
        const {notification} = this.state
        AdminActions.updateNotification(notification, (err) => {
            if (err) {
                console.log(err)
                alert('Error')
            } else {
                this.close()
                this.setState({notification: newNotification()})
            }
        })
    }

    render() {
        const {modalIsOpen, notification} = this.state
        const {_id, title, moreInfoUrl, message, imageSrc, recipients, scheduleDate, scheduleTime, scheduleNotification} = notification

        return (
            <div>
                <Modal style={{marginLeft: -400}} show={modalIsOpen} onClose={this.close.bind(this)}>
                    <div style={{position: 'relative'}}>
                        <div style={{display: 'flex', marginBottom: 24}}>
                            <H4>{_id ? 'Bewerk notificatie' : 'Nieuwe notificatie'}</H4>
                            <div style={{flex: 1}} />
                            <IconButton onClick={this.close.bind(this)}>
                                <i className='mdi mdi-close' />
                            </IconButton>
                        </div>

                        <div style={{display: 'flex', gap: 25}}>
                            <Input label='Titel' value={title} onChange={this.onChangeValue.bind(this, 'title')} />
                        </div>

                        <TextArea
                            label='Bericht'
                            value={message}
                            rows={6}
                            onChange={this.onChangeValue.bind(this, 'message')}
                        />

                        <Input label='Meer informatie link' value={moreInfoUrl} onChange={this.onChangeValue.bind(this, 'moreInfoUrl')} />

                        <div style={{marginBottom: 20}}>
                            <label htmlFor='insertNotificationImage'>Afbeelding</label>
                            <br />
                            <input id='insertNotificationImage' type='file' accept='image/*' onChange={this.onInsertImage.bind(this)} />

                            <img style={{display: 'block', margin: 'auto', maxWidth: '50%'}} src={imageSrc} />
                        </div>

                        <div style={{marginBottom: 20}}>
                            <Check label='Resellers/vervoerders' checked={recipients.includes('resellers')} onChange={this.onChangeRecipients.bind(this, 'resellers')} />
                            <Check label='Customers/klanten' checked={recipients.includes('customers')} onChange={this.onChangeRecipients.bind(this, 'customers')} />
                            <Check label='Messengers/Koeriers' checked={recipients.includes('messengers')} onChange={this.onChangeRecipients.bind(this, 'messengers')} />
                        </div>

                        <Check
                            label='Notificatie plannen'
                            checked={scheduleNotification}
                            onChange={this.onChangeChecked.bind(this, 'scheduleNotification')}
                        />
                        {scheduleNotification && (
                            <div style={{display: 'flex'}}>
                                <DatePicker
                                    style={{width: 182.5, marginRight: 10}}
                                    label='Datum'
                                    value={scheduleDate}
                                    onChange={(e) => {
                                        this.setState({notification: {
                                            ...notification,
                                            scheduleDate: e.target.value
                                        }})
                                    }}
                                />
                                <TimePicker
                                    style={{marginRight: 5, width: 172.5}}
                                    label='Tijd'
                                    time={scheduleTime}
                                    onChange={(e) => {
                                        this.setState({notification: {
                                            ...notification,
                                            scheduleTime: e.target.value
                                        }
                                        })
                                    }}
                                />
                            </div>
                        )}

                        <br />
                        <br />

                        {_id ?
                                <Button onClick={this.onUpdateNotification.bind(this)}>Opslaan</Button> :
                                <Button onClick={this.onCreateNotification.bind(this)}>Aanmaken</Button>
                        }
                        <Card style={{position: 'absolute', top: 0, left: 800, width: 500, overflowY: 'auto', padding: 12, paddingLeft: 32, paddingRight: 32, backgroundColor: Colors.backgroundNeutral}}>
                            <NotificationsPopup
                                ref={(ref) => this.previewPopupRef = ref}
                                previewNotification={modalIsOpen ?
                                        {
                                            title,
                                            moreInfoUrl,
                                            message,
                                            imageSrc,
                                            scheduleDate,
                                            scheduleTime
                                        } :
                                    undefined}
                            />
                        </Card>
                    </div>
                </Modal>
            </div>
        )
    }
}

export default CreateNotification
