import React from 'react'
import {Route} from 'react-router-dom'

import Reflux from 'reflux-react-16'

import LightspeedSettings from './LightspeedSettings.js'
import WooCommerceSettings from './WooCommerceSettings.js'
import ShopifySettings from './ShopifySettings.js'
import CCVShopSettings from './CCVShopSettings.js'
import BolcomSettings from './BolcomSettings.js'
import MijnwebwinkelSettings from './MijnwebwinkelSettings.js'
import ShoptraderSettings from './ShoptraderSettings.js'
import ExactSettings from './ExactSettings.js'
import MagentoSettings from './MagentoSettings.js'
import ShopwareSettings from './ShopwareSettings.js'
import PrestaShopSettings from './PrestaShopSettings.js'

import {Button, Card, Popup, H3, H6, Panel} from '../../../../components/UI/index.js'

class WebshopSettings extends Reflux.Component {
    onClickDownload(file) {
        const path = `/downloads/${file}`
        const name = file
        const a = document.createElement('a')
        document.body.appendChild(a)
        a.style = 'display: none'
        a.href = path
        a.download = name
        a.click()
        document.body.removeChild(a)
    }

    onClickNavigate(name) {
        const {history} = this.props

        history.push(`/klant/instellingen/koppelingen/webshops/${name}`)
    }

    onClickOpenPage(link) {
        window.open(`https://veloyd.nl${link}`)
    }

    onClickOpenWebsite(link) {
        window.open(link, '_blank')
    }

    render() {
        const {reseller, user, onChange} = this.props

        const webshops = [
            {name: 'Afosto', manualLink: '/koppelingen/afosto/', image: 'afostoLogo.png'},
            {name: 'Bol.com', link: 'bolcom', manualLink: '/koppelingen/bolcom', image: 'bolcomLogo.png'},
            {name: 'CCVShop', link: 'ccvshop', manualLink: '/koppelingen/ccvshop', image: 'ccvshopLogo.png'},
            {name: 'Exact Online', link: 'exact', image: 'exact.png'},
            {name: 'Factif', manualLink: '/koppelingen/factif', image: 'factif.png'},
            {name: 'Goedgepickt', manualLink: '/koppelingen/goedgepickt/', image: 'goedgepickt.png'},
            {name: 'Lightspeed', link: 'lightspeed', manual: 'lightspeed.pdf', image: 'lightspeedLogo.png'},
            {name: 'Magento', link: 'magento', manualLink: '/koppelingen/magento-2', image: 'magentoLogo.png'},
            {name: 'Mijnwebwinkel', link: 'mijnwebwinkel', manualLink: '/koppelingen/mijnwebwinkel', image: 'mijnwebwinkel.png'},
            {name: 'Mijnwinkel', informationLink: 'https://www.mijnwinkel.nl/consumenten', image: 'mijnwinkel.png'},
            {name: 'MyShop', manual: 'myshop.pdf', image: 'myshopLogo.png'},
            {name: 'Odoo', manualLink: '/koppelingen/odoo', image: 'odoo.png'},
            {name: 'OpenCart', download: 'veloyd.ocmod.zip', manual: 'opencart.pdf', image: 'opencartLogo.png'},
            {name: 'Picqer', manualLink: '/koppelingen/picqer', image: 'picqerLogo.png'},
            {name: 'PrestaShop', link: 'prestashop', manualLink: '/koppelingen/prestashop', image: 'prestaShopLogo.png'},
            {name: 'Shopforce', informationLink: 'https://www.shopforce.nl/', image: 'shopforce.png'},
            {name: 'Shopify', link: 'shopify', manualLink: '/koppelingen/shopify', image: 'shopifyLogo.png'},
            {name: 'Shoptrader', link: 'shoptrader', manualLink: '/koppelingen/shoptrader', image: 'shoptrader.png'},
            {name: 'Shopware', link: 'shopware', manualLink: '/koppelingen/shopware', image: 'shopware.png'},
            {name: 'StoreKeeper', informationLink: 'https://www.storekeeper.online/nl/', image: 'storekeeper.png'},
            {name: 'WooCommerce', link: 'woocommerce', manualLink: '/koppelingen/woocommerce', image: 'wooCommerceLogo.png'},
            {name: 'Zupr', informationLink: 'https://www.zupr.io/nl/', image: 'zupr.png'}
        ]

        return (
            <Panel style={{width: 785, padding: 20}}>
                <Route
                    path='/klant/instellingen/koppelingen/webshops'
                    exact
                    render={() => {
                        return (
                            <div style={{width: 800}}>
                                <H3>Webshops</H3>

                                <div style={{display: 'flex', alignItems: 'center', flexWrap: 'wrap'}}>
                                    {webshops.map((webshop) => {
                                        if (!webshop) {
                                            return
                                        }

                                        return (
                                            <Card style={{margin: 12, padding: 12, width: 350}} key={webshop.name}>

                                                <div style={{display: 'flex', alignItems: 'center', marginBottom: 24}}>
                                                    <img style={{marginRight: 24, objectFit: 'contain'}} src={`/images/${webshop.image}`} width={64} height={64} />

                                                    <H6>{webshop.name}</H6>
                                                </div>

                                                <br />
                                                <div style={{display: 'flex', justifyContent: 'space-between'}}>

                                                    {webshop.manual &&
                                                        <Button variant='text' onClick={this.onClickDownload.bind(this, webshop.manual)}>Handleiding</Button>
                                                    }

                                                    {webshop.manualLink &&
                                                        <Button variant='text' onClick={this.onClickOpenPage.bind(this, webshop.manualLink)}>Handleiding</Button>
                                                    }

                                                    {webshop.informationLink &&
                                                        <Button variant='text' onClick={this.onClickOpenWebsite.bind(this, webshop.informationLink)}>Informatie</Button>
                                                    }

                                                    {webshop.link &&
                                                        <Button variant='text' onClick={this.onClickNavigate.bind(this, webshop.link)}>Instellingen</Button>
                                                    }

                                                    {webshop.download &&
                                                        <Button variant='text' onClick={this.onClickDownload.bind(this, webshop.download)}>Download</Button>
                                                    }
                                                </div>
                                            </Card>
                                        )
                                    })}
                                </div>

                                <Popup ref={(ref) => this.popup = ref} />
                            </div>
                        )
                    }}
                />

                <Route path='/klant/instellingen/koppelingen/webshops/bolcom' render={(props) => <BolcomSettings {...props} reseller={reseller} user={user} onChange={onChange} />} />
                <Route path='/klant/instellingen/koppelingen/webshops/ccvshop' render={(props) => <CCVShopSettings {...props} reseller={reseller} user={user} onChange={onChange} />} />
                <Route path='/klant/instellingen/koppelingen/webshops/exact' render={(props) => <ExactSettings {...props} reseller={reseller} user={user} onChange={onChange} />} />
                <Route path='/klant/instellingen/koppelingen/webshops/lightspeed' render={(props) => <LightspeedSettings {...props} reseller={reseller} user={user} onChange={onChange} />} />
                <Route path='/klant/instellingen/koppelingen/webshops/mijnwebwinkel' render={(props) => <MijnwebwinkelSettings {...props} reseller={reseller} user={user} onChange={onChange} />} />
                <Route path='/klant/instellingen/koppelingen/webshops/shopify' render={(props) => <ShopifySettings {...props} reseller={reseller} user={user} onChange={onChange} />} />
                <Route path='/klant/instellingen/koppelingen/webshops/shoptrader' render={(props) => <ShoptraderSettings {...props} reseller={reseller} user={user} onChange={onChange} />} />
                <Route path='/klant/instellingen/koppelingen/webshops/woocommerce' render={(props) => <WooCommerceSettings {...props} reseller={reseller} user={user} onChange={onChange} />} />
                <Route path='/klant/instellingen/koppelingen/webshops/magento' render={(props) => <MagentoSettings {...props} reseller={reseller} user={user} onChange={onChange} />} />
                <Route path='/klant/instellingen/koppelingen/webshops/shopware' render={(props) => <ShopwareSettings {...props} reseller={reseller} user={user} onChange={onChange} />} />
                <Route path='/klant/instellingen/koppelingen/webshops/prestashop' render={(props) => <PrestaShopSettings {...props} reseller={reseller} user={user} onChange={onChange} />} />
            </Panel>
        )
    }
}

export default WebshopSettings
