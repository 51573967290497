import React from 'react'

import _ from 'underscore'

import {Button, CarrierLogo, DraggableList, H3, S1, S2, Panel, Colors, P} from '../../../../components/UI/index.js'
import {Input} from '../../../../components/UI/index.js'
import ParcelReferenceModal from '../../../../components/settings/ParcelsPreferences/ParcelPreferenceModal.js'

class ParcelPreferences extends React.Component {
    constructor(props) {
        super(props)
        this.state = {products: {}, enabledCarriers: []}
    }

    componentDidMount() {
        const {reseller, carriers} = this.props

        const enabledCarriers = _.filter(carriers, (carrier) => {
            return reseller.settings.parcels.carriers[carrier.name] && reseller.settings.parcels.carriers[carrier.name].enabled
        })

        const products = {'Standaard zending': {carriers: [], countries: []}}

        enabledCarriers.map((carrier) => {
            products['Standaard zending'].carriers.push(carrier.name)
            products['Standaard zending'].countries = _.uniq([...products['Standaard zending'].countries, ...carrier.countries])

            _.keys(carrier.products).map((productName) => {
                if (reseller.settings.parcels.products[productName] && reseller.settings.parcels.products[productName][carrier.name]) {
                    products[productName] = products[productName] || {carriers: [], countries: []}
                    products[productName].carriers = _.uniq([...products[productName].carriers, carrier.name])
                    products[productName].countries = _.uniq([...products[productName].countries, ...carrier.products[productName].countries])

                    carrier.products[productName].allowed.map((allowedProduct) => {
                        if (carrier.products[allowedProduct] && reseller.settings.parcels.products[allowedProduct] && reseller.settings.parcels.products[allowedProduct][carrier.name]) {
                            const combinedProduct = [productName, allowedProduct].sort().join(', ')
                            products[combinedProduct] = products[combinedProduct] || {carriers: [], countries: []}
                            products[combinedProduct].carriers = _.uniq([...products[combinedProduct].carriers, carrier.name])
                            products[combinedProduct].countries = _.uniq([...products[combinedProduct].countries, ..._.intersection(carrier.products[productName].countries, carrier.products[allowedProduct].countries)])

                            carrier.products[allowedProduct].allowed.map((allowedProduct2) => {
                                if (carrier.products[productName].allowed.indexOf(allowedProduct2) > -1) {
                                    if (carrier.products[allowedProduct2] && reseller.settings.parcels.products[allowedProduct2] && reseller.settings.parcels.products[allowedProduct2][carrier.name]) {
                                        const combinedProduct = [productName, allowedProduct, allowedProduct2].sort().join(', ')
                                        products[combinedProduct] = products[combinedProduct] || {carriers: [], countries: []}
                                        products[combinedProduct].carriers = _.uniq([...products[combinedProduct].carriers, carrier.name])
                                        products[combinedProduct].countries = _.uniq([...products[combinedProduct].countries, ..._.intersection(carrier.products[productName].countries, carrier.products[allowedProduct].countries, carrier.products[allowedProduct2].countries)])
                                    }
                                }
                            })
                        }
                    })
                }
            })
        })

        this.setState({products, enabledCarriers})
    }

    render() {
        const {products, enabledCarriers} = this.state
        const {reseller, carriers, onChange} = this.props

        return (
            <Panel style={{padding: 20, minWidth: 600, width: 'fit-content'}}>
                <H3 style={{marginBottom: 24}}>Voorkeuren</H3>

                {enabledCarriers.length < 1 &&
                    <S2 style={{marginBottom: 30}}>Er moet minimaal 1 vervoerders zijn geactiveerd voordat voorkeuren kunnen worden ingesteld.</S2>
                }

                <div style={{display: 'flex', alignItems: 'center', position: 'sticky', top: 0, paddingTop: 24, backgroundColor: Colors.backgroundWhite, zIndex: 1}}>
                    {enabledCarriers.length > 0 &&
                        <div style={{display: 'flex', width: 300, height: 40}}>
                            <Button
                                variant='outline'
                                icon='mdi mdi-pencil'
                                label='Bewerk voorkeurentabel'
                                onClick={() => this.parcelPreferenceModal.open(reseller.settings.parcels.preferences)}
                            >
                                Wijzig
                            </Button>
                        </div>
                    }

                    <div style={{display: 'flex'}}>
                        {enabledCarriers.map((carrier) => {
                            return (
                                <div
                                    key={`header${carrier.name}`}
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                        marginRight: 12,
                                        width: 90
                                    }}
                                >
                                    <CarrierLogo
                                        size={45}
                                        carriers={carriers}
                                        value={carrier.name}
                                    />
                                    <P>{carrier.displayName || carrier.name}</P>
                                </div>
                            )
                        })}
                    </div>
                </div>

                {_.keys(products).map((productName) => {
                    if (!reseller.settings.parcels.preferences?.[productName] || reseller.settings.parcels.preferences?.[productName].length < 1) {
                        return
                    }

                    if (products[productName].carriers.length > 0) {
                        return (
                            <div key={productName} style={{marginBottom: 12}}>
                                <S1 style={{marginBottom: 12}}>{productName}</S1>

                                {reseller.settings.parcels.preferences[productName] &&
                                    <DraggableList
                                        items={reseller.settings.parcels.preferences[productName]}
                                        renderItem={(preference, DragHandle, index) => {
                                            return (
                                                <div key={`${productName}${index}`} style={{display: 'flex', marginLeft: 15}}>
                                                    <div style={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        width: 285,
                                                        height: 30
                                                    }}
                                                    >
                                                        <S2>{preference.name}</S2>
                                                    </div>

                                                    {enabledCarriers.map((carrier) => {
                                                        const carrierHasProduct = products[productName].carriers.indexOf(carrier.name) > -1
                                                        const carrierHasCountry = carrierHasProduct && (preference.countries.indexOf('Overige') > -1 || _.intersection(carrier.combinedProducts[productName], preference.countries).length > 0)

                                                        return (
                                                            <Input
                                                                key={`carriers${carrier.name}`}
                                                                style={{
                                                                    marginLeft: 0,
                                                                    marginRight: 12,
                                                                    width: 90
                                                                }}
                                                                disabled={!carrierHasCountry}
                                                                value={preference[carrier.name] || ''}
                                                                readOnly
                                                            />
                                                        )
                                                    })}
                                                </div>
                                            )
                                        }}
                                    />
                                }
                            </div>
                        )
                    }
                })}

                <ParcelReferenceModal
                    onChange={onChange}
                    ref={(ref) => this.parcelPreferenceModal = ref}
                    reseller={reseller}
                    enabledCarriers={enabledCarriers}
                    carriers={carriers}
                    products={products}
                />
            </Panel>
        )
    }
}

export default (ParcelPreferences)
