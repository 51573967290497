import React from 'react'

import Reflux from 'reflux-react-16'
import _ from 'lodash'

import {Button, Divider, Panel, ListItem, H3, H6, S2, P} from '../../../../components/UI/index.js'
import {Toggle, Input, Select, TextArea} from '../../../../components/UI/index.js'
import FAQModal from '../../../../components/resellers/FAQModal.js'

class TrackTrace extends Reflux.Component {
    onChangeChecked(path, event) {
        const reseller = {...this.props.reseller}

        _.set(reseller.settings, path, event.target.checked)

        this.props.onChange(reseller, path)
    }

    onChangeValue(path, event) {
        const reseller = {...this.props.reseller}

        if (path === 'orders.trackTrace.timeFrame') {
            _.set(reseller.settings, path, parseInt(event.target.value))
        } else {
            _.set(reseller.settings, path, event.target.value)
        }

        this.props.onChange(reseller)
    }

    onSaveValue(path, event) {
        const reseller = {...this.props.reseller}

        if (event) {
            if (path === 'orders.trackTrace.timeFrame') {
                _.set(reseller.settings, path, parseInt(event.target.value))
            } else {
                _.set(reseller.settings, path, event.target.value)
            }
        }

        this.props.onChange(reseller, path)
    }

    onUpdateFAQ(faq, index) {
        const reseller = {...this.props.reseller}

        if (typeof index === 'number') {
            reseller.settings.orders.trackTrace.faq[index] = faq
        } else {
            reseller.settings.orders.trackTrace.faq = reseller.settings.orders.trackTrace.faq || []
            reseller.settings.orders.trackTrace.faq.push(faq)
        }

        this.props.onChange(reseller, 'orders.trackTrace.faq')
    }

    onRemoveFAQ(index) {
        const reseller = {...this.props.reseller}

        reseller.settings.orders.trackTrace.faq.splice(index, 1)

        this.props.onChange(reseller, 'orders.trackTrace.faq')
    }

    render() {
        const {reseller} = this.props

        return (
            <Panel style={{width: 600, padding: 20}}>

                <H3>Track & Trace Pagina</H3>

                <H6>Algemeen</H6>
                <Divider />

                <Select
                    noPlaceholder
                    label='Status icoon onderweg'
                    info='Deze instelling bepaalt of er een fiets- of autoicoon wordt getoond op de Track & Trace pagina voor de status onderweg.'
                    value={reseller.settings.orders.trackTrace.outForDeliveryStatusIcon}
                    onChange={this.onSaveValue.bind(this, 'orders.trackTrace.outForDeliveryStatusIcon')}
                >
                    <option value='bike'>Fiets</option>
                    <option value='cargo-bike'>Bakfiets</option>
                    <option value='car'>Auto</option>
                </Select>

                <Select
                    noPlaceholder
                    label='Tijdvak voor bezorging'
                    info='1/3e van het tijdvak wordt vóór de berekende ETA genomen, en 2/3e van het tijdvak na de ETA.'
                    value={reseller.settings.orders.trackTrace.timeFrame || 60}
                    onChange={this.onSaveValue.bind(this, 'orders.trackTrace.timeFrame')}
                >
                    <option value={15}>15 minuten</option>
                    <option value={30}>30 minuten</option>
                    <option value={45}>45 minuten</option>
                    <option value={60}>1 uur</option>
                    <option value={120}>2 uur</option>
                    <option value={240}>4 uur</option>
                </Select>

                <br />
                <br />
                <H6>Status berichten</H6>
                <Divider />

                <TextArea
                    label='Ophalen niet gelukt'
                    rows={3}
                    value={reseller.settings.orders.trackTrace.pickupNotCompletedMessage}
                    onChange={this.onSaveValue.bind(this, 'orders.trackTrace.pickupNotCompletedMessage')}
                />

                <TextArea
                    label='Ophalen niet thuis'
                    rows={3}
                    value={reseller.settings.orders.trackTrace.pickupNotHomeMessage}
                    onChange={this.onSaveValue.bind(this, 'orders.trackTrace.pickupNotHomeMessage')}
                />

                <TextArea
                    label='Ophalen geannuleerd'
                    rows={3}
                    value={reseller.settings.orders.trackTrace.pickupCancelledMessage}
                    onChange={this.onSaveValue.bind(this, 'orders.trackTrace.pickupCancelledMessage')}
                />

                <TextArea
                    label='Bezorgen niet gelukt (Nieuwe bezorgpoging)'
                    rows={3}
                    value={reseller.settings.orders.trackTrace.deliveryNotCompletedRescheduleMessage}
                    onChange={this.onSaveValue.bind(this, 'orders.trackTrace.deliveryNotCompletedRescheduleMessage')}
                />

                <TextArea
                    label='Bezorgen niet gelukt (Foutief adres)'
                    rows={3}
                    value={reseller.settings.orders.trackTrace.deliveryNotCompletedRetourMessage}
                    onChange={this.onSaveValue.bind(this, 'orders.trackTrace.deliveryNotCompletedRetourMessage')}
                />

                <TextArea
                    label='Bezorgen niet thuis (Nieuwe bezorgpoging)'
                    rows={3}
                    value={reseller.settings.orders.trackTrace.deliveryNotHomeRescheduleMessage}
                    onChange={this.onSaveValue.bind(this, 'orders.trackTrace.deliveryNotHomeRescheduleMessage')}
                />

                <TextArea
                    label='Bezorgen niet thuis (In bewaring)'
                    rows={3}
                    value={reseller.settings.orders.trackTrace.deliveryNotHomeOnHoldMessage}
                    onChange={this.onSaveValue.bind(this, 'orders.trackTrace.deliveryNotHomeOnHoldMessage')}
                />

                <TextArea
                    label='Bezorgen niet thuis (Retour)'
                    rows={3}
                    value={reseller.settings.orders.trackTrace.deliveryNotHomeRetourMessage}
                    onChange={this.onSaveValue.bind(this, 'orders.trackTrace.deliveryNotHomeRetourMessage')}
                />

                <TextArea
                    label='Bezorgen geannuleerd'
                    rows={3}
                    value={reseller.settings.orders.trackTrace.deliveryCancelledMessage}
                    onChange={this.onSaveValue.bind(this, 'orders.trackTrace.deliveryCancelledMessage')}
                />

                <TextArea
                    label='Manco'
                    rows={3}
                    value={reseller.settings.orders.trackTrace.deliveryMancoMessage}
                    onChange={this.onSaveValue.bind(this, 'orders.trackTrace.deliveryMancoMessage')}
                />

                <br />
                <br />
                <H6>Veelgestelde vragen</H6>
                <Divider />

                <Toggle
                    label='Toon veelgestelde vragen op Track & Trace pagina'
                    checked={reseller.settings.orders.trackTrace.showFaq}
                    onChange={this.onChangeChecked.bind(this, 'orders.trackTrace.showFaq')}
                />

                {reseller.settings.orders.trackTrace.showFaq &&
                    <>
                        <Panel style={{maxHeight: 300, overflowY: 'auto', marginBottom: 12}}>
                            {(reseller.settings.orders.trackTrace.faq || []).map((faq, index) => {
                                return (
                                    <ListItem key={`faq${index}`} onClick={() => this.faqModal.open(faq, index)}>
                                        <div>
                                            <P>{faq.question}</P>
                                            <S2>{faq.answer}</S2>
                                        </div>
                                    </ListItem>
                                )
                            })}
                        </Panel>

                        <Button
                            variant='outline'
                            icon='mdi mdi-plus'
                            label='Nieuwe FAQ'
                            onClick={() => this.faqModal.open()}
                        />
                    </>
                }

                <FAQModal
                    onSubmit={this.onUpdateFAQ.bind(this)}
                    onRemove={this.onRemoveFAQ.bind(this)}
                    ref={(ref) => this.faqModal = ref}
                />

                <br />
                <br />

                <H6>Footer bericht</H6>
                <Divider />

                <Input
                    style={{width: '90%'}}
                    label='Footer titel'
                    value={reseller.settings.orders.trackTrace.footerTitle}
                    onChange={this.onChangeValue.bind(this, 'orders.trackTrace.footerTitle')}
                    onBlur={this.onSaveValue.bind(this, 'orders.trackTrace.footerTitle')}
                />

                <TextArea
                    style={{width: '90%'}}
                    label='Footer tekst'
                    value={reseller.settings.orders.trackTrace.footerMessage}
                    onChange={this.onChangeValue.bind(this, 'orders.trackTrace.footerMessage')}
                    onBlur={this.onSaveValue.bind(this, 'orders.trackTrace.footerMessage')}
                />
                <br />
                <br />

                <H6>Contactinformatie</H6>
                <Divider />

                <Input
                    style={{width: '90%'}}
                    label='Telefoon'
                    value={reseller.settings.orders.trackTrace.phone}
                    onChange={this.onChangeValue.bind(this, 'orders.trackTrace.phone')}
                    onBlur={this.onSaveValue.bind(this, 'orders.trackTrace.phone')}
                />

                <Input
                    style={{width: '90%'}}
                    label='Emailadres'
                    value={reseller.settings.orders.trackTrace.email}
                    onChange={this.onChangeValue.bind(this, 'orders.trackTrace.email')}
                    onBlur={this.onSaveValue.bind(this, 'orders.trackTrace.email')}
                />
            </Panel>
        )
    }
}

export default (TrackTrace)
