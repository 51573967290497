import React, {useEffect, useRef, useState} from 'react'
import useAdminStore from '../../stores/AdminStoreZS.js'
import useLoginStore from '../../stores/LoginStoreZS.js'
import {H4, Input, P, Panel, Colors, Row, Button, Popup, IconButton} from '../../components/UI/index.js'
import Table from '../../components/Table/index.js'
import moment from 'moment'

const columns = (popup, resellers, searchOrderAndParcel, loginAsReseller) => {
    return [
        {title: 'Datum & tijd', visible: true, width: 120, sort: (log) => {
            const dateTime = moment(`${log.date} ${log.time}`, 'YYYY-MM-DD HH:mm:ss:SSS')
            return dateTime.valueOf()
        }, render: (log) => {
            const dateTime = moment(`${log.date} ${log.time}`, 'YYYY-MM-DD HH:mm:ss:SSS')
            return dateTime.format('DD-MM-YYYY HH:mm')
        }},
        {title: 'Reseller', visible: true, width: 250, key: 'resellerId', render: (log) => {
            return log?.resellerId ? `${log?.resellerId} (${resellers.find((reseller) => reseller._id === log.resellerId)?.name})` : ''
        }},
        {title: 'Klant', visible: true, width: 200, key: 'customerId', render: (log) => {
            return log?.customerId || ''
        }},
        {title: 'Rit ID', visible: true, width: 200, key: 'orderId', render: (log) => {
            if (!log?.orderId) {
                return ''
            }

            return (
                <div
                    style={{display: 'flex', alignItems: 'center', gap: 4}}
                    onClick={() => {
                        searchOrderAndParcel(log.orderId, (err, result) => {
                            if (!err) {
                                const {order} = result

                                const reseller = resellers.find((reseller) => reseller.name === order.reseller)
                                console.log(loginAsReseller)
                                loginAsReseller(reseller._id, `/planning/${log.orderId}`)
                            }
                        })
                    }}
                >
                    <IconButton icon='mdi mdi-eye' />
                    <P>{log.orderId}</P>
                </div>
            )
        }},
        {title: 'Zending ID', visible: true, width: 200, key: 'parcelId', render: (log) => {
            if (!log?.parcelId) {
                return ''
            }

            return (
                <div
                    style={{display: 'flex', alignItems: 'center', gap: 4}}
                    onClick={() => {
                        searchOrderAndParcel(log.parcelId, (err, result) => {
                            if (!err) {
                                const {parcel} = result

                                const reseller = resellers.find((reseller) => reseller.name === parcel.reseller)
                                loginAsReseller(reseller._id, `/zendingen/${log.parcelId}`)
                            }
                        })
                    }}
                >
                    <IconButton icon='mdi mdi-eye' />
                    <P>{log.parcelId}</P>
                </div>
            )
        }},
        {title: 'Naam', visible: true, width: 120, key: 'err.name'},
        {title: 'Omschrijving', visible: true, flex: 1, render: (log) => {
            return <P ellipsis>{log.err.message}</P>
        }},
        {title: 'Duur', visible: true, width: 50, render: (log) => {
            return log?.meta?.takenTimeMs ? `${(log.meta.takenTimeMs / 1000).toFixed(2).replace('.', ',')}s` : '-'
        }},
        {title: 'Request url', visible: true, width: 400, render: (log) => {
            if (!log?.meta?.requestOptions) {
                return ''
            }
            const {requestOptions} = log.meta
            return <P ellipsis>{requestOptions?.host || ''}{requestOptions?.path || ''}</P>
        }},
        {title: 'Request methode', visible: true, width: 120, key: 'meta.requestOptions.method'},
        {title: 'Origin', visible: true, width: 120, key: 'meta?.origin'},
        {title: 'Stack trace', visible: true, width: 250, key: 'err.stack', render: (log) => {
            if (!log.err.stack) {
                return ''
            }

            return (
                <div
                    style={{display: 'flex', alignItems: 'center', gap: 4}}
                    onClick={() => {
                        popup.open('Stack trace', JSON.stringify(log, null, 2))
                        popup.setErrors([log.err.stack]) // For some reason Popup width is set to 800 if setErrors is used instead of setError
                    }}
                >
                    <IconButton icon='mdi mdi-eye' />
                    <P ellipsis>{log.err.stack}</P>
                </div>
            )
        }},
        {visible: true, width: 40}
    ]
}

export default function Logs({history}) {
    const logs = useAdminStore((state) => state.logs)
    const getLogs = useAdminStore((state) => state.getLogs)
    const totalLogs = useAdminStore((state) => state.totalLogs)
    const resellers = useAdminStore((state) => state.resellers)
    const getResellers = useAdminStore((state) => state.getResellers)
    const searchOrderAndParcel = useAdminStore((state) => state.searchOrderAndParcel)
    const loginAsReseller = useLoginStore((state) => state.loginAsReseller)
    const popupRef = useRef()
    const [searchUrl, setSearchUrl] = useState('')
    const searchParams = new URLSearchParams(location.search)
    let customerId = searchParams.get('customerId')
    let resellerId = searchParams.get('resellerId')

    const onPagination = (pageSize, page) => {
        getLogs(pageSize, page, {searchUrl, customerId, resellerId})
    }

    const fetchLogs = () => {
        const pageSize = localStorage.getItem('_pageSize') || 40
        getLogs(+pageSize, 1, {searchUrl, customerId, resellerId})
    }

    useEffect(() => {
        fetchLogs()
        getResellers()
    }, [])

    return (
        <div style={{display: 'flex', flexDirection: 'column', height: '100%'}}>
            <Panel style={{background: Colors.backgroundNeutral, padding: '20px 10px 10px 10px'}}>
                <H4 style={{marginBottom: 12}}>Logs</H4>

                {(customerId || resellerId) &&
                    <div style={{display: 'flex', alignItems: 'baseline', gap: 8}}>
                        <P style={{marginBottom: 8}}>Je bent logs aan het bekijken voor <b>{resellerId ? 'reseller' : customerId ? 'klant' : ''}</b> met ID: <b>{resellerId || customerId || ''}</b></P>
                        <IconButton
                            onClick={() => {
                                history.push('/admin/logs')
                                customerId = null
                                resellerId = null
                                fetchLogs()
                            }}
                            icon='mdi mdi-close'
                        />
                    </div>
                }

                <Row>
                    <Input
                        value={searchUrl}
                        onChange={(event) => setSearchUrl(event.target.value)}
                        style={{width: 250}}
                        label='Request url'
                        info={
                            <div>
                                Zoek op domeinnaam of pad.
                                <br />
                                ✅ = <pre style={{display: 'inline'}}>veloyd.nl</pre> of <pre style={{display: 'inline'}}>/api/order</pre>
                                <br />
                                ❌ = <pre style={{display: 'inline'}}>veloyd.nl/api/order</pre>
                            </div>
                        }
                    />

                    <Button
                        icon='mdi mdi-magnify'
                        label='Zoeken'
                        onClick={() => {
                            const pageSize = localStorage.getItem('_pageSize') || 40

                            onPagination(+pageSize, 1)
                        }}
                    />
                </Row>
            </Panel>

            {logs?.length > 0 && popupRef?.current && resellers.length > 0 && typeof searchOrderAndParcel === 'function' && typeof loginAsReseller === 'function' &&
                <div style={{flex: 1, marginTop: 24, marginLeft: 24, marginRight: 24}}>
                    <Table
                        columns={columns(popupRef.current, resellers, searchOrderAndParcel, loginAsReseller)}
                        rows={logs}
                        selectedRows={[]}
                        onPagination={onPagination}
                        totalRowsCount={totalLogs}
                        serverSidePagination
                        onChange={() => {}}
                    />
                </div>
            }

            <Popup ref={popupRef} />
        </div>
    )
}
