import React from 'react'
import Reflux from 'reflux-react-16'

import aSync from 'async'
import _ from 'underscore'

import AddressStore from '../../stores/AddressStore.js'
import AddressActions from '../../actions/AddressActions.js'

import {Button, IconButton, Popup, P, Panel, Colors} from '../../components/UI/index.js'
import AddressModal from '../../components/addresses/AddressModal.js'
import ImportModal from '../../components/modals/importCSV/ImportModal.js'
import Table from '../../components/Table/index.js'
import SearchBar from '../../components/SearchBar/index.js'
import filter from '../../utils/filter.js'
import csvExport from '../../utils/csvExport.js'

class AddressBook extends Reflux.Component {
    constructor(props) {
        super(props)
        this.state = {
            selectedAddresses: [],
            queries: []
        }

        this.store = AddressStore
    }

    componentDidMount() {
        const {reseller} = this.props
        document.title = `Adresboek • ${reseller.settings.accountName || reseller.name}`
    }

    columns() {
        return [
            {title: 'Naam', visible: true, key: 'name', width: 150},
            {title: 'TAV', visible: true, key: 'attention', width: 100},
            {title: 'Bedrijfadres', visible: true, key: 'isBusiness', width: 80, render: (address) => {
                return (
                    <P ellipsis>{address.isBusiness ? 'Ja' : 'Nee'}</P>
                )
            }},
            {title: 'Adres', visible: true, key: 'address', flex: 2, render: ({street, nr, addition}) => `${street} ${nr}${addition}`},
            {title: 'Postcode', visible: true, key: 'postalCode', width: 100},
            {title: 'Plaats', visible: true, key: 'city', flex: 1},
            {title: 'Land', visible: true, key: 'country', width: 50},
            {title: 'Email', visible: true, key: 'email', flex: 1},
            {title: 'Telefoon', visible: true, key: 'phone', width: 150},
            {title: 'Instructies', visible: true, key: 'instructions', flex: 1},
            {title: 'Bijlage', visible: false, key: 'attachments', flex: 1, onClick: () => {}, render: (address) => {
                return (
                    <P
                        onClick={() => {
                            const attachment = address.attachments?.[0] || {}
                            const openUrl = (file) => {
                                const raw = window.atob(file)
                                const rawLength = raw.length
                                const array = new Uint8Array(new ArrayBuffer(rawLength))

                                for (let i = 0; i < rawLength; i++) {
                                    array[i] = raw.charCodeAt(i)
                                }

                                const blob = new Blob([array], {type: attachment.type})

                                const blobUrl = URL.createObjectURL(blob)

                                window.open(blobUrl)
                            }
                            if (attachment.fileId) {
                                AddressActions.downloadAttachment(attachment.fileId, (err, file) => {
                                    if (err) {
                                        console.log(err)
                                    } else {
                                        openUrl(file)
                                    }
                                })
                            } else if (attachment.data) {
                                window.open(attachment.data)
                            }
                        }}
                        ellipsis
                    >
                        {address.attachments?.map((a) => a.fileName).join(',')}
                    </P>
                )
            }},
            {title: '', visible: true, showOnHover: true, key: '', width: 60, onClick: () => {}, render: (address) => {
                return (
                    <IconButton onClick={this.onClickEdit.bind(this, address)}>
                        <i className='mdi mdi-pencil' />
                    </IconButton>
                )
            }}
        ]
    }

    onSaveAddress(address) {
        AddressActions.update([address], (err) => {
            if (err) {
                this.addressModal.setError(err)
            } else {
                this.addressModal.close()
            }
        })
    }

    onClickNew() {
        this.addressModal.open(undefined, this.onSaveAddress.bind(this))
    }

    onClickImport() {
        this.importModal.open()
    }

    onChangeImport(importedAddresses) {
        aSync.eachSeries(importedAddresses, (address, next) => {
            address = {
                name: address.name || '',
                attention: address.attention || '',
                street: address.street || '',
                nr: address.nr || '',
                addition: address.addition || '',
                street2: address.street2 || '',
                postalCode: address.postalCode || '',
                city: address.city || '',
                country: address.country || 'NL',
                email: address.email || '',
                phone: address.phone || '',
                instructions: address.instructions || ''
            }

            AddressActions.update([address], (err) => {
                if (err) {
                    this.importModal.setError(err)
                } else {
                    next()
                }
            })
        }).then(() => {
            this.importModal.close()
        })
    }

    onClickExport() {
        const {selectedAddresses, addressBook} = this.state
        const exportAddresses = []

        selectedAddresses.map((id) => {
            const address = _.findWhere(addressBook, {_id: id})

            if (address) {
                exportAddresses.push(address)
            }
        })

        csvExport.addresses(exportAddresses)
        this.setState({selectedAddresses: []})
    }

    onClickEdit(address) {
        this.addressModal.open(address, this.onSaveAddress.bind(this))
    }

    onClickRemove() {
        const {selectedAddresses} = this.state

        this.popup.open('Adressen verwijderen', 'Adressen worden definitief verwijderd.', () => {
            AddressActions.remove(selectedAddresses)
            this.popup.close()
            this.setState({selectedAddresses: []})
        })
    }

    onChangeQueries(queries) {
        this.table.selectPage(1)
        this.setState({queries})
    }

    onTableChange(selectedAddresses) {
        this.setState({selectedAddresses})
    }

    render() {
        const {queries, selectedAddresses, addressBook} = this.state

        const addresses = filter.addresses(addressBook, queries)

        return (
            <div style={{display: 'flex', flexDirection: 'column', height: '100%'}}>
                <Panel style={{display: 'flex', borderTop: 'none', background: Colors.backgroundNeutral, padding: '20px 10px 10px 10px'}}>

                    <SearchBar
                        columns={this.columns()}
                        onQueryChange={this.onChangeQueries.bind(this)}
                        queries={queries}
                    />

                    <Button
                        variant='outline-white'
                        onClick={this.onClickNew.bind(this)}
                    >
                        Nieuw adres
                    </Button>

                    <Button
                        variant='outline-white'
                        onClick={this.onClickImport.bind(this)}
                    >
                        <i className='mdi mdi-file-import' />
                    </Button>

                    {selectedAddresses.length > 0 &&
                        <>
                            <Button
                                variant='outline-white'
                                onClick={this.onClickExport.bind(this)}
                            >
                                <i className='mdi mdi-file-export' />
                            </Button>

                            <Button
                                variant='outline-white'
                                onClick={this.onClickRemove.bind(this)}
                            >
                                <i className='mdi mdi-delete' />
                            </Button>
                        </>
                    }
                </Panel>

                <div style={{flex: 1, marginTop: 24, marginRight: 24, marginLeft: 24}}>
                    <Table
                        tableName='addressBook'
                        columns={this.columns()}
                        rows={addresses}
                        selectedRows={selectedAddresses}
                        onChange={this.onTableChange.bind(this)}
                        sortKey='name'
                        ref={(ref) => this.table = ref}
                    />
                </div>

                <AddressModal
                    reseller={this.props.reseller}
                    showInstructions
                    allowEmptyName
                    ref={(ref) => this.addressModal = ref}
                />

                <ImportModal
                    ref={(ref) => this.importModal = ref}
                    possibleColumns={possibleColumns}
                    isAddressBook
                    onChange={this.onChangeImport.bind(this)}
                />

                <Popup ref={(ref) => this.popup = ref} />
            </div>
        )
    }
}

const possibleColumns = {
    name: 'Naam',
    attention: 'T.a.v.',
    street: 'Straat',
    nr: 'Nr',
    addition: 'Toevoeging',
    streetNr: 'Straat + nr + toevoeging',
    nrAddition: 'Nr + toevoeging',
    street2: '2e adres regel',
    postalCode: 'Postcode',
    city: 'Plaats',
    country: 'Land',
    email: 'Emailadres',
    phone: 'Telefoon',
    instructions: 'Instructies'
}

export default AddressBook
