import Reflux from 'reflux-react-16'
import socket from '../socketio/socketio.js'
import _ from 'underscore'
import UserActions from '../actions/UserActions.js'

export default class UserStore extends Reflux.Store {
    constructor() {
        super()
        this.state = {
            userQueries: [],
            users: [],
            usersObject: {},
            selectedUsers: [],
            usersLoading: false
        }
        this.listenables = UserActions

        UserActions.get(true)

        socket.on('updateUsers', () => {
            UserActions.get()
        })
    }

    onSetQueries(userQueries) {
        this.setState({userQueries, selectedUsers: []})
    }

    onSetSelected(selectedUsers) {
        this.setState({selectedUsers})
    }

    onGet(loading) {
        const {selectedUsers} = this.state
        this.setState({usersLoading: !!loading})

        socket.emit('users.get', sessionStorage.token, (err, users) => {
            const usersObject = {}

            const ids = users.map((user) => {
                usersObject[user._id] = user
                return user._id
            })

            this.setState({
                users: _.sortBy(users, 'name'),
                usersObject,
                selectedUsers: _.filter(selectedUsers, (id) => ids.indexOf(id) > -1),
                usersLoading: false
            })
        })
    }

    onCreate(user, callback) {
        socket.emit('users.create', sessionStorage.token, user, callback)
    }

    onEdit(user, callback) {
        socket.emit('users.edit', sessionStorage.token, user, callback)
    }

    onRemove(ids, callback) {
        socket.emit('users.remove', sessionStorage.token, ids, callback)
    }

    onReset2FA(user, callback) {
        socket.emit('users.reset2FA', sessionStorage.token, user, callback)
    }
}
