import React from 'react'
import Reflux from 'reflux-react-16'

import moment from 'moment'
import _ from 'lodash'

import {returnRefundMethods} from '../../../utils/variables.js'

import AddressStore from '../../stores/AddressStore.js'
import UtilStore from '../../stores/UtilStore.js'
import UtilActions from '../../actions/UtilActions.js'

import LoadingPage from '../../components/UI/LoadingPage.js'
import NotFoundPage from '../../components/UI/NotFoundPage.js'

import {
    Address,
    Alert,
    Button,
    Card,
    Check,
    Colors,
    Input,
    H6,
    S2,
    P,
    PrintModal,
    RadioButton,
    Select,
    TextArea,
    Row,
    H3
} from '../../components/UI/index.js'
import ReturnLocationPicker from '../../components/map/ReturnLocationPicker.js'

export default class ReturnPortal extends Reflux.Component {
    constructor(props) {
        super(props)

        this.state = {
            view: 'search',
            portalLoading: true,
            portalError: '',
            customerURL: '',
            orderNumberOrTrackTrace: '',
            returnParcelRequest: {
                address: {
                    name: '',
                    attention: '',
                    street: '',
                    street2: '',
                    nr: '',
                    addition: '',
                    postalCode: '',
                    city: '',
                    country: 'NL',
                    email: '',
                    phone: ''
                },
                items: [],
                refundMethod: '',
                refundMethodComment: '',
                carrier: ''
            },
            loading: false,
            paymentAlert: '',
            message: '',
            error: ''
        }

        this.stores = [AddressStore, UtilStore]
    }

    componentDidMount() {
        const {customerURL, orderNumberOrTrackTrace = '', postalCode = ''} = this.props.match.params

        document.title = 'Retourportaal'

        if (customerURL) {
            UtilActions.getReturnCustomer(customerURL, (err, response) => {
                if (!err) {
                    this.setState({
                        ...response,
                        customerURL,
                        orderNumberOrTrackTrace,
                        postalCode,
                        portalLoading: false,
                        portalError: ''
                    }, () => {
                        if (orderNumberOrTrackTrace && postalCode) {
                            this.onClickGetParcelForReturn()
                        }
                    })
                } else {
                    this.setState({portalLoading: false, portalError: 'Geen geldige URL'})
                }
            })
        } else {
            this.setState({portalLoading: false, portalError: 'Geen geldige URL'})
        }
    }

    onChangeValue(path, event) {
        const returnParcelRequest = {...this.state.returnParcelRequest}

        _.set(returnParcelRequest, path, event.target.value)

        if (path.indexOf('returnReason') > -1 && path.indexOf('returnReasonComment') === -1) {
            returnParcelRequest.items[path.split('.')[1]].returnReasonComment = ''
        }

        this.setState({returnParcelRequest}, () => {
            if (path === 'carrier') {
                this.locationPickerRef.initMap()
            }
        })
    }

    onChangeChecked(path, event) {
        const returnParcelRequest = {...this.state.returnParcelRequest}

        _.set(returnParcelRequest, path, event.target.checked)

        this.setState({returnParcelRequest})
    }

    onChangeAddress(address) {
        const returnParcelRequest = {...this.state.returnParcelRequest}

        returnParcelRequest.address = address

        this.setState({returnParcelRequest})
    }

    onClickGetParcelForReturn() {
        const {customerURL, orderNumberOrTrackTrace, postalCode} = this.state

        this.setState({loading: true, error: ''})

        UtilActions.getParcelForReturn(customerURL, orderNumberOrTrackTrace, postalCode, (err, response) => {
            if (!err) {
                const searchParams = new URLSearchParams(location.search)
                const origin = searchParams.get('origin')

                if (origin === 'mollie') {
                    if (response.returnParcelRequest.paymentStatus === 'paid') {
                        this.setState({paymentAlert: 'De betaling is gelukt.'})
                    } else if (response.returnParcelRequest.paymentStatus === 'pending') {
                        setTimeout(() => {
                            this.onClickGetParcelForReturn()
                            this.setState({paymentAlert: 'De betaling wordt verwerkt...'})
                        }, 1000)
                    } else {
                        this.setState({paymentAlert: 'Helaas is de betaling niet gelukt, klik op de knop hieronder om het nog eens te proberen.'})
                    }
                }

                this.setState({
                    ...response,
                    view: response.returnParcelRequest.retourId ? 'summary' : 'info',
                    loading: false
                })
            } else {
                this.setState({loading: false, error: err})
            }
        })
    }

    onClickCreateReturnParcel() {
        const {returnParcelRequest, customerName} = this.state

        this.setState({loading: true, error: ''})

        const paymentRequired = this.onCheckPaymentRequired()

        UtilActions.createReturnParcel(returnParcelRequest, (err, returnParcel) => {
            if (err) {
                this.setState({loading: false, error: `Er is iets fout gegaan bij het aanmelden van je retour. Neem contact op met ${customerName}`})
            } else {
                this.setState({loading: false})
                if (!paymentRequired) {
                    this.setState({label: returnParcel.label})
                } else {
                    this.onCreatePayment(returnParcel)
                }
            }
        })
    }

    onCreatePayment(returnParcel) {
        const {returnParcelRequest, customerURL} = this.state

        if (!returnParcel) {
            returnParcel = {
                _id: returnParcelRequest.retourId,
                options: ['Retour']
            }
        }

        UtilActions.createPayment(returnParcelRequest, returnParcel, customerURL, (err, paymentData) => {
            if (err) {
                this.setState({loading: false, error: `Er is iets fout gegaan met het aanmaken van de betaling. ${err}`})
            } else {
                this.setState({loading: false})
                location = paymentData.checkoutLink
            }
        })
    }

    onCheckPaymentRequired() {
        const {returnParcelRequest, returnShippingCosts} = this.state

        if (!returnParcelRequest.mollieIsActive) {
            return false
        }

        return parseFloat(returnShippingCosts[returnParcelRequest.carrier]?.value.replace(',', '.')) > 0
    }

    render() {
        const {view, color, orderNumberOrTrackTrace, postalCode, returnParcelRequest, returnShippingCosts, sendRetourConfirmationEmail, portalLoading, portalError, loading, message, error, customerURL, label} = this.state

        if (portalLoading) {
            return <LoadingPage />
        }

        if (portalError) {
            return <NotFoundPage />
        }

        return (
            <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', height: '100vh', overflowY: 'auto', background: Colors.backgroundNeutral}}>
                <div style={{flex: 1, display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%'}}>
                    <Card shadow='true' style={{display: 'flex', flex: 0, flexDirection: 'column', alignItems: 'center', minWidth: 450, maxWidth: 1000, padding: 24, marginTop: 120, marginLeft: 'auto', marginRight: 'auto'}}>
                        <img src={this.state.logo ? `/${this.state.logo}` : '/images/VELOYDLOGO.png'} alt='Logo' style={{maxWidth: 128, maxHeight: 128, marginBottom: 50, objectFit: 'contain'}} />

                        {view === 'search' &&
                            <>
                                <H3>Start je retouraanvraag</H3>
                                <P style={{maxWidth: 400, marginBottom: 48}}>Vul de Track & Trace code en postcode in om je bestelling te zoeken.</P>

                                <Row>
                                    <Input
                                        style={{maxWidth: 400}}
                                        label='Ordernummer of Track & Trace'
                                        value={orderNumberOrTrackTrace}
                                        onChange={(event) => this.setState({orderNumberOrTrackTrace: event.target.value})}
                                    />
                                </Row>

                                <Row>
                                    <Input
                                        style={{maxWidth: 400}}
                                        label='Postcode'
                                        value={postalCode}
                                        onChange={(event) => this.setState({postalCode: event.target.value.toUpperCase()})}
                                    />
                                </Row>

                                <Button
                                    variant='solid'
                                    color={color}
                                    style={{marginTop: 30, marginLeft: 0, width: '100%', maxWidth: 400}}
                                    loading={loading}
                                    disabled={!orderNumberOrTrackTrace || !postalCode}
                                    onClick={this.onClickGetParcelForReturn.bind(this)}
                                >
                                    Zoeken
                                </Button>
                            </>
                        }

                        {view === 'info' &&
                            <>
                                <H3 style={{maxWidth: 400}}>Jouw gegevens</H3>

                                <Address
                                    style={{marginBottom: 12, width: '100%', maxWidth: 400}}
                                    address={returnParcelRequest.address}
                                    noTAV
                                    noStreet2
                                    noCountry
                                    email
                                    phone
                                    onChange={this.onChangeAddress.bind(this)}
                                />

                                <Button
                                    variant='solid'
                                    color={color}
                                    style={{marginTop: 30, marginLeft: 0, width: '100%', maxWidth: 400}}
                                    disabled={!returnParcelRequest.address.email || !returnParcelRequest.address.name || !returnParcelRequest.address.postalCode || !returnParcelRequest.address.nr || !returnParcelRequest.address.name}
                                    onClick={() => this.setState({
                                        view: returnParcelRequest.items.length ?
                                            'items' :
                                            this.state.returnRefundMethods.length > 1 || returnParcelRequest.refundMethod === 'exchange' ?
                                                'refund' :
                                                'shipping'
                                    })}
                                >
                                    Volgende
                                </Button>
                            </>
                        }

                        {view === 'items' &&
                            <>
                                <H3 style={{maxWidth: 400}}>Selecteer de items die je wilt retourneren</H3>

                                {returnParcelRequest.items.map((item, index) => {
                                    return (
                                        <Card
                                            key={`item${index}`}
                                            style={{marginBottom: 12, width: '100%', maxWidth: 400}}
                                        >
                                            <div style={{display: 'flex', alignItems: 'center'}}>
                                                <Check
                                                    label={`${item.description}`}
                                                    checked={item.return}
                                                    onChange={this.onChangeChecked.bind(this, `items.${index}.return`)}
                                                />

                                                <S2 style={{width: 80}}>{`Aantal: ${item.quantity}`}</S2>
                                            </div>

                                            {item.return &&
                                                <Input
                                                    style={{marginTop: 15}}
                                                    type='number'
                                                    label='Aantal'
                                                    value={item.returnQuantity}
                                                    min={1}
                                                    max={item.quantity}
                                                    onChange={this.onChangeValue.bind(this, `items.${index}.returnQuantity`)}
                                                />
                                            }

                                            {item.return && !!this.state.returnReasons.length &&
                                                <Select
                                                    label='Selecteer een reden'
                                                    value={item.returnReason}
                                                    onChange={this.onChangeValue.bind(this, `items.${index}.returnReason`)}
                                                >
                                                    {this.state.returnReasons.map((reason, index) => {
                                                        return (
                                                            <option key={`reason${index}`} value={reason}>{reason}</option>
                                                        )
                                                    })}
                                                </Select>
                                            }

                                            {item.return && item.returnReason === 'Anders:' &&
                                                <TextArea
                                                    rows={4}
                                                    label='Toelichting'
                                                    value={item.returnReasonComment}
                                                    onChange={this.onChangeValue.bind(this, `items.${index}.returnReasonComment`)}
                                                />
                                            }
                                        </Card>
                                    )
                                })}

                                <Button
                                    variant='solid'
                                    color={color}
                                    style={{marginTop: 30, marginLeft: 0, width: '100%', maxWidth: 400}}
                                    disabled={!_.some(returnParcelRequest.items, 'return')}
                                    onClick={() => this.setState({
                                        view: this.state.returnRefundMethods.length > 1 || returnParcelRequest.refundMethod === 'exchange' ?
                                            'refund' :
                                            'shipping'
                                    })}
                                >
                                    Volgende
                                </Button>
                            </>
                        }

                        {view === 'refund' &&
                            <>
                                <H3 style={{maxWidth: 400}}>Kies de gewenste terugbetaling</H3>

                                {this.state.returnRefundMethods.map((refundMethod) => {
                                    return (
                                        <Card
                                            key={`refundMethod${refundMethod}`}
                                            style={{marginBottom: 12, width: '100%', maxWidth: 400}}
                                        >
                                            <RadioButton
                                                checked={returnParcelRequest.refundMethod === refundMethod}
                                                label={returnRefundMethods[refundMethod].title}
                                                value={refundMethod}
                                                onChange={this.onChangeValue.bind(this, 'refundMethod')}
                                            />

                                            <S2 style={{flex: 1}}>{returnRefundMethods[refundMethod].info}</S2>

                                            {refundMethod === 'exchange' && returnParcelRequest.refundMethod === refundMethod &&
                                                <TextArea
                                                    style={{marginTop: 15}}
                                                    label='Geef hier aan waarvoor je wilt ruilen'
                                                    value={returnParcelRequest.refundMethodComment}
                                                    onChange={this.onChangeValue.bind(this, 'refundMethodComment')}
                                                />
                                            }
                                        </Card>
                                    )
                                })}

                                <Button
                                    variant='solid'
                                    color={color}
                                    style={{marginTop: 30, marginLeft: 0, width: '100%', maxWidth: 400}}
                                    disabled={!returnParcelRequest.refundMethod || (returnParcelRequest.refundMethod === 'exchange' && !returnParcelRequest.refundMethodComment)}
                                    onClick={() => this.setState({view: 'shipping'})}
                                >
                                    Volgende
                                </Button>
                            </>
                        }

                        {view === 'shipping' &&
                            <>
                                <H3 style={{maxWidth: 400}}>Kies de gewenste verzendmethode</H3>

                                {Object.keys(returnShippingCosts).map((carrier, index) => {
                                    let carrierName = carrier
                                    if (carrierName.includes('Onlineparcel')) {
                                        carrierName = carrierName.replace(' - Onlineparcel', '')
                                    }

                                    if (carrierName.includes('QLS')) {
                                        carrierName = carrierName.replace('-QLS', '')
                                    }

                                    if (carrierName.includes('MSG')) {
                                        carrierName = carrierName.replace('-MSG', '')
                                    }

                                    return (
                                        returnShippingCosts[carrier].enabled && (
                                            <Card key={`returnCarrier${index}`} style={{marginBottom: 12, width: '100%', maxWidth: 400}}>
                                                <RadioButton
                                                    label={`${carrierName} Standaard zending`}
                                                    value={carrier}
                                                    checked={returnParcelRequest.carrier === carrier}
                                                    onChange={this.onChangeValue.bind(this, 'carrier')}
                                                />

                                                <S2>{`Verzendkosten: € ${returnShippingCosts[carrier].value}`}</S2>
                                            </Card>
                                        )
                                    )
                                })}

                                <ReturnLocationPicker
                                    returnParcelRequest={returnParcelRequest}
                                    customerURL={customerURL}
                                    ref={(ref) => this.locationPickerRef = ref}
                                />

                                <Button
                                    variant='solid'
                                    color={color}
                                    style={{marginTop: 30, marginLeft: 0, width: '100%', maxWidth: 400}}
                                    label='Volgende'
                                    disabled={!returnParcelRequest.carrier}
                                    onClick={() => this.setState({view: 'summary'})}
                                />
                            </>
                        }

                        {view === 'summary' &&
                            <>
                                <H3 style={{maxWidth: 400}}>Samenvatting</H3>

                                <H6 style={{maxWidth: 400}}>Jouw gegevens</H6>

                                <Card style={{marginBottom: 12, width: '100%', maxWidth: 400}}>

                                    <P>{returnParcelRequest.address.name}</P>
                                    <P>{`${returnParcelRequest.address.street} ${returnParcelRequest.address.nr}${returnParcelRequest.address.addition}`}</P>
                                    <P>{`${returnParcelRequest.address.postalCode} ${returnParcelRequest.address.city}`}</P>
                                    <br />

                                    <P>{returnParcelRequest.address.email}</P>
                                </Card>

                                {!returnParcelRequest.retourId && !label &&
                                    <Button
                                        variant='outline'
                                        style={{width: '100%', maxWidth: 400}}
                                        icon='mdi mdi-pencil'
                                        label='Wijzig gegevens'
                                        onClick={() => this.setState({view: 'info'})}
                                    />
                                }

                                {!!returnParcelRequest.items.length &&
                                    <>
                                        <H6 style={{marginTop: 30, maxWidth: 400}}>Te retourneren items</H6>

                                        {returnParcelRequest.items.map((item, index) => {
                                            if (item.return) {
                                                return (
                                                    <Card
                                                        key={`item${index}`}
                                                        style={{marginBottom: 12, width: '100%', maxWidth: 400}}
                                                    >
                                                        <div style={{display: 'flex', alignItems: 'center', marginBottom: 12}}>
                                                            <P style={{flex: 1}}>{item.description}</P>

                                                            <S2>{`Aantal: ${item.returnQuantity}`}</S2>
                                                        </div>

                                                        <S2>{`${item.returnReason || ''} ${item.returnReasonComment || ''}`}</S2>
                                                    </Card>
                                                )
                                            }
                                        })}

                                        {!returnParcelRequest.retourId && !label &&
                                            <Button
                                                variant='outline'
                                                style={{width: '100%', maxWidth: 400}}
                                                icon='mdi mdi-pencil'
                                                label='Wijzig items'
                                                onClick={() => this.setState({view: 'items'})}
                                            />
                                        }
                                    </>
                                }

                                {returnParcelRequest.refundMethod &&
                                    <>
                                        <H6 style={{marginTop: 30, maxWidth: 400}}>Terugbetaling</H6>

                                        <Card style={{marginBottom: 12, width: '100%', maxWidth: 400}}>
                                            <P style={{flex: 1, marginBottom: 12}}>{returnRefundMethods[returnParcelRequest.refundMethod].title}</P>

                                            <S2 style={{flex: 1}}>
                                                {
                                                    returnParcelRequest.refundMethod === 'exchange' ?
                                                        returnParcelRequest.refundMethodComment :
                                                        returnRefundMethods[returnParcelRequest.refundMethod].info
                                                }
                                            </S2>
                                        </Card>

                                        {!returnParcelRequest.retourId && !label &&
                                            <Button
                                                variant='outline'
                                                style={{width: '100%', maxWidth: 400}}
                                                icon='mdi mdi-pencil'
                                                label='Wijzig terugbetaling'
                                                onClick={() => this.setState({view: 'refund'})}
                                            />
                                        }
                                    </>
                                }

                                <H6 style={{marginTop: 30, maxWidth: 400}}>Verzendmethode</H6>

                                <Card style={{marginBottom: 12, width: '100%', maxWidth: 400}}>
                                    <P style={{flex: 1, marginBottom: 12}}>
                                        {`${returnParcelRequest.carrier.replace(/\s?-\s?(Onlineparcel|QLS|MSG)/g, '')} Standaard zending`}
                                    </P>

                                    <S2>
                                        {`Verzendkosten: € ${returnShippingCosts[returnParcelRequest.carrier].value}`}
                                    </S2>
                                </Card>

                                {!returnParcelRequest.retourId && !label &&
                                    <Button
                                        variant='outline'
                                        style={{width: '100%', maxWidth: 400, marginBottom: 24}}
                                        icon='mdi mdi-pencil'
                                        label='Wijzig verzendmethode'
                                        onClick={() => this.setState({view: 'shipping'})}
                                    />
                                }

                                <ReturnLocationPicker
                                    returnParcelRequest={returnParcelRequest}
                                    customerURL={customerURL}
                                />

                                {this.state.paymentAlert &&
                                    <Alert
                                        style={{width: '100%', maxWidth: 400}}
                                        variant={returnParcelRequest.paymentStatus === 'paid' ? 'success' : returnParcelRequest.paymentStatus === 'pending' ? 'info' : 'error'}
                                    >
                                        {this.state.paymentAlert}
                                    </Alert>
                                }

                                {this.state.label && returnParcelRequest.address.email && sendRetourConfirmationEmail &&
                                    <Alert
                                        style={{margin: '20px 0', width: '100%', maxWidth: 400}}
                                        variant='success'
                                    >
                                        Een email met verzendlabel is verzonden naar <i>{returnParcelRequest.address.email}</i>.
                                    </Alert>
                                }

                                {!this.state.label &&
                                    <Button
                                        variant='solid'
                                        color={color}
                                        style={{marginTop: 24, width: '100%', maxWidth: 400, marginBottom: 24}}
                                        loading={loading}
                                        label={this.onCheckPaymentRequired() ? 'Bevestig en betaal' : 'Bevestig'}
                                        onClick={this.onClickCreateReturnParcel.bind(this)}
                                    />
                                }

                                {this.state.label && (this.onCheckPaymentRequired() ? returnParcelRequest.paymentStatus === 'paid' : true) &&
                                    <Button
                                        variant='solid'
                                        color={color}
                                        style={{marginTop: 24, width: '100%', maxWidth: 400, marginBottom: 24}}
                                        icon='mdi mdi-print'
                                        label=' Print label'
                                        onClick={() => this.printModal.open(this.state.label)}
                                    />
                                }

                                {this.state.label && (this.onCheckPaymentRequired() && returnParcelRequest.paymentStatus !== 'paid') &&
                                    <Button
                                        variant='solid'
                                        color={color}
                                        style={{marginTop: 24, width: '100%', maxWidth: 400, marginBottom: 24}}
                                        label=' Betaal'
                                        onClick={this.onCreatePayment.bind(this, null)}
                                    />
                                }
                            </>
                        }

                        {message &&
                            <Alert variant='info' style={{marginTop: 15, maxWidth: 400}}>{message}</Alert>
                        }
                        {error &&
                            <Alert variant='danger' style={{marginTop: 15, maxWidth: 400}}>{error}</Alert>
                        }

                        {this.state.returnPolicyURL &&
                            <S2 style={{marginTop: 25}}>
                                Klik
                                <a target='_blank' style={{color}} href={withHttp(this.state.returnPolicyURL)} rel='noreferrer'>
                                    hier
                                </a> voor meer informatie over ons retourbeleid
                            </S2>
                        }
                    </Card>
                </div>

                <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%', padding: 24}}>
                    <img style={{objectFit: 'contain', width: 100}} src='/images/VELOYDLOGO.png' alt='Logo' />

                    <div style={{display: 'flex', marginTop: 12}}>
                        <P>&copy; {moment().year()} Veloyd</P>
                        <P style={{marginLeft: 6, marginRight: 6}}>|</P>
                        <P><a style={{color: Colors.textDark}} target='_blank' href='https://veloyd.nl/privacy-policy/' rel='noreferrer'>Privacy policy</a></P>
                    </div>
                </div>

                <PrintModal ref={(ref) => this.printModal = ref} />
            </div>
        )
    }
}

const withHttp = (url) => !/^https?:\/\//i.test(url) ? `http://${url}` : url
