import React from 'react'
import _ from 'lodash'

import {AddressSearch, Check, Colors, TimePicker, Input, Select, TextArea, RadioGroup, Row, Column, Option, AddressAttachment} from '../UI/index.js'
import PostalCodeAutocomplete from '../UI/PostalCodeAutocomplete.js'

import Countries from '../../utils/Countries.js'
import AddressActions from '../../actions/AddressActions.js'

class EditAddress extends React.PureComponent {
    constructor(props) {
        super(props)

        this.state = {
            address: null,
            suggestions: [],
            validation: {}
        }
    }

    componentDidMount() {
        if (this.props.onSave) {
            this.setState({address: JSON.parse(JSON.stringify(this.props.address))})
        }
    }

    setName(event, changeOnExactMatch = true) {
        const {validation} = this.state

        if (validation.name) {
            validation.name = null
            this.setState({validation})
        }

        const {addressBook = []} = this.props
        const address = JSON.parse(JSON.stringify(this.props.address))

        const suggestions = event.target.value.length < 1 ?
                [] :
            addressBook.filter((value) => {
                return value.name.toLowerCase().indexOf(event.target.value.toLowerCase()) > -1
            })

        address.name = event.target.value.length === 1 ? event.target.value.toUpperCase() : event.target.value

        const suggestionIndex = suggestions.findIndex((suggestion) => suggestion.name === event.target.value)

        const suggestion = suggestions[suggestionIndex]

        if (suggestion) {
            if (changeOnExactMatch) {
                address.attention = suggestion.attention || ''
                address.street = suggestion.street || ''
                address.nr = suggestion.nr || ''
                address.addition = suggestion.addition || ''
                address.street2 = suggestion.street2 || ''
                address.postalCode = suggestion.postalCode || ''
                address.city = suggestion.city || ''
                address.country = suggestion.country || 'NL'
                address.isBusiness = !!suggestion.isBusiness
                address.email = suggestion.email || address.email || ''
                address.phone = suggestion.phone || address.phone || ''
                address.comment = suggestion.comment || address.comment || ''
                address.instructions = suggestion.instructions || address.instructions || ''
                address.attachments = suggestion.attachments || []
                if (suggestion.position) {
                    address.position = suggestion.position
                } else {
                    delete address.position
                }

                typeof this.props.onConfirm === 'function' && this.props.onConfirm()
            }
        }

        this.setState({suggestions: suggestions})

        if (this.props.onSave) {
            this.setState({address})
        } else {
            this.props.onChange(address)
        }
    }

    onChangeAddress(updatedAddress) {
        if (this.props.onSave) {
            const address = {...this.state.address, ...updatedAddress}

            if (!updatedAddress.position) {
                delete address.position
            }

            this.setState({address})
        } else {
            const address = {...this.props.address, ...updatedAddress}

            if (!updatedAddress.position) {
                delete address.position
            }

            this.props.onChange(address)
        }
    }

    onChangeValue(path, parser, event) {
        if (!event) {
            event = parser
            parser = undefined
        }

        let address = {...this.props.address}

        if (this.props.onSave) {
            address = {...this.state.address}
        }

        if (typeof parser === 'function') {
            _.set(address, path, parser(event.target.value))
        } else {
            _.set(address, path, event.target.value)
        }

        if (this.props.onSave) {
            this.setState({address})
        } else {
            this.props.onChange(address)
        }
    }

    onChangeOptions(event) {
        let address = {...this.props.address}

        if (this.props.onSave) {
            address = {...this.state.address}
        }

        address.gpsRequired = false
        address.signatureRequired = false
        address.signatureNeighbors = false
        address.nameRequired = false
        address.statedAddressOnly = false
        address.mailboxDelivery = false
        address.scanRequired = false
        address.imageRequired = false
        address.minimumAge = false
        address.idCheck = false

        event.target.values.map((key) => {
            if (key === 'minimumAge +16') {
                address.minimumAge = 16
            } else if (key === 'minimumAge +18') {
                address.minimumAge = 18
            } else if (key === 'minimumAge +21') {
                address.minimumAge = 21
            } else {
                address[key] = true
            }
        })

        if (this.props.onSave) {
            this.setState({address})
        } else {
            this.props.onChange(address, true)
        }
    }

    onChangeChecked(path) {
        let address = {...this.props.address}

        if (this.props.onSave) {
            address = {...this.state.address}
        }

        address.disabledDays = address.disabledDays || {}

        _.set(address, path, !_.get(address, path))

        if (this.props.onSave) {
            this.setState({address})
        } else {
            this.props.onChange(address)
        }
    }

    save() {
        if (this.props.onSave) {
            this.props.onSave(this.state.address)
        }

        if (this.state.address.save) {
            AddressActions.update([this.state.address])
        }
    }

    render() {
        let {id = '', editAddressTypeAllowed, address, showDays, availableDays, allowAttachments, readOnly} = this.props
        const {expanded, validation, suggestions} = this.state

        if (this.props.onSave) {
            address = {...this.state.address}
        }

        address.options = []

        if (address.gpsRequired) {
            address.options.push('gpsRequired')
        }

        if (address.signatureRequired) {
            address.options.push('signatureRequired')
        }

        if (address.signatureNeighbors) {
            address.options.push('signatureNeighbors')
        }

        if (address.nameRequired) {
            address.options.push('nameRequired')
        }

        if (address.statedAddressOnly) {
            address.options.push('statedAddressOnly')
        }

        if (address.mailboxDelivery) {
            address.options.push('mailboxDelivery')
        }

        if (address.scanRequired) {
            address.options.push('scanRequired')
        }

        if (address.imageRequired) {
            address.options.push('imageRequired')
        }

        if (address.minimumAge) {
            if (address.minimumAge === 16) {
                address.options.push('minimumAge +16')
            }

            if (address.minimumAge === 18) {
                address.options.push('minimumAge +18')
            }

            if (address.minimumAge === 21) {
                address.options.push('minimumAge +21')
            }
        }

        if (address.idCheck) {
            address.options.push('idCheck')
        }

        const countries = [
            {value: 'NL', title: 'Nederland', bold: true},
            {value: 'BE', title: 'België', bold: true},
            {value: 'DE', title: 'Duitsland', bold: true},
            {value: 'FR', title: 'Frankrijk', bold: true},
            {value: 'IT', title: 'Italië', bold: true},
            {value: 'ES', title: 'Spanje', bold: true},
            {value: 'GB', title: 'Verenigd Koninkrijk', bold: true},
            {value: 'US', title: 'Verenigde Staten', bold: true}
        ]

        Countries.map((country) => {
            countries.push({value: country.code2, title: country.name})
        })

        return (
            <div style={{width: '100%', background: Colors.backgroundNeutral2}}>
                <Row>
                    <Column>
                        <RadioGroup
                            disabled={!editAddressTypeAllowed || readOnly}
                            value={address.type}
                            options={[
                                {value: 'pickup', label: 'Ophalen'},
                                {value: 'delivery', label: 'Bezorgen'}
                            ]}
                            onChange={this.onChangeValue.bind(this, 'type')}
                        />

                        <RadioGroup
                            disabled={readOnly}
                            value={(!!address.isBusiness).toString()}
                            options={[
                                {value: 'false', icon: 'mdi mdi-home'},
                                {value: 'true', icon: 'mdi mdi-store'}
                            ]}
                            onChange={this.onChangeValue.bind(this, 'isBusiness', (value) => value === 'true')}
                        />
                    </Column>

                    <Column>
                        <TimePicker
                            disabled={readOnly}
                            label='Starttijd'
                            time={address.startTime}
                            onChange={this.onChangeValue.bind(this, 'startTime')}
                        />

                        <TimePicker
                            disabled={readOnly}
                            label='Eindtijd'
                            time={address.endTime}
                            onChange={this.onChangeValue.bind(this, 'endTime')}
                        />
                    </Column>
                </Row>

                <Row>
                    <Column>
                        <Input
                            id={`name${id}`}
                            label='Naam'
                            value={address.name}
                            isInvalid={validation.name}
                            onChange={(event) => this.setName(event, false)}
                            suggestions={suggestions.map((suggestion) => {
                                const addressText = ` (${suggestion.street} ${suggestion.nr}${suggestion.addition}, ${suggestion.postalCode} ${suggestion.city})`
                                return {
                                    label: `${suggestion.name} ${addressText}`,
                                    value: suggestion.name
                                }
                            })}
                            onChooseSuggestion={this.setName.bind(this)}
                            suggestionFilter
                            autocomplete='off'
                            disabled={readOnly}
                        />
                    </Column>

                    <Column>
                        {address.type === 'pickup' &&
                            <Select
                                label='Opties'
                                values={address.options}
                                options={[
                                    {value: 'gpsRequired', title: 'GPS verplicht'},
                                    {value: 'scanRequired', title: 'Scan verplicht'},
                                    {value: 'imageRequired', title: 'Foto verplicht'}
                                ]}
                                onChange={this.onChangeOptions.bind(this)}
                                disabled={readOnly}
                            />
                        }

                        {address.type === 'delivery' &&
                            <Select
                                label='Opties'
                                values={address.options}
                                options={[
                                    {value: 'gpsRequired', title: 'GPS verplicht'},
                                    {value: 'scanRequired', title: 'Scan verplicht'},
                                    {value: 'imageRequired', title: 'Foto verplicht'},
                                    {value: 'signatureRequired', title: 'Handtekening'},
                                    {value: 'signatureNeighbors', title: 'Handtekening buren'},
                                    {value: 'nameRequired', title: 'Naam verplicht'},
                                    {value: 'statedAddressOnly', title: 'Niet bij buren'},
                                    {value: 'minimumAge +16', title: 'Leeftijdscheck +16'},
                                    {value: 'minimumAge +18', title: 'Leeftijdscheck +18'},
                                    {value: 'minimumAge +21', title: 'Leeftijdscheck +21'},
                                    {value: 'idCheck', title: 'ID check'},
                                    {value: 'mailboxDelivery', title: 'Brievenbus'}
                                ]}
                                onChange={this.onChangeOptions.bind(this)}
                                disabled={readOnly}
                            />
                        }
                    </Column>
                </Row>

                <Row>
                    <Column>
                        {!expanded &&
                            <AddressSearch
                                id={`address${id}`}
                                defaultAddress={address}
                                address={address}
                                onChange={this.onChangeAddress.bind(this)}
                                disabled={readOnly}
                            />
                        }

                        {expanded &&
                            <Input
                                id={`attention${id}`}
                                label='Ter attentie van'
                                value={address.attention}
                                onChange={this.onChangeValue.bind(this, 'attention')}
                                disabled={readOnly}
                            />
                        }
                    </Column>

                    <Column>
                        <TextArea
                            label='Instructies'
                            value={address.instructions}
                            onChange={this.onChangeValue.bind(this, 'instructions')}
                            disabled={readOnly}
                        />
                    </Column>
                </Row>

                {expanded &&
                    <>
                        <div style={{marginTop: 12}} />

                        <PostalCodeAutocomplete disabled={readOnly} address={address} onChange={this.onChangeAddress.bind(this)} />

                        <Row style={{flexWrap: 'wrap'}}>
                            <Column>
                                <Input
                                    id={`street${id}`}
                                    style={{minWidth: 200}}
                                    label='Straat'
                                    value={address.street || ''}
                                    isInvalid={validation.street}
                                    onChange={this.onChangeValue.bind(this, 'street')}
                                    disabled={readOnly}
                                />
                            </Column>

                            <Column>
                                <Input
                                    style={{minWidth: 200}}
                                    label='2e adres regel'
                                    value={address.street2}
                                    onChange={this.onChangeValue.bind(this, 'street2')}
                                    disabled={readOnly}
                                />
                            </Column>
                        </Row>

                        <Row style={{flexWrap: 'wrap'}}>
                            <Column>
                                <Input
                                    id={`city${id}`}
                                    style={{minWidth: 200}}
                                    label='Plaats'
                                    value={address.city}
                                    isInvalid={validation.city}
                                    onChange={this.onChangeValue.bind(this, 'city')}
                                    disabled={readOnly}
                                />
                            </Column>

                            <Column>
                                <Select
                                    style={{minWidth: 200}}
                                    label='Land'
                                    value={address.country}
                                    options={countries}
                                    onChange={this.onChangeValue.bind(this, 'country')}
                                    disabled={readOnly}
                                />
                            </Column>
                        </Row>

                        <Row style={{flexWrap: 'wrap'}}>
                            <Column>
                                <Input
                                    style={{minWidth: 200}}
                                    label='Emailadres'
                                    value={address.email}
                                    onChange={this.onChangeValue.bind(this, 'email')}
                                    disabled={readOnly}
                                />
                            </Column>

                            <Column>
                                <Input
                                    style={{minWidth: 200}}
                                    label='Telefoon'
                                    value={address.phone}
                                    onChange={this.onChangeValue.bind(this, 'phone')}
                                    disabled={readOnly}
                                />
                            </Column>
                        </Row>

                        <Row style={{marginBottom: 12}}>
                            <Column style={{flexDirection: 'column'}}>
                                <Check
                                    style={{marginBottom: 12, minWidth: 200}}
                                    label='Opslaan in adresboek'
                                    checked={address.save}
                                    onChange={this.onChangeChecked.bind(this, 'save')}
                                    disabled={readOnly}
                                />

                                {allowAttachments && showDays &&
                                    <AddressAttachment
                                        edit={!readOnly}
                                        address={address}
                                        onChange={this.onChangeAddress.bind(this)}
                                        fileUploadStyle={{height: 36}}
                                    />
                                }
                            </Column>

                            {allowAttachments && !showDays &&
                                <Column style={{display: 'block', marginLeft: 12}}>
                                    <AddressAttachment
                                        edit={!readOnly}
                                        address={address}
                                        onChange={this.onChangeAddress.bind(this)}
                                        fileUploadStyle={{height: 36}}
                                    />
                                </Column>
                            }

                            {showDays &&
                                <Column style={{flexWrap: 'wrap', marginLeft: 6}}>
                                    {[
                                        {title: 'M', value: 'monday'},
                                        {title: 'D', value: 'tuesday'},
                                        {title: 'W', value: 'wednesday'},
                                        {title: 'D', value: 'thursday'},
                                        {title: 'V', value: 'friday'},
                                        {title: 'Z', value: 'saturday'},
                                        {title: 'Z', value: 'sunday'}
                                    ].map((entry) => {
                                        return (
                                            <Option
                                                key={entry.value}
                                                label={entry.title}
                                                checked={!address.disabledDays?.[entry.value]}
                                                disabled={!availableDays[entry.value] || readOnly}
                                                onChange={this.onChangeChecked.bind(this, `disabledDays.${entry.value}`)}
                                            />
                                        )
                                    })}
                                </Column>
                            }
                        </Row>
                    </>
                }

                <Row
                    style={{justifyContent: 'center', cursor: 'pointer'}}
                    onClick={() => {
                        this.setState({expanded: !expanded})
                    }}
                >
                    {expanded ?
                            <i className='mdi mdi-chevron-up' /> :
                            <i className='mdi mdi-chevron-down' />
                    }
                </Row>
            </div>
        )
    }
}

export default EditAddress
