import React from 'react'
import {Colors} from './index.js'

class Popover extends React.Component {
    constructor(props) {
        super(props)

        this.state = {show: false}

        // important for event listeners
        this.close = this.close.bind(this)
        this.outsideClickClose = this.outsideClickClose.bind(this)
        this.updatePosition = this.updatePosition.bind(this)
    }

    outsideClickClose(event) {
        this.close(event, true)
    }

    componentDidMount() {
        window.addEventListener('mousedown', this.outsideClickClose)
        this.updatePosition()
    }

    componentDidUpdate(prevProps, prevState) {
        if (!this.props.children && prevProps.inputRef === undefined && this.props.inputRef) {
            this.updatePosition()
        }

        if (this.props.onToggle && typeof this.props.onToggle === 'function' && prevState.show !== this.state.show) {
            this.props.onToggle(this.state.show)
        }
    }

    componentWillUnmount() {
        window.removeEventListener('mousedown', this.outsideClickClose)
        window.removeEventListener('scroll', this.updatePosition)
        window.removeEventListener('resize', this.updatePosition)
    }

    updatePosition() {
        const {inputRef = undefined, children} = this.props

        const rect = children ? this.child?.getBoundingClientRect() : inputRef?.getBoundingClientRect() || {}

        if (rect) {
            this.setState({offsetTop: rect.height, parentWidth: rect.width, rect, offsetBottom: undefined}, () => {
                this.smartPlacementUpdate()
            })
        }
    }

    smartPlacementUpdate() {
        const {smartPlacement} = this.props

        if (smartPlacement) {
            const popoverRect = this.popoverEl?.getBoundingClientRect()

            const overflowsBottom = popoverRect.bottom > (window.innerHeight || document.documentElement.clientHeight)

            if (overflowsBottom) {
                this.setState({offsetBottom: '100%', offsetTop: undefined})
            }
        }
    }

    show() {
        const {scrollAnchorElement} = this.props
        this.updatePosition()

        window.addEventListener('scroll', this.updatePosition)
        window.addEventListener('resize', this.updatePosition)

        if (scrollAnchorElement) {
            scrollAnchorElement.addEventListener('scroll', () => {
                this.updatePosition()
            })
        } else {
            const modal = document.querySelector('.veloyd_modal[data-is-open=\'true\']')
            if (modal) {
                modal.addEventListener('scroll', () => {
                    this.updatePosition()
                })
            }
        }

        this.setState({show: true}, () => {
            this.smartPlacementUpdate()
        })
    }

    close(event, outsideClick) {
        if (outsideClick && this.state.show) {
            this.props.onOutsideClick?.()
        }
        this.setState({show: false})
    }

    closed() {
        return !this.state.show
    }

    render() {
        const {children, placement, content, popoverStyle = {}, disabled, noClose, noClickOpen} = this.props
        const {show, offsetTop, parentWidth, rect, offsetBottom} = this.state

        let defaultPopoverStyle = {
            display: !disabled && show ? 'block' : 'none',
            zIndex: 10,
            position: 'absolute',
            top: offsetTop,
            whiteSpace: 'nowrap',
            width: 'auto',
            minWidth: parentWidth,
            background: 'white',
            border: !disabled && show ? `1px solid ${Colors.grey20}` : 'none',
            boxShadow: !disabled && show ? `0 3px 6px ${Colors.border}, 0 3px 6px rgba(0,0,0,0.23)` : '',
            bottom: offsetBottom,
            ...popoverStyle
        }

        if (placement === 'left') {
            defaultPopoverStyle.right = 0
        } else if (placement === 'top-left') {
            defaultPopoverStyle.top = undefined
            defaultPopoverStyle.bottom = 25
            defaultPopoverStyle.right = 0
        } else {
            defaultPopoverStyle.left = 0
        }

        let width = 'auto'

        const {style} = children ? children[0] ? children[0].props : children.props : {}

        if (style?.width) {
            width = style.width
        }

        if (style?.flex) {
            delete style.width
        }

        const parentStyle = {
            position: 'relative',
            width,
            flex: style?.flex
        }

        if ((popoverStyle.position === 'fixed' || !children) && rect?.top !== undefined) {
            defaultPopoverStyle = {
                ...defaultPopoverStyle,
                position: 'fixed',
                zIndex: 9999,
                top: rect?.top + rect?.height,
                left: rect.left
            }
            if (defaultPopoverStyle.width === 'auto') {
                defaultPopoverStyle.maxWidth = rect.width
            }
            if (defaultPopoverStyle.bottom === '100%') {
                defaultPopoverStyle.bottom = window.innerHeight - rect.top
                delete defaultPopoverStyle.top
            }
        }

        return (
            <div style={parentStyle} onClick={noClickOpen ? null : this.show.bind(this)} ref={(ref) => this.child = ref}>
                {children}

                <div
                    style={defaultPopoverStyle}
                    onClick={(event) => {
                        event.stopPropagation()
                        !noClose && this.close()
                    }}
                    onMouseDown={(event) => event.stopPropagation()}
                    ref={(ref) => this.popoverEl = ref}
                >
                    {content}
                </div>
            </div>
        )
    }
}

export default Popover
