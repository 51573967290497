import React from 'react'

import {Colors, P} from './index.js'

export default class ListItem extends React.PureComponent {
    constructor(props) {
        super(props)

        this.state = {
            hover: false
        }
    }

    render() {
        const {style = {}, size, focused, active, icon, children, disabled, onClick, onMouseDown, id} = this.props

        const defaultStyle = {
            display: 'flex',
            flexShrink: 0,
            width: '100%',
            alignItems: 'center',
            cursor: 'pointer',
            paddingLeft: size === 'sm' ? 6 : 12,
            paddingRight: size === 'sm' ? 6 : 12,
            height: size === 'sm' ? 32 : 56,
            fontWeight: disabled || !active ? 400 : 500,
            color: disabled ? Colors.textMedium : Colors.textDark,
            background: (this.state.hover || focused) ? 'rgba(0, 0, 0, 0.02)' : 'transparent'
        }

        style && Object.keys(style).map((key) => {
            if (key !== 'background' && key !== 'width' && style[key] !== undefined) {
                defaultStyle[key] = style[key]
            }
        })

        return (
            <div
                style={{width: style.width || '100%', background: style.background}}
                onMouseEnter={() => this.setState({hover: true})}
                onMouseLeave={() => this.setState({hover: false})}
                id={id}
            >
                <P
                    size={size}
                    style={defaultStyle}
                    onClick={disabled ? null : onClick || null}
                    onMouseDown={disabled ? null : onMouseDown || null}
                >
                    {icon && <i style={{marginRight: 12, fontSize: size === 'sm' ? 16 : 20}} className={icon} />}
                    {children}
                </P>
            </div>

        )
    }
}
