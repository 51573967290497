import React from 'react'

import Reflux from 'reflux-react-16'
import _ from 'underscore'

import ProductCodeTable from '../../../../components/ProductCodeTable/index.js'
import {H3, S2, Panel} from '../../../../components/UI/index.js'

class ParcelProductCodes extends Reflux.Component {
    onParcelProductCodesChange(reseller, path) {
        const {onChange} = this.props
        onChange(reseller, path)
    }

    render() {
        const {reseller, carriers} = this.props

        const enabledCarriers = _.filter(carriers, (carrier) => {
            return reseller.settings.parcels.carriers[carrier.name] && reseller.settings.parcels.carriers[carrier.name].enabled
        })

        return (
            <Panel style={{padding: 20, minWidth: 600, width: 'fit-content'}}>
                <H3>Productcodes</H3>

                {enabledCarriers.length < 1 ?
                        <S2 style={{marginBottom: 30}}>Er moet minimaal 1 vervoerders zijn geactiveerd voordat productcodes kunnen worden ingesteld.</S2> :

                        <ProductCodeTable
                            reseller={reseller}
                            carriers={carriers}
                            enabledCarriers={enabledCarriers}
                            onChange={this.onParcelProductCodesChange.bind(this)}
                        />
                }
            </Panel>
        )
    }
}

export default (ParcelProductCodes)
