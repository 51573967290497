// @ts-check
import React, {useEffect, useRef} from 'react'
import Reflux from 'reflux-react-16'

import {Button, Colors, IconButton, Panel, Popup} from '../../components/UI/index.js'
import usePackageStore from '../../stores/PackageStore.js'
import Table from '../../components/Table/index.js'
import SearchBar from '../../components/SearchBar/index.js'
import dbCodes from '../../../server/dbCodes.js'
import ViewPackagingModal from '../../components/packages/ViewPackagingModal.js'
import filter from '../../utils/filter.js'
import CustomerStore from '../../stores/CustomerStore.js'
import csvExport from '../../utils/csvExport.js'
import _ from 'lodash'
import PackagingScanModal from '../../components/packages/PackagingScanModal.js'

/**
 * @typedef {object} PackagesProps
 * @property {Reseller} reseller
 * @property {{[key: string]: Customer}} customers
 * @property {{}} history
 * @property {{params: any}} match
 */

/**
 * @component
 *
 * @param {PackagesProps} props
 * @returns {JSX.Element}
 */
function Packages({reseller, customers, history}) {
    const PackageStore = usePackageStore()
    const scanModalRef = useRef(null)
    const popupRef = useRef(null)
    const packagingModalRef = useRef(null)
    const tableRef = useRef(null)
    const filteredPackages = filter.shifts(PackageStore.packages, PackageStore.packagesQueries)

    /**
    * @returns {{[key: string]: any; render?: (packaging: Packaging) => import('react').ReactNode}[]}
    */
    const columns = () => {
        return [
            {title: 'Type', visible: true, flex: 1, key: 'type'},
            {title: 'Klant', visible: true, flex: 1, key: 'customer', sort: (packaging) => {
                return customers[packaging.customerId] ? customers[packaging.customerId].name : ''
            }, render: (packaging) => {
                return customers[packaging.customerId] ? customers[packaging.customerId].name : ''
            }},
            {title: 'Barcode', visible: true, flex: 1, key: 'barcode'},
            {title: 'Status', visible: true, flex: 1, key: 'status', text: (packaging) => dbCodes.packagingStatus[packaging.status]},
            {title: 'Adres', visible: true, flex: 1, key: 'address', text: (packaging) => {
                return packaging.status === 'delivered' ? `${packaging.address.street} ${packaging.address.nr}${packaging.address.addition} ${packaging.address.postalCode} ${packaging.address.city}` : ''
            }},

            {visible: true, showOnHover: true, width: 42, onClick: () => {}, render: (packaging) => {
                return (
                    <IconButton onClick={() => packagingModalRef.current.open(packaging._id)} icon='mdi mdi-eye' />
                )
            }}
        ]
    }

    useEffect(() => {
        PackageStore.get()

        document.title = `Emballage • ${reseller.settings.accountName || reseller.name}`

        const urlParams = new URLSearchParams(window.location.search)
        const id = urlParams.get('id')

        if (id) {
            packagingModalRef.current.open(id)
        }

        window.history.replaceState({}, '', '/emballage')
    }, [])

    const onClickExport = () => {
        const exportPackages = []

        PackageStore.selectedPackages.map((id) => {
            const packaging = _.find(filteredPackages, (packaging) => id === packaging._id)

            if (packaging) {
                exportPackages.push(packaging)
            }
        })

        csvExport.packages(exportPackages, customers)
        PackageStore.setSelected([])
    }

    return (
        <div style={{display: 'flex', flexDirection: 'column', height: '100%'}}>
            <Panel style={{display: 'flex', borderTop: 'none', background: Colors.backgroundNeutral, padding: '20px 10px 10px 10px'}}>
                <SearchBar
                    columns={columns()}
                    queries={PackageStore.packagesQueries}
                    onQueryChange={(queries) => {
                        tableRef.current.selectPage(1)
                        PackageStore.setQueries(queries)
                    }}
                />

                <Button
                    variant='outline-white'
                    label='Emballage uitleveren'
                    onClick={() => scanModalRef.current.open()}
                />

                {PackageStore.selectedPackages.length > 0 &&
                    <>
                        <Button
                            variant='outline-white'
                            onClick={() => onClickExport()}
                            tooltip='Exporteer zendingen'
                        >
                            <i className='mdi mdi-file-export' />
                        </Button>
                    </>
                }
            </Panel>

            <div style={{flex: 1, marginTop: 24, marginLeft: 24, marginRight: 24}}>
                <Table
                    columns={columns()}
                    rows={filteredPackages}
                    selectedRows={PackageStore.selectedPackages || []}
                    onChange={(selected) => {
                        PackageStore.setSelected(selected)
                    }}
                    ref={tableRef}
                />
            </div>

            <ViewPackagingModal
                history={history}
                ref={packagingModalRef}
            />

            <PackagingScanModal ref={scanModalRef} />

            <Popup ref={popupRef} />
        </div>
    )
}

// @ts-ignore
export default class PackagesWrapper extends Reflux.Component {
    constructor(props) {
        super(props)

        this.store = CustomerStore

        this.state = {
            customers: {}
        }

        this.props = props
    }

    render() {
        return (
            <Packages reseller={this.props.reseller} customers={this.state.customers} history={this.props.history} match={this.props.match} />
        )
    }
}
