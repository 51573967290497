import React from 'react'

import _ from 'underscore'
import {parse} from 'csv-parse/browser/esm'

import {Alert, Button, IconButton, Colors, Modal, H4, S2, P, Row, Column} from '../../UI/index.js'
import {Select, Toggle, TimePicker} from '../../UI/index.js'

import splitAddress from '../../../utils/splitAddress.js'

class ImportModal extends React.Component {
    constructor(props) {
        super(props)
        this.initialState = {
            modalIsOpen: false,
            columns: props.columns || [],
            startTime: '',
            endTime: '',
            lines: [],
            loading: '',
            error: ''
        }
        this.state = this.initialState
    }

    componentDidUpdate(prevProps) {
        if (prevProps.columns !== this.props.columns) {
            this.setState({columns: this.props.columns})
        }
    }

    open() {
        this.setState({modalIsOpen: true})
    }

    close() {
        this.setState(this.initialState)
    }

    setError(error) {
        this.setState({loading: false, error})
    }

    onClickOpenFile() {
        document.getElementById('csv').value = null
        document.getElementById('csv').click()
    }

    onLoadFile() {
        const file = document.getElementById('csv').files[0]

        if (file) {
            const reader = new FileReader()
            reader.readAsText(file)
            reader.onerror = (event) => {
                if (event.target.error.name == 'NotReadableError') {
                    this.setState({error: 'Geen geldig .csv bestand'})
                }
            }
            reader.onload = (event) => {
                let delimiter = ';'
                let commaCount = 0
                let semicolonCount = 0

                event.target.result.split('').slice(0, 500).map((char) => {
                    commaCount += char === ',' ? 1 : 0
                    semicolonCount += char === ';' ? 1 : 0
                })

                if (commaCount > semicolonCount) {
                    delimiter = ','
                }

                parse(event.target.result, {delimiter, relax_column_count: true, skip_empty_lines: true, trim: true}, (err, lines) => {
                    if (err) {
                        this.setState({error: 'Geen geldig .csv bestand'})
                    } else {
                        const {columns} = this.state

                        let nrOfColumns = 0

                        lines.map((line) => {
                            if (line.length > nrOfColumns) {
                                nrOfColumns = line.length
                            }
                        })

                        const columnDiff = nrOfColumns - columns.length
                        if (columnDiff > 0) {
                            for (let i = 0; i < columnDiff; i++) {
                                columns.push('')
                            }
                        } else {
                            columns.splice(nrOfColumns)
                        }

                        this.setState({lines, columns, error: ''})
                    }
                })
            }
        }
    }

    onChangeColumn(index, event) {
        const {columns} = this.state
        columns[index] = event.target.value
        this.setState({columns})
    }

    onSubmit() {
        const {lines, columns, startTime, endTime, ignoreFirstLine} = this.state
        const {onChange} = this.props

        this.setState({loading: true, error: ''})

        const objects = []

        lines.map((line, index) => {
            if (index > 0 || !ignoreFirstLine) {
                const object = {}

                if (startTime) {
                    object.startTime = startTime
                }
                if (endTime) {
                    object.endTime = endTime
                }

                line.map((value, index) => {
                    if (columns[index]) {
                        const key = columns[index].split('.')

                        value = value.replace(/"/g, '')

                        if (key[0] === 'streetNr') {
                            const address = splitAddress(value)
                            object.street = address.street
                            object.nr = address.nr
                            object.addition = address.addition
                        } else if (key[0] === 'nrAddition') {
                            const address = splitAddress(`straat ${value}`)
                            object.nr = address.nr
                            object.addition = address.addition
                        } else if (key[0] === 'postalCode') {
                            object.postalCode = value.toUpperCase().replace(/  +/g, ' ').trim()

                            if (/^[0-9]{4}[A-Z]{2}$/.test(object.postalCode)) {
                                object.postalCode = `${object.postalCode.substr(0, 4)} ${object.postalCode.substr(4, 2)}`
                            }
                        } else if (key.length === 1) {
                            object[key[0]] = value.trim()
                        } else {
                            object[key[0]][key[1]] = value.trim() || object[key[0]][key[1]] || ''
                        }
                    }
                })

                object.country = object.country?.toUpperCase()

                if (object.country === 'NLD') {
                    object.country = 'NL'
                }

                objects.push(object)
            }
        })

        onChange(objects, columns)
    }

    render() {
        const {modalIsOpen, columns, startTime, endTime, lines, loading, error, ignoreFirstLine} = this.state
        const {possibleColumns, isAddressBook} = this.props

        return (
            <Modal style={{width: 'auto', minWidth: 600, maxWidth: '90vw'}} show={modalIsOpen} onClose={this.close.bind(this)}>
                <div style={{display: 'flex', justifyContent: 'space-between'}}>
                    <H4>CSV bestand importeren</H4>

                    <IconButton onClick={this.close.bind(this)}>
                        <i style={{color: Colors.buttonSolid}} className='mdi mdi-close' />
                    </IconButton>
                </div>

                {!isAddressBook && (
                    <div style={{display: 'flex', flex: 1, marginRight: 6}}>
                        <TimePicker
                            label='Starttijd'
                            time={startTime}
                            onChange={(event) => this.setState({startTime: event.target.value})}
                        />

                        <TimePicker
                            label='Eindtijd'
                            time={endTime}
                            onChange={(event) => this.setState({endTime: event.target.value})}
                        />
                    </div>
                )}

                {!lines.length &&
                    <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: 300}}>
                        <P>Selecteer csv bestand</P>
                        <IconButton onClick={this.onClickOpenFile.bind(this)}>
                            <i className='mdi mdi-folder-open' />
                        </IconButton>
                    </div>
                }

                {!!lines.length &&
                    <>
                        <Toggle
                            style={{width: 300}}
                            label='Negeer 1e regel'
                            checked={ignoreFirstLine}
                            onChange={(event) => this.setState({ignoreFirstLine: event.target.checked})}
                        />

                        <div style={{height: 500, overflow: 'auto', marginBottom: 24, marginTop: 24}}>
                            <Row style={{flexShrink: 0}}>
                                {columns.map((value, index) => {
                                    return (
                                        <Select
                                            key={index}
                                            style={{width: 200}}
                                            allowEmptyValue
                                            placeholder='Negeer kolom'
                                            value={value}
                                            onChange={this.onChangeColumn.bind(this, index)}
                                        >
                                            {_.keys(possibleColumns).map((key) => {
                                                return <option key={key} value={key}>{possibleColumns[key]}</option>
                                            })}
                                        </Select>
                                    )
                                })}
                            </Row>

                            {lines.map((line, index) => {
                                if ((index > 0 || !ignoreFirstLine) && index < 20) {
                                    return (
                                        <Row key={index} style={{flexShrink: 0, minHeight: 32, marginBottom: 10}}>
                                            {line.map((value, index) => {
                                                return (
                                                    <Column
                                                        key={index}
                                                        style={{
                                                            width: 212,
                                                            flexShrink: 0,
                                                            whiteSpace: 'nowrap',
                                                            overflow: 'hidden',
                                                            textOverflow: 'ellipsis',
                                                            borderBottom: '1px solid', borderColor: Colors.grey40
                                                        }}
                                                    >
                                                        {value}
                                                    </Column>
                                                )
                                            })}
                                        </Row>
                                    )
                                }
                            })}
                        </div>
                    </>
                }

                {!!lines.length &&
                    <S2>{`${ignoreFirstLine ? lines.length - 1 : lines.length} regels`}</S2>
                }

                <br />
                <br />
                {error &&
                    <Alert variant='danger'>{error}</Alert>
                }

                <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                    <Button
                        loading={loading}
                        disabled={!lines.length}
                        onClick={this.onSubmit.bind(this)}
                    >
                        Importeer
                    </Button>
                </div>

                <input
                    type='file'
                    id='csv'
                    accept='.csv'
                    style={{visibility: 'hidden', height: 0}}
                    onChange={this.onLoadFile.bind(this)}
                />
            </Modal>

        )
    }
}

export default (ImportModal)
