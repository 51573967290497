import {z} from 'zod'

// TODO: Not validated the model yet, just copied from types.ts
const AddressModel = z.object({
    _id: z.string().optional(),
    reseller: z.string().trim().optional(),
    customerId: z.string().trim().optional(),
    name: z.string(),
    attention: z.string().or(z.literal('')).optional(),
    street: z.string(),
    nr: z.string().max(20).or(z.literal('')),
    addition: z.string().or(z.literal('')),
    street2: z.string(),
    postalCode: z.string(),
    city: z.string(),
    country: z.string(),
    email: z.string().optional(),
    phone: z.string().optional(),
    state: z.string().optional(),
    isBusiness: z.boolean().optional(),
    instructions: z.string().optional(),
    position: z.object({
        lat: z.number(),
        lng: z.number()
    }).optional(),
    attachments: z.object({
        fileId: z.string().optional(),
        type: z.string(),
        fileName: z.string(),
        data: z.string().optional()
    }).array().optional(),
    overridePosition: z.boolean().optional(),
    showWarning: z.boolean().optional(),
    exactMatch: z.boolean().optional()
})

export {AddressModel}
