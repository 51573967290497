import React from 'react'

import OrderActions from '../../actions/OrderActions.js'

import {Alert, IconButton, Input, Select, S1, Toggle, Row, Colors, H6, Column, Card} from '../UI/index.js'
import EditAddress from './EditAddress.js'

class MoreOptions extends React.PureComponent {
    constructor(props) {
        super(props)

        this.initialState = {
            order: null,
            shifts: [],
            shift: '',
            retourAddress: {},
            allowAddCollo: '',
            edit: false,
            expanded: false,
            priceLoading: false,
            loading: false,
            error: '',
            message: ''
        }

        this.state = structuredClone(this.initialState)
    }

    componentDidMount() {
        this.setState({
            order: structuredClone(this.props.order),
            shift: this.props.order.shift,
            retourAddress: this.props.order.retourAddress,
            allowAddCollo: this.props.order.allowAddCollo
        })

        OrderActions.getOrderShifts(this.props.order.date, (err, shifts) => {
            if (!err) {
                this.setState({shifts})
            }
        })
    }

    save() {
        const {shift, retourAddress, allowAddCollo} = this.state
        const {order} = this.props

        this.setState({loading: true, error: ''})

        OrderActions.editMoreOptions(order._id, shift, retourAddress, allowAddCollo, (err, order) => {
            if (err) {
                this.setState({loading: false, error: err})
            } else {
                this.setState({loading: false, edit: false, error: ''})

                this.props.onUpdateOrder(order)
            }
        })
    }

    render() {
        const {order, expanded, edit, shifts, shift, retourAddress, allowAddCollo, error} = this.state
        const {reseller} = this.props

        return (
            <div style={{marginBottom: 24, width: '100%'}}>
                <Row style={{alignItems: 'center', minHeight: 36}}>
                    <Row style={{cursor: 'pointer'}} onClick={() => this.setState({expanded: !expanded})}>
                        <S1>Overige opties</S1>
                        <i style={{marginLeft: 6}}className={expanded ? 'mdi mdi-chevron-up' : 'mdi mdi-chevron-down'} />
                    </Row>

                    <div style={{flex: 1}} />

                    {!edit && expanded &&
                        <IconButton
                            icon='mdi mdi-pencil'
                            onClick={() => this.setState({edit: !edit})}
                        />
                    }

                    {edit &&
                        <IconButton
                            icon='mdi mdi-check'
                            onClick={this.save.bind(this)}
                        />
                    }
                </Row>

                {expanded &&
                    <div style={{marginBottom: 12, width: '100%', padding: 6}}>
                        <Row>
                            <Column>
                                {!reseller.isShop && reseller.permissions.shifts &&
                                    <Select
                                        label='Dienst'
                                        info='Door een rit te koppelen aan een dienst wordt een koerier toegewezen zodra deze wordt toegewezen aan de dienst'
                                        allowEmptyValue
                                        value={shift}
                                        onChange={(event) => this.setState({shift: event.target.value})}
                                        readOnly={!edit}
                                    >
                                        {shifts.map((shift) => {
                                            return (
                                                <option key={shift._id} value={shift.name}>{shift.name}</option>
                                            )
                                        })}
                                    </Select>
                                }
                            </Column>
                        </Row>

                        {!reseller.isShop && !order.templateId &&
                            <Row>
                                <Column>
                                    <Input
                                        style={{marginRight: 12}}
                                        label='Aantal bezorgpogingen'
                                        info='Ritten worden automatisch verplaatst naar de volgende werkdag na een mislukte bezorgpoging tot het maximaal aantal bezorgpogingen is bereikt. Vul 0 in om ritten nooit automatisch te verplaatsen.'
                                        type='number'
                                        value={order.nrOfDeliveries}
                                        readOnly
                                    />

                                    <Toggle
                                        style={{marginLeft: 12}}
                                        label='Automatisch retour'
                                        checked={order.autoRetour}
                                        disabled
                                    />
                                </Column>
                            </Row>
                        }

                        {!reseller.isShop && order.autoRetour && order.nrOfDeliveries > 0 &&
                            <Row>
                                <Column>
                                    <Input
                                        label='Bewaartijd'
                                        info='Hoeveel dagen blijft de rit in bewaring na de laatste bezorgpoging. Vul 0 in om na de laatste bezorgpoging meteen een retourrit aan te maken.'
                                        type='number'
                                        value={order.nrOfDaysBeforeRetour}
                                        readOnly
                                    />
                                </Column>
                                <Column />
                            </Row>

                        }

                        {!reseller.isShop && order.autoRetour &&
                            <>
                                <H6 style={{marginTop: 12}}>Retouradres</H6>

                                <Card style={{width: '100%', background: Colors.grey20}}>
                                    <div style={{height: 10}} />
                                    <EditAddress
                                        address={{...retourAddress, type: 'delivery'}}
                                        onChange={(address) => this.setState({retourAddress: address})}
                                        readOnly={!edit}
                                        ref={(ref) => this.retourAddress = ref}
                                    />
                                </Card>
                            </>
                        }

                        <Row style={{marginTop: 12}}>
                            <Column>
                                <Select
                                    label='Collo toevoegen toestaan'
                                    info=''
                                    options={[
                                        {title: 'Ja', value: 'yes'},
                                        {title: 'Alleen bij bezorgen', value: 'deliver'},
                                        {title: 'Alleen bij ophalen', value: 'pickup'},
                                        {title: 'Nee', value: ''}
                                    ]}
                                    value={allowAddCollo}
                                    onChange={(event) => this.setState({allowAddCollo: event.target.value})}
                                    readOnly={!edit}
                                />

                            </Column>
                            <Column />
                        </Row>

                        {reseller.settings.orders.monitorTemperature &&
                            <Toggle
                                style={{width: '50%', marginTop: 12}}
                                label='Geconditioneerd transport'
                                checked={order.monitorTemperature}
                                disabled
                            />
                        }

                        {order.monitorTemperature &&
                            <div style={{display: 'flex'}}>
                                <Select
                                    styl
                                    label='Sensor ID'
                                    value={order.sensorId}
                                    readOnly
                                >
                                    {(reseller.settings.orders.sensors || []).map((sensor) => {
                                        return (
                                            <option key={sensor.id} value={sensor.sensorId}>{sensor.name}</option>
                                        )
                                    })}
                                </Select>

                                <div style={{display: 'flex', marginLeft: 6}}>
                                    <Input
                                        id='minTemperature'
                                        type='number'
                                        label='Minimum temperatuur'
                                        value={order.minTemperature}
                                        readOnly
                                    />

                                    <Input
                                        id='maxTemperature'
                                        type='number'
                                        label='Maximum temperatuur'
                                        value={order.maxTemperature}
                                        readOnly
                                    />
                                </div>
                            </div>
                        }

                        {error &&
                            <Alert variant='danger'>{error}</Alert>
                        }
                    </div>
                }
            </div>

        )
    }
}

export default MoreOptions
