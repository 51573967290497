import React from 'react'

import _ from 'underscore'

import {Column, Input, Row} from '../UI/index.js'
import {Alert} from '../UI/index.js'

class SelectCustomer extends React.Component {
    constructor(props) {
        super(props)
        const {customers} = props
        const customer = customers[props.value]

        this.state = {
            customer,
            customerName: customer ? customer.name : '',
            validation: {}
        }
    }

    componentDidUpdate(prevProps) {
        const {customers, value} = this.props

        if (value !== prevProps.value) {
            const customer = customers[value]

            this.setState({customer, customerName: customer ? customer.name : ''})
        }
    }

    setCustomer(event) {
        const {validation} = this.state
        const {customers, onChange} = this.props

        validation.customer = false

        const customerName = event.target.value.length === 1 ? event.target.value.toUpperCase() : event.target.value
        const customer = _.findWhere(_.values(customers), {name: customerName})

        this.setState({customerName, validation}, () => {
            if (customer) {
                onChange({customer})
            }
        })
    }

    setContact(event) {
        const {customer} = this.state
        const {onChange} = this.props

        const name = event.target.value.length === 1 ? event.target.value.toUpperCase() : event.target.value

        let contact = {name, phone: '', email: ''}

        if (customer) {
            contact = _.findWhere(customer.contacts, {name}) || contact
        }

        onChange({contact})
    }

    validate() {
        const {customer} = this.state
        const validation = {}
        let id = ''
        let valid = true

        if (!customer) {
            id = 'customerselect'
            validation.customer = true
            valid = false
        }

        if (!valid) {
            const element = document.getElementById(id)
            if (element) {
                element.focus()
            }
        }

        this.setState({validation})
        return valid
    }

    focus() {
        document.getElementById('customerselect').focus()
    }

    render() {
        const {customerName, customer, validation} = this.state
        const {contact, customers, showNotes} = this.props

        const suggestions = customerName < 1 ?
                [] :
            _.values(customers).filter((customer) => {
                return customer.name.toLowerCase().indexOf(customerName.toLowerCase()) > -1 && !customer.isArchived
            }).splice(0, 9)

        return (
            <div style={{width: '100%'}}>
                <Row>
                    <Column>
                        <Input
                            id='customerselect'
                            label='Klant'
                            suggestions={_.pluck(suggestions, 'name')}
                            value={customerName}
                            isInvalid={validation.customer}
                            onChange={this.setCustomer.bind(this)}
                        />

                        {typeof contact === 'string' &&
                            <Input
                                id='contact'
                                label='Contactpersoon'
                                suggestions={customer ? _.pluck(customer.contacts, 'name') : []}
                                value={contact}
                                onChange={this.setContact.bind(this)}
                            />
                        }
                    </Column>
                </Row>

                {showNotes && customer?.settings.orders.newOrderInstructions &&
                    <Alert variant='info' style={{marginTop: -10}}>{customer.settings.orders.newOrderInstructions}</Alert>
                }
            </div>
        )
    }
}

export default SelectCustomer
