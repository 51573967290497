import {create} from 'zustand'
import socket from '../socketio/socketio.js'

const useLoginStore = create(() => ({
    loginAsReseller: (id, url, callback) => {
        socket.emit('login.loginAsReseller', sessionStorage.token, id, (err, token) => {
            if (!err) {
                localStorage.loginAsResellerToken = token
                setTimeout(() => window.open(url || '/'), 300)
                setTimeout(() => delete localStorage.loginAsResellerToken, 5000)
            }

            typeof callback === 'function' && callback(err)
        })
    }
}))

export default useLoginStore
