import React from 'react'

import _ from 'lodash'
import {Input, FileUpload, Toggle, Select, TimePicker, MetricInput, Colors, ColorPicker, Column, Option, Row, Info, Tabs, Tab, SaveBeforeExitPopup} from '../UI/index.js'
import {Alert, Button, IconButton, Modal, H4, H6, P} from '../UI/index.js'

const dayToLabel = {
    monday: 'M',
    tuesday: 'D',
    wednesday: 'W',
    thursday: 'D',
    friday: 'V',
    saturday: 'Z',
    sunday: 'Z'
}

export default class CarrierModal extends React.PureComponent {
    constructor(props) {
        super(props)

        this.initialState = {
            modalIsOpen: false,
            carrierName: '',
            settings: {},
            carrier: {},
            tempPlatformCarrierSettings: {}
        }

        this.state = JSON.parse(JSON.stringify(this.initialState))
    }

    open(carrierName, settings, platformCarriers, platform) {
        const {carriers} = this.props

        const carrier = carriers.find((carrier) => carrier?.name === carrierName)

        if (carrier) {
            if (carrier.type === 'partner') {
                settings.submitDays = settings.submitDays || {
                    monday: {enabled: true},
                    tuesday: {enabled: true},
                    wednesday: {enabled: true},
                    thursday: {enabled: true},
                    friday: {enabled: true},
                    saturday: {enabled: true},
                    sunday: {enabled: true}
                }
                settings.submitDayLowerPreference = settings.submitDayLowerPreference || false
            }

            this.setState({
                modalIsOpen: true,
                carrierName,
                settings,
                carrier,
                platformCarriers,
                platform
            })
        }
    }

    close() {
        this.setState(this.initialState)
    }

    onEnableCarrier(event) {
        const settings = {...this.state.settings}

        settings.enabled = true

        this.setState({settings})
    }

    onChangeCarrierValue(key, event, forAllPlatformCarriers = false) {
        if (forAllPlatformCarriers) {
            const {tempPlatformCarrierSettings} = this.state
            this.state.platformCarriers.forEach((carrier) => {
                const settings = {...tempPlatformCarrierSettings?.[carrier.name] || {}}
                settings[key] = event.target.value
                tempPlatformCarrierSettings[carrier.name] = settings
            })
            this.setState({tempPlatformCarrierSettings})
        }
        const settings = {...this.state.settings}
        settings[key] = event.target.value
        this.setState({settings})
    }

    onChangeCarrierValues(key, event) {
        const settings = {...this.state.settings}
        settings[key] = event.target.values
        this.setState({settings})
    }

    onChangeFileUpload(key, fileName, value) {
        const settings = {...this.state.settings}
        settings[key] = value
        settings[`${key}FileName`] = fileName
        this.setState({settings})
    }

    onChangeCarrierChecked(key, event) {
        const settings = {...this.state.settings}
        _.set(settings, key, event.target.checked)
        this.setState({settings})
    }

    onChangeUseDeliveryZones(event) {
        const settings = {...this.state.settings}
        settings.useCustomDeliveryZone = event.target.checked
        this.setState({settings})
    }

    onChangeWeight(event) {
        if (/^[0-9]{0,5}$/.test(event.target.value)) {
            const settings = {...this.state.settings}
            settings.maxWeight = parseInt(event.target.value)
            this.setState({settings})
        }
    }

    onChangeDimension(key, event) {
        const settings = {...this.state.settings}

        settings.maxDimensions = settings.maxDimensions || {}
        settings.maxDimensions[key] = event.target.value

        this.setState({settings})
    }

    onChangeVolume(event) {
        const settings = {...this.state.settings}
        settings.maxVolume = event.target.value
        this.setState({settings})
    }

    onDisable(stayOpen = false) {
        const settings = {...this.state.settings}
        settings.enabled = false
        if (!stayOpen) {
            this.props.onSubmit(this.state.carrierName, settings)
            this.close()
        } else {
            this.setState({settings})
        }
    }

    onSubmit(_, stayOpen = false, enable = true, source = 'submit') {
        const {platform, settings, carrier, platformCarriers, carrierName} = this.state
        const {reseller} = this.props

        if (platform) {
            let openSavePopup = false
            let saveCurrentCarrier = false
            const otherCarriersToSave = []

            if (JSON.stringify(settings) !== JSON.stringify(reseller.settings.parcels.carriers[carrier.name])) {
                openSavePopup = true
                saveCurrentCarrier = true
            }

            platformCarriers.forEach((carrier) => {
                if (carrier.name === carrierName) {
                    return
                }

                const tempSettings = this.state.tempPlatformCarrierSettings?.[carrier.name]
                if (!tempSettings) {
                    return
                }

                if (JSON.stringify(tempSettings) !== JSON.stringify(reseller.settings.parcels.carriers[carrier.name])) {
                    openSavePopup = true
                    otherCarriersToSave.push(carrier.name)
                }
            })

            const callback = () => {
                if (saveCurrentCarrier) {
                    const settings = {...this.state.settings}
                    this.props.onSubmit(carrierName, settings)
                }

                otherCarriersToSave.forEach((carrierName) => {
                    this.props.onSubmit(carrierName, {...this.state.tempPlatformCarrierSettings[carrierName]})
                })

                this.savePopup.close()
                this.close()
            }

            if (openSavePopup && source === 'close') {
                this.savePopup.open(callback, () => {
                    this.close()
                })
            } else if (source === 'submit') {
                callback()
            } else {
                this.close()
            }
        } else {
            const settings = {...this.state.settings}
            settings.enabled = enable
            this.props.onSubmit(this.state.carrierName, settings)
            !stayOpen && this.close()
        }
    }

    render() {
        const {modalIsOpen, carrierName, settings, carrier, platformCarriers, platform} = this.state
        const {reseller} = this.props

        console.log(carrier)
        return (
            <Modal show={modalIsOpen} onClose={this.close.bind(this)}>
                <div style={{display: 'flex', justifyContent: 'space-between'}}>
                    <H4>
                        {platform ?
                            platform :
                            (
                                <>
                                    {`${carrier?.displayName || carrierName} ${settings.enabled ? 'instellingen' : 'activeren'}`}
                                </>
                            )
                        }
                    </H4>


                    <IconButton onClick={platform ? () => this.onSubmit(undefined, false, true, 'close') : this.close.bind(this)}>
                        <i style={{color: Colors.buttonSolid}} className='mdi mdi-close'/>
                    </IconButton>
                </div>

                {['DHL-onlineparcel', 'DPD-onlineparcel', 'PostNL-onlineparcel'].includes(carrierName) &&
                    <>
                        <H6>Koppeling</H6>

                        <Input
                            label='Public Key'
                            value={settings.publicKey}
                            onChange={(event) => this.onChangeCarrierValue('publicKey', event, true)}
                        />

                        <Input
                            label='Secret Key'
                            value={settings.secretKey}
                            type='password'
                            autocomplete='new-password'
                            onChange={(event) => this.onChangeCarrierValue('secretKey', event, true)}
                        />

                        <Alert variant='info'>Via dit platform kan een referentie niet worden meegestuurd naar de vervoerder.</Alert>
                    </>
                }

                {platformCarriers?.length > 0 &&
                <>
                    <H6 style={{marginTop: 36}}>Vervoerders</H6>
                    <Tabs
                        style={{height: 'fit-content'}}
                        value={carrier.name}
                        onChange={(e) => {
                            const value = e.target?.value
                            let tempSettings = this.state.tempPlatformCarrierSettings?.[value]
                            if (tempSettings && reseller.settings.parcels.carriers[value]) {
                                tempSettings = {
                                    ...structuredClone(reseller.settings.parcels.carriers[value]),
                                    ...tempSettings
                                }
                            }

                            this.setState({
                                carrierName: value,
                                tempPlatformCarrierSettings: {
                                    ...this.state.tempPlatformCarrierSettings,
                                    [carrier.name]: settings
                                },
                                settings: tempSettings ? tempSettings : reseller.settings.parcels.carriers[value] ? structuredClone(reseller.settings.parcels.carriers[value]) : {},
                                carrier: platformCarriers.find((c) => c.name === value)
                            })
                        }}
                    >
                        {platformCarriers.map((c, index) => {
                            let isEnabled = false
                            if (reseller.settings.parcels.carriers[c.name]) {
                                isEnabled = reseller.settings.parcels.carriers[c.name].enabled
                            }

                            if (this.state.tempPlatformCarrierSettings[c.name]) {
                                isEnabled = this.state.tempPlatformCarrierSettings[c.name].enabled
                            }

                            if (carrierName === c.name) {
                                isEnabled = settings.enabled
                            }

                            return (
                                <Tab value={c.name} key={`${c.name}-${index}`}>
                                    <div style={{textAlign: 'center'}}>
                                        <img src={c.logo} width={48} height={48} style={{objectFit: 'contain', ...(!isEnabled && {filter: 'grayscale(100%)'})}}/>
                                        <P>
                                            {(c.displayName || c.name).replace('-', '').replace(platform, '').trim()}
                                        </P>
                                    </div>
                                </Tab>
                            )
                        })}
                    </Tabs>
                </>
                }

                {['DHL-onlineparcel', 'DPD-onlineparcel', 'PostNL-onlineparcel'].includes(carrierName) && !settings.enabled &&
                    <Button onClick={this.onEnableCarrier.bind(this)}>
                        Activeer
                    </Button>
                }

                {(['Cycloon', 'DHL', 'GLS', 'Peddler', 'PostNL', 'Skynet', 'PostNL-MSG', 'DHL-MSG', 'PostNL-QLS', 'DHL-QLS', 'DPD-QLS'].includes(carrierName)) &&
                    <>
                        <H6 style={{marginTop: 36}}>Koppeling</H6>
                    </>
                }

                {carrierName === 'Cycloon' &&
                    <>
                        <Input
                            label='Gebruikersnaam'
                            info='Vul hier je gebruikersnaam en API Key in van je klantaccount bij Cycloon, niet van je vervoerdersaccount.'
                            value={settings.username}
                            onChange={this.onChangeCarrierValue.bind(this, 'username')}
                        />

                        <Input
                            label='API Key'
                            value={settings.password}
                            onChange={this.onChangeCarrierValue.bind(this, 'password')}
                        />
                    </>
                }

                {carrierName === 'DHL' &&
                    <>
                        <Input
                            label='Klantnummer'
                            value={settings.accountId}
                            onChange={this.onChangeCarrierValue.bind(this, 'accountId')}
                        />

                        <Input
                            label='Gebruikers ID'
                            value={settings.userId}
                            onChange={this.onChangeCarrierValue.bind(this, 'userId')}
                        />

                        <Input
                            label='API Key'
                            value={settings.apiKey}
                            onChange={this.onChangeCarrierValue.bind(this, 'apiKey')}
                        />
                    </>
                }

                {carrierName === 'GLS' &&
                    <>
                        <Alert variant='info'>{'Om status updates van zendingen te ontvangen moeten er een aanvraag worden ingediend bij GLS. Stuur hiervoor een bericht naar info@veloyd.nl en vermeld hierin je GLS gebruikersnaam.'}</Alert>

                        <Input
                            label='Klantnummer'
                            value={settings.customerNumber}
                            onChange={this.onChangeCarrierValue.bind(this, 'customerNumber')}
                        />

                        <Input
                            label='Gebruikersnaam'
                            value={settings.username}
                            onChange={this.onChangeCarrierValue.bind(this, 'username')}
                        />

                        <Input
                            label='Wachtwoord'
                            type='password'
                            autocomplete='new-password'
                            value={settings.password}
                            onChange={this.onChangeCarrierValue.bind(this, 'password')}
                        />
                    </>
                }

                {carrierName === 'Peddler' &&
                    <>
                        <Toggle
                            label='Gebruik nieuwe API versie (Enterprise Shipping Service)'
                            checked={settings.useESS}
                            onChange={this.onChangeCarrierChecked.bind(this, 'useESS')}
                        />

                        {settings.useESS ?
                            <>
                                <Input
                                    label='Client Id'
                                    value={settings.clientId}
                                    onChange={this.onChangeCarrierValue.bind(this, 'clientId')}
                                />

                                <Input
                                    label='Client Secret'
                                    type='password'
                                    value={settings.clientSecret}
                                    onChange={this.onChangeCarrierValue.bind(this, 'clientSecret')}
                                />

                                <Input
                                    label='Carrier Id'
                                    value={settings.carrierId}
                                    onChange={this.onChangeCarrierValue.bind(this, 'carrierId')}
                                />

                                <Select
                                    label='Delivery Contents Type'
                                    value={settings.deliveryContentsType}
                                    options={[
                                        {value: 'GOODS', title: 'GOODS'},
                                        {value: 'FOOD', title: 'FOOD'},
                                        {value: 'ALCOHOL', title: 'ALCOHOL'},
                                        {value: 'BOOKS', title: 'BOOKS'}
                                    ]}
                                    onChange={this.onChangeCarrierValue.bind(this, 'deliveryContentsType')}
                                />
                            </> :
                            <>
                                <Input
                                    label='Gebruiker Id'
                                    value={settings.userId}
                                    onChange={this.onChangeCarrierValue.bind(this, 'userId')}
                                />

                                <Input
                                    label='Applicatie Id'
                                    value={settings.applicationId}
                                    onChange={this.onChangeCarrierValue.bind(this, 'applicationId')}
                                />

                                <Input
                                    label='Winkel Id'
                                    value={settings.storeId}
                                    onChange={this.onChangeCarrierValue.bind(this, 'storeId')}
                                />

                                <FileUpload
                                    accept='.rsa'
                                    label='RSA key'
                                    fileName={settings.rsaKeyFileName}
                                    onChange={this.onChangeFileUpload.bind(this, 'rsaKey')}
                                />

                            </>
                        }

                    </>
                }

                {carrierName === 'PostNL' &&
                    <>
                        <Input
                            label='Klantnummer'
                            value={settings.customerNumber}
                            onChange={this.onChangeCarrierValue.bind(this, 'customerNumber')}
                        />

                        <Input
                            label='Klantcode'
                            value={settings.customerCode}
                            onChange={this.onChangeCarrierValue.bind(this, 'customerCode')}
                        />

                        <Input
                            label='Klantcode internationaal'
                            value={settings.customerCodeInt}
                            onChange={this.onChangeCarrierValue.bind(this, 'customerCodeInt')}
                        />

                        <Input
                            label='API Key'
                            value={settings.apiKey}
                            onChange={this.onChangeCarrierValue.bind(this, 'apiKey')}
                        />
                    </>
                }

                {carrierName === 'Skynet' &&
                    <>
                        <Alert variant='info'>{'Om status updates van zendingen te ontvangen moeten er een aanvraag worden ingediend bij Skynet. Stuur hiervoor een bericht naar info@veloyd.nl en vermeld hierin je Skynet gebruikersnaam.'}</Alert>

                        <Input
                            label='Gebruikersnaam'
                            value={settings.username}
                            onChange={this.onChangeCarrierValue.bind(this, 'username')}
                        />

                        <Input
                            label='Wachtwoord'
                            type='password'
                            autocomplete='new-password'
                            value={settings.password}
                            onChange={this.onChangeCarrierValue.bind(this, 'password')}
                        />

                        <Input
                            label='API Key'
                            value={settings.apiKey}
                            onChange={this.onChangeCarrierValue.bind(this, 'apiKey')}
                        />
                    </>
                }

                {(carrierName === 'PostNL-MSG' || carrierName === 'DHL-MSG') &&
                    <>
                        <Input
                            label='Gebruikersnaam'
                            value={settings.username}
                            onChange={this.onChangeCarrierValue.bind(this, 'username')}
                        />

                        <Input
                            label='Wachtwoord'
                            type='password'
                            autocomplete='new-password'
                            value={settings.password}
                            onChange={this.onChangeCarrierValue.bind(this, 'password')}
                        />

                        <Input
                            label='Klantnaam in Monta'
                            value={settings.originName}
                            onChange={this.onChangeCarrierValue.bind(this, 'originName')}
                        />
                    </>
                }

                {carrierName === 'Packs' &&
                    <>
                        <Input
                            label='Gebruikersnaam'
                            value={settings.username}
                            onChange={this.onChangeCarrierValue.bind(this, 'username')}
                        />

                        <Input
                            label='Wachtwoord'
                            type='password'
                            autocomplete='new-password'
                            value={settings.password}
                            onChange={this.onChangeCarrierValue.bind(this, 'password')}
                        />
                    </>
                }

                {(carrierName === 'PostNL-QLS' || carrierName === 'DHL-QLS' || carrierName === 'DPD-QLS') &&
                    <>
                        <Input
                            label='Gebruikersnaam'
                            value={settings.username}
                            onChange={this.onChangeCarrierValue.bind(this, 'username')}
                        />

                        <Input
                            label='Wachtwoord'
                            type='password'
                            autocomplete='new-password'
                            value={settings.password}
                            onChange={this.onChangeCarrierValue.bind(this, 'password')}
                        />

                        <Input
                            label='Bedrijfs ID'
                            value={settings.companyId}
                            onChange={this.onChangeCarrierValue.bind(this, 'companyId')}
                        />

                        <Input
                            label='Merk ID'
                            value={settings.brandId}
                            onChange={this.onChangeCarrierValue.bind(this, 'brandId')}
                        />
                    </>
                }

                {['Cycloon', 'DHL', 'GLS', 'Peddler', 'PostNL', 'Skynet', 'Packs'].includes(carrierName) &&
                    <>
                        <br/>
                        <br/>
                    </>
                }


                <H6 style={{marginTop: 36}}>Bezorgrestricties</H6>

                {carrier?.type === 'partner' && (
                    <>
                        <Row style={{alignItems: 'center'}}>
                            <Column>
                                <P>Aanlever dagen</P>
                                <Info iconColor={Colors.buttonSolid} text={`Selecteer hier op welke dagen zendingen bij ${carrier.name} worden aangeleverd. De rit voor uitvoering wordt de dag na aanlevering in de planning gezet.`}/>
                            </Column>

                            <Column style={{justifyContent: 'flex-end'}}>
                                {_.keys(settings.submitDays).map((day) => {
                                    return (
                                        <Option
                                            key={day}
                                            label={dayToLabel[day]}
                                            checked={settings.submitDays[day].enabled}
                                            disabled={!carrier.openingHours[day].open}
                                            onChange={this.onChangeCarrierChecked.bind(this, `submitDays.${day}.enabled`)}
                                        />
                                    )
                                })}
                            </Column>
                        </Row>

                        {_.keys(settings.submitDays).some((d) => !settings.submitDays[d].enabled) &&
                        <Column>
                            <Toggle
                                style={{marginLeft: 0, marginRight: 0}}
                                label='Lagere voorkeur bij aanmelden op een dag zonder aanlevering'
                                info={`Geef ${carrier.name} een lagere voorkeur op dagen waarop er niet kan worden aangeleverd.`}
                                checked={settings.submitDayLowerPreference}
                                onChange={this.onChangeCarrierChecked.bind(this, 'submitDayLowerPreference')}
                            />
                        </Column>
                        }
                    </>
                )}

                {carrierName !== reseller.name ?
                    <>
                        <Toggle
                            label='Gebruik eigen bezorggebied'
                            checked={settings.useCustomDeliveryZone}
                            onChange={this.onChangeUseDeliveryZones.bind(this)}
                            disabled={platform && !settings.enabled}
                        />

                        {settings.useCustomDeliveryZone &&
                            <Select
                                copy
                                label='Bezorggebieden'
                                options={reseller.settings.deliveryZones.map((area) => {
                                    return {value: area.id, title: area.name}
                                })}
                                values={settings.deliveryZones || []}
                                onChange={this.onChangeCarrierValues.bind(this, 'deliveryZones')}
                                disabled={platform && !settings.enabled}
                            />
                        }
                    </> :
                    <>
                        <Select
                            copy
                            label='Bezorggebieden'
                            options={reseller.settings.deliveryZones.map((area) => {
                                return {value: area.id, title: area.name}
                            })}
                            values={settings.deliveryZones || []}
                            onChange={this.onChangeCarrierValues.bind(this, 'deliveryZones')}
                            disabled={platform && !settings.enabled}
                        />

                        <Input
                            label='(Aanvullende) postcodes'
                            info='De postcodes waar je zendingen gaat bezorgen. Gebruik een komma om postcodes te scheiden en een streepje om een reeks te noteren, bijvoorbeeld: 1000-2000,3000,4000-5000'
                            value={settings.postalCodes}
                            onChange={this.onChangeCarrierValue.bind(this, 'postalCodes')}
                            disabled={platform && !settings.enabled}
                        />
                    </>
                }

                <MetricInput
                    label='Max gewicht (kg)'
                    value={settings.maxWeight}
                    divisionFactor={1000}
                    onChange={this.onChangeWeight.bind(this)}
                    max={carrier?.maxWeight}
                    placeholder={carrier?.maxWeight}
                    append='kg'
                    disabled={platform && !settings.enabled}
                />

                <P>Max dimensies</P>
                <br/>
                <div style={{display: 'flex', gap: 10}}>
                    <MetricInput
                        label='Lengte'
                        value={settings.maxDimensions?.length}
                        divisionFactor={10}
                        onChange={this.onChangeDimension.bind(this, 'length')}
                        max={carrier?.maxDimensions?.length}
                        placeholder={carrier?.maxDimensions?.length}
                        append='cm'
                        disabled={platform && !settings.enabled}
                    />
                    <MetricInput
                        label='Breedte'
                        value={settings.maxDimensions?.width}
                        divisionFactor={10}
                        onChange={this.onChangeDimension.bind(this, 'width')}
                        max={carrier?.maxDimensions?.width}
                        placeholder={carrier?.maxDimensions?.width}
                        append='cm'
                        disabled={platform && !settings.enabled}
                    />
                    <MetricInput
                        label='Hoogte'
                        value={settings.maxDimensions?.height}
                        divisionFactor={10}
                        onChange={this.onChangeDimension.bind(this, 'height')}
                        max={carrier?.maxDimensions?.height}
                        placeholder={carrier?.maxDimensions?.height}
                        append='cm'
                        disabled={platform && !settings.enabled}
                    />
                </div>

                <MetricInput
                    label='Max inhoud'
                    value={settings.maxVolume}
                    onChange={this.onChangeCarrierValue.bind(this, 'maxVolume')}
                    max={carrier?.maxVolume}
                    placeholder={carrier?.maxVolume}
                    append='dm³'
                    disabled={platform && !settings.enabled}
                />

                {carrierName === reseller.name &&
                    <>
                        <H6 style={{marginTop: 36}}>Bezorgopties</H6>

                        {!reseller.isShop &&
                            <Input
                                label="Aantal bezorgpogingen"
                                type='number'
                                max={99}
                                value={settings.nrOfDeliveries}
                                onChange={this.onChangeCarrierValue.bind(this, 'nrOfDeliveries')}
                            />
                        }


                        {!reseller.isShop &&
                            <Input
                                type='number'
                                label='Bewaartijd'
                                info='Hoeveel dagen blijft de zending in bewaring na de laatste bezorgpoging. Vul 0 in om de zending meteen retour te sturen.'
                                value={settings.nrOfDaysBeforeRetour}
                                onChange={this.onChangeCarrierValue.bind(this, 'nrOfDaysBeforeRetour')}
                            />
                        }

                        {reseller.settings.parcels.products['Same Day'] && reseller.settings.parcels.products['Same Day'][carrierName] &&
                            <>
                                <Toggle
                                    label='Same Day naar bedrijfsadressen'
                                    checked={settings.sameDayBusiness}
                                    onChange={this.onChangeCarrierChecked.bind(this, 'sameDayBusiness')}
                                />

                                {settings.sameDayBusiness &&
                                    <div style={{display: 'flex', alignItems: 'center'}}>
                                        <P style={{flex: 1}}>Same Day bedrijfsadres bezorgtijdvak</P>

                                        <TimePicker
                                            label='Van'
                                            style={{width: 130}}
                                            time={settings.sameDayBusinessFrom || ''}
                                            onChange={this.onChangeCarrierValue.bind(this, 'sameDayBusinessFrom')}
                                        />

                                        <TimePicker
                                            label='Tot'
                                            style={{width: 130, marginLeft: 15}}
                                            time={settings.sameDayBusinessTill || ''}
                                            onChange={this.onChangeCarrierValue.bind(this, 'sameDayBusinessTill')}
                                        />
                                    </div>
                                }

                                <div style={{display: 'flex', alignItems: 'center'}}>
                                    <P style={{flex: 1}}>Same Day bezorgtijdvak</P>

                                    <TimePicker
                                        label='Van'
                                        style={{width: 130}}
                                        time={settings.sameDayFrom || ''}
                                        onChange={this.onChangeCarrierValue.bind(this, 'sameDayFrom')}
                                    />

                                    <TimePicker
                                        label='Tot'
                                        style={{width: 130, marginLeft: 15}}
                                        time={settings.sameDayTill || ''}
                                        onChange={this.onChangeCarrierValue.bind(this, 'sameDayTill')}
                                    />
                                </div>
                            </>
                        }

                        <div style={{display: 'flex', alignItems: 'center'}}>
                            <P style={{flex: 1}}>Next Day bezorgtijdvak</P>

                            <TimePicker
                                label='Van'
                                style={{width: 130}}
                                time={settings.nextDayFrom || ''}
                                onChange={this.onChangeCarrierValue.bind(this, 'nextDayFrom')}
                            />

                            <TimePicker
                                label='Tot'
                                style={{width: 130, marginLeft: 15}}
                                time={settings.nextDayTill || ''}
                                onChange={this.onChangeCarrierValue.bind(this, 'nextDayTill')}
                            />
                        </div>

                        <div style={{display: 'flex', alignItems: 'center'}}>
                            <P style={{flex: 1}}>Next Day bedrijfsadres bezorgtijdvak</P>

                            <TimePicker
                                label='Van'
                                style={{width: 130}}
                                time={settings.businessFrom || ''}
                                onChange={this.onChangeCarrierValue.bind(this, 'businessFrom')}
                            />

                            <TimePicker
                                label='Tot'
                                style={{width: 130, marginLeft: 15}}
                                time={settings.businessTill || ''}
                                onChange={this.onChangeCarrierValue.bind(this, 'businessTill')}
                            />
                        </div>

                        {!reseller.isShop &&
                            <Toggle
                                label='Standaard met handtekening'
                                info='Voor alle zendingen wordt een handtekening gevraagd bij het bezorgen ongeacht of de optie Handtekening voor ontvangst is aangevinkt.'
                                checked={settings.defaultSigned}
                                onChange={this.onChangeCarrierChecked.bind(this, 'defaultSigned')}
                            />
                        }
                    </>
                }

                <H6 style={{marginTop: 36}}>Overig</H6>

                {carrierName === 'PostNL-onlineparcel' &&
                    <Toggle
                        label='Aangetekend aanmelden als Handtekening + Niet bij buren'
                        checked={settings.signedEnabled}
                        onChange={this.onChangeCarrierChecked.bind(this, 'signedEnabled')}
                        disabled={platform && !settings.enabled}
                    />
                }

                <div style={{display: 'flex', alignItems: 'center', gap: 24, marginBottom: 12}}>
                    <Toggle
                        label='Kleur tonen bij scannen'
                        info='Bij meerdere vervoerders met hetzelfde logo kan het handig zijn om tijdens het scannen een kleur te zien voor snelle herkenning'
                        checked={settings.useCustomScanColor}
                        onChange={this.onChangeCarrierChecked.bind(this, 'useCustomScanColor')}
                        style={{marginBottom: 0}}
                        disabled={platform && !settings.enabled}
                    />

                    {settings.useCustomScanColor &&
                        <div style={{width: '50%', display: 'flex', alignItems: 'center', gap: 24}}>
                            <ColorPicker
                                value={settings.customScanColor}
                                colors={Colors.routeColors}
                                onChange={this.onChangeCarrierValue.bind(this, 'customScanColor')}
                                disabled={platform && !settings.enabled}
                            />

                            <P
                                style={{
                                    height: 36,
                                    width: '100%',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    borderRadius: 4,
                                    backgroundColor: settings.customScanColor || '',
                                    color: Colors.isDarkBackground(settings.customScanColor || '') ? 'white' : 'black'
                                }}
                            >
                                {carrier.displayName || carrier.name}
                            </P>
                        </div>
                    }

                </div>

                {carrierName === reseller.name &&
                    <>
                        {!reseller.isShop &&
                            <Input
                                label='Crediteurnummer'
                                info='Hier kun je het crediteurnummer invullen dat wordt gebruikt in je boekhouding voor deze vervoerder'
                                value={settings.creditorCode}
                                onChange={this.onChangeCarrierValue.bind(this, 'creditorCode')}
                            />
                        }
                    </>
                }

                {['DHL-onlineparcel', 'DPD-onlineparcel', 'PostNL-onlineparcel'].includes(carrierName) &&
                    <Toggle
                        label={`Retouren via ${reseller.settings?.accountName || reseller.name}`}
                        info={`Het adres van ${reseller.settings?.accountName || reseller.name} wordt gebruikt als afzender op de verzendlabels.`}
                        checked={settings.returnsViaReseller}
                        onChange={this.onChangeCarrierChecked.bind(this, 'returnsViaReseller')}
                        disabled={platform && !settings.enabled}
                    />
                }

                {carrierName === 'PostNL-onlineparcel' &&
                    <Alert variant='info'>Verzendoptie Niet bij buren wordt bij deze vervoerder aangemeld als Alleen huisadres + Handtekening voor ontvangst.</Alert>
                }

                {carrierName === 'Cycloon' &&
                    <>
                        {!reseller.isShop &&
                            <Input
                                label='Crediteurnummer'
                                info='Hier kun je het crediteurnummer invullen dat wordt gebruikt in je boekhouding voor deze vervoerder.'
                                value={settings.creditorCode}
                                onChange={this.onChangeCarrierValue.bind(this, 'creditorCode')}
                            />
                        }

                        <Select
                            label='Aangetekend aanmelden als'
                            options={[
                                {value: '', title: 'Handtekening voor ontvangst + Niet bij buren'},
                                {value: '5', title: 'Aangetekend pakket'}
                            ]}
                            value={settings.signedParcelProduct || ''}
                            onChange={this.onChangeCarrierValue.bind(this, 'signedParcelProduct')}
                        />

                        <Toggle
                            label='Lagere voorkeur op vrijdag'
                            info='Cycloon krijgt een lagere voorkeur dan andere vervoerders voor pakketten die worden aangemeld op vrijdag, dit voorkomt vertraging van een pakket omdat Cycloon op zaterdag niet bezorgd. Deze instelling kan op klant niveau worden overschreven.'
                            checked={settings.noParcelsOnFriday}
                            onChange={this.onChangeCarrierChecked.bind(this, 'noParcelsOnFriday')}
                        />

                        <Toggle
                            label='Alleen Cycloon bezorgingen per fiets'
                            info='Cycloon is alleen beschikbaar voor zendingen die op de fiets worden bezorgd. Deze instelling kan op klant niveau worden overschreven.'
                            checked={settings.deliveredByBikeOnly}
                            onChange={this.onChangeCarrierChecked.bind(this, 'deliveredByBikeOnly')}
                        />
                    </>
                }

                {carrierName === 'DHL' &&
                    <>
                        {!reseller.isShop &&
                        <Input
                            label='Crediteurnummer'
                            info='Hier kun je het crediteurnummer invullen dat wordt gebruikt in je boekhouding voor deze vervoerder.'
                            value={settings.creditorCode}
                            onChange={this.onChangeCarrierValue.bind(this, 'creditorCode')}
                        />
                        }

                        <Toggle
                            label='Aangetekend optie activeren'
                            info={'De optie \'Aangetekend\' wordt voor deze vervoerder aangemeld als combinatie van de opties \'Handtekening voor ontvangst\' en \'Niet bij buren\'.'}
                            checked={settings.signedEnabled}
                            onChange={this.onChangeCarrierChecked.bind(this, 'signedEnabled')}
                        />


                        <Toggle
                            label='Bedrijfsadressen via DHL Europlus'
                            info='Zendingen naar bedrijfsadressen worden verstuurd via het DHL Europlus netwerk. Deze instelling kan op klant niveau worden overschreven.'
                            checked={settings.europlusForBusinesAddress}
                            onChange={this.onChangeCarrierChecked.bind(this, 'europlusForBusinesAddress')}
                        />

                        {!reseller.isShop &&
                            <Toggle
                                label={`Retouren via ${reseller.settings?.accountName || reseller.name}`}
                                info={`Het adres van ${reseller.settings?.accountName || reseller.name} wordt gebruikt als afzender op de verzendlabels.`}
                                checked={settings.returnsViaReseller}
                                onChange={this.onChangeCarrierChecked.bind(this, 'returnsViaReseller')}
                            />
                        }
                    </>
                }

                {carrierName === 'DHL-MSG' &&
                    <>
                        <Toggle
                            label='Aangetekend optie activeren'
                            info={'De optie \'Aangetekend\' wordt voor deze vervoerder aangemeld met alleen de optie \'Handtekening voor ontvangst\'.'}
                            checked={settings.signedEnabled}
                            onChange={this.onChangeCarrierChecked.bind(this, 'signedEnabled')}
                        />

                        <Input
                            label='Standaard product SKU'
                            info='Vul hier de SKU code in zoals in het Monta account is gedefineerd.'
                            value={settings.defaultProductSKU}
                            onChange={this.onChangeCarrierValue.bind(this, 'defaultProductSKU')}
                        />

                        <Input
                            label='Brievenbuspakket product SKU'
                            info='Vul hier de SKU code in zoals in het Monta account is gedefineerd.'
                            value={settings.mailboxProductSKU}
                            onChange={this.onChangeCarrierValue.bind(this, 'mailboxProductSKU')}
                        />
                    </>
                }

                {['DHL-MSG', 'DHL', 'Cycloon'].includes(carrierName) &&
                    <Toggle
                        label='Lagere voorkeur voor bedrijfsadres'
                        info={`${carrierName} krijgt een lagere voorkeur dan andere vervoerders voor pakketten die worden bezorgd op een bedrijfsadres. Deze instelling kan op klant niveau worden overschreven.`}
                        checked={settings.noParcelsForBusinessAddress}
                        onChange={this.onChangeCarrierChecked.bind(this, 'noParcelsForBusinessAddress')}
                    />
                }

                {carrierName === 'DPD-onlineparcel' &&
                    <Toggle
                        label='Zaterdag bezorging'
                        info='Zendingen die op vrijdag worden aangemeld worden op zaterdag bezorgd. Deze instelling kan op klant niveau worden overschreven.'
                        checked={settings.saturdayDelivery}
                        onChange={this.onChangeCarrierChecked.bind(this, 'saturdayDelivery')}
                        disabled={platform && !settings.enabled}
                    />
                }

                {carrierName === 'DPD-onlineparcel' &&
                    <Alert variant='info'>De verzendopties Handtekening voor ontvangst en Verzekerd worden niet apart aangemeld, maar vallen onder de standaard zending van DPD.</Alert>
                }

                {carrierName === 'Peddler' &&
                    <>
                        {!reseller.isShop &&
                            <Input
                                label='Crediteurnummer'
                                info='Hier kun je het crediteurnummer invullen dat wordt gebruikt in je boekhouding voor deze vervoerder.'
                                value={settings.creditorCode}
                                onChange={this.onChangeCarrierValue.bind(this, 'creditorCode')}
                            />
                        }

                        <Toggle
                            label='Toon sorteercodes op label'
                            checked={settings.sortCodesOnLabel}
                            onChange={this.onChangeCarrierChecked.bind(this, 'sortCodesOnLabel')}
                        />


                    </>
                }

                {carrierName === 'PostNL' &&
                    <>
                        {!reseller.isShop &&
                            <Input
                                label='Crediteurnummer'
                                info='Hier kun je het crediteurnummer invullen dat wordt gebruikt in je boekhouding voor deze vervoerder.'
                                value={settings.creditorCode}
                                onChange={this.onChangeCarrierValue.bind(this, 'creditorCode')}
                            />
                        }

                        <Select
                            label='Standaard emailadres Track & Trace emails'
                            info='Voor PostNL zendingen is het meesturen van een emailadres verplicht. Als een zending geen emailadres heeft wordt de gekozen optie gebruikt.'
                            allowEmptyValue
                            options={[
                                {value: 'resellerEmail', title: reseller.settings.companyAddress.email},
                                {value: 'customerEmail', title: 'Emailadres klant'},
                                {value: 'custom', title: 'Anders'}
                            ]}
                            value={settings.defaultTTEmailAddress}
                            onChange={this.onChangeCarrierValue.bind(this, 'defaultTTEmailAddress')}
                        />

                        {settings.defaultTTEmailAddress === 'custom' &&
                            <Input
                                label='Emailadres'
                                value={settings.customDefaultTTEmailAddress}
                                onChange={this.onChangeCarrierValue.bind(this, 'customDefaultTTEmailAddress')}
                            />
                        }

                        <Select
                            label='Aangetekend aanmelden als'
                            options={[
                                {value: '3089', title: '3089 Handtekening voor ontvangst, Alleen huisadres'},
                                {value: '1020', title: '1020 Aangetekend pakket'}
                            ]}
                            value={settings.signedParcelProductCode || '1020'}
                            onChange={this.onChangeCarrierValue.bind(this, 'signedParcelProductCode')}
                        />

                        <Select
                            label='Aangetekende brief aanmelden als'
                            options={[
                                {value: '1010', title: '1010 Aangetekende brief'},
                                {value: '1410', title: '1410 Aangetekende brief partijenpost'},
                                {value: '3089', title: '3089 Handtekening voor ontvangst, Alleen huisadres'}
                            ]}
                            value={settings.signedLetterProductCode || '1010'}
                            onChange={this.onChangeCarrierValue.bind(this, 'signedLetterProductCode')}
                        />

                        <Select
                            label='Brievenbuspakket buitenland aanmelden als'
                            options={[
                                {value: '6350', title: '6350 Packet Track & Trace'},
                                {value: '6972', title: '6972 Boxable Track & Trace'}
                            ]}
                            value={settings.mailboxAbroadProductCode || '6350'}
                            onChange={this.onChangeCarrierValue.bind(this, 'mailboxAbroadProductCode')}
                        />

                        <Select
                            label='Buitenland zendingen binnen EU'
                            options={[
                                {value: '', title: 'Niet standaard verzekerd'},
                                {value: 'trackTraceInsured', title: 'Track & trace Verzekerd'},
                                {value: 'trackTraceInsuredPlus', title: 'Track & trace Verzekerd Plus'}
                            ]}
                            value={settings.foreignInsuranceType || ''}
                            onChange={this.onChangeCarrierValue.bind(this, 'foreignInsuranceType')}
                        />

                        {!reseller.isShop &&
                            <Toggle
                                label={`Retouren via ${reseller.settings?.accountName || reseller.name}`}
                                info={`Het adres van ${reseller.settings?.accountName || reseller.name} wordt gebruikt als afzender op de verzendlabels.`}
                                checked={settings.returnsViaReseller}
                                onChange={this.onChangeCarrierChecked.bind(this, 'returnsViaReseller')}
                            />
                        }
                    </>
                }

                {carrierName === 'Skynet' &&
                    <>
                        {!reseller.isShop &&
                            <Input
                                label='Crediteurnummer'
                                info='Hier kun je het crediteurnummer invullen dat wordt gebruikt in je boekhouding voor deze vervoerder'
                                value={settings.creditorCode}
                                onChange={this.onChangeCarrierValue.bind(this, 'creditorCode')}
                            />
                        }

                        {!reseller.isShop &&
                            <Toggle
                                label={`Retouren via ${reseller.settings?.accountName || reseller.name}`}
                                info={`Het adres van ${reseller.settings?.accountName || reseller.name} wordt gebruikt als afzender op de verzendlabels.`}
                                checked={settings.returnsViaReseller}
                                onChange={this.onChangeCarrierChecked.bind(this, 'returnsViaReseller')}
                            />
                        }
                    </>
                }

                {carrierName === 'GLS' &&
                    <>
                        {!reseller.isShop &&
                            <Input
                                label='Crediteurnummer'
                                info='Hier kun je het crediteurnummer invullen dat wordt gebruikt in je boekhouding voor deze vervoerder'
                                value={settings.creditorCode}
                                onChange={this.onChangeCarrierValue.bind(this, 'creditorCode')}
                            />
                        }

                        <Select
                            label='Standaard emailadres Track & Trace emails'
                            info='Voor GLS zendingen is het meesturen van een emailadres verplicht. Als een zending geen emailadres heeft wordt de gekozen optie gebruikt.'
                            allowEmptyValue
                            options={[
                                {value: 'resellerEmail', title: reseller.settings.companyAddress.email},
                                {value: 'customerEmail', title: 'Emailadres klant'},
                                {value: 'custom', title: 'Anders'}
                            ]}
                            value={settings.defaultTTEmailAddress}
                            onChange={this.onChangeCarrierValue.bind(this, 'defaultTTEmailAddress')}
                        />

                        {settings.defaultTTEmailAddress === 'custom' &&
                            <Input
                                label='Emailadres'
                                value={settings.customDefaultTTEmailAddress}
                                onChange={this.onChangeCarrierValue.bind(this, 'customDefaultTTEmailAddress')}
                            />
                        }

                        <Toggle
                            label='Gebruik Economy Parcel als standaard'
                            checked={settings.useEconomyParcel}
                            onChange={this.onChangeCarrierChecked.bind(this, 'useEconomyParcel')}
                        />

                        <Toggle
                            label='Zaterdagbezorging'
                            info='Zendingen die op vrijdag worden aangemeld worden op zaterdag bezorgd. Deze instelling kan op klant niveau worden overschreven.'
                            checked={settings.glsSaturdayDelivery}
                            onChange={this.onChangeCarrierChecked.bind(this, 'glsSaturdayDelivery')}
                        />

                        {!reseller.isShop &&
                            <Toggle
                                label={`Retouren via ${reseller.settings?.accountName || reseller.name}`}
                                info={`Het adres van ${reseller.settings?.accountName || reseller.name} gebruikt als afzender op de verzendlabels.`}
                                checked={settings.returnsViaReseller}
                                onChange={this.onChangeCarrierChecked.bind(this, 'returnsViaReseller')}
                            />
                        }
                    </>
                }

                {carrierName === 'PostNL-MSG' &&
                    <>
                        <Toggle
                            label='Aangetekend optie activeren'
                            info={'De optie \'Aangetekend\' wordt voor deze vervoerder aangemeld als combinatie van de opties \'Handtekening voor ontvangst\' en \'Niet bij buren\'.'}
                            checked={settings.signedEnabled}
                            onChange={this.onChangeCarrierChecked.bind(this, 'signedEnabled')}
                        />
                        <Toggle
                            label='Aangetekende brief optie activeren'
                            info={'De optie \'Aangetekende brief\' wordt voor deze vervoerder aangemeld als combinatie van de opties \'Handtekening voor ontvangst\' en \'Niet bij buren\'.'}
                            checked={settings.signedLetterEnabled}
                            onChange={this.onChangeCarrierChecked.bind(this, 'signedLetterEnabled')}
                        />

                        <Input
                            label='Standaard product SKU'
                            info='Vul hier de SKU code in zoals in het Monta account is gedefineerd.'
                            value={settings.defaultProductSKU}
                            onChange={this.onChangeCarrierValue.bind(this, 'defaultProductSKU')}
                        />

                        <Input
                            label='Brievenbuspakket product SKU'
                            info='Vul hier de SKU code in zoals in het Monta account is gedefineerd.'
                            value={settings.mailboxProductSKU}
                            onChange={this.onChangeCarrierValue.bind(this, 'mailboxProductSKU')}
                        />
                    </>
                }

                {carrierName === 'PostNL-QLS' &&
                    <>
                        <Toggle
                            label='Aangetekend optie activeren'
                            info={'De optie \'Aangetekend\' wordt voor deze vervoerder aangemeld als combinatie van de opties \'Handtekening voor ontvangst\' en \'Niet bij buren\'.'}
                            checked={settings.signedEnabled}
                            onChange={this.onChangeCarrierChecked.bind(this, 'signedEnabled')}
                        />

                        <Toggle
                            label='Aangetekende brief optie activeren'
                            info={'De optie \'Aangetekende brief\' wordt voor deze vervoerder aangemeld als combinatie van de opties \'Handtekening voor ontvangst\' en \'Niet bij buren\'.'}
                            checked={settings.signedLetterEnabled}
                            onChange={this.onChangeCarrierChecked.bind(this, 'signedLetterEnabled')}
                        />
                    </>
                }

                {carrier?.type === 'partner' &&
                    <>
                        {!reseller.isShop &&
                            <>
                                <Input
                                    label='Crediteurnummer'
                                    info='Hier kun je het crediteurnummer invullen dat wordt gebruikt in je boekhouding voor deze vervoerder'
                                    value={settings.creditorCode}
                                    onChange={this.onChangeCarrierValue.bind(this, 'creditorCode')}
                                />
                            </>
                        }
                    </>
                }


                <div style={{display: 'flex', justifyContent: 'flex-end', marginTop: 90}}>
                    {settings.enabled &&
                        <Button variant='error' onClick={this.onDisable.bind(this, !!platform)}>
                            Deactiveer
                        </Button>
                    }

                    <div style={{flex: 1}}/>

                    <Button variant='text' onClick={platform ? () => this.onSubmit(undefined, false, true, 'close') : this.close.bind(this)}>
                        Annuleer
                    </Button>

                    <Button onClick={this.onSubmit.bind(this)}>
                        {settings.enabled || platform ? 'Opslaan' : 'Activeer'}
                    </Button>
                </div>
                <SaveBeforeExitPopup ref={(ref) => this.savePopup = ref}/>
            </Modal>
        )
    }
}

