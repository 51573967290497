import React from 'react'

import _ from 'underscore'

import {Button, IconButton, Divider, H6, S1, S2, P, Row, Popup, Panel, ListItem} from '../../UI/index.js'
import {CarrierSelector, Input, CurrencyInput, Select} from '../../UI/index.js'
import PriceTableModal from './PriceTableModal.js'
import ImportPriceTableModal from './ImportPriceTableModal.js'
import ParcelFeeModal from './ParcelFeeModal.js'


export default class PriceTable extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            selectedCarrier: props.carriers[0] || {},
            selectedPriceTable: 'Standaard'
        }

        addEventListener('paste', (event) => {
            let pastedText

            try {
                pastedText = JSON.parse(event.clipboardData.getData('text'))
            } catch (err) {
                //
            }

            if (pastedText?.name && pastedText?.priceTable) {
                this.onClickAddPriceTable(pastedText.name, pastedText.priceTable)
            }
        })
    }

    onChangePriceTable(event) {
        this.setState({selectedPriceTable: event.target.value})
    }

    onChangeCarrier(event) {
        const {carriers} = this.props

        const selectedCarrier = _.findWhere(carriers, {name: event.target.value})

        this.setState({selectedCarrier})
    }


    onClickAddPriceTable(name, priceTable) {
        const {reseller, onChange} = this.props

        if (!reseller.settings.parcels.prices[name]) {
            reseller.settings.parcels.prices = reseller.settings.parcels.prices || {}
            reseller.settings.parcels.prices[name] = priceTable
            this.setState({selectedPriceTable: name})
            onChange(reseller, `parcels.prices.${name}`)
        }
    }

    async onClickOpenPriceTableModal(name, oldName, priceTable) {
        this.PriceTableModal.open(name, oldName, structuredClone(priceTable))

        if (priceTable) {
            await navigator.clipboard.writeText(JSON.stringify({name, priceTable}))
        }
    }

    onEditPriceTable(name, oldName, priceTable) {
        const {reseller, onChange, onUpdateCustomers} = this.props

        onUpdateCustomers(name, oldName, (err) => {
            if (!err) {
                delete reseller.settings.parcels.prices[oldName]
                reseller.settings.parcels.prices = reseller.settings.parcels.prices || {}
                reseller.settings.parcels.prices[name] = priceTable

                this.setState({selectedPriceTable: name})
                if (oldName !== name) {
                    onChange(reseller, `parcels.prices.${name}`, `parcels.prices.${oldName}`)
                } else {
                    onChange(reseller, `parcels.prices.${name}`)
                }
            }
        })
    }

    onRemovePriceTable(name) {
        const {reseller, onChange, onUpdateCustomers} = this.props

        onUpdateCustomers('', name, (err) => {
            if (!err) {
                delete reseller.settings.parcels.prices[name]
                this.setState({selectedPriceTable: 'Standaard'})
                onChange(reseller, 'parcels.prices', `parcels.prices.${name}`)
            }
        })
    }

    onClickCopyCarrierPriceTable(priceTable, carrier) {
        this.setState({copiedPriceTable: priceTable?.[carrier?.name] || {}})
    }


    onSaveFee(fee, index) {
        const {reseller, onChange} = this.props

        console.log(index)

        if (index >= 0) {
            reseller.settings.parcels.fees[index] = fee
        } else {
            reseller.settings.parcels.fees.push(fee)
        }

        onChange(reseller, 'parcels.fees')
    }

    onRemoveFee(index) {
        const {reseller, onChange} = this.props

        reseller.settings.parcels.fees.splice(index, 1)

        onChange(reseller, 'parcels.fees')
    }


    onClickExportTable() {
        const {reseller} = this.props

        const exportObj = {
            resellerName: reseller.name,
            priceTables: reseller.settings.parcels.prices || {},
            fees: reseller.settings.parcels.fees || []
        }

        const blob = new Blob([JSON.stringify(exportObj)], {type: 'application/json'})

        const linkEl = document.createElement('a')
        linkEl.href = URL.createObjectURL(blob)
        linkEl.setAttribute('download', `${exportObj.resellerName} Tarieven`)
        linkEl.click()
    }

    onClickImportTable() {
        const {onChange} = this.props

        this.importPriceTableModal?.open((reseller) => {
            Object.keys(reseller.settings.parcels.prices).map((priceTable) => {
                onChange(reseller, `parcels.prices.${priceTable}`)
            })
        })
    }


    render() {
        const {selectedPriceTable, selectedCarrier, copiedPriceTable} = this.state
        const {reseller, carriers} = this.props

        const enabledCarriers = _.filter(carriers, (carrier) => {
            return reseller.settings.parcels.carriers[carrier.name] && reseller.settings.parcels.carriers[carrier.name].enabled
        })

        const priceTables = _.uniq(['Standaard', ..._.keys(reseller.settings.parcels.prices).sort()])
        const priceTable = reseller.settings.parcels.prices[selectedPriceTable] || {}

        let selectedCarrierProducts = []

        if (selectedCarrier && selectedCarrier.products) {
            selectedCarrierProducts = [
                'Standaard zending',
                ..._.keys(selectedCarrier.products).sort()
            ]
        }

        return (
            <>
                <div>
                    <Row style={{marginBottom: 24}}>

                    </Row>

                    <div style={{display: 'flex', alignItems: 'center', marginBottom: 24}}>
                        <Select
                            style={{width: 300, marginRight: 15}}
                            label='Tarieftabel'
                            value={selectedPriceTable}
                            onChange={this.onChangePriceTable.bind(this)}
                        >
                            {priceTables.map((priceTable) => {
                                return <option key={priceTable} value={priceTable}>{priceTable}</option>
                            })}
                        </Select>

                        <div style={{display: 'flex', height: 40, marginBottom: 12}}>
                            <Button
                                variant='outline'
                                icon='mdi mdi-plus'
                                label='Nieuw'
                                onClick={this.onClickOpenPriceTableModal.bind(this, '', '', {})}
                            />

                            <Button
                                variant='outline'
                                icon='mdi mdi-content-copy'
                                label='Kopiëren'
                                onClick={this.onClickOpenPriceTableModal.bind(this, `${selectedPriceTable} kopie`, '', priceTable)}
                            />


                            <Button
                                variant='outline'
                                icon='mdi mdi-pencil'
                                label='Bewerken'
                                onClick={this.onClickOpenPriceTableModal.bind(this, selectedPriceTable, selectedPriceTable, priceTable)}
                            />

                            {selectedPriceTable !== 'Standaard' &&
                                <Button
                                    variant='outline'
                                    icon='mdi mdi-delete'
                                    label='Verwijderen'
                                    onClick={() => {
                                        this.popup.open('Tarieftabel verwijderen', 'Weet je zeker dat je deze tarieftabel wilt verwijderen?', () => {
                                            this.onRemovePriceTable(selectedPriceTable)
                                            this.popup.close()
                                        })
                                    }}
                                />
                            }

                            <Button
                                variant='outline'
                                icon='mdi mdi-file-export'
                                label='Alle exporteren'
                                onClick={this.onClickExportTable.bind(this)}
                            />

                            <Button
                                variant='outline'
                                icon='mdi mdi-file-import'
                                label='Importeren'
                                onClick={this.onClickImportTable.bind(this)}
                            />
                        </div>
                    </div>

                    <div style={{display: 'flex', alignItems: 'center'}}>
                        <div style={{display: 'flex', width: 330, height: 40}}>
                            <IconButton
                                disabled={!selectedCarrier.name}
                                tooltip={'Kopieer vervoerder tarieven'}
                                onClick={this.onClickCopyCarrierPriceTable.bind(this, priceTable, selectedCarrier)}
                            >
                                <i className='mdi mdi-content-copy'/>
                            </IconButton>
                        </div>
                        <CarrierSelector
                            size={45}
                            carriers={enabledCarriers}
                            value={selectedCarrier.name}
                            onChange={this.onChangeCarrier.bind(this)}
                        />
                    </div>
                </div>

                {selectedCarrierProducts.map((productName) => {
                    if (productName == 'Standaard zending' || (reseller.settings.parcels.products[productName] && reseller.settings.parcels.products[productName][selectedCarrier.name])) {
                        let product = selectedCarrier.products[productName]

                        if (productName == 'Standaard zending') {
                            product = {type: 'product', countries: selectedCarrier.countries}
                        }

                        if (!priceTable?.[selectedCarrier.name]?.[productName] || priceTable?.[selectedCarrier.name]?.[productName].length === 0) {
                            return
                        }

                        return (
                            <div key={productName} style={{marginBottom: 12}}>
                                <S1 style={{marginBottom: 12}}>{productName}</S1>

                                {priceTable[selectedCarrier.name] && priceTable[selectedCarrier.name][productName] && priceTable[selectedCarrier.name][productName].countryGroups.map((priceRule, index) => {
                                    const priceScaleEnabled = priceTable[selectedCarrier.name]?.usePriceScales && priceTable[selectedCarrier.name][productName]?.usePriceScales

                                    return (
                                        <div key={`${selectedCarrier.name}${productName}${index}`} style={{display: 'flex', marginLeft: 15}}>
                                            <div style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                flexShrink: 0,
                                                width: 285,
                                                height: 30
                                            }}
                                            >
                                                <S2>{priceRule.name}</S2>
                                            </div>

                                            <Select
                                                style={{width: 150, marginRight: 12}}
                                                allowEmptyValue
                                                value={priceRule.type}
                                                readOnly
                                            >
                                                {product.type === 'product' &&
                                                    <option value='fixed'>Vaste prijs</option>
                                                }

                                                {product.type === 'product' &&
                                                    <option value='weight'>Categorieën</option>
                                                }

                                                {product.type === 'product' &&
                                                    <option value='fixedKg'>Kilo toeslag</option>
                                                }

                                                {product.type !== 'product' &&
                                                    <option value='fee'>Toeslag</option>
                                                }

                                                {product.type !== 'product' &&
                                                    <option value='feepercent'>Toeslag %</option>
                                                }
                                            </Select>

                                            {priceRule.type === 'weight' && priceRule.categories.map((category, j) => {
                                                return (
                                                    <div
                                                        key={j}
                                                        style={{
                                                            width: 150,
                                                            flexShrink: 0,
                                                            marginRight: 12
                                                        }}
                                                    >
                                                        {priceScaleEnabled ?
                                                            <Input
                                                                label=''
                                                                value={category.name || `t/m ${category.maxWeight} kg`}
                                                                readOnly
                                                            /> :
                                                            <CurrencyInput
                                                                label={category.name || `t/m ${category.maxWeight} kg`}
                                                                value={category.price}
                                                                readOnly
                                                            />
                                                        }
                                                        {priceScaleEnabled &&
                                                            <div>
                                                                {category.priceScales?.map((priceScale, i) => (
                                                                    <CurrencyInput
                                                                        key={i}
                                                                        style={{marginLeft: 0, marginRight: 0}}
                                                                        label={`${category.priceScales[i - 1]?.maxAmount + 1 || 0}${priceScale.maxAmount ? ` - ${priceScale.maxAmount} st` : '+ stuks'}`}
                                                                        value={priceScale.price}
                                                                        readOnly
                                                                    />
                                                                ))}
                                                            </div>
                                                        }
                                                    </div>
                                                )
                                            })}

                                            {(priceRule.type === 'fixed' || priceRule.type === 'fixedKg' || priceRule.type === 'fee' || priceRule.type === 'feepercent') &&
                                            <div style={{display: 'flex', width: 800, flexWrap: 'wrap'}}>
                                                {product.values ?
                                                    product.values.map((value) => {
                                                        return (
                                                            <div
                                                                key={value}
                                                                style={{
                                                                    width: 150,
                                                                    marginRight: 12
                                                                }}
                                                            >
                                                                {priceRule.type !== 'feepercent' &&
                                                                    <CurrencyInput
                                                                        label={`${productName === 'Verzekerd' && 'Tot: '}${value}`}
                                                                        value={priceRule.values[value]}
                                                                        readOnly
                                                                    />
                                                                }

                                                                {priceRule.type === 'feepercent' &&
                                                                    <Input
                                                                        label={`${productName === 'Verzekerd' && 'Tot: '}${value}`}
                                                                        value={priceRule.values[value]}
                                                                        append='%'
                                                                        readOnly
                                                                    />
                                                                }
                                                            </div>
                                                        )
                                                    }) :
                                                    <div style={{width: 150, marginRight: 12}}>
                                                        {priceRule.type !== 'feepercent' &&
                                                        <>
                                                            {priceScaleEnabled ?
                                                                <div>
                                                                    {priceRule.priceScales?.map((priceScale, i) => (
                                                                        <CurrencyInput
                                                                            key={i}
                                                                            style={{marginLeft: 0, marginRight: 0}}
                                                                            label={`${priceRule.priceScales[i - 1]?.maxAmount + 1 || 0}${priceScale?.maxAmount ? ` - ${priceScale?.maxAmount} st` : '+ stuks'}`}
                                                                            value={priceScale?.price}
                                                                            readOnly
                                                                        />
                                                                    ))}
                                                                </div> :
                                                                <CurrencyInput
                                                                    label='Prijs'
                                                                    value={priceRule.price}
                                                                    readOnly
                                                                />
                                                            }
                                                        </>
                                                        }
                                                        {priceRule.type === 'feepercent' &&
                                                            <Input
                                                                label='Percentage'
                                                                value={priceRule.price}
                                                                append='%'
                                                                readOnly
                                                            />
                                                        }

                                                    </div>
                                                }

                                                {priceRule.type === 'fixedKg' &&
                                                    <CurrencyInput
                                                        style={{width: 150, marginRight: 12}}
                                                        label='Toeslag per kg'
                                                        value={priceRule.fee}
                                                        readOnly
                                                    />
                                                }

                                                {priceRule.type === 'fixedKg' &&
                                                    <Select
                                                        style={{width: 150, marginRight: 12}}
                                                        label='Afronden op'
                                                        options={[
                                                            {title: 'kg', value: ''},
                                                            {title: '100g', value: '100g'},
                                                            {title: 'g', value: 'g'}
                                                        ]}
                                                        value={priceRule.round}
                                                        readOnly
                                                    />
                                                }
                                            </div>
                                            }
                                        </div>
                                    )
                                })
                                }
                            </div>
                        )
                    }
                })}

                <br/>
                <br/>

                <H6>Toeslagen</H6>
                <Divider/>

                {reseller.settings.parcels.fees?.length > 0 &&
                    <Panel style={{width: 500, marginBottom: 12}} >
                        {(reseller.settings.parcels.fees || []).map((fee, index) => {
                            return (
                                <ListItem
                                    key={`fee${index}`}
                                    onClick={() => this.parcelFeeModal.open(fee, index)}
                                >
                                    <P style={{flex: 1}}>{fee.description}</P>
                                    <P style={{marginLeft: 36}}>{`€ ${fee.price || '0,00'}`}</P>
                                </ListItem>
                            )
                        })}
                    </Panel>
                }

                <Button
                    variant='outline'
                    icon='mdi mdi-plus'
                    label='Nieuwe toeslag'
                    onClick={() => this.parcelFeeModal.open()}
                />

                <PriceTableModal
                    reseller={reseller}
                    carriers={carriers}
                    onChange={this.onEditPriceTable.bind(this)}
                    onAdd={this.onClickAddPriceTable.bind(this)}
                    onRemove={this.onRemovePriceTable.bind(this)}
                    onClickCopyCarrierPriceTable={this.onClickCopyCarrierPriceTable.bind(this)}
                    copiedPriceTable={copiedPriceTable}
                    ref={(ref) => this.PriceTableModal = ref}
                />

                <ImportPriceTableModal
                    reseller={reseller}
                    ref={(ref) => this.importPriceTableModal = ref}
                />

                <ParcelFeeModal
                    reseller={reseller}
                    carriers={carriers}
                    onRemove={this.onRemoveFee.bind(this)}
                    onChange={this.onSaveFee.bind(this)}
                    ref={(ref) => this.parcelFeeModal = ref}
                />

                <Popup
                    ref={(ref) => this.popup = ref}
                    cancelBtnText='Terug'
                    confirmBtnText='Verwijderen'
                />
            </>
        )
    }
}


