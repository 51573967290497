import React from 'react'

import Reflux from 'reflux-react-16'
import moment from 'moment'

import LoginStore from '../../stores/LoginStore.js'
import LoginActions from '../../actions/LoginActions.js'

import LoginPopup from '../../components/login/LoginPopup.js'
import {Alert, Button, Card, Colors, P, VerificationCodeInput, H5} from '../../components/UI/index.js'
import {Input} from '../../components/UI/index.js'
import Setup2FA from './Setup2FA.js'
import LoadingPage from '../../components/UI/LoadingPage.js'

export default class Login extends Reflux.Component {
    constructor(props) {
        super(props)

        this.initTime = moment()

        this.interval = setInterval(() => {
            if (moment().diff(this.initTime, 'minutes') > 10) {
                window.location = '/login'
            }
        }, 10000)

        this.state = {
            email: '',
            password: '',
            showMfa: false,
            code: '',
            token: null,
            index: null,
            loading: false,
            error: '',
            successMessage: '',
            timeLeftTillLoginSeconds: 0,
            showSendMfaEmail: false,
            showInsertRecoveryCode: false,
            recoveryCode: '',
            firstTime2fa: false
        }

        this.store = LoginStore
    }

    componentDidMount() {
        const {history, location} = this.props

        document.title = 'Login'

        this.emailInput?.focus()

        const query = new URLSearchParams(location.search)
        const redirect = query.get('redirect')

        LoginActions.loggedIn((loggedIn, link) => {
            if (loggedIn) {
                history.push(redirect || link)
            }
        })
    }

    componentWillUnmount() {
        super.componentWillUnmount.call(this)
        clearInterval(this.interval)
        clearInterval(this.checkTimeLeftInterval)
        clearTimeout(this.MfaEmailTimeout)
    }

    onSubmit(event) {
        event.preventDefault()
        this.setState({loading: true, error: ''})

        const {history, location} = this.props
        const {email, password} = this.state

        const query = new URLSearchParams(location.search)
        let redirect = query.get('redirect')

        LoginActions.getAccounts(email, password, async (err, response) => {
            if (err) {
                this.setState({loading: false, error: err})
                return
            }

            if (response.code === 401) {
                const checkTimeLeft = () => {
                    const now = moment()

                    const notAllowedTill = moment(response.noLoginTill)

                    if (notAllowedTill.isAfter(now)) {
                        this.setState({timeLeftTillLoginSeconds: notAllowedTill.diff(now, 'seconds')})
                    } else {
                        this.setState({timeLeftTillLoginSeconds: 0})
                        clearInterval(this.checkTimeLeftInterval)
                    }
                }

                checkTimeLeft()

                this.checkTimeLeftInterval = setInterval(checkTimeLeft, 1000)

                this.setState({loading: false})
                return
            }

            this.setState({loading: false})

            const token = response.token
            const accounts = response.accounts

            const index = accounts.length > 1 ? await this.loginPopup.open(accounts) : 0
            const account = accounts[index]

            if (redirect) {
                if (redirect.indexOf('/admin') > -1) {
                    if (!account.isAdmin) {
                        redirect = ''
                    }
                } else if (redirect.indexOf('/klant') > -1) {
                    if (!account.isCustomer) {
                        redirect = ''
                    }
                } else if (redirect.indexOf('/koerier') > -1) {
                    if (!account.isCustomer) {
                        redirect = ''
                    }
                } else if (!account.isReseller) {
                    redirect = ''
                }
            }

            this.setState({resellerName: account.reseller})

            if (account.mfaRequired) {
                if (account.mfaEnabled) {
                    this.setState({loading: false, token, index, showMfa: true, mfaChoice: account.mfaMethod}, () => {
                        if (account.mfaMethod === 'email') {
                            this.mailMFACode()
                        }
                    })
                } else {
                    LoginActions.generateMFAKey(token, index, (err, key, recoveryCode) => {
                        if (err) {
                            this.setState({error: err})
                        } else {
                            this.setState({
                                loading: false,
                                token,
                                index,
                                setupMFA: true,
                                authenticatorAppEnabled: account.authenticatorAppEnabled,
                                authenticatorEmailEnabled: account.authenticatorEmailEnabled,
                                MFAKey: key,
                                firstTime2fa: true,
                                recoveryCode
                            })
                        }
                    })
                }
            } else {
                this.setState({loading: true, error: ''})

                LoginActions.login(token, index, null, (err, link) => {
                    if (!err) {
                        history.push(redirect || link)
                    } else {
                        this.setState({loading: false, error: err})
                    }
                })
            }
        })
    }

    mailMFACode(mfaEmail) {
        const {index, token} = this.state

        this.setState({setupMFA: false, showMfa: true, showSendMfaEmail: false})

        if (this.MfaEmailTimeout) {
            clearTimeout(this.MfaEmailTimeout)
        }

        LoginActions.mailMFACode(token, index, mfaEmail, (err) => {
            if (err) {
                this.setState({error: err})
            } else {
                this.setState({setupMFA: false, showMfa: true})
            }
        })

        this.MfaEmailTimeout = setTimeout(() => {
            this.setState({showSendMfaEmail: true})
        }, 10000)
    }

    reset2FA(event) {
        event.preventDefault()
        const {recoveryCodeInput, index, token} = this.state

        this.setState({error: '', loading: true})

        if (!recoveryCodeInput) {
            this.setState({error: 'Er is geen recovery code ingevuld.', loading: false})
            return
        }

        LoginActions.reset2FA(token, index, recoveryCodeInput, (err) => {
            if (err) {
                this.setState({error: err})
            } else {
                this.setState({successMessage: 'Reset gelukt. Log in om tweestapsverificatie opnieuw in te stellen.', recoverySuccess: true})
            }
            this.setState({loading: false})
        })
    }

    onSubmitCode(event) {
        event.preventDefault()

        this.setState({loading: true, error: ''})

        const {history, location} = this.props
        const {token, index, code, firstTime2fa} = this.state

        const query = new URLSearchParams(location.search)
        const redirect = query.get('redirect')

        LoginActions.login(token, index, code, (err, link) => {
            if (!err) {
                if (!firstTime2fa) {
                    history.push(redirect || link)
                } else {
                    this.setState({showRecoveryCode: true, loading: false, firstTime2fa: false, error: ''})
                }
            } else {
                this.setState({loading: false, error: err})
            }
        })
    }

    onClickForgotPassword() {
        const {history} = this.props
        history.push('/wachtwoordvergeten')
    }

    render() {
        const {email, password, showMfa, loading, brandingLoading, error, timeLeftTillLoginSeconds, MFAKey, recoveryCodeInput, setupMFA, showSendMfaEmail, showInsertRecoveryCode, recoveryCode, resellerName, firstTime2fa, showRecoveryCode, recoverySuccess, successMessage, mfaChoice, branding} = this.state

        if (brandingLoading) {
            return <LoadingPage />
        }

        return (
            <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', height: '100vh', overflowY: 'auto', background: Colors.backgroundNeutral}}>
                <div style={{flex: 1, display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%'}}>
                    <form onSubmit={showMfa ? this.onSubmitCode.bind(this) : this.onSubmit.bind(this)}>
                        <Card shadow='true' style={{display: 'flex', flexDirection: 'column', alignItems: 'center', width: 450, padding: 24, marginTop: 120, marginLeft: 'auto', marginRight: 'auto'}}>
                            <img style={{maxHeight: 128, maxWidth: 256, marginBottom: 48, objectFit: 'contain'}} src={branding?.mainLogo || '/images/VELOYDLOGO.png'} />

                            {!showMfa && !setupMFA &&
                                <>
                                    <Input
                                        noPlaceholder
                                        style={{width: '100%'}}
                                        type='email'
                                        label='Emailadres'
                                        required={true}
                                        value={email}
                                        onChange={(event) => this.setState({email: event.target.value.toLowerCase().replace(/\s+/g, ''), error: ''})}
                                        ref={(ref) => this.emailInput = ref}
                                    />

                                    <Input
                                        noPlaceholder
                                        style={{width: '100%'}}
                                        type='password'
                                        label='Wachtwoord'
                                        required={true}
                                        value={password}
                                        onChange={(event) => this.setState({password: event.target.value, error: ''})}
                                    />
                                </>
                            }

                            {setupMFA &&
                                <Setup2FA
                                    onContinue={(mfaChoice, mfaEmail) => {
                                        this.setState({mfaChoice, error: ''}, () => {
                                            if (mfaChoice === 'app') {
                                                this.setState({setupMFA: false, showMfa: true})
                                            } else if (mfaChoice === 'email') {
                                                this.mailMFACode(mfaEmail)
                                            }
                                        })
                                    }}
                                    MFAKey={MFAKey}
                                    email={email}
                                    resellerName={resellerName}
                                    branding={branding}
                                />
                            }

                            {!showInsertRecoveryCode && showMfa && !showRecoveryCode &&
                                <div style={{marginBottom: 24}}>
                                    <P style={{marginBottom: 24, maxWidth: 500, textAlign: 'center'}}>
                                        Vul de verificatiecode in verkregen via {mfaChoice === 'app' && 'je authenticator app'}{mfaChoice === 'email' && 'e-mail'}.
                                    </P>
                                    <div>
                                        <VerificationCodeInput
                                            onChange={(codeArray) => {
                                                this.setState({code: codeArray.join('')})
                                            }}
                                        />
                                    </div>
                                </div>
                            }

                            {showRecoveryCode &&
                                <div style={{marginBottom: 24}}>
                                    <Alert style={{marginBottom: 24}}>Activatie gelukt!</Alert>

                                    <P style={{maxWidth: 500, marginBottom: 24, textAlign: 'center'}}>Hieronder staat de recovery code die bij de actieve 2FA methode hoort. Deze recovery code kun je gebruiken om de tweestapsverificatie te resetten wanneer je geen toegang meer hebt tot je authenticator app of je e-mail.</P>

                                    <H5 style={{marginBottom: 24, textAlign: 'center'}}>Bewaar deze code goed!</H5>

                                    <P style={{textAlign: 'center'}}>
                                        {recoveryCode}
                                    </P>
                                </div>
                            }

                            {!setupMFA && !showInsertRecoveryCode && (
                                <>
                                    <Button
                                        type='submit'
                                        variant='solid'
                                        color={branding?.color || Colors.brandColor}
                                        style={{
                                            width: (firstTime2fa || showMfa) ? 348 : '100%',
                                            marginTop: (firstTime2fa || showMfa) ? 0 : 12
                                        }}
                                        loading={loading}
                                        disabled={timeLeftTillLoginSeconds > 0}
                                    >
                                        {firstTime2fa ? 'Activeer' : 'Inloggen'}
                                    </Button>

                                    {firstTime2fa && <Button style={{marginTop: 72}} variant='text' onClick={() => this.setState({setupMFA: true, showMfa: false})}>Terug</Button>}
                                </>
                            )}

                            {showMfa && showSendMfaEmail && !showRecoveryCode && !showInsertRecoveryCode && (
                                <Button
                                    style={{marginTop: 72}}
                                    variant='text'
                                    onClick={this.mailMFACode.bind(this, null)}
                                >
                                    Email niet ontvangen? Stuur email nog een keer
                                </Button>
                            )}

                            {showMfa && !firstTime2fa && !showRecoveryCode && !showInsertRecoveryCode &&
                                <Button
                                    style={{marginTop: 72}}
                                    onClick={() => this.setState({showInsertRecoveryCode: true})}
                                    variant='text'
                                >
                                    Geen toegang tot verificatie code?
                                </Button>
                            }

                            {showInsertRecoveryCode && !recoverySuccess &&
                                <div>
                                    <P style={{marginBottom: 24}}>Vul hier de recovery code in om 2FA te resetten.</P>

                                    <Input
                                        label='Recovery code'
                                        value={recoveryCodeInput}
                                        onChange={(event) => this.setState({recoveryCodeInput: event.target.value})}
                                        style={{marginBottom: 24, marginLeft: 0, marginRight: 0, width: '100%'}}
                                    />

                                    <Button
                                        variant='solid'
                                        color={branding?.color || Colors.brandColor}
                                        style={{margin: 'auto', width: '100%'}}
                                        loading={loading}
                                        onClick={this.reset2FA.bind(this)}
                                    >
                                        Reset tweestapsverificatie
                                    </Button>

                                    <Button
                                        variant='text'
                                        onClick={() => this.setState({showInsertRecoveryCode: false})}
                                        style={{marginTop: 72}}
                                    >
                                        Terug
                                    </Button>
                                </div>
                            }

                            {successMessage && recoverySuccess &&
                                <div style={{minHeight: 36, width: '100%'}}>
                                    <Alert style={{marginBottom: 24}}>
                                        {successMessage}
                                    </Alert>

                                    <Button
                                        variant='text'
                                        style={{marginTop: 72}}
                                        onClick={() => history.go(0)}
                                    >
                                        Naar inloggen
                                    </Button>
                                </div>
                            }

                            {(error || timeLeftTillLoginSeconds > 0) &&
                                <div style={{minHeight: 36, marginTop: 12, marginBottom: 24, width: '100%'}}>
                                    {error &&
                                        <Alert variant='danger'>{error}</Alert>
                                    }
                                    {timeLeftTillLoginSeconds > 0 && (
                                        <Alert variant='danger'>Je kan pas weer inloggen over {timeLeftTillLoginSeconds} seconden</Alert>
                                    )}
                                </div>
                            }

                            {!showMfa && !setupMFA &&
                                <Button
                                    variant='text'
                                    style={{marginTop: 72}}
                                    onClick={this.onClickForgotPassword.bind(this)}
                                >
                                    Wachtwoord vergeten?
                                </Button>
                            }
                        </Card>
                    </form>
                </div>

                <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%', padding: 24}}>
                    <img style={{objectFit: 'contain', width: 100}} src='/images/VELOYDLOGO.png' alt='Logo' />

                    <div style={{display: 'flex', marginTop: 12}}>
                        <P>&copy; {moment().year()} Veloyd</P>
                        <P style={{marginLeft: 6, marginRight: 6}}>|</P>
                        <P><a style={{color: Colors.textDark}} target='_blank' href='https://veloyd.nl/privacy-policy/' rel='noreferrer'>Privacy policy</a></P>
                    </div>
                </div>

                <LoginPopup ref={(ref) => this.loginPopup = ref} />
            </div>
        )
    }
}
