import React from 'react'

import {Alert, Card, IconButton, Colors, H1, H2, H3, H4, H5, H6, S1, S2, P, Column, Check, FlexRow, FlexColumn, Option, RadioButton, Panel, Input, Select, TextArea, TimePicker, DatePicker, Toggle, Caption, Badge, Button, Tab, Tabs, NavBar, NavBarLink, NavBarMenu} from '../../components/UI/index.js'

const Components = ({history}) => {
    return (
        <div style={{display: 'flex', flexDirection: 'column', gap: 24, width: '100%', height: '100vh', padding: 100, overflow: 'scroll'}}>
            <NavBar>
                <NavBarLink
                    history={history}
                    active={true}
                    link={{icon: 'mdi mdi-store', title: 'Resellers', target: '/componenten'}}
                />

                <NavBarLink
                    history={history}
                    link={{icon: 'mdi mdi-account', title: 'Klanten', target: '/componenten'}}
                />

                <NavBarLink
                    history={history}
                    link={{icon: 'mdi mdi-package-variant-closed', title: 'Zoeken', target: '/componenten'}}
                />

                <NavBarLink
                    history={history}
                    link={{icon: 'mdi mdi-text-account', title: 'Notificaties', target: '/componenten'}}
                />

                <NavBarLink
                    history={history}
                    link={{icon: 'mdi mdi-finance', title: 'Facturen', target: '/componenten'}}
                />

                <div style={{flex: 1}} />

                <NavBarMenu
                    history={history}
                    links={[
                        {title: 'Uitloggen', icon: 'mdi mdi-logout'}
                    ]}
                    link={{icon: 'mdi mdi-account-circle', title: 'Naam', subTitle: 'Admin'}}
                />
            </NavBar>

            <Alert>Test info banner</Alert>
            <Alert variant='info'>Test info banner</Alert>
            <Alert variant='warning'>Test info banner</Alert>
            <Alert variant='error'>Test info banner</Alert>

            <FlexRow style={{gap: 12}}>
                <Card style={{width: 300, height: 100}}>Card met border</Card>
                <Card shadow='true' style={{width: 300, height: 100}}>Card met shadow</Card>
            </FlexRow>

            <Panel style={{width: 300, height: 100}}>Panel</Panel>

            <FlexColumn>
                <Check label='Checkbox aan' checked={true} />
                <Check label='Checkbox uit' checked={false} />
                <Check label='Checkbox disabled' disabled={true} />
                <Check label='Checkbox indeterminate' indeterminate={true} />
            </FlexColumn>

            <FlexColumn>
                <Option label='M' checked={true} />
                <Option label='D' checked={false} />
                <Option label='W' disabled={true} />
            </FlexColumn>

            <FlexColumn>
                <RadioButton label='Radio 1' checked={true} />
                <RadioButton label='Radio 2' checked={false} />
                <RadioButton label='Radio 3' disabled={true} />
            </FlexColumn>

            <FlexColumn style={{width: 300}}>
                <Toggle label='Toggle aan' checked={true} />
                <Toggle label='Toggle uit' checked={false} />
                <Toggle label='Toggle disabled' disabled={true} />
            </FlexColumn>

            <FlexColumn style={{width: 300}}>
                <Input label='Input' value='Vul hier iets in' />
                <Input label='Input' value='Vul hier iets in' disabled='true' />
                <Select label='Select' value='option1' options={[{label: 'Option 1', value: 'option1'}, {label: 'Option 2', value: 'option2'}]} />
                <Select label='Select' value='option1' options={[{label: 'Option 1', value: 'option1'}, {label: 'Option 2', value: 'option2'}]} disabled='true' />
                <TextArea label='TextArea' value='Vul hier iets in' />
                <TextArea label='TextArea' value='Vul hier iets in' disabled='true' />
                <TimePicker label='TimePicker' value='12:00' />
                <TimePicker label='TimePicker' value='12:00' disabled='true' />
                <DatePicker label='DatePicker' value='2023-01-15' />
                <DatePicker label='DatePicker' value='2023-01-15' disabled='true' />
            </FlexColumn>

            <FlexColumn>
                <H1>Header 1</H1>
                <H2>Header 2</H2>
                <H3>Header 3</H3>
                <H4>Header 4</H4>
                <H5>Header 5</H5>
                <H6>Header 6</H6>
                <S1>Subheader 1</S1>
                <S2>Subheader 2</S2>
                <P>Paragraph</P>
                <Caption>Caption</Caption>

            </FlexColumn>

            <FlexColumn>
                <Badge>Badge</Badge>
                <Badge variant='info'>Badge</Badge>
                <Badge variant='warning'>Badge</Badge>
                <Badge variant='error'>Badge</Badge>
            </FlexColumn>

            <FlexRow style={{gap: 12}}>
                <IconButton icon='mdi mdi-check' />
                <IconButton icon='mdi mdi-check' disabled='true' />
            </FlexRow>

            <FlexRow style={{gap: 12}}>
                <Button icon='mdi mdi-alien' label='Button' />
                <Button active='true' icon='mdi mdi-alien' label='Button' />
                <Button disabled='true' icon='mdi mdi-alien' label='Button' />
            </FlexRow>

            <FlexRow style={{gap: 12}}>
                <Button variant='text' icon='mdi mdi-alien' label='Button' />
                <Button variant='text' active='true' icon='mdi mdi-alien' label='Button' />
                <Button variant='text' disabled='true' icon='mdi mdi-alien' label='Button' />
            </FlexRow>

            <FlexRow style={{gap: 12}}>
                <Button variant='outline-white' icon='mdi mdi-alien' label='Button' />
                <Button variant='outline-white' active='true' icon='mdi mdi-alien' label='Button' />
                <Button variant='outline-white' disabled='true' icon='mdi mdi-alien' label='Button' />
            </FlexRow>

            <FlexRow style={{gap: 12, background: Colors.backgroundNeutral, paddingTop: 12, paddingBottom: 12}}>
                <Button variant='outline-white' icon='mdi mdi-alien' label='Button' />
                <Button variant='outline-white' active='true' icon='mdi mdi-alien' label='Button' />
                <Button variant='outline-white' disabled='true' icon='mdi mdi-alien' label='Button' />
            </FlexRow>

            <FlexRow style={{gap: 12}}>
                <Tabs>
                    <Tab label='Tab 1'active='true'>Tab 1 content</Tab>
                    <Tab label='Tab 2'>Tab 2 content</Tab>
                    <Tab label='Tab 3' disabled='true'>Tab 3 content</Tab>
                </Tabs>
            </FlexRow>

            <FlexRow style={{gap: 12}}>
                <Column style={{width: 100, height: 100, background: Colors.brandColor}}>BrandColor</Column>
                <Column style={{width: 100, height: 100, background: Colors.white}}>White</Column>
                <Column style={{width: 100, height: 100, background: Colors.grey10}}>Grey10</Column>
                <Column style={{width: 100, height: 100, background: Colors.grey20}}>Grey20</Column>
                <Column style={{width: 100, height: 100, background: Colors.grey30}}>Grey30</Column>
                <Column style={{width: 100, height: 100, background: Colors.grey40}}>Grey40</Column>
                <Column style={{width: 100, height: 100, background: Colors.grey50}}>Grey50</Column>
                <Column style={{width: 100, height: 100, background: Colors.grey60}}>Grey60</Column>
            </FlexRow>

        </div>
    )
}

export default Components
