import React from 'react'
import Reflux from 'reflux-react-16'

import Alert from './Alert.js'
import Button from './Button.js'
import Tabs from './Tabs.js'
import Tab from './Tab.js'
import Card from './Card.js'
import {Input} from '../UI/index.js'

import UtilStore from '../../stores/UtilStore.js'
import UtilActions from '../../actions/UtilActions.js'

class EmailPreview extends Reflux.Component {
    constructor(props) {
        super(props)

        this.state = {
            view: 'desktop'
        }

        this.store = UtilStore
    }

    onClickSendPreview() {
        const {type, notification, action} = this.props
        const {email} = this.state

        this.setState({loading: true, message: '', error: ''})

        UtilActions.sendEmailPreview(email, type, notification, action, (err, response) => {
            if (err) {
                this.setState({loading: false, message: '', error: err})
            } else {
                this.setState({loading: false, message: response, error: ''})
            }
        })
    }

    render() {
        const {loading, message, error} = this.state
        const {html, disabled} = this.props

        return (
            <div style={{width: '100%', height: '100%'}}>

                <div style={{display: 'flex', alignItems: 'center', marginBottom: 12}}>
                    {this.state.view === 'mobile' &&
                        <Tabs
                            value={this.state.view}
                            onChange={(event) => this.setState({view: event.target.value})}
                            style={{marginRight: 20}}
                        >
                            <Tab size='sm' value='desktop'>
                                <i className='mdi mdi-monitor' />
                            </Tab>
                            <Tab size='sm' value='mobile'>
                                <i className='mdi mdi-cellphone' />
                            </Tab>
                        </Tabs>
                    }

                    <Input
                        style={{width: 240}}
                        label='Emailadres'
                        value={this.state.email}
                        onChange={(event) => this.setState({email: event.target.value, message: '', error: ''})}
                        disabled={disabled}
                    />

                    <Button
                        style={{marginBottom: 12}}
                        loading={loading}
                        disabled={disabled}
                        onClick={this.onClickSendPreview.bind(this)}
                    >
                        Verstuur voorbeeld
                    </Button>
                </div>

                <div style={{height: 55, marginTop: -25}}>
                    {message &&
                        <Alert variant='success'>{message}</Alert>
                    }

                    {error &&
                        <Alert variant='danger'>{error}</Alert>
                    }
                </div>

                <Card style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                    <div dangerouslySetInnerHTML={{__html: html}} />
                </Card>
            </div>
        )
    }
}

export default EmailPreview
