import React, {CSSProperties, ReactNode} from 'react'
import {Colors} from './index.js'

interface CardProps {
    style?: CSSProperties
    selected?: boolean
    children: ReactNode
    shadow?: boolean
    onClick?: () => void
}

export default class Card extends React.Component<CardProps> {
    render() {
        const {style = {}, selected, children, shadow} = this.props

        const defaultStyle = {
            background: selected ? Colors.backgroundSelected : Colors.backgroundWhite,
            flex: style.flex || style.width ? '' : 1,
            padding: 6,
            border: shadow ? 'none' : `1px solid ${selected ? Colors.grey40 : Colors.grey40}`,
            borderRadius: 4,
            flexShrink: 0,
            boxShadow: shadow ? '0px 2px 4px 0px rgba(0, 0, 0, 0.25)' : '',
            cursor: this.props.onClick ? 'pointer' : 'default',
            ...style
        }

        return (
            <>
                <style>
                    {`
                        .selected {
                            animation: pulse 2s;
                            animation-delay: 0.5s;
                        }

                        @keyframes pulse {
                            0% {
                                background-color: ${Colors.backgroundWhite};
                            }
                            25% {
                                background-color: ${Colors.backgroundSelected};
                            }
                            50% {
                                background-color: ${Colors.backgroundWhite};
                                }
                            75% {
                                background-color: ${Colors.backgroundSelected};                                    
                            }
                            100% {
                                background-color: ${Colors.backgroundWhite};
                            }
                        }
                    `}
                </style>
                <div {...this.props} style={defaultStyle}>
                    {children}
                </div>
            </>
        )
    }
}
