import React from 'react'

import _ from 'underscore'

export default class CarrierLogo extends React.Component {
    render() {
        const {size = 45, carriers, value, style} = this.props

        const carrier = _.findWhere(carriers, {name: value}) || {logo: ''}

        if (!carriers) {
            if (value === 'DHL') {
                carrier.logo = '/images/dhlLogo.png'
            }
            if (value === 'PostNL') {
                carrier.logo = '/images/postnlLogo.png'
            }
        }

        return (
            <div style={style}>
                <img style={{objectFit: 'contain', height: size, maxWidth: 2 * size}} src={carrier.logo} />
            </div>
        )
    }
}
