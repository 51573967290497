import {z} from 'zod'
import {AddressModel} from './address.js'

const NewPackageModel = z.object({
    reseller: z.string(),
    customerId: z.string(),
    status: z.enum(['registered', 'cancelled', 'collected', 'athub', 'delivered', 'return_collected', 'return_athub', 'return_delivered', 'missing', 'manco']),
    events: z.array(z.object({
        timestamp: z.string(),
        status: z.string(),
        orderId: z.string(),
        addressId: z.string(),
        who: z.string()
    })).optional(),
    type: z.string(),
    description: z.string(),
    barcode: z.string().optional(),
    address: AddressModel.optional(),
    addressId: z.string().optional()
})

const PackageModel = NewPackageModel.extend({
    _id: z.string()
})

export {
    NewPackageModel,
    PackageModel
}
