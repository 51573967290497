import React, {useEffect, useState} from 'react'
import {Vehicle} from '../../../dist/server/functions/vehicles/vehicles.types.js'
import Row from '../UI/Row.js'
import moment from 'moment'
import DatePicker from '../UI/DatePicker.js'
import Input from '../UI/Input.js'
import IconButton from '../UI/IconButton.js'
import {P, S2} from '../UI/index.js'

interface NoteEntryProps {
    entry: Vehicle['notesEntries'][0]
    onChange: (entry: Vehicle['notesEntries'][0]) => void
    onRemove: () => void
    scrollAnchorElement?: HTMLElement
}

const NoteEntry = ({entry, onChange, onRemove, scrollAnchorElement}: NoteEntryProps) => {
    const [edit, setEdit] = useState<boolean>(entry.date ? null : true)
    const [date, setDate] = useState(entry.date || moment().format('YYYY-MM-DD'))
    const [note, setNote] = useState(entry.note)
    const [hovering, setHovering] = useState(false)

    useEffect(() => {
        if (typeof edit === 'boolean' && !edit) {
            onChange({date, note})
        }
    }, [edit])

    return (
        <Row style={{width: '100%', alignItems: 'center'}} onMouseEnter={() => setHovering(true)} onMouseLeave={() => setHovering(false)}>
            {edit ?
                    (
                        <>
                            <Input
                                label='Notitie'
                                value={note}
                                onChange={(event) => {
                                    setNote(event.target.value)
                                }}
                            />

                            <DatePicker
                                style={{marginLeft: 0}}
                                label='Datum'
                                value={date}
                                onChange={(event) => {
                                    if ('target' in event) {
                                        setDate(event.target.value)
                                    }
                                }}
                                scrollAnchorElement={scrollAnchorElement}
                            />

                            <IconButton
                                icon='mdi mdi-check'
                                onClick={() => setEdit(false)}
                            />
                            <IconButton
                                icon='mdi mdi-delete'
                                onClick={onRemove}
                            />
                        </>
                    ) :
                    <>
                        <div style={{flex: 1}}>
                            <P>{note}</P>
                        </div>
                        <S2>{moment(date).format('DD-MM-YYYY')}</S2>
                        {hovering ?
                                <IconButton
                                    icon='mdi mdi-pencil'
                                    onClick={() => setEdit(true)}
                                    style={{marginLeft: 8}}
                                /> :
                                <div style={{width: 44, height: 36}} />}
                    </>
            }

        </Row>
    )
}

export default NoteEntry
