import React from 'react'

import {Button, Modal, P, H4} from './index.js'

class ConfirmPopup extends React.Component {
    constructor(props) {
        super(props)
        this.initialState = {
            isOpen: false,
            title: '',
            text: '',
            callback: null
        }

        this.state = JSON.parse(JSON.stringify(this.initialState))
    }

    open(title, text, callback) {
        this.setState({isOpen: true, title, text, callback})

        if (!callback) {
            return new Promise((resolve, reject) => {
                this.resolve = resolve
                this.reject = reject
            })
        }
    }

    close() {
        this.setState({isOpen: false}, () => {
            this.setState(this.initialState)
        })
    }

    onClickButton(bool) {
        if (this.state.callback) {
            this.state.callback(bool)
        } else {
            this.resolve(bool)
        }

        this.close()
    }

    render() {
        const {isOpen, title, text} = this.state

        return (
            <Modal show={isOpen} style={{maxWidth: 400, zIndex: 1031}} onClose={this.close.bind(this)}>
                <div style={{display: 'flex', marginBottom: 24}}>
                    <H4>{title}</H4>

                    <div style={{flex: 1}} />
                </div>

                <P style={{marginBottom: 30}}>{text}</P>

                <div style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-end'}}>
                    <Button
                        variant='text'
                        style={{width: 80}}
                        onClick={this.onClickButton.bind(this, false)}
                    >
                        Nee
                    </Button>

                    <Button
                        variant='text'
                        style={{width: 80}}
                        onClick={this.onClickButton.bind(this, true)}
                    >
                        Ja
                    </Button>

                </div>
            </Modal>
        )
    }
}

export default (ConfirmPopup)
