import React from 'react'

import {Select, AddressSearch, FlexRow, FlexColumn} from './index.js'
import compareAddresses from '../../../utils/compareAddresses.js'

export default class ChooseRoutePoint extends React.Component {
    constructor(props) {
        super(props)

        const {defaultOption = 'companyLocation'} = props

        this.state = {
            addressOption: defaultOption,
            addressField: props.address,
            edit: false,
            positionError: false
        }
    }

    componentDidMount() {
        const {defaultOption = 'companyLocation', address, reseller, users, route} = this.props

        if (compareAddresses(address, users?.[route.messengerId]?.address)) {
            this.setState({addressOption: 'messengerAddress'})
        } else if (!compareAddresses(address, reseller?.settings?.companyAddress)) {
            this.setState({addressOption: 'address'})
        }

        if (!address) {
            this.setState({addressOption: defaultOption})
            this.onAddressOptionChange({
                target: {
                    value: defaultOption
                }
            })
        }
    }

    onConfirm(address) {
        const {onChange} = this.props

        if (address && address.position) {
            delete address.id
            onChange(address)
            this.setState({edit: false})
        } else {
            this.setState({positionError: true})
        }
    }

    async onGetAddress() {
        const position = await new Promise((resolve, reject) => {
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(resolve, reject)
            }
        })

        return {
            lat: position.coords.latitude, lng: position.coords.longitude, accuracy: position.coords.accuracy
        }
    }

    async onAddressOptionChange(event) {
        const {route, reseller, users, user} = this.props
        const option = event.target.value
        let address

        if (option.includes('routeAddress')) {
            const addressIndex = option.split('-')[1]
            address = route.addresses[addressIndex]
        } else if (option === 'address') {
            this.setState({addressField: {}})
        } else if (option === 'companyLocation') {
            address = reseller.settings.companyAddress
        } else if (option.includes('sublocation')) {
            const addressIndex = option.split('-')[1]
            address = reseller.settings.sublocations[addressIndex]
        } else if (option === 'messengerAddress') {
            if (users[route.messengerId]?.address) {
                address = users[route.messengerId]?.address
            }
        } else if (option === 'userAddress') {
            address = user.address
        } else if (option === 'currentLocation') {
            const position = await this.onGetAddress()
            address = {
                name: 'Huidige locatie',
                street: '',
                street2: '',
                nr: '',
                postalCode: '',
                city: '',
                country: '',
                position
            }
        }

        if (option !== 'address') {
            this.setState({addressField: address})
            this.onConfirm(address)
        }

        this.setState({addressOption: option})
    }

    onChangeAddressField(address) {
        this.setState({addressField: address})
        this.onConfirm(address)
    }

    render() {
        const {addressOption, addressField, positionError} = this.state
        const {reseller, users, user, route, label, disabled, readOnly} = this.props

        const addressOptions = [
            {
                title: 'Bedrijfslocatie',
                value: 'companyLocation',
                bold: true
            }
        ]

        reseller.settings.sublocations?.map((address, index) => {
            addressOptions.push({
                title: address.name,
                value: `sublocation-${index}`,
                bold: true
            })
        })

        if (user) {
            addressOptions.push(
                {
                    title: 'Huidige locatie',
                    value: 'currentLocation',
                    bold: true
                },
                {
                    title: 'Mijn adres',
                    value: 'userAddress',
                    bold: true
                }
            )
        }

        if (users?.[route.messengerId]?.address) {
            addressOptions.push({
                title: 'Adres van koerier',
                value: 'messengerAddress',
                bold: true
            })
        }

        addressOptions.push({
            title: 'Anders:',
            value: 'address',
            bold: true
        })

        route.addresses?.map((address, index) => {
            addressOptions.push({
                title: `${address.street} ${address.nr}${address.addition}, ${address.city}`,
                value: `routeAddress-${index}`
            })
        })

        return (
            <FlexRow>
                <FlexColumn>
                    <Select
                        label={label}
                        value={addressOption}
                        onChange={this.onAddressOptionChange.bind(this)}
                        options={addressOptions}
                        disabled={disabled}
                        readOnly={readOnly}
                        info={positionError && 'Dit adres staat niet in onze database, controleer of dit adres geldig is.'}
                        infoIcon='mdi mdi-alert'
                    />
                </FlexColumn>

                <FlexColumn>
                    <AddressSearch
                        defaultAddress={addressField}
                        address={addressField}
                        readOnly={addressOption !== 'address'}
                        onChange={this.onChangeAddressField.bind(this)}
                    />
                </FlexColumn>
            </FlexRow>
        )
    }
}
