import React from 'react'

import {Popover, ListItem, Button} from './index.js'

class DropdownButton extends React.Component {
    onClickShowMenu(event) {
        event.stopPropagation()
        this.popover.show()
    }

    onClickOption(value, action, event) {
        event.stopPropagation()

        if (typeof action === 'function') {
            action(event)
        } else {
            const {onChange} = this.props
            event.target.value = value
            onChange(event)
        }

        this.popover.close()
    }

    render() {
        let {style = {}, variant, value, options, children, disabled} = this.props

        if (typeof children === 'string') {
            children = children.toUpperCase()
        }

        return (
            <div style={{display: 'flex', height: 38, width: style.width, flex: style.flex}}>
                <Button
                    {...this.props}
                    style={{marginRight: 0, ...style}}
                    buttonStyle={{borderRadius: '4px 0px 0px 4px', paddingRight: 6, borderRight: 'none'}}

                >
                    {children}
                </Button>

                <Popover
                    ref={(ref) => this.popover = ref}
                    disabled={disabled}
                    content={
                        <>
                            {options && options.map((option, index) => {
                                return (
                                    <ListItem
                                        key={`${index}button`}
                                        size='sm'
                                        active={typeof option.value === 'string' && value === option.value}
                                        onClick={this.onClickOption.bind(this, option.value, option.action)}
                                    >
                                        {option.title || option.value}
                                    </ListItem>
                                )
                            })}
                        </>
                    }
                >
                    <Button
                        disabled={disabled}
                        style={{marginLeft: 0}}
                        variant={variant}
                        buttonStyle={{borderRadius: '0px 4px 4px 0px', paddingLeft: 6, paddingRight: 3, borderLeft: 'none'}}
                        onClick={this.onClickShowMenu.bind(this)}
                        icon='mdi mdi-menu-down'
                    />
                </Popover>
            </div>
        )
    }
}

export default DropdownButton
