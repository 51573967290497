import React from 'react'

import _ from 'underscore'

import {Check, CurrencyInput, Select} from '../UI/index.js'
import {Popover} from '../UI/index.js'

class Price extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            inputFocused: false,
            priceTable: '',
            priceTableRow: '',
            priceTableColumn: ''
        }
    }

    componentDidMount() {
        const {priceTables, price, fees, onChange} = this.props
        const priceTable = _.keys(priceTables)[0]

        onChange(price, fees || [])

        let priceTableRow = ''

        if (priceTable && _.keys(priceTables[priceTable].table).length === 1) {
            priceTableRow = _.keys(priceTables[priceTable].table)[0]
        }

        let priceTableColumn = ''

        if (priceTableRow && _.keys(priceTables[priceTable].table[priceTableRow]).length === 1) {
            priceTableColumn = _.keys(priceTables[priceTable].table[priceTableRow])[0]
        }

        this.setState({priceTable, priceTableRow, priceTableColumn})
    }

    onChangePrice(event) {
        const {onChange} = this.props

        event.target.value = event.target.value.replace('.', ',')

        if (/^$|^[-]{0,1}[0-9]{0,5}$|^[-]{0,1}[0-9]{0,5}[,][0-9]{0,2}$/.test(event.target.value)) {
            this.setState({priceTableRow: '', priceTableColumn: ''})
            onChange(event.target.value, [])
        }
    }

    onChangePriceTable(event) {
        const {priceTables, price, onChange} = this.props
        const priceTable = event.target.value

        onChange(price, [])

        let priceTableRow = ''

        if (priceTable && _.keys(priceTables[priceTable].table).length === 1) {
            priceTableRow = _.keys(priceTables[priceTable].table)[0]
        }

        let priceTableColumn = ''

        if (priceTableRow && _.keys(priceTables[priceTable].table[priceTableRow]).length === 1) {
            priceTableColumn = _.keys(priceTables[priceTable].table[priceTableRow])[0]
        }

        this.setState({priceTable, priceTableRow, priceTableColumn}, this.onSetPrice)
    }

    onChangePriceTableRow(event) {
        const {priceTables} = this.props
        const {priceTable} = this.state

        const priceTableRow = event.target.value

        let priceTableColumn = ''

        if (priceTableRow && _.keys(priceTables[priceTable].table[priceTableRow]).length === 1) {
            priceTableColumn = _.keys(priceTables[priceTable].table[priceTableRow])[0]
        }

        this.setState({priceTableRow, priceTableColumn}, this.onSetPrice)
    }

    onChangePriceTableColumn(event) {
        const priceTableColumn = event.target.value
        this.setState({priceTableColumn}, this.onSetPrice)
    }

    onSetPrice() {
        const {priceTables, fees, onChange} = this.props
        const {priceTable, priceTableRow, priceTableColumn} = this.state

        let price = 0

        if (priceTable && priceTableRow && priceTableColumn) {
            price = priceTables[priceTable].table[priceTableRow][priceTableColumn] || 0
        }

        fees.map((fee) => {
            price += parseFloat(priceTables[priceTable].fees[fee]) || 0
        })

        price = price.toFixed(2).replace('.', ',')

        onChange(price, fees)
    }

    onChangeFee(key) {
        let {priceTables, price, fees, onChange} = this.props
        const {priceTable} = this.state
        price = parseFloat(price.replace(',', '.')) || 0

        if (_.includes(fees, key)) {
            fees.splice(fees.indexOf(key), 1)
            price -= parseFloat(priceTables[priceTable].fees[key]) || 0
        } else {
            fees.push(key)
            price += parseFloat(priceTables[priceTable].fees[key]) || 0
        }

        price = price.toFixed(2).replace('.', ',')
        onChange(price, fees)
    }

    render() {
        const {style, label, priceTables, price, fees} = this.props
        const {priceTable, priceTableRow, priceTableColumn} = this.state

        return (
            <div style={{position: 'relative'}}>
                <CurrencyInput
                    style={style}
                    label={label}
                    value={price}
                    onChange={this.onChangePrice.bind(this)}
                    ref={(ref) => this.formControl = ref}
                />

                {_.keys(priceTables).length > 0 &&
                    <Popover
                        noClose={true}
                        ref={(ref) => this.popover = ref}
                        popoverStyle={{marginTop: -15}}
                        content={
                            <div style={{padding: 10}}>
                                <div style={{display: 'flex'}}>
                                    {priceTables && _.keys(priceTables).length > 1 &&
                                        <Select
                                            style={{width: 230, marginRight: 6}}
                                            value={priceTable}
                                            onChange={this.onChangePriceTable.bind(this)}
                                        >
                                            {_.keys(priceTables).map((key, i) => {
                                                return <option key={i} value={key}>{key}</option>
                                            })}
                                        </Select>
                                    }

                                    {priceTables && priceTables[priceTable] && _.keys(priceTables[priceTable].table).length > 1 &&
                                        <Select
                                            style={{width: 230, marginLeft: 6, marginRight: 6}}
                                            value={priceTableRow}
                                            allowEmptyValue
                                            onChange={this.onChangePriceTableRow.bind(this)}
                                        >
                                            {priceTable &&
                                                _.keys(priceTables[priceTable].table).map((key, i) => {
                                                    return <option key={i} value={key}>{key}</option>
                                                })
                                            }
                                        </Select>
                                    }

                                    {priceTables && priceTables[priceTable] && _.keys(priceTables[priceTable].table[priceTableRow]).length > 1 &&
                                        <Select
                                            style={{width: 230, marginLeft: 6}}
                                            allowEmptyValue
                                            value={priceTableColumn}
                                            onChange={this.onChangePriceTableColumn.bind(this)}
                                        >
                                            {priceTableRow &&
                                                _.keys(priceTables[priceTable].table[priceTableRow]).map((key, i) => {
                                                    return <option key={i} value={key}>{key}</option>
                                                })
                                            }
                                        </Select>
                                    }
                                </div>

                                <br />

                                <div style={{display: 'flex', flexWrap: 'wrap'}}>
                                    {priceTables && priceTables[priceTable] &&
                                        _.keys(priceTables[priceTable].fees).map((key, i) => {
                                            return (
                                                <Check
                                                    key={i}
                                                    label={key}
                                                    checked={_.includes(fees, key)}
                                                    onChange={this.onChangeFee.bind(this, key)}
                                                />
                                            )
                                        })
                                    }
                                </div>
                            </div>
                        }
                    >
                        <i
                            style={{position: 'absolute', fontSize: 18, right: 10, top: -45, cursor: 'pointer'}}
                            className='mdi mdi-calculator'
                        />
                    </Popover>
                }
            </div>
        )
    }
}

export default (Price)
