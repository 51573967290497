import React from 'react'

import Reflux from 'reflux-react-16'

import AdminStore from '../../stores/AdminStore.js'
import Notifications from '../../components/communication/Notifications.js'

class Dashboard extends Reflux.Component {
    constructor(props) {
        super(props)
        this.state = {}
        this.stores = [AdminStore]
    }

    render() {
        const {resellers, notifications} = this.state
        const {user} = this.props

        return (
            <div style={{height: '100%', paddingTop: 30, paddingLeft: 10, paddingRight: 10}}>
                <Notifications resellers={resellers} user={user} notifications={notifications} />
            </div>
        )
    }
}

export default (Dashboard)
