import Reflux from 'reflux-react-16'
import moment from 'moment'
import _ from 'underscore'
import socket from '../socketio/socketio.js'

import AdminActions from '../actions/AdminActions.js'

export default class AdminStore extends Reflux.Store {
    constructor() {
        super()

        this.state = {
            statistics: {},
            statisticsLoading: false,
            notifications: [],
            resellers: [],
            selectedReseller: null,
            resellersLoading: false,
            month: moment().subtract(1, 'months').format('YYYY-MM'),
            invoices: [],
            selectedInvoices: [],
            invoicesLoading: false
        }

        this.listenables = AdminActions

        AdminActions.getResellers(true)

        const pageSize = localStorage.getItem('_pageSize') || 40
        AdminActions.getCustomers(+pageSize, 1, null)

        socket.on('admin.updateResellers', () => {
            AdminActions.getResellers()
        })

        socket.on('admin.updateInvoices', () => {
            AdminActions.getInvoices()
        })

        socket.on('admin.getNotifications', () => {
            AdminActions.getNotifications()
        })
    }

    onGetStatistics(statisticsLoading) {
        this.setState({statisticsLoading})

        socket.emit('admin.getStatistics', sessionStorage.token, (err, statistics) => {
            this.setState({statistics, statisticsLoading: false})
        })
    }

    onGetResellers(resellersLoading, includeArchived = false) {
        this.setState({resellersLoading})

        socket.emit('admin.getResellers', sessionStorage.token, includeArchived, (err, resellers) => {
            let {selectedReseller} = this.state

            if (selectedReseller) {
                selectedReseller = _.findWhere(resellers, {_id: selectedReseller._id})
            }

            this.setState({resellers, selectedReseller, resellersLoading: false, isArchived: includeArchived})
        })
    }

    onSelectReseller(selectedReseller) {
        this.setState({selectedReseller})
    }

    onUpdateReseller(reseller, user, callback) {
        socket.emit('admin.updateReseller', sessionStorage.token, reseller, user, callback)
    }

    onRemoveReseller(id, callback) {
        socket.emit('admin.removeReseller', sessionStorage.token, id, callback)
    }

    onToggleArchiveReseller(id, callback) {
        socket.emit('admin.toggleArchiveReseller', sessionStorage.token, id, callback)
    }

    onGetCustomers(amount, currentPage, search, callback) {
        this.setState({customersLoading: true})

        socket.emit('admin.getCustomers', sessionStorage.token, amount, currentPage, search, (err, customers, totalCustomers) => {
            if (err) {
                this.setState({customersLoading: false})
                typeof callback === 'function' && callback(err)
            } else {
                this.setState({customers, totalCustomers, customersLoading: false})
                typeof callback === 'function' && callback()
            }
        })
    }

    onSearchOrderAndParcel(trackTraceOrId, callback) {
        socket.emit('admin.searchOrderAndParcel', sessionStorage.token, trackTraceOrId, callback)
    }

    onSetMonth(month) {
        this.setState({month, selectedInvoices: []})
        AdminActions.getInvoices()
    }

    onGetInvoices(invoicesLoading) {
        const {month} = this.state
        this.setState({invoicesLoading})

        socket.emit('admin.getInvoices', sessionStorage.token, month, (err, invoices) => {
            if (!err) {
                this.setState({invoices, invoicesLoading: false})
            }
        })
    }

    onSelectInvoice(id) {
        let {invoices, selectedInvoices} = this.state

        if (id) {
            const index = selectedInvoices.indexOf(id)

            if (index === -1) {
                selectedInvoices.push(id)
            } else {
                selectedInvoices.splice(index, 1)
            }
        } else {
            if (selectedInvoices.length === invoices.length) {
                selectedInvoices = []
            } else {
                selectedInvoices = invoices.map((invoice) => invoice._id)
            }
        }

        this.setState({selectedInvoices})
    }

    onUpdateInvoice(invoice, callback) {
        socket.emit('admin.updateInvoice', sessionStorage.token, invoice, callback)
    }

    onSendInvoice(id, callback) {
        socket.emit('admin.sendInvoice', sessionStorage.token, id, callback)
    }

    onRemoveInvoice(id, callback) {
        socket.emit('admin.removeInvoice', sessionStorage.token, id, callback)
    }

    onGetNotifications() {
        socket.emit('admin.getNotifications', sessionStorage.token, (err, notifications) => {
            if (notifications) {
                this.setState({notifications})
            }
        })
    }

    onCreateNotification(notification, callback) {
        socket.emit('admin.createNotification', sessionStorage.token, notification, callback)
    }

    onUpdateNotification(notification, callback) {
        socket.emit('admin.updateNotification', sessionStorage.token, notification, callback)
    }

    onRemoveNotification(id, callback) {
        socket.emit('admin.removeNotification', sessionStorage.token, id, callback)
    }

    onExportQLS(...args) {
        socket.emit('admin.exportQLS', sessionStorage.token, ...args)
    }
}
