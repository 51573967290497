import React from 'react'
import moment from 'moment'
import aSync from 'async'

import {Button, H4, IconButton, Popup} from '../UI/index.js'
import Table from '../Table/index.js'
import CreateNotification from './CreateNotificationModal.js'
import AdminActions from '../../actions/AdminActions.js'

class Notifications extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            selectedNotifications: []
        }
    }

    componentDidMount() {
        const {user} = this.props

        if (user.isAdmin) {
            AdminActions.getNotifications()
        }
    }

    componentDidUpdate(prevProps) {
        const {user} = this.props

        if (user.isAdmin && user !== prevProps.user) {
            AdminActions.getNotifications()
        }
    }

    columns() {
        return [
            {title: 'Titel', visible: true, key: 'title', width: 200},
            {title: 'Bericht', visible: true, key: 'message', width: 200},
            {title: 'Ontvangers', visible: true, key: 'recipients', render: (notification) => {
                return notification.recipients.toString()
            }, flex: 1},
            {title: 'Verstuurd', visible: true, width: 100, render: (notification) => {
                if (notification.scheduleNotification) {
                    const scheduled = moment(`${notification.scheduleDate} ${notification.scheduleTime}`)
                    const isScheduled = moment().isAfter(scheduled)

                    if (isScheduled) {
                        return 'Ja'
                    }
                }

                return 'Nee'
            }},
            {title: 'Gepland op', visible: true, key: 'scheduleDate', sort: (notification) => {
                if (!notification.scheduleNotification) {
                    return 99999
                }
                return moment(`${notification.scheduleDate} ${notification.scheduleTime}`).valueOf()
            }, render: (notification) => {
                if (notification.scheduleDate && notification.scheduleTime) {
                    return moment(`${notification.scheduleDate} ${notification.scheduleTime}`).format('DD-MM-YYYY HH:mm')
                }
            }, flex: 1},
            {
                title: 'Aangemaakt', visible: true, key: '_id', sort: (notification) => {
                    const dateFromObjectId = new Date(parseInt(notification._id.substring(0, 8), 16) * 1000)
                    return moment(dateFromObjectId).valueOf()
                }, render: (notification) => {
                    const dateFromObjectId = new Date(parseInt(notification._id.substring(0, 8), 16) * 1000)
                    return moment(dateFromObjectId).format('DD-MM-YYYY HH:mm')
                }, flex: 1
            },
            {visible: true, showOnHover: true, width: 40, onClick: () => {}, render: (notification) => {
                return (
                    <IconButton onClick={this.onClickEdit.bind(this, notification, true)}>
                        <i className='mdi mdi-pencil' />
                    </IconButton>
                )
            }}
        ]
    }

    onClickEdit(notification) {
        this.createNotificationModal.open(notification)
    }

    onSendNow() {
        const {notifications} = this.props
        const {selectedNotifications} = this.state

        this.popup.open('Nu versturen?', 'Weet je zeker dat je de geselecteerde notificaties nu wilt versturen?', () => {
            aSync.eachSeries(selectedNotifications, (_id, next) => {
                const notification = notifications.find((n) => n._id === _id)

                notification.scheduleNotification = true
                notification.scheduleDate = moment().format('YYYY-MM-DD')
                notification.scheduleTime = moment().format('HH:mm')

                AdminActions.updateNotification(notification, (err) => {
                    if (err) {
                        console.log(err)
                        alert('Error')
                    }
                    next()
                })
            }, (err) => {
                if (err) {
                    console.log(err)
                    alert('Error')
                }
                this.popup.close()
                this.setState({selectedNotifications: []})
            })
        })
    }

    onTableChange(selectedNotifications) {
        this.setState({selectedNotifications})
    }

    onClickRemove() {
        const {selectedNotifications} = this.state

        this.popup.open('Notificaties verwijderen?', 'Weet je zeker dat je de geselecteerde notificaties wilt verwijderen?', () => {
            aSync.eachSeries(selectedNotifications, (_id, next) => {
                AdminActions.removeNotification(_id)
                next()
            }, (err) => {
                if (err) {
                    console.log(err)
                    alert('Error')
                }
                this.popup.close()
                this.setState({selectedNotifications: []})
            })
        })
    }

    render() {
        const {notifications} = this.props
        const {selectedNotifications} = this.state

        return (
            <div style={{height: '100%'}}>
                <div style={{display: 'flex', flexDirection: 'column', height: '100%', gap: 20, justifyContent: 'center'}}>
                    <H4 style={{marginBottom: 0}}>Notificaties</H4>
                    <div style={{display: 'flex'}}>
                        <Button
                            style={{width: 'fit-content'}}
                            onClick={() => this.createNotificationModal.open()}
                        >Nieuwe notificatie
                        </Button>
                        {selectedNotifications.length > 0 && (
                            <>
                                <Button onClick={this.onSendNow.bind(this)}>
                                    <i className='mdi mdi-send' />
                                </Button>
                                <Button onClick={this.onClickRemove.bind(this)}>
                                    <i className='mdi mdi-delete' />
                                </Button>
                            </>
                        )}
                    </div>
                    <Table
                        style={{width: '100%'}}
                        columns={this.columns()}
                        rows={notifications}
                        selectedRows={selectedNotifications}
                        onChange={this.onTableChange.bind(this)}
                    />
                </div>
                <CreateNotification ref={(ref) => this.createNotificationModal = ref} />
                <Popup ref={(ref) => this.popup = ref} />
            </div>
        )
    }
}

export default Notifications
