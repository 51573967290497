import React, {useState, useEffect} from 'react'

import {H4, IconButton, Modal, Colors, Select, Input, Button} from '../UI/index.js'

import dbCodes from '../../../server/dbCodes.js'

export default function ReasonsSettingsModal({open, onClose, activeSpecification, settings, reseller, type, onChange, onRemove}) {
    const [specification, setSpecification] = useState(structuredClone(activeSpecification))

    useEffect(() => {
        setSpecification(structuredClone(activeSpecification))
    }, [activeSpecification])

    if (!specification) return null

    return (
        <Modal show={open} onClose={onClose} style={{maxWidth: 400}}>
            <div style={{display: 'flex', justifyContent: 'space-between'}}>
                <H4>Specificatie optie</H4>

                <IconButton onClick={onClose}>
                    <i style={{color: Colors.buttonSolid}} className='mdi mdi-close' />
                </IconButton>
            </div>

            <Select
                label='Reden'
                options={[...(settings[`${type}NotPossibleReasons`].includes('default') ? reseller.settings[`${type}NotPossibleReasons`] : settings[`${type}NotPossibleReasons`])].map((reason) => ({
                    value: reason,
                    title: dbCodes.colloReasons[reason.split(';')[1] || reason] || dbCodes.colloStatus[reason]
                }))}
                value={specification.reason}
                onChange={(event) => {
                    specification.reason = event.target.value
                    setSpecification({...specification})
                }}
                disabled={settings[`${type}NotPossibleReasons`].includes('default')}
            />
            <Input
                label='Specificatie'
                value={specification.specification}
                onChange={(event) => {
                    specification.specification = event.target.value
                    setSpecification({...specification})
                }}
                onBlur={() => {
                    // Remove extra spaces
                    specification.specification = specification.specification.trim().replace(/\s+/g, ' ')
                    setSpecification({...specification})
                }}
                disabled={settings[`${type}NotPossibleReasons`].includes('default')}
                maxLength={30}
                capitalize
            />

            <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                <Button variant='error' onClick={onRemove}>Verwijder</Button>

                <Button disabled={!specification.reason || !specification.specification} onClick={() => onChange(specification)}>Opslaan</Button>
            </div>
        </Modal>
    )
}
