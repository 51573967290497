import React from 'react'
import {Colors} from './index.js'

export default class Icon extends React.Component {
    render() {
        const {icon, size, color, style = {}, className} = this.props

        return (
            <i
                style={{
                    fontSize: size === 'xs' ? 12 : size === 'sm' ? 14 : size === 'md' ? 16 : 18,
                    marginRight: 6,
                    color: color || Colors.textDark,
                    ...style
                }}

                className={`${icon} ${className}`}
            />
        )
    }
}
