import React from 'react'

import _ from 'underscore'

import {Button, Input, CurrencyInput, MetricInput, Select} from '../UI/index.js'
import {IconButton} from '../UI/index.js'
import HSCodes from '../../utils/hsCodes.js'

class ParcelCustoms extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            validation: {}
        }
    }

    onChangeValue(key, event) {
        const {parcel, onChange} = this.props
        parcel.customs[key] = event.target.value

        if (key === 'typeItem') {
            if (parcel.customs.typeItem === 'Documents' || parcel.customs.typeItem === 'Gift') {
                parcel.customs.typeNumber = ''
                parcel.customs.number = ''
                parcel.customs.content.map((content) => {
                    content.hsCode = ''
                    content.countryOrigin = 'NL'
                })
            } else {
                parcel.customs.typeNumber = parcel.customs.typeNumber || 'invoice'
            }
        }

        onChange(parcel)
    }

    onChangeContentValue(index, key, event) {
        const {parcel, onChange} = this.props

        if (key === 'weight') {
            if (/^[0-9]{0,5}$/.test(event.target.value)) {
                parcel.customs.content[index][key] = parseInt(event.target.value) || 0
            }
        } else if (key === 'quantity') {
            parcel.customs.content[index][key] = parseInt(event.target.value) || 0
        } else {
            parcel.customs.content[index][key] = event.target.value
        }

        onChange(parcel)
    }

    onClickAddContent() {
        const {parcel, onChange} = this.props

        if (parcel.customs.content.length < 5) {
            parcel.customs.content.push({
                description: '',
                quantity: 1,
                weight: 0,
                value: '0,00',
                hsCode: '',
                countryOrigin: 'NL'
            })
        }

        onChange(parcel)
    }

    onClickRemoveContent(index) {
        const {parcel, onChange} = this.props

        if (parcel.customs.content.length > 1) {
            parcel.customs.content.splice(index, 1)
        }

        onChange(parcel)
    }

    validate() {
        const {parcel} = this.props

        let id = ''
        let valid = true
        const validation = {}

        if (EU.indexOf(parcel.address.country) === -1) {
            parcel.customs.content.map((content, index) => {
                if (parcel.customs.typeItem !== 'Documents' && parcel.customs.typeItem !== 'Gift') {
                    if (!content.hsCode) {
                        id = `hsCode${index}`
                        validation[`hsCode${index}`] = true
                        valid = false
                    }
                }

                if (!content.description) {
                    id = `description${index}`
                    validation[`description${index}`] = true
                    valid = false
                }
            })

            if (parcel.customs.typeItem !== 'Documents' && parcel.customs.typeItem !== 'Gift' && !parcel.customs.number) {
                id = 'number'
                validation.number = true
                valid = false
            }
        }

        if (!valid) {
            const element = document.getElementById(id)
            if (element) {
                element.focus()
            }
        }

        this.setState({validation})
        return valid
    }

    render() {
        const {validation} = this.state
        const {parcel} = this.props

        return (
            <div style={{marginBottom: 12}}>
                <div style={{display: 'flex'}}>
                    <div style={{flex: 1, marginRight: 6}}>
                        <Select
                            label='Type zending'
                            value={parcel.customs.typeItem}
                            onChange={this.onChangeValue.bind(this, 'typeItem')}
                        >
                            <option value='Commercial Goods'>Verkoop goederen</option>
                            <option value='Returned Goods'>Retour goederen</option>
                            <option value='Commercial Sample'>Handelsmonster</option>
                            <option value='Documents'>Documenten</option>
                            <option value='Gift'>Geschenk</option>
                        </Select>
                    </div>
                    <div style={{display: 'flex', flex: 1, marginLeft: 6}}>
                        {parcel.customs.typeItem !== 'Gift' && parcel.customs.typeItem !== 'Documents' &&
                            <>
                                <Select
                                    style={{marginRight: 6}}
                                    label='Type nummer'
                                    value={parcel.customs.typeNumber}
                                    onChange={this.onChangeValue.bind(this, 'typeNumber')}
                                >
                                    <option value='invoice'>Factuur</option>
                                    <option value='certificate'>Certificaat</option>
                                    <option value='license'>Licentie</option>
                                </Select>

                                <Input
                                    id='number'
                                    style={{marginLeft: 6}}
                                    isInvalid={validation.number}
                                    label='Nummer'
                                    value={parcel.customs.number}
                                    onChange={this.onChangeValue.bind(this, 'number')}
                                />
                            </>
                        }
                    </div>
                </div>

                {parcel.customs && parcel.customs.content.map((content, index) => {
                    return (
                        <div key={`customsContent${index}`} style={{display: 'flex'}}>
                            <div style={{display: 'flex', flex: 1, marginRight: 6}}>
                                <Input
                                    style={{maxWidth: 60, marginRight: 6}}
                                    type='number'
                                    label='Aantal'
                                    value={content.quantity}
                                    onChange={this.onChangeContentValue.bind(this, index, 'quantity')}
                                />

                                <Input
                                    id={`description${index}`}
                                    style={{marginLeft: 6, marginRight: 6}}
                                    label='Omschrijving'
                                    isInvalid={validation[`description${index}`]}
                                    value={content.description}
                                    onChange={this.onChangeContentValue.bind(this, index, 'description')}
                                />

                                <MetricInput
                                    style={{maxWidth: 120, marginLeft: 6}}
                                    label='Gewicht'
                                    value={content.weight}
                                    onChange={this.onChangeContentValue.bind(this, index, 'weight')}
                                    append='g'
                                />
                            </div>

                            <div style={{display: 'flex', flex: 1, marginLeft: 6}}>
                                <CurrencyInput
                                    style={{maxWidth: 120}}
                                    label='Waarde'
                                    value={content.value}
                                    onChange={this.onChangeContentValue.bind(this, index, 'value')}
                                />

                                {parcel.customs.typeItem !== 'Gift' && parcel.customs.typeItem !== 'Documents' &&
                                    <>
                                        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'flex-end', flex: 1, marginLeft: 12, marginRight: 6}}>
                                            <Select
                                                id={`hsCode${index}`}
                                                label='HS-code'
                                                onChange={this.onChangeContentValue.bind(this, index, 'hsCode')}
                                                value={content.hsCode}
                                                allowEmptyValue={true}
                                                allowFreeMode={true}
                                                isInvalid={validation.hsCode}
                                                maxOptions={100}
                                            >
                                                {_.keys(HSCodes).map((code) => {
                                                    return (
                                                        <option key={code} value={code}>{`${code} ${HSCodes[code]}`}</option>
                                                    )
                                                })}
                                            </Select>
                                        </div>

                                        <Input
                                            style={{maxWidth: 70, marginLeft: 6}}
                                            label='Landcode'
                                            value={content.countryOrigin}
                                            onChange={this.onChangeContentValue.bind(this, index, 'countryOrigin')}
                                        />
                                    </>
                                }

                                {parcel.customs.content.length > 1 &&
                                    <IconButton onClick={this.onClickRemoveContent.bind(this, index)}>
                                        <i className='mdi mdi-delete' />
                                    </IconButton>
                                }
                            </div>
                        </div>
                    )
                })}

                {parcel.customs.content.length < 5 &&
                    <Button
                        variant='outline-white'
                        icon='mdi mdi-plus'
                        label='Nieuw artikel'
                        onClick={this.onClickAddContent.bind(this)}
                    />
                }
            </div>

        )
    }
}

const EU = [
    'AT', 'BE', 'BG', 'HR', 'CY', 'DK', 'EE', 'FI', 'FR', 'DE', 'GR', 'HU', 'IE',
    'IT', 'LV', 'LT', 'LU', 'MC', 'MT', 'NL', 'CZ', 'PL', 'PT', 'RO', 'SK', 'SI', 'ES', 'SE'
]

export default (ParcelCustoms)
