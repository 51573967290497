import React from 'react'

import moment from 'moment'

import AdminActions from '../../../../actions/AdminActions.js'

import {Button, Card, H6, S2, P, Popup} from '../../../../components/UI/index.js'

class Invoice extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            invoice: JSON.parse(JSON.stringify(props.invoice)),
            loading: false,
            message: '',
            error: ''
        }
    }

    onClickRemove() {
        const {invoice} = this.state

        this.popup.open('Factuur verwijderen', 'De factuur wordt niet verwijderd in Jortt. Na het verwijderen wordt de factuur weer zichtbaar als concept.', () => {
            AdminActions.removeInvoice(invoice._id, (err) => {
                if (err) {
                    this.popup.setError(err)
                } else {
                    this.popup.close()
                }
            })
        })
    }

    render() {
        const {invoice} = this.state

        let totalPrice = 0

        return (
            <Card style={{width: '100%', marginBottom: 30}}>
                <div style={{display: 'flex'}}>
                    <H6 style={{flex: 1}}>{invoice?.resellerDisplayName || invoice.reseller}</H6>
                    <H6 style={{width: 200, marginLeft: 60, textTransform: 'capitalize'}}>{moment(invoice.month).locale('NL').format('MMMM YYYY')}</H6>
                </div>

                <br />
                <br />

                <div style={{display: 'flex', marginBottom: 12}}>
                    <S2 style={{flex: 1}}>Omschrijving</S2>
                    <S2 style={{width: 150}}>Aantal</S2>
                    <S2 style={{width: 150}}>Prijs</S2>
                    <S2 style={{width: 150}}>Totaal</S2>
                </div>

                {invoice.invoiceLines.map((invoiceLine, index) => {
                    totalPrice += invoiceLine.quantity * invoiceLine.price

                    return (
                        <div key={`invoiceLine${index}`} style={{display: 'flex', marginBottom: 5}}>
                            <P style={{flex: 1}}>{invoiceLine.description}</P>

                            <P style={{width: 150}}>
                                {invoiceLine.quantity}
                            </P>

                            <P style={{width: 150}}>
                                {`€ ${invoiceLine.price.toFixed(2).replace('.', ',')}`}
                            </P>

                            <P style={{width: 150}}>{`€ ${(invoiceLine.price * invoiceLine.quantity).toFixed(2).replace('.', ',')}`}</P>

                        </div>
                    )
                })}

                <div style={{display: 'flex', marginTop: 15, marginBottom: 12}}>
                    <S2 style={{flex: 1}} />
                    <P style={{marginLeft: 300, width: 150}}>{`€ ${totalPrice.toFixed(2).replace('.', ',')}`}</P>
                </div>

                <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                    {invoice.status === 'Gefactureerd' &&
                        <Button variant='error' onClick={this.onClickRemove.bind(this)}>Verwijder</Button>
                    }
                </div>

                <Popup ref={(ref) => this.popup = ref} />
            </Card>
        )
    }
}

export default (Invoice)
