import React from 'react'

import {Select} from './index.js'

export default class SelectRoute extends React.PureComponent {
    render() {
        const {label, value, usersObject, routes, date, style, onChange} = this.props

        const options = []

        Object.keys(routes).sort().reverse().map((routeId) => {
            const route = routes[routeId]

            if (route.completed || (date && route.date !== date)) {
                return
            }

            options.push({value: routeId, title: `${route.name || ''}${(route.name && route.messengerId) ? ' - ' : ''}${usersObject[route.messengerId]?.name || ''}`, icon: 'mdi mdi-circle', iconColor: route.color})
        })

        options.push({value: 'new|#newRoute', title: 'Nieuwe route'})

        Object.values(usersObject).map((user) => {
            options.push({value: `new|${user._id}`, title: `Nieuwe route - ${user.name}`})
        })

        return (
            <Select
                allowEmptyValue
                style={style}
                label={label}
                value={value}
                options={options}
                onChange={onChange}
            />
        )
    }
}
