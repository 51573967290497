import React, {CSSProperties, ReactNode} from 'react'

interface RowProps extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
    style?: CSSProperties
    children: ReactNode
}

export default class Row extends React.Component<RowProps> {
    render() {
        const {style = {}, children} = this.props

        const defaultStyle = {
            display: 'flex',
            width: '100%',
            ...style
        }

        return (
            <div {...this.props} style={defaultStyle}>
                {children}
            </div>

        )
    }
}
