import React from 'react'

import {Button, Modal, P, H4} from './index.js'

export default class AlertPopup extends React.Component {
    constructor(props) {
        super(props)
        this.initialState = {
            isOpen: false,
            title: '',
            text: '',
            callback: null,
            actionTitle: '',
            actionCallback: null
        }

        this.state = JSON.parse(JSON.stringify(this.initialState))
    }

    open(title, text, callback) {
        this.setState({isOpen: true, title, text, callback})

        if (!callback) {
            return new Promise((resolve, reject) => {
                this.resolve = resolve
                this.reject = reject
            })
        }
    }

    setAction(actionTitle, actionCallback) {
        this.setState({actionTitle, actionCallback})
    }

    close() {
        this.setState({isOpen: false}, () => {
            this.setState(this.initialState)
        })
    }

    onClickClose() {
        if (this.state.callback) {
            this.state.callback()
        } else {
            this.resolve()
        }

        this.close()
    }

    onClickAction() {
        if (this.state.actionCallback) {
            this.state.actionCallback()
        }

        this.close()
    }

    render() {
        const {isOpen, title, text, actionTitle} = this.state

        return (
            <Modal show={isOpen} style={{width: 400, maxWidth: '90%', zIndex: 1031}} onClose={this.close.bind(this)}>
                <div style={{display: 'flex', marginBottom: 24}}>
                    <H4>{title}</H4>

                    <div style={{flex: 1}} />
                </div>

                <P style={{marginBottom: 30}}>{text}</P>

                <div style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-end', flexDirection: 'row'}}>

                    {actionTitle &&
                        <Button
                            variant='text'
                            onClick={this.onClickAction.bind(this)}
                        >
                            {actionTitle}
                        </Button>
                    }

                    <Button
                        variant='primary'
                        onClick={this.onClickClose.bind(this)}
                    >
                        Sluiten
                    </Button>

                </div>
            </Modal>
        )
    }
}
