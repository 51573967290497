import React from 'react'

import moment from 'moment'

import {Check} from '../../../../components/UI/index.js'
import {Badge, ListItem, P} from '../../../../components/UI/index.js'

class InvoiceListItem extends React.Component {
    render() {
        const {invoice, selected, onSelect} = this.props

        let totalPrice = 0

        invoice.invoiceLines.map((invoiceLine) => {
            totalPrice += invoiceLine.quantity * invoiceLine.price
        })

        return (
            <ListItem onClick={onSelect}>
                <Check checked={selected} />
                <P style={{flex: 2, marginLeft: 30}}>{invoice?.resellerDisplayName && invoice?.resellerDisplayName !== invoice.reseller ? `${invoice?.resellerDisplayName} (${invoice.reseller})` : invoice.reseller}</P>
                <P style={{width: 200, marginLeft: 30, textTransform: 'capitalize'}}>{moment(invoice.month).locale('NL').format('MMMM YYYY')}</P>
                <P style={{width: 130, marginLeft: 30}}>{`€ ${totalPrice.toFixed(2).replace('.', ',')}`}</P>

                <div style={{flex: 1, marginLeft: 30}}>
                    {invoice.status === 'Concept' &&
                        <Badge variant='info' style={{width: 80}}>Concept</Badge>
                    }
                </div>
            </ListItem>
        )
    }
}

export default (InvoiceListItem)
