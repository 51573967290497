import React from 'react'
import moment from 'moment'

import {Button, Link, Modal, P, H4} from '../UI/index.js'

class NotificationsPopup extends React.Component {
    constructor(props) {
        super(props)

        this.initialState = {
            modalIsOpen: false,
            newNotifications: [],
            currentFeatureIndex: 0
        }

        this.state = JSON.parse(JSON.stringify(this.initialState))
    }

    open() {
        this.setState({modalIsOpen: true})
    }

    close() {
        this.setState(this.initialState)
    }

    checkIfNewNotification(showAll) {
        const {notifications, user} = this.props

        if (!notifications || !user || showAll) return

        const newNotifications = notifications.filter((notification) => !notification.isRead)

        if (newNotifications.length) {
            newNotifications.sort((a, b) => {
                const Ascheduled = moment(`${a.scheduleDate} ${a.scheduleTime}`).valueOf()
                const Bscheduled = moment(`${b.scheduleDate} ${b.scheduleTime}`).valueOf()

                if (Ascheduled > Bscheduled) {
                    return -1
                }

                if (Ascheduled < Bscheduled) {
                    return 1
                }

                return 0
            })

            this.setState({modalIsOpen: true, newNotifications})
        }
    }

    onClickNextNotification() {
        const {currentFeatureIndex, newNotifications} = this.state
        const {notifications} = this.props
        const nextFeatureindex = currentFeatureIndex + 1

        const availableNotifications = newNotifications?.length > 0 ? newNotifications : notifications?.length > 0 ? notifications : []

        if (typeof this.props.onClickReadNotifications === 'function') {
            this.props.onClickReadNotifications(availableNotifications[currentFeatureIndex]._id)
        }

        if (availableNotifications[nextFeatureindex]) {
            this.setState({currentFeatureIndex: nextFeatureindex})
        } else {
            this.close()
        }
    }

    renderPreview() {
        const {currentFeatureIndex} = this.state
        let {newNotifications} = this.state
        const {previewNotification, showAll, notifications} = this.props

        if (previewNotification) {
            newNotifications = [previewNotification]
        }

        if (notifications?.length === 0) {
            return <P>Geen meldingen</P>
        }

        const availableNotifications = showAll ? (notifications?.length > 0 ? notifications : []) : (newNotifications?.length > 0 ? newNotifications : [])

        availableNotifications.sort((a, b) => {
            const Ascheduled = moment(`${a.scheduleDate} ${a.scheduleTime}`).valueOf()
            const Bscheduled = moment(`${b.scheduleDate} ${b.scheduleTime}`).valueOf()

            if (Ascheduled > Bscheduled) {
                return -1
            }

            if (Ascheduled < Bscheduled) {
                return 1
            }

            return 0
        })

        const activeFeature = previewNotification || availableNotifications?.[currentFeatureIndex]

        return (
            <>
                {activeFeature && (
                    <div>
                        {(previewNotification || showAll) && (
                            <H4 style={{marginBottom: 12}}>{activeFeature.title}</H4>
                        )}

                        {activeFeature.imageSrc &&
                            <div style={{width: '100%', marginBottom: 24}}>
                                <img style={{display: 'block', margin: 'auto', maxWidth: '100%'}} src={activeFeature.imageSrc} />
                            </div>
                        }

                        <P style={{fontSize: 16}}>
                            <div dangerouslySetInnerHTML={{__html: activeFeature.message}} />
                        </P>

                        <br />
                        {activeFeature.moreInfoUrl && (
                            <Link>
                                <a style={{textDecoration: 'none', color: 'inherit'}} target='_blank' href={activeFeature.moreInfoUrl} rel='noreferrer'>
                                    Meer informatie
                                </a>
                            </Link>
                        )}
                    </div>
                )}

                <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                    {currentFeatureIndex !== 0 && (
                        <Button
                            label={`Vorige (${currentFeatureIndex}/${availableNotifications.length})`}
                            onClick={() => this.setState({currentFeatureIndex: currentFeatureIndex - 1})}
                        />
                    )}

                    {currentFeatureIndex === (availableNotifications.length - 1) ?
                            (!showAll && (
                                <Button
                                    label='OK'
                                    onClick={this.onClickNextNotification.bind(this)}
                                />
                            )) :
                            <Button
                                label={`Volgende (${currentFeatureIndex + 2}/${availableNotifications.length})`}
                                onClick={this.onClickNextNotification.bind(this)}
                            />
                    }

                </div>
            </>
        )
    }

    render() {
        const {modalIsOpen, newNotifications, currentFeatureIndex} = this.state
        const {previewNotification, showAll} = this.props

        if (previewNotification || showAll) {
            return this.renderPreview()
        }

        const activeFeature = newNotifications?.[currentFeatureIndex]

        return (
            <Modal
                style={{paddingLeft: 32, paddingRight: 32}}
                show={modalIsOpen}
                title={activeFeature?.title}
                onClose={this.close.bind(this)}
            >
                {this.renderPreview()}
            </Modal>
        )
    }
}

export default NotificationsPopup
