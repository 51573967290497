import React from 'react'
import Reflux from 'reflux-react-16'

import LoginStore from '../../stores/LoginStore.js'

import {Alert, Button, Card, Colors, H3, P} from '../../components/UI/index.js'
import {Input} from '../../components/UI/index.js'
import moment from 'moment'
import LoadingPage from '../../components/UI/LoadingPage.js'

export default class Login extends Reflux.Component {
    constructor(props) {
        super(props)
        this.state = {trackTrace: '', error: ''}

        this.store = LoginStore
    }

    componentDidMount() {
        const {history} = this.props

        document.title = 'Track & Trace'

        if (history && history.action === 'PUSH' && history.location && history.location.state && history.location.state.notFound) {
            this.setState({error: `Geen zending gevonden met Track & Trace code: ${history.location.state.trackTrace}`})
        } else {
            this.setState({error: ''})
        }
    }

    onSubmit(event) {
        event.preventDefault()
        const {trackTrace} = this.state
        const {history} = this.props

        history.push(`/track&trace/${trackTrace.trim()}`)
    }

    render() {
        const {trackTrace, error, brandingLoading, branding} = this.state

        if (brandingLoading) {
            return <LoadingPage />
        }

        return (
            <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', height: '100vh', overflowY: 'auto', background: Colors.backgroundNeutral}}>
                <div style={{flex: 1, display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%'}}>
                    <form onSubmit={this.onSubmit.bind(this)}>
                        <Card shadow='true' style={{display: 'flex', flexDirection: 'column', alignItems: 'center', width: 450, padding: 24, marginTop: 120, marginLeft: 'auto', marginRight: 'auto'}}>
                            <img style={{maxHeight: 128, maxWidth: 256, marginBottom: 48, objectFit: 'contain'}} src={branding?.mainLogo || '/images/VELOYDLOGO.png'} />

                            <H3>Track & Trace</H3>
                            <P style={{maxWidth: 400, marginBottom: 24}}>Vul hieronder de track & trace code van je pakket in.</P>

                            <Input
                                style={{width: '100%'}}
                                label='Track & Trace code'
                                value={trackTrace}
                                onChange={(event) => {
                                    this.setState({trackTrace: event.target.value})
                                }}
                            />

                            <Button
                                type='submit'
                                style={{width: '100%', marginTop: 12}}
                                color={branding?.color || Colors.brandColor}
                            >
                                Zoeken
                            </Button>

                            <div style={{minHeight: 36, marginTop: 12, marginBottom: 24, width: '100%'}}>
                                {error &&
                                    <Alert variant='danger'>{error}</Alert>
                                }
                            </div>
                        </Card>
                    </form>
                </div>

                <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%', padding: 24}}>
                    <img style={{objectFit: 'contain', width: 100}} src='/images/VELOYDLOGO.png' alt='Logo' />

                    <div style={{display: 'flex', marginTop: 12}}>
                        <P>&copy; {moment().year()} Veloyd</P>
                        <P style={{marginLeft: 6, marginRight: 6}}>|</P>
                        <P><a style={{color: Colors.textDark}} target='_blank' href='https://veloyd.nl/privacy-policy/' rel='noreferrer'>Privacy policy</a></P>
                    </div>
                </div>
            </div>
        )
    }
}
