import React, {Component, Fragment} from 'react'

import {Input} from './index.js'

class VerificationCodeInput extends Component {
    constructor(props) {
        super(props)
        this.state = {
            code: ['', '', '', '', '', '']
        }
    }

    componentDidMount() {
        this['input0'].focus()
    }

    handleChange(event, index) {
        const {onChange} = this.props
        let {value} = event.target

        if (/^[0-9]*$/.test(value)) {
            const newCode = [...this.state.code]
            if (value.length > 1) {
                value = value.substring(0, (6 - index))
                value.split('').forEach((number, nIndex) => {
                    newCode[nIndex + index] = number
                })
            } else {
                newCode[index] = value
            }

            this.setState({code: newCode}, () => {
                if (value !== '' && index < 5) {
                    if (value.length > 1) {
                        this[`input${index + (value.length - 1)}`]?.focus()
                    } else {
                        this[`input${index + 1}`]?.focus()
                    }
                }
            })
            typeof onChange === 'function' && onChange(newCode)
        }
    }

    handleKeyUp(e, index) {
        const {code} = this.state
        if (e.key === 'Backspace' && index > 0 && code[index] === '') {
            this[`input${index - 1}`].focus()
            this[`input${index - 1}`].select()
        }
    }

    render() {
        const {code} = this.state

        return (
            <div style={{display: 'flex', alignItems: 'center'}}>
                {code.map((character, index) => (
                    <Fragment key={index}>
                        <Input
                            inputStyle={{
                                width: 45,
                                fontSize: 32,
                                paddingTop: 0,
                                paddingLeft: 0,
                                paddingRight: 0,
                                paddingBottom: 0,
                                textAlign: 'center'
                            }}
                            value={character}
                            inputMode='numeric'
                            onChange={(event) => this.handleChange(event, index)}
                            onKeyUp={(event) => this.handleKeyUp(event, index)}
                            ref={(input) => {
                                this[`input${index}`] = input
                            }}
                            onClick={() => {
                                this[`input${index}`]?.select()
                            }}
                        />
                        {index === 2 && (
                            <span style={{fontSize: 32, height: '100%', margin: '0 5px'}}>-</span>
                        )}
                    </Fragment>
                ))}
            </div>
        )
    }
}

export default VerificationCodeInput
