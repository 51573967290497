import React from 'react'

import OrderActions from '../../actions/OrderActions.js'

import {Alert, Button, H4, Input, Toggle, Modal, S1, S2, Row, DropdownActionButton, Spinner} from '../UI/index.js'
import dbCodes from '../../../server/dbCodes.js'
import _ from 'lodash'

export default class CompleteOrderPopup extends React.Component {
    constructor(props) {
        super(props)

        this.initialState = {
            isOpen: false,
            notPossibleReasonsLoading: false,
            ids: [],
            pickupNotPossibleReasons: [],
            pickupNotPossibleReasonsSpecifications: [],
            deliveryNotPossibleReasons: [],
            deliveryNotPossibleReasonsSpecifications: [],
            pickupPossible: '',
            pickupReason: '',
            pickupReasonSpecification: '',
            pickupComment: '',
            overwritePickupAddresses: false,
            deliveryPossible: '',
            deliveryStatus: '',
            deliveryReason: '',
            deliveryReasonSpecification: '',
            deliveryComment: '',
            overwriteDeliveryAddresses: false,
            callback: null,
            loading: false,
            errors: []
        }

        this.state = structuredClone(this.initialState)
    }

    async open(ids, callback) {
        const {orders} = this.props
        const customers = {}

        this.setState({isOpen: true, notPossibleReasonsLoading: true})

        for (const id of ids) {
            const order = orders[id]

            if (order) {
                customers[order.customer] = true
            }
        }

        let pickupNotPossibleReasons = []
        const pickupNotPossibleReasonsSpecifications = []
        let deliveryNotPossibleReasons = []
        const deliveryNotPossibleReasonsSpecifications = []

        for (const customerId of Object.keys(customers)) {
            await new Promise((resolve) => {
                OrderActions.getNotPossibleReasons(customerId, '', (err, response) => {
                    if (err) {
                        console.error(err)
                    } else {
                        pickupNotPossibleReasons = _.uniq([...pickupNotPossibleReasons, ...response.pickupNotPossibleReasons])

                        response.pickupNotPossibleReasonsSpecifications.map((specification) => {
                            const index = pickupNotPossibleReasonsSpecifications.findIndex((spec) => spec.reason === specification.reason && spec.specification === specification.specification)

                            if (index === -1) {
                                pickupNotPossibleReasonsSpecifications.push(specification)
                            }
                        })

                        deliveryNotPossibleReasons = _.uniq([...deliveryNotPossibleReasons, ...response.deliveryNotPossibleReasons])

                        response.deliveryNotPossibleReasonsSpecifications.map((specification) => {
                            const index = deliveryNotPossibleReasonsSpecifications.findIndex((spec) => spec.reason === specification.reason && spec.specification === specification.specification)

                            if (index === -1) {
                                deliveryNotPossibleReasonsSpecifications.push(specification)
                            }
                        })
                    }

                    resolve()
                })
            })
        }

        this.setState({notPossibleReasonsLoading: false, ids: [...ids], pickupNotPossibleReasons, pickupNotPossibleReasonsSpecifications, deliveryNotPossibleReasons, deliveryNotPossibleReasonsSpecifications, callback})
    }

    close() {
        this.setState(this.initialState)
    }

    confirm() {
        const {ids, pickupPossible, pickupReason, pickupReasonSpecification, pickupComment, overwritePickupAddresses, deliveryPossible, deliveryStatus, deliveryReason, deliveryReasonSpecification, deliveryComment, overwriteDeliveryAddresses, callback} = this.state

        const errors = []

        this.setState({loading: true, errors})

        OrderActions.complete(ids, pickupPossible, pickupReason, pickupReasonSpecification, pickupComment, overwritePickupAddresses, deliveryPossible, deliveryStatus, deliveryReason, deliveryReasonSpecification, deliveryComment, overwriteDeliveryAddresses, (err, errors) => {
            if (err) {
                errors.push(err)
            } else if (errors.length) {
                this.setState({errors})
            } else {
                OrderActions.setSelected([])
                typeof callback === 'function' && callback()
                this.close()
            }
        })
    }

    render() {
        const {
            isOpen,
            notPossibleReasonsLoading,
            ids,
            pickupNotPossibleReasons,
            pickupNotPossibleReasonsSpecifications,
            deliveryNotPossibleReasons,
            deliveryNotPossibleReasonsSpecifications,
            pickupPossible,
            pickupReason,
            pickupReasonSpecification,
            pickupComment,
            overwritePickupAddresses,
            deliveryPossible,
            deliveryStatus,
            deliveryReason,
            deliveryReasonSpecification,
            deliveryComment,
            overwriteDeliveryAddresses,
            loading,
            errors
        } = this.state

        const pickupNotPossibleOptions = []
        const deliveryNotPossibleOptions = []

        for (const reason of pickupNotPossibleReasons) {
            const specifications = pickupNotPossibleReasonsSpecifications.filter((specification) => specification.reason === reason)

            if (specifications.length > 0) {
                for (const specification of specifications) {
                    pickupNotPossibleOptions.push({label: `${dbCodes.colloReasons[reason]} - ${specification.specification}`, onClick: () => this.setState({pickupPossible: false, pickupReason: reason, pickupReasonSpecification: specification.specification})})
                }
            } else {
                pickupNotPossibleOptions.push({label: dbCodes.colloReasons[reason], onClick: () => this.setState({pickupPossible: false, pickupReason: reason, pickupReasonSpecification: ''})})
            }
        }

        for (const reason of deliveryNotPossibleReasons) {
            const value = reason.split(';')[0]
            const subValue = reason.split(';')[1] || ''

            const specifications = deliveryNotPossibleReasonsSpecifications.filter((specification) => specification.reason === reason)

            if (specifications.length > 0) {
                for (const specification of specifications) {
                    const specStatus = specification.reason.split(';')[0]
                    const specReason = specification.reason.split(';')[1] || ''

                    let label
                    if (specReason && specReason === subValue) {
                        label = `${dbCodes.colloReasons[specReason]} - ${specification.specification}`
                    } else if (specStatus === value) {
                        label = `${dbCodes.colloReasons[specStatus] || dbCodes.colloStatus[specStatus]} - ${specification.specification}`
                    }

                    deliveryNotPossibleOptions.push({label, onClick: () => this.setState({deliveryPossible: false, deliveryStatus: specStatus, deliveryReason: specReason, deliveryReasonSpecification: specification.specification})})
                }
            } else {
                const label = dbCodes.colloReasons[subValue || value] || dbCodes.colloStatus[value]

                deliveryNotPossibleOptions.push({label, onClick: () => this.setState({deliveryPossible: false, deliveryStatus: value, deliveryReason: subValue, deliveryReasonSpecification: ''})})
            }
        }

        return (
            <Modal show={isOpen} style={{width: 600}} onClose={this.close.bind(this)}>
                <div style={{display: 'flex', marginBottom: 24}}>
                    <H4>Rit ophalen / bezorgen</H4>

                    <div style={{flex: 1}} />
                </div>

                {notPossibleReasonsLoading &&
                    <Row style={{justifyContent: 'center', marginTop: 12, marginBottom: 12}}>
                        <Spinner />
                    </Row>
                }

                {!notPossibleReasonsLoading &&
                    <>
                        <S1 style={{marginBottom: 12}}>Ophaaladressen</S1>

                        <Row style={{marginBottom: 12}}>
                            <Button
                                style={{flex: 1}}
                                variant='outline-white'
                                selected={pickupPossible}
                                label='Ophalen'
                                icon='mdi mdi-check'
                                onClick={() => this.setState({pickupPossible: true, pickupReason: '', pickupReasonSpecification: ''})}
                            />

                            <DropdownActionButton
                                style={{flex: 1}}
                                variant='outline-white'
                                label={pickupPossible === false ? (pickupReasonSpecification || dbCodes.colloReasons[pickupReason]) : 'Ophalen niet mogelijk'}
                                icon='mdi mdi-close'
                                selected={pickupPossible === false}
                                options={pickupNotPossibleOptions}
                            />
                        </Row>

                        <Toggle
                            label='Overschrijven van al uitgevoerde adressen'
                            checked={overwritePickupAddresses}
                            onChange={(event) => this.setState({overwritePickupAddresses: event.target.checked})}
                        />

                        <Input
                            style={{marginBottom: 24}}
                            label='Opmerking'
                            value={pickupComment}
                            onChange={(event) => this.setState({pickupComment: event.target.value})}
                            ref={(ref) => this.pickupCommentInput = ref}
                        />

                        <S1 style={{marginBottom: 12}}>Bezorgadressen</S1>

                        <Row style={{marginBottom: 12}}>
                            <Button
                                style={{flex: 1}}
                                variant='outline-white'
                                label='Bezorgen'
                                icon='mdi mdi-check'
                                selected={deliveryPossible}
                                onClick={() => this.setState({deliveryPossible: true, deliveryStatus: '', deliveryReason: '', deliveryReasonSpecification: ''})}
                            />

                            <DropdownActionButton
                                style={{flex: 1}}
                                variant='outline-white'
                                label={deliveryPossible === false ? (deliveryReasonSpecification || dbCodes.colloReasons[deliveryReason] || dbCodes.colloStatus[deliveryStatus]) : 'Bezorgen niet mogelijk'}
                                icon='mdi mdi-close'
                                selected={deliveryPossible === false}
                                options={deliveryNotPossibleOptions}
                            />
                        </Row>

                        <Toggle
                            label='Overschrijven van al uitgevoerde adressen'
                            checked={overwriteDeliveryAddresses}
                            onChange={(event) => this.setState({overwriteDeliveryAddresses: event.target.checked})}
                        />

                        <Input
                            label='Opmerking'
                            value={deliveryComment}
                            onChange={(event) => this.setState({deliveryComment: event.target.value})}
                            ref={(ref) => this.deliveryCommentInput = ref}
                        />

                        {errors.length > 0 && errors.map((error, index) => {
                            return <Alert key={`error${index}`} variant='danger'>{error}</Alert>
                        })}

                        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-end'}}>
                            {ids.length > 1 &&
                                <>
                                    <i style={{fontSize: 12, marginLeft: 6, marginRight: 3}} className='mdi mdi-check' />
                                    <S2 style={{flex: 1}}>{`${ids.length} ritten geselecteerd`}</S2>

                                </>
                            }

                            {!errors.length && !loading &&
                                <Button
                                    variant='text'
                                    onClick={this.close.bind(this)}
                                >
                                    Annuleren
                                </Button>
                            }

                            {!errors.length &&
                                <Button
                                    variant='text'
                                    loading={loading}
                                    onClick={this.confirm.bind(this)}
                                >
                                    Bevestigen
                                </Button>
                            }

                            {!!errors.length &&
                                <Button
                                    style={{marginBottom: 0}}
                                    variant='text'
                                    onClick={this.close.bind(this)}
                                >
                                    Sluiten
                                </Button>
                            }
                        </div>
                    </>
                }
            </Modal>
        )
    }
}
