import {create} from 'zustand'
import socket from '../socketio/socketio.js'

const usePackageStore = create((set) => ({
    packages: [],
    selectedPackages: [],
    packagesQueries: [],
    error: '',

    get: (previousId) => {
        socket.emit('packages.get', sessionStorage.token, previousId, (err, packages) => {
            if (err) {
                set({error: err})
                return
            }

            set({packages})
        })
    },
    getOne: (...args) => {
        socket.emit('packages.getOne', sessionStorage.token, ...args)
    },
    setSelected: (selected) => {
        set({selectedPackages: selected})
    },
    setQueries(packagesQueries) {
        set({packagesQueries, selectedPackages: []})
    },
    findByBarcode: (barcode) => {
        return new Promise((resolve, reject) => {
            socket.emit('packages.findByBarcode', sessionStorage.token, barcode, (err, packages) => {
                if (err) {
                    reject(err)
                } else {
                    resolve(packages)
                }
            })
        })
    },
    updateStatus: (...args) => {
        return new Promise((resolve, reject) => {
            socket.emit('packages.updateStatus', sessionStorage.token, ...args, (err, packaging) => {
                if (err) {
                    reject(err)
                } else {
                    set((state) => ({
                        packages: state.packages.map((pkg) => {
                            if (pkg._id === packaging._id) {
                                return packaging
                            }
                            return pkg
                        })
                    }))
                    resolve(packaging)
                }
            })
        })
    }
}))

export default usePackageStore
