import React from 'react'

import {Input} from '../UI/index.js'

export default class TimePicker extends React.Component {
    constructor(props) {
        super(props)
        const {time = '', value = ''} = props

        this.state = {
            time: value || time
        }
    }

    componentDidUpdate(prevProps) {
        const {time} = this.props

        if (prevProps.time !== time) {
            this.setState({time})
        }
    }

    handleChangeInput(event) {
        const {allowEmpty, onChange} = this.props

        let time = event.target.value.replace('.', ':').replace(',', ':')

        if (/^$|^[0-9]{0,2}[:]?[0-9]{0,2}$/.test(time)) {
            if (this.state.time.length === 1 && time.length === 2) {
                time = `${time}:`
            }

            this.setState({time})

            if (/^([0-1][0-9]|2[0-3]):[0-5][0-9]$/.test(time)) {
                onChange(event)
            }
        }

        if (allowEmpty && time === '') {
            onChange(event)
        }
    }

    onBlur() {
        const {time = '', value = ''} = this.props

        this.setState({time: value || time})
    }

    render() {
        const {time} = this.state
        const {label, info, readOnly, disabled, style = {}, inputMode} = this.props
        style.caretColor = inputMode === 'none' ? 'transparent' : 'default'

        return (
            <Input
                style={style}
                label={label}
                info={info}
                value={time}
                disabled={disabled}
                readOnly={readOnly}
                onChange={this.handleChangeInput.bind(this)}
                onBlur={this.onBlur.bind(this)}
                ref={(ref) => this.formControl = ref}
                append='mdi mdi-clock-outline'
                inputMode={inputMode}
            />
        )
    }
}
