import React from 'react'

import {Colors, Info, P} from '../UI/index.js'

class Check extends React.PureComponent {
    constructor(props) {
        super(props)
        this.state = {focused: false}
        this.onPressSpace = this.onPressSpace.bind(this)
    }

    componentDidMount() {
        document.addEventListener('keydown', this.onPressSpace)
    }

    componentWillUnmount() {
        document.removeEventListener('keydown', this.onPressSpace)
    }

    onPressSpace(event) {
        const {focused} = this.state
        const {disabled, checked, onChange} = this.props

        if (focused && event.keyCode === 32) {
            if (!disabled && typeof onChange === 'function') {
                onChange({target: {checked: !checked}})
            }
        }
    }

    onClick(event) {
        const {disabled, checked, onChange} = this.props

        if (!disabled && typeof onChange === 'function') {
            onChange({target: {checked: !checked}, shiftKey: event.shiftKey})
            this.setState({focused: false})
        }
    }

    render() {
        const {disabled, checked, label, info, infoIcon, style = {}, indeterminate = false} = this.props

        const overLayStyle = {
            display: 'flex',
            justifyContent: 'center',
            flexShrink: 0,
            alignItems: 'center',
            background: (this.state.hover || this.state.focused) && !disabled ? 'rgba(0, 0, 0, 0.1)' : 'transparent',
            width: 36,
            height: 36,
            borderRadius: '50%',
            cursor: disabled ? 'default' : 'pointer'
        }

        const checkboxStyle = {
            display: 'flex',
            flexShrink: 0,
            justifyContent: 'center',
            alignItems: 'center',
            paddingBottom: 1,
            background: checked || indeterminate ? (disabled ? Colors.grey60 : Colors.buttonSolid) : (disabled ? Colors.grey20 : Colors.backgroundWhite),
            width: 18,
            height: 18,
            borderRadius: 2,
            border: `1px solid ${checked || indeterminate ? (disabled ? Colors.grey60 : Colors.buttonSolid) : Colors.border}`,
            fontSize: checked || indeterminate ? 16 : 0,
            color: disabled ? Colors.grey20 : Colors.white,
            transition: 'font-size .1s ease',
            outline: 'none'
        }

        const labelStyle = {
            display: 'flex',
            alignItems: 'center',
            marginRight: 12,
            ...(this.props.labelStyle || {})
        }

        const defaultStyle = {display: 'flex', alignItems: 'center', flex: label && !style.width ? 1 : '', height: 36, ...style}

        return (
            <div style={defaultStyle}>
                <div
                    style={overLayStyle}
                    onClick={this.onClick.bind(this)}
                    onMouseEnter={() => this.setState({hover: true})}
                    onMouseLeave={() => this.setState({hover: false})}
                >
                    <div
                        tabIndex={disabled ? -1 : 0}
                        style={checkboxStyle}
                        onFocus={() => this.setState({focused: true})}
                        onBlur={() => this.setState({focused: false})}
                    >
                        {checked ? <i className='mdi mdi-check' /> : indeterminate ? <i className='mdi mdi-minus' /> : ''}
                    </div>
                </div>

                {label &&
                    <P style={labelStyle}>
                        {label}
                        {info && <Info icon={infoIcon} text={info} />}
                    </P>
                }
            </div>
        )
    }
}

export default Check
