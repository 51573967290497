import React from 'react'

import _ from 'lodash'

import Guid from '../../../../../utils/guid.js'

import {Alert, Button, IconButton, Colors, H3, P, ListItem, Panel, Row} from '../../../../components/UI/index.js'
import {Toggle} from '../../../../components/UI/index.js'
import csvExport from '../../../../utils/csvExport.js'
import OrderPriceTableModal from './OrderPriceTableModal.js'
import PriceTableModal from '../../../../components/resellers/PriceTableModal.js'

class OrderPrices extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            name: '',
            nameValidation: null,
            useNewPrices: !props.reseller.settings.priceTables || props.reseller.settings.priceTables.length === 0 || props.reseller.settings.orders.useNewPrices,
            error: ''
        }
    }

    onChangeChecked(path, event) {
        const reseller = {...this.props.reseller}

        _.set(reseller.settings, path, event.target.checked)

        this.props.onChange(reseller, path)
    }

    onClickNewPriceTable() {
        this.orderPriceTableModal.open()
    }

    onClickRemovePriceTable(name) {
        const {reseller, onChange} = this.props
        delete reseller.settings.priceTables[name]
        onChange(reseller, 'priceTables')
    }

    onClickDownloadPriceTable(name) {
        const {reseller} = this.props
        const priceTable = reseller.settings.priceTables[name]

        csvExport.priceTable(name, priceTable)
    }

    onChangePriceTableModal(name, table) {
        const {reseller, onChange} = this.props

        if (!reseller.settings.priceTables) {
            reseller.settings.priceTables = {}
        }

        reseller.settings.priceTables[name] = table

        onChange(reseller, 'priceTables')
    }

    onUpdatePriceTable(priceTable, index) {
        const reseller = {...this.props.reseller}

        if (typeof index === 'number') {
            reseller.settings.orders.prices[index] = priceTable
        } else {
            reseller.settings.orders.prices = reseller.settings.orders.prices || []
            reseller.settings.orders.prices.push(priceTable)
        }

        this.props.onChange(reseller, 'orders.prices')
    }

    onRemovePriceTable(index) {
        const reseller = {...this.props.reseller}

        reseller.settings.orders.prices.splice(index, 1)

        this.props.onChange(reseller, 'orders.prices')
    }

    onClickExportTables() {
        const {reseller} = this.props

        const prices = structuredClone(reseller.settings.orders.prices)
        prices.forEach((price) => {
            price.id = Guid.generate()
            price.deliveryZones = []
            price.zoneFees = {}
        })

        const blob = new Blob([JSON.stringify(prices)], {type: 'application/json'})

        const linkEl = document.createElement('a')
        linkEl.href = URL.createObjectURL(blob)
        linkEl.setAttribute('download', `${reseller.settings?.accountName || reseller.name} Tarieven`)
        linkEl.click()
    }

    onClickImportTables() {
        document.getElementById('table-import').value = null
        document.getElementById('table-import').click()
    }

    onLoadFile() {
        const {reseller, onChange} = this.props
        this.setState({fromReseller: '', error: ''})
        const file = document.getElementById('table-import').files[0]

        if (file) {
            this.setState({fileName: file.name})
            const reader = new FileReader()
            reader.readAsText(file)
            reader.onerror = (event) => {
                if (event.target.error.name == 'NotReadableError') {
                    alert('Can\'t read file !')
                }
            }
            reader.onload = (event) => {
                try {
                    const prices = JSON.parse(event.target.result)
                    if (prices.length === 0) {
                        throw new Error('Dit bestand heeft geen geldige opmaak. Er kan alleen een JSON bestand geïmporteerd worden dat ook vanuit Veloyd geëxporteerd is en dat niet is bewerkt.')
                    } else {
                        reseller.settings.orders.prices.push(...prices)
                        onChange(reseller, 'orders.prices')
                    }
                } catch {
                    this.setState({error: 'Dit bestand heeft geen geldige opmaak. Er kan alleen een JSON bestand geïmporteerd worden dat ook vanuit Veloyd geëxporteerd is en dat niet is bewerkt.'})
                }
            }
        }
    }

    render() {
        const {error} = this.state
        const {reseller} = this.props

        return (
            <Panel style={{width: 600, padding: 20}}>
                <H3>Tarieven</H3>

                <Toggle
                    label='Nieuwe tarieftabellen'
                    checked={reseller.settings.orders.useNewPrices}
                    onChange={this.onChangeChecked.bind(this, 'orders.useNewPrices')}
                />

                {!reseller.settings.orders.useNewPrices &&
                    <Alert variant='info' style={{marginTop: -10, marginBottom: 30}}>Wij adviseren om gebruik te maken van de nieuwe tarieftabellen. Neem voor meer informatie contact op via info@veloyd.nl</Alert>
                }

                {!reseller.settings.orders.useNewPrices &&
                    <>
                        {_.keys(reseller.settings.priceTables).map((name) => {
                            return (
                                <div
                                    key={name}
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        borderBottom: 'solid 1px',
                                        borderColor: Colors.grey40,
                                        marginBottom: 12
                                    }}
                                >
                                    <P style={{flex: 1}}>{name}</P>
                                    <IconButton onClick={this.onClickDownloadPriceTable.bind(this, name)}>
                                        <i className='mdi mdi-download' />
                                    </IconButton>
                                    <IconButton onClick={this.onClickRemovePriceTable.bind(this, name)}>
                                        <i className='mdi mdi-close' />
                                    </IconButton>
                                </div>
                            )
                        })}

                        <Button
                            variant='outline'
                            icon='mdi mdi-plus'
                            label='Nieuwe tarieftabel'
                            onClick={this.onClickNewPriceTable.bind(this)}
                        />

                        <OrderPriceTableModal
                            onChange={this.onChangePriceTableModal.bind(this)}
                            ref={(ref) => this.orderPriceTableModal = ref}
                        />
                    </>
                }

                {error && <Alert variant='error'>{error}</Alert>}

                {reseller.settings.orders.useNewPrices &&
                    <>
                        <Row style={{marginBottom: 24}}>
                            <Button
                                variant='outline'
                                icon='mdi mdi-file-export'
                                label='Tarieftabellen exporteren'
                                onClick={this.onClickExportTables.bind(this)}
                            />

                            <Button
                                variant='outline'
                                icon='mdi mdi-file-import'
                                label='Tarieftabellen importeren'
                                onClick={this.onClickImportTables.bind(this)}
                            />
                        </Row>
                        {!!reseller.settings.orders.prices.length &&
                            <Panel style={{maxHeight: 300, overflowY: 'auto', marginBottom: 12}}>
                                {(reseller.settings.orders.prices || []).map((priceTable, index) => {
                                    return (
                                        <ListItem key={`priceTable${index}`} onClick={() => this.priceTableModal.open(priceTable, index)}>
                                            {priceTable.name}
                                        </ListItem>
                                    )
                                })}
                            </Panel>
                        }

                        <Button
                            variant='outline'
                            icon='mdi mdi-plus'
                            label='Nieuwe tarieftabel'
                            onClick={() => this.priceTableModal.open()}
                        />
                    </>
                }

                <OrderPriceTableModal
                    onChange={this.onChangePriceTableModal.bind(this)}
                    ref={(ref) => this.orderPriceTableModal = ref}
                />

                <PriceTableModal
                    onSubmit={this.onUpdatePriceTable.bind(this)}
                    onRemove={this.onRemovePriceTable.bind(this)}
                    ref={(ref) => this.priceTableModal = ref}
                    reseller={reseller}
                />

                <input
                    type='file'
                    id='table-import'
                    accept='.json'
                    style={{visibility: 'hidden', height: 0}}
                    onChange={this.onLoadFile.bind(this)}
                />
            </Panel>
        )
    }
}

export default (OrderPrices)
