import React from 'react'
import Reflux from 'reflux-react-16'

import UtilStore from '../../stores/UtilStore.js'
import UtilActions from '../../actions/UtilActions.js'

import {Toggle, Input, Select} from '../UI/index.js'
import {Alert, Button, S1, P} from '../UI/index.js'

class SMTPSettings extends Reflux.Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            message: '',
            error: ''
        }

        this.store = UtilStore
    }

    onChange(...args) {
        this.setState({message: '', error: ''})
        this.props.onChange(...args)
    }

    onChangeChecked(...args) {
        this.setState({message: '', error: ''})
        this.props.onChangeChecked(...args)
    }

    onClickVerifySMTP() {
        const {settings} = this.props
        this.setState({loading: true, message: '', error: ''})

        UtilActions.verifySMTP(settings, (err) => {
            let error = ''

            if (err) {
                error = err.response || err.error || err.errno || err.reason || JSON.stringify(err)
                this.setState({loading: false, message: '', error})
            } else {
                this.setState({loading: false, message: 'SMTP instellingen zijn correct.', error: ''})
            }
        })
    }

    render() {
        const {loading, message, error} = this.state
        const {settings} = this.props

        return (
            <div>
                <br />
                <Toggle
                    label='Gebruik eigen SMTP server'
                    info='Emails worden verstuurd via de opgegeven server in plaats van de Veloyd server'
                    checked={settings.useOwnSMTP}
                    onChange={this.onChangeChecked.bind(this, 'useOwnSMTP')}
                />

                {settings.useOwnSMTP &&
                    <>
                        <Input
                            label='Emailadres'
                            info='Vul hier het emailadres van de SMTP server in.'
                            value={settings.smtpUser}
                            onChange={this.onChange.bind(this, 'smtpUser')}
                            onBlur={typeof this.props.onBlur === 'function' && this.props.onBlur.bind(this, 'smtpUser')}
                        />

                        <Input
                            label='Wachtwoord'
                            info='Vul het wachtwoord in dat bij bovenstaand mailadres hoort om online op de mailserver in te loggen. Wordt daar gebruik gemaakt van een 2 staps authenticatie om in te loggen dan werkt dit wachtwoord niet. In de online omgeving van de mailserver dient dan een zogenaamd app-wachtwoord aangemaakt te worden. Dit wachtwoord kan dan hier gebruikt worden.'
                            type='password'
                            autocomplete='new-password'
                            value={settings.smtpPassword}
                            onChange={this.onChange.bind(this, 'smtpPassword')}
                            onBlur={typeof this.props.onBlur === 'function' && this.props.onBlur.bind(this, 'smtpPassword')}
                        />

                        <div style={{display: 'flex'}}>
                            <Input
                                style={{flex: 2, marginRight: 6}}
                                label='SMTP Server'
                                info={
                                    <div>
                                        <S1>Voorbeeld Gmail</S1>
                                        <P>smtp.gmail.com 465</P>
                                        <br />
                                        <S1>Voorbeeld Office 365</S1>
                                        <P>smtp.office365.com 587</P>
                                        <br />
                                        <S1>Voorbeeld Hotmail/Live</S1>
                                        <P>smtp.live.com 587</P>
                                    </div>
                                }
                                value={settings.smtpHost}
                                onChange={this.onChange.bind(this, 'smtpHost')}
                                onBlur={typeof this.props.onBlur === 'function' && this.props.onBlur.bind(this, 'smtpHost')}
                            />

                            <Select
                                style={{marginLeft: 6}}
                                label='Poort'
                                allowEmptyValue
                                value={settings.smtpPort}
                                onChange={this.onChange.bind(this, 'smtpPort')}
                                onBlur={typeof this.props.onBlur === 'function' && this.props.onBlur.bind(this, 'smtpPort')}
                            >
                                <option value='25'>25</option>
                                <option value='465'>465</option>
                                <option value='587'>587</option>
                            </Select>
                        </div>

                        <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                            <Button variant='text' loading={loading} onClick={this.onClickVerifySMTP.bind(this)}>
                                Verifieer
                            </Button>
                        </div>
                    </>
                }

                {message &&
                    <Alert variant='success'>{message}</Alert>
                }

                {error &&
                    <Alert variant='danger'>{error}</Alert>
                }
            </div>
        )
    }
}

export default (SMTPSettings)
