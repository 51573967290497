import React from 'react'

import OrderActions from '../../actions/OrderActions.js'

import {Alert, Button, Input, Modal, P, S1, Check, Card, Colors, Row, S2, Toggle} from '../UI/index.js'
import dbCodes from '../../../server/dbCodes.js'
import _ from 'lodash'

export default class AtHubOrderPopup extends React.Component {
    constructor(props) {
        super(props)

        this.initialState = {
            isOpen: false,
            order: null,
            colli: [],
            ids: [],
            status: 'athub',
            comment: '',
            callback: null,
            loading: false,
            progressNow: 0,
            progressMax: 0,
            errors: []
        }

        this.state = structuredClone(this.initialState)
    }

    open(orderIds, callback) {
        let order = null
        let colli = []
        let ids = []

        if (Array.isArray(orderIds)) {
            ids = [...orderIds]
        } else {
            order = structuredClone(orderIds)

            colli = order.handledBy === 'reseller' ?
                order.colli.filter((collo) => typeof collo.deliveryAddressIndex === 'number' && ['expected', 'notsupplied', 'insorting', 'athub'].includes(collo.status)) :
                order.colli.filter((collo) => typeof collo.deliveryAddressIndex === 'number' && ['expected', 'notsupplied', 'insorting'].includes(collo.status))

            colli.map((collo) => {
                if (collo.status !== 'athub') {
                    collo.originalStatus = collo.status
                    collo.originalReason = collo.reason
                } else {
                    collo.originalStatus = typeof collo.pickupAddressIndex === 'number' || !collo.barcode ? 'athub' : 'expected'
                    collo.originalReason = ''
                }
            })

            if (colli.length === 1) {
                order.colli[0].status = 'athub'
                order.colli[0].reason = ''
            }
        }

        this.setState({isOpen: true, order, colli, ids, callback})
    }

    close() {
        this.setState(this.initialState)
    }

    updateColloStatus(colloIndex, event) {
        const colli = [...this.state.colli]

        colli[colloIndex].status = event.target.checked ? 'athub' : colli[colloIndex].originalStatus
        colli[colloIndex].reason = event.target.checked ? '' : colli[colloIndex].originalReason

        this.setState({colli})
    }

    confirm() {
        const {order, colli, ids, status, comment, callback} = this.state

        const errors = []

        this.setState({loading: true, errors})

        OrderActions.atHub(order ? [order._id] : ids, status, order ? colli : null, comment, (err, errors, order) => {
            if (err) {
                errors.push(err)
            } else if (errors.length) {
                this.setState({errors})
            } else {
                OrderActions.setSelected([])
                typeof callback === 'function' && callback(order)
                this.close()
            }
        })
    }

    render() {
        const {isOpen, order, colli, ids, status, comment, loading, errors} = this.state

        return (
            <Modal
                show={isOpen}
                title='Aangeleverd op hub'
                style={{width: 600, maxWidth: '90%'}}
                onClose={this.close.bind(this)}
            >

                {order &&
                    <>
                        <P style={{marginBottom: 24}}>Selecteer hieronder de colli die zijn aangeleverd.</P>

                        {colli.length > 1 &&
                            <Check
                                label='Selecteer alles'
                                checked={_.every(colli, (collo) => collo.status === 'athub')}
                                indeterminate={_.some(colli, (collo) => collo.status === 'athub')}
                                onChange={(event) => {
                                    colli.map((collo, index) => {
                                        this.updateColloStatus(index, event)
                                    })
                                }}
                            />
                        }

                        {colli.map((collo, index) => {
                            return (
                                <div key={`collo${collo.index}`} style={{display: 'flex', alignItems: 'center'}}>

                                    <Check
                                        checked={collo.status === 'athub'}
                                        onChange={this.updateColloStatus.bind(this, index)}
                                    />

                                    <Card style={{display: 'flex', alignItems: 'center'}}>

                                        <S1 style={{marginRight: 12}}>{collo.index + 1}</S1>

                                        <div style={{flex: 1, marginBottom: collo.type ? 6 : 0}}>
                                            <P>{`${collo.barcode || 'Geen barcode'}`}</P>
                                            <P>{dbCodes.colloTypes[collo.type] || ''}</P>
                                        </div>

                                        <P style={{marginRight: 12}}>{dbCodes.colloStatus[collo.status] || collo.status}</P>

                                        <i style={{fontSize: 8, color: Colors.colloStatusIcon[collo.status]}} className='mdi mdi-circle' />
                                    </Card>
                                </div>
                            )
                        })}

                        {order.colli.length > 0 && colli.length === 0 &&
                            <Alert variant='warning'> Deze rit heeft geen colli beschikbaar voor deze actie.</Alert>
                        }
                    </>
                }

                {ids.length > 0 &&
                    <>
                        <P style={{marginBottom: 12}}>Beschikbare colli krijgen de status:</P>

                        <Card style={{marginBottom: 12}}>
                            <Row style={{alignItems: 'center', padding: 6}}>
                                <P style={{marginRight: 12}}>{dbCodes.colloStatus[status]}</P>

                                <i style={{fontSize: 12, color: Colors.colloStatusIcon[status]}} className='mdi mdi-circle' />
                            </Row>
                        </Card>

                        <Toggle
                            label='Aangeleverd op hub ongedaan maken'
                            checked={status === 'expected'}
                            onChange={(event) => this.setState({status: event.target.checked ? 'expected' : 'athub'})}
                        />

                        {/* {false &&
                            <Toggle
                                label='Niet aangeleverd op hub'
                                checked={status === 'notsupplied'}
                                onChange={(event) => this.setState({status: event.target.checked ? 'notsupplied' : 'athub'})}
                            />
                        } */}
                    </>
                }

                <Input
                    style={{marginTop: 24}}
                    label='Opmerking'
                    value={comment}
                    onChange={(event) => this.setState({comment: event.target.value})}
                />

                {errors.length > 0 && errors.map((error, index) => {
                    return <Alert key={`error${index}`} variant='danger'>{error}</Alert>
                })}

                <div style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-end'}}>
                    {ids.length > 1 &&
                        <>
                            <i style={{fontSize: 12, marginLeft: 6, marginRight: 3}} className='mdi mdi-check' />
                            <S2 style={{flex: 1}}>{`${ids.length} ritten geselecteerd`}</S2>

                        </>
                    }

                    {!errors.length && !loading &&
                        <Button
                            variant='text'
                            onClick={this.close.bind(this)}
                        >
                            Annuleren
                        </Button>
                    }

                    {!errors.length &&
                        <Button
                            variant='text'
                            loading={loading}
                            onClick={this.confirm.bind(this)}
                        >
                            Bevestigen
                        </Button>
                    }

                    {!!errors.length &&
                        <Button
                            variant='text'
                            onClick={this.close.bind(this)}
                        >
                            Sluiten
                        </Button>
                    }
                </div>
            </Modal>
        )
    }
}
