import React from 'react'

import {Colors, Info, P} from '../UI/index.js'

class Toggle extends React.Component {
    constructor(props) {
        super(props)

        this.state = {focused: false}

        this.onPressSpace = this.onPressSpace.bind(this)
    }

    componentDidMount() {
        document.addEventListener('keydown', this.onPressSpace)
    }

    componentWillUnmount() {
        document.removeEventListener('keydown', this.onPressSpace)
    }

    onPressSpace(event) {
        const {focused} = this.state
        const {disabled, checked, onChange} = this.props

        if (focused && event.keyCode === 32) {
            if (!disabled && typeof onChange === 'function') {
                onChange({target: {checked: !checked}})
            }
        }
    }

    onClick() {
        const {disabled, onChange, checked} = this.props

        if (!disabled && typeof onChange === 'function') {
            onChange({target: {checked: !checked}})
            this.setState({focused: false})
        }
    }

    render() {
        const {disabled, checked, label, info, infoIcon, style = {}} = this.props

        const overLayStyle = {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            background: !disabled && (this.state.hover || this.state.focused) ? 'rgba(0, 0, 0, 0.05)' : 'transparent',
            width: 36,
            height: 36,
            marginLeft: 12,
            borderRadius: '50%',
            cursor: disabled ? 'default' : 'pointer'
        }

        const containerStyle = {
            position: 'relative',
            display: ' inline-block',
            width: 36,
            height: 20,
            border: `1px solid ${checked ? (disabled ? Colors.grey60 : Colors.buttonSolid) : Colors.grey40}`,
            borderRadius: 10,
            transition: 'all .1s ease',
            background: checked ? (disabled ? Colors.grey60 : Colors.buttonSolid) : Colors.grey20,
            outline: 'none'
        }

        const sliderStyle = {
            width: 16,
            height: 16,
            position: 'absolute',
            top: 1,
            left: checked ? 17 : 2,
            background: disabled ? (checked ? Colors.grey20 : Colors.grey60) : 'white',
            transition: 'all .1s ease',
            borderRadius: '50%'
        }

        const labelStyle = {
            display: 'flex',
            alignItems: 'center'
        }

        const defaultStyle = {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            flex: 1,
            height: 36,
            marginBottom: 12,
            marginLeft: 6,
            marginRight: 6,
            ...style
        }

        if (style && style.width) {
            delete defaultStyle.flex
        }

        return (
            <div style={defaultStyle}>

                {label &&
                    <P style={labelStyle}>
                        <div style={{whiteSpace: 'nowrap'}}>{label}</div>
                        {info && <Info icon={infoIcon} text={info} />}
                    </P>
                }

                <div
                    style={overLayStyle}
                    onClick={this.onClick.bind(this)}
                    onMouseEnter={() => this.setState({hover: true})}
                    onMouseLeave={() => this.setState({hover: false})}
                >
                    <div
                        tabIndex={disabled ? -1 : 0}
                        style={containerStyle}
                        onFocus={() => this.setState({focused: true})}
                        onBlur={() => this.setState({focused: false})}
                    >
                        <div style={sliderStyle} />
                    </div>
                </div>
            </div>
        )
    }
}

export default Toggle
