import React from 'react'

import {Input} from '../UI/index.js'

class MetricInput extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            value: props.value || 0,
            displayValue: !props.value ? '' : (props.value / (props.divisionFactor || 1)).toString().replace('.', ',')
        }
    }

    componentDidUpdate(prevProps) {
        const {value, divisionFactor} = this.props

        if (prevProps.value !== value || prevProps.divisionFactor !== divisionFactor) {
            this.setState({
                value: value || 0,
                displayValue: !value ? '' : (value / (divisionFactor || 1)).toString().replace('.', ',')
            })
        }
    }

    focus() {
        this.formControl.focus()
    }

    onBlur() {
        const {value} = this.state

        const event = {target: {value}}

        this.props.onChange(event)

        typeof this.props.onBlur === 'function' && this.props.onBlur()
    }

    onChange(event) {
        const {divisionFactor = 1, precision = 0, min, max} = this.props
        event.target.value = event.target.value.replace('.', ',')

        if (new RegExp(`^$|^[0-9]{0,5}[,]{0,1}$|^[0-9]{0,5}[,][0-9]{0,${(divisionFactor).toString().length - 1 + precision}}$`).test(event.target.value)) {
            let value = (parseFloat(event.target.value.replace(',', '.')) * divisionFactor) || 0
            let displayValue = event.target.value

            if (value < min) {
                value = min
                const multiplicator = Math.pow(10, 2)
                displayValue = (Math.round(parseFloat(((value / divisionFactor) * multiplicator).toFixed(11))) / multiplicator).toString().replace('.', ',')
            }

            if (value > max) {
                value = max
                const multiplicator = Math.pow(10, 2)
                displayValue = (Math.round(parseFloat(((value / divisionFactor) * multiplicator).toFixed(11))) / multiplicator).toString().replace('.', ',')
            }

            this.setState({value, displayValue})
        }
    }

    render() {
        const {displayValue} = this.state
        const {style, id, label, info, isInvalid, onFocus, placeholder, divisionFactor = 1} = this.props

        return (
            <Input
                {...this.props}
                placeholder={typeof placeholder === 'string' ? placeholder : (divisionFactor && placeholder) ? (placeholder / divisionFactor).toString().replace('.', ',') : placeholder || ''}
                id={id}
                style={style}
                label={label}
                info={info}
                value={displayValue}
                isInvalid={isInvalid}
                onFocus={onFocus}
                onBlur={this.onBlur.bind(this)}
                onChange={this.onChange.bind(this)}
                ref={(ref) => this.formControl = ref}
            />
        )
    }
}

export default MetricInput
