import React from 'react'

import {Input} from './index.js'

export default class DurationPickerPicker extends React.Component {
    constructor(props) {
        super(props)
        const {value = 0} = props

        this.state = {
            displayValue: formatTime(value)
        }
    }

    componentDidUpdate(prevProps) {
        const {value = 0} = this.props

        if (prevProps.value !== value) {
            this.setState({displayValue: formatTime(value)})
        }
    }

    handleChangeInput(event) {
        const {onChange} = this.props

        let displayValue = event.target.value.replace('.', ':').replace(',', ':')

        if (/^$|^[0-9]{0,2}[:]?[0-9]{0,2}$/.test(displayValue)) {
            if (this.state.displayValue.length === 1 && displayValue.length === 2 && /^[0-9]{0,2}$/.test(displayValue)) {
                displayValue = `${displayValue}:`
            }

            console.log(displayValue)
            this.setState({displayValue})

            if (/^([0-9]{0,2}):[0-5][0-9]$/.test(displayValue)) {
                event.target.value = parseInt(displayValue.split(':')[0]) * 60 + parseInt(displayValue.split(':')[1])
                console.log(event.target.value)
                onChange(event)
            }
        }
    }

    onBlur() {
        const {value = 0} = this.props

        this.setState({displayValue: formatTime(value)})
    }

    render() {
        const {displayValue} = this.state
        const {label, info, readOnly, disabled, style = {}, inputMode} = this.props
        style.caretColor = inputMode === 'none' ? 'transparent' : 'default'

        return (
            <Input
                style={style}
                label={label}
                info={info}
                value={displayValue}
                disabled={disabled}
                readOnly={readOnly}
                onChange={this.handleChangeInput.bind(this)}
                onBlur={this.onBlur.bind(this)}
                ref={(ref) => this.formControl = ref}
                append='mdi mdi-clock-outline'
                inputMode={inputMode}
            />
        )
    }
}

function formatTime(value) {
    return `${value / 60 < 10 ? '0' : ''}${parseInt(value / 60)}:${value % 60 < 10 ? '0' : ''}${value % 60}`
}
