import Reflux from 'reflux-react-16'
import moment from 'moment'
import _ from 'underscore'
import socket from '../socketio/socketio.js'
import ParcelActions from '../actions/ParcelActions.js'

let updateTimeout

export default class ParcelStore extends Reflux.Store {
    constructor() {
        super()

        this.state = {
            parcelStartDate: moment().format('YYYY-MM-DD'),
            parcelEndDate: moment().format('YYYY-MM-DD'),
            parcelQueries: [],
            parcels: [],
            selectedParcels: [],
            carriers: [],
            parcelsLoading: false,
            parcelLimitWarning: false
        }

        this.listenables = ParcelActions

        ParcelActions.getCarriers()
        ParcelActions.get(true)

        socket.on('updateParcel', this.update.bind(this))
    }

    update(id) {
        clearTimeout(updateTimeout)
        const {parcels, selectedParcels, parcelStartDate, parcelEndDate} = this.state

        socket.emit('parcels.get', sessionStorage.token, parcelStartDate, parcelEndDate, id, (err, parcel) => {
            if (!err) {
                parcel = parcel[0]

                const index = _.findIndex(parcels, {_id: id})

                // update parcels
                if (parcel) {
                    if (index > -1) {
                        parcels[index] = parcel
                    } else {
                        parcels.push(parcel)
                    }
                } else {
                    if (index > -1) {
                        const selectedIndex = selectedParcels.indexOf(id)
                        selectedParcels.splice(selectedIndex, 1)
                        parcels.splice(index, 1)
                    }
                }

                if (updateTimeout !== null) {
                    clearTimeout(updateTimeout)
                }

                updateTimeout = setTimeout(() => {
                    this.setState({parcels, selectedParcels})
                }, 50)
            }
        })
    }

    onSetDates(parcelStartDate, parcelEndDate) {
        this.setState({parcelStartDate, parcelEndDate, selectedParcels: []})
        ParcelActions.get(true)
    }

    onSetQueries(parcelQueries) {
        this.setState({parcelQueries, selectedParcels: []})
    }

    onSetSelected(selectedParcels) {
        this.setState({selectedParcels})
    }

    onGet(loading) {
        const {parcelStartDate, parcelEndDate, selectedParcels} = this.state
        this.setState({parcelsLoading: !!loading})

        socket.emit('parcels.get', sessionStorage.token, parcelStartDate, parcelEndDate, null, (err, parcels) => {
            if (!err) {
                const ids = _.pluck(parcels, '_id')

                this.setState({
                    parcels,
                    selectedParcels: _.filter(selectedParcels, (id) => ids.indexOf(id) > -1),
                    parcelsLoading: false,
                    parcelLimitWarning: parcels.length >= 35000
                })
            }
        })
    }

    onGetOne(id, callback) {
        socket.emit('parcels.getOne', sessionStorage.token, id, callback)
    }

    onCreate(parcel, callback) {
        socket.emit('parcels.create', sessionStorage.token, parcel, callback)
    }

    onEdit(parcel, callback) {
        socket.emit('parcels.edit', sessionStorage.token, parcel, callback)
    }

    onRemove(id, callback) {
        socket.emit('parcels.remove', sessionStorage.token, id, callback)
    }

    onCancel(id, callback) {
        socket.emit('parcels.cancel', sessionStorage.token, id, callback)
    }

    onEditCustomer(id, customer, contact, callback) {
        socket.emit('parcels.editCustomer', sessionStorage.token, id, customer, contact, callback)
    }

    onEditAdministration(...args) {
        socket.emit('parcels.editAdministration', sessionStorage.token, ...args)
    }

    onEditDimensionsAndWeight(id, weight, dimensions, callback) {
        socket.emit('parcels.editDimensionsAndWeight', sessionStorage.token, id, weight, dimensions, callback)
    }

    onEditNotes(id, notes, callback) {
        socket.emit('parcels.editNotes', sessionStorage.token, id, notes, callback)
    }

    onPrintLabels(ids, nrOfEmptyLabels, callback) {
        const printerPageSize = localStorage.printerPageSize || 'A6'
        socket.emit('parcels.printLabels', sessionStorage.token, ids, printerPageSize, nrOfEmptyLabels, callback)
    }

    onGetStatus(id, callback) {
        socket.emit('parcels.getStatus', sessionStorage.token, id, callback)
    }

    onCalculatePrice(id, fees, removeOldFees, pricingOption, newPrice, callback) {
        socket.emit('parcels.calculatePrice', sessionStorage.token, id, fees, removeOldFees, pricingOption, newPrice, callback)
    }

    onSendTrackTraceEmail(parcel, email, callback) {
        socket.emit('parcels.sendTrackTraceEmail', sessionStorage.token, parcel, email, callback)
    }

    onGetOptions(parcel, callback) {
        socket.emit('parcels.getOptions', sessionStorage.token, parcel, callback)
    }

    onGetCarriers() {
        socket.emit('parcels.getCarriers', sessionStorage.token, (err, carriers) => {
            if (!err) {
                this.setState({carriers})
            }
        })
    }

    onGetOrdersFromWebshop(callback) {
        socket.emit('parcels.getOrdersFromWebshop', sessionStorage.token, callback)
    }

    onScan(trackTrace, callback) {
        socket.emit('parcels.scan', sessionStorage.token, trackTrace, callback)
    }

    onChangeStatus(...args) {
        socket.emit('parcels.changeStatus', sessionStorage.token, ...args)
    }
}
