// @ts-check

import {Reseller} from '../../../server/functions/resellers/resellers.types.js'
import {Vehicle} from '../../../server/functions/vehicles/vehicles.types.js'

/**
 * New vehicle
 *
 * @param {Reseller} reseller
 * @returns {Vehicle}
 */
const newVehicle = (reseller: Reseller): Vehicle => {
    const vehicle: Vehicle = {
        reseller: reseller.name,
        name: '',
        typeId: reseller.settings.vehicleTypes.find((vehicleType) => !vehicleType.isArchived && vehicleType._id === reseller.settings.defaultVehicle)?._id || reseller.settings.vehicleTypes.find((vehicleType) => !vehicleType.isArchived)?._id,
        comment: '',
        notes: '',
        notAvailableDateTimeRanges: [],
        notesEntries: [],
        isArchived: false
    }
    return structuredClone(vehicle)
}

/**
 * New vehicle type
 *
 * @returns {VehicleType}
 */
const newVehicleType = (): Reseller['settings']['vehicleTypes'][0] => {
    const vehicleType: Reseller['settings']['vehicleTypes'][0] = {
        name: '',
        icon: '',
        routeProfile: 'bike',
        speedDifferenceEnabled: false,
        speedDifferenceMode: 'faster',
        speedDifference: 0,
        fuelType: 'none',
        usage: 0,
        range: 0,
        payloads: [],
        hourlyRate: '0,00',
        stopTime: 120,
        comment: '',
        notes: '',
        isArchived: false
    }
    return structuredClone(vehicleType)
}

export {
    newVehicle,
    newVehicleType
}
