import React from 'react'

export default class Tabs extends React.Component {
    render() {
        const {style = {}, children, value, values, onChange} = this.props

        const defaultStyle = {
            display: 'flex',
            height: 38,
            marginBottom: 30,
            ...style
        }

        return (
            <div style={defaultStyle}>
                {React.Children.map(children, (child) => {
                    if (child) {
                        return React.cloneElement(child, {active: child.props.value === value || values && values.indexOf(child.props.value) > -1, onClick: onChange})
                    }
                })}
            </div>
        )
    }
}
