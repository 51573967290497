import React from 'react'

import {Colors} from '../UI/index.js'

class Option extends React.Component {
    constructor(props) {
        super(props)
        this.state = {focused: false}
        this.onPressSpace = this.onPressSpace.bind(this)
    }

    componentDidMount() {
        document.addEventListener('keydown', this.onPressSpace)
    }

    componentWillUnmount() {
        document.removeEventListener('keydown', this.onPressSpace)
    }

    onPressSpace(event) {
        const {focused} = this.state
        const {disabled, checked, onChange} = this.props

        if (focused && event.keyCode === 32) {
            if (!disabled && typeof onChange === 'function') {
                onChange({target: {checked: !checked}})
            }
        }
    }

    onClick() {
        const {disabled, checked, onChange} = this.props

        if (!disabled && typeof onChange === 'function') {
            onChange({target: {checked: !checked}})
        }
    }

    render() {
        const {disabled, checked, label, style = {}} = this.props

        const overLayStyle = {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            background: !disabled && (this.state.hover || this.state.focus) ? 'rgba(0, 0, 0, 0.05)' : 'transparent',
            width: 36,
            height: 36,
            borderRadius: '50%',
            cursor: disabled ? 'default' : 'pointer'
        }

        const checkboxStyle = {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            background: disabled ? Colors.grey20 : checked ? Colors.buttonSolid : Colors.grey40,
            width: 26,
            height: 26,
            borderRadius: '50%',
            fontSize: 14,
            color: disabled ? Colors.grey60 : checked ? Colors.white : Colors.textDark,
            transition: 'background .1s ease',
            outline: 'none'
        }

        const defaultStyle = {display: 'flex', alignItems: 'center', height: 42, ...style}

        return (
            <div style={defaultStyle}>
                <div style={overLayStyle} onClick={this.onClick.bind(this)}>
                    <div
                        tabIndex={disabled ? -1 : 0}
                        style={checkboxStyle}
                        onFocus={() => this.setState({focused: true})}
                        onBlur={() => this.setState({focused: false})}
                        onMouseEnter={() => this.setState({hover: true})}
                        onMouseLeave={() => this.setState({hover: false})}
                    >
                        {label}
                    </div>
                </div>
            </div>
        )
    }
}

export default (Option)
