// @ts-check

import React, {useState, useEffect, useRef, useMemo, Fragment, CSSProperties} from 'react'
import * as d3 from 'd3'
import moment from 'moment'
import _ from 'lodash'

import {Colors, H5, Row, Select, Card, Button, Panel, Icon, P, IconButton} from '../UI/index.js'
import useVehicleStore from '../../stores/VehicleStore.js'
import naturalSortBy from '../../utils/naturalSortBy.js'
import {Reseller} from '../../../server/functions/resellers/resellers.types.js'
import {User} from '../../../server/functions/users/users.types.js'
import Map from '../map/Map.js'
import {FrontEndRoute} from '../../../server/functions/route/route.types.js'

interface VehiclePanelProps {
    reseller: Reseller
    mapRef: Map
    user: User
    routes: {
        [routeId: string]: FrontEndRoute
    }
    date: string
}

export default function VehiclePanel({reseller, mapRef, user, routes, date}: VehiclePanelProps) {
    const panelObject = localStorage._vehiclePanel ? JSON.parse(localStorage._vehiclePanel) : {}
    const userPanelObject = panelObject[user._id]

    const vehicles = useVehicleStore((state) => state.vehicles)
    const getVehicles = useVehicleStore((state) => state.getVehicles)
    const collapsedHeight = 0
    const [height, setHeight] = useState(userPanelObject ? (!userPanelObject.collapsed && userPanelObject.height) ? userPanelObject.height : collapsedHeight : 270)
    const [isResizing, setIsResizing] = useState(false)
    const [collapsed, setCollapsed] = useState(userPanelObject ? userPanelObject.collapsed : false)
    const [sort, setSort] = useState(userPanelObject?.sort || 'startTime')
    const [group, setGroup] = useState(userPanelObject?.group || 'messenger')
    const [sortOrder, setSortOrder] = useState('asc')
    const [chartZoomTransform, setChartZoomTransform] = useState(null)
    const [barChartWidth, setBarChartWidth] = useState(NaN)
    const [groupedData, setGroupedData] = useState([])
    const [messengers, setMessengers] = useState([])
    const [now, setNow] = useState(new Date())
    const [routeHover, setRouteHover] = useState(null)
    const panelRef = useRef(null)
    const isResizingRef = useRef(null)
    const barchartRef2 = useRef(null)
    const svgChartRef = useRef(null)
    const zoomRef = useRef(null)

    isResizingRef.current = isResizing

    const wrapperStyle = {
        width: '100%',
        height: `${height}px`,
        background: Colors.backgroundNeutral,
        flexShrink: 0,
        position: 'relative',
        borderStyle: 'solid none none none',
        padding: '0 12px',
        zIndex: 1000,
        display: 'flex',
        flexDirection: 'column',
        ...(!isResizingRef.current && {transition: 'height 0.2s'}),
        ...(collapsed && {display: 'flex', alignItems: 'center', height: collapsedHeight})
    }

    /**
     * @type {import('react').CSSProperties}
     */
    const collapseButtonStyle: CSSProperties = {
        width: 36,
        height: 24,
        backgroundColor: Colors.white,
        cursor: 'pointer',
        position: 'absolute',
        left: '50%',
        top: -24,
        transform: 'translateX(-50%)',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: '4px 4px 0 0'
    }

    const handleMouseDown = () => {
        setIsResizing(true)
    }

    const handleMouseMove = (e) => {
        if (isResizingRef.current) {
            setHeight((prevHeight) => {
                const grandParent = panelRef.current?.parent.parentElement
                let leftOverSpace = panelRef.current?.parent?.getBoundingClientRect().top - grandParent?.getBoundingClientRect().top

                leftOverSpace += e.movementY

                if (leftOverSpace < 0) {
                    return prevHeight
                }
                const newHeight = prevHeight - e.movementY
                if (newHeight < 40) {
                    setCollapsed(true)
                    return collapsed
                }
                if (collapsed) {
                    setCollapsed(false)
                }
                return newHeight
            })
        }
    }

    const handleMouseUp = () => {
        setIsResizing(false)
    }

    let nowTimeout

    useEffect(() => {
        getVehicles()

        nowTimeout = setInterval(() => {
            setNow(new Date())
        }, 1000)

        return () => {
            clearInterval(nowTimeout)
        }
    }, [])

    useEffect(() => {
        if (Object.values(routes).length > 0) {
            const messengers = Object.values(routes).map((route) => ({_id: route.messengerId, name: route.messengerId && route.messengerName ? route.messengerName : 'no-messenger', routeId: route._id})).filter((messenger, index, self) => {
                if (!messenger._id) {
                    return true
                }

                return messenger._id && self.findIndex((m) => m._id === messenger._id) === index
            })
            setMessengers(messengers)
        }
    }, [routes])

    useEffect(() => {
        if (isResizing) {
            document.addEventListener('mousemove', handleMouseMove)
            document.addEventListener('mouseup', handleMouseUp)
        } else {
            document.documentElement.removeAttribute('mousemoveListener')
            document.removeEventListener('mousemove', handleMouseMove)
            document.removeEventListener('mouseup', handleMouseUp)

            setTimeout(() => {
                mapRef?.mapState.map.invalidateSize({pan: false})
            }, 200)
        }

        return () => {
            document.removeEventListener('mousemove', handleMouseMove)
            document.removeEventListener('mouseup', handleMouseUp)
        }
    }, [isResizing])

    useEffect(() => {
        setTimeout(() => {
            if (!isResizing && !collapsed) {
                panelObject[user._id] = panelObject[user._id] || {}
                panelObject[user._id].height = height
                localStorage._vehiclePanel = JSON.stringify(panelObject)
            }
        }, 200)
    }, [height, isResizing, collapsed])

    useEffect(() => {
        panelObject[user._id] = panelObject[user._id] || {}
        panelObject[user._id].collapsed = collapsed
        localStorage._vehiclePanel = JSON.stringify(panelObject)

        setTimeout(() => {
            mapRef?.mapState.map.invalidateSize({pan: false})
        }, 200)

        if (!collapsed) {
            setBarChartWidth(barchartRef2.current?.clientWidth)
        }
    }, [collapsed])

    useEffect(() => {
        let observer

        if (!observer) {
            const parentDiv = panelRef.current?.parent

            let previousWidth = parentDiv?.offsetWidth

            const onResize = _.debounce((entries) => {
                const newWidth = entries[0].target.offsetWidth

                if (previousWidth !== newWidth) {
                    previousWidth = newWidth

                    setChartZoomTransform(null)
                    setBarChartWidth(barchartRef2.current?.clientWidth)
                }
            }, 50)

            observer = new ResizeObserver(onResize)
            if (parentDiv) {
                observer.observe(parentDiv)
            }
        }

        return () => {
            observer?.disconnect()
        }
    }, [collapsed, height])

    useEffect(() => {
        let data

        if (group === 'vehicle') {
            const activeVehicleTypes = []

            Object.values(routes).forEach((route) => {
                if (route.vehicleTypeId && !route.vehicleId && (route.startTime || route.plannedStartTime) && (route.endTime || route.estimatedEndTime || route.plannedEndTime)) {
                    const vehicleType = reseller.settings.vehicleTypes.find((vehicleType) => !vehicleType.isArchived && vehicleType._id === route.vehicleTypeId)

                    if (vehicleType) {
                        activeVehicleTypes.push({
                            isVehicleType: true,
                            routeId: route._id,
                            ...vehicleType
                        })
                    }
                }
            })

            const filteredVehicles = vehicles.filter((v) => !v.isArchived).map((vehicle) => ({...vehicle, notAvailableToday: vehicle.notAvailableDateTimeRanges?.some((range) => moment().isBetween(range.start, range.end, 'day', '[]'))}))

            data = [...activeVehicleTypes, ...filteredVehicles]
        }

        if (group === 'messenger') {
            data = _.uniqBy(messengers, (messenger) => messenger._id).filter((messenger) => messenger._id)
        }

        if (group === 'route') {
            data = Object.values(routes)
        }

        if (data && sort) {
            data = data.map((item, index) => ({...item, index}))

            if (sort === 'name') {
                data = naturalSortBy(data, 'name')
            }

            data.sort((a, b) => {
                if (group === 'vehicle' && sort === 'vehicleType') {
                    const vehicleTypeA = 'typeId' in a ? reseller.settings.vehicleTypes.find((vt) => vt._id === a.typeId) : a
                    const vehicleTypeB = 'typeId' in b ? reseller.settings.vehicleTypes.find((vt) => vt._id === b.typeId) : b

                    if (vehicleTypeA?.name < vehicleTypeB?.name) return -1
                    if (vehicleTypeA?.name > vehicleTypeB?.name) return 1
                    return 0
                }

                if (sort === 'startTime') {
                    let routesA
                    let routesB

                    if (group === 'vehicle') {
                        const aIsVehicle = a && 'typeId' in a
                        const bIsVehicle = b && 'typeId' in b

                        routesA = Object.values(routes).filter((route) => {
                            const hasVehicle = route.vehicleId

                            if (hasVehicle && aIsVehicle) {
                                return route.vehicleId === a._id
                            }

                            if (hasVehicle && !aIsVehicle) {
                                return false
                            }

                            return route.vehicleTypeId === a._id
                        })
                        routesB = Object.values(routes).filter((route) => {
                            const hasVehicle = route.vehicleId

                            if (hasVehicle && bIsVehicle) {
                                return route.vehicleId === b._id
                            }

                            if (hasVehicle && !bIsVehicle) {
                                return false
                            }

                            return route.vehicleTypeId === b._id
                        })

                        if (!aIsVehicle && routesA.length > 1) {
                            const occurrences = data.filter((i) => i._id === a._id)
                            const thisOccurrenceIndex = occurrences.findIndex((o) => o.index === a.index)
                            data[a.index] = {...data[a.index], index: thisOccurrenceIndex}
                            routesA = [routesA[thisOccurrenceIndex]]
                        }

                        if (!bIsVehicle && routesB.length > 1) {
                            const occurrences = data.filter((i) => i._id === b._id)
                            const thisOccurrenceIndex = occurrences.findIndex((o) => o.index === b.index)
                            data[b.index] = {...data[b.index], index: thisOccurrenceIndex}
                            routesB = [routesB[thisOccurrenceIndex]]
                        }
                    }

                    if (group === 'messenger') {
                        routesA = Object.values(routes).filter((route) => !route.completed && route.messengerId === a._id)
                        routesB = Object.values(routes).filter((route) => !route.completed && route.messengerId === b._id)
                    }

                    if (group === 'route') {
                        routesA = [a]
                        routesB = [b]
                    }

                    const firstRouteA = routesA.sort((a, b) => (a.startTime || a.plannedStartTime) < (b.startTime || b.plannedStartTime) ? -1 : (a.startTime || a.plannedStartTime) > (b.startTime || b.plannedStartTime) ? 1 : 0)[0]
                    const firstRouteB = routesB.sort((a, b) => (a.startTime || a.plannedStartTime) < (b.startTime || b.plannedStartTime) ? -1 : (a.startTime || a.plannedStartTime) > (b.startTime || b.plannedStartTime) ? 1 : 0)[0]

                    return (firstRouteA?.startTime || firstRouteA?.plannedStartTime || '99:99') < (firstRouteB?.startTime || firstRouteB?.plannedStartTime || '99:99') ? -1 : (firstRouteA?.startTime || firstRouteA?.plannedStartTime || '99:99') > (firstRouteB?.startTime || firstRouteB?.plannedStartTime || '99:99') ? 1 : 0
                }

                if (sort === 'endTime') {
                    let routesA
                    let routesB

                    if (group === 'vehicle') {
                        const aIsVehicle = a && 'typeId' in a
                        const bIsVehicle = b && 'typeId' in b

                        routesA = Object.values(routes).filter((route) => {
                            const hasVehicle = route.vehicleId

                            if (hasVehicle && aIsVehicle) {
                                return route.vehicleId === a._id
                            }

                            if (hasVehicle && !aIsVehicle) {
                                return false
                            }

                            return route.vehicleTypeId === a._id
                        })
                        routesB = Object.values(routes).filter((route) => {
                            const hasVehicle = route.vehicleId

                            if (hasVehicle && bIsVehicle) {
                                return route.vehicleId === b._id
                            }

                            if (hasVehicle && !bIsVehicle) {
                                return false
                            }

                            return route.vehicleTypeId === b._id
                        })

                        if (!aIsVehicle && routesA.length > 1) {
                            const occurrences = data.filter((i) => i._id === a._id)
                            const thisOccurrenceIndex = occurrences.findIndex((o) => o.index === a.index)
                            data[a.index] = {...data[a.index], index: thisOccurrenceIndex}
                            routesA = [routesA[thisOccurrenceIndex]]
                        }

                        if (!bIsVehicle && routesB.length > 1) {
                            const occurrences = data.filter((i) => i._id === b._id)
                            const thisOccurrenceIndex = occurrences.findIndex((o) => o.index === b.index)
                            data[b.index] = {...data[b.index], index: thisOccurrenceIndex}
                            routesB = [routesB[thisOccurrenceIndex]]
                        }
                    }

                    if (group === 'messenger') {
                        routesA = Object.values(routes).filter((route) => route.messengerId === a._id)
                        routesB = Object.values(routes).filter((route) => route.messengerId === b._id)
                    }

                    if (group === 'route') {
                        routesA = [a]
                        routesB = [b]
                    }

                    const lastRouteA = routesA.sort((a, b) => (a.estimatedEndTime || a.endTime) < (b.estimatedEndTime || b.endTime) ? -1 : (a.estimatedEndTime || a.endTime) > (b.estimatedEndTime || b.endTime) ? 1 : 0)[routesA.length - 1]
                    const lastRouteB = routesB.sort((a, b) => (a.estimatedEndTime || a.endTime) < (b.estimatedEndTime || b.endTime) ? -1 : (a.estimatedEndTime || a.endTime) > (b.estimatedEndTime || b.endTime) ? 1 : 0)[routesB.length - 1]

                    return (lastRouteA?.estimatedEndTime || lastRouteA?.endTime || '99:99') < (lastRouteB?.estimatedEndTime || lastRouteB?.endTime || '99:99') ? -1 : (lastRouteA?.estimatedEndTime || lastRouteA?.endTime || '99:99') > (lastRouteB?.estimatedEndTime || lastRouteB?.endTime || '99:99') ? 1 : 0
                }

                if (group === 'route' && sort === 'status') {
                    const getStatus = (route) => {
                        if (!route.ready && !route.started && !route.completed) { // Concept
                            return 0
                        }

                        if (route.ready && !route.started && !route.completed) { // Gereed
                            return 1
                        }

                        if (route.started && !route.completed) { // Onderweg
                            return 2
                        }

                        if (route.completed) { // Afgerond
                            return 3
                        }
                    }

                    const statusA = getStatus(a)
                    const statusB = getStatus(b)

                    return statusA < statusB ? -1 : statusA > statusB ? 1 : 0
                }
            })
            if (sortOrder === 'desc') {
                data.reverse()
            }
        }

        setGroupedData([...data])
    }, [group, sort, sortOrder, vehicles, messengers, routes])

    useEffect(() => {
        panelObject[user._id] = panelObject[user._id] || {}
        panelObject[user._id].sort = sort
        panelObject[user._id].group = group
        localStorage._vehiclePanel = JSON.stringify(panelObject)
    }, [sort, group])

    const contentHeight = (34 * groupedData.length) + 40
    const width = barChartWidth - 280

    const availableHeight = barchartRef2.current?.offsetHeight - 60
    const rowHeight = 34
    const totalRows = Math.floor(availableHeight / rowHeight)

    const dataRows = groupedData.length
    const emptyRows = totalRows - dataRows > 0 ? totalRows - dataRows : 0

    const yScale = useMemo(() => {
        const scale = d3.scaleBand()
            .domain([...groupedData.map(({name}) => name), ...Array(emptyRows).fill('').map(() => 'empty')].map((v, i) => `${v}-${i}`))
            .range([0, (34 * (groupedData.length + emptyRows))])

        return scale
    }, [groupedData, emptyRows])

    const today = new Date()
    const startOfDay = new Date(today.getFullYear(), today.getMonth(), today.getDate(), 0, 0)
    const endOfDay = new Date(today.getFullYear(), today.getMonth(), today.getDate(), 23, 59)
    const initialStartTime = new Date(today.getFullYear(), today.getMonth(), today.getDate(), 8, 0)
    const initialEndTime = new Date(today.getFullYear(), today.getMonth(), today.getDate(), 22, 0)

    const xScale = useMemo(() => {
        const scale = d3.scaleTime()
            .domain([startOfDay, endOfDay])
            .range([0, width])
        if (chartZoomTransform) {
            const newScale = chartZoomTransform.rescaleX(scale.copy())
            return newScale
        }
        return scale
    }, [barChartWidth, chartZoomTransform])

    const tickFormat = xScale.tickFormat(undefined, d3.timeFormat('%H:%M'))

    const ticks = xScale.ticks()

    useEffect(() => {
        if (svgChartRef.current && barchartRef2.current && !isNaN(width)) {
            zoomRef.current = d3.zoom()
                .scaleExtent([1, 24])
                .translateExtent([[0, 0], [width, contentHeight]])
                .extent([[0, 0], [width, contentHeight]])
                .filter((event) => {
                    const condition = event.type === 'wheel' ? (event.shiftKey && event.deltaX === 0) : true
                    return (condition)
                })
                .on('zoom', (event) => {
                    setChartZoomTransform(event.transform)
                })

            d3.select(svgChartRef.current)
                .call(zoomRef.current)

            const initialTransform = d3.zoomIdentity
                .scale(width / (xScale(initialEndTime) - xScale(initialStartTime)))
                .translate(-xScale(initialStartTime), 0)

            d3.select(svgChartRef.current).call(zoomRef.current.transform, initialTransform)
        }

        if (barchartRef2.current && isNaN(barChartWidth) && !collapsed) {
            setBarChartWidth(barchartRef2.current.clientWidth)
        }
    }, [barchartRef2.current, barChartWidth, collapsed])

    let sortOptions

    if (group === 'messenger') {
        sortOptions = [
            {
                title: 'Naam',
                value: 'name'
            },
            {
                title: 'Starttijd',
                value: 'startTime'
            },
            {
                title: 'Eindtijd',
                value: 'endTime'
            }
        ]
    }

    if (group === 'vehicle') {
        sortOptions = [
            {
                title: 'Naam',
                value: 'name'
            },
            {
                title: 'Voertuigsoort',
                value: 'vehicleType'
            },
            {
                title: 'Starttijd',
                value: 'startTime'
            },
            {
                title: 'Eindtijd',
                value: 'endTime'
            }
        ]
    }

    if (group === 'route') {
        sortOptions = [
            {
                title: 'Naam',
                value: 'name'
            },
            {
                title: 'Starttijd',
                value: 'startTime'
            },
            {
                title: 'Eindtijd',
                value: 'endTime'
            },
            {
                title: 'Status',
                value: 'status'
            }
        ]
    }

    return (
        <Panel style={wrapperStyle} ref={panelRef}>
            {collapsed &&
                <div
                    style={collapseButtonStyle}
                    onClick={() => {
                        setHeight(userPanelObject ? (userPanelObject.height > 83.5 ? userPanelObject.height : 300) : 300)
                        setCollapsed(false)
                    }}
                >
                    <i className='mdi mdi-chevron-up' />
                </div>
            }
            <Row style={{justifyContent: collapsed ? 'center' : 'space-between', cursor: 'row-resize', ...(!collapsed && {alignItems: 'center'})}} onMouseDown={handleMouseDown}>
                <div style={{width: 'calc(100% / 3)'}} />

                <H5 style={{marginBottom: 0, textAlign: 'center', width: 'calc(100% / 3)'}}>Bezetting</H5>

                <div style={{width: 'calc(100% / 3)'}}>
                    <Row style={{display: collapsed ? 'none' : 'flex', alignItems: 'center', justifyContent: 'flex-end'}}>
                        <IconButton
                            icon='mdi mdi-close'
                            onClick={() => {
                                setCollapsed(true)
                                setHeight(collapsedHeight)
                            }}
                            onMouseDown={(e) => e.stopPropagation()}
                        />
                    </Row>
                </div>
            </Row>

            <div style={{flexGrow: 1, overflowY: 'hidden', position: 'relative'}} ref={barchartRef2}>
                <div className='content' style={{height: 'calc(100% - 85.3px)', overflowY: 'auto'}}>
                    <svg ref={svgChartRef} width='100%' style={{height: emptyRows > 0 ? 'calc(100% - 5px)' : contentHeight - rowHeight, cursor: 'grab'}}>
                        <g>
                            <g className='rows'>
                                {groupedData.map((item, index) => (
                                    <g key={item._id ? `${item._id}-${index}` : item.name ? `${item.name}-${index}` : index} className='outer-row'>
                                        <foreignObject
                                            className='row'
                                            x={280}
                                            y={yScale(`${item.name}-${index}`)}
                                            width={!isNaN(width) ? width : undefined}
                                            height={31}
                                        >
                                            <div className='inner-row' style={{height: 27, background: item.notAvailableToday ? Colors.grey20 : Colors.white, boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)', position: 'relative'}}>
                                                {ticks.map((tick, i) => (
                                                    <Fragment key={`${tickFormat(tick)}-${i}`}>
                                                        <div style={{position: 'absolute', top: 0, left: xScale(tick) || undefined, width: 1, height: 7, background: Colors.grey30}} />
                                                        <div style={{position: 'absolute', top: '50%', left: xScale(tick) || undefined, width: 1, height: 7, background: Colors.grey30}} />
                                                    </Fragment>
                                                ))}
                                            </div>
                                        </foreignObject>
                                    </g>
                                ))}
                                {Array(emptyRows).fill('').map((_, i) => (
                                    <foreignObject
                                        key={`empty-${i}`}
                                        className='row'
                                        x={280}
                                        y={(dataRows + i) * rowHeight}
                                        width={!isNaN(barChartWidth) ? barChartWidth - 280 : undefined}
                                        height={rowHeight - 3}
                                    >
                                        <div className='inner-row' style={{height: 27, background: Colors.white, boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)', position: 'relative'}}>
                                            {ticks.map((tick, i) => (
                                                <Fragment key={`${tickFormat(tick)}-${i}`}>
                                                    <div style={{position: 'absolute', top: 0, left: xScale(tick) || undefined, width: 1, height: 7, background: Colors.grey30}} />
                                                    <div style={{position: 'absolute', top: '50%', left: xScale(tick) || undefined, width: 1, height: 7, background: Colors.grey30}} />
                                                </Fragment>
                                            ))}
                                        </div>
                                    </foreignObject>
                                ))}
                            </g>
                            <g className='bars'>
                                {groupedData.map((item, index, self) => {
                                    let barsRoutes: FrontEndRoute[]

                                    if (group === 'messenger') {
                                        barsRoutes = Object.values(routes).filter((route) => route.messengerId && route.messengerId === item._id)
                                    }

                                    if (group === 'vehicle') {
                                        barsRoutes = Object.values(routes).filter((route) => {
                                            if (item.isVehicleType) {
                                                return item.routeId === route._id
                                            }

                                            return route.vehicleId === item._id
                                        })

                                        if (item.isVehicleType && barsRoutes.length > 1) {
                                            const occurrences = self.map((sItem, i) => ({...sItem, index: i})).filter((i) => i._id === item._id)
                                            const thisOccurrenceIndex = occurrences.findIndex((o) => o.index === (item?.index ?? index))
                                            barsRoutes = [barsRoutes[thisOccurrenceIndex]]
                                        }
                                    }

                                    if (group === 'route') {
                                        barsRoutes = [item]
                                    }

                                    barsRoutes.sort((a, b) => {
                                        return (a.startTime || a.plannedStartTime) < (b.startTime || b.plannedStartTime) ? -1 : (a.startTime || a.plannedStartTime) > (b.startTime || b.plannedStartTime) ? 1 : 0
                                    })

                                    return barsRoutes.map((route) => {
                                        if (!route) {
                                            return
                                        }
                                        const startTime = moment(route.startTime || route.plannedStartTime, 'HH:mm').toDate()
                                        const endTime = moment(route.endTime || route.estimatedEndTime || route.plannedEndTime, 'HH:mm').toDate()

                                        const endsOnNextDay = endTime < startTime
                                        const endOfDay = moment().endOf('day').toDate()

                                        let statusColor

                                        if (!route.ready && !route.started && !route.completed) {
                                            statusColor = Colors.grey30
                                        }

                                        if (route.ready && !route.started && !route.completed) {
                                            statusColor = Colors.infoPale
                                        }

                                        if (route.started && !route.completed) {
                                            statusColor = Colors.infoLight
                                        }

                                        if (route.completed) {
                                            statusColor = Colors.successLight
                                        }

                                        const uniqueColli = route.colli?.filter((c, index, self) => c.barcode ? index === self.findIndex((collo) => collo.barcode === c.barcode) : true)

                                        const amountOfColli = uniqueColli?.length || 0

                                        const vehicleType = reseller.settings.vehicleTypes.find((vehicleType) => vehicleType._id === route.vehicleTypeId)

                                        const vehicle = vehicles.find((vehicle) => vehicle._id === route.vehicleId)

                                        const tooMuchColli = _.some(vehicleType.payloads, (payload) => {
                                            if (payload.payloadType === 'colli') {
                                                if (payload.payloadColliType) {
                                                    const amountOfColliType = uniqueColli?.filter((collo) => collo.type === payload.payloadColliType).length
                                                    return payload.payload > 0 && amountOfColliType > payload.payload
                                                } else {
                                                    return payload.payload > 0 && amountOfColli > payload.payload
                                                }
                                            }
                                        })

                                        let totalWeight = 0

                                        uniqueColli?.forEach((collo) => {
                                            totalWeight += collo.weight
                                        })

                                        const tooHeavy = _.some(vehicleType.payloads, (payload) => {
                                            if (payload.payloadType === 'weight') {
                                                return payload.payload > 0 && totalWeight > payload.payload
                                            }
                                        })

                                        const tooFar = vehicleType?.range && vehicleType.range > 0 && route.distance > vehicleType.range

                                        let noVehicleSubtext

                                        if (group !== 'vehicle' && (route.vehicleTypeId || route.vehicleId)) {
                                            const vehicleType = reseller.settings.vehicleTypes.find((vehicleType) => vehicleType._id === route.vehicleTypeId)
                                            const vehicle = vehicles.find((vehicle) => vehicle._id === route.vehicleId)
                                            const nameText = vehicle?.name || vehicleType?.name || ''

                                            noVehicleSubtext = (
                                                <span style={{fontStyle: 'italic', marginLeft: 12}}>({nameText})</span>
                                            )
                                        }

                                        const thisRouteStart = route.plannedStartTime || route.startTime
                                        const thisRouteEnd = route.estimatedEndTime || route.endTime

                                        const routeOverlaps = vehicle && Object.values(routes).filter((r) => !r.completed && !route.completed && r._id !== route._id && r.vehicleId === route.vehicleId).some((r) => {
                                            const startTime = r.plannedStartTime || r.startTime
                                            const endTime = r.estimatedEndTime || r.endTime

                                            return moment(thisRouteStart, 'HH:mm').isBetween(moment(startTime, 'HH:mm'), moment(endTime, 'HH:mm'), 'minute', '[)') || moment(thisRouteEnd, 'HH:mm').isBetween(moment(startTime, 'HH:mm'), moment(endTime, 'HH:mm'), 'minute', '(]')
                                        })

                                        const messengerRoutes = Object.values(routes).filter((r) => route.messengerId && r.messengerId === route.messengerId && r._id !== route._id && (!r.completed && !route.completed))

                                        const messengerOverlaps = messengerRoutes.some((r) => {
                                            const startTime = r.plannedStartTime || r.startTime
                                            const endTime = r.estimatedEndTime || r.endTime

                                            return moment(thisRouteStart, 'HH:mm').isBetween(moment(startTime, 'HH:mm'), moment(endTime, 'HH:mm'), 'minute', '[)') || moment(thisRouteEnd, 'HH:mm').isBetween(moment(startTime, 'HH:mm'), moment(endTime, 'HH:mm'), 'minute', '(]')
                                        })

                                        return (
                                            <g
                                                key={`${route._id}-${index}`}
                                                className='bar'
                                                style={{transform: `translate(${280 + xScale(startTime)}px, ${yScale(`${item.name}-${index}`) + 2}px`, opacity: routeHover && routeHover !== route._id ? 0.5 : 1, height: rowHeight, cursor: 'pointer'}}
                                                data-start={startTime}
                                                data-end={endTime}
                                                data-item={item.name}
                                                onMouseEnter={() => setRouteHover(route._id)}
                                                onMouseLeave={() => setRouteHover(null)}
                                                onClick={() => {
                                                    const routeListItem = document.querySelector(`[data-route-id="${route._id}"]`)
                                                    routeListItem.scrollIntoView({behavior: 'smooth', block: 'start', inline: 'nearest'})
                                                    routeListItem.classList.add('selected')
                                                    setTimeout(() => {
                                                        routeListItem.classList.remove('selected')
                                                    }, 2500)
                                                }}
                                            >
                                                <rect
                                                    height={rowHeight}
                                                    width={xScale(endsOnNextDay ? endOfDay : endTime) - xScale(startTime) || undefined}
                                                    fill='transparent'
                                                />
                                                <rect
                                                    className='barWidth'
                                                    width={(xScale(endsOnNextDay ? endOfDay : endTime) - xScale(startTime)) + 1 || undefined}
                                                    height={24}
                                                    fill={statusColor}
                                                    rx={4}
                                                    ry={4}
                                                    stroke={Colors.white}
                                                    strokeWidth={2}
                                                    data-start={startTime}
                                                    data-end={endTime}
                                                    data-item={item.name}
                                                />
                                                <foreignObject
                                                    className='barWidth'
                                                    x={0}
                                                    y={0}
                                                    width={xScale(endsOnNextDay ? endOfDay : endTime) - xScale(startTime) || undefined}
                                                    height={24}
                                                    data-start={startTime}
                                                    data-end={endTime}
                                                    data-vehicle={item.name}
                                                >
                                                    <div className='barContent' style={{display: 'flex', alignItems: 'center', height: '100%', gap: 12, paddingLeft: 12}}>
                                                        <Icon icon='mdi mdi-circle' style={{color: route.color, marginRight: 0}} />

                                                        {(tooMuchColli || tooHeavy || tooFar || routeOverlaps || messengerOverlaps) ?
                                                                <Icon icon='mdi mdi-alert' style={{color: Colors.errorBright, marginRight: 0}} /> :
                                                            ''
                                                        }

                                                        <P ellipsis style={{color: Colors.textDark}}>
                                                            {group === 'route' ? route.messengerName : route.name}
                                                            {group === 'vehicle' && route.messengerId && route.messengerName &&
                                                                <span style={{fontStyle: 'italic', marginLeft: 12}}>({route.messengerName})</span>
                                                            }
                                                            {noVehicleSubtext}
                                                        </P>
                                                    </div>
                                                </foreignObject>
                                            </g>
                                        )
                                    })
                                })}
                            </g>
                            <g className='y-axis' transform='translate(0,0)'>
                                {[...groupedData, ...Array(emptyRows).fill('').map((_, i) => `empty-${i}`)].map((item, i) => {
                                    let iconComp = null
                                    let notAvailableTodayComp
                                    const name = item?.name || ''

                                    if (group === 'vehicle') {
                                        const isVehicle = item && typeof item !== 'string' && 'typeId' in item
                                        const vehicleType = isVehicle ? reseller.settings.vehicleTypes.find((vt) => vt._id === item.typeId) : item
                                        iconComp = <Icon icon={`mdi mdi-${vehicleType?.icon}`} style={{fontSize: 20, height: 24, color: Colors.textDark}} />

                                        const notAvailableToday = item.notAvailableDateTimeRanges?.some((range) => moment().isBetween(range.start, range.end, 'day', '[]'))

                                        if (notAvailableToday) {
                                            notAvailableTodayComp = <Icon icon='mdi mdi-cancel' style={{fontSize: 20, height: 24, marginLeft: 'auto', color: Colors.textDark}} />
                                        }
                                    }

                                    return (
                                        <foreignObject
                                            key={item._id ? `${item._id}-${i}` : item.name ? `${item.name}-${i}` : `empty-${i}`}
                                            x={0}
                                            y={yScale(item?.name ? `${item.name}-${i}` : `empty-${i}`)}
                                            width={280}
                                            height={31}
                                        >
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    gap: 12,
                                                    alignItems: 'center',
                                                    background: item.notAvailableToday ? Colors.grey20 : Colors.white,
                                                    borderRadius: '4px 0 0 4px',
                                                    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                                                    paddingLeft: 6,
                                                    paddingRight: 4,
                                                    maxWidth: 280,
                                                    height: 27,
                                                    borderRight: `4px solid ${Colors.grey30}`
                                                }}
                                            >
                                                {iconComp}
                                                <P style={{color: Colors.textDark, maxWidth: 280}} ellipsis>{name === 'no-messenger' ? '' : name}</P>
                                                {notAvailableTodayComp}
                                            </div>
                                        </foreignObject>
                                    )
                                })}
                            </g>
                        </g>
                    </svg>
                </div>
                <div className='bottom' style={{position: 'relative', height: 85.3, zIndex: 100000, display: 'flex', alignItems: 'center'}}>
                    <Row style={{width: 280, alignItems: 'center'}}>
                        <Select
                            style={{display: collapsed ? 'none' : 'inherit', width: 84, marginLeft: 0, marginBottom: 0}}
                            value={group}
                            options={[
                                {
                                    title: 'Per koerier',
                                    value: 'messenger',
                                    icon: 'mdi mdi-account'
                                },
                                ...reseller.permissions.vehicles ?
                                        [{
                                            title: 'Per voertuig',
                                            value: 'vehicle',
                                            icon: 'mdi mdi-bicycle-cargo'
                                        }] :
                                        [],
                                {
                                    title: 'Per route',
                                    value: 'route',
                                    icon: 'mdi mdi-map-marker-path'
                                }
                            ]}
                            onChange={(event) => setGroup(event.target.value)}
                            showOnlyIcon
                            smartPlacement
                        />
                        <Select
                            style={{width: 154, display: collapsed ? 'none' : 'inherit', marginLeft: 0, marginBottom: 0}}
                            label='Sorteren op'
                            value={sort}
                            options={sortOptions}
                            onChange={(event) => setSort(event.target.value)}
                            smartPlacement
                        />
                        <IconButton
                            icon={`mdi mdi-sort-${sortOrder === 'asc' ? 'ascending' : 'descending'}`}
                            onClick={() => setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc')}
                        />
                    </Row>
                    <svg style={{position: 'absolute', top: 0, left: 280, right: 0, height: '100%', width: !isNaN(width) ? width : undefined}}>
                        <g className='x-axis' transform='translate(12,0)' fontSize='10' textAnchor='middle'>
                            {ticks.map((tick, index) => {
                                const formattedTick = tickFormat(tick)
                                return (
                                    <g key={index} className='tick' data-hour={tick} transform={`translate(${xScale(tick) || 0}, 12)`}>
                                        <text style={{textAnchor: 'end', fontSize: 14, transform: 'rotate(65deg)'}} dx='3.5em' dy='0em' y='9'>{formattedTick}</text>
                                    </g>
                                )
                            })}
                            <rect width={!isNaN(width) ? width : undefined} height={83.5} fill='none' style={{pointerEvents: 'all'}}></rect>
                        </g>
                    </svg>
                    <div style={{marginLeft: 'auto', position: 'relative', zIndex: 100, backgroundColor: Colors.backgroundNeutral, height: '100%', display: 'flex', alignItems: 'center', paddingLeft: 6}}>
                        <Card shadow='true' style={{padding: 0, width: 'fit-content', height: 'fit-content'}}>
                            <Row>
                                <Button
                                    style={{marginLeft: 0, marginRight: 0}}
                                    variant='text'
                                    icon='mdi mdi-minus'
                                    onClick={() => {
                                        zoomRef.current.scaleBy(d3.select(svgChartRef.current).transition(), 0.5)
                                    }}
                                />

                                <Button
                                    style={{marginLeft: 0, marginRight: 0}}
                                    variant='text'
                                    icon='mdi mdi-plus'
                                    onClick={() => {
                                        zoomRef.current.scaleBy(d3.select(svgChartRef.current).transition(), 2)
                                    }}
                                />
                            </Row>
                        </Card>
                    </div>
                </div>
                {date === moment().format('YYYY-MM-DD') &&
                    <>
                        <div className='now-handle' style={{position: 'absolute', left: xScale(now) < width ? 280 + xScale(now) : undefined, top: 0, width: 1, height: availableHeight - 26 || undefined, zIndex: 9, borderRight: `2px dotted ${Colors.textDark}`, visibility: xScale(now) < 0 ? 'hidden' : 'visible'}} />
                        <div style={{position: 'absolute', left: xScale(now) < width ? 280 + xScale(now) : undefined, top: availableHeight - 26 || undefined, width: 8, height: 8, transform: 'translateX(-3px)', borderRadius: '50%', backgroundColor: Colors.textDark, visibility: xScale(now) < 0 ? 'hidden' : 'visible'}} />
                    </>
                }
            </div>
        </Panel>
    )
}
