import React from 'react'

import Reflux from 'reflux-react-16'
import moment from 'moment'
import _ from 'lodash'
import aSync from 'async'

import ParcelStore from '../../stores/ParcelStore.js'
import ParcelActions from '../../actions/ParcelActions.js'
import AddressStore from '../../stores/AddressStore.js'

import ViewParcelModal from '../../components/parcels/ViewParcelModal.js'
import EditParcelModal from '../../components/parcels/EditParcelModal.js'
import ImportParcels from '../../components/parcels/ImportParcels.js'
import NewParcel from '../../../server/functions/parcels/NewParcel.js'
import {Button, Colors, DropdownButton, IconButton, CarrierLogo, S2, P, Panel, Popup, PrintPopup, PrintModal, ChangeParcelStatusPopup, AlertPopup, Icon, ToolTip} from '../../components/UI/index.js'
import Table from '../../components/Table/index.js'
import SearchBar from '../../components/SearchBar/index.js'
import dbCodes from '../../../server/dbCodes.js'
import csvExport from '../../utils/csvExport.js'
import filter from '../../utils/filter.js'

class Parcels extends Reflux.Component {
    constructor(props) {
        super(props)

        this.state = {printerPageSize: localStorage.printerPageSize || 'A6'}

        this.stores = [ParcelStore, AddressStore]
    }

    componentDidUpdate() {
        if (this.state.parcelLimitWarning) {
            this.setState({parcelLimitWarning: false})
            this.alertPopup.open('Waarschuwing', 'Je huidige datumrange bevat meer dan 35 000 zendingen, hierdoor worden niet alle zendingen weergegeven. Verklein de datumrange om dit te voorkomen.')
        }
    }

    componentDidMount() {
        const {user} = this.props
        const {parcelEndDate} = this.state

        if (user.settings.parcels?.defaultDateRange === 'week') {
            const parcelStartDate = moment(parcelEndDate).subtract(1, 'week').format('YYYY-MM-DD')
            ParcelActions.setDates(parcelStartDate, parcelEndDate)
        }
    }

    columns() {
        return [
            {title: 'Datum', visible: true, width: 120, key: 'date', sort: (parcel) => {
                return `${parcel.date} ${parcel.timeConfirmed}`
            }, render: (parcel) => {
                return (
                    <div style={{width: '100%'}}>
                        <P ellipsis>{parcel.date ? moment(parcel.date).format('DD-MM-YYYY') : ''}</P>
                        <S2 ellipsis>{parcel.timeConfirmed}</S2>
                    </div>
                )
            }},
            {title: 'Adres', visible: true, sort: (parcel) => {
                const address = parcel.address
                const dashboardObj = localStorage._dashboard ? JSON.parse(localStorage._dashboard) : {}
                if (dashboardObj?.addressSortOption) {
                    return address[dashboardObj.addressSortOption]
                }
                return address.postalCode
            }, dropdown: {
                localStorageKey: 'addressSortOption',
                options: [
                    {
                        label: 'Naam',
                        value: 'name'
                    },
                    {
                        label: 'Straat',
                        value: 'street'
                    },
                    {
                        label: 'Huisnummer',
                        value: 'nr'
                    },
                    {
                        label: 'Postcode',
                        value: 'postalCode'
                    },
                    {
                        label: 'Plaats',
                        value: 'city'
                    }
                ],
                onClick: (value) => {
                    const dashboardObj = JSON.parse(localStorage._dashboard)
                    dashboardObj.addressSortOption = value
                    localStorage._dashboard = JSON.stringify(dashboardObj)
                }
            }, flex: 2, render: (parcel) => {
                const address = parcel.address

                return (
                    <div style={{width: '100%'}}>
                        <P ellipsis>{address.name}</P>
                        <div style={{display: 'flex', alignItems: 'center'}}>
                            {address.postalCode && address.country === 'NL' && address.showWarning &&
                                <ToolTip text='Adres niet gevonden. Controleer de gegevens voor je bevestigt.' fixedPosition>
                                    <Icon size='md' style={{color: Colors.errorBright}} icon='mdi mdi-alert' />
                                </ToolTip>
                            }
                            <S2 ellipsis>{`${address.street} ${address.nr}${address.addition} ${address.postalCode} ${address.city}`}</S2>
                        </div>
                    </div>
                )
            }},
            {title: 'Land', visible: true, width: 60, key: 'address.country'},
            {title: 'Referentie', visible: true, width: 200, key: 'reference'},
            {title: 'Bezorginstructie', visible: false, flex: 1, key: 'comment'},
            {title: 'Opties', visible: true, width: 250, key: 'options', sort: (parcel) => {
                return parcel.options.join(', ')
            }, render: (parcel) => {
                return parcel.options.join(', ')
            }},
            {title: 'Gewicht', visible: false, width: 100, key: 'weight', render: (parcel) => {
                return `${parcel.weight || 0} g`
            }},
            {title: 'Prijs', visible: false, width: 100, key: 'price', sort: (parcel) => {
                let price = parseFloat(parcel.price.replace(',', '.'))

                parcel.fees.map((fee) => {
                    if (fee.price) {
                        price += parseFloat(fee.price.replace(',', '.')) * fee.quantity
                    }
                })

                return price
            }, render: (parcel) => {
                if (parcel.price === 'priceScale') {
                    if (parcel.priceScaleRange) {
                        return <P>€{parcel.priceScaleRange.min} - €{parcel.priceScaleRange.max}</P>
                    }
                    return <P>n.t.b.</P>
                }

                let price = parseFloat(parcel.price.replace(',', '.'))

                parcel.fees.map((fee) => {
                    if (fee.price) {
                        price += parseFloat(fee.price.replace(',', '.')) * fee.quantity
                    }
                })

                return `€ ${price.toFixed(2).replace('.', ',')}`
            }},
            {title: 'Vervoerder', visible: true, width: 150, key: 'carrier', render: (parcel) => {
                return <CarrierLogo size={35} carriers={this.state.carriers} value={parcel.carrier} />
            }},
            {title: 'Track & Trace', visible: true, width: 220, key: 'trackTrace', onClick: (parcel) => {
                if (parcel.options.includes('Brievenbuspakket zonder Track & Trace')) {
                    return
                }

                if (parcel.trackTraceLink.indexOf('veloyd') > -1) {
                    window.open(`/track&trace/${parcel.trackTrace}`)
                } else {
                    window.open(parcel.trackTraceLink)
                }
            }},
            {title: 'Externe ID', visible: false, width: 100, key: 'externId', sort: (parcel) => {
                if (['GLS', 'Skynet'].includes(parcel.carrier)) {
                    return parcel.externId
                }
            }, render: (parcel) => {
                if (['GLS', 'Skynet'].includes(parcel.carrier)) {
                    return parcel.externId
                }
            }},
            {title: 'Koppeling', visible: false, width: 150, key: 'plugin', render: (parcel) => {
                let plugin = parcel.plugin || parcel.webshop || ''

                if (plugin && !['API', 'CSV'].includes(plugin)) {
                    plugin = _.capitalize(plugin)
                }

                return plugin
            }},
            {title: 'Status', visible: true, width: 150, key: 'status', render: (parcel) => {
                return dbCodes.parcelStatus[parcel.status]
            }},
            {title: '', visible: true, showOnHover: true, key: '', width: 60, onClick: () => {}, render: (parcel) => {
                if (parcel.status === dbCodes.parcelStatus.aangemaakt()) {
                    return (
                        <IconButton onClick={() => this.editParcelModal.open(parcel)}>
                            <i className='mdi mdi-pencil' />
                        </IconButton>
                    )
                } else {
                    return (
                        <IconButton onClick={() => this.viewParcelModal.open(parcel._id)}>
                            <i className='mdi mdi-eye' />
                        </IconButton>
                    )
                }
            }}
        ]
    }

    onChangeDates(parcelStartDate, parcelEndDate) {
        ParcelActions.setDates(parcelStartDate, parcelEndDate)
    }

    onChangeQueries(parcelQueries) {
        this.table.selectPage(1)
        ParcelActions.setQueries(parcelQueries)
    }

    onChangeTable(selectedParcels) {
        ParcelActions.setSelected(selectedParcels)
    }

    onClickNew() {
        this.editParcelModal.open()
    }

    onClickGetOrdersFromWebshop() {
        this.popup.open('Importeren vanuit webshop', 'Bestellingen in je webshop worden geimporteerd.', () => {
            ParcelActions.getOrdersFromWebshop((err, response) => {
                if (err) {
                    this.popup.setError(err)
                } else {
                    this.popup.setMessage(`${response.ids.length} nieuwe ${response.ids.length === 1 ? 'zending' : 'zendingen'} aangemaakt.`)
                    this.popup.setErrors(response.errors)
                }
            })
        })
    }

    onClickExport() {
        const {selectedParcels, parcels, parcelStartDate, parcelEndDate, carriers} = this.state

        const selected = selectedParcels.map((id) => {
            return _.find(parcels, {_id: id})
        })

        csvExport.parcels(true, selected, parcelStartDate, parcelEndDate, null, null, carriers)
        ParcelActions.setSelected([])
    }

    async onClickCopy(id, multiple) {
        const {user} = this.props

        if (id) {
            let parcel = _.find(this.state.parcels, {_id: id})

            parcel = JSON.parse(JSON.stringify(parcel))

            const copyParcel = () => {
                const newParcel = NewParcel()

                newParcel.customer = parcel.customer
                newParcel.contact = parcel.contact
                newParcel.customerAddress = user.address
                newParcel.address = parcel.address
                newParcel.emailTT = parcel.emailTT
                newParcel.reference = parcel.reference
                newParcel.comment = parcel.comment
                newParcel.weight = parcel.weight
                newParcel.height = parcel.height
                newParcel.width = parcel.width
                newParcel.length = parcel.length
                newParcel.options = parcel.options

                if (parcel.cover) {
                    newParcel.cover = parcel.cover
                }
                if (parcel.deliveryTime) {
                    newParcel.deliveryTime = parcel.deliveryTime
                }
                if (parcel.pickupDate) {
                    newParcel.pickupDate = parcel.pickupDate
                }
                if (parcel.pickupAddress) {
                    newParcel.pickupAddress = parcel.pickupAddress
                }
                if (parcel.pickupPoint) {
                    newParcel.pickupPoint = parcel.pickupPoint
                }

                newParcel.carrier = parcel.carrier

                if (parcel.customs) {
                    newParcel.customs = parcel.customs
                }

                return newParcel
            }

            if (multiple) {
                const popupInput = {
                    label: 'Aantal kopieën',
                    value: 1,
                    type: 'number',
                    min: 1,
                    max: 99

                }
                this.popup.setInput(popupInput)
                const inputValue = await this.popup.open('Kopieer meerdere keren', '')

                popupInput.value = inputValue
                popupInput.disabled = true
                this.popup.setInput(popupInput)

                this.popup.setProgress(0, inputValue)

                let success = 0
                const errors = []

                for (let i = 0; i < inputValue; i++) {
                    const newParcel = copyParcel()

                    await new Promise((resolve) => {
                        ParcelActions.create(newParcel, (err) => {
                            if (err) {
                                errors.push(err)
                            } else {
                                success += 1
                            }

                            this.popup.setProgress(success + errors.length, inputValue)
                            resolve()
                        })
                    })
                }
                this.popup.setMessage(`${success} ${success === 1 ? 'zending' : 'zendingen'} gekopieerd.`)
                this.popup.setErrors(errors)
            } else {
                const newParcel = copyParcel()

                this.editParcelModal.open(newParcel)
            }

            ParcelActions.setSelected([])
        }
    }

    onClickRemove() {
        const {selectedParcels} = this.state

        this.popup.open('Zendingen verwijderen', 'Zendingen met status Aangemaakt worden verwijderd.', () => {
            ParcelActions.setSelected([])

            let success = 0
            const errors = []

            this.popup.setProgress(0, selectedParcels.length)

            aSync.eachSeries(selectedParcels, (id, next) => {
                ParcelActions.remove(id, (err) => {
                    if (err) {
                        errors.push(err)
                    } else {
                        success += 1
                    }

                    this.popup.setProgress(success + errors.length, selectedParcels.length)

                    next()
                })
            }).then(() => {
                this.popup.setMessage(`${success} ${success === 1 ? 'zending' : 'zendingen'} verwijderd.`)
                this.popup.setErrors(errors)
            })
        })
    }

    onClickCancel() {
        const {selectedParcels} = this.state

        this.popup.open('Zendingen annuleren', 'Zendingen met status Bevestigd worden geannuleerd.', () => {
            ParcelActions.setSelected([])

            let success = 0
            const errors = []

            this.popup.setProgress(0, selectedParcels.length)

            aSync.eachSeries(selectedParcels, (id, next) => {
                ParcelActions.cancel(id, (err) => {
                    if (err) {
                        errors.push(err)
                    } else {
                        success += 1
                    }

                    this.popup.setProgress(success + errors.length, selectedParcels.length)

                    next()
                })
            }).then(() => {
                this.popup.setMessage(`${success} ${success === 1 ? 'zending' : 'zendingen'} geannuleerd.`)
                this.popup.setErrors(errors)
            })
        })
    }

    onClickPrintLabels(id) {
        const {selectedParcels, parcels, printerPageSize} = this.state

        const ids = (id && typeof id === 'string') ? [id] : selectedParcels

        const batchAmount = 50
        if (ids.length > batchAmount) {
            const batches = _.chunk(ids, batchAmount)

            this.printPopup.open('Labels printen', `Er zijn meer dan ${batchAmount} zendingen geselecteerd. Hierdoor zullen de labels per set van ${batchAmount} worden opgevraagd en gedownload. Zie je download folder om de labels te openen.`, printerPageSize, (nrOfEmptyLabels) => {
                const printCallback = async () => {
                    this.popup.close()
                    ParcelActions.setSelected([])

                    let batchIndex = 0

                    for (const batch of batches) {
                        this.printPopup.setProgress({percentage: 0, message: `Label set ${batchIndex + 1} van ${batches.length}`})

                        await new Promise((resolve, reject) => {
                            ParcelActions.printLabels(batch, nrOfEmptyLabels, (err, pdf, errors) => {
                                if (err) {
                                    this.printPopup.setError(err)
                                    reject(err)
                                } else {
                                    if (errors.length) {
                                        this.printPopup.setErrors(errors)
                                    }

                                    batchIndex += 1
                                    this.printPopup.setProgress({percentage: 100, message: `Label set ${batchIndex} van ${batches.length}`})

                                    resolve()

                                    if (pdf) {
                                        const aLink = document.createElement('a')
                                        aLink.href = `data:application/pdf;base64,${pdf}`
                                        aLink.download = `labels-${moment().format('YYYY-MM-DD')}-${batchIndex}.pdf`
                                        aLink.click()
                                        aLink.remove()
                                    }
                                }
                            })
                        })
                    }
                    setTimeout(() => {
                        this.printPopup.close()
                    }, 1000)
                }

                const someIncorrectAddresses = parcels.filter((parcel) => ids.includes(parcel._id)).some(({address}) => address.postalCode && address.country === 'NL' && address.showWarning)
                if (someIncorrectAddresses) {
                    this.printPopup.close()
                    this.popup.open('Foutieve adressen', undefined, () => {
                        this.printPopup.open('Labels printen', 'Zendingen worden bevestigd en kunnen niet meer worden gewijzigd.', printerPageSize, undefined, undefined, true)
                        printCallback()
                    })
                    this.popup.setWarning('Eén of meer van de geselecteerde zendingen heeft een onbekende combinatie van postcode/straatnaam + huisnummer. Wil je toch doorgaan met het printen van de labels?')
                } else {
                    printCallback()
                }
            }, undefined, true)
            return
        }

        this.printPopup.open('Labels printen', 'Zendingen worden bevestigd en kunnen niet meer worden gewijzigd.', printerPageSize, (nrOfEmptyLabels) => {
            const printCallback = () => {
                this.popup.close()
                ParcelActions.setSelected([])

                ParcelActions.printLabels(ids, nrOfEmptyLabels, (err, pdf, errors) => {
                    if (err) {
                        this.printPopup.setError(err)
                    } else {
                        if (errors.length) {
                            this.printPopup.setErrors(errors)
                        } else {
                            this.printPopup.close()
                        }

                        if (pdf) {
                            this.printModal.open(pdf)
                        }
                    }
                })
            }

            const someIncorrectAddresses = parcels.filter((parcel) => ids.includes(parcel._id)).some(({address}) => address.postalCode && address.country === 'NL' && address.showWarning)
            if (someIncorrectAddresses) {
                this.printPopup.close()
                this.popup.open('Foutieve adressen', undefined, () => {
                    this.printPopup.open('Labels printen', 'Zendingen worden bevestigd en kunnen niet meer worden gewijzigd.', printerPageSize)
                    printCallback()
                })
                this.popup.setWarning('Eén of meer van de geselecteerde zendingen heeft een onbekende combinatie van postcode/straatnaam + huisnummer. Wil je toch doorgaan met het printen van de labels?')
            } else {
                printCallback()
            }
        })
    }

    onChangePrinterPageSize(event) {
        localStorage.printerPageSize = event.target.value
        this.setState({printerPageSize: event.target.value})
    }

    onOpenChangeParcelStatus() {
        const {selectedParcels} = this.state
        this.changeParcelStatusPopup.open((newStatus) => {
            this.setState({loading: true})
            let success = 0
            const errors = []

            this.changeParcelStatusPopup.setProgress(0, selectedParcels.length)

            ParcelActions.setSelected([])
            aSync.eachSeries(selectedParcels, (id, next) => {
                if (this.changeParcelStatusPopup?.state?.stopOperation) return
                ParcelActions.changeStatus(id, newStatus, (err) => {
                    if (err) {
                        errors.push(err)
                    } else {
                        success += 1
                    }

                    this.changeParcelStatusPopup.setProgress(success + errors.length, selectedParcels.length)

                    next()
                })
            }).then(() => {
                this.changeParcelStatusPopup.setMessage(`${success} ${success === 1 ? 'zending' : 'zendingen'} gewijzigd.`)
                this.changeParcelStatusPopup.setErrors(errors)
            })
        })
    }

    render() {
        const {parcelStartDate, parcelEndDate, selectedParcels, parcelsLoading, parcelQueries, carriers, addressBook, printerPageSize} = this.state
        const {reseller, user, history} = this.props
        const parcels = filter.parcels(this.state.parcels, parcelQueries, carriers)

        return (
            <div style={{display: 'flex', flexDirection: 'column', height: '100%'}}>
                <Panel style={{display: 'flex', background: Colors.backgroundNeutral, padding: '20px 10px 10px 10px', borderTop: 'none'}}>

                    <SearchBar
                        columns={this.columns()}
                        onQueryChange={this.onChangeQueries.bind(this)}
                        onDateChange={this.onChangeDates.bind(this)}
                        startDate={parcelStartDate}
                        endDate={parcelEndDate}
                        queries={parcelQueries}
                    />

                    <Button
                        variant='outline-white'
                        onClick={this.onClickNew.bind(this)}
                    >
                        Nieuwe Zending
                    </Button>

                    {user.settings.webshops && Object.keys(user.settings.webshops).some((webshop) => user.settings.webshops[webshop].active) &&
                        <Button
                            variant='outline-white'
                            onClick={this.onClickGetOrdersFromWebshop.bind(this)}
                            tooltip='Importeer vanuit webshop'
                        >
                            <i className='mdi mdi-refresh' />
                        </Button>
                    }

                    <Button
                        variant='outline-white'
                        onClick={() => this.importParcelsModal.open()}
                        tooltip='Importeer zendingen'
                    >
                        <i className='mdi mdi-file-import' />
                    </Button>

                    {selectedParcels.length > 0 &&
                        <>

                            <Button
                                variant='outline-white'
                                onClick={this.onClickExport.bind(this)}
                                tooltip='Exporteer zendingen'
                            >
                                <i className='mdi mdi-file-export' />
                            </Button>

                            {selectedParcels.length === 1 &&
                                <DropdownButton
                                    variant='outline-white'
                                    onClick={this.onClickCopy.bind(this, selectedParcels[0], false)}
                                    tooltip='Kopieer zending'
                                    options={[
                                        {title: 'Kopieer meerdere keren', action: () => {
                                            this.onClickCopy(selectedParcels[0], true)
                                        }}
                                    ]}
                                >
                                    <i className='mdi mdi-content-copy' />
                                </DropdownButton>
                            }

                            <Button
                                variant='outline-white'
                                onClick={this.onClickRemove.bind(this)}
                                tooltip='Verwijder zendingen'
                            >
                                <i className='mdi mdi-delete' />
                            </Button>

                            <Button
                                variant='outline-white'
                                onClick={this.onClickCancel.bind(this)}
                                tooltip='Annuleer zendingen'
                            >
                                <i className='mdi mdi-close-circle' />
                            </Button>

                            <DropdownButton
                                variant='outline-white'
                                value={printerPageSize}
                                options={[
                                    {title: 'Papierformaat A6', value: 'A6'},
                                    {title: 'Papierformaat 10x21', value: '10x21'},
                                    {title: 'Papierformaat A5', value: 'A5'},
                                    {title: 'Papierformaat A4 | 4x A6', value: 'A4'},
                                    {title: 'Papierformaat A4 | 3x 10x21', value: 'A410x21'},
                                    {title: 'Papierformaat A4 | 2x A5', value: 'A4A5'}
                                ]}
                                onChange={this.onChangePrinterPageSize.bind(this)}
                                onClick={this.onClickPrintLabels.bind(this)}
                                tooltip='Print zending labels'
                            >
                                <i className='mdi mdi-printer' />
                            </DropdownButton>

                            <Button
                                variant='outline-white'
                                onClick={this.onOpenChangeParcelStatus.bind(this)}
                            >
                                Status wijzigen
                            </Button>
                        </>
                    }
                </Panel>

                <div style={{flex: 1, marginTop: 24, marginRight: 24, marginLeft: 24}}>
                    <Table
                        tableName='customerDashboard'
                        columns={this.columns()}
                        rows={parcels}
                        selectedRows={selectedParcels}
                        loading={parcelsLoading}
                        onChange={this.onChangeTable.bind(this)}
                        ref={(ref) => this.table = ref}
                    />
                </div>

                <ImportParcels
                    reseller={reseller}
                    user={user}
                    carriers={carriers}
                    ref={(ref) => this.importParcelsModal = ref}
                />

                <ViewParcelModal
                    history={history}
                    match={this.props.match}
                    reseller={reseller}
                    user={user}
                    carriers={carriers}
                    onClickPrintLabels={this.onClickPrintLabels.bind(this)}
                    onClickCopy={this.onClickCopy.bind(this)}
                    ref={(modal) => this.viewParcelModal = modal}
                />

                <EditParcelModal
                    reseller={reseller}
                    user={user}
                    carriers={carriers}
                    addressBook={addressBook}
                    printModal={this.printModal}
                    ref={(modal) => this.editParcelModal = modal}
                />

                <Popup ref={(modal) => this.popup = modal} />
                <AlertPopup ref={(modal) => this.alertPopup = modal} />
                <PrintPopup ref={(ref) => this.printPopup = ref} />
                <PrintModal ref={(ref) => this.printModal = ref} />
                <ChangeParcelStatusPopup ref={(ref) => this.changeParcelStatusPopup = ref} />
            </div>
        )
    }
}

export default (Parcels)
