// @ts-check
import React, {useState, useEffect, useRef, ReactNode} from 'react'
import moment from 'moment'

import {Button, Colors, IconButton, P, Panel, Popup, S2, Toggle, Icon, Link, Alert, S1} from '../../components/UI/index.js'
import useVehicleStore from '../../stores/VehicleStore.js'
import Table from '../../components/Table/index.js'
import EditVehicleModal from '../../components/vehicles/EditVehicleModal.js'
import {newVehicle} from '../../components/vehicles/newVehicle.js'
import SearchBar from '../../components/SearchBar/index.js'
import {useHistory} from 'react-router-dom'
import {Reseller} from '../../../server/functions/resellers/resellers.types.js'
import {User} from '../../../server/functions/users/users.types.js'
import {Vehicle} from '../../../server/functions/vehicles/vehicles.types.js'
import dbCodes from '../../../server/dbCodes.js'

interface VehiclesProps {
    reseller: Reseller
    user: User
}

/**
 * @type {{[key in RouteProfile]: string}}
 */
export const routeProfileMap = {
    car: 'Auto',
    bike: 'Fiets',
    walk: 'Lopen'
}

/**
 * @type {{[key in FuelType]: string}}
 */
export const fuelTypeMap = {
    'green-electric': 'Groene stroom',
    'grey-electric': 'Grijze stroom',
    'unknown-electric': 'Stroom onbekend',
    'bio-diesel': 'Bio diesel',
    'diesel': 'Diesel',
    'lpg': 'Benzine',
    'hydrogen': 'Waterstof',
    'none': 'Geen'
}

/**
 * @type {{[key in PayloadType]: string}}
 */
export const payloadTypeMap = {
    colli: 'Colli',
    weight: 'kg'
}

/**
 *
 * @param {VehicleType[]} vehicleTypes
 * @param {(vehicleId: Vehicle['_id']) => void} onEditVehicle
 *
 * @returns {{[key: string]: any; render?: (vehicle: Vehicle) => import('react').ReactNode}[]}
 */
const columns = (vehicleTypes: Reseller['settings']['vehicleTypes'], onEditVehicle: (vehicleId: string) => void): {
    [key: string]: any
    render?: (vehicle: Vehicle) => ReactNode
}[] => {
    return [
        {title: '', visible: true, width: 24, key: 'notAvailableDateTimeRange', render: (vehicle) => {
            const isInRange = vehicle.notAvailableDateTimeRanges?.some((range) => moment().isBetween(range.start, range.end, 'day', '[]'))

            return isInRange ? <i style={{fontSize: 24}} className='mdi mdi-cancel' /> : null
        }},
        {title: 'Icoon', visible: true, width: 64, key: 'icon', render: (vehicle) => {
            const vehicleType = vehicleTypes.find((type) => type._id === vehicle.typeId)

            return <Icon icon={`mdi mdi-${vehicleType?.icon}`} />
        }},
        {title: 'Voertuigsoort', visible: true, width: 120, key: 'typeId', render: (vehicle) => {
            const vehicleType = vehicleTypes.find((vehicleType) => vehicleType._id === vehicle.typeId)

            return vehicleType?.name || ''
        }},
        {title: 'Naam', visible: true, width: 250, key: 'name'},
        {title: 'Route berekening', visible: false, width: 200, key: 'routeProfile', render: (vehicle) => {
            const vehicleType = vehicleTypes.find((type) => type._id === vehicle.typeId)

            return routeProfileMap[vehicleType?.routeProfile] || ''
        }},
        {title: 'Snelheidsafwijking', visible: true, width: 200, key: 'speedDifference', render: (vehicle) => {
            const vehicleType = vehicleTypes.find((type) => type._id === vehicle.typeId)

            if (!vehicleType?.speedDifferenceEnabled) {
                return ''
            }

            const speedDifference = vehicleType?.speedDifference || 0

            return speedDifference ? `${speedDifference}% ${vehicleType?.speedDifferenceMode === 'faster' ? 'sneller' : 'langzamer'}` : ''
        }},
        {title: 'Aandrijving', visible: false, width: 200, key: 'fuelType', render: (vehicle) => {
            const vehicleType = vehicleTypes.find((type) => type._id === vehicle.typeId)

            return fuelTypeMap[vehicleType?.fuelType] || ''
        }},
        {title: 'Bereik', visible: true, width: 120, key: 'range', render: (vehicle) => {
            const vehicleType = vehicleTypes.find((type) => type._id === vehicle.typeId)

            return vehicleType?.range ? `${Math.round(((vehicleType.range) / 1000))} km` : ''
        }},
        {title: 'Laadvermogen', visible: true, width: 120, key: 'payload', render: (vehicle) => {
            const vehicleType = vehicleTypes.find((type) => type._id === vehicle.typeId)

            if (!vehicleType?.payloads || vehicleType?.payloads.length === 0) {
                return ''
            }

            const payloadsTexts = vehicleType.payloads.map((payload) => {
                let text = ''

                if (payload.payload === 0) {
                    return text
                }

                if (payload.payloadType === 'weight') {
                    text += `${(payload.payload / 1000).toFixed(0)}`
                } else {
                    text += payload.payload
                }

                text += ` ${payload.payloadType === 'colli' && payload.payloadColliType ? dbCodes.colloTypes[payload.payloadColliType] : payloadTypeMap[payload.payloadType] || ''}`

                return text
            })

            return payloadsTexts.join(', ')
        }},
        {title: 'Stoptijd', visible: true, width: 120, key: 'stopTime', render: (vehicle) => {
            const vehicleType = vehicleTypes.find((type) => type._id === vehicle.typeId)
            const stopTime = vehicleType?.stopTime

            return stopTime ? `${stopTime} s` : ''
        }},
        {title: 'Opmerking voor koerier', visible: true, flex: 1, key: 'comment', render: (vehicle) => {
            const vehicleType = vehicleTypes.find((type) => type._id === vehicle.typeId)

            return vehicle.comment || vehicleType?.comment || ''
        }},
        {title: 'Notitie', visible: false, flex: 1, key: 'notes', render: (vehicle) => {
            const vehicleType = vehicleTypes.find((type) => type._id === vehicle.typeId)

            return vehicle.notes || vehicleType?.notes || ''
        }},
        {title: 'Laatste notitie', visible: false, flex: 1, key: 'notesEntries', render: (vehicle) => {
            if (!vehicle.notesEntries || vehicle.notesEntries.length === 0) {
                return ''
            }
            const latestEntry = vehicle.notesEntries?.toSorted((a, b) => moment(b.date).diff(moment(a.date)))[0]

            return (
                <div style={{width: '100%'}}>
                    <div style={{display: 'flex', alignItems: 'center'}}>
                        <P ellipsis>{moment(latestEntry.date).format('DD-MM-YYYY')}</P>
                    </div>
                    <S2 style={{marginRight: 15}}>{latestEntry.note}</S2>
                </div>
            )
        }},
        // {title: 'Weergave volgorde', info: 'Dit is de volgorde waarin de voertuigen gesorteerd kunnen worden in de planning.', visible: false, width: 200, key: 'order'},
        {title: 'Niet beschikbaar', visible: true, width: 200, key: 'notAvailableDateTimeRange', render: (vehicle) => {
            const futureRanges = vehicle.notAvailableDateTimeRanges?.filter((range) => moment().isSameOrBefore(range.end, 'day')).toSorted((a, b) => a.start.localeCompare(b.start))
            const inFutureRange = futureRanges?.[0]

            return inFutureRange ?
                    (
                        <div style={{width: '100%'}}>
                            <div style={{display: 'flex', alignItems: 'center'}}>
                                <P ellipsis>{moment(inFutureRange.end).isSame(moment(inFutureRange.start), 'day') ? moment(inFutureRange.start).format('DD-MM-YYYY') : `${moment(inFutureRange.start).format('DD-MM-YYYY')} t/m ${moment(inFutureRange.end).format('DD-MM-YYYY')}`}</P>
                                {futureRanges.length > 1 &&
                                    <S1 style={{marginRight: 15}}>+ {futureRanges.length - 1}</S1>
                                }
                            </div>
                            <S2 ellipsis>{inFutureRange.reason}</S2>
                        </div>
                    ) :
                ''
        }},
        {title: 'Gearchiveerd', visible: false, key: 'isArchived', width: 100, render: (vehicle) => {
            return vehicle.isArchived ? 'Ja' : 'Nee'
        }},
        {visible: true, showOnHover: true, width: 42, onClick: () => {}, render: (vehicle) => {
            return (
                <IconButton onClick={() => onEditVehicle(vehicle._id)} icon='mdi mdi-pencil' />
            )
        }}
    ]
}

/**
 * @typedef {object} VehiclesProps
 * @property {Reseller} reseller
 * @property {User} user
 */

/**
 * @component
 *
 * @param {VehiclesProps} props
 * @returns {JSX.Element}
 */
export default function Vehicles({reseller, user}: VehiclesProps) {
    const vehicles = useVehicleStore((state) => state.vehicles)
    const getVehicles = useVehicleStore((state) => state.getVehicles)
    const createVehicle = useVehicleStore((state) => state.createVehicle)
    const updateVehicles = useVehicleStore((state) => state.updateVehicles)
    const [selectedVehicles, setSelectedVehicles] = useState([])
    const [showArchived, setShowArchived] = useState(false)
    const [editVehicleModalOpen, setEditVehicleModalOpen] = useState(false)
    /**
    * @type {ReturnType<typeof useState<Vehicle>>}
    */
    const [activeVehicle, setActiveVehicle] = useState(null)
    const [editVehicleModalErrors, setEditVehicleModalErrors] = useState([])
    const [infoClosed, setInfoClosed] = useState((JSON.parse(localStorage.getItem('vehicles-overview-info-closed')) || []).includes(user._id))
    const popupRef = useRef(null)
    /** @type {React.MutableRefObject<Vehicle[]>} */
    const vehiclesRef = useRef(null)
    const history = useHistory()

    // https://stackoverflow.com/a/60643670/13664713
    vehiclesRef.current = vehicles

    useEffect(() => {
        getVehicles()

        document.title = `Voertuigen • ${reseller.settings.accountName || reseller.name}`
    }, [])

    useEffect(() => {
        if (!editVehicleModalOpen) {
            setActiveVehicle(null)
        }
    }, [editVehicleModalOpen])

    const filteredVehicles = vehicles.filter((vehicle) => showArchived || !vehicle.isArchived)

    const onCopy = () => {
        const vehicle = vehicles.find((v) => v._id === selectedVehicles[0])

        if (vehicle) {
            const newVehicle = {...vehicle, _id: undefined, name: `${vehicle.name} (kopie)`}
            setActiveVehicle(newVehicle)
            setEditVehicleModalOpen(true)
        }

        setSelectedVehicles([])
    }

    /**
     *
     * @param {any} _event
     * @param {boolean} deArchive
     * @param {Vehicle['_id'][]} overrideVehicles
     */
    const onToggleArchive = (_event, deArchive = false, overrideVehicles = selectedVehicles) => {
        const archiveVehicles = structuredClone(vehicles.filter((v) => overrideVehicles.includes(v._id) && (deArchive ? v.isArchived : !v.isArchived)))

        let amountChanged = 0

        archiveVehicles.forEach((vehicle) => {
            const vehicleType = reseller.settings.vehicleTypes.find((type) => type._id === vehicle.typeId)
            vehicle.isArchived = vehicleType.isArchived ? true : !deArchive
            if (vehicle.isArchived === !deArchive) {
                amountChanged++
            }
        })

        if (popupRef?.current) {
            popupRef.current.open(`Voertuig ${deArchive ? 'de' : ''}archiveren`, `${amountChanged} ${amountChanged === 1 ? 'voertuig wordt' : 'voertuigen worden'} ${deArchive ? 'weer actief gemaakt voor gebruik' : 'gearchiveerd'}.`, () => {
                updateVehicles(archiveVehicles, (errors) => {
                    if (errors?.length) {
                        popupRef.current.setErrors(errors)
                        return
                    }
                    popupRef.current.close()
                    setSelectedVehicles([])
                    setEditVehicleModalOpen(false)
                })
            })
        }
    }

    if (!reseller.settings.vehicleTypes || reseller.settings.vehicleTypes?.length === 0) {
        return null
    }

    const vehicleColumns = columns(reseller.settings.vehicleTypes, (vehicleId) => {
        setActiveVehicle(vehiclesRef.current.find((v) => v._id === vehicleId))
        setEditVehicleModalOpen(true)
    })

    return (
        <div style={{display: 'flex', flexDirection: 'column', height: '100%'}}>
            <Panel style={{display: 'flex', borderTop: 'none', background: Colors.backgroundNeutral, padding: '20px 10px 10px 10px'}}>
                <SearchBar
                    columns={vehicleColumns}
                    queries={[]}
                />

                <Button
                    onClick={() => {
                        setEditVehicleModalOpen(true)
                    }}
                    variant='outline-white'
                    tooltip='Voertuig toevoegen'
                >
                    Nieuwe voertuig
                </Button>

                {selectedVehicles.length === 1 &&
                    <Button
                        onClick={onCopy}
                        icon='mdi mdi-content-copy'
                        variant='outline-white'
                        tooltip='Voertuig kopiëren'
                    />
                }

                {selectedVehicles.length > 0 &&
                    <>
                        <Button
                            onClick={onToggleArchive}
                            icon='mdi mdi-archive'
                            variant='outline-white'
                            tooltip='Voertuig archiveren'
                        />

                        {showArchived &&
                            <Button
                                onClick={(event) => onToggleArchive(event, true)}
                                icon='mdi mdi-archive-off'
                                variant='outline-white'
                                tooltip='Voertuig activeren'
                            />
                        }
                    </>
                }

                <Toggle
                    style={{width: 'fit-content', marginLeft: 'auto'}}
                    label='Toon gearchiveerde voertuigen'
                    checked={showArchived}
                    onChange={(event) => setShowArchived(event.target.checked)}
                />
            </Panel>

            {!infoClosed &&
                <Alert
                    variant='info'
                    style={{marginTop: 24, marginLeft: 'auto', marginRight: 'auto', width: 'calc(100% - 48px)'}}
                    onClose={() => {
                        const closedInfo = JSON.parse(localStorage.getItem('vehicles-overview-info-closed')) || []
                        closedInfo.push(user._id)
                        localStorage.setItem('vehicles-overview-info-closed', JSON.stringify(closedInfo))
                        setInfoClosed(true)
                    }}
                >
                    <P style={{display: 'inline'}}>Dit is het overzicht van specifieke voertuigen. Specifieke voertuigen vallen altijd onder een voertuigsoort. Om de voertuigsoorten te beheren ga naar instellingen {'->'} planning {'->'} voertuigsoorten of <Link style={{display: 'inline'}} onClick={() => history.push('/instellingen/planning/voertuig-typen')}>klik hier</Link>.</P>
                </Alert>
            }

            <div style={{flex: 1, marginTop: 24, marginLeft: 24, marginRight: 24}}>
                <Table
                    columns={vehicleColumns}
                    rows={filteredVehicles || []}
                    selectedRows={selectedVehicles}
                    onChange={(newSelectedVehicles) => {
                        setSelectedVehicles(newSelectedVehicles)
                    }}
                />
            </div>

            <EditVehicleModal
                open={editVehicleModalOpen}
                vehicle={activeVehicle ?? newVehicle(reseller)}
                vehicleTypes={reseller.settings.vehicleTypes}
                onClose={() => {
                    setEditVehicleModalOpen(false)
                }}
                onChange={(newVehicle) => {
                    setEditVehicleModalErrors([])
                    if ('typeId' in newVehicle) {
                        if (newVehicle._id) {
                            updateVehicles([newVehicle], (errors) => {
                                if (errors?.length) {
                                    setEditVehicleModalErrors(errors)
                                    return
                                }
                                setEditVehicleModalOpen(false)
                            })
                        } else {
                            createVehicle(newVehicle, (error) => {
                                if (error) {
                                    setEditVehicleModalErrors([error])
                                    return
                                }
                                setEditVehicleModalOpen(false)
                            })
                        }
                    }
                }}
                onArchive={() => {
                    onToggleArchive(undefined, !!activeVehicle.isArchived, [activeVehicle._id])
                }}
                errors={editVehicleModalErrors}
            />

            <Popup ref={popupRef} />
        </div>
    )
}
