import React, {useReducer} from 'react'

import {H3, H6, Divider, Button, Alert} from '../../../../components/UI/index.js'
import {Toggle, Input, Select} from '../../../../components/UI/index.js'

import LoginActions from '../../../../actions/LoginActions.js'
import reducer from '../../../../helpers/reducer.js'

const initialState = {
    loading: false,
    verified: false,
    error: ''
}

export default function BolComSettings({user, onChange}) {
    const [{loading, verified, error}, dispatch] = useReducer(reducer, initialState)

    function onChangeChecked(key, event) {
        user.settings.webshops.bolcom[key] = event.target.checked

        onChange(user)
    }

    function onChangeValue(key, event) {
        user.settings.webshops.bolcom[key] = event.target.value

        onChange(user)
    }

    function onVerifyConnection() {
        dispatch({loading: true, verified: false, error: ''})

        onChange(user, true)

        setTimeout(() => { // wait for saving
            LoginActions.verifyWebshop('bolcom', (err) => {
                if (err) {
                    dispatch({loading: false, error: `Verificatie niet succesvol, controleer de ingevulde gegevens. Foutmelding: ${err}`})
                } else {
                    dispatch({loading: false, verified: true})
                }
            })
        }, 1000)
    }

    return (
        <div>
            <H3>Bol.com</H3>

            <H6>Instellingen</H6>
            <Divider />

            <Toggle
                label='Actief'
                checked={user.settings.webshops.bolcom.active}
                onChange={(event) => onChangeChecked('active', event)}
            />

            {user.settings.webshops.bolcom.active &&
                <>
                    <Input
                        label='Client ID'
                        value={user.settings.webshops.bolcom.apiKey}
                        onChange={(event) => onChangeValue('apiKey', event)}
                    />

                    <Input
                        label='Client Secret'
                        value={user.settings.webshops.bolcom.apiSecret}
                        onChange={(event) => onChangeValue('apiSecret', event)}
                    />

                    <Select
                        label='Update order status in Bol.com'
                        info=''
                        value={user.settings.webshops.bolcom.updateStatus}
                        onChange={(event) => onChangeValue('updateStatus', event)}
                    >
                        <option value=''>Nooit</option>
                        <option value='confirm'>Na bevestigen</option>
                        <option value='afterSent'>Na verzenden</option>
                    </Select>

                    <Button
                        variant='text'
                        style={{marginBottom: 12, marginLeft: 0}}
                        loading={loading}
                        onClick={onVerifyConnection}
                    >
                        Verifieer
                    </Button>

                    {error && (
                        <Alert onClose={() => dispatch({error: ''})} variant='error'>{error}</Alert>
                    )}

                    {!error && verified && (
                        <Alert onClose={() => dispatch({verified: false})}>Verificatie succesvol.</Alert>
                    )}
                </>
            }
        </div>
    )
}
