// @ts-check
import React, {forwardRef, useImperativeHandle, useReducer} from 'react'
import {Alert, Card, Colors, Column, H4, H6, IconButton, Link, Modal, P, Row, S1, S2, ScrollView} from '../UI/index.js'
import usePackageStore from '../../stores/PackageStore.js'
import dbCodes from '../../../server/dbCodes.js'
import moment from 'moment'

/**
 * @typedef {object} ViewPackagingModalProps
 *
 * @param {{}} history
 * @param {function} [onClose]
 */

const ViewPackagingModal = forwardRef(

    /**
 * @component
 *
 * @param {ViewPackagingModalProps} props
 *
 * @returns {JSX.Element}
 */
    ({history, onClose}, ref) => {
    /**
     * @type {[{packaging: Packaging | null, show: boolean, loading: boolean, error: string | null}, function: (newState: {packaging?: Packaging | null, show? : boolean, loading?: boolean, error?: string | null}) => void]}
     */
        const [state, setState] = useReducer((state, newState) => ({...state, ...newState}), {
            packaging: null,
            show: false,
            loading: false,
            error: null
        })
        const PackageStore = usePackageStore()

        const open = (id) => {
            setState({show: true, loading: true})

            PackageStore.getOne(id, (err, packaging) => {
                if (err) {
                    console.error(err)
                    return
                }

                console.log(packaging)

                setState({packaging, loading: false})
            })
        }

        const close = () => {
            setState({packaging: null, show: false, loading: false, error: null})
            onClose && onClose()
        }

        // To make passed functions accessible to parents
        useImperativeHandle(ref, () => ({open, close}))

        const {packaging, show, loading, error} = state

        return (
            <Modal show={show} onClose={close} style={{maxWidth: 600}}>
                <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'baseline', marginBottom: 24}}>
                    <H4>Emballage</H4>

                    <IconButton icon='mdi mdi-close' onClick={close} />
                </div>

                {loading &&
                    <Row>
                        <H6>Loading...</H6>
                    </Row>
                }

                {error &&
                    <Alert type='error' style={{marginBottom: 24}}>{error}</Alert>
                }

                {packaging &&
                    <>
                        <Card style={{marginBottom: 24}}>
                            <Row style={{alignItems: 'center'}}>
                                <Column>
                                    <i style={{fontSize: 16, marginRight: 12}} className='mdi mdi-package-variant-closed' />
                                    <P>{packaging.barcode}</P>
                                </Column>

                                <Column>
                                    <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', width: '100%', background: Colors.grey20, height: 42, marginLeft: 6, marginRight: 6, marginBottom: 6}}>
                                        <S1>{dbCodes.packagingStatus[packaging.status]}</S1>
                                    </div>
                                </Column>
                            </Row>
                        </Card>

                        <H6>Historie</H6>

                        <ScrollView style={{maxHeight: 250}}>
                            {[...packaging.events].reverse().map((event, index) => {
                                return (
                                    <Row key={`history${index}`} style={{marginBottom: 3}}>

                                        <i className='mdi mdi-circle-small' style={{marginRight: 6, fontSize: 16, color: Colors.grey60}} />

                                        <Column style={{flex: 1, flexDirection: 'column'}}>

                                            <Row style={{alignItems: 'center'}}>
                                                {event.orderId ?
                                                        <Link onClick={() => history.push(`/planning?orderId=${event.orderId}`)}>{event.description || dbCodes.packagingStatus[event.status]}</Link> :
                                                        <P>{event.description || dbCodes.packagingStatus[event.status]}</P>
                                                }
                                            </Row>
                                        </Column>

                                        <Column style={{width: 200}}>
                                            <S2>{`${event.who || ''} | ${moment(event.timestamp).format('DD-MM-YYYY HH:mm:ss')}`}</S2>
                                        </Column>
                                    </Row>
                                )
                            })}
                        </ScrollView>
                    </>
                }

            </Modal>
        )
    })

ViewPackagingModal.displayName = 'ViewPackagingModal'

export default ViewPackagingModal
