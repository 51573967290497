import React from 'react'

import {CarrierSelector, Colors, CurrencyInput, H6, Input, P, Row, Column, Select, Toggle, MetricInput} from '../../UI/index.js'
import {Button, IconButton, Modal, H4} from '../../UI/index.js'
import _ from 'lodash'
import Countries from '../../../utils/Countries.js'

const newFee = () => {
    return {
        description: '',
        price: '',
        productCode: '',
        auto: false,
        applyToAllCarriers: false,
        carriers: [],
        applyToAllCountries: false,
        countries: [],
        postalCodes: '',
        conditions: [],
        matchAllConditions: false
    }
}

export default class ParcelFeeModal extends React.Component {
    constructor(props) {
        super(props)

        this.initialState = {
            open: false,
            fee: newFee(),
            index: null
        }

        this.state = structuredClone(this.initialState)
    }

    open(fee, index) {
        if (!fee) {
            fee = newFee()
        }

        fee.carriers = fee.carriers || []
        fee.countries = fee.countries || []
        fee.conditions = fee.conditions || []

        fee = structuredClone(fee)

        this.setState({open: true, fee, index})
    }

    close() {
        this.setState(this.initialState)
    }

    onChangeValue(path, parser, event) {
        const fee = {...this.state.fee}

        if (!event) {
            event = parser
        }

        if (typeof parser === 'function') {
            _.set(fee, path, parser(event.target.value))
        } else {
            _.set(fee, path, event.target.value)
        }

        this.setState({fee})
    }

    onChangeChecked(path, event) {
        const fee = {...this.state.fee}

        _.set(fee, path, event.target.checked)

        this.setState({fee})
    }

    onChangeCarriers(event) {
        const fee = {...this.state.fee}

        const index = fee.carriers.indexOf(event.target.value)

        if (index > -1) {
            fee.carriers.splice(index, 1)
        } else {
            fee.carriers.push(event.target.value)
        }

        this.setState({fee})
    }

    onChangeCountries(event) {
        const fee = {...this.state.fee}

        fee.countries = event.target.values

        this.setState({fee})
    }

    onAddCondition() {
        const fee = {...this.state.fee}

        fee.conditions.push({
            variable: 'weight',
            operator: 'gt',
            value: 0
        })

        this.setState({fee})
    }

    onRemoveCondition(index) {
        const fee = {...this.state.fee}

        fee.conditions.splice(index, 1)

        this.setState({fee})
    }

    onSubmit() {
        const {fee, index} = this.state

        this.props.onChange(fee, index)

        this.close()
    }

    render() {
        const {open, fee, index} = this.state
        const {reseller, carriers, onRemove} = this.props

        const enabledCarriers = carriers.filter((carrier) => {
            return reseller.settings.parcels.carriers[carrier.name] && reseller.settings.parcels.carriers[carrier.name].enabled
        })

        return (
            <Modal style={{width: 800}} show={open} onClose={this.close.bind(this)}>
                <div style={{display: 'flex', justifyContent: 'space-between'}}>
                    <H4>Wijzig toeslag</H4>

                    <IconButton onClick={this.close.bind(this)}>
                        <i style={{color: Colors.buttonSolid}} className='mdi mdi-close' />
                    </IconButton>
                </div>

                <Input
                    label='Beschrijving'
                    value={fee.description}
                    onChange={this.onChangeValue.bind(this, 'description')}
                />

                <CurrencyInput
                    label='Prijs'
                    value={fee.price}
                    onChange={this.onChangeValue.bind(this, 'price')}
                />

                <Row>
                    <Column>
                        <Toggle
                            style={{marginTop: 24}}
                            label='Automatisch toepassen'
                            checked={fee.auto}
                            onChange={this.onChangeChecked.bind(this, 'auto')}
                        />
                    </Column>

                    <Column />
                </Row>

                {fee.auto &&
                    <>
                        <H6 style={{marginTop: 48}}>Vervoerders</H6>

                        <Row>
                            <Column>
                                <Toggle
                                    style={{marginTop: 12}}
                                    label='Toepassen op alle vervoerders'
                                    checked={fee.applyToAllCarriers}
                                    onChange={this.onChangeChecked.bind(this, 'applyToAllCarriers')}
                                />

                            </Column>

                            <Column />
                        </Row>

                        {!fee.applyToAllCarriers &&
                            <Row style={{marginTop: 12, width: '100%', overflowX: 'auto'}}>
                                <CarrierSelector
                                    label='Vervoerders'
                                    size={40}
                                    carriers={enabledCarriers}
                                    values={fee.carriers}
                                    onChange={this.onChangeCarriers.bind(this)}
                                />
                            </Row>
                        }

                        <H6 style={{marginTop: 48}}>Landen</H6>

                        <Row>
                            <Column>
                                <Toggle
                                    style={{marginTop: 12}}
                                    label='Toepassen op alle landen'
                                    checked={fee.applyToAllCountries}
                                    onChange={this.onChangeChecked.bind(this, 'applyToAllCountries')}
                                />
                            </Column>

                            <Column />
                        </Row>

                        {!fee.applyToAllCountries &&
                            <>
                                <Select
                                    copy
                                    label='Landen'
                                    options={[
                                        ...Countries.map((country) => {
                                            return {value: country.code2, title: country.name}
                                        })
                                    ]}
                                    values={fee.countries || []}
                                    onChange={this.onChangeCountries.bind(this)}
                                />

                                {fee.countries.some((country) => country === 'NL') &&
                                    <Input
                                        label='Beperkt postcodegebied Nederland'
                                        info='Dit zijn de postcodes waar de toeslag op wordt toegepast. Gebruik een komma om postcodes te scheiden en een streepje om een reeks te noteren, bijvoorbeeld: 1000-2000,3000,4000-5000. Laat dit veld leeg om alle postcodes te gebruiken.'
                                        value={fee.postalCodes}
                                        onChange={this.onChangeValue.bind(this, 'postalCodes')}
                                    />
                                }
                            </>
                        }

                        <H6 style={{marginTop: 48}}>Voorwaarden</H6>

                        {fee.conditions.length > 1 &&
                            <Row style={{marginTop: 24}}>
                                <Column>
                                    <Toggle
                                        label='Voldoe aan alle voorwaarden'
                                        checked={fee.matchAllConditions}
                                        onChange={this.onChangeChecked.bind(this, 'matchAllConditions')}
                                    />

                                </Column>

                                <Column />
                            </Row>
                        }

                        {fee.conditions.map((condition, index) => {
                            return (
                                <Row key={`condition${index}`} style={{alignItems: 'center', marginTop: 12}}>
                                    {index === 0 &&
                                        <P style={{marginRight: 12, width: 25}}>Als</P>
                                    }

                                    {index > 0 &&
                                        <P style={{marginRight: 12, width: 25}}>{fee.matchAllConditions ? 'en' : 'of'}</P>
                                    }

                                    <Select
                                        options={[
                                            {title: 'Gewicht', value: 'weight', type: 'number'},
                                            {title: 'Volume', value: 'volume', type: 'number'},
                                            {title: 'Lengte', value: 'length', type: 'number'},
                                            {title: 'Breedte', value: 'width', type: 'number'},
                                            {title: 'Hoogte', value: 'height', type: 'number'}
                                        ]}
                                        value={condition.variable}
                                        onChange={this.onChangeValue.bind(this, `conditions.${index}.variable`)}
                                    />

                                    <Select
                                        options={[
                                            {title: '>', value: 'gt'},
                                            {title: '≥', value: 'gte'},
                                            {title: '<', value: 'lt'},
                                            {title: '≤', value: 'lte'},
                                            {title: '=', value: 'eq'},
                                            {title: '≠', value: 'neq'}
                                        ]}
                                        value={condition.operator}
                                        onChange={this.onChangeValue.bind(this, `conditions.${index}.operator`)}
                                    />

                                    {condition.variable === 'weight' &&
                                        <MetricInput
                                            value={condition.value}
                                            onChange={this.onChangeValue.bind(this, `conditions.${index}.value`, parseInt)}
                                            append='g'
                                        />
                                    }

                                    {['length', 'width', 'height'].includes(condition.variable) &&
                                        <MetricInput
                                            max={9999}
                                            divisionFactor={10}
                                            value={condition.value}
                                            onChange={this.onChangeValue.bind(this, `conditions.${index}.value`, parseInt)}
                                            append='cm'
                                        />
                                    }

                                    {condition.variable === 'volume' &&
                                        <MetricInput
                                            divisionFactor={1000000}
                                            value={condition.value}
                                            onChange={this.onChangeValue.bind(this, `conditions.${index}.value`, parseInt)}
                                            append='dm3'
                                        />
                                    }

                                    <IconButton
                                        style={{marginBottom: 12}}
                                        icon='mdi mdi-delete'
                                        onClick={this.onRemoveCondition.bind(this, index)}
                                    />

                                </Row>
                            )
                        })}

                        <Button
                            style={{marginTop: 12}}
                            variant='outline-white'
                            icon='mdi mdi-plus'
                            label='Voorwaarde'
                            onClick={this.onAddCondition.bind(this)}
                        />
                    </>
                }

                <Row style={{marginTop: 36}}>
                    {typeof index === 'number' &&
                        <Button
                            variant='error'
                            onClick={() => {
                                onRemove(index)
                                this.close()
                            }}
                        >
                            Verwijder
                        </Button>
                    }

                    <div style={{flex: 1}} />

                    <Button
                        variant='text'
                        onClick={() => {
                            this.close()
                        }}
                    >
                        Annuleren
                    </Button>

                    <Button disabled={!fee.description} onClick={this.onSubmit.bind(this)}>Opslaan</Button>
                </Row>
            </Modal>
        )
    }
}
