import React from 'react'

import {Colors, Info} from '../UI/index.js'

export default class TextArea extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            focused: false
        }
    }

    focus() {
        this.textArea.focus()
    }

    blur() {
        this.textArea.blur()
    }

    getRef() {
        return this.textArea
    }

    setInvalid() {
        this.setState({isInvalid: true})
        this.focus()
    }

    render() {
        const {style = {}, inputStyle = {}, id, label, info, infoIcon, value, rows = 1, readOnly, disabled, isInvalid, onChange, onFocus, onBlur} = this.props
        const {focused} = this.state

        const focusedState = focused || value

        const defaultStyle = {
            flex: 1,
            flexShrink: 0,
            position: 'relative',
            marginLeft: 6,
            marginRight: 6,
            marginBottom: 9,
            ...style
        }

        if (defaultStyle.width) {
            delete defaultStyle.flex
        }

        const labelStyle = {
            display: 'flex',
            width: '100%',
            alignItems: 'center',
            position: 'absolute',
            paddingLeft: 10,
            top: focusedState ? 2 : 10,
            fontSize: focusedState ? 12 : 14,
            color: Colors.textMedium,
            cursor: disabled || readOnly ? 'default' : 'text',
            transition: 'all .2s ease',
            background: readOnly ? 'transparent' : disabled ? Colors.grey20 : Colors.backgroundWhite

        }

        const defaultInputStyle = {
            fontSize: 14,
            minHeight: 42,
            paddingTop: 18,
            paddingBottom: 0,
            paddingLeft: 10,
            paddingRight: 10,
            width: '100%',
            overflow: 'hidden',
            outline: 'none',
            borderTop: 'none',
            borderLeft: 'none',
            borderRight: 'none',
            borderBottom: '1px solid',
            borderColor: disabled ? Colors.border : isInvalid ? Colors.errorBright : (focusedState && focused) ? Colors.buttonSolid : Colors.border,
            borderRadius: '4px 4px 0px 0px',
            background: readOnly ? 'transparent' : disabled ? Colors.grey20 : Colors.backgroundWhite,
            color: disabled && !readOnly ? Colors.textMedium : Colors.textDark,
            cursor: disabled && !readOnly ? 'default' : 'text',
            resize: readOnly ? '' : 'vertical',
            ...inputStyle
        }

        if (readOnly) {
            defaultInputStyle.pointerEvents = 'none'
        }

        return (
            <div style={defaultStyle}>
                <div style={labelStyle} onClick={() => this.textArea?.focus()}>
                    <div style={{whiteSpace: 'nowrap'}}>{label}</div>
                    {info && <Info icon={infoIcon} text={info} />}
                </div>

                {!readOnly &&
                    <textarea
                        style={defaultInputStyle}
                        id={id}
                        value={value || ''}
                        rows={rows}
                        disabled={disabled || readOnly}
                        onFocus={() => {
                            this.setState({focused: true})
                            onFocus && onFocus()
                        }}
                        onBlur={() => {
                            this.setState({focused: false})
                            onBlur && onBlur()
                        }}
                        onKeyUp={this.props.onKeyUp && this.props.onKeyUp.bind(this)}
                        onClick={this.props.onClick && this.props.onClick.bind(this)}
                        onChange={readOnly ? () => {} : onChange}
                        ref={(ref) => this.textArea = ref}
                    />
                }

                {readOnly &&
                    <div style={defaultInputStyle}>
                        {value}
                    </div>
                }
            </div>

        )
    }
}
