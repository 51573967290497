// @ts-check

import React, {useState} from 'react'

import {Divider, H3, H6, Toggle, Input, Select, Button, Alert} from '../../../../components/UI/index.js'
import LoginActions from '../../../../actions/LoginActions.js'

export default function MagentoSettings({user, onChange}) {
    const [loading, setLoading] = useState(false)
    const [verified, setVerified] = useState(false)
    const [error, setError] = useState('')

    /**
     * @param {string} key
     * @param {React.ChangeEvent<HTMLInputElement>} event
     */
    const onChangeChecked = (key, event) => {
        user.settings.webshops.magento[key] = event.target.checked

        onChange(user)
    }

    /**
     * @param {string} key
     * @param {React.ChangeEvent<HTMLInputElement>} event
     */
    const onChangeValue = (key, event) => {
        user.settings.webshops.magento[key] = key === 'nrOfDays' ? event.target.valueAsNumber : event.target.value

        onChange(user)
    }

    function onChangeValues(key, event) {
        user.settings.webshops.magento[key] = event.target.values

        onChange(user)
    }

    const onVerifyConnection = () => {
        setLoading(true)
        setVerified(false)
        setError('')

        onChange(user, true)

        setTimeout(() => { // wait for saving
            LoginActions.verifyWebshop('magento', (err) => {
                setLoading(false)
                if (err) {
                    setError(`Verificatie niet succesvol, controleer de ingevulde gegevens. Foutmelding: ${err}`)
                } else {
                    setVerified(true)
                }
            })
        }, 1000)
    }

    return (
        <div>
            <H3>Magento</H3>

            <H6>Instellingen</H6>
            <Divider />

            <Toggle
                label='Actief'
                checked={user.settings.webshops.magento.active}
                onChange={(event) => onChangeChecked('active', event)}
            />

            {user.settings.webshops.magento.active &&
                <>
                    <Input
                        label='Webshop URL'
                        value={user.settings.webshops.magento.url}
                        onChange={(event) => onChangeValue('url', event)}
                    />

                    <Input
                        label='Access token'
                        value={user.settings.webshops.magento.accessToken}
                        onChange={(event) => onChangeValue('accessToken', event)}
                    />

                    <Input
                        type='number'
                        min={0}
                        max={180}
                        label='Aantal dagen terugkijken voor ophalen bestellingen'
                        placeholder={7}
                        value={user.settings.webshops.magento.nrOfDays}
                        onChange={(event) => onChangeValue('nrOfDays', event)}
                    />

                    <Select
                        label='Update order status in Magento'
                        info=''
                        value={user.settings.webshops.magento.updateStatus}
                        onChange={(event) => onChangeValue('updateStatus', event)}
                    >
                        <option value=''>Nooit</option>
                        <option value='confirm'>Na bevestigen</option>
                        <option value='afterSent'>Na verzenden</option>
                    </Select>

                    <Select
                        label='Op te halen statussen uit Magento'
                        options={[
                            {value: 'pending', title: 'Pending'},
                            {value: 'processing', title: 'Processing'},
                            {value: 'complete', title: 'Complete'}
                        ]}
                        allowCustomValues
                        values={user.settings.webshops.magento.statuses || []}
                        onChange={(event) => onChangeValues('statuses', event)}
                    />

                    <Toggle
                        label='Apart veld voor huisnummer en toevoeging'
                        checked={user.settings.webshops.magento.separateHouseNrAndAdditionField}
                        onChange={(event) => onChangeChecked('separateHouseNrAndAdditionField', event)}
                        info='Zet deze optie aan als er in Magento gebruik wordt gemaakt van een apart veld voor huisnummer en toevoeging.'
                    />

                    <Button
                        variant='text'
                        style={{marginBottom: 12, marginLeft: 0}}
                        loading={loading}
                        onClick={onVerifyConnection}
                    >
                        Verifieer
                    </Button>

                    {error && (
                        <Alert onClose={() => setError('')} variant='error'>{error}</Alert>
                    )}

                    {!error && verified && (
                        <Alert onClose={() => setVerified(false)}>Verificatie succesvol.</Alert>
                    )}

                </>
            }
        </div>
    )
}
