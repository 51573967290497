export default {
    DUTCH: {
        rrule: {
            'every': 'elke',
            'day': 'dag',
            'days': 'dagen',
            'weekday': 'werkdag',
            'weekdays': 'werkdagen',
            'week': 'week',
            'weeks': 'weken',
            'month': 'maand',
            'months': 'maanden',
            'year': 'jaar',
            'years': 'jaren',
            'and': 'en',
            'on': 'op',
            'the': 'de',
            'on the': 'op de',
            'st': 'e',
            'nd': 'e',
            'th': 'e',
            'rd': 'e',
            'first': 'eerste',
            'second': 'tweede',
            'third': 'derde',
            'nth': 'nde',
            'last': 'laatste',
            'for': 'voor',
            'time': 'keer',
            'times': 'keer',
            'until': 'tot',
            'monday': 'maandag',
            'tuesday': 'dinsdag',
            'wednesday': 'woensdag',
            'thursday': 'donderdag',
            'friday': 'vrijdag',
            'saturday': 'zaterdag',
            'sunday': 'zondag',
            'january': 'januari',
            'february': 'februari',
            'march': 'maart',
            'april': 'april',
            'may': 'mei',
            'june': 'juni',
            'july': 'juli',
            'august': 'augustus',
            'september': 'september',
            'october': 'october',
            'november': 'november',
            'december': 'december',
            'comma': 'komma'
        },
        dayNames: [
            'zondag', 'maandag', 'dinsdag', 'woensdag',
            'donderdag', 'vrijdag', 'zaterdag'
        ],
        monthNames: [
            'januari', 'februari', 'maart', 'april', 'mei',
            'juni', 'juli', 'augustus', 'september', 'oktober',
            'november', 'december'
        ],
        tokens: {
            'SKIP': /^[ \r\n\t]+|^\.$/,
            'number': /^[1-9][0-9]*/,
            'numberAsText': /^(one|two|three)/i,
            'every': /^every/i,
            'day(s)': /^days?/i,
            'weekday(s)': /^weekdays?/i,
            'week(s)': /^weeks?/i,
            'hour(s)': /^hours?/i,
            'month(s)': /^months?/i,
            'year(s)': /^years?/i,
            'on': /^(on|in)/i,
            'at': /^(at)/i,
            'the': /^the/i,
            'first': /^first/i,
            'second': /^second/i,
            'third': /^third/i,
            'nth': /^([1-9][0-9]*)(\.|th|nd|rd|st)/i,
            'last': /^last/i,
            'for': /^for/i,
            'time(s)': /^times?/i,
            'until': /^(un)?til/i,
            'monday': /^mo(n(day)?)?/i,
            'tuesday': /^tu(e(s(day)?)?)?/i,
            'wednesday': /^we(d(n(esday)?)?)?/i,
            'thursday': /^th(u(r(sday)?)?)?/i,
            'friday': /^fr(i(day)?)?/i,
            'saturday': /^sa(t(urday)?)?/i,
            'sunday': /^su(n(day)?)?/i,
            'january': /^jan(uary)?/i,
            'february': /^feb(ruary)?/i,
            'march': /^mar(ch)?/i,
            'april': /^apr(il)?/i,
            'may': /^may/i,
            'june': /^june?/i,
            'july': /^july?/i,
            'august': /^aug(ust)?/i,
            'september': /^sep(t(ember)?)?/i,
            'october': /^oct(ober)?/i,
            'november': /^nov(ember)?/i,
            'december': /^dec(ember)?/i,
            'comma': /^(,\s*|(and|or)\s*)+/i
        }
    }
}
