import React from 'react'

import {Button, Modal, P, H4} from '../UI/index.js'
import {RadioButton} from '../UI/index.js'

class Prompt extends React.Component {
    constructor(props) {
        super(props)
        this.initialState = {
            isOpen: false,
            title: '',
            text: '',
            choices: [],
            selectedChoice: null,
            callback: null
        }

        this.state = JSON.parse(JSON.stringify(this.initialState))
    }

    open(title, text, choices, callback) {
        let selectedChoice = null

        choices.map((choice) => {
            if (choice.default) {
                selectedChoice = choice.value
            }
        })

        this.setState({isOpen: true, title, text, selectedChoice, choices, callback})
    }

    close() {
        this.setState({isOpen: false}, () => {
            this.setState(this.initialState)
        })
    }

    onClickConfirm() {
        const {selectedChoice, callback} = this.state

        callback(selectedChoice)
        this.close()
    }

    onClickChoice(event) {
        this.setState({selectedChoice: event.target.value})
    }

    render() {
        const {isOpen, title, text, choices, selectedChoice} = this.state

        return (
            <Modal show={isOpen} style={{width: 400}} onClose={this.close.bind(this)}>
                <div style={{display: 'flex', marginBottom: 24}}>
                    <H4>{title}</H4>

                    <div style={{flex: 1}} />
                </div>

                <P style={{marginBottom: 30}}>{text}</P>

                {choices.map((choice) => {
                    return (
                        <RadioButton
                            key={`choice${choice.value}`}
                            checked={choice.value === selectedChoice}
                            label={choice.title}
                            value={choice.value}
                            onChange={this.onClickChoice.bind(this)}
                        />
                    )
                })}

                <br />
                <br />
                <div style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-end'}}>
                    <Button
                        variant='text'
                        onClick={this.close.bind(this)}
                    >
                        Annuleren
                    </Button>

                    <Button
                        variant='text'
                        onClick={this.onClickConfirm.bind(this)}
                    >
                        Bevestigen
                    </Button>

                </div>
            </Modal>
        )
    }
}

export default (Prompt)
