export default (string) => {
    let street = ''
    let nr = ''
    let addition = ''

    let address = string.trim().replace(',', '').replace('-', ' ')

    address = address.split(/\s+/)

    if (address.length > 2) {
        if (address[address.length - 2].match(/[0-9]/)) {
            addition = address.pop()
            nr = address.pop()
            street = address.join(' ')
        } else if (address[address.length - 1].match(/[0-9]/)) {
            nr = address.pop()
            street = address.join(' ')
        } else if (address[0].match(/[0-9]/)) {
            nr = address.shift()
            street = address.join(' ')
        } else {
            street = address.join(' ')
        }
    } else if (address.length > 1) {
        if (address[address.length - 1].match(/[0-9]/)) {
            nr = address.pop()
            street = address.join(' ')
        } else if (address[0].match(/[0-9]/)) {
            nr = address.shift()
            street = address.join(' ')
        } else {
            street = address.join(' ')
        }
    } else {
        street = address.join(' ')
    }

    if (!addition) {
        const nrAddition = nr.match(/[0-9]+|[^0-9]/gi)

        if (nrAddition && nrAddition.length === 2) {
            nr = nrAddition[0]
            addition = nrAddition[1]
        }
    }

    if (/[0-9]/.test(addition[0])) {
        addition = `-${addition}`
    }

    addition = addition.toUpperCase()

    return {street, nr, addition}
}
