const defaultCustomerConfirmedEmailBody = `Beste {naam},

Je zending is aangemeld voor verzending bij {vervoerder}. Klik op onderstaande knop om je zending te volgen.

Met vriendelijke groet,
{bedrijfsnaam}`

const defaultCustomerShippedEmailBody = `Beste {naam},

Je zending is verzonden met {vervoerder}. Klik op onderstaande knop om je zending te volgen.

Met vriendelijke groet,
{bedrijfsnaam}`

const defaultCustomerCancelEmailBody = `Beste {naam},

Wij hebben de zending {barcode} geannuleerd.
Helaas kunt u deze zending niet meer verwachten.

Met vriendelijke groet,
{bedrijfsnaam}`

const defaultCustomerRetourConfirmationEmailBody = `Beste {naam},

Bedankt voor het aanmelden van je retourzending.
Het verzendlabel vind je in de bijlage.

Print het label, plak deze op het pakket en lever deze in bij een inleverpunt van {vervoerder}. De zending is vervolgens te volgen via de Track & Trace link onderaan deze email.

Met vriendelijke groet,

{bedrijfsnaam}`

import {Customer} from './customers.types.js'

export default /** @return {Customer} */(): Customer => {
    /** @type {Customer} */
    const customer: Customer = {
        name: '',
        synced: false,
        debtorCode: '',
        useOtherInvoiceDebtorCode: false,
        invoiceDebtorCode: '',
        address: {
            name: '',
            attention: '',
            street: '',
            nr: '',
            addition: '',
            street2: '',
            postalCode: '',
            city: '',
            country: 'NL',
            isBusiness: true
        },
        phone: '',
        email: '',
        contacts: [],
        login: false,
        loginEmail: '',
        settings: {
            logo: '',
            color: '',
            useOwnSMTP: false,
            smtpHost: '',
            smtpPort: '',
            smtpUser: '',
            smtpPassword: '',
            openingHours: {
                monday: {
                    open: true
                },
                tuesday: {
                    open: true
                },
                wednesday: {
                    open: true
                },
                thursday: {
                    open: true
                },
                friday: {
                    open: true
                },
                saturday: {
                    open: false
                },
                sunday: {
                    open: false
                }
            },
            usePickupDaysForRetourOrder: false,
            orders: {
                defaultSigned: 'default',
                defaultStatedAddressOnly: 'default',
                nameRequired: 'default',
                gpsRequired: 'default',
                scanRequired: 'default',
                imageRequired: 'default',
                allowAddCollo: 'default',
                referenceRequired: false,
                newOrderInstructions: '',
                emailNotificationsReceiverOption: 'default',
                emailNotificationsReceiver: [],
                emailNotificationsCustomerOption: 'default',
                emailNotificationsCustomer: [],
                pickupNotPossibleReasons: ['default'],
                deliveryNotPossibleReasons: ['default'],
                pickupNotPossibleReasonsSpecifications: [],
                deliveryNotPossibleReasonsSpecifications: [],
                showNewOrderNotification: 'default',
                voorzorgDeliveryDays: {
                    monday: true,
                    tuesday: true,
                    wednesday: true,
                    thursday: true,
                    friday: true,
                    saturday: false,
                    sunday: false
                }
            },
            parcels: {
                'selectCarrier': false,
                'emailTT': '',
                'disabledCarriers': [],
                'cycloonNoParcelsOnFriday': '',
                'cycloonDeliveredByBikeOnly': '',
                'dhlEuroplusForBusinesAddress': '',
                'dhlQLSEuroplusForBusinesAddress': '',
                'dpdOnlineparcelSaturdayDelivery': '',
                'DHLnoParcelsForBusinessAddress': '',
                'DHL-MSGnoParcelsForBusinessAddress': '',
                'CycloonnoParcelsForBusinessAddress': '',
                'autoPickupOrder': false,
                'autoPickupPrice': '0,00',
                'coverAmount': '',
                'pickupDays': {
                    monday: true,
                    tuesday: true,
                    wednesday: true,
                    thursday: true,
                    friday: true,
                    saturday: false,
                    sunday: false
                },
                'sameDayCuttoff': '00:00',
                'sameDayPickupStart': '16:00',
                'sameDayPickupEnd': '17:00',
                'nextDayCuttoff': '17:00',
                'nextDayPickupStart': '17:00',
                'nextDayPickupEnd': '18:00',
                'priceTable': '',
                'priceTableAbroad': '',
                'defaultOptions': [],
                'autoMailboxParcel': false,
                'useMailboxParcelWeight': false,
                'mailboxParcelWeight': 0,
                'useMailboxParcelValue': false,
                'mailboxParcelValue': 0,
                'defaultComment': '',
                'referenceNotOnLabel': false,
                'dontSendEmailToCarriers': false,
                'returnsVia': '',
                'senderAddress': {
                    name: '',
                    attention: '',
                    street: '',
                    nr: '',
                    addition: '',
                    street2: '',
                    postalCode: '',
                    city: '',
                    country: 'NL'
                },

                'sendConfirmedEmail': false,
                'confirmedEmailSenderName': '',
                'confirmedEmailReplyTo': '',
                'confirmedEmailSubject': 'Zending aangemeld',
                'confirmedEmailBody': defaultCustomerConfirmedEmailBody,

                'sendShippedEmail': false,
                'shippedEmailSenderName': '',
                'shippedEmailReplyTo': '',
                'shippedEmailSubject': 'Zending verzonden',
                'shippedEmailBody': defaultCustomerShippedEmailBody,

                'sendCancelEmail': false,
                'cancelEmailSenderName': '',
                'cancelEmailReplyTo': '',
                'cancelEmailSubject': 'Zending geannuleerd',
                'cancelEmailBody': defaultCustomerCancelEmailBody,

                'sendRetourConfirmationEmail': false,
                'retourConfirmationEmailSenderName': '',
                'retourConfirmationEmailReplyTo': '',
                'retourConfirmationEmailSubject': 'Retourlabel',
                'retourConfirmationEmailBody': defaultCustomerRetourConfirmationEmailBody,

                'returnPortalActive': false,
                'returnPeriod': 14,
                'returnAddress': {
                    name: '',
                    attention: '',
                    street: '',
                    nr: '',
                    addition: '',
                    street2: '',
                    postalCode: '',
                    city: '',
                    country: 'NL'
                },
                'returnPolicyURL': '',
                'returnShippingCosts': {
                    DHL: {
                        enabled: false,
                        value: '0,00'
                    },
                    GLS: {
                        enabled: false,
                        value: '0,00'
                    },
                    PostNL: {
                        enabled: false,
                        value: '0,00'
                    }

                },
                'returnReasons': [],
                'returnRefundMethods': [],
                'mollie': {
                    active: false,
                    accessToken: '',
                    expireDate: '',
                    refreshToken: '',
                    websiteProfiles: []
                },
                'sizes': []
            },
            webshops: {
                lightspeed: {
                    active: false,
                    clusterId: '',
                    apiKey: '',
                    apiSecret: '',
                    language: '',
                    ignoreNotPayedOrders: false,
                    nrOfDays: 7,
                    updateStatus: '',
                    shippingMethods: []
                },
                shopify: {
                    active: false,
                    url: '',
                    apiKey: '',
                    nrOfDays: 7,
                    updateStatus: '',
                    ignoreNotPayedOrders: false
                },
                woocommerce: {
                    active: false,
                    url: '',
                    apiKey: '',
                    apiSecret: '',
                    nrOfDays: 7,
                    updateStatus: '',
                    statuses: [],
                    weightInGrams: false,
                    streetField: '',
                    nrField: '',
                    additionField: '',
                    shippingMethods: []
                },
                ccvshop: {
                    active: false
                },
                bolcom: {
                    active: false
                },
                mijnwebwinkel: {
                    active: false
                },
                shoptrader: {
                    active: false
                },
                exactOnline: {
                    active: false
                },
                magento: {
                    active: false,
                    url: '',
                    accessToken: '',
                    nrOfDays: 7,
                    updateStatus: '',
                    statuses: []
                },
                shopware: {
                    active: false
                },
                prestashop: {
                    active: false
                }
            },
            apiKey: '',
            webhookURL: '',
            parcelWebhookURL: '',
            webhookAuthHeader: '',
            mfaRequired: false
        },
        notes: ''
    }

    return JSON.parse(JSON.stringify(customer))
}
