import React from 'react'

import {Colors} from '../index.js'

export default class S1 extends React.PureComponent {
    render() {
        const {id, children, disabled, style = {}, ellipsis} = this.props

        const defaultStyle = {
            fontSize: 14,
            fontWeight: 500, // medium
            letterSpacing: 0.1,
            color: disabled ? Colors.textMedium : Colors.textDark,
            lineHeight: 1.5,
            ...style
        }

        if (ellipsis) {
            defaultStyle.overflow = 'hidden',
            defaultStyle.whiteSpace = 'nowrap',
            defaultStyle.textOverflow = 'ellipsis'
        }

        return (
            <div
                id={id}
                style={defaultStyle}
                onClick={this.props.onClick || null}
                onMouseDown={this.props.onMouseDown || null}
                onMouseUp={this.props.onMouseUp || null}
                onMouseEnter={this.props.onMouseEnter || null}
                onMouseLeave={this.props.onMouseLeave || null}
                ref={(ref) => this.ref = ref}
            >
                {children}
            </div>
        )
    }
}
