import React from 'react'

import {Colors} from './index.js'

export default class Badge extends React.Component {
    render() {
        const {size = 'md', children, style = {}, variant = '', color, textColor, onClick} = this.props

        let background = Colors.grey30

        if (variant === 'white') {
            background = Colors.white
        }

        if (variant === 'success') {
            background = Colors.successLight
        }

        if (variant === 'info') {
            background = Colors.infoLight
        }

        if (variant === 'warning') {
            background = Colors.warningLight
        }

        if (variant === 'error') {
            background = Colors.errorLight
        }

        if (color) {
            background = color
        }

        const defaultStyle = {
            display: 'flex',
            alignItems: 'center',
            flexShrink: 0,
            paddingLeft: size === 'sm' ? 12 : 12,
            paddingRight: size === 'sm' ? 12 : 12,
            height: size === 'sm' ? 20 : 24,
            width: 'fit-content',
            background,
            border: variant === 'white' ? `1px solid ${Colors.border}` : 'none',
            color: textColor || (Colors.isDarkBackground(background) ? Colors.textLight : Colors.textDark),
            fontSize: size === 'sm' ? 12 : 12,
            borderRadius: 24,
            ...style
        }

        return (
            <div style={defaultStyle} onClick={onClick || null}>
                {children}
            </div>
        )
    }
}
