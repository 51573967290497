import React from 'react'

import Reflux from 'reflux-react-16'
import moment from 'moment'
import _ from 'lodash'
import aSync from 'async'
import csvExport from '../../utils/csvExport.js'

import OrderStore from '../../stores/OrderStore.js'
import OrderActions from '../../actions/OrderActions.js'
import ParcelStore from '../../stores/ParcelStore.js'
import CustomerStore from '../../stores/CustomerStore.js'
import UserStore from '../../stores/UserStore.js'
import AddressStore from '../../stores/AddressStore.js'
import AddressActions from '../../actions/AddressActions.js'
import dbCodes from '../../../server/dbCodes.js'

import {Alert, Button, DropdownButton, IconButton, Colors, S1, S2, P, Popup, Tabs, Tab, PrintPopup, PrintModal, Panel, Prompt, Row, Column, DropdownActionButton, FlexColumn, AlertPopup, Icon, ToolTip} from '../../components/UI/index.js'
import {SelectRoute} from '../../components/UI/index.js'
import AutoSetMessengerPopup from '../../components/routes/AutoSetMessengerPopup.js'
import ViewOrderModal from '../../components/orders/ViewOrderModal.js'
import EditOrderModal from '../../components/orders/EditOrderModal.js'
import NewOrder from '../../../server/functions/orders/NewOrder.js'
import OrderScanModal from '../../components/orders/OrderScanModal.js'
import ImportOrders from '../../components/orders/ImportOrders.js'
import AtHubOrderPopup from '../../components/orders/AtHubOrderPopup.js'
import RescheduleOrderPopup from '../../components/orders/RescheduleOrderPopup.js'
import PickupDeliverPopup from '../../components/orders/PickupDeliverPopup.js'
import CompleteOrderPopup from '../../components/orders/CompleteOrderPopup.js'

import Table from '../../components/Table/index.js'
import SearchBar from '../../components/SearchBar/index.js'

import Planning from './Planning.js'
import EditPricePopup from '../../components/orders/EditPricePopup.js'
import CancelOrderPopup from '../../components/orders/CancelOrderPopup.js'
import OnHoldOrderPopup from '../../components/orders/OnHoldOrderPopup.js'
import ReturnOrderPopup from '../../components/orders/ReturnOrderPopup.js'
import NotSuppliedOrderPopup from '../../components/orders/NotSuppliedOrderPopup.js'
import MancoOrderPopup from '../../components/orders/MancoOrderPopup.js'
import ChangeTimeslotPopup from '../../components/orders/ChangeTimeslotPopup.js'
import EditRouteModal from '../../components/routes/EditRouteModal.js'
import ExportOrders from '../../components/orders/ExportOrders.js'
import {withVehiclesHook} from '../../stores/VehicleStore.js'

class Dashboard extends Reflux.Component {
    constructor(props) {
        super(props)

        this.state = {
            selectedMessengers: ['', ''],
            printerPageSize: localStorage.printerPageSize || 'A6',
            exportOrdersIsOpen: false
        }

        this.stores = [OrderStore, ParcelStore, CustomerStore, UserStore, AddressStore]
    }

    componentDidMount() {
        const {reseller} = this.props
        let {orderId = ''} = this.props.match.params

        document.title = `Planning • ${reseller.settings.accountName || reseller.name}`

        if (this.props.location?.state?.orderId) {
            this.viewOrderModal.open(this.props.location?.state?.orderId, this.props.location?.state?.fromScan && this.onClickScan.bind(this))
        }

        if (!orderId) {
            const urlParams = new URLSearchParams(window.location.search)
            orderId = urlParams.get('orderId')
        }

        if (orderId) {
            this.viewOrderModal.open(orderId)
        }

        window.history.replaceState({}, '')
    }

    componentDidUpdate() {
        if (this.state.orderLimitWarning) {
            this.setState({orderLimitWarning: false})
            this.alertPopup.open('Waarschuwing', 'Je huidige datumrange bevat meer dan 35 000 ritten, hierdoor worden niet alle ritten weergegeven. Verklein de datumrange om dit te voorkomen. ')
        }
    }

    columns() {
        const {reseller, user, vehicles} = this.props

        return [
            {title: 'Datum', visible: false, key: 'date', width: 120, render: (order) => {
                return moment(order.date).format('DD-MM-YYYY')
            }},
            {title: 'Aanmaaktijd', visible: false, key: 'createdTime', width: 120, sort: (order) => {
                return moment(new Date(parseInt(order._id.substring(0, 8), 16) * 1000)).format('HH:mm')
            }, render: (order) => {
                return moment(new Date(parseInt(order._id.substring(0, 8), 16) * 1000)).format('HH:mm')
            }},
            {title: 'Klant', visible: true, width: 250, key: 'customer', sort: (order) => {
                return `${order.senderAddress?.name || ''}${order.senderAddress?.name ? ` (${order.customerAddress?.name})` : order.customerAddress?.name}`
            }, render: (order) => {
                return `${order.senderAddress?.name || ''}${order.senderAddress?.name ? ` (${order.customerAddress?.name})` : order.customerAddress?.name}`
            }},
            {title: 'Tijdvak', key: 'startTime', visible: true, width: 90, sort: (order) => {
                const address = order.addresses[0]

                const dashboardObj = JSON.parse(localStorage._dashboard)

                if (dashboardObj.timeSortOption) {
                    if (dashboardObj.timeSortOption === 'startTime') {
                        let startTime = '24:00'

                        order.addresses.map((address) => {
                            if (address.startTime < startTime) {
                                startTime = address.startTime
                            }
                        })

                        return startTime
                    }

                    if (dashboardObj.timeSortOption === 'endTime') {
                        let endTime = '24:00'

                        order.addresses.map((address) => {
                            if (address.endTime < endTime) {
                                endTime = address.endTime
                            }
                        })

                        return endTime
                    }

                    return address[dashboardObj.timeSortOption]
                }
                return address.startTime
            },
            dropdown: {
                localStorageKey: 'timeSortOption',
                options: [
                    {
                        label: 'Starttijd',
                        value: 'startTime'
                    },
                    {
                        label: 'Eindtijd',
                        value: 'endTime'
                    }
                ],
                onClick: (value) => {
                    const dashboardObj = JSON.parse(localStorage._dashboard)
                    dashboardObj.timeSortOption = value
                    localStorage._dashboard = JSON.stringify(dashboardObj)
                }
            },
            render: (order) => {
                const firstAddress = order.addresses[0]
                const lastAddress = order.addresses.length > 1 ? order.addresses[order.addresses.length - 1] : null

                return (
                    <div style={{width: '100%'}}>
                        {firstAddress &&
                            <div style={{display: 'flex', alignItems: 'center', height: 27.5}}>
                                <S2 ellipsis style={{marginRight: 12}}>{`${firstAddress.startTime} - ${firstAddress.endTime}`.trim()}</S2>
                            </div>
                        }

                        {lastAddress &&
                            <div style={{display: 'flex', alignItems: 'center', height: 27.5}}>
                                <S2 ellipsis style={{marginRight: 12}}>{`${lastAddress.startTime} - ${lastAddress.endTime}`.trim()}</S2>
                            </div>
                        }
                    </div>
                )
            }},
            {title: 'Adres', key: 'address', visible: true, minWidth: 250, sort: (order) => {
                const address = order.addresses[0]

                const dashboardObj = JSON.parse(localStorage._dashboard)

                if (dashboardObj.addressSortOption) {
                    return address[dashboardObj.addressSortOption]
                }
                return address.postalCode
            }, dropdown: {
                localStorageKey: 'addressSortOption',
                options: [
                    {
                        label: 'Naam',
                        value: 'name'
                    },
                    {
                        label: 'Straat',
                        value: 'street'
                    },
                    {
                        label: 'Huisnummer',
                        value: 'nr'
                    },
                    {
                        label: 'Postcode',
                        value: 'postalCode'
                    },
                    {
                        label: 'Plaats',
                        value: 'city'
                    },
                    {
                        label: 'Stopnummer',
                        value: 'stopNumber'
                    }
                ],
                onClick: (value) => {
                    const dashboardObj = JSON.parse(localStorage._dashboard)
                    dashboardObj.addressSortOption = value
                    localStorage._dashboard = JSON.stringify(dashboardObj)
                }
            }, flex: 2, render: (order) => {
                const firstAddress = order.addresses[0]
                const lastAddress = order.addresses.length > 1 ? order.addresses[order.addresses.length - 1] : null

                return (
                    <div style={{width: '100%'}}>
                        {firstAddress &&
                            <div style={{display: 'flex', alignItems: 'center'}}>
                                <i style={{color: Colors[firstAddress.type], fontSize: 18, marginRight: 12}} className={firstAddress.type === 'pickup' ? 'mdi mdi-alpha-p-circle' : 'mdi mdi-alpha-d-circle'} />

                                {!firstAddress.position &&
                                    <ToolTip text='Adres niet gevonden. Controleer de gegevens voor je bevestigt.' fixedPosition>
                                        <Icon size='md' style={{color: Colors.errorBright}} icon='mdi mdi-alert' />
                                    </ToolTip>
                                }

                                <P ellipsis style={{width: 150, marginRight: 6}}>
                                    {firstAddress.name}
                                </P>

                                <S1 ellipsis style={{marginRight: 6}}>
                                    {firstAddress.stopNumber}
                                </S1>

                                <P ellipsis style={{flex: 1}}>
                                    {`${firstAddress.street} ${firstAddress.nr}${firstAddress.addition} ${firstAddress.city}`.trim()}
                                </P>

                                {order.addresses.length > 2 &&
                                    <S1 style={{marginRight: 15}}>+ {order.addresses.length - 2}</S1>
                                }
                            </div>
                        }

                        {lastAddress &&
                            <div style={{display: 'flex', alignItems: 'center'}}>
                                <i style={{color: Colors[lastAddress.type], fontSize: 18, marginRight: 12}} className={lastAddress.type === 'pickup' ? 'mdi mdi-alpha-p-circle' : 'mdi mdi-alpha-d-circle'} />

                                {(_.some(order.addresses.slice(1), (address) => !address.position) || !lastAddress.position) &&
                                    <Icon size='md' style={{color: Colors.errorBright}} icon='mdi mdi-alert' />
                                }

                                <P ellipsis style={{width: 150, marginRight: 6}}>
                                    {lastAddress.name}
                                </P>

                                <S1 ellipsis style={{marginRight: 6}}>
                                    {lastAddress.stopNumber}
                                </S1>

                                <P ellipsis style={{flex: 1}}>
                                    {`${lastAddress.street} ${lastAddress.nr}${lastAddress.addition} ${lastAddress.city}`.trim()}
                                </P>
                            </div>
                        }
                    </div>
                )
            }},
            {title: 'Ophaaladres', key: 'pickupAddress', filterOnly: true},
            {title: 'Bezorgadres', key: 'deliveryAddress', filterOnly: true},
            {title: 'Aantal colli', visible: false, key: 'colli', width: 200, render: (order) => {
                return `${order.colli.length} ${order.colli.length === 1 ? 'collo' : 'colli'}`
            }},
            {title: 'Opmerkingen', visible: true, key: 'notes', flex: 1},
            {title: 'Referentie', visible: false, key: 'reference', flex: 1},
            {title: 'Track & Trace', visible: false, width: 200, key: 'trackTrace', onClick: (order) => {
                if (order.noTrackTrace) {
                    return
                }

                window.open(`/track&trace/${order.trackTrace}`)
            }},
            {title: 'Barcodes', visible: false, width: 200, render: (order) => {
                return order.colli?.map((collo) => collo.barcode || '').filter((b) => b).join(', ') || ''
            }},
            {title: 'Prijs', visible: false, key: 'price', width: 70, sort: (order) => {
                return parseFloat(order.price.replace(',', '.'))
            }, render: (order) => {
                return `€ ${order.price}`
            }},
            {title: 'Toeslagen', visible: false, key: 'fees', width: 150, sort: (order) => {
                return order.fees.join(', ')
            }, render: (order) => {
                return order.fees.join(', ')
            }},
            {title: 'Route', visible: true, key: 'routeName', width: 180, text: (order) => {
                const route = this.state.routes[order.routeId]
                return route?.name || ''
            }},
            {title: 'Voertuig', visible: false, key: 'vehicleId', width: 180, text: (order) => {
                const route = this.state.routes[order.routeId]
                const vehicle = vehicles?.find((vehicle) => vehicle._id === route?.vehicleId)
                return vehicle?.name || ''
            }},
            {title: 'Voertuigsoort', visible: false, key: 'vehicleType', width: 180, text: (order) => {
                const route = this.state.routes[order.routeId]
                const vehicleType = reseller.settings.vehicleTypes.find((vt) => vt._id === route?.vehicleTypeId)
                return vehicleType?.name || ''
            }},
            {title: 'Koerier', visible: true, key: 'messengerName', width: 180, text: (order) => {
                return (order.routeId ? this.state.routes[order.routeId]?.messengerName : order.messenger) || '' // order.messenger is DEPRECATED
            }},
            {title: 'Vervoerder', visible: false, key: 'carrierName', width: 180, text: (order) => {
                if (order.handledBy === 'partner') {
                    return order.carrierName
                } else {
                    return this.props.reseller?.settings?.accountName || this.props.reseller?.name || ''
                }
            }},
            ...reseller.permissions.shifts ? [{title: 'Dienst', visible: false, key: 'shift', width: 180}] : [],
            {title: 'Status', visible: true, key: 'status', width: 200, render: (order) => {
                const {statusColor, status, subStatus} = Colors.orderStatusColor(order)

                return (
                    <div style={{flex: 1, display: 'flex', flexDirection: 'column', alignItems: 'center', padding: 5, minWidth: 0, background: statusColor, borderRadius: 4}}>
                        <P>{status}</P>

                        {subStatus && subStatus !== dbCodes.status[dbCodes.status.geannuleerd()] &&
                            <S1>{subStatus}</S1>
                        }
                    </div>
                )
            }, sort: (order) => {
                const {subStatus} = Colors.orderStatusColor(order)

                return `${order.status} ${subStatus || ''}`
            }},
            {title: 'Opgehaald om', visible: false, width: 110, render: (order) => {
                return order.addresses[0].timeOfArrival || ''
            }},
            {title: 'Bezorgd om', visible: false, width: 110, render: (order) => {
                return order.addresses[order.addresses.length - 1].timeOfArrival || ''
            }},
            {title: 'Opmerking Koerier', visible: false, width: 200, render: (order) => {
                return order.addresses[order.addresses.length - 1].comment || ''
            }},
            ...reseller.settings.administration.checkOrders && user.permissions.invoices ?
                    [
                        {title: 'Geaccordeerd', visible: false, key: 'checked', width: 120, render: (order) => {
                            return order.checked ? 'Ja' : 'Nee'
                        }}
                    ] :
                    [],
            ...user.permissions.invoices ?
                    [
                        {title: 'Gefactureerd', visible: false, key: 'invoiced', width: 120, render: (order) => {
                            return order.dontInvoice ? 'n.v.t.' : order.invoiced ? 'Ja' : 'Nee'
                        }}
                    ] :
                    [],
            {title: 'Bijlage', visible: false, key: 'attachments', flex: 1, onClick: () => {}, render: (order) => {
                const attachments = order.addresses.flatMap((address) => address.attachments || [])
                return (
                    <P
                        onClick={() => {
                            const attachment = attachments?.[0] || {}
                            const openUrl = (file) => {
                                const raw = window.atob(file)
                                const rawLength = raw.length
                                const array = new Uint8Array(new ArrayBuffer(rawLength))

                                for (let i = 0; i < rawLength; i++) {
                                    array[i] = raw.charCodeAt(i)
                                }

                                const blob = new Blob([array], {type: attachment.type})

                                const blobUrl = URL.createObjectURL(blob)

                                window.open(blobUrl)
                            }
                            if (attachment.fileId) {
                                AddressActions.downloadAttachment(attachment.fileId, (err, file) => {
                                    if (err) {
                                        console.log(err)
                                    } else {
                                        openUrl(file)
                                    }
                                })
                            } else if (attachment.data) {
                                window.open(attachment.data)
                            }
                        }}
                        ellipsis
                    >
                        {attachments?.[0]?.fileName}{attachments.length > 1 ? ` (+${attachments.length - 1})` : ''}
                    </P>
                )
            }},
            {visible: true, width: 42, showOnHover: true, onClick: () => {}, render: (order) => {
                return (
                    <IconButton onClick={() => this.viewOrderModal.open(order._id)}>
                        <i className='mdi mdi-eye' />
                    </IconButton>
                )
            }}
        ]
    }

    onChangeDates(orderStartDate, orderEndDate) {
        OrderActions.setDates(orderStartDate, orderEndDate)
    }

    onChangeTimes(startTime, endTime, startInclude, endInclude) {
        OrderActions.setTimes(startTime, endTime, startInclude, endInclude)
    }

    onChangeQueries(orderQueries) {
        if (this.table) {
            this.table.selectPage(1)
        }
        OrderActions.setQueries(orderQueries)
    }

    onChangeTable(selectedOrders) {
        OrderActions.setSelected(selectedOrders)
    }

    onClickNew() {
        this.editOrderModal.open()
        OrderActions.setSelected([])
    }

    onClickImport() {
        this.importModal.open()
        OrderActions.setSelected([])
    }

    onClickScan() {
        this.scanModal.open()
    }

    onClickExport(template) {
        const {orders, selectedOrders, orderStartDate, orderEndDate, customers, routes} = this.state
        const {reseller, vehicles} = this.props
        const exportOrders = []

        selectedOrders.map((id) => {
            const order = _.find(orders, (order) => order._id === id)

            if (order) {
                order.routeName = this.state.routes[order.routeId]?.name || ''
                order.messengerName = this.state.routes[order.routeId]?.messengerName || ''
                order.vehicleType = this.state.routes[order.routeId]?.vehicleType || ''

                exportOrders.push(order)
            }
        })

        csvExport.orders(reseller, exportOrders, orderStartDate, orderEndDate, customers, routes, vehicles, template)
        OrderActions.setSelected([])
        this.setState({exportOrdersIsOpen: false})
    }

    onClickCopy(id) {
        const {orders, selectedOrders, customers} = this.state
        id = id || selectedOrders[0]
        let order = _.find(orders, (order) => order._id === id)
        order = JSON.parse(JSON.stringify(order))

        const newOrder = NewOrder()

        const customer = customers[order.customer]

        if (customer) {
            newOrder.customer = order.customer
            newOrder.contact = order.contact
            newOrder.email = order.email
            newOrder.phone = order.phone
        }

        newOrder.addresses = []
        order.addresses.map((address) => {
            const newAddress = {
                type: address.type,
                name: address.name,
                attention: address.attention,
                street: address.street,
                nr: address.nr,
                addition: address.addition,
                street2: address.street2,
                postalCode: address.postalCode,
                city: address.city,
                country: address.country,
                email: address.email || '',
                phone: address.phone || '',
                instructions: address.instructions,
                startTime: address.startTime,
                endTime: address.endTime,
                isBusiness: address.isBusiness || false,

                gpsRequired: !!address.gpsRequired,
                signatureRequired: !!address.signatureRequired,
                nameRequired: !!address.nameRequired,
                signatureNeighbors: !!address.signatureNeighbors,
                scanRequired: !!address.scanRequired,
                imageRequired: !!address.imageRequired,
                statedAddressOnly: !!address.statedAddressOnly,
                mailboxDelivery: !!address.mailboxDelivery,
                idCheck: !!address.idCheck,
                minimumAge: address.minimumAge ?? undefined,
                attachments: address.attachments || []
            }

            if (address.position) {
                newAddress.position = JSON.parse(JSON.stringify(address.position))
            }

            newOrder.addresses.push(newAddress)
        })

        if (order.colli) {
            newOrder.colli = []

            order.colli.map((collo) => {
                if (collo.barcode) {
                    newOrder.colli.push({
                        type: collo.type,
                        description: collo.type,
                        weight: collo.weight,
                        length: collo.length,
                        width: collo.width,
                        height: collo.height,
                        barcode: 'Barcode'
                    })
                }
            })
        }

        newOrder.distance = order.distance || 0
        newOrder.priceTableId = order.priceTableId || ''
        newOrder.price = order.price
        newOrder.reference = order.reference
        newOrder.notes = order.notes
        newOrder.autoRetour = !!order.autoRetour
        newOrder.nrOfDeliveries = order.nrOfDeliveries
        newOrder.nrOfDaysBeforeRetour = order.nrOfDaysBeforeRetour
        if (order.retourAddress) {
            newOrder.retourAddress = order.retourAddress
            newOrder.retourAddress = {
                instructions: '',
                startTime: '09:00',
                endTime: '17:00',
                ...order.retourAddress
            }
        }
        newOrder.allowAddCollo = order.allowAddCollo || 'yes'

        this.editOrderModal.open(newOrder)
        OrderActions.setSelected([])
    }

    onClickCheck() {
        const {selectedOrders} = this.state

        this.popup.open('Ritten accorderen', 'Ritten worden geaccordeerd, hierna zijn ze zichtbaar op de factuur.', () => {
            OrderActions.setSelected([])

            let success = 0
            const errors = []

            this.popup.setProgress(0, selectedOrders.length)

            aSync.eachSeries(selectedOrders, (id, next) => {
                OrderActions.check(id, (err) => {
                    if (err) {
                        errors.push(err)
                    } else {
                        success += 1
                    }

                    this.popup.setProgress(success + errors.length, selectedOrders.length)

                    next()
                })
            }).then(() => {
                if (errors.length) {
                    this.popup.setMessage(`${success} ${success === 1 ? 'rit' : 'ritten'} geaccordeerd.`)
                    this.popup.setErrors(errors)
                } else {
                    this.popup.close()
                }
            })
        })
    }

    onClickCalculatePrice() {
        const {selectedOrders} = this.state

        this.editPricePopup.open((pricingOption, newPrice, invoice, priceTable) => {
            let success = 0
            const errors = []

            OrderActions.setSelected([])

            this.editPricePopup.setProgress(0, selectedOrders.length)

            aSync.eachSeries(selectedOrders, (id, next) => {
                if (this.editPricePopup.state.stopOperation) return
                OrderActions.calculatePrice(id, pricingOption, newPrice, invoice, priceTable, (err) => {
                    if (err) {
                        errors.push(err)
                    } else {
                        success += 1
                    }

                    this.editPricePopup.setProgress(success + errors.length, selectedOrders.length)

                    next()
                })
            }).then(() => {
                this.editPricePopup.setMessage(`${success} ${success === 1 ? 'rit' : 'ritten'} herberekend.`)
                this.editPricePopup.setErrors(errors)
            })
        })
    }

    onClickRemove(order) {
        if (order) {
            if (order.rrule) {
                this.prompt.open('Verwijder herhaalde rit', '', [
                    {title: 'Alleen deze rit', value: 'remove', default: true},
                    {title: 'Deze en toekomstige ritten', value: 'removeAll'}
                ], (choice) => {
                    this.setState({loading: true, error: ''})

                    if (choice === 'removeAll') {
                        OrderActions.removeTemplate(order, (err) => {
                            if (err) {
                                this.prompt.setError(err)
                            } else {
                                this.prompt.close()
                                this.viewOrderModal.close()
                            }
                        })
                    } else {
                        OrderActions.remove([order._id], (err) => {
                            if (err) {
                                this.prompt.setError(err)
                            } else {
                                this.prompt.close()
                                this.viewOrderModal.close()
                            }
                        })
                    }
                })
            } else {
                this.popup.open('Verwijder rit', 'Weet je zeker dat je deze rit wilt verwijderen?', () => {
                    OrderActions.remove([order._id], (err) => {
                        if (err) {
                            this.popup.setError(err)
                        } else {
                            this.popup.close()
                            this.viewOrderModal.close()
                        }
                    })
                })
            }
        } else {
            const {selectedOrders} = this.state

            this.popup.open('Ritten verwijderen', 'Ritten worden definitief verwijderd.', () => {
                OrderActions.setSelected([])

                OrderActions.remove(selectedOrders, (err, errors, success) => {
                    if (err) {
                        this.popup.setError(err)
                    } else if (errors.length) {
                        this.popup.setMessage(`${success} ${success === 1 ? 'rit' : 'ritten'} verwijderd.`)
                        this.popup.setErrors(errors)
                    } else {
                        this.popup.close()
                    }
                })
            })
        }
    }

    async onClickPrintLabels(id, addressIndex, colloIndex) {
        const {selectedOrders, printerPageSize} = this.state

        const ids = id ? [id] : selectedOrders

        const batchAmount = 50
        if (ids.length > batchAmount) {
            const batches = _.chunk(ids, batchAmount)

            this.printPopup.open('Labels printen', `Er zijn meer dan ${batchAmount} ritten geselecteerd. Hierdoor zullen de labels per set van ${batchAmount} worden opgevraagd en gedownload. Zie je download folder om de labels te openen.`, printerPageSize, async (nrOfEmptyLabels) => {
                OrderActions.setSelected([])

                let batchIndex = 0

                for (const batch of batches) {
                    this.printPopup.setProgress({percentage: 0, message: `Label set ${batchIndex + 1} van ${batches.length}`})

                    await new Promise((resolve, reject) => {
                        OrderActions.printLabels(batch, nrOfEmptyLabels, addressIndex, colloIndex, (err, pdf, errors) => {
                            if (err) {
                                this.printPopup.setError(err)
                                reject(err)
                            } else {
                                if (errors.length) {
                                    this.printPopup.setErrors(errors)
                                }

                                batchIndex += 1
                                this.printPopup.setProgress({percentage: 100, message: `Label set ${batchIndex} van ${batches.length}`})

                                resolve()

                                if (pdf) {
                                    const aLink = document.createElement('a')
                                    aLink.href = `data:application/pdf;base64,${pdf}`
                                    aLink.download = `labels-${moment().format('YYYY-MM-DD')}-${batchIndex}.pdf`
                                    aLink.click()
                                    aLink.remove()
                                }
                            }
                        })
                    })
                }
                setTimeout(() => {
                    this.printPopup.close()
                }, 1000)
            })
            return
        }

        this.printPopup.open('Labels printen', '', printerPageSize, (nrOfEmptyLabels) => {
            OrderActions.setSelected([])

            OrderActions.printLabels(ids, nrOfEmptyLabels, addressIndex, colloIndex, (err, pdf, errors) => {
                if (err) {
                    this.printPopup.setError(err)
                } else {
                    if (errors.length) {
                        this.printPopup.setErrors(errors)
                    } else {
                        this.printPopup.close()
                    }

                    if (pdf) {
                        this.printModal.open(pdf)
                    }
                }
            })
        })
    }

    onChangePrinterPageSize(event) {
        localStorage.printerPageSize = event.target.value
        this.setState({printerPageSize: event.target.value})
    }

    async onChangeRoute(id, event, callback) {
        const {orders, routes, selectedOrders} = this.state
        let routeId = event.target.value
        let userId
        let newRoute = false

        if (routeId.indexOf('new|') > -1) {
            userId = routeId.split('|')[1]
            routeId = ''
            newRoute = true
        }

        const ids = id ? [id] : selectedOrders

        if (!id) {
            OrderActions.setSelected([])
        }

        const route = routes[routeId]

        let bool

        if (route?.started) {
            bool = await this.popup.open('Route wijzigen', 'Stop die worden toegevoegd aan een gestarte route worden automatisch op onderweg gezet.')
        } else if (route?.ready) {
            bool = await this.popup.open('Route wijzigen', 'Je voegt adressen toe aan een route die gereed is. De route wordt teruggezet naar concept.')
        } else {
            const ordersInReadyRoute = _.some(ids, (id) => orders[id]?.routeId && routes[orders[id].routeId]?.ready)

            if (ordersInReadyRoute) {
                bool = await this.popup.open('Route wijzigen', 'Je verwijderd adressen uit een route die gereed is. Weet je dit zeker?')
            } else {
                const ordersInStartedRoute = _.some(ids, (id) => orders[id]?.routeId && routes[orders[id].routeId]?.started)

                if (ordersInStartedRoute) {
                    bool = await this.popup.open('Route wijzigen', 'Je verwijderd adressen uit een route die onderweg is. Weet je dit zeker?')
                } else {
                    bool = true
                }
            }
        }

        if (bool) {
            OrderActions.addToRoute(ids, routeId, userId, (err, route, order, errors) => {
                if (err) {
                    this.popup.setError(err)
                } else {
                    if (errors.length) {
                        this.popup.setErrors(errors)
                    } else {
                        this.popup.close()
                    }

                    if (newRoute) {
                        this.editRouteModal.open(route)
                    }
                }

                typeof callback === 'function' && callback(err, order)
            })
        }
    }

    onClickAutoSetMessengers() {
        const {selectedOrders} = this.state

        this.autoSetMessengerPopup.open(selectedOrders, (options) => {
            OrderActions.setSelected([])

            OrderActions.autoSetMessengers(selectedOrders, options, (err) => {
                if (err) {
                    this.autoSetMessengerPopup.setError('Geen mogelijk route gevonden.')
                } else {
                    this.autoSetMessengerPopup.close()
                }
            })
        })
    }

    onClickOpenTimeslotPopup() {
        const {selectedOrders} = this.state

        this.changeTimeslotPopup.open(selectedOrders)
    }

    onClickCancelRetour(id) {
        this.popup.open('Annuleer retour', 'Deze rit wordt verwijderd en de voorgaande rit wordt weer actief.', () => {
            OrderActions.cancelRetour(id, '', (err, order) => {
                if (err) {
                    this.popup.setError(err)
                } else {
                    order && this.setState({order})
                    this.popup.close()
                }
            })
        })
    }

    render() {
        const {view, filteredOrders, orders, selectedOrders, routes, usersObject, customers, carriers, ordersLoading, addressBook, orderStartDate, orderEndDate, startTime, endTime, startInclude, endInclude, orderQueries, printerPageSize, exportOrdersIsOpen, error} = this.state
        const {reseller, user, history, onUpdateReseller} = this.props

        return (
            <FlexColumn>
                {error && <Alert variant='error' onClose={() => OrderActions.clearError()}>{error}</Alert>}

                <Panel style={{display: 'flex', borderTop: 'none', background: Colors.backgroundNeutral, padding: '20px 10px 10px 10px'}}>

                    <Tabs style={{marginRight: 20}} value={view} onChange={(event) => OrderActions.setView(event.target.value)}>
                        <Tab size='sm' value='routes'>
                            <i className='mdi mdi-map' />
                        </Tab>
                        <Tab size='sm' value='orders'>
                            <i className='mdi mdi-format-list-bulleted' />
                        </Tab>
                    </Tabs>

                    <SearchBar
                        columns={this.columns()}
                        onQueryChange={this.onChangeQueries.bind(this)}
                        onDateChange={this.onChangeDates.bind(this)}
                        onTimeChange={this.onChangeTimes.bind(this)}
                        startDate={orderStartDate}
                        endDate={orderEndDate}
                        startTime={startTime}
                        endTime={endTime}
                        startInclude={startInclude}
                        endInclude={endInclude}
                        queries={orderQueries}
                        user={user}
                    />

                    <Row style={{flexWrap: 'wrap'}}>
                        <Column style={{flex: 0, marginBottom: 6}}>
                            <Button
                                style={{minWidth: 115}}
                                variant='outline-white'
                                label='Nieuwe rit'
                                onClick={this.onClickNew.bind(this)}
                            />

                            <Button
                                variant='outline-white'
                                onClick={this.onClickImport.bind(this)}
                                tooltip='Ritten importeren'
                            >
                                <i className='mdi mdi-file-import' />
                            </Button>

                            <Button
                                variant='outline-white'
                                onClick={this.onClickScan.bind(this)}
                                tooltip='Scannen'
                            >
                                <i className='mdi mdi-barcode-scan' />
                            </Button>
                        </Column>

                        {selectedOrders.length > 0 &&
                            <>
                                <Column style={{flex: 0, height: 36, marginBottom: 6}}>
                                    <Button
                                        variant='outline-white'
                                        onClick={() => this.setState({exportOrdersIsOpen: true})}
                                        tooltip='Ritten exporteren'
                                    >
                                        <i className='mdi mdi-file-export' />
                                    </Button>

                                    {selectedOrders.length === 1 &&
                                        <Button
                                            variant='outline-white'
                                            onClick={() => this.onClickCopy()}
                                            tooltip='Rit kopiëren'
                                        >
                                            <i className='mdi mdi-content-copy' />
                                        </Button>
                                    }

                                    <DropdownButton
                                        variant='outline-white'
                                        value={printerPageSize}
                                        options={[
                                            {title: 'Papierformaat A6', value: 'A6'},
                                            {title: 'Papierformaat 10x21', value: '10x21'},
                                            {title: 'Papierformaat A5', value: 'A5'},
                                            {title: 'Papierformaat A4 | 4x A6', value: 'A4'},
                                            {title: 'Papierformaat A4 | 3x 10x21', value: 'A410x21'},
                                            {title: 'Papierformaat A4 | 2x A5', value: 'A4A5'}
                                        ]}
                                        onChange={this.onChangePrinterPageSize.bind(this)}
                                        onClick={() => this.onClickPrintLabels()}
                                        tooltip='Labels printen'
                                    >
                                        <i className='mdi mdi-printer' />
                                    </DropdownButton>

                                    <Button
                                        variant='outline-white'
                                        onClick={this.onClickCalculatePrice.bind(this)}
                                        tooltip='Prijs aanpassen'
                                    >
                                        <i className='mdi mdi-currency-eur' />
                                    </Button>

                                    <Button
                                        variant='outline-white'
                                        onClick={this.onClickOpenTimeslotPopup.bind(this)}
                                        tooltip='Tijdvak wijzigen'
                                    >
                                        <i className='mdi mdi-clock-edit-outline' />
                                    </Button>

                                    <Button
                                        variant='outline-white'
                                        onClick={() => this.onClickRemove()}
                                        tooltip='Ritten verwijderen'
                                    >
                                        <i className='mdi mdi-delete' />
                                    </Button>

                                    {reseller.settings.administration.checkOrders && user.permissions.invoices && view === 'orders' &&
                                        <Button
                                            variant='outline-white'
                                            onClick={this.onClickCheck.bind(this)}
                                            tooltip='Ritten accorderen'
                                        >
                                            <i className='mdi mdi-check' />
                                        </Button>
                                    }

                                    {selectedOrders.length > 2 &&
                                        <Button
                                            variant='outline-white'
                                            onClick={this.onClickAutoSetMessengers.bind(this)}
                                            tooltip='Automatisch routes berekenen'
                                        >
                                            <i className='mdi mdi-map-marker-path' />
                                        </Button>
                                    }

                                    <SelectRoute
                                        style={{width: 160, marginLeft: 12}}
                                        variant='outline-white'
                                        label='Wijzig route'
                                        usersObject={usersObject}
                                        routes={routes}
                                        onChange={(event) => this.onChangeRoute(null, event)}
                                    />
                                </Column>

                                <Column style={{flex: 0, height: 36, marginBottom: 6}}>
                                    <Button
                                        variant='outline-white'
                                        tooltip='Aangeleverd op hub'
                                        icon='mdi mdi-package-variant-closed-check'
                                        onClick={() => {
                                            if (selectedOrders.length === 1) {
                                                const order = _.find(orders, (order) => order._id === selectedOrders[0])

                                                if (order) {
                                                    this.atHubOrderPopup.open(order)
                                                }
                                            } else {
                                                this.atHubOrderPopup.open(selectedOrders)
                                            }
                                        }}
                                    />

                                    <Button
                                        variant='outline-white'
                                        tooltip='Herplannen'
                                        icon='mdi mdi-calendar'
                                        onClick={() => {
                                            if (selectedOrders.length === 1) {
                                                const order = _.find(orders, (order) => order._id === selectedOrders[0])

                                                if (order) {
                                                    this.rescheduleOrderPopup.open(order)
                                                }
                                            } else {
                                                this.rescheduleOrderPopup.open(selectedOrders)
                                            }
                                        }}
                                    />

                                    {!reseller.isShop &&
                                        <Button
                                            variant='outline-white'
                                            tooltip='In bewaring'
                                            icon='mdi mdi-archive'
                                            onClick={() => this.onHoldOrderPopup.open(selectedOrders)}
                                        />
                                    }

                                    {!reseller.isShop &&
                                        <Button
                                            variant='outline-white'
                                            tooltip='Retour'
                                            icon='mdi mdi-keyboard-return'
                                            onClick={() => this.returnOrderPopup.open(selectedOrders)}
                                        />
                                    }

                                    <DropdownActionButton
                                        variant='outline-white'
                                        icon='mdi mdi-close-circle'
                                        options={[
                                            {label: 'Niet aangeleverd', onClick: () => {
                                                if (selectedOrders.length === 1) {
                                                    const order = _.find(orders, (order) => order._id === selectedOrders[0])

                                                    if (order) {
                                                        this.notSuppliedOrderPopup.open(order)
                                                    }
                                                } else {
                                                    this.notSuppliedOrderPopup.open(selectedOrders)
                                                }
                                            }},
                                            {label: 'Manco', onClick: () => {
                                                if (selectedOrders.length === 1) {
                                                    const order = _.find(orders, (order) => order._id === selectedOrders[0])

                                                    if (order) {
                                                        this.mancoOrderPopup.open(order)
                                                    }
                                                } else {
                                                    this.mancoOrderPopup.open(selectedOrders)
                                                }
                                            }},
                                            {label: 'Annuleren', onClick: () => {
                                                if (selectedOrders.length === 1) {
                                                    const order = _.find(orders, (order) => order._id === selectedOrders[0])

                                                    if (order?.type === 'retour') {
                                                        this.onClickCancelRetour(order._id)
                                                    } else if (order) {
                                                        this.cancelOrderPopup.open(order)
                                                    }
                                                } else {
                                                    this.cancelOrderPopup.open(selectedOrders)
                                                }
                                            }}
                                        ]}
                                    />

                                    <Button
                                        variant='outline-white'
                                        label='Ophalen / Bezorgen'
                                        style={{minWidth: 155}}
                                        onClick={() => {
                                            if (selectedOrders.length === 1) {
                                                const order = _.find(orders, (order) => order._id === selectedOrders[0])

                                                if (order) {
                                                    this.pickupDeliverPopup.open(order, reseller, customers[order.customer])
                                                }
                                            } else {
                                                this.completeOrderPopup.open(selectedOrders)
                                            }
                                        }}
                                    />
                                </Column>
                            </>
                        }
                    </Row>
                </Panel>

                {view === 'orders' &&
                    <div style={{flex: 1, marginTop: 24, marginLeft: 24, marginRight: 24}}>
                        <Table
                            tableName='dashboard'
                            forceUpdate
                            columns={this.columns()}
                            rows={Object.values(filteredOrders)}
                            selectedRows={selectedOrders}
                            loading={ordersLoading}
                            onChange={this.onChangeTable.bind(this)}
                            sortKey='endTime'
                            ref={(ref) => this.table = ref}
                        />
                    </div>
                }

                {view === 'routes' &&
                    <Planning
                        reseller={reseller}
                        user={user}
                        viewOrder={(...args) => this.viewOrderModal.open(...args)}
                    />
                }

                <ViewOrderModal
                    reseller={reseller}
                    user={user}
                    history={history}
                    usersObject={usersObject}
                    routes={routes}
                    customers={customers}
                    addressBook={addressBook}
                    editOrder={(...args) => this.editOrderModal.open(...args)}
                    onChangeRoute={this.onChangeRoute.bind(this)}
                    onClickCopy={this.onClickCopy.bind(this)}
                    onClickRemove={this.onClickRemove.bind(this)}
                    onClickPrintLabels={this.onClickPrintLabels.bind(this)}
                    match={this.props.match}
                    ref={(modal) => this.viewOrderModal = modal}
                />

                <EditOrderModal
                    reseller={reseller}
                    customers={customers}
                    addressBook={addressBook}
                    viewOrder={(...args) => this.viewOrderModal.open(...args)}
                    ref={(modal) => this.editOrderModal = modal}
                />

                <OrderScanModal
                    user={user}
                    history={history}
                    customers={customers}
                    users={usersObject}
                    carriers={carriers}
                    reseller={reseller}
                    routes={routes}
                    filteredOrders={filteredOrders}
                    printerPageSize={printerPageSize}
                    ref={(ref) => this.scanModal = ref}
                />

                <ImportOrders
                    reseller={reseller}
                    user={user}
                    customers={customers}
                    ref={(modal) => this.importModal = modal}
                    addressBook={addressBook}
                />

                <ExportOrders
                    reseller={reseller}
                    onChange={onUpdateReseller}
                    onClickExport={this.onClickExport.bind(this)}
                    open={exportOrdersIsOpen}
                    onClose={() => this.setState({exportOrdersIsOpen: false})}
                />

                <NotSuppliedOrderPopup ref={(ref) => this.notSuppliedOrderPopup = ref} />
                <MancoOrderPopup ref={(ref) => this.mancoOrderPopup = ref} />
                <CancelOrderPopup ref={(ref) => this.cancelOrderPopup = ref} />
                <OnHoldOrderPopup ref={(ref) => this.onHoldOrderPopup = ref} />
                <AtHubOrderPopup ref={(ref) => this.atHubOrderPopup = ref} />
                <RescheduleOrderPopup reseller={reseller} ref={(ref) => this.rescheduleOrderPopup = ref} />
                <ChangeTimeslotPopup ref={(ref) => this.changeTimeslotPopup = ref} />
                <PickupDeliverPopup ref={(ref) => this.pickupDeliverPopup = ref} />
                <ReturnOrderPopup reseller={reseller} ref={(ref) => this.returnOrderPopup = ref} />
                <CompleteOrderPopup reseller={reseller} orders={orders} ref={(ref) => this.completeOrderPopup = ref} />
                <Popup ref={(ref) => this.popup = ref} />
                <AlertPopup ref={(ref) => this.alertPopup = ref} />
                <AutoSetMessengerPopup reseller={reseller} ref={(ref) => this.autoSetMessengerPopup = ref} />
                <EditRouteModal reseller={reseller} users={usersObject} ref={(ref) => this.editRouteModal = ref} />

                <Prompt ref={(ref) => this.prompt = ref} />
                <PrintPopup ref={(ref) => this.printPopup = ref} />
                <PrintModal ref={(ref) => this.printModal = ref} />
                <EditPricePopup reseller={reseller} ref={(ref) => this.editPricePopup = ref} />
            </FlexColumn>
        )
    }
}

export default withVehiclesHook(Dashboard)
