import React from 'react'

import {Divider, H3, H6} from '../../../../components/UI/index.js'
import {Toggle, Input, Select, Button, Alert} from '../../../../components/UI/index.js'
import LoginActions from '../../../../actions/LoginActions.js'

class CCVShopSettings extends React.Component {
    constructor(props) {
        super(props)

        this.state = {error: ''}
    }

    onChangeChecked(key, event) {
        const {user, onChange} = this.props

        user.settings.webshops.ccvshop[key] = event.target.checked

        onChange(user)
    }

    onChangeValue(key, event) {
        const {user, onChange} = this.props

        user.settings.webshops.ccvshop[key] = event.target.value

        onChange(user)
    }

    onVerifyConnection() {
        const {user, onChange} = this.props

        this.setState({loading: true, verified: false, error: ''})

        onChange(user, true)

        setTimeout(() => { // wait for saving
            LoginActions.verifyWebshop('ccvshop', (err) => {
                if (err) {
                    this.setState({loading: false, error: `Verificatie niet succesvol, controleer de ingevulde gegevens. Foutmelding: ${err}`})
                } else {
                    this.setState({loading: false, error: '', verified: true})
                }
            })
        }, 1000)
    }

    render() {
        const {user} = this.props
        const {error, verified, loading} = this.state

        return (
            <div>
                <H3>CCV Shop</H3>

                <H6>Instellingen</H6>
                <Divider />

                <Toggle
                    label='Actief'
                    checked={user.settings.webshops.ccvshop.active}
                    onChange={this.onChangeChecked.bind(this, 'active')}
                />

                {user.settings.webshops.ccvshop.active &&
                    <>
                        <Input
                            label='Webshop URL'
                            value={user.settings.webshops.ccvshop.url}
                            onChange={this.onChangeValue.bind(this, 'url')}
                        />

                        <Input
                            label='API Key'
                            info='Deze kun je aanmaken in je CCVShop omgeving.'
                            value={user.settings.webshops.ccvshop.apiKey}
                            onChange={this.onChangeValue.bind(this, 'apiKey')}
                        />

                        <Input
                            label='API Secret'
                            info='Deze kun je aanmaken in je CCVShop omgeving.'
                            value={user.settings.webshops.ccvshop.apiSecret}
                            onChange={this.onChangeValue.bind(this, 'apiSecret')}
                        />

                        <Select
                            label='Update order status in CCVShop'
                            info=''
                            value={user.settings.webshops.ccvshop.updateStatus}
                            onChange={this.onChangeValue.bind(this, 'updateStatus')}
                        >
                            <option value=''>Nooit</option>
                            <option value='confirm'>Na bevestigen</option>
                            <option value='afterSent'>Na verzenden</option>
                        </Select>

                        <Button
                            variant='text'
                            style={{marginBottom: 12, marginLeft: 0}}
                            loading={loading}
                            onClick={this.onVerifyConnection.bind(this)}
                        >
                            Verifieer
                        </Button>

                        {error && (
                            <Alert onClose={() => this.setState({error: ''})} variant='error'>{error}</Alert>
                        )}

                        {!error && verified && (
                            <Alert onClose={() => this.setState({verified: false})}>Verificatie succesvol.</Alert>
                        )}

                    </>

                }
            </div>
        )
    }
}

export default (CCVShopSettings)
