import React from 'react'

import Reflux from 'reflux-react-16'
import _ from 'lodash'

import {Badge, Button, Card, Divider, H3, H6, S2, P, Panel, Row, Colors} from '../../../../components/UI/index.js'
import {Toggle} from '../../../../components/UI/index.js'
import CarrierModal from '../../../../components/resellers/CarrierModal.js'

class ParcelCarriers extends Reflux.Component {
    constructor(props) {
        super(props)

        this.state = {carrier: props.reseller.name}
    }

    onChangeCarrierSettings(carrierName, settings) {
        const {reseller, onChange} = this.props
        reseller.settings.parcels.carriers[carrierName] = {...settings}
        onChange(reseller, `parcels.carriers.${carrierName}`)

        reseller.settings.parcels.products['Aangetekend'] = reseller.settings.parcels.products['Aangetekend'] || {}

        if (carrierName === 'DHL' && settings.signedEnabled && !reseller.settings.parcels.products['Aangetekend']['DHL']) {
            reseller.settings.parcels.products['Aangetekend']['DHL'] = true
            onChange(reseller, 'parcels.products')
        }

        if (carrierName === 'DHL-MSG' && settings.signedEnabled && !reseller.settings.parcels.products['Aangetekend']['DHL-MSG']) {
            reseller.settings.parcels.products['Aangetekend']['DHL-MSG'] = true
            onChange(reseller, 'parcels.products')
        }

        if (carrierName === 'PostNL-MSG') {
            if (settings.signedEnabled && !reseller.settings.parcels.products['Aangetekend']['PostNL-MSG']) {
                reseller.settings.parcels.products['Aangetekend']['PostNL-MSG'] = true
            }

            if (settings.signedLetterEnabled && !reseller.settings.parcels.products['Aangetekende brief']['PostNL-MSG']) {
                reseller.settings.parcels.products['Aangetekende brief']['PostNL-MSG'] = true
            }

            onChange(reseller, 'parcels.products')
        }

        if (carrierName === 'PostNL-QLS') {
            if (settings.signedEnabled && !reseller.settings.parcels.products['Aangetekend']['PostNL-QLS']) {
                reseller.settings.parcels.products['Aangetekend']['PostNL-QLS'] = true
            }

            if (settings.signedLetterEnabled && !reseller.settings.parcels.products['Aangetekende brief']['PostNL-QLS']) {
                reseller.settings.parcels.products['Aangetekende brief']['PostNL-QLS'] = true
            }

            onChange(reseller, 'parcels.products')
        }

        if (carrierName === 'PostNL-onlineparcel' && settings.signedEnabled && !reseller.settings.parcels.products['Aangetekend']['PostNL-onlineparcel']) {
            reseller.settings.parcels.products['Aangetekend']['PostNL-onlineparcel'] = true
            onChange(reseller, 'parcels.products')
        }
    }

    onEnablePartner() {
        const {reseller, onChange} = this.props

        reseller.settings.parcels.carriers.isVeloydPartner = !reseller.settings.parcels.carriers.isVeloydPartner

        onChange(reseller, 'parcels.carriers.isVeloydPartner')
    }

    onSendAcceptPartner(partner) {
        const {reseller, onChange} = this.props

        reseller.settings.parcels.carriers.partners = reseller.settings.parcels.carriers.partners || []

        const index = reseller.settings.parcels.carriers.partners.indexOf(partner)

        if (index === -1) {
            reseller.settings.parcels.carriers.partners.push(partner)
        } else {
            reseller.settings.parcels.carriers.partners.splice(index, 1)
        }

        onChange(reseller, 'parcels.carriers.partners')
    }

    render() {
        const {reseller} = this.props

        const [onlineParcelCarriers, carriers] = _.partition(this.props.carriers, (carrier) => carrier.platform === 'onlineparcel')

        return (
            <>
                <Panel style={{padding: 20, width: 1165}}>
                    <H3>Vervoerders</H3>

                    <div style={{display: 'flex', flexWrap: 'wrap', marginBottom: 24}}>
                        {carriers.map((carrier) => {
                            if (carrier.type !== 'partner' || (reseller.settings.parcels.carriers.partners.indexOf(carrier.name) > -1) && carrier.isPartner) {
                                const settings = reseller.settings.parcels.carriers[carrier.name] ? JSON.parse(JSON.stringify(reseller.settings.parcels.carriers[carrier.name])) : {}

                                if (settings.enabled) {
                                    return (
                                        <Card style={{margin: 12, padding: 12, width: 350}} key={carrier.name}>
                                            <div style={{display: 'flex', alignItems: 'center', marginBottom: 24}}>
                                                <img style={{marginRight: 24, objectFit: 'contain'}} src={carrier.logo} width={64} height={64} />

                                                <H6>{carrier.displayName}</H6>
                                            </div>

                                            <br />
                                            <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                                                {reseller.settings.parcels.carriers[carrier.name] && reseller.settings.parcels.carriers[carrier.name].enabled ?
                                                        <Button variant='text' onClick={() => this.carrierModal.open(carrier.name, settings)}>Instellingen</Button> :
                                                        <Button onClick={() => this.carrierModal.open(carrier.name, settings)}>Activeer</Button>
                                                }
                                            </div>
                                        </Card>
                                    )
                                }
                            }
                        })}
                    </div>

                    <div style={{display: 'flex', flexWrap: 'wrap', marginBottom: 36}}>
                        {carriers.map((carrier) => {
                            if (carrier.name === 'PostNL-MSG' && !reseller.msgPostNLenabled) {
                                return
                            }

                            if (carrier.name === 'DHL-MSG' && !reseller.msgDHLenabled) {
                                return
                            }

                            if (carrier.name.includes('QLS') && !reseller.qlsEnabled) {
                                return
                            }

                            if (carrier.type !== 'partner' || (reseller.settings.parcels.carriers.partners.indexOf(carrier.name) > -1) && carrier.isPartner) {
                                const settings = reseller.settings.parcels.carriers[carrier.name] ? JSON.parse(JSON.stringify(reseller.settings.parcels.carriers[carrier.name])) : {}

                                if (!settings.enabled) {
                                    return (
                                        <Card style={{margin: 12, padding: 12, width: 350}} key={carrier.name}>
                                            <div style={{display: 'flex', alignItems: 'center', marginBottom: 36}}>
                                                <img style={{marginRight: 24, objectFit: 'contain'}} src={carrier.logo} width={64} height={64} />

                                                <H6>{carrier.displayName}</H6>
                                            </div>

                                            <br />
                                            <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                                                {reseller.settings.parcels.carriers[carrier.name] && reseller.settings.parcels.carriers[carrier.name].enabled ?
                                                        <Button variant='text' onClick={() => this.carrierModal.open(carrier.name, settings)}>Instellingen</Button> :
                                                        <Button onClick={() => this.carrierModal.open(carrier.name, settings)}>Activeer</Button>
                                                }
                                            </div>
                                        </Card>
                                    )
                                }
                            }
                        })}
                    </div>

                    <H6>Veloyd vervoerders</H6>
                    <Divider />
                    <S2>Als Veloyd gebruiker kun je pakketten versturen met andere Veloyd gebruikers. Hiervoor moet onderstaande optie worden geactiveerd, daarna kun je een verzoek sturen naar een andere Veloyd gebruiker. Zodra dit verzoek is geaccepteerd verschijnt de vervoerder hierboven en kan deze worden geactiveerd.</S2>
                    <br />

                    <Toggle
                        style={{width: 500}}
                        label='Zichtbaar als vervoerder voor andere Veloyd gebruikers'
                        checked={reseller.settings.parcels.carriers.isVeloydPartner}
                        onChange={this.onEnablePartner.bind(this)}
                    />

                    {reseller.settings.parcels.carriers.isVeloydPartner &&
                        <>
                            <br />

                            {carriers.map((carrier) => {
                                if (carrier.type === 'partner') {
                                    const checked = reseller.settings.parcels.carriers.partners.indexOf(carrier.name) > -1

                                    return (
                                        <div
                                            key={carrier.name}
                                            style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: 500}}
                                        >
                                            <P>{carrier.displayName}</P>
                                            {checked &&
                                                <>
                                                    <Badge variant={carrier.isPartner ? 'success' : 'info'}>
                                                        {carrier.isPartner ?
                                                            'Koppeling actief' :
                                                            'Verzoek verstuurd'
                                                        }
                                                    </Badge>
                                                    <Button
                                                        variant='text'
                                                        onClick={this.onSendAcceptPartner.bind(this, carrier.name)}
                                                    >
                                                        Deactiveer
                                                    </Button>
                                                </>
                                            }

                                            {!checked && carrier.isPartner &&
                                                <>
                                                    <Badge variant='info'>
                                                        Partner verzoek ontvangen
                                                    </Badge>
                                                    <Button
                                                        variant='text'
                                                        onClick={this.onSendAcceptPartner.bind(this, carrier.name)}
                                                    >
                                                        Accepteer
                                                    </Button>
                                                </>
                                            }

                                            {!checked && !carrier.isPartner &&
                                                <>
                                                    <Button
                                                        variant='text'
                                                        onClick={this.onSendAcceptPartner.bind(this, carrier.name)}
                                                    >
                                                        Activeer
                                                    </Button>
                                                </>
                                            }

                                        </div>
                                    )
                                }
                            })}

                            <br />
                        </>
                    }

                    <CarrierModal
                        ref={(ref) => this.carrierModal = ref}
                        reseller={reseller}
                        carriers={this.props.carriers}
                        onSubmit={this.onChangeCarrierSettings.bind(this)}
                    />
                </Panel>
                {/* No onlineparcel for non Business Post Hoogeveen (Stark) and Veloyd resellers */}
                {(reseller.name === 'Stark' || reseller.name === 'Veloyd') &&
                    <Panel style={{marginTop: 36, padding: 20, width: 1165}}>
                        <H3>Vervoerders via platform</H3>

                        <P>Verschillende vervoerders zijn ook te activeren via externe platforms. Om gebruik te maken van deze vervoerders dien je zelf een account aan te vragen op dit platform.</P>
                        <Card style={{margin: 12, padding: '12px 24px', width: 350}}>
                            <Row style={{justifyContent: 'space-evenly', gap: 12}}>
                                {onlineParcelCarriers.map((carrier) => (
                                    <div style={{textAlign: 'center'}} key={carrier.name}>
                                        <img style={{objectFit: 'contain', ...(!reseller.settings.parcels.carriers[carrier.name]?.enabled && {filter: 'grayscale(100%)'})}} src={carrier.logo} width={64} height={64} />
                                        <P>{carrier.displayName.replace(' - Onlineparcel', '')}</P>
                                    </div>
                                ))}
                            </Row>
                            <Divider style={{background: Colors.grey30, marginTop: 6, marginBottom: 6}} />
                            <P style={{fontWeight: 700, color: Colors.black, fontSize: 12}}>Platform</P>
                            <Row style={{gap: 24, alignItems: 'center'}}>
                                <img src='/images/onlineparcel.png' style={{objectFit: 'contain'}} width={64} height={64} />
                                <P>Onlineparcel.nl</P>
                            </Row>
                            <Row style={{justifyContent: 'flex-end'}}>
                                <Button
                                    onClick={() => {
                                        const firstCarrier = onlineParcelCarriers[0]
                                        const firstCarrierSettings = reseller.settings.parcels.carriers[firstCarrier.name] ? structuredClone(reseller.settings.parcels.carriers[firstCarrier.name]) : {}
                                        this.carrierModal.open(firstCarrier.name, firstCarrierSettings, onlineParcelCarriers, 'Onlineparcel')
                                    }}
                                    variant='text'
                                >
                                    Instellingen
                                </Button>
                            </Row>
                        </Card>
                    </Panel>
                }
            </>
        )
    }
}

export default (ParcelCarriers)
