// @ts-check
import React from 'react'

import LoginActions from '../../../../actions/LoginActions.js'

import {
    Alert,
    Button,
    H3,
    Icon,
    P,
    Card,
    Panel,
    Input,
    Row,
    Colors,
    H6,
    ToolTip,
    Popup
} from '../../../../components/UI/index.js'

import DefaultPartnerSettingsPanel from '../../../../components/resellers/DefaultPartnerSettingsPanel.js'
import PartnerSettingsPanel from '../../../../components/resellers/PartnerSettingsPanel.js'

class PartnerSettings extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            showDefaultSettings: false,
            selectedPartnerSettings: null,
            error: ''
        }
    }

    onSaveDefaultPartnerSettings(defaultPartnerSettings) {
        const reseller = {...this.props.reseller}

        reseller.settings.defaultPartnerSettings = defaultPartnerSettings

        this.props.onSave(reseller, 'defaultPartnerSettings')
    }

    onSavePartnerSettings(partnerSettings) {
        const reseller = {...this.props.reseller}

        const index = reseller.settings.partnerSettings.findIndex((settings) => settings._id === partnerSettings._id)

        if (index > -1) {
            reseller.settings.partnerSettings[index] = partnerSettings
            this.props.onSave(reseller, `partnerSettings.${index}`)
        }
    }

    async onClickCopyPartnerKey() {
        if (navigator && navigator.clipboard) {
            try {
                await navigator.clipboard.writeText(this.props.reseller.settings.partnerKey)

                clearTimeout(this.tooltipTimeout)

                this.setState({showCopyTooltip: true})

                this.tooltipTimeout = setTimeout(() => this.setState({showCopyTooltip: false}), 2000)
            } catch (err) {
                console.log(err)
            }
        }
    }

    render() {
        /** @type Reseller */
        const reseller = this.props.reseller
        const {partners} = this.props
        const {showDefaultSettings, selectedPartnerSettings} = this.state

        return (
            <div style={{display: 'flex', gap: 24}}>
                <Panel shadow='true' style={{height: 'fit-content', width: 800, padding: 24}}>
                    <H3>Partner koppelingen</H3>

                    <P style={{marginBottom: 12}}>
                        Als Veloyd gebruiker kun je pakketten versturen en ritten uitwisselen met andere Veloyd vervoerders. Deze koppeling kun je activeren door direct te zoeken op de API key van een partner vervoerder. Deze partner API key kun je opvragen bij de partner.
                    </P>

                    <P style={{marginBottom: 12}}>
                        Hieronder staat je eigen API key, deze kun je delen met partner vervoerders. Deze api key is niet bedoeld voor koppelingen voor klanten of webshops.
                    </P>

                    <H6>Eigen instellingen</H6>

                    <Row style={{alignItems: 'center', marginTop: 24, marginBottom: 24}}>
                        <Input
                            style={{width: '50%', marginBottom: 0}}
                            label='Eigen API key partnerkoppelingen'
                            value={reseller.settings.partnerKey}
                            readOnly
                        />

                        <Button variant='outline-white' onClick={() => this.onClickCopyPartnerKey()}>
                            <i className='mdi mdi-content-copy' />
                        </Button>
                    </Row>

                    <Card
                        shadow='true'
                        style={{display: 'flex', alignItems: 'center', marginBottom: 36}}
                        selected={showDefaultSettings}
                    >
                        <img style={{objectFit: 'contain', height: 24, maxWidth: 48, marginRight: 24}} src={reseller.settings.logo} />

                        <P style={{flex: 1}}>{reseller.settings.accountName || reseller.name}</P>

                        <Button
                            variant='text'
                            onClick={() => this.setState({showDefaultSettings: true, selectedPartnerSettings: null})}
                        >
                            Instellingen
                        </Button>
                    </Card>

                    <H6 style={{marginTop: 36}}>Partner accounts</H6>

                    <Row style={{alignItems: 'center', marginTop: 24, marginBottom: 24}}>
                        <Input
                            style={{width: '50%', marginBottom: 0}}
                            label='Partner toevoegen op API key'
                            value={this.state.partnerKey}
                            onChange={(event) => this.setState({partnerKey: event.target.value})}
                        />

                        <Button variant='outline-white' onClick={() => LoginActions.addPartner(this.state.partnerKey, (err) => this.setState({error: err}))}>Partner toevoegen</Button>
                    </Row>

                    {this.state.error &&
                        <Alert variant='danger'>{this.state.error}</Alert>
                    }

                    {reseller.settings.partnerSettings?.map((partnerSettings) => {
                        const partner = partners?.[partnerSettings._id]

                        if (partner) {
                            return (
                                <Card
                                    shadow='true'
                                    key={partner._id}
                                    style={{display: 'flex', alignItems: 'center', marginBottom: 6}}
                                    selected={selectedPartnerSettings?._id === partnerSettings._id}
                                >
                                    <img style={{objectFit: 'contain', height: 24, maxWidth: 48, marginRight: 24}} src={partner.logo} />

                                    <P style={{flex: 1}}>{partner.name}</P>

                                    {partnerSettings.status === 'pending' &&
                                        <>
                                            <Button
                                                variant='outline'
                                                onClick={async () => {
                                                    await this.popup.open('Partner verzoek', 'Weet je zeker dat je dit verzoek wilt accepteren? ')

                                                    LoginActions.handlePartnerRequest(partner._id, 'accepted', (err) => {
                                                        if (err) {
                                                            this.popup.setError(err)
                                                        } else {
                                                            this.popup.close()
                                                        }
                                                    })
                                                }}
                                            >
                                                Accepteer
                                            </Button>

                                            <Button
                                                variant='outline'
                                                onClick={async () => {
                                                    await this.popup.open('Partner verzoek', 'Weet je zeker dat je dit verzoek wilt afwijzen? ')

                                                    LoginActions.handlePartnerRequest(partner._id, 'rejected', (err) => {
                                                        if (err) {
                                                            this.popup.setError(err)
                                                        } else {
                                                            this.popup.close()
                                                        }
                                                    })
                                                }}
                                            >
                                                Afwijzen
                                            </Button>
                                        </>
                                    }

                                    {partnerSettings.status === 'inactive' &&
                                        <Button
                                            variant='outline'
                                            onClick={async () => {
                                                await this.popup.open('Partner verwijderen', 'Weet je zeker dat je deze partner wilt verwijderen? ')
                                                LoginActions.handlePartnerRequest(partner._id, 'remove', (err) => {
                                                    if (err) {
                                                        this.popup.setError(err)
                                                    } else {
                                                        this.popup.close()
                                                    }
                                                })
                                            }}
                                        >Verwijderen
                                        </Button>
                                    }

                                    {partnerSettings.status === 'active' &&
                                        <Button
                                            variant='text'
                                            onClick={() => this.setState({showDefaultSettings: false, selectedPartnerSettings: partnerSettings})}
                                        >
                                            Instellingen
                                        </Button>
                                    }

                                    <Icon icon='mdi mdi-circle' size='sm' color={partnerSettings.status === 'active' ? Colors.successBright : ['pending', 'requested'].includes(partnerSettings.status) ? Colors.warningBright : Colors.errorBright} />
                                </Card>
                            )
                        }
                    })}

                    <ToolTip visible={this.state.showCopyTooltip} text='Gekopieerd naar clipboard'></ToolTip>

                </Panel>

                {selectedPartnerSettings &&
                    <PartnerSettingsPanel
                        reseller={reseller}
                        partnerSettings={selectedPartnerSettings}
                        partners={partners}
                        onClose={() => this.setState({selectedPartnerSettings: null})}
                        onRemove={async () => {
                            await this.popup.open('Partner deactiveren', 'Weet je zeker dat je deze partner wilt deactiveren? ')

                            this.setState({selectedPartnerSettings: null})
                            LoginActions.removePartner(selectedPartnerSettings._id, (err) => {
                                if (err) {
                                    this.popup.setError(err)
                                } else {
                                    this.popup.close()
                                }
                            })
                        }}
                        onSave={(partnerSettings) => this.onSavePartnerSettings(partnerSettings)}
                    />
                }

                {showDefaultSettings &&
                    <DefaultPartnerSettingsPanel
                        reseller={reseller}
                        defaultPartnerSettings={reseller.settings.defaultPartnerSettings}
                        onClose={() => this.setState({showDefaultSettings: false})}
                        onSave={(defaultPartnerSettings) => this.onSaveDefaultPartnerSettings(defaultPartnerSettings)}
                    />
                }

                <Popup ref={(ref) => this.popup = ref} />
            </div>

        )
    }
}

export default PartnerSettings
