import React from 'react'

import {Popover, ListItem, Button} from './index.js'

class DropdownActionButton extends React.Component {
    onClickShowMenu(event) {
        event.stopPropagation()
        this.popover.show()
    }

    onClickOption(option, event) {
        event.stopPropagation()

        if (typeof option.onClick === 'function') {
            event.index = this.props.index
            option.onClick(event)
        }

        this.popover.close()
    }

    render() {
        const {options, disabled} = this.props

        return (
            <Popover
                ref={(ref) => this.popover = ref}
                disabled={disabled}
                content={
                    <>
                        {options && options.map((option, index) => {
                            if (option) {
                                return (
                                    <ListItem
                                        key={`${index}button`}
                                        size='sm'
                                        disabled={option.disabled}
                                        onClick={this.onClickOption.bind(this, option)}
                                    >
                                        {option.label || option.value}
                                    </ListItem>
                                )
                            }
                        })}
                    </>
                }
            >
                <Button
                    {...this.props}
                    onClick={this.onClickShowMenu.bind(this)}
                    afterIcon='mdi mdi-menu-down'
                />
            </Popover>
        )
    }
}

export default DropdownActionButton
