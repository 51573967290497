// @ts-check

import React from 'react'

import _ from 'lodash'

import {Colors, Input, CarrierSelector, DraggableList, Select, CurrencyInput, SaveBeforeExitPopup, Popup, Toggle, Card} from '../../UI/index.js'
import {Alert, Button, IconButton, Modal, H4, S1, S2} from '../../UI/index.js'

import PriceRuleModal from './PriceRuleModal.js'
import CategoryModal from './CategoryModal.js'
import PriceScaleModal from './PriceScaleModal.js'

class PriceTableModal extends React.Component {
    constructor(props) {
        super(props)
        this.initialState = {
            open: false,
            name: '',
            oldName: '',
            /**
             * @type {ParcelPriceTable} priceTable
             */
            priceTable: {},
            /**
             * @type {{name: string; [key: string]: *}} selectedCarrier
             */
            selectedCarrier: props.carriers[0] || {},
            selectedPriceTable: 'Standaard',
            error: ''
        }

        this.state = this.initialState
    }

    open(name, oldName, priceTable) {
        this.setState({open: true, name, oldName, priceTable, initialPriceTable: priceTable})
    }

    close(showWarning) {
        if (showWarning) {
            this.saveBeforeExitPopup.open(this.onSubmit.bind(this), () => {
                this.setState(this.initialState)
            })
        } else {
            this.setState(this.initialState)
        }
    }

    onChangeName(event) {
        if (!event.target.value.includes('.')) {
            this.setState({name: event.target.value})
        }
    }

    onClickPasteCarrierPriceTable(carrier) {
        const {priceTable} = this.state
        const {copiedPriceTable, onClickCopyCarrierPriceTable} = this.props

        priceTable[carrier.name] = copiedPriceTable

        this.setState({priceTable})

        onClickCopyCarrierPriceTable()
    }

    onChangeCarrier(event) {
        const {carriers} = this.props

        const selectedCarrier = _.find(carriers, {name: event.target.value})

        this.setState({selectedCarrier})
    }

    /**
     * @param {{name: string;}} carrier
     * @param {ParcelProducts} product
     * @param {number} index
     * @param {ParcelPriceTableProductCountryGroup} priceRule
     */
    onEditPriceRule(carrier, product, index, priceRule) {
        const {priceTable} = this.state

        priceTable[carrier.name][product].countryGroups[index] = priceRule

        this.setState({priceTable})
    }

    /**
     * @param {{name: string;}} carrier
     * @param {ParcelProducts} product
     * @param {number} index
     */
    onRemovePriceRule(carrier, product, index) {
        const {priceTable} = this.state

        priceTable[carrier.name][product].countryGroups.splice(index, 1)

        this.setState({priceTable})
    }

    /**
     *
     * @param {{name: string; [key: string]: *}} carrier
     * @param {ParcelProducts} product
     * @param {number} index
     * @param {ParcelPriceTableProductCountryGroup} priceRule
     */
    onClickOpenPriceRuleModal(carrier, product, index, priceRule) {
        const {priceTable} = this.state
        let countries = [...carrier.countries]

        if (product !== 'Standaard zending') {
            product.split(', ').map((product) => {
                countries = _.intersection(countries, carrier.products[product].countries)
            })
        }

        if (countries.length > 1) {
            countries.push('Overige')
        }

        const usedCountries = []
        priceTable[carrier.name][product].countryGroups.map((priceRule) => {
            usedCountries.push(...priceRule.countries)
        })
        countries = _.uniq(_.difference(countries, usedCountries))
        countries.push(...priceRule.countries)

        this.PriceRuleModal.open(carrier, product, index, priceRule, countries)
    }

    /**
     *
     * @param {{name: string;}} carrier
     * @param {ParcelProducts} product
     * @param {ParcelPriceTableProductCountryGroup[]} priceRules
     */
    onSortPricesRules(carrier, product, priceRules) {
        const {priceTable} = this.state

        priceTable[carrier.name][product].countryGroups = priceRules

        this.setState({priceTable})
    }

    /**
     *
     * @param {{name: string;}} carrier
     * @param {ParcelPriceTableProductCountryGroup} product
     * @param {ParcelProducts} productName
     */
    onClickAddPriceRule(carrier, product, productName) {
        const {priceTable} = this.state

        const priceRule = {
            name: '',
            countries: [],
            type: product.type === 'product' ? 'weight' : 'fee',
            price: '0,00',
            fee: '0,00',
            categories: [
                {
                    name: '',
                    price: '0,00',
                    maxWeight: '2',
                    length: 0,
                    width: 0,
                    height: 0,
                    volume: 0,
                    productCode: '',
                    priceScales: [{
                        price: '0,00'
                    }]
                },
                {
                    name: '',
                    price: '0,00',
                    maxWeight: '5',
                    length: 0,
                    width: 0,
                    height: 0,
                    volume: 0,
                    productCode: '',
                    priceScales: [{
                        price: '0,00'
                    }]
                },
                {
                    name: '',
                    price: '0,00',
                    maxWeight: '10',
                    length: 0,
                    width: 0,
                    height: 0,
                    volume: 0,
                    productCode: '',
                    priceScales: [{
                        price: '0,00'
                    }]
                },
                {
                    name: '',
                    price: '0,00',
                    maxWeight: '20',
                    length: 0,
                    width: 0,
                    height: 0,
                    volume: 0,
                    productCode: '',
                    priceScales: [{
                        price: '0,00'
                    }]
                },
                {
                    name: '',
                    price: '0,00',
                    maxWeight: '31,5',
                    length: 0,
                    width: 0,
                    height: 0,
                    volume: 0,
                    productCode: '',
                    priceScales: [{
                        price: '0,00'
                    }]
                }
            ],
            values: {},
            priceScales: [{
                price: '0,00'
            }]
        }

        priceTable[carrier.name] = priceTable[carrier.name] || {usePriceScales: false}
        priceTable[carrier.name][productName] = priceTable[carrier.name][productName] || {usePriceScales: false, countryGroups: []}
        priceTable[carrier.name][productName].countryGroups = priceTable[carrier.name][productName].countryGroups || []
        priceTable[carrier.name][productName].countryGroups.push(priceRule)


        this.setState({priceTable})

        this.onClickOpenPriceRuleModal(carrier, productName, priceTable[carrier.name][productName].countryGroups.length - 1, priceRule)
    }

    /**
     *
     * @param {{name: string;}} carrier
     * @param {ParcelProducts} product
     * @param {number} priceRule
     * @param {*} event
     */
    onChangeType(carrier, product, priceRule, event) {
        const {priceTable} = this.state

        priceTable[carrier.name][product].countryGroups[priceRule].type = event.target.value

        this.setState({priceTable})
    }

    /**
     *
     * @param {{name: string;}} carrier
     * @param {ParcelProducts} product
     * @param {number} priceRule
     * @param {number} value
     * @param {*} event
     */
    onChangePrice(carrier, product, priceRule, value, event) {
        const {priceTable} = this.state

        if (/^$|^[-]{0,1}[0-9]{0,4}$|^[-]{0,1}[0-9]{0,4}[,][0-9]{0,2}$/.test(event.target.value)) {
            if (value) {
                priceTable[carrier.name][product].countryGroups[priceRule].values[value] = event.target.value
            } else {
                priceTable[carrier.name][product].countryGroups[priceRule].price = event.target.value
            }

            this.setState({priceTable})
        }
    }

    /**
     *
     * @param {{name: string;}} carrier
     * @param {ParcelProducts} product
     * @param {number} priceRule
     */
    onClickAddCategory(carrier, product, priceRule) {
        const {priceTable} = this.state

        priceTable[carrier.name][product].countryGroups[priceRule].categories.push({
            name: '',
            maxWeight: '0',
            length: 0,
            width: 0,
            height: 0,
            volume: 0,
            price: '0,00',
            fee: '0'
        })

        this.setState({priceTable})
    }

    /**
     *
     * @param {*} carrier
     * @param {ParcelProducts} product
     * @param {*} priceRule
     * @param {number} index
     * @param {ParcelPriceTableProductCountryGroup['categories'][0]} category
     */
    onClickOpenCategoryModal(carrier, product, priceRule, index, category) {
        this.categoryModal.open(carrier, product, priceRule, index, category)
    }

    /**
     *
     * @param {{name: string;}} carrier
     * @param {ParcelProducts} product
     * @param {number} priceRule
     * @param {number} index
     * @param {ParcelPriceTableProductCountryGroup['categories'][0]} category
     */
    onEditCategory(carrier, product, priceRule, index, category) {
        const {priceTable} = this.state

        priceTable[carrier.name][product].countryGroups[priceRule].categories[index] = category

        this.setState({priceTable})
    }

    /**
     *
     * @param {{name: string;}} carrier
     * @param {ParcelProducts} product
     * @param {number} priceRule
     * @param {number} index
     */
    onRemoveCategory(carrier, product, priceRule, index) {
        const {priceTable} = this.state

        priceTable[carrier.name][product].countryGroups[priceRule].categories.splice(index, 1)


        this.setState({priceTable})
    }

    /**
     *
     * @param {{name: string;}} carrier
     * @param {ParcelProducts} product
     * @param {number} priceRule
     * @param {number} category
     * @param {*} event
     */
    onChangeCategoryPrice(carrier, product, priceRule, category, event) {
        const {priceTable} = this.state

        if (/^$|^[-]{0,1}[0-9]{0,4}$|^[-]{0,1}[0-9]{0,4}[,][0-9]{0,2}$/.test(event.target.value)) {
            priceTable[carrier.name][product].countryGroups[priceRule].categories[category].price = event.target.value

            this.setState({priceTable})
        }
    }

    /**
     *
     * @param {{name: string;}} carrier
     * @param {ParcelProducts} product
     * @param {number} priceRule
     * @param {*} event
     */
    onChangeFee(carrier, product, priceRule, event) {
        const {priceTable} = this.state

        if (/^$|^[-]{0,1}[0-9]{0,4}$|^[-]{0,1}[0-9]{0,4}[,][0-9]{0,2}$/.test(event.target.value)) {
            priceTable[carrier.name][product].countryGroups[priceRule].fee = event.target.value


            this.setState({priceTable})
        }
    }

    /**
     *
     * @param {{name: string;}} carrier
     * @param {ParcelProducts} product
     * @param {number} priceRule
     * @param {*} event
     */
    onChangeRound(carrier, product, priceRule, event) {
        const {priceTable} = this.state

        priceTable[carrier.name][product].countryGroups[priceRule].round = event.target.value

        this.setState({priceTable})
    }

    onRemove() {
        const {oldName} = this.state
        const {onRemove} = this.props

        this.popup.open('Tarieftabel verwijderen', 'Weet je zeker dat je deze tarieftabel wilt verwijderen?', () => {
            onRemove(oldName)
            this.close()
        })
    }

    onSubmit() {
        const {name, oldName, priceTable} = this.state
        const {onAdd, onChange} = this.props

        if (oldName) {
            onChange(name, oldName, priceTable)
        } else {
            onAdd(name, priceTable)
        }
        this.close()
    }

    render() {
        const {open, name, oldName, selectedCarrier, priceTable, error} = this.state
        const {carriers, reseller, copiedPriceTable, onClickCopyCarrierPriceTable} = this.props

        const enabledCarriers = _.filter(carriers, (carrier) => {
            return reseller.settings.parcels.carriers[carrier.name] && reseller.settings.parcels.carriers[carrier.name].enabled
        })


        let selectedCarrierProducts = []

        if (selectedCarrier && selectedCarrier.products) {
            selectedCarrierProducts = [
                'Standaard zending',
                ..._.keys(selectedCarrier.products).sort()
            ]
        }

        return (
            <Modal style={{width: 'fit-content', minWidth: '95%', maxWidth: '95%', overflowX: 'auto'}} show={open} onClose={this.close.bind(this)}>
                <div style={{display: 'flex', justifyContent: 'space-between'}}>
                    <H4>{`${oldName ? 'Wijzig' : 'Nieuwe'} tarieftabel`}</H4>

                    <IconButton onClick={this.close.bind(this, true)}>
                        <i style={{color: Colors.buttonSolid}} className='mdi mdi-close'/>
                    </IconButton>
                </div>

                <Input
                    label='Naam'
                    value={name}
                    onChange={this.onChangeName.bind(this)}
                    style={{maxWidth: 400}}
                    disabled={name === 'Standaard'}
                />

                <div style={{display: 'flex', alignItems: 'center'}}>
                    <div style={{display: 'flex', width: 330, height: 40}}>
                        <IconButton
                            disabled={!selectedCarrier.name}
                            tooltip={'Kopieer vervoerder tarieven'}
                            onClick={() => onClickCopyCarrierPriceTable(priceTable, selectedCarrier)}
                        >
                            <i className='mdi mdi-content-copy'/>
                        </IconButton>
                        <IconButton
                            disabled={!copiedPriceTable}
                            tooltip={'Plak vervoerder tarieven'}
                            onClick={this.onClickPasteCarrierPriceTable.bind(this, selectedCarrier)}
                        >
                            <i className='mdi mdi-content-paste'/>
                        </IconButton>
                    </div>
                    <CarrierSelector
                        size={45}
                        carriers={enabledCarriers}
                        value={selectedCarrier.name}
                        onChange={this.onChangeCarrier.bind(this)}
                    />
                </div>

                {(process.env.NODE_ENV !== 'production' || ['Veloyd', 'Groenbezorgen', 'Groenbezorgen Oss', 'Groenbezorgen Veghel'].includes(reseller.name)) &&
                    <>
                        <Toggle
                            style={{width: 280}}
                            label='Tariefstaffels'
                            checked={priceTable[selectedCarrier.name]?.usePriceScales}
                            onChange={(event) => {
                                priceTable[selectedCarrier.name] = priceTable[selectedCarrier.name] || {usePriceScales: false}
                                priceTable[selectedCarrier.name].usePriceScales = event.target.checked
                                this.setState({priceTable})
                            }}
                        />

                        {priceTable[selectedCarrier.name]?.usePriceScales && <Alert variant='info'>Wanneer zendingen onder een staffeltarief vallen wordt de prijs pas berekend op het moment van factureren.</Alert>}
                    </>
                }

                {selectedCarrierProducts.map((productName) => {
                    if (productName == 'Standaard zending' || (reseller.settings.parcels.products[productName] && reseller.settings.parcels.products[productName][selectedCarrier.name])) {
                        let product = selectedCarrier.products[productName]

                        if (productName == 'Standaard zending') {
                            product = {type: 'product', countries: selectedCarrier.countries}
                        }

                        return (
                            <Card shadow key={productName} style={{marginBottom: 36, padding: 12}}>
                                <S1 style={{marginBottom: 12}}>{productName}</S1>

                                {(process.env.NODE_ENV !== 'production' || ['Veloyd', 'Groenbezorgen', 'Groenbezorgen Oss', 'Groenbezorgen Veghel'].includes(reseller.name)) && priceTable[selectedCarrier.name]?.usePriceScales && product.type === 'product' &&
                                    <Toggle
                                        style={{width: 280 - 12}}
                                        label='Tariefstaffels'
                                        checked={priceTable[selectedCarrier.name]?.[productName] && priceTable[selectedCarrier.name][productName].usePriceScales}
                                        onChange={(event) => {
                                            priceTable[selectedCarrier.name][productName] = priceTable[selectedCarrier.name][productName] || {usePriceScales: false}
                                            priceTable[selectedCarrier.name][productName].usePriceScales = event.target.checked
                                            priceTable[selectedCarrier.name][productName].countryGroups?.forEach((priceRule) => {
                                                priceRule.priceScales = priceRule.priceScales || [{
                                                    price: priceRule.price
                                                }]

                                                priceRule.categories.forEach((category) => {
                                                    category.priceScales = category.priceScales || [{
                                                        price: category.price
                                                    }]
                                                })
                                            })
                                            this.setState({priceTable})
                                        }}
                                    />
                                }

                                {priceTable[selectedCarrier.name] && priceTable[selectedCarrier.name][productName] &&
                                <DraggableList
                                    items={priceTable[selectedCarrier.name][productName].countryGroups}
                                    renderItem={
                                        /**
                                         *
                                         * @param {ParcelPriceTableProductCountryGroup} priceRule
                                         * @param {*} DragHandle
                                         * @param {*} index
                                         * @returns
                                         */
                                        (priceRule, DragHandle, index) => {
                                            const priceScaleEnabled = priceTable[selectedCarrier.name]?.usePriceScales && priceTable[selectedCarrier.name][productName]?.usePriceScales
                                            return (
                                                <div key={`${selectedCarrier.name}${productName}${index}`} style={{display: 'flex', marginLeft: 15}}>
                                                    <div style={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        flexShrink: 0,
                                                        width: 285,
                                                        minWidth: 285,
                                                        height: 30
                                                    }}
                                                    >
                                                        <DragHandle/>
                                                        <IconButton
                                                            onClick={this.onClickOpenPriceRuleModal.bind(this, selectedCarrier, productName, index, priceRule)}
                                                        >
                                                            <i className='mdi mdi-pencil'/>
                                                        </IconButton>
                                                        <S2>{priceRule.name}</S2>
                                                    </div>

                                                    <div style={{display: 'flex'}}>
                                                        <Select
                                                            style={{width: 150, marginRight: 12}}
                                                            allowEmptyValue
                                                            value={priceRule.type}
                                                            onChange={this.onChangeType.bind(this, selectedCarrier, productName, index)}
                                                        >
                                                            {product.type === 'product' &&
                                                                <option value='fixed'>Vaste prijs</option>
                                                            }

                                                            {product.type === 'product' &&
                                                                <option value='weight'>Categorieën</option>
                                                            }

                                                            {product.type === 'product' &&
                                                                <option value='fixedKg'>Kilo toeslag</option>
                                                            }

                                                            {product.type !== 'product' &&
                                                                <option value='fee'>Toeslag</option>
                                                            }

                                                            {product.type !== 'product' &&
                                                                <option value='feepercent'>Toeslag %</option>
                                                            }
                                                        </Select>

                                                        {priceRule.type === 'weight' && priceRule.categories.map((category, j) => {
                                                            return (
                                                                <div
                                                                    key={j}
                                                                    style={{
                                                                        width: 150,
                                                                        flexShrink: 0,
                                                                        marginRight: 12
                                                                    }}
                                                                >
                                                                    {priceScaleEnabled ? (
                                                                        <Input
                                                                            label=''
                                                                            value={category.name || `t/m ${category.maxWeight} kg`}
                                                                            readOnly
                                                                            append={
                                                                                <IconButton
                                                                                    onClick={this.onClickOpenCategoryModal.bind(this, selectedCarrier, productName, index, j, category)}
                                                                                >
                                                                                    <i className='mdi mdi-pencil'/>
                                                                                </IconButton>
                                                                            }
                                                                        />
                                                                    ) :
                                                                        <CurrencyInput
                                                                            label={category.name || `t/m ${category.maxWeight} kg`}
                                                                            value={category.price}
                                                                            onChange={this.onChangeCategoryPrice.bind(this, selectedCarrier, productName, index, j)}
                                                                            append={
                                                                                <IconButton
                                                                                    onClick={this.onClickOpenCategoryModal.bind(this, selectedCarrier, productName, index, j, category)}
                                                                                >
                                                                                    <i className='mdi mdi-pencil'/>
                                                                                </IconButton>
                                                                            }
                                                                        />
                                                                    }
                                                                    {priceScaleEnabled &&
                                                                    <div>
                                                                        {category.priceScales?.map((priceScale, i) => (
                                                                            <CurrencyInput
                                                                                key={i}
                                                                                style={{marginLeft: 0, marginRight: 0}}
                                                                                label={`${category.priceScales[i - 1]?.maxAmount + 1 || 0}${priceScale.maxAmount ? ` - ${priceScale.maxAmount} st` : '+ stuks'}`}
                                                                                value={priceScale.price}
                                                                                onChange={(event) => {
                                                                                    const priceScales = priceTable[selectedCarrier.name][productName].countryGroups[index].categories[j].priceScales
                                                                                    if (priceScales?.[i]) {
                                                                                        priceScales[i].price = event.target.value
                                                                                    }
                                                                                    this.setState({priceTable})
                                                                                }}
                                                                                append={i !== (category.priceScales?.length - 1) ?
                                                                                    <IconButton
                                                                                        onClick={() => this.scaleModal.open(
                                                                                            selectedCarrier.name,
                                                                                            productName,
                                                                                            index,
                                                                                            j,
                                                                                            i,
                                                                                            priceRule.categories[j].priceScales[i]
                                                                                        )}
                                                                                    >
                                                                                        <i className='mdi mdi-pencil'/>
                                                                                    </IconButton> : undefined
                                                                                }
                                                                            />
                                                                        ))}
                                                                        <Button
                                                                            style={{width: '100%', margin: 0, padding: 0, marginBottom: 12}}
                                                                            label='Nieuwe staffel'
                                                                            icon='mdi mdi-plus'
                                                                            variant='outline'
                                                                            onClick={() => this.scaleModal.open(
                                                                                selectedCarrier.name,
                                                                                productName,
                                                                                index,
                                                                                j,
                                                                                null,
                                                                                {
                                                                                    price: ''
                                                                                }
                                                                            )}
                                                                        />
                                                                    </div>
                                                                    }
                                                                </div>
                                                            )
                                                        })}
                                                    </div>

                                                    {priceRule.type === 'weight' &&
                                                    <Button
                                                        style={{marginRight: 30}}
                                                        variant='outline'
                                                        icon='mdi mdi-plus'
                                                        label='Nieuwe categorie'
                                                        onClick={this.onClickAddCategory.bind(this, selectedCarrier, productName, index)}
                                                    />
                                                    }

                                                    {(priceRule.type === 'fixed' || priceRule.type === 'fixedKg' || priceRule.type === 'fee' || priceRule.type === 'feepercent') &&
                                                    <div style={{display: 'flex', width: 800, flexWrap: 'wrap'}}>
                                                        {product.values ?
                                                            product.values.map((value) => {
                                                                return (
                                                                    <div
                                                                        key={value}
                                                                        style={{
                                                                            width: 150,
                                                                            marginRight: 12
                                                                        }}
                                                                    >
                                                                        {priceRule.type !== 'feepercent' &&
                                                                        <CurrencyInput
                                                                            label={`${productName === 'Verzekerd' ? 'Tot: ' : ''}${value}`}
                                                                            value={priceRule.values[value]}
                                                                            onChange={this.onChangePrice.bind(this, selectedCarrier, productName, index, value)}
                                                                        />
                                                                        }

                                                                        {priceRule.type === 'feepercent' &&
                                                                        <Input
                                                                            label={`${productName === 'Verzekerd' ? 'Tot: ' : ''}${value}`}
                                                                            value={priceRule.values[value]}
                                                                            onChange={this.onChangePrice.bind(this, selectedCarrier, productName, index, value)}
                                                                            append='%'
                                                                        />
                                                                        }
                                                                    </div>
                                                                )
                                                            }) :
                                                            <div style={{width: 150, marginRight: 12}}>
                                                                {priceRule.type !== 'feepercent' &&
                                                                <>
                                                                    {priceScaleEnabled ?
                                                                        <div style={{width: 160}}>
                                                                            {priceRule.priceScales?.map((priceScale, i) => (
                                                                                <CurrencyInput
                                                                                    key={i}
                                                                                    style={{marginLeft: 0, marginRight: 0}}
                                                                                    label={`${priceRule.priceScales[i - 1]?.maxAmount + 1 || 0}${priceScale?.maxAmount ? ` - ${priceScale?.maxAmount} st` : '+ stuks'}`}
                                                                                    value={priceScale?.price}
                                                                                    onChange={(event) => {
                                                                                        const priceScales = priceTable[selectedCarrier.name][productName].countryGroups[index].priceScales
                                                                                        if (priceScales?.[i]) {
                                                                                            priceScales[i].price = event.target.value
                                                                                        }
                                                                                        this.setState({priceTable})
                                                                                    }}
                                                                                    append={i !== (priceRule.priceScales?.length - 1) ?
                                                                                        <IconButton
                                                                                            onClick={() => this.scaleModal.open(
                                                                                                selectedCarrier.name,
                                                                                                productName,
                                                                                                index,
                                                                                                null,
                                                                                                i,
                                                                                                priceRule.priceScales[i]
                                                                                            )}
                                                                                        >
                                                                                            <i className='mdi mdi-pencil'/>
                                                                                        </IconButton> : undefined
                                                                                    }
                                                                                />
                                                                            ))}
                                                                            <Button
                                                                                style={{width: '100%', margin: 0, padding: 0, marginBottom: 12}}
                                                                                label='Nieuwe staffel'
                                                                                icon='mdi mdi-plus'
                                                                                variant='outline'
                                                                                onClick={() => this.scaleModal.open(
                                                                                    selectedCarrier.name,
                                                                                    productName,
                                                                                    index,
                                                                                    null,
                                                                                    null,
                                                                                    {
                                                                                        price: ''
                                                                                    }
                                                                                )}
                                                                            />
                                                                        </div> :
                                                                        <CurrencyInput
                                                                            label='Prijs'
                                                                            value={priceRule.price}
                                                                            onChange={this.onChangePrice.bind(this, selectedCarrier, productName, index, null)}
                                                                        />
                                                                    }
                                                                </>
                                                                }
                                                                {priceRule.type === 'feepercent' &&
                                                                <Input
                                                                    label='Percentage'
                                                                    value={priceRule.price}
                                                                    onChange={this.onChangePrice.bind(this, selectedCarrier, productName, index, null)}
                                                                    append='%'
                                                                />
                                                                }
                                                            </div>
                                                        }

                                                        {priceRule.type === 'fixedKg' &&
                                                        <CurrencyInput
                                                            style={{width: 150, marginRight: 12}}
                                                            label='Toeslag'
                                                            value={priceRule.fee}
                                                            onChange={this.onChangeFee.bind(this, selectedCarrier, productName, index)}
                                                        />
                                                        }

                                                        {priceRule.type === 'fixedKg' &&
                                                        <Select
                                                            style={{width: 150, marginRight: 12}}
                                                            label='Afronden op'
                                                            options={[
                                                                {title: 'kg', value: ''},
                                                                {title: '100g', value: '100g'},
                                                                {title: 'g', value: 'g'}
                                                            ]}
                                                            value={priceRule.round}
                                                            onChange={this.onChangeRound.bind(this, selectedCarrier, productName, index)}
                                                        />
                                                        }
                                                    </div>
                                                    }
                                                </div>
                                            )
                                        }}
                                    onChange={this.onSortPricesRules.bind(this, selectedCarrier, productName)}
                                />
                                }

                                <Button
                                    variant='outline'
                                    style={{marginBottom: 24, width: 'fit-content'}}
                                    icon='mdi mdi-plus'
                                    label='Nieuwe landengroep'
                                    onClick={this.onClickAddPriceRule.bind(this, selectedCarrier, product, productName)}
                                />
                            </Card>
                        )
                    }
                })}

                <br/>
                {error && <Alert variant="danger">{error}</Alert>}

                <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                    {oldName && oldName !== 'Standaard' &&
                        <Button variant='error' onClick={this.onRemove.bind(this)}>Verwijder</Button>
                    }
                    <Button variant='text' onClick={this.close.bind(this, true)}>Annuleer</Button>
                    <Button variant='text' disabled={!name} onClick={this.onSubmit.bind(this)}>Opslaan</Button>
                </div>

                <PriceRuleModal
                    onChange={this.onEditPriceRule.bind(this)}
                    remove={this.onRemovePriceRule.bind(this)}
                    ref={(ref) => this.PriceRuleModal = ref}
                />

                <CategoryModal
                    onChange={this.onEditCategory.bind(this)}
                    remove={this.onRemoveCategory.bind(this)}
                    ref={(ref) => this.categoryModal = ref}
                />

                <PriceScaleModal
                    onSubmit={(carrierName, productName, countryGroupIndex, categoryIndex, scaleIndex, scale) => {
                        const priceScales = typeof categoryIndex === 'number' ? priceTable[carrierName][productName].countryGroups[countryGroupIndex].categories[categoryIndex].priceScales : priceTable[carrierName][productName].countryGroups[countryGroupIndex].priceScales

                        if (typeof scaleIndex === 'number') {
                            priceScales[scaleIndex] = scale
                        } else {
                            priceScales.push(scale)
                        }

                        // Make sure there is always a scale without a maxAmount (minAmount: x+)
                        const scalesWithMaxAmount = priceScales.filter((scale) => typeof scale?.maxAmount === 'number')
                        const sortedPriceScales = scalesWithMaxAmount.toSorted((a, b) => a.maxAmount - b.maxAmount)

                        const highestScale = priceScales.find((scale) => typeof scale?.maxAmount === 'undefined')
                        sortedPriceScales.push(highestScale)

                        sortedPriceScales.forEach((priceScale, index) => {
                            const sameMaxAmountIndex = sortedPriceScales.findIndex((p, i) => p.maxAmount === priceScale.maxAmount && index !== i)
                            if (sameMaxAmountIndex > -1) {
                                sortedPriceScales[sameMaxAmountIndex].maxAmount = sortedPriceScales[sameMaxAmountIndex].maxAmount + 1
                            }
                        })

                        if (typeof categoryIndex === 'number') {
                            priceTable[carrierName][productName].countryGroups[countryGroupIndex].categories[categoryIndex].priceScales = sortedPriceScales
                        } else {
                            priceTable[carrierName][productName].countryGroups[countryGroupIndex].priceScales = sortedPriceScales
                        }

                        this.setState({priceTable}, () => {
                            this.scaleModal.close()
                        })
                    }}
                    onRemove={(carrierName, productName, countryGroupIndex, categoryIndex, scaleIndex) => {
                        const priceScales = typeof categoryIndex === 'number' ? priceTable[carrierName][productName].countryGroups[countryGroupIndex].categories[categoryIndex].priceScales : priceTable[carrierName][productName].countryGroups[countryGroupIndex].priceScales
                        priceScales.splice(scaleIndex, 1)

                        this.setState({priceTable}, () => {
                            this.scaleModal.close()
                        })
                    }}
                    ref={(ref) => this.scaleModal = ref}
                />

                <SaveBeforeExitPopup
                    ref={(ref) => this.saveBeforeExitPopup = ref}
                />

                <Popup
                    ref={(ref) => this.popup = ref}
                    cancelBtnText='Terug'
                    confirmBtnText='Verwijderen'
                />
            </Modal>
        )
    }
}

export default (PriceTableModal)
