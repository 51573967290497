import defaultWelcomeCustomerBody from './defaultWelcomeCustomerBody.js'
import defaultWelcomeUserBody from './defaultWelcomeUserBody.js'
import defaultShiftOverviewBody from './defaultShiftOverviewBody.js'
import defaultTrackTraceBody from './defaultTrackTraceBody.js'
import defaultEmailNotifications from './defaultEmailNotifications.js'
import defaultCancelEmailBody from './defaultCancelEmailBody.js'
import moment from 'moment'
import guid from '../../../../../utils/guid.js'

export default () => {
    const vehicleTypes = [
        {
            name: 'Auto',
            icon: 'van-utility',
            routeProfile: 'car',
            fuelType: 'unknown-electric',
            speedDifferenceMode: 'faster',
            usage: 0,
            range: 0,
            payload: 0,
            payloadType: 'colli',
            stopTime: 180,
            comment: '',
            notes: '',
            isArchived: false,
            _id: guid.generate()
        },
        {
            name: 'Fiets',
            icon: 'bike',
            routeProfile: 'bike',
            fuelType: 'none',
            speedDifferenceMode: 'faster',
            usage: 0,
            range: 0,
            payload: 0,
            payloadType: 'colli',
            stopTime: 180,
            comment: '',
            notes: '',
            isArchived: false,
            _id: guid.generate()
        },
        {
            name: 'Lopen',
            icon: 'walk',
            routeProfile: 'walk',
            fuelType: 'none',
            speedDifferenceMode: 'faster',
            usage: 0,
            range: 0,
            payload: 0,
            payloadType: 'colli',
            stopTime: 180,
            comment: '',
            notes: '',
            isArchived: false,
            _id: guid.generate()
        }
    ]

    return JSON.parse(JSON.stringify({
        name: '',
        startDate: moment().add(15, 'days').format('YYYY-MM-DD'),
        debtorCode: '',
        accountType: 'carrier',
        groups: [],
        reference: '',
        prices: {
            branchManagement: false,
            customPrices: false,
            orders: [
                {max: 99999999, price: '0,00'}
            ],
            parcels: [
                {max: 99999999, price: '0,00'}
            ]
        },
        repeatedInvoiceRules: [],
        permissions: {
            orders: true,
            parcels: true,
            shifts: false,
            paychecks: false,
            subscriptions: false,
            users: true,
            vehicles: false,
            customers: true,
            invoices: true
        },
        settings: {
            logo: '',
            logoBlack: '',
            color: '#ff9900',
            companyAddress: {
                name: '',
                street: '',
                nr: '',
                addition: '',
                street2: '',
                postalCode: '',
                city: '',
                country: 'NL',
                email: '',
                phone: ''
            },
            sublocations: [],
            deliveryZones: [],
            account: {
                openingHours: {
                    monday: {
                        open: true,
                        from: '09:00',
                        till: '17:00'
                    },
                    tuesday: {
                        open: true,
                        from: '09:00',
                        till: '17:00'
                    },
                    wednesday: {
                        open: true,
                        from: '09:00',
                        till: '17:00'
                    },
                    thursday: {
                        open: true,
                        from: '09:00',
                        till: '17:00'
                    },
                    friday: {
                        open: true,
                        from: '09:00',
                        till: '17:00'
                    },
                    saturday: {
                        open: false,
                        from: '09:00',
                        till: '17:00'
                    },
                    sunday: {
                        open: false,
                        from: '09:00',
                        till: '17:00'
                    },
                    closed: {

                    }
                }
            },
            customers: {
                welcomeEmailSubject: 'Account voor Veloyd',
                welcomeEmailBody: defaultWelcomeCustomerBody
            },
            orders: {
                emailNotifications: defaultEmailNotifications(),
                prices: [],
                trackTrace: {
                    outForDeliveryStatusIcon: 'bike',
                    timeFrame: 60,
                    pickupNotCompletedMessage: 'Het is ons helaas niet gelukt om je zending op te halen, onze excuses hiervoor. Neem contact op met de afzender om een nieuwe afspraak te maken.',
                    pickupNotHomeMessage: 'Je was niet thuis toen we je zending kwamen ophalen. Neem contact op met de afzender om een nieuwe afspraak te maken.',
                    pickupCancelledMessage: 'Je ophaalafspraak is geannuleerd. Neem contact op met de afzender voor meer informatie.',
                    deliveryNotCompletedRescheduleMessage: 'Het is ons helaas niet gelukt je zending te bezorgen, onze excuses hiervoor. Hieronder vind je de nieuwe bezorgdatum.',
                    deliveryNotCompletedRetourMessage: 'Je zending gaat retour naar de afzender omdat het adres niet correct is. Neem contact op met de afzender voor meer informatie.',
                    deliveryNotHomeRescheduleMessage: 'Je was niet thuis om je zending in ontvangst te nemen. Hieronder vind je de nieuwe bezorgdatum.',
                    deliveryNotHomeOnHoldMessage: 'Je was niet thuis om je zending in ontvangst te nemen. Neem contact met ons op om een nieuwe bezorgafspraak te maken.',
                    deliveryNotHomeRetourMessage: 'Je was niet thuis om je zending in ontvangst te nemen. De zending gaat retour naar de afzender.',
                    deliveryCancelledMessage: 'Je bezorging is geannuleerd. Neem contact op met de afzender voor meer informatie.',
                    deliveryMancoMessage: 'Je bezorging is manco. Neem contact op met de afzender voor meer informatie.',
                    showFaq: false,
                    faq: [],
                    footerTitle: 'Vragen?',
                    footerMessage: 'Kijk bij de veelgestelde vragen of neem contact met ons op.',
                    email: '',
                    phone: ''
                },
                csvColumns: {},
                csvOptions: {},
                addressCsvColumns: {},
                useNewPrices: true,
                exportCsvTemplates: [],
                showNewOrderNotification: 'never'
            },
            useExpectedOrders: true,
            scanRequiredBeforeStart: false,
            maxDeliveryRadius: 40,
            defaultVehicle: vehicleTypes.find((v) => v.routeProfile === 'bike')._id,
            customSpeed: false,
            speedDifference: 0,
            stopTime: 180,
            priceTables: {},
            parcels: {
                carriers: {
                    'DHL': {
                        enabled: false,
                        accountId: '',
                        apiKey: ''
                    },
                    'PostNL': {
                        enabled: false,
                        customerNumber: '',
                        customerCode: '',
                        customerCodeInt: '',
                        apiKey: ''
                    },
                    'Skynet': {
                        enabled: false,
                        username: '',
                        password: '',
                        apiKey: ''
                    },
                    'GLS': {
                        enabled: false
                    },
                    'PostNL-MSG': {
                        enabled: false,
                        username: '',
                        password: '',
                        originName: ''
                    },
                    'partners': []
                },
                products: {

                },
                preferences: {

                },
                prices: {

                },
                fees: [],
                postalCodes: {

                },
                trackTrace: {
                    sendTTMails: false,
                    senderName: '',
                    replyToEmail: '',
                    emailSubject: 'Track & Trace',
                    emailBody: defaultTrackTraceBody
                },
                cancelEmail: {
                    sendCancelEmails: false,
                    senderName: '',
                    replyToEmail: '',
                    emailSubject: 'Zending {barcode} geannuleerd',
                    emailBody: defaultCancelEmailBody
                },
                csvColumns: {},
                csvOptions: {}
            },
            administration: {
                api: '',
                checkOrders: false
            },
            users: {
                welcomeEmailSubject: 'Account voor Veloyd',
                welcomeEmailBody: defaultWelcomeUserBody,
                shiftOverviewEmailSubject: 'Dienstenoverzicht',
                shiftOverviewEmailBody: defaultShiftOverviewBody
            },
            pickupNotPossibleReasons: ['nothome', 'notready', 'invalid', 'addressnotfound', 'wrongaddress', 'companyaddressclosed', 'other'],
            deliveryNotPossibleReasons: ['notdelivered;nothome', 'notdelivered;addressnotfound', 'notdelivered;wrongaddress', 'notdelivered;companyaddressclosed', 'notdelivered;verificationfailed', 'missing', 'refused', 'notdelivered;other'],
            pickupNotPossibleReasonsSpecifications: [],
            deliveryNotPossibleReasonsSpecifications: [],
            vehicleTypes
        }
    }))
}
