import React from 'react'
import {FileUpload, IconButton, Link} from './index.js'

import AddressActions from '../../actions/AddressActions.js'

export default function AddressAttachment({address, onChange, edit, fileUploadStyle}) {
    const onChangeFileUpload = (fileName, result, type) => {
        address.attachments = address.attachments || []

        // For now only 1 attachment is allowed
        address.attachments = [{fileName, data: result, type}]

        onChange(address)
    }

    return (
        <>
            {edit && address?.attachments?.length !== 1 &&
                <div style={{width: 'fit-content'}}>
                    <FileUpload
                        label='Bijlage uploaden'
                        onChange={onChangeFileUpload}
                        accept={['.pdf', '.jpg', '.jpeg', '.png']}
                        maxSize={5}
                        style={{marginBottom: 0, ...fileUploadStyle}}
                    />
                </div>
            }

            {address?.attachments?.length > 0 && address.attachments.map((attachment) => (
                <div
                    style={{display: 'flex', alignItems: 'center', marginRight: 6}}
                    key={attachment.fileId || attachment.fileName}
                >
                    <i style={{marginRight: 6, fontSize: 16}} className='mdi mdi-paperclip' />

                    <Link
                        onClick={() => {
                            const openUrl = (file) => {
                                const raw = window.atob(file)
                                const rawLength = raw.length
                                const array = new Uint8Array(new ArrayBuffer(rawLength))

                                for (let i = 0; i < rawLength; i++) {
                                    array[i] = raw.charCodeAt(i)
                                }

                                const blob = new Blob([array], {type: attachment.type})

                                const blobUrl = URL.createObjectURL(blob)

                                window.open(blobUrl)
                            }
                            if (attachment.fileId) {
                                AddressActions.downloadAttachment(attachment.fileId, (err, file) => {
                                    if (err) {
                                        console.log(err)
                                    } else {
                                        openUrl(file)
                                    }
                                })
                            } else if (attachment.data) {
                                window.open(attachment.data)
                            }
                        }}
                    >
                        {attachment.fileName}
                    </Link>

                    {edit &&
                        <IconButton
                            icon='mdi mdi-close'
                            onClick={() => {
                                address.attachments = address.attachments.filter((a) => attachment.fileId && a.fileId ? a.fileId !== attachment.fileId : a.fileName !== attachment.fileName)
                                onChange(address)
                            }}
                        />
                    }
                </div>
            ))}
        </>
    )
}
