import React from 'react'

import moment from 'moment'
import _ from 'underscore'

import ParcelActions from '../../actions/ParcelActions.js'
import AddressActions from '../../actions/AddressActions.js'

import {Alert, DropdownButton, IconButton, Popup, Modal, H4, H6, Row, Column, Colors} from '../UI/index.js'
import NewParcel from '../../../server/functions/parcels/NewParcel.js'
import {Input, Select, Address, CarrierSelector, MetricInput} from '../UI/index.js'
import ParcelCustoms from './ParcelCustoms.js'
import ParcelOptions from './ParcelOptions.js'
import time from '../../../server/utils/time.js'

class EditParcelModal extends React.Component {
    constructor(props) {
        super(props)
        this.initialState = {
            modalIsOpen: false,
            createParcelMode: localStorage.createParcelMode || 'save',
            parcel: NewParcel(),
            customer: {settings: {parcels: {defaultOptions: []}}},
            parcelCarriers: [],
            options: [],
            disabledOptions: [],
            amounts: [],
            deliveryTimes: [],
            pickupDates: [],
            pickupPoints: [],
            optionsHaveChanged: false,
            sizeOption: props.user?.settings?.parcels?.sizes?.findIndex((size) => size.isDefault) ?? undefined,
            validation: {},
            loading: false,
            info: '',
            warning: '',
            error: ''
        }

        this.state = JSON.parse(JSON.stringify(this.initialState))
    }

    open(parcel, callback) {
        const {reseller, user, customers} = this.props

        let customer
        let sizeOption

        parcel = parcel ? JSON.parse(JSON.stringify(parcel)) : NewParcel()

        if (user.isCustomer) {
            customer = JSON.parse(JSON.stringify(user))

            const sizes = user.settings?.parcels?.sizes || []

            if (parcel._id) {
                const index = sizes.findIndex((size) => size.width === parcel.width && size.length === parcel.length && size.height === parcel.height)

                if (index > -1) {
                    sizeOption = index
                }
            } else {
                const index = sizes.findIndex((size) => size.isDefault)

                if (index > -1) {
                    parcel.length = sizes[index].length
                    parcel.width = sizes[index].width
                    parcel.height = sizes[index].height
                    sizeOption = index
                }
            }
        } else {
            if (parcel._id) {
                customer = customers[parcel.customer] || this.state.customer
            } else {
                customer = this.state.customer
            }
        }

        if (reseller.isShop && customers && _.keys(customers).length === 1) {
            customer = JSON.parse(JSON.stringify(_.values(customers)[0]))
        }

        if (!parcel._id && customer._id) {
            parcel.customer = customer._id
            parcel.customerAddress = customer.address

            parcel.comment = customer.settings.parcels.defaultComment || ''
        }

        const createParcelMode = localStorage.createParcelMode || 'save'

        console.log(parcel)

        this.setState({modalIsOpen: true, parcel, customer, createParcelMode, sizeOption, callback}, () => {
            this.getOptions(true)
            if (user.isCustomer || (reseller.isShop && customer._id)) {
                setTimeout(() => this.parcelAddress.focus(), 10)
            } else {
                setTimeout(() => this.selectCustomer.focus(), 10)
            }
        })
    }

    close() {
        this.setState(this.initialState)
    }

    onChangeCustomer(event) {
        const {customers} = this.props
        const parcel = {...this.state.parcel}

        const customer = customers[event.target.value]

        if (customer) {
            parcel.customer = customer._id
            parcel.contact = ''
            parcel.options = []

            if (customer.address) {
                parcel.customerAddress = customer.address
            }
            parcel.emailTT = customer.settings.parcels.emailTT || ''

            this.setState({parcel, customer}, () => {
                this.getOptions()
                setTimeout(() => this.parcelAddress.focus(), 10)
            })
        }
    }

    onChangeContact(event) {
        const {customer} = this.state
        const parcel = {...this.state.parcel}

        parcel.contact = event.target.value

        const contact = _.find(customer?.contacts, {name: parcel.contact})

        if (contact) {
            parcel.emailTT = contact.emailTT || parcel.emailTT
        }

        this.setState({parcel})
    }

    onChangePickupAddress(address) {
        const {parcel} = this.state

        // Only NL is allowed for pickups
        address.country = 'NL'

        parcel.pickupAddress = address

        this.setState({parcel})
    }

    onChangeAddress(address) {
        const {parcel, options} = this.state

        if (parcel.address.country !== address.country) {
            parcel.options = []

            if (EU.indexOf(address.country) === -1) {
                parcel.customs = {
                    typeItem: 'Commercial Goods',
                    typeNumber: 'invoice',
                    number: '',
                    content: [{
                        description: '',
                        quantity: 1,
                        weight: 0,
                        value: '0,00',
                        hsCode: '',
                        countryOrigin: 'NL'
                    }]
                }
            } else {
                delete parcel.customs
            }
        }

        parcel.address = address

        if (address.instructions) {
            parcel.comment = address.instructions
        }

        // TODO: remove this after Px pilot
        if (address.px && options.includes('Px') && !parcel.options.includes('Px')) {
            parcel.options.push('Px')

            setTimeout(() => {
                document.getElementById('pxBarcode')?.focus()
            }, 100)
        }

        this.getOptions()
        this.setState({parcel})
    }

    onChangeParcelCustoms(parcel) {
        let weight = 0

        parcel.customs.content.map((content) => {
            weight += content.weight
        })

        if (weight !== parcel.weight) {
            parcel.weight = weight
            parcel.options = []

            this.setState({parcel}, () => {
                this.getOptions()
            })
        } else {
            this.setState({parcel})
        }
    }

    onChangeWeight(event) {
        if (/^[0-9]{0,5}$/.test(event.target.value)) {
            const {parcel} = this.state
            parcel.weight = parseInt(event.target.value) || 0
            parcel.options = []

            this.setState({parcel}, () => {
                this.getOptions()
            })
        }
    }

    onChangeParcelSize(event) {
        const {parcel} = this.state
        const {user} = this.props

        const size = user.settings.parcels.sizes[event.target.value]

        parcel.length = size.length
        parcel.width = size.width
        parcel.height = size.height

        this.setState({
            parcel,
            sizeOption: +event.target.value
        }, () => {
            this.getOptions()
        })
    }

    onChangeValue(key, event) {
        const {user} = this.props
        const {parcel, pickupPoints, infoMessages} = this.state

        if (key === 'length' || key === 'width' || key === 'height') {
            parcel[key] = +event.target.value

            if (user.isCustomer) {
                const sizes = user.settings.parcels?.sizes || []
                const index = sizes.findIndex((size) => size.width === parcel.width && size.length === parcel.length && size.height === parcel.height)
                this.setState({
                    sizeOption: index > -1 ? index : undefined
                }, () => {
                    this.getOptions()
                })
            } else {
                this.getOptions()
            }
        } else if (key === 'carrier') {
            const carrier = event.target.value
            const firstPickupPoint = pickupPoints.find((point) => point.carrier === carrier)
            parcel.pickupPoint = firstPickupPoint
            parcel[key] = event.target.value
            const info = infoMessages.find((message) => message.carrier === carrier)
            this.setState({parcel, info: info?.message})
        } else {
            parcel[key] = event.target.value
            this.setState({parcel})
        }
    }

    onChangeFee(key, index, event) {
        const {reseller} = this.props
        const {parcel} = this.state

        if (key === 'description') {
            const fee = _.findWhere(reseller.settings.parcels.fees, {description: event.target.value})

            if (fee && fee.description) {
                fee.quantity = 1
                parcel.fees[index] = fee
            }

            if (!event.target.value) {
                parcel.fees.splice(index, 1)
            }

            if (index === parcel.fees.length) {
                const fee = _.findWhere(reseller.settings.parcels.fees, {description: event.target.value})

                if (fee && fee.description) {
                    fee.quantity = 1
                    parcel.fees.push(fee)
                    this.setState({parcel})
                }
            }
        }

        if (key === 'quantity') {
            if (/^[0-9]{0,5}$/.test(event.target.value)) {
                parcel.fees[index].quantity = parseInt(event.target.value) || 0
            }
        }

        this.setState({parcel})
    }

    onChangeCover(event) {
        const {parcel} = this.state
        parcel.cover = parseInt(event.target.value)
        this.getOptions(parcel)
        this.setState({parcel})
    }

    onChangeDeliveryTime(event) {
        const {parcel} = this.state
        parcel.deliveryTime = event.target.value

        this.setState({parcel}, () => {
            this.getOptions()
        })
    }

    onChangePickupPoint(event) {
        const {pickupPoints} = this.state
        const {parcel} = this.state

        parcel.pickupPoint = _.findWhere(pickupPoints, {description: event.target.value})

        parcel.carrier = parcel.pickupPoint.carrier

        this.setState({parcel})
    }

    onChangeOptions(options) {
        const {parcel} = this.state

        parcel.options = options

        delete parcel.cover
        delete parcel.deliveryTime
        delete parcel.pickupDate

        if (parcel.options.indexOf('Pick & Ship') > -1) {
            parcel.pickupAddress = parcel.pickupAddress || {
                name: '',
                attention: '',
                street: '',
                nr: '',
                addition: '',
                street2: '',
                postalCode: '',
                city: '',
                country: 'NL',
                email: '',
                phone: ''
            }
        } else {
            delete parcel.pickupAddress
        }

        this.setState({parcel, optionsHaveChanged: true}, () => {
            if (parcel.options.includes('Px')) {
                document.getElementById('pxBarcode')?.focus()
            }

            this.getOptions()
        })
    }

    getOptions(edit) {
        const {parcel} = this.state

        if (parcel.address.postalCode && (parcel.address.country !== 'NL' || parcel.address.nr) && parcel.address.city && parcel.address.street) {
            ParcelActions.getOptions(parcel, (err, response) => {
                if (!err) {
                    const {options, disabledOptions, parcelCarriers, amounts, deliveryTimes, pickupDates, pickupPoints, infoMessages = []} = response
                    const {customer, optionsHaveChanged} = this.state

                    parcel.carrier = edit && parcelCarriers.indexOf(parcel.carrier) > -1 ? parcel.carrier : (parcelCarriers[0] || '')
                    parcel.options = response.parcel.options

                    if (response.parcel.cover) {
                        parcel.cover = response.parcel.cover
                    } else {
                        delete parcel.cover
                    }

                    if (response.parcel.deliveryTime) {
                        parcel.deliveryTime = response.parcel.deliveryTime
                    } else {
                        delete parcel.deliveryTime
                    }

                    if (response.parcel.pickupDate) {
                        parcel.pickupDate = response.parcel.pickupDate
                    } else {
                        delete parcel.pickupDate
                    }

                    if (response.parcel.pickupPoint) {
                        parcel.pickupPoint = response.parcel.pickupPoint
                    } else {
                        delete parcel.pickupPoint
                    }

                    if (!parcel._id && !optionsHaveChanged) {
                        const enabledOptions = _.difference(options, disabledOptions)
                        let defaultOptions = _.difference(customer.settings.parcels.defaultOptions, parcel.options)

                        if (customer.settings.parcels.autoSameDayNextDay) {
                            const index = parcel.options.indexOf('Same Day')

                            if (index === -1 && time.time() <= customer.settings.parcels.sameDayCuttoff || time.time() > customer.settings.parcels.nextDayCuttoff) {
                                defaultOptions.push('Same Day')
                            }
                        }

                        defaultOptions = _.intersection(defaultOptions, enabledOptions)

                        if (defaultOptions[0]) {
                            parcel.options.push(defaultOptions[0])

                            if (parcel.options.indexOf('Pick & Ship') > -1) {
                                parcel.pickupAddress = parcel.pickupAddress || {
                                    name: '',
                                    attention: '',
                                    street: '',
                                    nr: '',
                                    addition: '',
                                    street2: '',
                                    postalCode: '',
                                    city: '',
                                    country: 'NL',
                                    email: '',
                                    phone: ''
                                }
                            } else {
                                delete parcel.pickupAddress
                            }

                            this.setState({options, disabledOptions, parcelCarriers, amounts, deliveryTimes, pickupDates, pickupPoints, infoMessages}, () => {
                                this.getOptions()
                            })
                        } else {
                            this.setState({options, disabledOptions, parcelCarriers, amounts, deliveryTimes, pickupDates, pickupPoints, infoMessages})
                            this.setState({parcel})
                        }
                    } else {
                        this.setState({parcel, options, disabledOptions, parcelCarriers, amounts, deliveryTimes, pickupDates, pickupPoints, infoMessages})
                    }
                }
            })
        }
    }

    validate() {
        const {parcel} = this.state
        const {user, customers} = this.props

        const validation = {}
        let valid = true
        let focusId = ''

        let referenceRequired = user.isCustomer && user.settings.parcels.referenceRequired

        if (user.isReseller) {
            const customer = customers[parcel.customer]
            referenceRequired = customer && customer.settings.parcels.referenceRequiredReseller
        }

        if (parcel.options.includes('Px') && !parcel.pxBarcode) {
            focusId = 'pxBarcode'
            validation.pxBarcode = true
            valid = false
        }

        if (referenceRequired && !parcel.reference) {
            focusId = 'reference'
            validation.reference = true
            valid = false
        }

        if (user.isReseller && !parcel.customer) {
            focusId = 'customer'
            validation.customer = true
            valid = false
        }

        if (!valid) {
            document.getElementById(focusId).focus()
        }

        const customsValid = !this.parcelCustoms || this.parcelCustoms.validate()
        const addressValid = this.parcelAddress.validate()
        const pickupAddressValid = !parcel.pickupAddress || this.parcelPickupAddress.validate()

        this.setState({validation})
        return valid && customsValid && addressValid && pickupAddressValid
    }

    onSubmit(event) {
        event.preventDefault()
        const {parcel} = this.state

        // Prevent submitting by scanning barcode
        if (parcel.pxBarcode && document.getElementById('pxBarcode') === document.activeElement) {
            return
        }

        if (this.validate()) {
            if (!parcel.address.nr) {
                this.popup.open('Huisnummer ontbreekt', 'Weet je zeker dat je deze zending wilt aanmaken zonder huisnummer?', this.createParcel.bind(this, parcel))
            } else {
                this.createParcel(parcel)
            }
        }
    }

    createParcel(parcel) {
        const {createParcelMode} = this.state
        this.setState({loading: true, info: '', message: '', error: ''})

        if (createParcelMode === 'savePrint' || createParcelMode === 'savePrintAndNew') {
            if (parcel.carrier === 'PostNL-MSG' || parcel.carrier === 'DHL-MSG') {
                this.setState({info: 'Het ophalen van een label voor deze vervoerder kan een paar seconden duren.'})
            }
            if (parcel._id) {
                ParcelActions.edit(parcel, this.printLabel.bind(this))
            } else {
                ParcelActions.create(parcel, this.printLabel.bind(this))
            }
        } else {
            if (parcel._id) {
                ParcelActions.edit(parcel, this.response.bind(this))
            } else {
                ParcelActions.create(parcel, this.response.bind(this))
            }
        }

        if (parcel.address.save) {
            if (parcel.options.includes('Px')) {
                parcel.address.px = true
            }

            AddressActions.update([parcel.address])
        }
    }

    response(err) {
        const {callback, createParcelMode} = this.state

        if (err) {
            this.setState({loading: false, info: '', message: '', error: err})
        } else {
            typeof callback === 'function' && callback()
            if (createParcelMode === 'saveNew') {
                this.setState(this.initialState, () => {
                    this.open()
                })
            } else {
                this.close()
            }
        }
    }

    printLabel(err, updatedParcel) {
        const {callback, createParcelMode, parcel} = this.state

        // Prevent creating a new parcel if printLabel returns an error
        if (!parcel._id) {
            this.setState({parcel: {...parcel, ...updatedParcel}})
        }

        if (err) {
            this.setState({loading: false, info: '', message: '', error: err})
        } else {
            typeof callback === 'function' && callback()

            const printCallback = () => {
                this.popup.close()

                ParcelActions.printLabels([updatedParcel._id], 0, (err, pdf, errors, warnings) => {
                    if (err || errors[0]) {
                        this.setState({loading: false, info: '', message: '', error: err || errors[0]})
                    } else {
                        if (warnings[0]) {
                            this.setState({loading: false, info: '', message: '', error: '', warning: warnings[0]})
                        }

                        if (pdf) {
                            this.props.printModal.open(pdf)
                        }

                        if (createParcelMode === 'savePrintAndNew') {
                            this.setState(this.initialState, () => {
                                this.open()
                            })
                        } else if (!warnings[0]) {
                            this.close()
                        }
                    }
                })
            }

            const incorrectAddresses = parcel.address.postalCode && parcel.address.country === 'NL' && (!parcel.address.position || parcel.address.showWarning)

            if (incorrectAddresses) {
                this.popup.open('Foutieve adressen', undefined, () => {
                    printCallback()
                }, () => {
                    this.setState({loading: false, info: '', message: '', error: ''})
                })

                this.popup.setWarning('Deze zending heeft een onbekende combinatie van postcode/straatnaam + huisnummer. Wil je toch doorgaan met het printen van de label?')
            } else {
                printCallback()
            }
        }
    }

    render() {
        const {customer, parcel, parcelCarriers, options, disabledOptions, amounts, deliveryTimes, pickupDates, pickupPoints, modalIsOpen, sizeOption, validation, createParcelMode, loading, info, message, error, warning} = this.state
        const {reseller, user, addressBook, customers, carriers} = this.props

        const pickAndShip = parcel.options.indexOf('Pick & Ship') > -1
        const pickAndReturn = parcel.options.indexOf('Pick & Return') > -1

        return (
            <Modal show={modalIsOpen} onClose={this.close.bind(this)}>
                <div style={{display: 'flex', marginBottom: 24}}>
                    <H4>{parcel._id ? 'Bewerk zending' : 'Nieuwe zending'}</H4>

                    <div style={{flex: 1}} />

                    <IconButton onClick={this.close.bind(this)}>
                        <i style={{color: Colors.buttonSolid}} className='mdi mdi-close' />
                    </IconButton>
                </div>

                <form onSubmit={this.onSubmit.bind(this)}>
                    <div>
                        {!user.isCustomer &&
                            <>
                                <H6>Klant</H6>

                                <Row>
                                    <Column>
                                        <Select
                                            openAfter={1}
                                            label='Klant'
                                            options={_.values(customers).filter((customer) => !customer.isArchived).map((customer) => ({value: customer._id, title: customer.name}))}
                                            value={parcel.customer}
                                            isInvalid={validation.customer}
                                            onChange={this.onChangeCustomer.bind(this)}
                                            ref={(ref) => this.selectCustomer = ref}
                                        />

                                        <Input
                                            id='contact'
                                            label='Contactpersoon'
                                            suggestions={customer ? customer.contacts?.map((contact) => contact.name) : []}
                                            suggestionFilter
                                            value={parcel.contact}
                                            onChange={this.onChangeContact.bind(this)}
                                        />
                                    </Column>

                                    {!reseller.isShop &&
                                        <Column>
                                            <Input
                                                label='Emailadres'
                                                value={parcel.emailTT}
                                                onChange={this.onChangeValue.bind(this, 'emailTT')}
                                            />
                                        </Column>
                                    }
                                </Row>
                            </>
                        }

                        {pickAndShip &&
                            <>
                                <H6 style={{marginTop: 24}}>Ophaaladres</H6>
                                <Address
                                    title='Ophaaladres'
                                    address={parcel.pickupAddress}
                                    onChange={this.onChangePickupAddress.bind(this)}
                                    addressBook={addressBook}
                                    email={true}
                                    phone={true}
                                    saveCheckbox={true}
                                    noCountry={true}
                                    ref={(ref) => this.parcelPickupAddress = ref}
                                />
                            </>
                        }

                        {parcel.options.includes('Retour') ?
                                <H6 style={{marginTop: 24}}>Afzender</H6> :
                                <H6 style={{marginTop: 24}}>Adres</H6>
                        }

                        <Address
                            address={JSON.parse(JSON.stringify(parcel.address))}
                            onChange={this.onChangeAddress.bind(this)}
                            addressBook={addressBook}
                            state={true}
                            email={true}
                            phone={true}
                            businessCheckbox={true}
                            saveCheckbox={true}
                            ref={(ref) => this.parcelAddress = ref}
                        />

                        <H6 style={{marginTop: 24}}>Inhoud</H6>

                        <Row>
                            <Column>
                                {user.isCustomer && user.settings.parcels?.sizes?.length > 0 && (
                                    <Select
                                        label='Standaardformaten'
                                        value={sizeOption}
                                        onChange={this.onChangeParcelSize.bind(this)}
                                    >
                                        {user.settings.parcels.sizes.map((size, index) => (
                                            <option key={index} value={index}>{size.name}</option>
                                        ))}
                                    </Select>
                                )}
                                <Input
                                    id='reference'
                                    label='Referentie'
                                    info='Het referentieveld gebruik je voor interne referenties. Bijvoorbeeld wie heeft de zending verstuurd of bij welk ordernummer hoort hij. De referentie komt ook terug in de factuurbijlage.'
                                    value={parcel.reference}
                                    isInvalid={validation.reference}
                                    onChange={this.onChangeValue.bind(this, 'reference')}
                                />
                            </Column>

                            <Column>
                                <Input
                                    label='Bezorginstructie'
                                    info='Het instructieveld is voor de koerier. Hierin kan een mogelijke extra bezorginstructie.'
                                    value={parcel.comment}
                                    onChange={this.onChangeValue.bind(this, 'comment')}
                                />
                            </Column>
                        </Row>

                        <Row>
                            <Column>
                                <MetricInput
                                    label='Gewicht'
                                    value={parcel.weight}
                                    onChange={this.onChangeWeight.bind(this)}
                                    append='g'
                                />

                                <MetricInput
                                    label='Lengte'
                                    value={parcel.length || 0}
                                    divisionFactor={10}
                                    onChange={this.onChangeValue.bind(this, 'length')}
                                    append='cm'
                                />
                            </Column>

                            <Column>
                                <MetricInput
                                    label='Breedte'
                                    value={parcel.width || 0}
                                    divisionFactor={10}
                                    onChange={this.onChangeValue.bind(this, 'width')}
                                    append='cm'
                                />

                                <MetricInput
                                    label='Hoogte'
                                    value={parcel.height || 0}
                                    divisionFactor={10}
                                    onChange={this.onChangeValue.bind(this, 'height')}
                                    append='cm'
                                />
                            </Column>
                        </Row>

                        {!user.isCustomer && [...parcel.fees, {description: '', quantity: 1}].map((fee, index) => {
                            return (
                                <Row key={`fee${index}`}>
                                    <Column>
                                        <Select
                                            allowEmptyValue
                                            label='Toeslag'
                                            value={fee.description}
                                            onChange={this.onChangeFee.bind(this, 'description', index)}
                                            options={(reseller.settings.parcels.fees || []).map((fee) => {
                                                if (fee.description) {
                                                    return {title: fee.description, value: fee.description}
                                                }
                                            })}
                                        />

                                        {fee.description &&
                                            <Input
                                                noPlaceholder
                                                style={{width: 100}}
                                                label='Aantal'
                                                value={fee.quantity}
                                                onChange={this.onChangeFee.bind(this, 'quantity', index)}
                                            />
                                        }
                                    </Column>
                                    <Column />
                                </Row>
                            )
                        })}

                        {EU.indexOf(parcel.address.country) === -1 &&
                            <>
                                <H6 style={{marginTop: 24}}>Douane informatie</H6>

                                <ParcelCustoms
                                    parcel={parcel}
                                    onChange={this.onChangeParcelCustoms.bind(this)}
                                    ref={(ref) => this.parcelCustoms = ref}
                                />
                            </>
                        }

                        {parcel.customer && parcel.address.postalCode && (parcel.address.country !== 'NL' || parcel.address.nr) && parcel.address.city && parcel.address.street &&
                            <>
                                <H6 style={{marginTop: 24}}>Verzendopties</H6>

                                <ParcelOptions
                                    options={options}
                                    selected={parcel.options}
                                    disabledOptions={disabledOptions}
                                    onChange={this.onChangeOptions.bind(this)}
                                />

                                {parcel.options.indexOf('Verzekerd') > -1 &&
                                    <Select
                                        style={{maxWidth: 375}}
                                        label='Verzekerd tot'
                                        value={parcel.cover}
                                        onChange={this.onChangeCover.bind(this)}
                                    >
                                        {amounts.map((amount) => {
                                            return <option key={amount} value={amount}>{`€ ${amount},-`}</option>
                                        })}
                                    </Select>
                                }

                                {parcel.options.indexOf('Spoed levering') > -1 &&
                                    <Select
                                        style={{maxWidth: 375}}
                                        label='Leveren voor'
                                        value={parcel.deliveryTime}
                                        onChange={this.onChangeDeliveryTime.bind(this)}
                                    >
                                        {deliveryTimes.map((deliveryTime) => {
                                            return <option key={deliveryTime} value={deliveryTime}>{`${deliveryTime.indexOf('zaterdag') === -1 ? 'volgende werkdag ' : ''}${deliveryTime}`}</option>
                                        })}
                                    </Select>
                                }

                                {(pickAndShip || pickAndReturn) &&
                                    <Select
                                        style={{maxWidth: 375}}
                                        label='Ophaaldatum'
                                        value={parcel.pickupDate}
                                        onChange={this.onChangeValue.bind(this, 'pickupDate')}
                                    >
                                        {pickupDates.map((pickupDate) => {
                                            return <option key={pickupDate} value={pickupDate}>{moment(pickupDate).format('DD-MM-YYYY')}</option>
                                        })}
                                    </Select>
                                }

                                {parcel.options.indexOf('Afhaalpunt') > -1 &&
                                    <Select
                                        label='Afhaalpunt'
                                        value={parcel.pickupPoint ? parcel.pickupPoint.description : ''}
                                        onChange={this.onChangePickupPoint.bind(this)}
                                        options={pickupPoints.map((pickupPoint) => {
                                            return {value: pickupPoint.description, title: `${pickupPoint.description}${parcelCarriers.length > 1 ? ` - ${user.isCustomer ? pickupPoint.carrier.replace('-onlineparcel', '') : pickupPoint.carrier}` : ''}`}
                                        })}
                                    />
                                }

                                {parcel.options.indexOf('Px') > -1 &&
                                    <Input
                                        id='pxBarcode'
                                        style={{maxWidth: 375}}
                                        label='Px barcode'
                                        value={parcel.pxBarcode}
                                        isInvalid={validation.pxBarcode}
                                        onChange={(event) => this.onChangeValue('pxBarcode', event)}
                                        ref={(ref) => this.pxBarcodeInput = ref}
                                    />
                                }

                                <H6 style={{marginTop: 24}}>Vervoerder</H6>

                                {parcel.options.includes('Antwoordnummer') && parcel.options.length > 1 && parcel.carrier === 'PostNL' && (
                                    <Alert variant='info'>Voor een zending naar een antwoordnummer met extra verzendopties worden kosten in rekening gebracht.</Alert>
                                )}

                                {parcel.options.includes('Antwoordnummer') && parcel.carrier !== 'PostNL' && (
                                    <Alert variant='info'>Voor een zending naar een antwoordnummer met deze vervoerder worden kosten in rekening gebracht.</Alert>
                                )}

                                {parcelCarriers && parcelCarriers.length === 0 &&
                                    <Alert variant='warning'>Er is geen vervoerder beschikbaar voor deze zending</Alert>
                                }

                                <div style={{width: '100%', overflowX: 'auto'}}>
                                    <CarrierSelector
                                        size={55}
                                        carriers={carriers}
                                        displayCarriers={parcelCarriers}
                                        value={parcel.carrier}
                                        onChange={this.onChangeValue.bind(this, 'carrier')}
                                        isCustomer={user.isCustomer}
                                    />
                                </div>
                            </>
                        }
                    </div>

                    <br />
                    {info && <Alert variant='info'>{info}</Alert>}
                    {message && <Alert variant='success'>{message}</Alert>}
                    {warning && <Alert variant='warning'>{warning}</Alert>}
                    {error && <Alert variant='danger'>{error}</Alert>}

                    <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                        <DropdownButton
                            type='submit'
                            loading={loading}
                            value={createParcelMode}
                            options={[
                                {title: 'Opslaan', value: 'save'},
                                {title: 'Opslaan + Print', value: 'savePrint'},
                                {title: 'Opslaan + Nieuw', value: 'saveNew'},
                                {title: 'Opslaan + Print + Nieuw', value: 'savePrintAndNew'}
                            ]}
                            onChange={(event) => {
                                localStorage.createParcelMode = event.target.value
                                this.setState({createParcelMode: event.target.value})
                            }}
                        >
                            {
                                createParcelMode === 'saveNew' ?
                                    'Opslaan + Nieuw' :
                                    createParcelMode === 'savePrint' ?
                                        'Opslaan + Print' :
                                        createParcelMode === 'savePrintAndNew' ?
                                            'Opslaan + Print + Nieuw' :
                                            'Opslaan'
                            }
                        </DropdownButton>
                    </div>
                </form>

                <Popup ref={(ref) => this.popup = ref} />
            </Modal>
        )
    }
}

const EU = [
    'AT', 'BE', 'BG', 'HR', 'CY', 'DK', 'EE', 'FI', 'FR', 'DE', 'GR', 'HU', 'IE',
    'IT', 'LV', 'LT', 'LU', 'MC', 'MT', 'NL', 'CZ', 'PL', 'PT', 'RO', 'SK', 'SI', 'ES', 'SE'
]

export default (EditParcelModal)
