import React from 'react'

import {Colors} from '../UI/index.js'

export default class NavBar extends React.Component {
    render() {
        const {children, branding} = this.props

        const style = {
            position: 'fixed',
            top: 0,
            zIndex: 405,
            display: 'flex',
            alignItems: 'center',
            width: '100%',
            height: 60,
            fontSize: 16,
            color: Colors.textDark,
            background: Colors.backgroundWhite,
            boxShadow: '0 0 10px 0 rgba(0,0,0,0.1)'
        }

        return (
            <>
                {process.env.NODE_ENV === 'development' && process.env.CURRENT_BRANCH && (
                    <div style={{position: 'fixed', top: 8, left: '50%', transform: 'translateX(-50%)', zIndex: 406, padding: '4px 16px', backgroundColor: 'rgba(0, 255, 97, 0.5)', borderRadius: 8, fontSize: 12, color: 'darkgreen'}}>Branch: {process.env.CURRENT_BRANCH}</div>
                )}
                <div style={style}>
                    {branding === undefined ?
                            <div style={{height: 32, width: 32, borderRadius: 4, marginLeft: 20, backgroundColor: Colors.grey20}} /> :
                            <img
                                style={{height: 32, marginLeft: 20}}
                                src={branding?.squareLogo || '/images/VELOYDLOGOSMALL.png'}
                                alt='Logo'
                            />
                    }

                    <div style={{display: 'flex', marginRight: 20, flex: 1, justifyContent: 'flex-start', height: '100%'}}>
                        {children}
                    </div>
                </div>
            </>
        )
    }
}
