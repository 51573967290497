import React from 'react'

import OrderActions from '../../actions/OrderActions.js'

import {Alert, DatePicker, Button, H4, Input, Modal, Toggle, P, S2} from '../UI/index.js'
import time from '../../../server/utils/time.js'

export default class RescheduleOrderPopup extends React.Component {
    constructor(props) {
        super(props)

        this.initialState = {
            isOpen: false,
            ids: [],
            order: null,
            date: time.weekDay(props?.reseller),
            reschedulePickups: false,
            rescheduleCompleted: false,
            comment: '',
            callback: null,
            loading: false,
            errors: []
        }

        this.state = structuredClone(this.initialState)
    }

    open(ids, callback) {
        if (Array.isArray(ids)) {
            this.setState({isOpen: true, ids: [...ids], callback})
        } else {
            this.setState({isOpen: true, order: ids, callback})
        }
    }

    close() {
        this.setState(this.initialState)
    }

    confirm() {
        const {ids, order, date, reschedulePickups, rescheduleCompleted, comment, callback} = this.state

        this.setState({loading: true, errors: []})

        OrderActions.reschedule(order?._id ? [order._id] : ids, date, reschedulePickups, rescheduleCompleted, comment, (err, errors, order) => {
            if (err) {
                this.setState({errors: [err]})
            } else if (errors.length) {
                this.setState({errors})
            } else {
                OrderActions.setSelected([])
                typeof callback === 'function' && callback(order)
                this.close()
            }
        })
    }

    render() {
        const {isOpen, ids, order, date, reschedulePickups, rescheduleCompleted, comment, loading, errors} = this.state

        let showReschedulePickups = true

        if (order) {
            const pickupAddresses = order.addresses.filter((address) => address.type === 'pickup')

            if (!pickupAddresses.length || pickupAddresses.length === order.addresses.length) {
                showReschedulePickups = false
            }
        }

        return (
            <Modal show={isOpen} style={{width: 600, maxWidth: '90%'}} onClose={this.close.bind(this)}>
                <div style={{display: 'flex', marginBottom: 24}}>
                    <H4>Rit herplannen</H4>

                    <div style={{flex: 1}} />
                </div>

                <P style={{marginBottom: 24}}>Selecteer hieronder de datum waarop de rit wordt gepland.</P>

                <DatePicker
                    label='Datum'
                    value={date}
                    onChange={(event) => this.setState({date: event.target.value})}
                />

                {showReschedulePickups &&
                    <Toggle
                        label='Ophalen opnieuw inplannen'
                        checked={reschedulePickups}
                        onChange={(event) => this.setState({reschedulePickups: event.target.checked})}
                    />
                }

                <Toggle
                    label='Afgeronde adressen opnieuw inplannen'
                    checked={rescheduleCompleted}
                    onChange={(event) => this.setState({rescheduleCompleted: event.target.checked})}
                />

                <Input
                    label='Opmerking'
                    value={comment}
                    onChange={(event) => this.setState({comment: event.target.value})}
                />

                {errors.length > 0 && errors.map((error, index) => {
                    return <Alert key={`error${index}`} variant='danger'>{error}</Alert>
                })}

                <div style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-end'}}>
                    {ids.length > 1 &&
                        <>
                            <i style={{fontSize: 12, marginLeft: 6, marginRight: 3}} className='mdi mdi-check' />
                            <S2 style={{flex: 1}}>{`${ids.length} ritten geselecteerd`}</S2>

                        </>
                    }

                    {!errors.length && !loading &&
                        <Button
                            variant='text'
                            onClick={this.close.bind(this)}
                        >
                            Annuleren
                        </Button>
                    }

                    {!errors.length &&
                        <Button
                            variant='text'
                            loading={loading}
                            onClick={this.confirm.bind(this)}
                        >
                            Bevestigen
                        </Button>
                    }

                    {!!errors.length &&
                        <Button
                            style={{marginBottom: 0}}
                            variant='text'
                            onClick={this.close.bind(this)}
                        >
                            Sluiten
                        </Button>
                    }
                </div>
            </Modal>
        )
    }
}
