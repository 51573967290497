import React from 'react'

import OrderActions from '../../actions/OrderActions.js'

import {Alert, Button, Card, Check, Colors, Input, Modal, P, Row, S1, S2, Toggle} from '../UI/index.js'
import _ from 'lodash'
import dbCodes from '../../../server/dbCodes.js'

class CancelOrderPopup extends React.Component {
    constructor(props) {
        super(props)

        this.initialState = {
            isOpen: false,
            order: null,
            colli: [],
            ids: [],
            undo: false,
            comment: '',
            callback: null,
            loading: false,
            errors: []
        }

        this.state = structuredClone(this.initialState)
    }

    open(orderIds, callback) {
        let order = null
        let colli = []
        let ids = []

        if (Array.isArray(orderIds)) {
            ids = [...orderIds]
        } else {
            order = structuredClone(orderIds)

            colli = order.colli.filter((collo) => ['cancelled', 'expected', 'notsupplied', 'pickup', 'outforpickup', 'notcollected', 'athub', 'onhold', 'outfordelivery', 'notdelivered', 'manco'].includes(collo.status))

            colli.map((collo) => {
                if (collo.status !== 'cancelled') {
                    collo.originalStatus = collo.status
                    collo.originalReason = collo.reason
                } else {
                    collo.originalStatus = typeof collo.pickupAddressIndex === 'number' ? 'pickup' : collo.barcode ? 'expected' : 'athub'
                    collo.originalReason = ''
                }
            })

            if (colli.length === 1) {
                colli[0].status = 'cancelled'
                colli[0].reason = ''
            }
        }

        this.setState({isOpen: true, order, colli, ids, callback})
    }

    close() {
        this.setState(this.initialState)
    }

    updateColloStatus(colloIndex, event) {
        const colli = [...this.state.colli]

        colli[colloIndex].status = event.target.checked ? 'cancelled' : colli[colloIndex].originalStatus
        colli[colloIndex].reason = event.target.checked ? '' : colli[colloIndex].originalReason

        this.setState({colli})
    }

    confirm() {
        const {order, colli, ids, undo, comment, callback} = this.state

        this.setState({loading: true, errors: []})

        OrderActions.cancel(order ? [order._id] : ids, undo, order ? colli : null, comment, (err, errors, order) => {
            if (err) {
                this.setState({errors: [err]})
            } else if (errors.length) {
                this.setState({errors})
            } else {
                OrderActions.setSelected([])
                typeof callback === 'function' && callback(order)
                this.close()
            }
        })
    }

    render() {
        const {isOpen, order, colli, ids, undo, comment, loading, errors} = this.state

        return (
            <Modal
                show={isOpen}
                title='Annuleren'
                style={{width: 600, maxWidth: '90%'}}
                onClose={this.close.bind(this)}
            >
                {order &&
                    <>
                        {colli.length > 0 ?
                                <P style={{marginBottom: 24}}>Selecteer hieronder de colli die geannuleerd moeten worden.</P> :
                            order.status === dbCodes.status.geannuleerd() > 0 ?
                                    <P style={{marginBottom: 24}}> Klik op bevestigen om de rit te annuleren.</P> :
                                    <P style={{marginBottom: 24}}> Klik op bevestigen om de rit te opnieuw te activeren.</P>
                        }

                        {colli.length > 1 &&
                            <Check
                                label='Selecteer alles'
                                checked={_.every(colli, (collo) => collo.status === 'cancelled')}
                                indeterminate={_.some(colli, (collo) => collo.status === 'cancelled')}
                                onChange={(event) => {
                                    colli.map((collo, index) => {
                                        this.updateColloStatus(index, event)
                                    })
                                }}
                            />
                        }

                        {colli.map((collo, index) => {
                            return (
                                <div key={`collo${collo.index}`} style={{display: 'flex', alignItems: 'center'}}>
                                    <Check
                                        checked={collo.status === 'cancelled'}
                                        onChange={this.updateColloStatus.bind(this, index)}
                                    />

                                    <Card style={{display: 'flex', alignItems: 'center'}}>

                                        <S1 style={{marginRight: 12}}>{collo.index + 1}</S1>

                                        <div style={{flex: 1, marginBottom: collo.type ? 6 : 0}}>
                                            <P>{`${collo.barcode || 'Geen barcode'}`}</P>
                                            <P>{dbCodes.colloTypes[collo.type] || ''}</P>
                                        </div>

                                        <P style={{marginRight: 12}}>{dbCodes.colloStatus[collo.status]}</P>

                                        <i style={{fontSize: 8, color: Colors.colloStatusIcon[collo.status]}} className='mdi mdi-circle' />
                                    </Card>
                                </div>
                            )
                        })}

                        {order.colli.length > 0 && colli.length === 0 &&
                            <Alert variant='warning'>Deze rit heeft geen colli beschikbaar om te annuleren.</Alert>
                        }
                    </>
                }

                {ids.length > 0 &&
                    <>
                        <P style={{marginBottom: 12}}>Beschikbare colli krijgen de status:</P>

                        <Card style={{marginBottom: 12}}>
                            <Row style={{alignItems: 'center', padding: 6}}>
                                {undo ?
                                        <P style={{marginRight: 12}}>{`${dbCodes.colloStatus['expected']} / Gereed`}</P> :
                                        <P style={{marginRight: 12}}>{dbCodes.colloStatus['cancelled']}</P>
                                }

                                <i style={{fontSize: 12, color: undo ? Colors.grey20 : Colors.grey40}} className='mdi mdi-circle' />
                            </Row>
                        </Card>

                        <Toggle
                            label='Annuleren ongedaan maken'
                            checked={undo}
                            onChange={(event) => this.setState({undo: event.target.checked})}
                        />
                    </>
                }

                <Input
                    style={{marginTop: 24}}
                    label='Opmerking'
                    value={comment}
                    onChange={(event) => this.setState({comment: event.target.value})}
                />

                {errors.length > 0 && errors.map((error, index) => {
                    return <Alert key={`error${index}`} variant='danger'>{error}</Alert>
                })}

                <div style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-end'}}>
                    {ids.length > 1 &&
                        <>
                            <i style={{fontSize: 12, marginLeft: 6, marginRight: 3}} className='mdi mdi-check' />
                            <S2 style={{flex: 1}}>{`${ids.length} ritten geselecteerd`}</S2>

                        </>
                    }

                    {!errors.length && !loading &&
                        <Button
                            variant='text'
                            onClick={this.close.bind(this)}
                        >
                            Annuleren
                        </Button>
                    }

                    {!errors.length &&
                        <Button
                            variant='text'
                            loading={loading}
                            onClick={this.confirm.bind(this)}
                        >
                            Bevestigen
                        </Button>
                    }

                    {!!errors.length &&
                        <Button
                            variant='text'
                            onClick={this.close.bind(this)}
                        >
                            Sluiten
                        </Button>
                    }
                </div>
            </Modal>
        )
    }
}

export default CancelOrderPopup
