import React from 'react'

import _ from 'underscore'

import {Colors, Popover, ListItem} from '../UI/index.js'

class NavBarLink extends React.Component {
    constructor(props) {
        super(props)

        this.state = {hover: false}
    }

    onNavigate(link) {
        const {history} = this.props
        history.push(link)
        this.popover && this.popover.close()
    }

    render() {
        const {branding} = this.props
        let {active, links, link} = this.props

        if (links) {
            links = _.filter(links, (link) => !!link)

            if (links.length === 1) {
                link = links[0]
                links = null
            }
        }

        const style = {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            fontSize: 16,
            color: Colors.textDark,
            cursor: 'pointer',
            width: 'auto',
            height: '100%',
            marginLeft: 20
        }

        const customBranding = branding && branding.color

        if (links) {
            return (
                <Popover
                    ref={(ref) => this.popover = ref}
                    popoverStyle={{marginLeft: 6}}
                    content={
                        <>
                            {links.map((link) => {
                                if (link) {
                                    return (
                                        <ListItem key={link.title} active={link.active} onClick={link.onClick || this.onNavigate.bind(this, link.target)}>
                                            {link.icon && <i style={{marginRight: 12, fontSize: 20}} className={link.icon} />}
                                            {link.title}
                                        </ListItem>
                                    )
                                }
                            })}
                        </>
                    }
                >
                    <div
                        style={style}
                        onMouseEnter={() => this.setState({hover: true})}
                        onMouseLeave={() => this.setState({hover: false})}
                    >
                        <div style={{height: '100%', display: 'flex', alignItems: 'center'}}>
                            {link.icon && <i style={{marginRight: 12, fontSize: 20}} className={link.icon} />}
                            {link.title}
                            <i className='mdi mdi-menu-down' />
                        </div>

                        <div style={{height: 4, background: active ? (branding === undefined ? Colors.grey20 : customBranding ? customBranding : Colors.brandColor) : Colors.backgroundWhite, width: '100%', transition: 'all ease 0.1s'}} />
                    </div>
                </Popover>
            )
        } else {
            return (
                <div
                    style={style}
                    onClick={link.onClick || this.onNavigate.bind(this, link.target)}
                    onMouseEnter={() => this.setState({hover: true})}
                    onMouseLeave={() => this.setState({hover: false})}
                >
                    <div style={{height: '100%', display: 'flex', alignItems: 'center'}}>
                        {link.icon && <i style={{marginRight: link.title ? 10 : 0, fontSize: 20}} className={link.icon} />}
                        {link.title}
                    </div>

                    <div style={{height: 4, background: active ? (branding === undefined ? Colors.grey20 : customBranding ? customBranding : Colors.brandColor) : Colors.backgroundWhite, width: '100%', transition: 'all ease 0.1s'}} />
                </div>
            )
        }
    }
}

export default NavBarLink
