import React from 'react'

import moment from 'moment'
import _ from 'underscore'

import {Colors, Card, Caption, P, FlexRow, Icon, S2} from '../UI/index.js'

export default class OrderCard extends React.Component {
    constructor(props) {
        super(props)

        const getToLateAddresses = (order) => {
            const toLateAddresses = {first: false, multi: false, last: false}

            order.addresses.map((address, index) => {
                const toLate = !address.hasArrived && moment().isAfter(moment(`${order.date} ${address.endTime}`, 'YYYY-MM-DD HH:mm'))

                if (index === 0) {
                    toLateAddresses.first = toLate
                } else if (index === order.addresses.length - 1) {
                    toLateAddresses.last = toLate
                } else if (toLate) {
                    toLateAddresses.multi = true
                }
            })

            return toLateAddresses
        }

        this.state = {
            toLateAddresses: getToLateAddresses(this.props.order)
        }

        this.interval = setInterval(() => {
            const toLateAddresses = getToLateAddresses(this.props.order)

            if (toLateAddresses.first !== this.state.toLateAddresses.first || toLateAddresses.multi !== this.state.toLateAddresses.multi || toLateAddresses.last !== this.state.toLateAddresses.last) {
                this.setState({toLateAddresses})
            }
        }, 6000)
    }

    componentWillUnmount() {
        clearInterval(this.interval)
    }

    render() {
        const {toLateAddresses} = this.state
        const {order, showDate = false, showCustomer = true, onClick} = this.props

        const firstAddress = order.addresses[0]
        const lastAddress = order.addresses.length > 1 ? order.addresses[order.addresses.length - 1] : null

        const customerName = `${order.senderAddress?.name || ''}${order.senderAddress?.name ? ` (${order.customerAddress?.name})` : order.customerAddress?.name}`

        return (
            <Card
                shadow='true'
                style={{flexDirection: 'column', padding: '0px 6px 0px 6px', marginLeft: 6, marginRight: 3, marginBottom: 6, height: 'auto'}}
                onClick={onClick}
            >

                <FlexRow style={{alignItems: 'center', height: 24}}>

                    {showCustomer &&
                        <Caption ellipsis style={{flex: 1}}>{customerName}</Caption>
                    }

                    {showDate &&
                        <Caption ellipsis style={{flex: 1}}>{moment(order.date).format('DD-MM-YYYY')}</Caption>
                    }

                    <Caption ellipsis style={{flex: 1, textAlign: 'right'}}>{order.reference}</Caption>
                </FlexRow>

                {firstAddress &&
                    <>
                        <FlexRow style={{alignItems: 'center', height: 24, position: 'relative'}}>
                            <div
                                style={{
                                    position: 'absolute',
                                    background: Colors[firstAddress.type],
                                    width: 6,
                                    height: 45,
                                    left: -12,
                                    top: 0,
                                    borderRadius: '4px 0 0 4px'
                                }}
                            />

                            {toLateAddresses.first &&
                                <Icon size='md' style={{color: Colors.errorBright}} icon='mdi mdi-clock-alert-outline' />
                            }

                            {!firstAddress.position &&
                                <Icon size='md' style={{color: Colors.errorBright}} icon='mdi mdi-alert' />
                            }

                            <P ellipsis style={{flex: 1}}>{`${firstAddress.street} ${firstAddress.nr}${firstAddress.addition} ${firstAddress.postalCode} ${firstAddress.city}`}</P>
                            <Caption ellipsis>{`${firstAddress.startTime} - ${firstAddress.endTime}`}</Caption>
                        </FlexRow>

                        <FlexRow style={{alignItems: 'center', height: 21}}>
                            <Icon size='md' style={{color: Colors.textMedium}} icon={firstAddress.isBusiness ? 'mdi mdi-store-outline' : 'mdi mdi-account'} />
                            <Caption ellipsis style={{flex: 1}}>{firstAddress.name}</Caption>
                        </FlexRow>
                    </>
                }

                {order.addresses.length > 2 &&
                    <FlexRow style={{alignItems: 'center', justifyContent: 'center', height: 21}}>
                        <div style={{flex: 1, height: 1, background: Colors.grey30, marginRight: 12}} />

                        {toLateAddresses.multi &&
                            <Icon size='md' style={{color: Colors.errorBright}} icon='mdi mdi-clock-alert-outline' />
                        }

                        {_.some(order.addresses.slice(1, -1), (address) => !address.position) &&
                            <Icon size='md' style={{color: Colors.errorBright}} icon='mdi mdi-alert' />
                        }

                        <S2>{`+${order.addresses.length - 2}`}</S2>

                        <div style={{flex: 1, height: 1, background: Colors.grey30, marginLeft: 12}} />
                    </FlexRow>
                }

                {lastAddress &&
                    <>

                        <FlexRow style={{alignItems: 'center', height: 24, position: 'relative'}}>
                            <div
                                style={{
                                    position: 'absolute',
                                    background: Colors[lastAddress.type],
                                    width: 6,
                                    height: 45,
                                    left: -12,
                                    top: 0,
                                    borderRadius: '4px 0 0 4px'
                                }}
                            />

                            {toLateAddresses.last &&
                                <Icon size='md' style={{color: Colors.errorBright}} icon='mdi mdi-clock-alert-outline' />
                            }

                            {!lastAddress.position &&
                                <Icon size='md' style={{color: Colors.errorBright}} icon='mdi mdi-alert' />
                            }

                            <P ellipsis style={{flex: 1}}>{`${lastAddress.street} ${lastAddress.nr}${lastAddress.addition} ${lastAddress.postalCode} ${lastAddress.city}`}</P>
                            <Caption ellipsis>{`${lastAddress.startTime} - ${lastAddress.endTime}`}</Caption>
                        </FlexRow>

                        <FlexRow style={{alignItems: 'center', height: 21}}>
                            <Icon size='sm' style={{color: Colors.textMedium}} icon={lastAddress.isBusiness ? 'mdi mdi-store-outline' : 'mdi mdi-account'} />
                            <Caption ellipsis style={{flex: 1}}>{lastAddress.name}</Caption>
                        </FlexRow>
                    </>
                }

                {order.notes &&
                    <FlexRow style={{alignItems: 'center', height: 21}}>
                        <Icon size='sm' style={{color: Colors.textMedium}} icon='mdi mdi-message-outline' />
                        <Caption ellipsis style={{flex: 1}}>{order.notes}</Caption>
                    </FlexRow>
                }
            </Card>
        )
    }
}
