import React from 'react'

import Reflux from 'reflux-react-16'

import LoginStore from '../../stores/LoginStore.js'
import LoginActions from '../../actions/LoginActions.js'

import {Alert, Button, Card, Colors, H3, P} from '../../components/UI/index.js'
import {Input} from '../../components/UI/index.js'
import moment from 'moment'
import LoadingPage from '../../components/UI/LoadingPage.js'

export default class ForgotPassword extends Reflux.Component {
    constructor(props) {
        super(props)
        this.state = {email: '', message: '', loading: false}
        this.store = LoginStore
    }

    componentDidMount() {
        document.title = 'Wachtwoord vergeten'
    }

    onSubmit(event) {
        event.preventDefault()
        const {email} = this.state
        this.setState({message: '', loading: true})

        const subdomain = window?.location.hostname?.split('.')[0]

        LoginActions.sendResetPasswordMail(email, !['localhost', 'dev', 'test', 'app'].includes(subdomain) ? subdomain : null, () => {
            this.setState({message: 'Als jouw emailadres bij ons bekend is, is er een email gestuurd om je wachtwoord te wijzigen.', loading: false})
        })
    }

    onClickLogin() {
        window.location = '/login'
    }

    render() {
        const {email, message, loading, brandingLoading, branding} = this.state

        if (brandingLoading) {
            return <LoadingPage />
        }

        return (
            <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', height: '100vh', overflowY: 'auto', background: Colors.backgroundNeutral}}>
                <div style={{flex: 1, display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%'}}>
                    <form onSubmit={this.onSubmit.bind(this)}>
                        <Card shadow='true' style={{display: 'flex', flexDirection: 'column', alignItems: 'center', width: 450, padding: 24, marginTop: 120, marginLeft: 'auto', marginRight: 'auto'}}>

                            <img style={{maxHeight: 128, maxWidth: 256, marginBottom: 48, objectFit: 'contain'}} src={branding?.mainLogo || '/images/VELOYDLOGO.png'} />

                            <H3>Wachtwoord vergeten</H3>
                            <P style={{maxWidth: 400, marginBottom: 24}}>Vul hieronder het emailadres van je account in.</P>

                            <Input
                                style={{width: '100%'}}
                                type='email'
                                label='Emailadres'
                                value={email}
                                onChange={(event) => this.setState({email: event.target.value.toLowerCase()})}
                            />

                            {message && <Alert variant='success'>{message}</Alert>}

                            <Button
                                type='submit'
                                style={{width: '100%', marginTop: 12}}
                                color={branding?.color || Colors.brandColor}
                                loading={loading}
                            >
                                Bevestigen
                            </Button>

                            <Button
                                style={{margin: 'auto', marginTop: 72}}
                                variant='text'
                                onClick={this.onClickLogin.bind(this)}
                            >
                                Inloggen
                            </Button>
                        </Card>
                    </form>
                </div>

                <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%', padding: 24}}>
                    <img style={{objectFit: 'contain', width: 100}} src='/images/VELOYDLOGO.png' alt='Logo' />

                    <div style={{display: 'flex', marginTop: 12}}>
                        <P>&copy; {moment().year()} Veloyd</P>
                        <P style={{marginLeft: 6, marginRight: 6}}>|</P>
                        <P><a style={{color: Colors.textDark}} target='_blank' href='https://veloyd.nl/privacy-policy/' rel='noreferrer'>Privacy policy</a></P>
                    </div>
                </div>
            </div>
        )
    }
}
