import React from 'react'

import Datepicker from './Datepicker.js'
import SearchBox from './SearchBox.js'
import TimeSlider from './TimeSlider.js'
import SearchItem from './SearchItem.js'

class SearchBar extends React.Component {
    addQuery(query) {
        const {queries, onQueryChange} = this.props

        queries.push(query)
        onQueryChange(queries)
    }

    removeQuery(i) {
        const {queries, onQueryChange} = this.props
        queries.splice(i, 1)

        onQueryChange(queries)
    }

    renderQueries() {
        const {queries} = this.props

        return queries.map((query, i) => {
            return (
                <SearchItem
                    key={i}
                    query={query}
                    onRemove={this.removeQuery.bind(this, i)}
                />
            )
        })
    }

    render() {
        const {columns, startDate, endDate, startTime, endTime, startInclude, endInclude, onDateChange, onTimeChange} = this.props

        return (
            <div style={{minWidth: onDateChange ? 530 : 300, marginRight: 12}}>
                <div style={{display: 'flex'}}>
                    <Datepicker
                        onChange={onDateChange}
                        startDate={startDate}
                        endDate={endDate}
                    />
                    <SearchBox columns={columns} onChange={this.addQuery.bind(this)} />
                </div>

                {startTime && endTime &&
                    <TimeSlider
                        startTime={startTime}
                        endTime={endTime}
                        startInclude={startInclude}
                        endInclude={endInclude}
                        onTimeChange={onTimeChange}
                    />
                }

                <div style={{display: 'flex'}}>
                    {this.renderQueries()}
                </div>
            </div>
        )
    }
}

export default SearchBar
