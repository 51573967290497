import React from 'react'

import _ from 'underscore'

import naturalSortBy from '../../utils/naturalSortBy.js'

import Header from './Header.js'
import Row from './Row.js'
import {Colors, P, IconButton, Spinner} from '../UI/index.js'

export default class Table extends React.PureComponent {
    constructor(props) {
        super(props)

        let {tableName, columns = [], sortColumnIndex = 0, sortDecending = false} = props

        let storedTable = JSON.parse(localStorage.getItem(`_${tableName}`))
        const pageSize = localStorage.getItem('_pageSize') || 40

        const tableReset = localStorage.getItem('_tableRemoved')

        if (storedTable && !tableReset) {
            console.log('removed table')
            localStorage.removeItem(`_${tableName}`)
            localStorage.setItem('_tableRemoved', 'true')

            storedTable = null
        }

        if (storedTable) {
            columns.map((column) => {
                if (typeof storedTable[column.title] === 'boolean') {
                    column.visible = storedTable[column.title]
                }
            })

            if (storedTable.sortColumnIndex) {
                sortColumnIndex = storedTable.sortColumnIndex
            }

            sortDecending = storedTable.sortDecending || false
        }

        this.state = {
            sortColumnIndex,
            sortDecending,
            page: 1,
            pageSize,
            columns
        }

        this.selectRow = this.selectRow.bind(this)
        this.selectAllRows = this.selectAllRows.bind(this)
        this.renderHeader = this.renderHeader.bind(this)
        this.renderRows = this.renderRows.bind(this)
        this.isOnPage = this.isOnPage.bind(this)
        this.renderSpinner = this.renderSpinner.bind(this)
        this.renderPagination = this.renderPagination.bind(this)
        this.selectPage = this.selectPage.bind(this)
    }

    componentDidMount() {
        // TODO move setInterval to OrderStore check all orders and use setState if necessary to trigger rerender of components
        // if (this.props.forceUpdate) {
        // const intervalId = setInterval(() => this.forceUpdate(), 30000)
        // this.setState({intervalId})
        // }
    }

    setPageSize(event) {
        const pageSize = parseInt(event.target.value)
        localStorage.setItem('_pageSize', pageSize)

        this.setState({pageSize})
    }

    headerToggle(title) {
        const columns = [...this.state.columns]
        const {tableName} = this.props
        const columnIndex = _.findIndex(columns, {title})

        if (columnIndex > -1) {
            columns[columnIndex].visible = !columns[columnIndex].visible
            this.setState({columns})

            const storedTable = JSON.parse(localStorage.getItem(`_${tableName}`)) || {}
            storedTable[title] = columns[columnIndex].visible
            localStorage.setItem(`_${tableName}`, JSON.stringify(storedTable))
        }
    }

    setSort(sortColumnIndex) {
        const {tableName} = this.props

        let {sortDecending} = this.state
        if (sortColumnIndex === this.state.sortColumnIndex) {
            sortDecending = !sortDecending
        } else {
            sortDecending = false
        }

        const storedTable = JSON.parse(localStorage.getItem(`_${tableName}`)) || {}
        storedTable.sortColumnIndex = sortColumnIndex
        storedTable.sortDecending = sortDecending
        localStorage.setItem(`_${tableName}`, JSON.stringify(storedTable))

        this.setState({sortColumnIndex, sortDecending})
    }

    selectRow(id, event) {
        const {onChange} = this.props
        let {selectedRows, rows} = this.props
        const {sortColumnIndex, sortDecending, columns} = this.state

        if (selectedRows.indexOf(id) > -1) {
            selectedRows.splice(selectedRows.indexOf(id), 1)
        } else {
            if (event.shiftKey) { // select multiple rows
                window.getSelection().removeAllRanges()

                const column = columns[sortColumnIndex] || columns[0]
                const sortKey = column.sort || column.text || column.key || column.render

                rows = naturalSortBy(rows, (row) => {
                    if (typeof sortKey === 'function') {
                        const value = sortKey(row)
                        return typeof value === 'string' ? value.toLowerCase() : value
                    }
                    const value = traverse(row, sortKey)
                    return typeof value === 'string' ? value.toLowerCase() : value
                })

                if (sortDecending) {
                    rows.reverse()
                }

                const lastSelectedRowId = selectedRows[selectedRows.length - 1]
                const lastSelectedRowIndex = rows.findIndex((row) => row._id === lastSelectedRowId)
                const selectedRowIndex = rows.findIndex((row) => row._id === id)

                if (lastSelectedRowIndex > -1 && selectedRowIndex > -1) {
                    const start = Math.min(lastSelectedRowIndex, selectedRowIndex)
                    const end = Math.max(lastSelectedRowIndex, selectedRowIndex)

                    const idsBetween = rows.slice(start, end + 1).map((row) => row._id)

                    selectedRows.push(...idsBetween)
                }
            } else {
                selectedRows.push(id)
            }
        }

        selectedRows = _.uniq(selectedRows)

        onChange(selectedRows)
    }

    selectRows(ids) {
        if (ids.length > 1) {
            let {selectedRows, onChange} = this.props
            selectedRows = _.uniq([...selectedRows, ...ids])
            onChange(selectedRows)
        }
    }

    selectAllRows() {
        let selected = []

        const {selectedRows, onChange} = this.props
        let {rows} = this.props
        const {sortColumnIndex, sortDecending, columns} = this.state

        const column = columns[sortColumnIndex] || columns[0]
        const sortKey = column.sort || column.text || column.key || column.render

        rows = naturalSortBy(rows, (row) => {
            if (typeof sortKey === 'function') {
                const value = sortKey(row)
                return typeof value === 'string' ? value.toLowerCase() : value
            }
            const value = traverse(row, sortKey)
            return typeof value === 'string' ? value.toLowerCase() : value
        })

        if (sortDecending) {
            rows.reverse()
        }

        if (selectedRows.length !== rows.length) {
            selected = rows.map((row) => {
                return row._id
            })
        }

        onChange(selected)
    }

    addPageToSelection() {
        const {onChange} = this.props
        let {rows, selectedRows} = this.props
        const {columns, sortColumnIndex, sortDecending} = this.state

        const column = columns[sortColumnIndex] || columns[0]
        const sortKey = column.sort || column.text || column.key || column.render

        rows = naturalSortBy(rows, (row) => {
            if (typeof sortKey === 'function') {
                const value = sortKey(row)
                return typeof value === 'string' ? value.toLowerCase() : value
            }
            const value = traverse(row, sortKey)
            return typeof value === 'string' ? value.toLowerCase() : value
        })

        if (sortDecending) {
            rows.reverse()
        }

        let rowsOnCurrentPage = rows.filter((row, i) => this.isOnPage(i) && row)
        rowsOnCurrentPage = rowsOnCurrentPage.map((row) => row._id)
        selectedRows = selectedRows.concat(rowsOnCurrentPage)

        onChange(selectedRows)
    }

    renderHeader() {
        const {selectedRows, rows, tableName, onChange} = this.props
        const {pageSize, columns, sortColumnIndex, sortDecending} = this.state

        const allRowsSelected = rows.length > 0 && selectedRows.length == rows.length

        return (
            <Header
                noPagination={this.props.noPagination}
                setSort={this.setSort.bind(this)}
                sortColumnIndex={sortColumnIndex}
                sortDecending={sortDecending}
                columns={columns}
                selectAllRows={this.selectAllRows}
                allRowsSelected={allRowsSelected}
                selectedRows={selectedRows}
                addPageToSelection={this.addPageToSelection.bind(this)}
                pageSize={pageSize}
                setPageSize={this.setPageSize.bind(this)}
                headerToggle={this.headerToggle.bind(this)}
                tableName={tableName}
                onOptionChange={onChange}
            />
        )
    }

    renderRows() {
        const {selectedRows, rowBackground, onChange, serverSidePagination} = this.props
        let {rows} = this.props
        const {sortColumnIndex, sortDecending, columns} = this.state

        const column = columns[sortColumnIndex] || columns[0]
        const sortKey = column.sort || column.text || column.key || column.render

        rows = naturalSortBy(rows, (row) => {
            if (typeof sortKey === 'function') {
                const value = sortKey(row)
                return typeof value === 'string' ? value.toLowerCase() : value
            }

            const value = traverse(row, sortKey)
            return typeof value === 'string' ? value.toLowerCase() : value
        })

        if (sortDecending) {
            rows.reverse()
        }

        return rows.map((row, i) => {
            if (serverSidePagination || this.isOnPage(i)) {
                const selected = selectedRows.indexOf(row._id) > -1
                const background = rowBackground && rowBackground(row)

                return (
                    <Row
                        key={row._id}
                        selectableKey={row._id}
                        row={row}
                        columns={columns}
                        selected={selected}
                        selectRow={this.selectRow}
                        background={background}
                        canCheck={!!onChange}
                    />
                )
            }
        })
    }

    isOnPage(i) {
        const {page, pageSize} = this.state
        return this.props.noPagination || ((i >= ((page - 1) * pageSize)) && (i < (page * pageSize)))
    }

    renderSpinner() {
        return (
            <div style={{marginRight: 'auto', marginLeft: 'auto', marginTop: 20}}>
                <Spinner name='ball-clip-rotate' fadeIn='none' />
            </div>
        )
    }

    renderPagination() {
        const {pageSize, page} = this.state
        const {rows, totalRowsCount} = this.props

        const items = []
        const nrOfPages = Math.ceil((totalRowsCount || rows.length) / pageSize) || 1

        let pagesLeft = Math.min(4, Math.max(0, 4 - (5 - page)))
        let pagesRight = Math.min(4, nrOfPages - page)

        if (pagesLeft < 4) {
            pagesRight += 4 - pagesLeft
        } else if (pagesRight < 4) {
            pagesLeft += 4 - pagesRight
        }

        for (let number = 0; number < pagesLeft; number++) {
            const pageNr = page - pagesLeft + number
            if (pageNr > 0) {
                items.push(
                    <IconButton
                        key={`page${pageNr}`}
                        style={{fontSize: 14}}
                        onClick={this.selectPage.bind(this, pageNr)}
                    >
                        {pageNr}
                    </IconButton>
                )
            }
        }

        items.push(
            <IconButton
                key={`page${page}`}
                style={{colors: Colors.brandColor, fontSize: 16, fontWeight: 'bold'}}
            >
                {page}
            </IconButton>
        )

        for (let number = 1; number <= pagesRight; number++) {
            const pageNr = page + number
            if (pageNr <= nrOfPages) {
                items.push(
                    <IconButton
                        key={`page${pageNr}`}
                        style={{fontSize: 14}}
                        onClick={this.selectPage.bind(this, pageNr)}
                    >
                        {pageNr}
                    </IconButton>
                )
            }
        }

        return (
            <>
                <IconButton onClick={this.selectPage.bind(this, 1)}>
                    <i className='mdi mdi-chevron-double-left' />
                </IconButton>
                <IconButton onClick={this.selectPage.bind(this, Math.max(1, (page - 1)))}>
                    <i className='mdi mdi-chevron-left' />
                </IconButton>
                {items}
                <IconButton onClick={this.selectPage.bind(this, Math.min(nrOfPages, (page + 1)))}>
                    <i className='mdi mdi-chevron-right' />
                </IconButton>
                <IconButton onClick={this.selectPage.bind(this, nrOfPages)}>
                    <i className='mdi mdi-chevron-double-right' />
                </IconButton>
            </>
        )
    }

    selectPage(page) {
        const {onPagination} = this.props
        const {pageSize} = this.state
        this.setState({page}, () => {
            typeof onPagination === 'function' && onPagination(+pageSize, page)
        })
    }

    render() {
        const {loading, rows, selectedRows, style = {}} = this.props

        return (
            <div style={{display: 'flex', flexDirection: 'column', height: '100%', ...style}}>
                {this.renderHeader()}

                <div style={{display: 'flex', flexDirection: 'column', flex: '1 0px', overflowY: 'auto'}}>
                    {loading && this.renderSpinner()}
                    {!loading && this.renderRows()}
                </div>

                <div style={{display: 'flex', alignItems: 'center', background: Colors.backgroundWhite, borderTop: '1px solid', borderColor: Colors.grey20, padding: '5px 10px 5px 10px'}}>
                    <div style={{flex: 1}} />

                    {this.renderPagination()}

                    <div style={{display: 'flex', justifyContent: 'flex-end', flex: 1}}>
                        <P>{`${selectedRows.length} van ${rows.length} geselecteerd`}</P>
                    </div>
                </div>
            </div>
        )
    }
}

function traverse(obj, key) {
    if (key) {
        return key.split('.').reduce((cur, key) => {
            if (cur && cur[key]) {
                return cur[key]
            } else {
                return ''
            }
        }, obj)
    } else {
        return ''
    }
}
