import React, {useState} from 'react'
import {IconButton, Popover, Colors, FlexRow} from '../UI/index.js'

/**
 * @typedef {object} IconPickerProps
 * @property {string} value
 * @property {string[]} icons
 * @property {(event) => void} onChange
 * @property {string} [label]
 * @property {string} [placeholder]
 * @property {boolean} [isInvalid]
 * @property {boolean} [disabled]
 */

/**
 *
 * @param {IconPickerProps} props
 * @returns {JSX.Element}
 */
const IconPicker = ({value, icons, onChange, label, placeholder, isInvalid, disabled}) => {
    const [popOverOpen, setPopOverOpen] = useState(false)

    const onClickIcon = (icon, event) => {
        event.target.value = icon
        onChange(event)
    }

    const popoverRef = React.useRef(null)

    const wrapperStyle = {
        display: 'flex',
        flexWrap: 'wrap',
        height: 42,
        minHeight: 42,
        width: '100%',
        position: 'relative',
        paddingTop: 12,
        paddingRight: 10,
        borderBottom: `1px solid ${isInvalid ? Colors.errorBright : Colors.border}`,
        borderRadius: '4px 4px 0px 0px',
        background: disabled ? Colors.grey20 : Colors.backgroundWhite,
        color: disabled ? Colors.textMedium : Colors.textDark,
        cursor: disabled ? 'default' : 'text'
    }

    const focusedState = popOverOpen || value || placeholder

    const labelStyle = {
        display: 'flex',
        alignItems: 'center',
        position: 'absolute',
        paddingLeft: 10,
        top: focusedState ? 2 : 10,
        fontSize: focusedState ? 12 : 14,
        color: Colors.textMedium,
        cursor: disabled ? 'default' : 'text',
        transition: 'all .2s ease'
    }

    return (
        <Popover
            ref={popoverRef}
            content={
                <div style={{display: 'flex', flexWrap: 'wrap', gap: 4, width: 'auto'}}>
                    {icons.map((icon) => (
                        <IconButton
                            key={icon}
                            onClick={(event) => onClickIcon(icon, event)}
                        >
                            <i style={{fontSize: 20}} className={`mdi mdi-${icon}`} />
                        </IconButton>
                    ))}
                </div>
            }
            onToggle={(open) => setPopOverOpen(open)}
            disabled={disabled}
        >
            <div
                tabIndex={0}
                onFocus={() => {
                    if (!disabled) {
                        popoverRef.current.show()
                    }
                }}
                onBlur={() => {
                    setTimeout(() => {
                        popoverRef.current.close()
                    }, 100)
                }}
                style={{display: 'flex', flexDirection: 'column'}}
            >
                <div style={wrapperStyle}>
                    <div style={labelStyle}>
                        <div style={{whiteSpace: 'nowrap'}}>{label}</div>
                    </div>

                    <FlexRow style={{position: 'absolute', left: 12, bottom: 6, paddingRight: 20}}>
                        <i style={{fontSize: 18, color: value ? 'inherit' : Colors.textMedium}} className={`mdi mdi-${value || placeholder}`} />
                    </FlexRow>

                    <i
                        style={{position: 'absolute', right: 10, top: 10, color: Colors.textDark}}
                        className='mdi mdi-menu-down'
                    />

                </div>
                {/* <IconButton>
                    <i className={`mdi mdi-${value}`}/>
                </IconButton> */}
            </div>
        </Popover>
    )
}

export default IconPicker
