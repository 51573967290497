import React from 'react'

import aSync from 'async'
import moment from 'moment'
import _ from 'lodash'

import OrderActions from '../../actions/OrderActions.js'
import CustomerActions from '../../actions/CustomerActions.js'
import AddressActions from '../../actions/AddressActions.js'

import {DropdownButton, Modal, Popup, Card, Colors, IconButton, H4, H5, ScrollView, Button, Check, H6, Row, Column} from '../UI/index.js'
import {Input, TextArea, Price, CurrencyInput, DatePicker, Recurrence, Toggle, Select, NewSelect, Alert, Spinner} from '../UI/index.js'
import Addresses from './Addresses.js'
import EditCollo from './EditCollo.js'
import OrderCard from './OrderCard.js'
import NewOrder from './NewOrder.js'
import EditAddress from './EditAddress.js'

export default class EditOrderModal extends React.Component {
    constructor(props) {
        super(props)

        this.initialState = {
            modalIsOpen: false,
            createOrderMode: localStorage.createOrderMode || 'save',
            editTemplate: false,
            editTemplateFromDate: moment().format('YYYY-MM-DD'),
            orderLoading: false,
            orderError: null,
            order: NewOrder(),
            customer: {settings: {orders: {}, parcels: {}}},
            shifts: [],
            showMore: false,
            referenceValid: true,
            customerValid: true,
            addressDistanceLoading: false,
            recentCustomerOrders: [],
            priceLoading: false,
            editAll: false,
            removeAll: false,
            loading: false,
            message: '',
            error: ''
        }

        this.state = structuredClone(this.initialState)
    }

    open(order, editTemplate, editTemplateFromDate) {
        const createOrderMode = localStorage.createOrderMode || 'save'

        if (order) {
            order.colli = order.colli.filter((collo) => {
                return collo.barcode
            })


            this.setState({modalIsOpen: true, order, createOrderMode, editTemplate, editTemplateFromDate})
        } else {
            const {reseller} = this.props

            const order = NewOrder(reseller)

            this.setState({modalIsOpen: true, order, createOrderMode})
        }

        if (!order) {
            setTimeout(() => this.selectCustomer.focus(), 1)
        }
    }

    close() {
        this.setState(this.initialState)
    }

    onChangeDate(event) {
        const order = {...this.state.order}

        order.date = event.target.value
        order.shift = ''

        this.setState({shifts: []}, () => {
            OrderActions.getOrderShifts(order.date, (err, shifts) => {
                if (!err) {
                    this.setState({shifts})
                }
            })

            this.setState({order})
        })
    }

    onChangeCustomer(event) {
        const {reseller, customers} = this.props
        const order = {...this.state.order}

        const customer = customers[event.target.value]

        if (customer) {
            order.customer = customer._id
            order.contact = ''
            order.email = customer.email || ''
            order.phone = customer.phone || ''

            if (customer.settings.orders && !order._id) {
                order.addresses.map((address) => {
                    address.gpsRequired = customer.settings.orders.gpsRequired === 'default' ?
                        !!reseller.settings.gpsRequired :
                        !!customer.settings.orders.gpsRequired

                    address.scanRequired = customer.settings.orders.scanRequired === 'default' ?
                        !!reseller.settings.scanRequired :
                        !!customer.settings.orders.scanRequired

                    address.imageRequired = customer.settings.orders.imageRequired === 'default' ?
                        !!reseller.settings.imageRequired :
                        !!customer.settings.orders.imageRequired

                    if (address.type === 'delivery') {
                        address.signatureRequired = customer.settings.orders.defaultSigned === 'default' ?
                            !!reseller.settings.defaultSigned :
                            !!customer.settings.orders.defaultSigned

                        address.statedAddressOnly = customer.settings.orders.defaultStatedAddressOnly === 'default' ?
                            !!reseller.settings.defaultStatedAddressOnly :
                            !!customer.settings.orders.defaultStatedAddressOnly

                        address.nameRequired = customer.settings.orders.nameRequired === 'default' ?
                            !!reseller.settings.nameRequired :
                            !!customer.settings.orders.nameRequired
                    }
                })

                if (customer.settings.orders.generateReference) {
                    order.reference = order.reference || `${moment().format('YYMMDD')}-${Math.random().toString().substring(2, 5)}`
                }

                order.retourAddress = {
                    startTime: reseller?.settings?.account?.openingHours[moment().format('dddd').toLowerCase()] ?
                        reseller.settings.account.openingHours[moment().format('dddd').toLowerCase()].from :
                        moment().format('HH:mm'),
                    endTime: reseller?.settings?.account?.openingHours[moment().format('dddd').toLowerCase()] ?
                        reseller.settings.account.openingHours[moment().format('dddd').toLowerCase()].till :
                        moment().format('HH:mm'),
                    attention: '',
                    instructions: '',
                    ...customer.address
                }

                order.allowAddCollo = customer.settings.orders.allowAddCollo === 'default' ?
                    (reseller.settings.allowAddCollo || '') :
                    (customer.settings.orders.allowAddCollo || '')
            }

            this.setState({customer, customerValid: true}, () => {
                this.setState({order}, () => {
                    if (!order._id) {
                        if (customer.address && order.addresses[0].type === 'pickup') {
                            setTimeout(() => {
                                this.addresses.setCustomer(customer.name)
                            }, 10)
                        } else {
                            this.addresses.focus()
                        }
                    }

                    OrderActions.getRecentCustomerOrders(customer._id, 20, 0, (err, recentCustomerOrders) => {
                        if (!err) {
                            this.setState({recentCustomerOrders, disableRecentCustomerOrdersBtn: false})
                        }
                    })

                    if (customer.settings.orders && customer.settings.orders.defaultPriceTable) {
                        this.onChangePricetableId({target: {value: customer.settings.orders.defaultPriceTable}})
                    }
                })
            })
        }
    }

    onChangeContact(event) {
        const {customer} = this.state
        const order = {...this.state.order}

        order.contact = event.target.value

        const contact = _.find(customer?.contacts, {name: order.contact})

        if (contact) {
            order.email = contact.email || order.email
            order.phone = contact.phone || order.phone
        }

        this.setState({order})
    }

    loadMoreRecentCustomerOrders() {
        const {recentCustomerOrders, customer} = this.state

        this.setState({recentCustomerOrdersLoading: true})

        OrderActions.getRecentCustomerOrders(customer._id, 10, recentCustomerOrders.length, (err, moreRecentCustomerOrders) => {
            if (!err) {
                this.setState({recentCustomerOrders: [...recentCustomerOrders, ...moreRecentCustomerOrders], recentCustomerOrdersLoading: false})
                if (moreRecentCustomerOrders.length === 0) {
                    this.setState({disableRecentCustomerOrdersBtn: true})
                }
            }
        })
    }

    selectRecentOrder(recentOrder) {
        const order = {...this.state.order}

        order.contact = recentOrder.contact
        order.email = recentOrder.email
        order.phone = recentOrder.phone
        order.addresses = []
        recentOrder.addresses.map((address) => {
            const newAddress = {
                type: address.type,
                name: address.name,
                attention: address.attention,
                street: address.street,
                nr: address.nr,
                addition: address.addition,
                street2: address.street2,
                postalCode: address.postalCode,
                city: address.city,
                country: address.country,
                email: address.email || '',
                phone: address.phone || '',
                instructions: address.instructions,
                startTime: address.startTime,
                endTime: address.endTime,

                gpsRequired: !!address.gpsRequired,
                signatureRequired: !!address.signatureRequired,
                nameRequired: !!address.nameRequired,
                signatureNeighbors: !!address.signatureNeighbors,
                scanRequired: !!address.scanRequired,
                imageRequired: !!address.imageRequired,
                statedAddressOnly: !!address.statedAddressOnly,
                mailboxDelivery: !!address.mailboxDelivery,
                idCheck: !!address.idCheck,
                minimumAge: address.minimumAge ?? undefined
            }

            if (address.position) {
                newAddress.position = JSON.parse(JSON.stringify(address.position))
            }

            order.addresses.push(newAddress)
        })
        order.distance = recentOrder.distance || 0
        order.priceTableId = recentOrder.priceTableId || ''
        order.price = recentOrder.price
        order.feeIds = recentOrder.feeIds
        order.reference = recentOrder.reference
        order.notes = recentOrder.notes
        order.autoRetour = recentOrder.autoRetour

        if (recentOrder.retourAddress) {
            order.retourAddress = recentOrder.retourAddress
        }

        order.monitorTemperature = recentOrder.monitorTemperature
        order.sensorId = recentOrder.sensorId
        order.sensorId = recentOrder.sensorId
        order.minTemperature = recentOrder.minTemperature
        order.maxTemperature = recentOrder.maxTemperature

        this.setState({order})
    }

    onChangePrice(price, fees) {
        const order = {...this.state.order}

        order.priceTableId = ''
        order.feeIds = []
        order.price = price
        order.fees = fees

        this.setState({order})
    }

    onChangeAddresses(addresses, getPrice) {
        const {reseller} = this.props
        const order = {...this.state.order}

        const addressesLengthChanged = order.addresses.length !== addresses.length

        const isDeliveryOrder = (addresses.length === 1 && addresses[0].type === 'delivery') || (addresses.length === 2 && addresses[0].type === 'pickup' && addresses[1].type === 'delivery')

        if (!isDeliveryOrder) {
            order.monitorTemperature = false
            order.sensorId = ''
            delete order.minTemperature
            delete order.maxTemperature
        }

        const deliveryAddressesLengthChanged = order.addresses.filter((address) => address.type === 'delivery').length !== addresses.filter((address) => address.type === 'delivery').length
        const pickupAddressLengthChanged = order.addresses.filter((address) => address.type === 'pickup').length !== addresses.filter((address) => address.type === 'pickup').length

        order.addresses = [...addresses]

        if (order.priceTableId) {
            const priceTable = _.find(reseller.settings.orders.prices, {id: order.priceTableId})

            if (priceTable?.distanceFeeType === 'distance' && !priceTable.startAtReseller && order.addresses.length === 1) {
                order.priceTableId = ''
                order.feeIds = []
                order.price = '0,00'
            } else if (priceTable?.distanceFeeType === 'zones' && order.addresses.length > 2) {
                order.priceTableId = ''
                order.feeIds = []
                order.price = '0,00'
            } else if (addressesLengthChanged || deliveryAddressesLengthChanged || pickupAddressLengthChanged || getPrice || priceTable?.distanceFeeType === 'distance' || priceTable?.distanceFeeType === 'zones' || priceTable?.fuelFeeType === 'fuelFeeDistance') {
                this.calculateDistanceAndPrice(order, 500)
            }
        }

        this.setState({order})
    }

    onChangeRetourAddress(address) {
        const order = {...this.state.order}

        order.retourAddress = address

        this.setState({order})
    }

    onChangeValue(path, parser, event) {
        if (!event) {
            event = parser
            parser = undefined
        }

        const order = {...this.state.order}

        if (path === 'status') {
            order[path] = parseInt(event.target.value)
        } else if (path === 'rrule') {
            if (!event.target.value) {
                delete order.rrule
            } else {
                order.rrule = event.target.value
            }
        } else {
            if (typeof parser === 'function') {
                _.set(order, path, parser(event.target.value))
            } else {
                _.set(order, path, event.target.value)
            }
        }

        this.setState({order})
    }


    calculateDistanceAndPrice(order, delay = 0) {
        this.setState({priceLoading: true})

        if (this.calculatePriceTimeout) {
            clearTimeout(this.calculatePriceTimeout)
        }

        this.calculatePriceTimeout = setTimeout(() => {
            OrderActions.getPrice(order, (err, price, distance) => {
                const order = {...this.state.order}

                if (!err) {
                    order.price = price
                    order.distance = distance || 0

                    this.setState({priceLoading: false, order})
                } else {
                    this.setState({priceLoading: false, error: err})
                }
            })
        }, delay)
    }

    onClickAddCollo() {
        const {reseller} = this.props
        const order = {...this.state.order}

        const pickupAddressIndex = order.addresses.findIndex((address) => address.type === 'pickup')
        const deliveryAddressIndex = order.addresses.findIndex((address) => address.type === 'delivery')

        order.colli = [...order.colli, {
            index: order.colli.length,
            type: '',
            description: '',
            weight: 0,
            length: 0,
            width: 0,
            height: 0,
            barcode: 'Barcode',
            pickupAddressIndex,
            deliveryAddressIndex
        }]

        if (order.priceTableId) {
            const priceTable = _.find(reseller.settings.orders.prices, {id: order.priceTableId})

            if (priceTable?.distanceFeeType === 'distance' && !priceTable.startAtReseller && order.addresses.length === 1) {
                order.priceTableId = ''
                order.feeIds = []
                order.price = '0,00'
            } else if (priceTable?.distanceFeeType === 'zones' && order.addresses.length > 2) {
                order.priceTableId = ''
                order.feeIds = []
                order.price = '0,00'
            } else {
                this.calculateDistanceAndPrice(order, 500)
            }
        }


        this.setState({order})
    }

    onClickRemoveCollo(index) {
        const {reseller} = this.props
        const order = {...this.state.order}

        const colli = [...order.colli]
        colli.splice(index, 1)
        order.colli = colli

        if (order.priceTableId) {
            const priceTable = _.find(reseller.settings.orders.prices, {id: order.priceTableId})

            if (priceTable?.distanceFeeType === 'distance' && !priceTable.startAtReseller && order.addresses.length === 1) {
                order.priceTableId = ''
                order.feeIds = []
                order.price = '0,00'
            } else if (priceTable?.distanceFeeType === 'zones' && order.addresses.length > 2) {
                order.priceTableId = ''
                order.feeIds = []
                order.price = '0,00'
            } else {
                this.calculateDistanceAndPrice(order, 500)
            }
        }

        this.setState({order})
    }

    onChangePricetableId(event) {
        const {reseller} = this.props
        const order = {...this.state.order}
        const priceTable = _.find(reseller.settings.orders.prices, {id: event.target.value})

        if (priceTable) {
            order.priceTableId = priceTable.id || ''
            order.feeIds = []
            order.price = '0,00'

            this.setState({order})

            this.calculateDistanceAndPrice(order)
        } else {
            order.priceTableId = ''
            order.feeIds = []
            order.price = '0,00'

            this.setState({order})
        }
    }

    onChangeFees(event) {
        const order = {...this.state.order}

        order.feeIds = [...event.target.values]

        this.setState({order}, () => {
            this.calculateDistanceAndPrice(order)
        })
    }

    onChangeChecked(key) {
        const order = {...this.state.order}
        order[key] = !order[key]

        if (key === 'autoRetour' && !order.retourAddress) {
            order.retourAddress = {
                name: '',
                attention: '',
                street: '',
                nr: '',
                addition: '',
                street2: '',
                postalCode: '',
                city: '',
                country: 'NL',
                instructions: ''
            }
        }

        this.setState({order})
    }

    onChangeSensorId(event) {
        const {reseller} = this.props
        const order = {...this.state.order}

        order.sensorId = event.target.value

        const sensor = _.find(reseller.settings.orders.sensors, {sensorId: order.sensorId})

        if (sensor) {
            order.minTemperature = sensor.minTemperature || 0
            order.maxTemperature = sensor.maxTemperature || 0
        }


        this.setState({order})
    }

    validate() {
        const {order} = this.state
        const {customers} = this.props

        const referenceRequired = customers[order.customer] ? customers[order.customer].settings.orders.referenceRequired : false
        const referenceValid = !referenceRequired || order.reference
        const customerValid = !!customers[order.customer]

        if (!referenceValid) {
            this.referenceInput?.focus()
        }

        if (!customerValid) {
            this.selectCustomer?.focus()
        }

        if (referenceValid && customerValid) {
            return true
        } else {
            this.setState({referenceValid, customerValid})
        }
    }

    onSubmit(seperateOrders, event) {
        event.preventDefault()
        const {order, createOrderMode, editTemplate, editTemplateFromDate} = this.state
        const {customers, viewOrder} = this.props

        if (this.validate()) {
            this.setState({loading: true, error: ''})

            if (order._id) {
                if (order.rrule && editTemplate) {
                    OrderActions.editTemplate(order, editTemplateFromDate, (err) => {
                        if (err) {
                            this.setState({loading: false, error: err})
                        } else {
                            this.close()
                        }
                    })
                } else {
                    OrderActions.edit(order, (err, order) => {
                        if (err) {
                            this.setState({loading: false, error: err})
                        } else {
                            if (order && createOrderMode === 'save') {
                                viewOrder(order)
                                this.close()
                            } else if (createOrderMode === 'saveNew') {
                                this.setState(this.initialState, () => {
                                    this.open()
                                })
                            } else {
                                this.close()
                            }
                        }
                    })
                }
            } else {
                if (seperateOrders) {
                    aSync.eachSeries(order.addresses, (address, next) => {
                        const seperateOrder = JSON.parse(JSON.stringify(order))
                        seperateOrder.addresses = [address]

                        OrderActions.create(seperateOrder, (err) => {
                            if (err) {
                                this.setState({loading: false, error: err})
                            } else {
                                next()
                            }
                        })
                    }, () => {
                        this.close()
                    })
                } else {
                    OrderActions.create(order, (err, order) => {
                        if (err) {
                            this.setState({loading: false, error: err})
                        } else {
                            if (order && createOrderMode === 'save') {
                                viewOrder(order)
                                this.close()
                            } else if (createOrderMode === 'saveNew') {
                                this.setState(this.initialState, () => {
                                    this.open()
                                })
                            } else {
                                this.close()
                            }
                        }
                    })
                }
            }

            const customer = customers[order.customer]

            if (customer) {
                const contact = _.find(customer.contacts, {name: order.contact})
                if (!contact && order.contact) {
                    customer.contacts.push({name: order.contact, email: order.email, phone: order.phone})
                    CustomerActions.edit(customer)
                }
            }


            const addressesToUpdate = _.filter(order.addresses, {save: true})

            if (addressesToUpdate.length) {
                AddressActions.update(addressesToUpdate)
            }
        }
    }

    render() {
        const {modalIsOpen, createOrderMode, orderLoading, orderError, order, loading, message, error, editTemplate} = this.state
        const {customer, referenceValid, customerValid, shifts, showMore, recentCustomerOrders, priceLoading, recentCustomerOrdersLoading, disableRecentCustomerOrdersBtn} = this.state
        const {reseller, addressBook, customers} = this.props

        const priceTable = _.find(reseller.settings.orders.prices, {id: order.priceTableId})

        const showDays = (editTemplate || !order._id) && order.rrule && order.rrule.includes('FREQ=WEEKLY')
        const availableDays = {}

        if (showDays) {
            const splittedRrule = order.rrule.split('=')
            const rruleDays = splittedRrule.pop()

            rruleDays.split(',').map((day) => {
                if (day === 'MO') availableDays['monday'] = true
                if (day === 'TU') availableDays['tuesday'] = true
                if (day === 'WE') availableDays['wednesday'] = true
                if (day === 'TH') availableDays['thursday'] = true
                if (day === 'FR') availableDays['friday'] = true
                if (day === 'SA') availableDays['saturday'] = true
                if (day === 'SU') availableDays['sunday'] = true
            })
        }

        return (
            <Modal style={{width: 800}} show={modalIsOpen} onClose={this.close.bind(this)}>
                <div style={{display: 'flex', marginBottom: 24}}>
                    <H4>{order._id ? (editTemplate ? 'Bewerk herhaalde rit' : 'Bewerk rit') : 'Nieuwe rit'}</H4>

                    <div style={{flex: 1}}/>

                    <IconButton onClick={this.close.bind(this)}>
                        <i style={{color: Colors.buttonSolid}} className='mdi mdi-close'/>
                    </IconButton>
                </div>

                {orderLoading ?
                    <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: 250}}>
                        <Spinner name='ball-clip-rotate' fadeIn='none' color={Colors.textDark}/>
                    </div>:
                    <form onSubmit={this.onSubmit.bind(this, false)}>
                        <div style={{position: 'relative'}}>
                            {!!recentCustomerOrders.length &&
                                <Card style={{position: 'absolute', top: 0, left: 800, width: 360, overflowY: 'auto'}}>
                                    <H4>Recente ritten</H4>

                                    <ScrollView style={{maxHeight: 700, marginTop: 12}}>
                                        {recentCustomerOrders.map((order) => {
                                            return (
                                                <OrderCard
                                                    key={order._id}
                                                    showDate={true}
                                                    showCustomer={false}
                                                    order={order}
                                                    onClick={() => this.selectRecentOrder(order)}
                                                />
                                            )
                                        })}

                                        {recentCustomerOrders.length >= 20 &&
                                            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-around'}}>
                                                <Button
                                                    variant='text'
                                                    style={{marginBottom: 6, marginTop: 6}}
                                                    loading={recentCustomerOrdersLoading}
                                                    onClick={this.loadMoreRecentCustomerOrders.bind(this)}
                                                    disabled={disableRecentCustomerOrdersBtn}
                                                >
                                                    Laad meer
                                                </Button>
                                            </div>
                                        }
                                    </ScrollView>
                                </Card>
                            }

                            <Row>
                                <Column>
                                    <Select
                                        label='Klant'
                                        options={_.values(customers).filter((customer) => !customer.isArchived).map((customer) => ({value: customer._id, title: customer.name}))}
                                        value={order.customer}
                                        isInvalid={!customerValid}
                                        onChange={this.onChangeCustomer.bind(this)}
                                        ref={(ref) => this.selectCustomer = ref}
                                    />

                                    <Input
                                        id='contact'
                                        label='Contactpersoon'
                                        suggestions={customer ? customer.contacts?.map((contact) => contact.name) : []}
                                        suggestionFilter
                                        value={order.contact}
                                        onChange={this.onChangeContact.bind(this)}
                                    />
                                </Column>

                                <Column>
                                    <DatePicker
                                        label='Datum'
                                        value={order.date}
                                        disabled={!!order._id}
                                        onChange={this.onChangeDate.bind(this)}
                                    />

                                    <Check
                                        label='Herhalen'
                                        checked={!!order.rrule}
                                        disabled={!!order._id}
                                        info={!!order._id && !!order.rrule && 'Het herhaal-patroon kan niet worden aangepast. Om dit te wijzigen kun je een kopie van de rit maken, met een ander herhaal-patroon.'}
                                        onChange={() => {
                                            const order = {...this.state.order}

                                            if (!order.rrule) {
                                                order.rrule = this.recurrence.init()
                                            } else {
                                                order.rrule = ''
                                            }

                                            this.setState({order})
                                        }}
                                    />
                                </Column>
                            </Row>

                            <Row>
                                <Column>
                                    <Input
                                        label='Emailadres'
                                        value={order.email}
                                        onChange={this.onChangeValue.bind(this, 'email')}
                                    />

                                    <Input
                                        label='Telefoon'
                                        type='phone'
                                        value={order.phone}
                                        onChange={this.onChangeValue.bind(this, 'phone')}
                                    />
                                </Column>

                                <Column>
                                    <Recurrence
                                        date={order.date}
                                        rrule={order.rrule}
                                        onChange={this.onChangeValue.bind(this, 'rrule')}
                                        disabled={!!order._id}
                                        ref={(ref) => this.recurrence = ref}
                                    />

                                    {!order.rrule &&
                                        <TextArea
                                            label='Opmerkingen'
                                            value={order.notes}
                                            onChange={this.onChangeValue.bind(this, 'notes')}
                                        />
                                    }
                                </Column>
                            </Row>

                            <Row>
                                <Column>
                                    {order.rrule &&
                                        <TextArea
                                            label='Opmerkingen'
                                            value={order.notes}
                                            onChange={this.onChangeValue.bind(this, 'notes')}
                                        />
                                    }
                                </Column>
                                <Column/>
                            </Row>

                            <Row>
                                <Column>
                                    {customer?.settings.orders.newOrderInstructions &&
                                        <Alert
                                            style={{marginBottom: 12}}
                                            variant='info'
                                            icon='mdi mdi-message-outline'
                                        >
                                            {customer.settings.orders.newOrderInstructions}
                                        </Alert>
                                    }
                                </Column>
                            </Row>

                            <Row>
                                <Column>
                                    {customer?.settings?.openingHours && (!customer.settings.openingHours[moment(order.date).format('dddd').toLocaleLowerCase()].open) &&
                                        <Alert
                                            style={{marginBottom: 12}}
                                            variant='danger'
                                        >
                                            {`${customer.name} is op deze dag niet geopend.`}
                                        </Alert>
                                    }
                                </Column>
                            </Row>

                            <Addresses
                                reseller={reseller}
                                customer={customer}
                                customers={customers}
                                addresses={order.addresses}
                                addressBook={addressBook}
                                showAddAddressButton={order.type !== 'parcelPickup' && order.type !== 'parcelDelivery' && order.type !== 'retour'}
                                rrule={(editTemplate || !order._id) && order.rrule}
                                showDays={showDays}
                                availableDays={availableDays}
                                onChange={this.onChangeAddresses.bind(this)}
                                ref={(ref) => this.addresses = ref}
                            />

                            <div style={{display: 'flex', alignItems: 'center', marginTop: 24}}>
                                <H5 style={{flex: 1}}>Inhoud</H5>
                            </div>

                            {order.colli.map((collo, index) => {
                                return (
                                    <Card key={`Collo${index}`} style={{padding: 0, marginBottom: 12}}>
                                        <EditCollo
                                            order={order}
                                            collo={collo}
                                            onChange={(collo) => {
                                                const order = {...this.state.order}
                                                order.colli[index] = collo
                                                this.setState({order})
                                            }}
                                            onRemove={this.onClickRemoveCollo.bind(this, index)}
                                        />
                                    </Card>
                                )
                            })}

                            <Row>
                                <Button
                                    variant='outline-white'
                                    style={{marginBottom: 12}}
                                    onClick={this.onClickAddCollo.bind(this)}
                                    tooltip='Nieuwe collo'
                                    icon='mdi mdi-plus'
                                />
                            </Row>

                            <H5 style={{marginTop: 24}}>Administratie</H5>

                            <Row>
                                <Column>
                                    <Toggle
                                        label='Rit factureren'
                                        checked={!order.dontInvoice}
                                        onChange={this.onChangeChecked.bind(this, 'dontInvoice')}
                                    />

                                </Column>

                                <Column>
                                    <DatePicker
                                        label='Factuurdatum'
                                        value={!order._id ? order.date : order.invoiceDate}
                                        onChange={this.onChangeValue.bind(this, 'invoiceDate')}
                                        disabled={!order._id || order.dontInvoice || order.invoiced}
                                    />
                                </Column>
                            </Row>


                            {reseller.settings.orders.useNewPrices &&
                                <>
                                    <Row>
                                        <Column>
                                            <NewSelect
                                                loading={priceLoading}
                                                label='Tarieftabel'
                                                allowEmptyValue
                                                value={order.priceTableId}
                                                onChange={this.onChangePricetableId.bind(this)}
                                            >
                                                {(reseller.settings.orders.prices || []).map((priceTable) => {
                                                    let disabled = false

                                                    if (priceTable?.distanceFeeType === 'distance' && !priceTable.startAtReseller && order.addresses.length === 1) {
                                                        disabled = true
                                                    }
                                                    if (priceTable.distanceFeeType === 'zones' && order.addresses.length > 2) {
                                                        disabled = true
                                                    }

                                                    return (
                                                        <option key={priceTable.id} value={priceTable.id} disabled={disabled}>{priceTable.name}</option>
                                                    )
                                                })}
                                            </NewSelect>

                                        </Column>

                                        <Column>
                                            <Select
                                                label='Toeslag'
                                                values={order.feeIds}
                                                options={_.keys(priceTable?.fees || {}).map((id) => {
                                                    const fee = priceTable.fees[id]
                                                    return {value: id, title: fee.name}
                                                })}
                                                onChange={this.onChangeFees.bind(this)}
                                                disabled={!order.priceTableId || !priceTable}
                                            />
                                        </Column>
                                    </Row>
                                </>
                            }

                            <Row>
                                <Column>
                                    <TextArea
                                        label='Referentie'
                                        value={order.reference}
                                        isInvalid={!referenceValid}
                                        onChange={this.onChangeValue.bind(this, 'reference')}
                                        ref={(ref) => this.referenceInput = ref}
                                    />
                                </Column>

                                <Column>
                                    <Row>
                                        {order.priceTableId && order.distance > 0 &&
                                            <Input
                                                style={{width: 100}}
                                                label='Afstand'
                                                value={`${order.distance} km`}
                                                readOnly
                                            />
                                        }

                                        {reseller.settings.orders.useNewPrices ?
                                            <CurrencyInput
                                                label='Prijs'
                                                value={order.price}
                                                onChange={this.onChangeValue.bind(this, 'price')}
                                                readOnly={order.priceTableId}
                                            /> :
                                            <Price
                                                label='Prijs'
                                                price={order.price}
                                                fees={order.fees}
                                                priceTables={reseller.settings.priceTables || []}
                                                onChange={this.onChangePrice.bind(this)}
                                                ref={(ref) => this.price = ref}
                                            />
                                        }
                                    </Row>
                                </Column>
                            </Row>


                            <H5
                                style={{cursor: 'pointer', marginTop: 24, marginBottom: 12}}
                                onClick={() => this.setState({showMore: !showMore})}
                            >
                                {'Overige opties'}
                                <i className={showMore ? 'mdi mdi-chevron-up' : 'mdi mdi-chevron-down'}/>
                            </H5>

                            {showMore &&
                                <>
                                    <Row>
                                        {!reseller.isShop && reseller.permissions.shifts &&
                                            <Column>
                                                <Select
                                                    label='Dienst'
                                                    info='Door een rit te koppelen aan een dienst wordt een koerier toegewezen zodra deze wordt toegewezen aan de dienst'
                                                    allowEmptyValue
                                                    value={order.shift}
                                                    onChange={this.onChangeValue.bind(this, 'shift')}
                                                >
                                                    {shifts.map((shift) => {
                                                        return (
                                                            <option key={shift._id} value={shift.name}>{shift.name}</option>
                                                        )
                                                    })}
                                                </Select>
                                            </Column>
                                        }

                                        <Column>
                                            {order.addresses.length > 2 &&
                                                <Select
                                                    label='Adres volgorde'
                                                    info='De volgorde waarin de rit gereden mag worden, de automatische routeoptimalisatie houdt hier rekening mee.'
                                                    value={order.addressOrder}
                                                    onChange={this.onChangeValue.bind(this, 'addressOrder')}
                                                >
                                                    <option value='fixed'>Vaste volgorde</option>
                                                    <option value='random'>Willekeurig</option>
                                                </Select>
                                            }
                                        </Column>
                                    </Row>

                                    {!reseller.isShop && !order.templateId &&
                                        <Row>
                                            <Column>
                                                <Input
                                                    label='Aantal bezorgpogingen'
                                                    info='Ritten worden automatisch verplaatst naar de volgende werkdag na een mislukte bezorgpoging tot het maximaal aantal bezorgpogingen is bereikt. Vul 0 in om ritten nooit automatisch te verplaatsen.'
                                                    type='number'
                                                    min={0}
                                                    value={order.nrOfDeliveries}
                                                    onChange={this.onChangeValue.bind(this, 'nrOfDeliveries')}
                                                />
                                            </Column>

                                            <Column>
                                                <Toggle
                                                    label='Automatisch retour'
                                                    checked={order.autoRetour}
                                                    onChange={this.onChangeChecked.bind(this, 'autoRetour')}
                                                />
                                            </Column>
                                        </Row>
                                    }

                                    <Row>
                                        <Column>
                                            {!reseller.isShop && order.autoRetour && order.nrOfDeliveries > 0 &&
                                                <Input
                                                    label='Bewaartijd'
                                                    info='Hoeveel dagen blijft de rit in bewaring na de laatste bezorgpoging. Vul 0 in om na de laatste bezorgpoging meteen een retourrit aan te maken.'
                                                    type='number'
                                                    value={order.nrOfDaysBeforeRetour}
                                                    onChange={this.onChangeValue.bind(this, 'nrOfDaysBeforeRetour')}
                                                />
                                            }
                                        </Column>

                                        <Column/>
                                    </Row>

                                    {!reseller.isShop && order.autoRetour &&
                                    <>
                                        <H6>Retouradres</H6>

                                        <Card style={{width: '100%', background: Colors.grey20}}>
                                            <div style={{height: 10}}/>
                                            <EditAddress
                                                id='address-retour'
                                                address={{...order.retourAddress, type: 'delivery'}}
                                                addressBook={addressBook}
                                                onChange={this.onChangeRetourAddress.bind(this)}
                                                allowAttachments={true}
                                                ref={(ref) => this.retourAddress = ref}
                                            />
                                        </Card>
                                    </>
                                    }

                                    <Row style={{marginTop: 12}}>
                                        <Column>
                                            <Select
                                                label='Collo toevoegen toestaan'
                                                info=''
                                                options={[
                                                    {title: 'Ja', value: 'yes'},
                                                    {title: 'Alleen bij bezorgen', value: 'deliver'},
                                                    {title: 'Alleen bij ophalen', value: 'pickup'},
                                                    {title: 'Nee', value: ''}
                                                ]}
                                                value={order.allowAddCollo}
                                                onChange={this.onChangeValue.bind(this, 'allowAddCollo')}
                                            />

                                        </Column>
                                        <Column/>
                                    </Row>

                                    <Row>
                                        <Column>
                                            {reseller.settings.orders.monitorTemperature &&

                                            <Toggle
                                                label='Geconditioneerd transport'
                                                checked={order.monitorTemperature}
                                                onChange={this.onChangeChecked.bind(this, 'monitorTemperature')}
                                            />

                                            }
                                        </Column>

                                        <Column/>
                                    </Row>

                                    {order.monitorTemperature &&
                                        <Row>
                                            <Column>
                                                <Select
                                                    styl
                                                    label='Sensor ID'
                                                    value={order.sensorId}
                                                    allowEmptyValue={true}
                                                    onChange={this.onChangeSensorId.bind(this)}
                                                >
                                                    {(reseller.settings.orders.sensors || []).map((sensor) => {
                                                        return (
                                                            <option key={sensor.id} value={sensor.sensorId}>{sensor.name}</option>
                                                        )
                                                    })}

                                                </Select>
                                            </Column>

                                            <Column>
                                                <Input
                                                    id='minTemperature'
                                                    type='number'
                                                    label='Minimum temperatuur'
                                                    value={order.minTemperature}
                                                    onChange={this.onChangeValue.bind(this, 'minTemperature')}
                                                />

                                                <Input
                                                    id='maxTemperature'
                                                    type='number'
                                                    label='Maximum temperatuur'
                                                    value={order.maxTemperature}
                                                    onChange={this.onChangeValue.bind(this, 'maxTemperature')}
                                                />
                                            </Column>
                                        </Row>
                                    }
                                </>
                            }
                        </div>

                        {message &&
                            <Alert variant="success">{message}</Alert>
                        }
                        {error &&
                            <Alert variant="danger">{error}</Alert>
                        }

                        <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                            <DropdownButton
                                type="submit"
                                loading={loading}
                                value={createOrderMode}
                                options={[
                                    {title: 'Opslaan', value: 'save'},
                                    {title: 'Opslaan + Nieuw', value: 'saveNew'},
                                    {title: 'Opslaan + Sluiten', value: 'saveClose'},
                                    ...order.addresses.length > 2 ? [{title: 'Aparte rit per adres', action: (event) => this.onSubmit(true, event)}] : []
                                ]}
                                onChange={(event) => {
                                    localStorage.createOrderMode = event.target.value
                                    this.setState({createOrderMode: event.target.value})
                                }}
                            >
                                {
                                    createOrderMode === 'saveNew' ?
                                        'Opslaan + Nieuw' :
                                        createOrderMode === 'saveClose' ?
                                            'Opslaan + Sluiten' :
                                            'Opslaan'
                                }
                            </DropdownButton>
                        </div>
                    </form>
                }


                {orderError &&
                    <Alert variant="danger">{orderError}</Alert>
                }

                <Popup ref={(ref) => this.popup = ref}/>
            </Modal>
        )
    }
}

