import Reflux from 'reflux-react-16'
import _ from 'lodash'
import socket from '../socketio/socketio.js'

import LoginActions from '../actions/LoginActions.js'
import moment from 'moment'

export default class LoginStore extends Reflux.Store {
    constructor() {
        super()

        this.listenables = LoginActions

        this.state = {
            reseller: {permissions: {}, settings: {carriers: [], parcels: {}, orders: {}}},
            user: {permissions: {}, settings: {parcels: {}, orders: {}}},
            accounts: [],
            branding: undefined,
            brandingLoading: true
        }

        if (localStorage.loginAsCustomerToken) {
            sessionStorage.token = localStorage.loginAsCustomerToken
            sessionStorage.noDomainRedirect = true
            delete localStorage.loginAsCustomerToken
            window.location.reload() // Fix to get socket io working
        }

        if (localStorage.loginAsResellerToken) {
            sessionStorage.token = localStorage.loginAsResellerToken
            sessionStorage.noDomainRedirect = true
            delete localStorage.loginAsResellerToken
            window.location.reload() // Fix to get socket io working
        }

        sessionStorage.lastActiveTime = moment().toISOString(true)

        this.interval = setInterval(() => {
            if (moment().diff(moment(sessionStorage.lastActiveTime), 'hours') > 8) {
                LoginActions.logout()
            }
        }, 60000)

        window.document.addEventListener('click', () => {
            sessionStorage.lastActiveTime = moment().toISOString(true)
        }, {passive: false})

        const subdomain = window?.location.hostname?.split('.')[0]

        if (!['localhost', 'dev', 'test', 'app'].includes(subdomain)) {
            LoginActions.getBranding(subdomain, (error, branding) => {
                if (!error && branding) {
                    this.setState({branding, brandingLoading: false})
                } else {
                    this.setState({brandingLoading: false})
                }
            })
        } else {
            this.state.branding = {}
            this.state.brandingLoading = false
        }

        if (sessionStorage.token) {
            LoginActions.getAccount()
            LoginActions.getReseller()
            LoginActions.getPartners()
        }

        socket.on('updateUser', () => {
            LoginActions.getAccount()
        })

        socket.on('updateReseller', (path) => {
            LoginActions.updateReseller(path)
        })

        socket.on('updatePartners', () => {
            LoginActions.getPartners()
            LoginActions.getReseller()
        })
    }

    onGetAccounts(email, password, callback) {
        socket.emit('login.getAccounts', email, password, callback)
    }

    onLogin(token, index, code, callback) {
        socket.emit('login.login', token, index, code, (err, token, decoded, reseller) => {
            if (err) {
                callback(err)
                return
            }

            if (checkRedirectToNewDomain(reseller?.domain, true, reseller?.settings?.brandingEnabled)) return

            if (decoded.isMessenger) {
                localStorage.mtoken = token
                // Making sure the localStorage.mtoken is being set
                setTimeout(() => {
                    window.location = '/koerier'
                }, 500)
            } else {
                sessionStorage.token = token

                this.setState({reseller, accounts: decoded.accounts})

                LoginActions.getAccount((err, user) => {
                    callback(err, !err && this.link(user))
                })
            }
        })
    }

    onSwitchAccount(index, code, callback) {
        socket.emit('login.login', sessionStorage.token, index, code, (err, token, decoded, reseller) => {
            if (err) {
                callback(err, reseller)
                return
            }

            if (checkRedirectToNewDomain(reseller?.domain, true, reseller?.settings?.brandingEnabled)) return

            if (decoded.isMessenger) {
                localStorage.mtoken = token
                window.location = '/koerier'
            } else {
                sessionStorage.token = token

                LoginActions.getAccount((err, user) => {
                    window.location = this.link(user)
                })
            }
        })
    }

    onLoginAsReseller(id, url, callback) {
        socket.emit('login.loginAsReseller', sessionStorage.token, id, (err, token) => {
            if (!err) {
                localStorage.loginAsResellerToken = token
                setTimeout(() => window.open(url || '/'), 300)
                setTimeout(() => delete localStorage.loginAsResellerToken, 5000)
            }

            callback(err)
        })
    }

    onLoginAsCustomer(customer, callback) {
        socket.emit('login.loginAsCustomer', sessionStorage.token, customer._id, (err, token, user) => {
            if (!err) {
                if (user?.isAdmin) {
                    localStorage.loginAsCustomerToken = token
                    window.open('/klant')
                    setTimeout(() => delete localStorage.loginAsCustomerToken, 300)
                } else if (customer.settings.mfaRequired) {
                    callback(undefined, (showMfaPopup) => {
                        sessionStorage.customerToken = token

                        if (customer.mfaMethod === 'email') {
                            this.onMailMFACode(token, undefined, undefined, (err) => {
                                if (!err) {
                                    typeof showMfaPopup === 'function' && showMfaPopup()
                                }
                            })
                        } else {
                            typeof showMfaPopup === 'function' && showMfaPopup()
                        }
                    })
                } else {
                    localStorage.loginAsCustomerToken = token
                    window.open('/klant')
                    setTimeout(() => delete localStorage.loginAsCustomerToken, 300)
                }
            }

            callback(err)
        })
    }

    link(user) {
        let link = '/login'

        if (user.isCustomer) {
            return '/klant/'
        }

        if (user.isAdmin) {
            return '/admin'
        }

        if (user.isReseller) {
            _.keys(user.permissions).map((permission) => {
                if (user.permissions[permission] && link === '/login') {
                    if (permission === 'orders') {
                        link = '/planning'
                    } else if (permission === 'parcels') {
                        link = '/zendingen'
                    } else if (permission === 'shifts') {
                        link = '/diensten'
                    } else if (permission === 'subscriptions') {
                        link = '/abonnementen'
                    } else if (permission === 'customers') {
                        link = '/klanten'
                    } else if (permission === 'invoices') {
                        link = '/facturatie'
                    } else if (permission === 'users') {
                        link = '/gebruikers'
                    } else if (permission === 'settings') {
                        link = '/instellingen'
                    }
                }
            })
        }

        return link
    }

    onLogout() {
        sessionStorage.removeItem('token')
        localStorage.removeItem('lastActiveTime')
        window.location = '/login'
    }

    onLoggedIn(callback) {
        const loggedIn = !!sessionStorage.token

        callback(loggedIn, loggedIn && this.link(this.state.user))
    }

    onGetAccount(callback) {
        socket.emit('login.getAccount', sessionStorage.token, (err, user) => {
            if (!err) {
                this.setState({user: {...this.state.user, ...user}, accounts: user.accounts})
            }

            typeof callback === 'function' && callback(err, user)
        })
    }

    onUpdateUserSettings(user, callback) {
        if (user.isCustomer) {
            socket.emit('customers.updateSettings', sessionStorage.token, user, callback)
        }
    }

    onGetShippingMethods(user, callback) {
        LoginActions.updateUserSettings(user, () => {
            socket.emit('customers.getShippingMethods', sessionStorage.token, callback)
        })
    }

    onInstallLightspeed(language, timestamp, token, signature, clusterId, shopId, callback) {
        socket.emit('customers.installLightspeed', sessionStorage.token, language, timestamp, token, signature, clusterId, shopId, (err) => {
            if (!err) {
                LoginActions.getAccount((err) => callback(err))
            } else {
                callback(err)
            }
        })
    }

    onUninstallLightspeed(language, shopId, timestamp, signature, callback) {
        socket.emit('customers.uninstallLightspeed', sessionStorage.token, language, shopId, timestamp, signature, (err) => {
            if (!err) {
                LoginActions.getAccount((err) => callback(err))
            } else {
                callback(err)
            }
        })
    }

    onInstallShopify(code, hmac, nonce, shop, callback) {
        socket.emit('customers.installShopify', sessionStorage.token, code, hmac, nonce, shop, (err) => {
            if (!err) {
                LoginActions.getAccount((err) => callback(err))
            } else {
                callback(err)
            }
        })
    }

    onInstallExactOnline(code, callback) {
        socket.emit('customers.installExactOnline', sessionStorage.token, code, (err) => {
            if (!err) {
                LoginActions.getAccount((err) => callback(err))
            } else {
                callback(err)
            }
        })
    }

    onVerifyWebshop(webshop, callback) {
        socket.emit('customers.verifyWebshop', sessionStorage.token, webshop, (err) => {
            if (!err) {
                LoginActions.getAccount((err) => callback(err))
            } else {
                callback(err)
            }
        })
    }

    onInstallMollie(code, callback) {
        socket.emit('customers.installMollie', sessionStorage.token, code, (err) => {
            if (!err) {
                LoginActions.getAccount((err) => callback(err))
            } else {
                callback(err)
            }
        })
    }

    onGetMollieProfiles(callback) {
        socket.emit('customers.getMollieProfiles', sessionStorage.token, (err) => {
            if (!err) {
                LoginActions.getAccount((err) => callback(err))
            } else {
                callback(err)
            }
        })
    }

    onGetReseller() {
        const {user} = this.state

        if (!user.isAdmin) {
            socket.emit('login.getReseller', sessionStorage.token, (err, reseller, domain) => {
                if (!err) {
                    if (checkRedirectToNewDomain(domain, true, reseller?.settings?.brandingEnabled)) return
                    this.setState({reseller})
                }
            })
        }
    }

    onUpdateReseller(path) {
        const {user} = this.state

        if (!user.isAdmin) {
            socket.emit('resellers.updateReseller', sessionStorage.token, path, (err, value) => {
                if (!err) {
                    const reseller = {...this.state.reseller}

                    _.set(reseller, path, value)

                    this.setState({reseller})
                }
            })
        }
    }

    onUpdateResellerSettings(...args) {
        socket.emit('resellers.updateSettings', sessionStorage.token, ...args)
    }

    /**
     *
     * @param {*} partnerKey
     * @param {function} callback
     */
    onAddPartner(partnerKey, callback) {
        console.log('addPartner')
        socket.emit('resellers.addPartner', sessionStorage.token, partnerKey, (err, reseller) => {
            if (!err) {
                this.setState({reseller})
            }

            typeof callback === 'function' && callback(err)
        })
    }

    /**
     * @param {string} partnerId
     * @param {'approved' | 'rejected'} action
     * @param {function} callback
     */
    onHandlePartnerRequest(partnerId, action, callback) {
        socket.emit('resellers.handlePartnerRequest', sessionStorage.token, partnerId, action, (err, reseller) => {
            if (!err) {
                this.setState({reseller})
            }

            typeof callback === 'function' && callback(err)
        })
    }

    /**
     * @param {string} partnerId
     * @param {function} callback
     */
    onRemovePartner(partnerId, callback) {
        socket.emit('resellers.removePartner', sessionStorage.token, partnerId, (err, reseller) => {
            if (!err) {
                this.setState({reseller})
            }

            typeof callback === 'function' && callback(err)
        })
    }

    onGetPartners() {
        socket.emit('resellers.getPartners', sessionStorage.token, (err, partners) => {
            if (!err) {
                const partnerObject = {}

                partners.map((partner) => {
                    partnerObject[partner._id] = partner
                })

                this.setState({partners: partnerObject})
            }
        })
    }

    onSendResetPasswordMail(...args) {
        socket.emit('login.sendResetPasswordMail', ...args)
    }

    onResetPassword(token, password, callback) {
        socket.emit('login.resetPassword', token, password, callback)
    }

    onGenerateMFAKey(token, accountIndex, callback) {
        socket.emit('login.generateMFAKey', sessionStorage.token || token, accountIndex, callback)
    }

    onMailMFACode(token, ...args) {
        socket.emit('login.mailMFACode', sessionStorage.customerToken || sessionStorage.token || token, ...args)
    }

    onReset2FA(token, ...args) {
        socket.emit('login.reset2FA', sessionStorage.token || token, ...args)
    }

    onVerify2FACode(...args) {
        socket.emit('login.verify2FACode', sessionStorage.customerToken || sessionStorage.token, ...args)
    }

    onSendCustomerWelcomeMail(customer, callback) {
        socket.emit('login.sendCustomerWelcomeMail', sessionStorage.token, customer, callback)
    }

    onSendUserWelcomeMail(user, callback) {
        socket.emit('login.sendUserWelcomeMail', sessionStorage.token, user, callback)
    }

    onCheckNotifications(callback) {
        socket.emit('login.checkNotifications', sessionStorage.token, (err, notifications) => {
            if (!err && notifications) {
                this.setState({notifications})

                typeof callback === 'function' && callback()
            } else {
                console.log(err)
            }
        })
    }

    onUpdateReadNotifications(...args) {
        socket.emit('login.updateReadNotifications', sessionStorage.token, ...args)
    }

    onGetBranding(subdomain, callback) {
        socket.emit('login.getBranding', subdomain, (err, branding) => {
            this.setState({branding})
            typeof callback === 'function' && callback(err, branding)
        })
    }
}

function checkRedirectToNewDomain(brandingDomain, keepPath, brandingEnabled) {
    let newDomain
    const currentDomain = `${location.protocol}//${location.host}`
    const currentSubdomain = window?.location.hostname?.split('.')[0]

    if (!brandingDomain || (!brandingEnabled && ![brandingDomain, 'localhost', 'dev', 'test', 'app'].includes(currentSubdomain))) {
        if (location.hostname.includes('localhost')) {
            newDomain = `${location.protocol}//localhost:4000`
        }

        if (location.hostname.includes('veloyd.nl')) {
            newDomain = `${location.protocol}//app.veloyd.nl`
        }

        if (location.hostname.includes('test.veloyd.nl')) {
            newDomain = `${location.protocol}//test.veloyd.nl`
        }

        if (location.hostname.includes('dev.veloyd.nl')) {
            newDomain = `${location.protocol}//dev.veloyd.nl`
        }
    } else if (brandingEnabled) {
        newDomain = `${location.protocol}//${brandingDomain}.${location.host.split('.').slice(1).join('.')}`

        if (location.hostname === 'localhost') {
            newDomain = `${location.protocol}//${brandingDomain}.${location.host}`
        }
    } else {
        newDomain = currentDomain
    }

    if (newDomain !== currentDomain && !sessionStorage.noDomainRedirect) {
        if (keepPath) {
            newDomain = `${newDomain}${location.pathname}${location.search}`
        }

        // Logout current user
        sessionStorage.removeItem('token')
        localStorage.removeItem('lastActiveTime')

        window.location.href = newDomain
        return true
    }
}
