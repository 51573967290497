import React from 'react'

import _ from 'underscore'

import {Colors, Input, Select} from '../../UI/index.js'
import {Button, IconButton, Modal, H4} from '../../UI/index.js'
import Countries from '../../../utils/Countries.js'

class PriceRuleModal extends React.Component {
    constructor(props) {
        super(props)
        this.initialState = {
            open: false,
            priceRule: {name: ''},
            countries: []
        }
        this.state = this.initialState
    }

    open(carrier, product, i, priceRule, countries) {
        this.setState({open: true, carrier, product, i, priceRule, countries})
    }

    close() {
        this.setState(this.initialState)
    }

    onChangeName(event) {
        const {priceRule} = this.state
        priceRule.name = event.target.value
        this.setState({priceRule})
    }

    onChangeCountries(event) {
        const {priceRule} = this.state
        priceRule.countries = event.target.values
        this.setState({priceRule})
    }

    onRemove() {
        const {carrier, product, i} = this.state
        const {remove} = this.props
        remove(carrier, product, i)
        this.close()
    }

    onSubmit() {
        const {carrier, product, i, priceRule} = this.state
        const {onChange} = this.props
        onChange(carrier, product, i, priceRule)
        this.close()
    }

    render() {
        const {open, priceRule, countries} = this.state

        return (
            <Modal style={{width: 400}} show={open} onClose={this.close.bind(this)}>
                <div style={{display: 'flex', justifyContent: 'space-between'}}>
                    <H4>Wijzig tariefregel</H4>

                    <IconButton onClick={this.close.bind(this)}>
                        <i style={{color: Colors.buttonSolid}} className='mdi mdi-close' />
                    </IconButton>
                </div>

                <Input
                    label='Naam'
                    value={priceRule.name}
                    onChange={this.onChangeName.bind(this)}
                />

                <Select
                    copy
                    label='Landen'
                    options={countries.map((countryCode) => {
                        const country = _.findWhere(Countries, {code2: countryCode}) || {code2: countryCode, name: countryCode}
                        return {value: country.code2, title: country.name}
                    })}
                    values={priceRule.countries || []}
                    onChange={this.onChangeCountries.bind(this)}
                />

                <br />
                <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                    <Button variant='text' onClick={this.onRemove.bind(this)}>Verwijder</Button>
                    <Button disabled={!priceRule.name || !priceRule.countries.length}variant='text' onClick={this.onSubmit.bind(this)}>Opslaan</Button>
                </div>
            </Modal>
        )
    }
}

export default (PriceRuleModal)
