import React from 'react'

import Reflux from 'reflux-react-16'
import _ from 'lodash'
import {Route} from 'react-router-dom'

import LoginStore from '../../../stores/LoginStore.js'
import LoginActions from '../../../actions/LoginActions.js'
import AddressStore from '../../../stores/AddressStore.js'
import ParcelStore from '../../../stores/ParcelStore.js'

import AccountSettings from './Account/AccountSettings.js'
import CustomerSettings from './Customers/CustomerSettings.js'
import PartnerSettings from './Partners/PartnerSettings.js'
import OrderSettings from './Orders/OrderSettings.js'
import AdministrationSettings from './Administration/AdministrationSettings.js'
import ParcelSettings from './Parcels/ParcelSettings.js'
import {Alert, Panel, ListItem, Colors} from '../../../components/UI/index.js'

export default class Settings extends Reflux.Component {
    constructor(props) {
        super(props)

        this.state = {
            saved: false,
            error: ''
        }

        this.stores = [LoginStore, AddressStore, ParcelStore]
    }

    componentDidMount() {
        const {history} = this.props

        console.log(this.state.reseller)

        if (history.location.pathname === '/instellingen' || history.location.pathname === '/instellingen/') {
            history.replace('/instellingen/algemeen/bedrijfsgegevens')
        }
    }

    componentDidUpdate(prevProps) {
        const {history, reseller} = this.props

        if (history.location.pathname === '/instellingen' || history.location.pathname === '/instellingen/') {
            history.replace('/instellingen/algemeen/bedrijfsgegevens')
        }

        const prevName = prevProps?.reseller?.settings?.accountName || prevProps?.reseller?.name
        const name = reseller?.settings?.accountName || reseller?.name
        if (prevName !== name) {
            document.title = `Instellingen • ${name}`

            console.log(reseller.settings)
        }
    }

    componentWillUnmount() {
        super.componentWillUnmount.call(this)
        clearTimeout(this.state.timeout)
    }

    updateReseller(reseller, path, unsetPath) {
        this.setState({reseller: {...reseller}, saved: false}, () => {
            if (path) {
                const value = _.get(reseller.settings, path)

                LoginActions.updateResellerSettings(path, value, unsetPath, (err) => {
                    if (!err) {
                        clearTimeout(this.state.timeout)
                        const timeout = setTimeout(() => this.setState({saved: false}), 3000)
                        this.setState({saved: true, timeout})
                    } else {
                        this.setState({error: err})
                    }
                })
            }
        })
    }

    onNavigate(link) {
        const {history} = this.props
        history.push(link)
    }

    render() {
        const {user, reseller, partners, saved, error, carriers} = this.state
        const {history} = this.props
        const {pathname} = history.location

        return (
            <div style={{display: 'flex', height: 'calc(100vh - 60px)'}}>
                <Panel style={{width: 300, borderTop: 'none'}}>
                    <ListItem
                        icon='mdi mdi-domain'
                        onClick={this.onNavigate.bind(this, '/instellingen/algemeen/bedrijfsgegevens')}
                    >
                        Algemeen
                    </ListItem>

                    {pathname.indexOf('/instellingen/algemeen') !== -1 &&
                        <>
                            <ListItem
                                style={{paddingLeft: 50}}
                                active={pathname === '/instellingen/algemeen/bedrijfsgegevens'}
                                onClick={this.onNavigate.bind(this, '/instellingen/algemeen/bedrijfsgegevens')}
                            >
                                Bedrijfsgegevens
                            </ListItem>

                            <ListItem
                                style={{paddingLeft: 50}}
                                active={pathname === '/instellingen/algemeen/openingstijden'}
                                onClick={this.onNavigate.bind(this, '/instellingen/algemeen/openingstijden')}
                            >
                                Openingstijden
                            </ListItem>

                            <ListItem
                                style={{paddingLeft: 50}}
                                active={pathname === '/instellingen/algemeen/bezorggebieden'}
                                onClick={this.onNavigate.bind(this, '/instellingen/algemeen/bezorggebieden')}
                            >
                                Bezorggebieden
                            </ListItem>

                            <ListItem
                                style={{paddingLeft: 50}}
                                active={pathname === '/instellingen/algemeen/emailinstellingen'}
                                onClick={this.onNavigate.bind(this, '/instellingen/algemeen/emailinstellingen')}
                            >
                                Email Instellingen
                            </ListItem>
                        </>
                    }

                    {reseller.permissions.customers && user.permissions.customers &&
                        <ListItem
                            icon='mdi mdi-store'
                            active={pathname === '/instellingen/klanten'}
                            onClick={this.onNavigate.bind(this, '/instellingen/klanten')}
                        >
                            Klanten
                        </ListItem>
                    }

                    {reseller.name === 'Veloyd' &&
                        <ListItem
                            icon='mdi mdi-connection'
                            active={pathname === '/instellingen/partnerkoppelingen'}
                            onClick={this.onNavigate.bind(this, '/instellingen/partnerkoppelingen')}
                        >
                            Partner koppelingen
                        </ListItem>
                    }

                    {reseller.permissions.orders && user.permissions.orders &&
                        <>

                            <ListItem
                                icon='mdi mdi-map-clock'
                                onClick={this.onNavigate.bind(this, '/instellingen/planning/opties')}
                            >
                                Planning
                            </ListItem>

                            {pathname.indexOf('/instellingen/planning') !== -1 &&
                                <>
                                    <ListItem
                                        style={{paddingLeft: 50}}
                                        active={pathname === '/instellingen/planning/opties'}
                                        onClick={this.onNavigate.bind(this, '/instellingen/planning/opties')}
                                    >
                                        Opties
                                    </ListItem>

                                    {reseller.permissions.vehicles &&
                                        <ListItem
                                            style={{paddingLeft: 50}}
                                            active={pathname === '/instellingen/planning/voertuig-typen'}
                                            onClick={this.onNavigate.bind(this, '/instellingen/planning/voertuig-typen')}
                                        >
                                            Voertuigsoorten
                                        </ListItem>
                                    }

                                    {!reseller.isShop &&
                                        <ListItem
                                            style={{paddingLeft: 50}}
                                            active={pathname === '/instellingen/planning/tarieven'}
                                            onClick={this.onNavigate.bind(this, '/instellingen/planning/tarieven')}
                                        >
                                            Tarieven
                                        </ListItem>
                                    }

                                    <ListItem
                                        style={{paddingLeft: 50}}
                                        active={pathname === '/instellingen/planning/emailnotificaties'}
                                        onClick={this.onNavigate.bind(this, '/instellingen/planning/emailnotificaties')}
                                    >
                                        Email Notificaties
                                    </ListItem>

                                    {!reseller.isShop &&
                                        <ListItem
                                            style={{paddingLeft: 50}}
                                            active={pathname === '/instellingen/planning/koppelingen'}
                                            onClick={this.onNavigate.bind(this, '/instellingen/planning/koppelingen')}
                                        >
                                            Koppelingen
                                        </ListItem>
                                    }
                                    <ListItem
                                        style={{paddingLeft: 50}}
                                        active={pathname === '/instellingen/planning/tracktrace'}
                                        onClick={this.onNavigate.bind(this, '/instellingen/planning/tracktrace')}
                                    >
                                        Track & Trace Pagina
                                    </ListItem>
                                </>
                            }
                        </>
                    }

                    {reseller.permissions.parcels && user.permissions.parcels &&
                        <>
                            <ListItem
                                icon='mdi mdi-package-variant-closed'
                                onClick={this.onNavigate.bind(this, '/instellingen/zendingen/vervoerders')}
                            >
                                Zendingen
                            </ListItem>

                            {pathname.indexOf('/instellingen/zendingen') !== -1 &&
                                <>
                                    <ListItem
                                        style={{paddingLeft: 50}}
                                        active={pathname === '/instellingen/zendingen/vervoerders'}
                                        onClick={this.onNavigate.bind(this, '/instellingen/zendingen/vervoerders')}
                                    >
                                        Vervoerders
                                    </ListItem>
                                    <ListItem
                                        style={{paddingLeft: 50}}
                                        active={pathname === '/instellingen/zendingen/verzendopties'}
                                        onClick={this.onNavigate.bind(this, '/instellingen/zendingen/verzendopties')}
                                    >
                                        Verzendopties
                                    </ListItem>
                                    <ListItem
                                        style={{paddingLeft: 50}}
                                        active={pathname === '/instellingen/zendingen/voorkeuren'}
                                        onClick={this.onNavigate.bind(this, '/instellingen/zendingen/voorkeuren')}
                                    >
                                        Voorkeuren
                                    </ListItem>
                                    {!reseller.isShop &&
                                        <ListItem
                                            style={{paddingLeft: 50}}
                                            active={pathname === '/instellingen/zendingen/tarieven'}
                                            onClick={this.onNavigate.bind(this, '/instellingen/zendingen/tarieven')}
                                        >
                                            Tarieven
                                        </ListItem>
                                    }
                                    {!reseller.isShop &&
                                        <ListItem
                                            style={{paddingLeft: 50}}
                                            active={pathname === '/instellingen/zendingen/productcodes'}
                                            onClick={this.onNavigate.bind(this, '/instellingen/zendingen/productcodes')}
                                        >
                                            Productcodes
                                        </ListItem>
                                    }
                                    <ListItem
                                        style={{paddingLeft: 50}}
                                        active={pathname === '/instellingen/zendingen/tracktraceemail'}
                                        onClick={this.onNavigate.bind(this, '/instellingen/zendingen/tracktraceemail')}
                                    >
                                        Email Notificaties
                                    </ListItem>
                                </>
                            }
                        </>
                    }

                    {reseller.permissions.invoices && user.permissions.invoices &&
                        <ListItem
                            icon='mdi mdi-currency-eur'
                            active={pathname === '/instellingen/administratie'}
                            onClick={this.onNavigate.bind(this, '/instellingen/administratie')}
                        >
                            Administratie
                        </ListItem>
                    }
                </Panel>

                <div style={{background: Colors.backgroundNeutral2, height: '100%', overflow: 'auto', width: '100%'}}>

                    {saved && <Alert onClose={() => this.setState({saved: false})} style={{position: 'fixed', zIndex: 100, width: 'calc(100vw - 300px)'}} variant='success'>Je wijzigingen zijn opgeslagen</Alert>}
                    {error && <Alert onClose={() => this.setState({error: ''})} style={{position: 'fixed', zIndex: 100, width: 'inherit'}} variant='error'>{error}</Alert>}

                    <div style={{margin: 30}}>
                        <Route path='/instellingen/algemeen' render={() => <AccountSettings reseller={reseller} history={history} user={user} onChange={this.updateReseller.bind(this)} />} />

                        {reseller.permissions.customers && user.permissions.customers &&
                            <Route path='/instellingen/klanten' render={() => <CustomerSettings reseller={reseller} history={history} onChange={this.updateReseller.bind(this)} />} />
                        }

                        <Route path='/instellingen/partnerkoppelingen' render={() => <PartnerSettings reseller={reseller} partners={partners} history={history} onSave={this.updateReseller.bind(this)} />} />

                        {reseller.permissions.orders && user.permissions.orders &&
                            <Route path='/instellingen/planning' render={() => <OrderSettings reseller={reseller} history={history} onChange={this.updateReseller.bind(this)} />} />
                        }
                        {reseller.permissions.parcels && user.permissions.parcels &&
                            <Route path='/instellingen/zendingen' render={() => <ParcelSettings reseller={reseller} history={history} carriers={carriers} onChange={this.updateReseller.bind(this)} />} />
                        }
                        {reseller.permissions.invoices && user.permissions.invoices &&
                            <Route path='/instellingen/administratie' render={() => <AdministrationSettings reseller={reseller} history={history} onChange={this.updateReseller.bind(this)} />} />
                        }
                    </div>
                </div>
            </div>
        )
    }
}
