import React from 'react'

import {Colors, Popover, ListItem, P} from '../UI/index.js'

class NavBarMenu extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            isMobile: window.matchMedia('(max-width: 768px)').matches
        }
    }

    onNavigate(link) {
        const {history} = this.props
        history.push(link)
        this.popover.close()
    }

    render() {
        const {link, links} = this.props
        const {isMobile} = this.state

        const style = {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            fontSize: 16,
            color: this.state.hover ? Colors.buttonSolid : Colors.textDark,
            cursor: 'pointer',
            width: 'auto',
            height: '100%',
            marginLeft: 20
        }

        return (
            <div style={{display: 'flex', justifyContent: 'flex-end', height: '100%', marginLeft: 12}}>
                <Popover
                    placement='left'
                    ref={(ref) => this.popover = ref}
                    content={
                        <div>
                            {links.map((link) => {
                                if (link) {
                                    return (
                                        <ListItem key={link.title} onClick={link.onClick || this.onNavigate.bind(this, link.target)}>
                                            {link.icon && <i style={{marginRight: 12, fontSize: 20}} className={link.icon} />}
                                            <div>{link.title}</div>
                                        </ListItem>
                                    )
                                }
                            })}
                        </div>
                    }
                >

                    <div
                        style={style}
                        onMouseEnter={() => this.setState({hover: true})}
                        onMouseLeave={() => this.setState({hover: false})}
                    >
                        <div style={{height: '100%', display: 'flex', alignItems: 'center'}}>
                            {link.icon && <i style={{marginRight: 10, fontSize: 20}} className={link.icon} />}
                            <div>
                                <P style={{color: style.color}} ellipsis>{link.title}</P>

                                {link.subTitle &&
                                    <P style={{color: style.color, maxWidth: isMobile ? 100 : ''}} ellipsis> {link.subTitle}</P>
                                }

                            </div>

                        </div>
                        <div style={{height: 4}} />
                    </div>
                </Popover>
            </div>
        )
    }
}

export default NavBarMenu
