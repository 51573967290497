import React from 'react'
import moment from 'moment'

import InvoiceActions from '../../actions/InvoiceActions.js'

import {Alert, Button, Colors, Check, Link, TextArea, Modal, S2, P, Spinner} from '../UI/index.js'

class InvoiceModal extends React.Component {
    constructor(props) {
        super(props)
        this.initialState = {
            modalIsOpen: false,
            invoice: {price: 0, invoiceLines: []},
            selected: [],
            notes: '',
            loading: false,
            error: ''
        }

        this.state = structuredClone(this.initialState)
    }

    open(id) {
        if (typeof id === 'object') {
            const invoice = id
            const selected = [...Array(invoice.invoiceLines.length).keys()]
            console.log(invoice)
            this.setState({modalIsOpen: true, invoice, selected, notes: invoice.notes || ''})
        } else {
            this.setState({modalIsOpen: true, invoiceLoading: true})

            InvoiceActions.getOne(id, (err, invoice) => {
                if (err) {
                    this.setState({invoiceLoading: false, invoiceError: err})
                } else {
                    console.log(invoice)
                    const selected = [...Array(invoice.invoiceLines.length).keys()]
                    this.setState({invoiceLoading: false, invoiceError: null, invoice, selected, notes: invoice.notes || ''})
                }
            })
        }
    }

    close() {
        const {invoice, notes} = this.state

        if (invoice.invoiced && notes !== invoice.notes) {
            InvoiceActions.editNotes(invoice._id, notes, (err) => {
                if (err) {
                    this.setState({error: err})
                } else {
                    this.setState(this.initialState)
                }
            })
        } else {
            this.setState(this.initialState)
        }
    }

    onClickSendInvoice() {
        const {onClickSendInvoice} = this.props
        const {invoice, selected} = this.state

        if (selected.length > 0) {
            const invoiceLines = invoice.invoiceLines.filter((invoiceLine, index) => {
                return selected.indexOf(index) > -1
            })

            invoice.invoiceLines = invoiceLines
            onClickSendInvoice(invoice, (err) => {
                if (err) {
                    this.setState({loading: false, error: err})
                } else {
                    this.close()
                }
            })
        }
    }

    onClickOpenAttachment() {
        const {invoice} = this.state
        const {toPDF} = this.props

        toPDF(invoice, (err, response) => {
            const raw = window.atob(response.pdf)
            const rawLength = raw.length
            const array = new Uint8Array(new ArrayBuffer(rawLength))

            for (let i = 0; i < rawLength; i++) {
                array[i] = raw.charCodeAt(i)
            }
            const blob = new Blob([array], {type: 'application/pdf'})
            const blobUrl = URL.createObjectURL(blob)
            window.open(blobUrl)
        })
    }

    onSelectInvoiceLine(i) {
        const {selected} = this.state
        const index = selected.indexOf(i)

        if (index > -1) {
            selected.splice(index, 1)
        } else {
            selected.push(i)
        }

        selected.sort((a, b) => (a - b)) // Sort numerically
        this.setState({selected})
    }

    onSelectAll() {
        let {invoice, selected} = this.state

        if (invoice.invoiceLines.length !== selected.length) {
            selected = [...Array(invoice.invoiceLines.length).keys()]
        } else {
            selected = []
        }

        this.setState({selected})
    }

    onChangeNotes(event) {
        this.setState({notes: event.target.value})
    }

    render() {
        const {modalIsOpen, invoiceLoading, invoiceError, invoice, selected, notes, loading, error} = this.state
        const {history, customers} = this.props

        return (
            <Modal
                show={modalIsOpen}
                title='Factuur info'
                closeButton
                onClose={this.close.bind(this)}
            >

                {invoiceLoading ?
                        <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: 250}}>
                            <Spinner name='ball-clip-rotate' fadeIn='none' color={Colors.textDark} />
                        </div> :
                        <>

                            <div style={{display: 'flex', marginBottom: 30}}>
                                <div style={{flex: 1}}>
                                    <S2>Datum</S2>
                                    <P>{moment(invoice.date).format('DD-MM-YYYY')}</P>
                                </div>
                                <div style={{flex: 1}}>
                                    <S2>Status</S2>
                                    <P>{invoice.invoiced ? 'Gefactureerd' : 'Concept'}</P>
                                </div>
                            </div>

                            <div style={{display: 'flex', marginBottom: 30}}>
                                <div style={{flex: 1}}>
                                    <S2>Klant</S2>
                                    <P>{customers[invoice.customer] ? customers[invoice.customer].name : ''}</P>
                                </div>

                                <div style={{flex: 1}}>
                                    <S2>Prijs</S2>
                                    <P>{`€ ${invoice.price}`}</P>
                                </div>
                            </div>

                            <div style={{maxHeight: 430, marginBottom: 30, overflowY: 'auto'}}>
                                <div style={{flex: 1}}>
                                    <table style={{width: '100%', textAlign: 'left'}}>
                                        <thead style={{color: Colors.textDark}}>
                                            <tr>
                                                {!invoice.invoiced &&
                                                    <th>
                                                        <Check
                                                            checked={selected.length === invoice.invoiceLines.length}
                                                            onChange={this.onSelectAll.bind(this)}
                                                        />
                                                    </th>
                                                }
                                                <th><S2>Datum</S2></th>
                                                <th><S2>Omschrijving</S2></th>
                                                <th><S2>Prijs</S2></th>
                                            </tr>
                                        </thead>
                                        <tbody style={{color: Colors.textDark}}>
                                            {invoice.invoiceLines.map((invoiceLine, index) => {
                                                return (
                                                    <tr key={`invoiceLine${index}`}>
                                                        {!invoice.invoiced &&
                                                            <td>
                                                                <Check
                                                                    checked={selected.indexOf(index) > -1}
                                                                    onChange={this.onSelectInvoiceLine.bind(this, index)}
                                                                />
                                                            </td>
                                                        }
                                                        <td style={{width: 100}}><P>{moment(invoiceLine.date).format('DD-MM-YYYY')}</P></td>
                                                        <td>
                                                            {invoiceLine.type === 'order' &&
                                                                <Link onClick={() => history.push('/planning', {orderId: invoiceLine._id})}>{invoiceLine.description}</Link>
                                                            }
                                                            {invoiceLine.type === 'parcel' &&
                                                                <Link onClick={() => history.push('/zendingen', {parcelId: invoiceLine._id})}>{invoiceLine.description}</Link>
                                                            }
                                                            {invoiceLine.type === 'subscription' &&
                                                                <Link onClick={() => history.push('/abonnementen', {subscriptionId: invoiceLine._id || invoiceLine.subscriptionId})}>{invoiceLine.description}</Link>
                                                            }

                                                        </td>
                                                        <td style={{width: 100}}><P>{`€ ${invoiceLine.price}`}</P></td>
                                                    </tr>
                                                )
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                            </div>

                            <br />
                            {invoice.invoiced &&
                                <TextArea
                                    label='Notities'
                                    value={notes}
                                    onChange={this.onChangeNotes.bind(this)}
                                />
                            }

                            <br />
                            {error && <Alert variant='danger'>{error}</Alert>}

                            <div style={{display: 'flex', justifyContent: 'flex-end'}}>

                                {!invoice.invoiced &&
                                    <Button loading={loading} onClick={this.onClickSendInvoice.bind(this)}>
                                        Factuur aanmaken
                                    </Button>
                                }

                                {invoice.invoiced &&
                                    <Button onClick={this.onClickOpenAttachment.bind(this)}>
                                        <i className='mdi mdi-download' />
                                    </Button>
                                }
                            </div>
                        </>
                }

                {invoiceError &&
                    <Alert variant='danger'>{invoiceError}</Alert>
                }
            </Modal>
        )
    }
}

export default (InvoiceModal)
