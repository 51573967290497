import React from 'react'
import Reflux from 'reflux-react-16'

import {Route} from 'react-router-dom'

import LoginStore from '../../stores/LoginStore.js'
import LoginActions from '../../actions/LoginActions.js'

import {Colors, FlexColumn, MfaPopup, NavBar, NavBarLink, NavBarMenu, Popup} from '../../components/UI/index.js'
import LoginPopup from '../../components/login/LoginPopup.js'
import Dashboard from './Dashboard.js'
import Resellers from './Resellers.js'
import Invoices from './Invoices.js'
import Customers from './Customers.js'
import SearchOrdersAndParcels from './SearchOrdersAndParcels.js'
import Logs from './Logs.js'

export default class Layout extends Reflux.Component {
    constructor(props) {
        super(props)

        this.store = LoginStore
    }

    componentDidMount() {
        const {history} = this.props

        document.title = 'Admin'

        LoginActions.loggedIn((loggedIn, link) => {
            if (!loggedIn) {
                const redirect = encodeURIComponent(`${history.location.pathname}${history.location.search}`)

                if (redirect && redirect !== '%2F') {
                    history.push(`/login?redirect=${redirect}`)
                } else {
                    history.push('/login')
                }
            } else {
                const originalPathname = `${history.location.pathname}${history.location.search}`
                history.push(link)
                if (originalPathname === '/admin' || originalPathname === '/admin/') {
                    history.push('/admin/resellers')
                } else if (originalPathname.includes('/admin')) {
                    history.push(originalPathname)
                }
            }
        })
    }

    onClickSwitchAccount() {
        const {accounts, user} = this.state

        this.loginPopup.open(accounts, user, (index) => {
            const account = accounts[index]

            if ((account.mfaRequired && !user.is2FASignedIn)) {
                if (account.mfaEnabled) {
                    if (account.mfaMethod === 'email') {
                        LoginActions.mailMFACode(null, index, null, (err) => {
                            if (err) {
                                this.mfaPopup.setError(err)
                            }
                        })
                    }

                    this.mfaPopup.open((code) => {
                        LoginActions.switchAccount(index, code, (err) => {
                            if (err) {
                                this.mfaPopup.setError(err)
                            }
                        })
                    }, account.mfaMethod)
                } else {
                    this.alertPopup.open('Tweestapsverificatie nog niet ingesteld.', 'Er is nog geen twee-stapsverificatie ingesteld op dit account, maar het is wel verplicht. Log uit en vervolgens weer in op het betreffende account om het proces voor het instellen van tweestapsverificatie te starten.')
                    this.alertPopup.setAction('Uitloggen', () => {
                        LoginActions.logout()
                    })
                }
            } else {
                LoginActions.switchAccount(index, null, (err, reseller) => {
                    if (err) {
                        this.setState({loading: false, error: err})

                        if ((!account.isMessenger && reseller.settings.mfaWebEnabled) || (account.isMessenger && reseller.settings.mfaAppEnabled)) {
                            this.alertPopup.open('Tweestapsverificatie nog niet ingesteld.', 'Er is nog geen twee-stapsverificatie ingesteld op dit account, maar het is wel verplicht. Log uit en vervolgens weer in op het betreffende account om het proces voor het instellen van tweestapsverificatie te starten.')
                            this.alertPopup.setAction('Uitloggen', () => {
                                LoginActions.logout()
                            })
                        }
                    }
                })
            }
        })
    }

    onClickLogout() {
        this.popup.open('Uitloggen', 'Weet je zeker dat je wilt uitloggen?', () => LoginActions.logout())
    }

    render() {
        const {user, accounts} = this.state
        const {history} = this.props
        const {pathname} = history.location

        return (
            <FlexColumn style={{height: '100vh', minWidth: 1366, paddingTop: 60}}>
                <NavBar branding={{}}>
                    <NavBarLink
                        branding={{}}
                        history={history}
                        active={pathname === '/admin/resellers'}
                        link={{icon: 'mdi mdi-store', title: 'Resellers', target: '/admin/resellers'}}
                    />

                    <NavBarLink
                        branding={{}}
                        history={history}
                        active={pathname === '/admin/klanten'}
                        link={{icon: 'mdi mdi-account', title: 'Klanten', target: '/admin/klanten'}}
                    />

                    <NavBarLink
                        branding={{}}
                        history={history}
                        active={pathname === '/admin/zoeken'}
                        link={{icon: 'mdi mdi-package-variant-closed', title: 'Zoeken', target: '/admin/zoeken'}}
                    />

                    <NavBarLink
                        branding={{}}
                        history={history}
                        active={pathname === '/admin/notificaties'}
                        link={{icon: 'mdi mdi-text-account', title: 'Notificaties', target: '/admin/notificaties'}}
                    />

                    <NavBarLink
                        branding={{}}
                        history={history}
                        active={pathname === '/admin/facturen'}
                        link={{icon: 'mdi mdi-finance', title: 'Facturen', target: '/admin/facturen'}}
                    />

                    <NavBarLink
                        history={history}
                        active={pathname === '/admin/logs'}
                        link={{icon: 'mdi mdi-finance', title: 'Logs', target: '/admin/logs'}}
                    />

                    <div style={{flex: 1}} />

                    <NavBarMenu
                        branding={{}}
                        history={history}
                        links={[
                            accounts.length > 1 && {title: 'Wissel account', icon: 'mdi mdi-account-multiple', onClick: this.onClickSwitchAccount.bind(this)},
                            {title: 'Uitloggen', icon: 'mdi mdi-logout', onClick: this.onClickLogout.bind(this)}
                        ]}
                        link={{icon: 'mdi mdi-account-circle', title: user.name, subTitle: 'Admin'}}
                    />
                </NavBar>

                <FlexColumn style={{flex: 1, background: Colors.backgroundNeutral}}>
                    <Route path='/admin/notificaties' render={() => <Dashboard user={user} />} />
                    <Route path='/admin/resellers' render={() => <Resellers user={user} history={history} />} />
                    <Route path='/admin/klanten' render={() => <Customers user={user} history={history} />} />
                    <Route path='/admin/zoeken' render={() => <SearchOrdersAndParcels user={user} />} />
                    <Route path='/admin/facturen' render={() => <Invoices user={user} />} />
                    <Route path='/admin/logs' render={() => <Logs user={user} history={history} />} />
                </FlexColumn>

                <LoginPopup ref={(ref) => this.loginPopup = ref} />
                <Popup ref={(ref) => this.popup = ref} />
                <MfaPopup ref={(ref) => this.mfaPopup = ref} />
            </FlexColumn>
        )
    }
}
