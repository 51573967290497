import React from 'react'

import {Badge, Modal, H4, S2, P, ListItem, IconButton, Colors} from '../UI/index.js'

export default class LoginPopup extends React.Component {
    constructor(props) {
        super(props)

        this.initialState = {
            isOpen: false,
            accounts: [],
            onConfirm: null,
            user: null
        }

        this.state = JSON.parse(JSON.stringify(this.initialState))
    }

    open(accounts, user, onConfirm) {
        this.setState({isOpen: true, accounts, user, onConfirm})

        if (!onConfirm) {
            return new Promise((resolve, reject) => {
                this.resolve = resolve
                this.reject = reject
            })
        }
    }

    close() {
        this.setState({isOpen: false}, () => {
            this.setState(this.initialState)
        })
    }

    onClickConfirm(index) {
        this.close()

        if (typeof this.state.onConfirm === 'function') {
            this.state.onConfirm(index)
        } else {
            this.resolve(index)
        }
    }

    render() {
        const {isOpen, accounts, user} = this.state

        return (
            <Modal show={isOpen} style={{width: 400, maxWidth: '90%'}} onClose={() => this.close()}>
                <div style={{display: 'flex', marginBottom: 24}}>
                    <H4>Account keuze</H4>

                    <div style={{flex: 1}} />

                    <IconButton onClick={() => this.close()}>
                        <i style={{color: Colors.buttonSolid}} className='mdi mdi-close' />
                    </IconButton>
                </div>

                <P style={{marginBottom: 30}}>Kies hieronder het account waarvoor je wilt inloggen:</P>

                <div style={{maxHeight: 600, overflowY: 'auto'}}>
                    {accounts.map((account, index) => {
                        let isActive = false

                        if (account?._id && account?._id === user?._id) {
                            isActive = true

                            if (account.isMessenger && !window.location.pathname.includes('koerier')) {
                                isActive = false
                            }
                        } else {
                            if (user?.isAdmin && account?.isAdmin) {
                                isActive = true
                            }
                        }

                        return (
                            <ListItem key={`account${index}`} style={{opacity: isActive ? 0.3 : 1}} onClick={() => this.onClickConfirm(index)}>
                                <div style={{flex: 1}}>
                                    <P>{account.name}</P>
                                    <S2>{account.isAdmin ? 'Veloyd admin' : account.resellerName}</S2>
                                </div>

                                {account.isReseller &&
                                    <Badge>Vervoerder</Badge>
                                }
                                {account.isMessenger &&
                                    <Badge>Koerier</Badge>
                                }
                                {account.isCustomer &&
                                    <Badge>Klant</Badge>
                                }
                                {account.isAdmin &&
                                    <Badge>Admin</Badge>
                                }
                            </ListItem>
                        )
                    })}
                </div>
            </Modal>
        )
    }
}
