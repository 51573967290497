import React from 'react'

import {Alert, Button, IconButton, Modal, H1, H4, S2, P, Panel, ListItem, Colors, ProgressBar} from '../UI/index.js'
import socket from '../../socketio/socketio.js'

class PrintPopup extends React.Component {
    constructor(props) {
        super(props)
        this.initialState = {
            isOpen: false,
            loading: false,
            title: '',
            text: '',
            onConfirm: null,
            message: '',
            warning: '',
            warnings: [],
            error: '',
            errors: [],
            printerPageSize: '',
            nrOfEmptyLabels: 0,
            progressPercentage: undefined,
            progressMessage: '',
            preventProgressUpdate: false
        }

        this.state = JSON.parse(JSON.stringify(this.initialState))
    }

    componentDidMount() {
        socket.on('updateProgress', (progress) => {
            const {preventProgressMessageUpdate} = this.state

            this.setState({
                progressPercentage: progress.percentage,
                ...(!preventProgressMessageUpdate && {progressMessage: progress.message})
            })
        })
    }

    open(title, text, printerPageSize, onConfirm, onCancel, preventProgressMessageUpdate) {
        this.setState({isOpen: true, title, text, printerPageSize, onConfirm, onCancel, preventProgressMessageUpdate})
    }

    close() {
        this.setState({isOpen: false}, () => {
            typeof this.state.onClose === 'function' && this.state.onClose()
            this.setState(this.initialState)
        })
    }

    setNrOfEmptyLabels(nrOfEmptyLabels) {
        this.setState({nrOfEmptyLabels})
    }

    onClickConfirm() {
        this.setState({loading: true, message: '', error: ''})
        typeof this.state.onConfirm === 'function' && this.state.onConfirm(this.state.nrOfEmptyLabels)
    }

    setProgress(progress) {
        this.setState({progressPercentage: progress.percentage, progressMessage: progress.message})
    }

    setMessage(message) {
        this.setState({message, loading: false})
    }

    setInfo(info, loading = false) {
        this.setState({info, loading})
    }

    setWarning(warning) {
        this.setState({warning, loading: false})
    }

    setWarnings(warnings) {
        this.setState({warnings, loading: false})
    }

    setError(error) {
        this.setState({error, loading: false, progressMessage: ''})
    }

    setErrors(errors) {
        this.setState({errors, loading: false, progressMessage: ''})
    }

    render() {
        const {isOpen, title, text, printerPageSize, nrOfEmptyLabels, onConfirm, loading, message, info, warning, warnings, error, errors, progressPercentage, progressMessage} = this.state
        const {closeButton} = this.props

        return (
            <Modal show={isOpen} style={{width: errors.length > 0 ? 800 : 400, maxWidth: '90%'}} onClose={this.close.bind(this)}>
                <div style={{display: 'flex', marginBottom: 24}}>

                    <H4>{title}</H4>

                    <div style={{flex: 1}} />

                    {closeButton &&
                        <IconButton onClick={this.close.bind(this)}>
                            <i style={{color: Colors.buttonSolid}} className='mdi mdi-close' />
                        </IconButton>
                    }
                </div>

                <P style={{marginBottom: 30}}>{text}</P>

                {printerPageSize.indexOf('A4') > -1 &&
                    <div style={{paddingTop: 30, marginBottom: 60}}>

                        <S2 style={{marginBottom: 12}}>Kies de positie van het eerste label:</S2>

                        <div style={{display: 'flex', justifyContent: 'center'}}>

                            {printerPageSize === 'A4' &&
                                <Panel style={{display: 'flex', flexWrap: 'wrap', maxWidth: 302}}>
                                    <Page
                                        nrOfEmptyLabels={nrOfEmptyLabels}
                                        value={0}
                                        onClick={this.setNrOfEmptyLabels.bind(this, 0)}
                                    />
                                    <Page
                                        nrOfEmptyLabels={nrOfEmptyLabels}
                                        value={1}
                                        onClick={this.setNrOfEmptyLabels.bind(this, 1)}
                                    />

                                    <Page
                                        nrOfEmptyLabels={nrOfEmptyLabels}
                                        value={2}
                                        onClick={this.setNrOfEmptyLabels.bind(this, 2)}
                                    />
                                    <Page
                                        nrOfEmptyLabels={nrOfEmptyLabels}
                                        value={3}
                                        onClick={this.setNrOfEmptyLabels.bind(this, 3)}
                                    />
                                </Panel>
                            }

                            {printerPageSize === 'A410x21' &&
                                <Panel style={{display: 'flex', flexWrap: 'wrap', maxWidth: 303}}>
                                    <Page
                                        portrait
                                        nrOfEmptyLabels={nrOfEmptyLabels}
                                        value={0}
                                        onClick={this.setNrOfEmptyLabels.bind(this, 0)}
                                    />
                                    <Page
                                        portrait
                                        nrOfEmptyLabels={nrOfEmptyLabels}
                                        value={1}
                                        onClick={this.setNrOfEmptyLabels.bind(this, 1)}
                                    />

                                    <Page
                                        portrait
                                        nrOfEmptyLabels={nrOfEmptyLabels}
                                        value={2}
                                        onClick={this.setNrOfEmptyLabels.bind(this, 2)}
                                    />
                                </Panel>
                            }

                            {printerPageSize === 'A4A5' &&
                                <Panel style={{display: 'flex', flexWrap: 'wrap', maxWidth: 151}}>
                                    <Page
                                        nrOfEmptyLabels={nrOfEmptyLabels}
                                        value={0}
                                        onClick={this.setNrOfEmptyLabels.bind(this, 0)}
                                    />
                                    <Page
                                        nrOfEmptyLabels={nrOfEmptyLabels}
                                        value={1}
                                        onClick={this.setNrOfEmptyLabels.bind(this, 1)}
                                    />
                                </Panel>
                            }
                        </div>
                    </div>
                }

                {typeof progressPercentage === 'number' &&
                    <ProgressBar now={progressPercentage} max={100} />
                }

                {progressMessage &&
                    <P style={{textAlign: 'center', marginBottom: 8}}>{progressMessage}</P>
                }

                {message &&
                    <Alert variant='success'>{message}</Alert>
                }

                {info &&
                    <Alert variant='info'>{info}</Alert>
                }

                {warning &&
                    <Alert variant='warning'>{warning}</Alert>
                }

                {warnings.length > 0 && warnings.map((warning, index) => {
                    return <Alert key={`warning${index}`} variant='warning'>{warning}</Alert>
                })}

                {error &&
                    <Alert variant='danger'>{error}</Alert>
                }

                {errors.length > 0 && errors.map((error, index) => {
                    return <Alert key={`error${index}`} variant='danger'>{error}</Alert>
                })}

                <div style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-end'}}>
                    {!message && !error && !errors.length &&
                        <Button
                            variant='text'
                            onClick={() => {
                                this.close()
                                typeof this.state.onCancel === 'function' && this.state.onCancel()
                            }}
                        >
                            Annuleren
                        </Button>
                    }

                    {onConfirm && !message && !error && !errors.length &&
                        <Button
                            variant='text'
                            loading={loading}
                            onClick={this.onClickConfirm.bind(this)}
                        >
                            Bevestigen
                        </Button>
                    }

                    {(message || error || !!errors.length) &&
                        <Button
                            style={{marginBottom: 0}}
                            variant='text'
                            onClick={this.close.bind(this)}
                        >
                            Sluiten
                        </Button>
                    }
                </div>
            </Modal>
        )
    }
}

const Page = (props) => {
    const {nrOfEmptyLabels, value, portrait, onClick} = props

    return (
        <ListItem
            style={{
                height: portrait ? 150 : 100,
                width: portrait ? 100 : 150,
                justifyContent: 'center'
            }}
            active={nrOfEmptyLabels <= value}
            onClick={onClick}
        >
            <H1 style={{marginBottom: 0, color: ''}}>{value + 1}</H1>
        </ListItem>
    )
}

export default (PrintPopup)
