import React from 'react'

import {Address, Colors, Toggle} from '../UI/index.js'
import {Alert, Button, IconButton, Modal, H4} from '../UI/index.js'
import NewAddress from './NewAddress.js'

export default class AddressModal extends React.Component {
    constructor(props) {
        super(props)

        this.initialState = {
            address: NewAddress(),
            modalIsOpen: false,
            loading: false,
            error: '',
            onSave: undefined,
            onRemove: undefined
        }

        this.state = JSON.parse(JSON.stringify(this.initialState))
    }

    open(address, onSave, onRemove) {
        this.setState({
            modalIsOpen: true,
            address: address || this.state.address,
            onSave,
            onRemove
        })
    }

    close() {
        this.setState(this.initialState)
    }

    onSubmit(event) {
        event.preventDefault()
        const {address, onSave} = this.state

        if (this.address.validate()) {
            this.setState({loading: true, error: ''})

            if (onSave && typeof onSave === 'function') {
                onSave(address)
            }
        }
    }

    onClickRemove() {
        const {onRemove} = this.state

        this.setState({loading: true, error: ''})
        onRemove()
    }

    setError(error) {
        this.setState({error, loading: false})
    }

    response(err) {
        if (err) {
            this.setState({loading: false, error: err})
        } else {
            this.close()
        }
    }

    onAddressChange(address) {
        this.setState({address})
    }

    render() {
        const {address, modalIsOpen, error, loading, onRemove} = this.state
        const {showEmail = true, showPhone = true, showBusinessCheckbox = true, noTAV = false, showInstructions = false, allowEmptyName} = this.props

        return (
            <Modal show={modalIsOpen} onClose={this.close.bind(this)}>
                <div style={{display: 'flex', justifyContent: 'space-between'}}>
                    <H4>{address._id ? 'Bewerk adres' : 'Nieuw adres'}</H4>

                    <IconButton onClick={this.close.bind(this)}>
                        <i style={{color: Colors.buttonSolid}} className='mdi mdi-close' />
                    </IconButton>
                </div>

                <form onSubmit={this.onSubmit.bind(this)}>
                    <Address
                        email={showEmail}
                        phone={showPhone}
                        businessCheckbox={showBusinessCheckbox}
                        overridePosition={true}
                        state={true}
                        noTAV={noTAV}
                        showInstructions={showInstructions}
                        allowEmptyName={allowEmptyName}
                        address={address}
                        onChange={this.onAddressChange.bind(this)}
                        allowAttachments={true}
                        ref={(ref) => this.address = ref}
                    />

                    {/* TODO: remove this after Px pilot */ this.props.showPx &&
                        <Toggle
                            style={{marginTop: 24, width: '50%'}}
                            label='Verstuur standaard met Px'
                            checked={address.px}
                            onChange={(event) => this.onAddressChange({...address, px: event.target.checked})}
                        />
                    }

                    {error &&
                        <Alert variant='danger'>{error}</Alert>
                    }

                    <div style={{display: 'flex', justifyContent: 'flex-end', marginTop: 12}}>
                        {onRemove && typeof onRemove === 'function' &&
                            <Button variant='error' onClick={this.onClickRemove.bind(this)} loading={loading}>Verwijder</Button>
                        }
                        <Button type='submit' loading={loading}>Opslaan</Button>
                    </div>
                </form>
            </Modal>
        )
    }
}
