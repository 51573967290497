// @ts-check
import React from 'react'

import {Icon, Button, H4, H6, Tabs, Tab, Map, Panel, Row, Toggle, S1, Colors, ColorPicker, P, Input, Select, MetricInput, Column} from '../UI/index.js'
import _ from 'lodash'

export default class DefaultPartnerSettingsPanel extends React.PureComponent {
    constructor(props) {
        super(props)

        this.state = {
            tab: 'send',
            settingsChanged: false,
            defaultPartnerSettings: structuredClone(this.props.defaultPartnerSettings)
        }
    }

    /**
     * @param {string} path
     * @param {{target: HTMLInputElement}} event
     */
    onChangeValue(path, event) {
        const defaultPartnerSettings = {...this.state.defaultPartnerSettings}
        _.set(defaultPartnerSettings, path, event.target.value)
        this.setState({defaultPartnerSettings, settingsChanged: true})
    }

    /**
     * @param {string} key
     * @param {{target: HTMLInputElement}} event
     */
    onChangeChecked(key, event) {
        const defaultPartnerSettings = {...this.state.defaultPartnerSettings}
        defaultPartnerSettings[key] = !!event.target.checked
        this.setState({defaultPartnerSettings, settingsChanged: true})
    }

    /**
     * @param {string} key
     * @param {string} value
     */
    onChangeArray(key, value) {
        const defaultPartnerSettings = {...this.state.defaultPartnerSettings}

        const array = _.get(defaultPartnerSettings, key, [])

        const index = array.indexOf(value)

        if (index > -1) {
            array.splice(index, 1)
        } else {
            array.push(value)
        }

        _.set(defaultPartnerSettings, key, array)

        this.setState({defaultPartnerSettings, settingsChanged: true})
    }

    onSubmit() {
        this.props.onSave(this.state.defaultPartnerSettings)
    }

    render() {
        const {reseller} = this.props
        const {defaultPartnerSettings} = this.state

        return (
            <Panel shadow='true' style={{width: 800, padding: 24, height: 'fit-content'}}>
                <Row style={{justifyContent: 'center', marginBottom: 36}}>
                    <H4>{reseller?.settings.accountName || reseller?.name}</H4>
                </Row>

                <Row style={{alignItems: 'center', marginBottom: 12}}>
                    <Column>
                        <Toggle
                            label='Kleur tonen bij scannen'
                            info='Bij meerdere vervoerders met hetzelfde logo kan het handig zijn om tijdens het scannen een kleur te zien voor snelle herkenning'
                            checked={defaultPartnerSettings.useCustomScanColor}
                            onChange={(event) => this.onChangeChecked('useCustomScanColor', event)}
                            style={{marginBottom: 0}}
                        />
                    </Column>

                    <Column style={{alignItems: 'center', gap: 24}}>
                        {defaultPartnerSettings.useCustomScanColor &&
                            <>
                                <ColorPicker
                                    value={defaultPartnerSettings.customScanColor}
                                    colors={Colors.routeColors}
                                    onChange={(event) => this.onChangeValue('customScanColor', event)}
                                />

                                <P
                                    style={{
                                        height: 36,
                                        width: '100%',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        borderRadius: 4,
                                        backgroundColor: defaultPartnerSettings.customScanColor || '',
                                        color: Colors.isDarkBackground(defaultPartnerSettings.customScanColor || '') ? 'white' : 'black'
                                    }}
                                >
                                    {reseller?.settings.accountName || reseller?.name}
                                </P>
                            </>
                        }
                    </Column>
                </Row>

                <Row>
                    <Column>
                        <Toggle
                            label='Toon route informatie aan partner'
                            info='De partner ziet de route informatie tijdens het scannen, hierdoor kunnen pakketten meteen op route gesorteerd worden.'
                            checked={defaultPartnerSettings.showRouteInfo}
                            onChange={(event) => this.onChangeChecked('showRouteInfo', event)}
                        />
                    </Column>
                    <Column />
                </Row>

                <Input
                    label='Crediteurnummer'
                    info='Hier kun je het crediteurnummer invullen dat wordt gebruikt in je boekhouding voor deze vervoerder'
                    value={defaultPartnerSettings.creditorCode}
                    onChange={(event) => this.onChangeValue('creditorCode', event)}
                />

                <Tabs
                    style={{marginBottom: 24, marginTop: 48}}
                    value={this.state.tab}
                    onChange={(event) => this.setState({tab: event.target.value})}
                >
                    <Tab value='send'>Aanmelden bij partner</Tab>
                    <Tab value='receive'>Ontvangen van partner</Tab>
                </Tabs>

                {this.state.tab === 'send' &&
                    <>
                        <H6>Rit instellingen</H6>

                        <Select
                            style={{marginTop: 24}}
                            label='Gebruik communicatie van'
                            value={defaultPartnerSettings.sendSettings.communication || 'partner'}
                            options={[
                                {value: 'reseller', title: reseller.settings.accountName || reseller.name},
                                {value: 'partner', title: 'Partner'}
                            ]}
                            onChange={(event) => this.onChangeValue('sendSettings.communication', event)}
                        />

                        <Select
                            label='Mag partner de rit bewerken'
                            value={defaultPartnerSettings.sendSettings.allowEditOrder || 'yes'}
                            options={[
                                {value: 'yes', title: 'Ja'},
                                {value: 'addressOnly', title: 'Alleen adres'},
                                {value: 'no', title: 'Nee'}
                            ]}
                            onChange={(event) => this.onChangeValue('sendSettings.allowEditOrder', event)}
                        />
                    </>
                }

                {this.state.tab === 'receive' &&
                    <>

                        <H6>Bezorggebied</H6>

                        <div style={{height: 500, marginBottom: 24}}>
                            <Map layers={reseller.settings.deliveryZones.filter((deliveryZone) => {
                                return defaultPartnerSettings.receiveSettings.deliveryZones.includes(deliveryZone.id)
                            })}
                            />
                        </div>

                        {reseller.settings.deliveryZones.map((deliveryZone) => {
                            return (
                                <Row key={deliveryZone.id}style={{alignItems: 'center', marginBottom: 12}}>
                                    <Icon icon='mdi mdi-circle' style={{fontSize: 24, color: deliveryZone.color}} />

                                    <S1 style={{flex: 1}}>{deliveryZone.name}</S1>

                                    <Toggle
                                        style={{marginBottom: 0, flex: 0}}
                                        checked={defaultPartnerSettings.receiveSettings.deliveryZones.includes(deliveryZone.id)}
                                        onChange={() => this.onChangeArray('receiveSettings.deliveryZones', deliveryZone.id)}
                                    />
                                </Row>
                            )
                        })}

                        <H6 style={{marginTop: 36}}>Maximum dimensies</H6>

                        <Row>
                            <MetricInput
                                label='Maximale lengte'
                                value={defaultPartnerSettings.receiveSettings.maxLength}
                                divisionFactor={10}
                                onChange={(event) => this.onChangeValue('receiveSettings.maxLength', event)}
                                append='cm'
                            />

                            <MetricInput
                                label='Maximale breedte'
                                value={defaultPartnerSettings.receiveSettings.maxWidth}
                                divisionFactor={10}
                                onChange={(event) => this.onChangeValue('receiveSettings.maxWidth', event)}
                                append='cm'
                            />

                            <MetricInput
                                label='Maximale hoogte'
                                value={defaultPartnerSettings.receiveSettings.maxHeight}
                                divisionFactor={10}
                                onChange={(event) => this.onChangeValue('receiveSettings.maxHeight', event)}
                                append='cm'
                            />
                        </Row>

                        <Row>
                            <MetricInput
                                label='Maximaal gewicht'
                                value={defaultPartnerSettings.receiveSettings.maxWeight}
                                divisionFactor={1000}
                                onChange={(event) => this.onChangeValue('receiveSettings.maxWeight', event)}
                                append='kg'
                            />

                            <MetricInput
                                label='Maximaal volume'
                                value={defaultPartnerSettings.receiveSettings.maxVolume}
                                onChange={(event) => this.onChangeValue('receiveSettings.maxVolume', event)}
                                append='dm3'
                            />
                        </Row>
                    </>
                }

                <div style={{display: 'flex', justifyContent: 'flex-end', marginTop: 90}}>
                    <div style={{flex: 1}} />

                    <Button onClick={this.onSubmit.bind(this)} disabled={!this.state.settingsChanged}>
                        Opslaan
                    </Button>
                </div>
            </Panel>
        )
    }
}
