import {Order} from './functions/orders/orders.types.js'

export default {
    status: {
        0: 'Verwacht',
        7: 'In sortering',
        1: 'Gereed',
        2: 'Onderweg',
        3: 'In bewaring',
        4: 'Niet afgerond',
        5: 'Afgerond',
        6: 'Geannuleerd',
        verwacht: () => 0,
        inSortering: () => 7,
        gereed: () => 1,
        onderweg: () => 2,
        inBewaring: () => 3,
        nietAfgerond: () => 4,
        afgerond: () => 5,
        geannuleerd: () => 6
    },

    statusV2: {
        0: 'expected',
        7: 'in sorting',
        1: 'ready',
        2: 'in transit',
        3: 'on hold',
        4: 'not completed',
        5: 'completed',
        6: 'cancelled'
    },

    pickupStatus: {
        0: '',
        1: 'Opgehaald',
        2: 'Niet thuis',
        3: 'Niet opgehaald',
        opgehaald: () => 1,
        nietThuis: () => 2,
        nietOpgehaald: () => 3
    },

    deliveryStatus: {
        0: '',
        1: 'Bezorgd',
        2: 'Bezorgd bij buren',
        3: 'Niet thuis',
        4: 'Niet bezorgd',
        5: 'Geweigerd',
        bezorgd: () => 1,
        bezorgdBijBuren: () => 2,
        nietThuis: () => 3,
        nietBezorgd: () => 4,
        geweigerd: () => 5
    },

    addressType: {
        pickup: 'Ophaaladres',
        delivery: 'Bezorgadres'
    },

    colloTypes: {
        'box': 'Doos',
        'crate': 'Krat',
        'roll container': 'Rolcontainer',
        'pallet': 'Pallet',
        'other': 'Overige'
    },

    colloStatus: {
        cancelled: 'Geannuleerd',

        expected: 'Verwacht',
        notsupplied: 'Niet aangeleverd',

        pickup: 'Gereed voor ophalen',
        outforpickup: 'Onderweg voor ophalen',
        notcollected: 'Niet opgehaald',
        collected: 'Opgehaald',

        insorting: 'In sortering',
        athub: 'Gereed voor bezorgen',

        onhold: 'In bewaring',

        outfordelivery: 'Onderweg voor bezorgen',

        notdelivered: 'Niet bezorgd',
        missing: 'Ontbreekt',

        delivered: 'Bezorgd',
        refused: 'Geweigerd',
        manco: 'Manco',
        returned: 'Retour'
    },

    colloPickupStatus: (collo: Order['colli'][0]) => {
        if (typeof collo.pickupAddressIndex !== 'number') {
            return {status: '', reason: ''}
        }

        if (['cancelled', 'pickup', 'outforpickup', 'notcollected'].includes(collo.status)) {
            return {status: collo.status, reason: collo.reason || ''}
        } else {
            return {status: 'collected', reason: ''}
        }
    },

    colloReasons: {
        nothome: 'Niet thuis',
        notready: 'Niet gereed',
        invalid: 'Voldoet niet aan eisen',
        addressnotfound: 'Adres niet gevonden',
        wrongaddress: 'Adres onjuist',
        companyaddressclosed: 'Bedrijfsadres gesloten',
        verificationfailed: 'Verificatie niet gelukt',
        delayed: 'Tijdnood',
        overmacht: 'Overmacht',
        other: 'Anders'
    },

    packagingStatus: {
        registered: 'Aangemeld',

        collected: 'Opgehaald',
        notcollected: 'Niet opgehaald',
        athub: 'Op hub',
        delivered: 'Bezorgd',

        return_collected: 'Retour opgehaald',
        return_athub: 'Retour op hub',
        return_delivered: 'Retour bezorgd',

        missing: 'Ontbreekt',
        manco: 'Manco'
    },

    parcelStatus: {
        1: 'Aangemaakt', // registered
        2: 'Bevestigd', // confirmed
        3: 'Opgehaald', // collected
        4: 'Onderweg', // in transit
        5: 'Manco', // missing
        6: 'Op afhaalpunt', // available at pickup point
        7: 'Bezorgd', // delivered
        8: 'Retour', // returned to sender
        9: 'Geannuleerd', // cancelled
        10: 'Zie T&T link', // see t&t page
        aangemaakt: () => 1,
        bevestigd: () => 2,
        opgehaald: () => 3,
        onderweg: () => 4,
        manco: () => 5,
        opAfhaalpunt: () => 6,
        bezorgd: () => 7,
        retour: () => 8,
        geannuleerd: () => 9,
        zieTTLink: () => 10

    },

    orderToParcelStatus: {
        0: 2, // Verwacht -> Bevestigd
        1: 4, // Aangemaakt -> Onderweg
        2: 4, // Onderweg -> Onderweg
        3: 4, // In bewaring -> Onderweg
        4: 4, // Niet bezorgd -> Onderweg
        5: 7, // Afgerond -> Bezorgd
        6: 9 // Geannuleerd -> Geannuleerd
    },

    salaryMethod: {
        0: 'Geen',
        1: 'Vast',
        2: 'Uurloon',
        3: 'Percentage',
        4: 'Percentage met minimum vast',
        5: 'Percentage met minimum uurloon'
    },

    newOrderStatus: {
        1000: 'Aangemeld',
        2000: 'Opgehaald',
        2001: 'Op hub',
        2002: 'In bewaring',
        3000: 'Onderweg',
        5000: 'Niet afgerond',
        6000: 'Retour',
        7000: 'Geannuleerd',
        verwacht: () => 0,
        aangemaakt: () => 1,
        onderweg: () => 2,
        inBewaring: () => 3,
        nietAfgerond: () => 4,
        afgerond: () => 5,
        geannuleerd: () => 6
    }
}
